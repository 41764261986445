import React, { Component } from 'react';
import { ChartLine } from '../'
import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import { gaTimeDimensions, getLabelFromProps } from "../../resources/functions/"
import { FormatMetric, FormatDimension } from "../"

import Tooltip from "./ChartTooltip"

class TooltipBar extends Component {
    
    static defaultProps = {
        tooltipTitle: undefined,
        tooltipLabel: undefined,
    }

    getLabel = (id, data) => {
        const { tooltipLabel } = this.props;

        let labels = [];

        // label from tooltipLabel        
        
        if (tooltipLabel) {
            return getLabelFromProps({...data, label: tooltipLabel})
        }
        
        // label from id
        
        const identifiers = id.split('/');
        
        identifiers.map((key) => {
            
            const keyParts = key.split(':')
            
            if (keyParts[0] === "startDate" || keyParts[0] === "endDate") {
                return
            } 
            
            if (gaTimeDimensions.includes(key)) {
                return
            }

            labels.push(key)
            
        })
        
        return labels.join(' ');
        
    }

    
   getGroups = (data, index) => {
       const { keys, colors } = this.props;
       const { points } = data;
       const { x, datetime } = points[0]
       
       const groups = keys.map((key, index) => {
           
           const label = this.getLabel(key, points[index])

            return {
                key: key,
                label: label,
                value: data[key] || 0,
                color: colors[index],
                x: x,
                datetime: datetime,
                data: points[index]
            }
           
       })
       
       return groups        
    }
    
    renderLabel = (name, index) => {

        return (
            <i className="admin-tooltip__label">
                <FormatMetric name={name} key={index}/>
            </i>
        )
    
    }
    
   renderPoint = (point, index) => {
        let { dimension, metricType } = this.props;
        
        const { color, key, label, value } = point;
        
        const style = {
            backgroundColor: color
        }
        
        if (!value) {
            return false
        }
        
        return (
            <li key={index}>
                <div className="admin-tooltip__chip" style={style}></div>
                {label.split(' ').map(this.renderLabel)}
                <i className="admin-tooltip__value"><FormatMetric type={metricType} value={value} /></i>
            </li>
        )
        
    }
    
    renderTitle = (data) => {
        const { dimension, tooltipTitle } = this.props;

        console.log('tooltipProps', data)
        
        
        if (tooltipTitle) {
            const title = getLabelFromProps({...data, label: tooltipTitle})
            
            return (
                <h2 className="admin-tooltip__title">{title}</h2>
            )
            
        }        

        const { datetime, x } = data;

        let value;

        if (dimension.startsWith('ga:nth')) {
            value = datetime;
        } else {
            value = x;
        }
        
        return (
            <h2 className="admin-tooltip__title"><FormatDimension name={dimension} value={value} /></h2>
        )
        
    }

    renderSeries = (series) => {

        return (
            <Tooltip>
                { this.renderTitle(series[0].data)}
                <ul>
                { series.map(this.renderPoint) }
                </ul>
            </Tooltip>
        )
    }
        
    render() {
        const { groupMode, compare, dimension, bar } = this.props;
        const { data } = bar;
        
        console.log('DATA BAR', bar)

        const series = this.getGroups(data)
        
        return this.renderSeries(series)
        
    }

}

export default TooltipBar