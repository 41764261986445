import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSearch } from '../redux/functions/search';
import { getUpload } from '../redux/functions/upload';

import AppFinder from "../AppFinder/FinderApp"
import FinderQuery from "../AppFinder/FinderQuery"

import UploadQueue from "./UploadQueue"
import UploadHeader from "./UploadHeader"


const UploadFinder = ({app, uploadById, searchById, ...props}) => {
    const { collectionId } = app

    const id = props.location && props.location.pathname

    const query = {
        id: id,
        collectionId: collectionId,
        models: "media",
        status: "upload",
        q: "NOT parentId:*",
        sort: "updatedAt DESC",
        rows: 10
    }

    useEffect(() => {
        props.getUpload({id})
        props.getSearch({
            id: id,
            collectionId: collectionId,
            models: "media",
            status: "upload",
            q: "NOT parentId:*",
            sort: "updatedAt DESC",
            rows: 10
        })
    }, [id])

    const search = searchById && searchById[id] && searchById[id] || {};
    const upload = uploadById && uploadById[id] && uploadById[id] || {};

    const uploadFiles = (acceptedFiles = []) => {

        const uploadData = {
            collectionId: collectionId,
            status: "upload"
        }

        props.getUpload({id, acceptedFiles, uploadData})

    }

    return (
        <AppFinder {...props}>
            <UploadHeader {...upload} onUpload={uploadFiles} />

            { upload && <UploadQueue {...props} {...upload} /> }
            { query && <FinderQuery {...props} query={query} template="uploads" /> }

        </AppFinder>
    )

}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        searchById: state.searchById,
        uploadById: state.uploadById
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      getSearch,
      getUpload,
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadFinder);