import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { InboxQuery, InboxEditor } from "../AppInbox"

class AppRoutes extends Component {
  
    render() {
    
        return (
      
            <Switch>
                <Route path="/forum/:inboxSection/:inboxCategory/:uniqueId" component={ InboxEditor } />
                <Route path="/forum/:inboxSection/:inboxCategory" component={ InboxQuery } />
                <Route path="/forum/:inboxSection" component={ InboxQuery } />
                <Route path="/forum" component={ InboxQuery } />
            </Switch>

        )

    }

}

export default AppRoutes