import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100%",
        paddingBottom: props => { return props.ratio && props.ratio * 100 + "%" },
        margin: "auto"
    }
}));

const MediaFormat = ({format = undefined, ratio = undefined, children}) => {

    if (format && format.includes(':')) {
        const size = format.split(":");
        const w = size[0];
        const h = size[1];
        ratio = h/w;
    }

    const classes = useStyles({ratio})

    if (ratio) {
        return (
            <div className={classes.root} data-format={format} data-ratio={ratio}>
                { children }
            </div>
        )
    }

    return children

}

export default MediaFormat