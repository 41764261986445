import React from 'react';
import ReactMarkdown from 'react-markdown';
import { PreviewIframe } from '@frontend-components/admin';
import { makeStyles } from '@material-ui/core/styles';
//import VideoRecorder from 'react-video-recorder'

const useStyles = makeStyles(theme => ({
    chat: {
        backgroundColor: "#e1dac9",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "1em",
        lineHeight: 1.25,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        padding: "1em"
    },
    chatSection: {

    },
    chatMedia: {
        display: "inline-block",
        border: "1px solid",
        borderRadius: "1.5em 1.5em 1.5em 0",
        padding: "1em",
        margin: ".5em"
    },
    chatImage: {
        display: "block",
        width: "auto",
        height: "12em"
    },
    chatMessage: {
        "& p": {
            display: "inline-block",
            border: "1px solid",
            borderRadius: "1.5em 1.5em 1.5em 0",
            padding: "1em",
            margin: ".5em"
        },
        "& li   ": {
            display: "inline-block",
            border: "1px solid",
            borderRadius: "1.5em 1.5em 1.5em 0",
            padding: "1em",
            margin: ".5em"
        },
    },
    chatButton: {
        display: "inline-block",
        backgroundColor: "black",
        color: "white",
        borderRadius: "1.5em 1.5em 0 1.5em",
        padding: "1em",
        margin: ".25em"
    },
    chatReply: {
        display: "flex",    
        justifyContent: "flex-end",
        margin: ".25em",
    },
    chatInput: {
        display: "inline-block",
        backgroundColor: "white",
        color: "black",
        borderRadius: "1.5em 1.5em 0 1.5em",
        padding: "1em",
        margin: ".5em"
    },
}));

const ChatMedia = ({media = []}) => {
    const classes = useStyles()

    if (!media.length) {
        return false
    }

    const renderMedia = ({media, size}, index) => {
        const { mediaType, imageUrl } = media;

        if (mediaType === "image" && imageUrl) {
            return <img className={classes.chatImage} src={imageUrl} data-size={size} />
        }

        return false
    }

    return (
        <figure className={classes.chatMedia}>
            { media && media.map(renderMedia) }
        </figure>
    )

}

const ChatLinks = ({links = []}) => {
    const classes = useStyles()

    if (!links.length) {
        return false
    }

    const renderLink = (link, index) => {
        const { title, url } = link;
        return <li className={classes.chatButton}>{title}</li>
    }

    return (
        <ul className={classes.chatReply} >
            { links && links.map(renderLink) }
        </ul>
    )

}


const ChatReply = () => {
    const classes = useStyles()

    return (
        <div className={classes.chatReply}>
            <div className={classes.chatInput}>
                ...
            </div>
        </div>
    )

}

const ChatVideoReply = () => {
    const classes = useStyles()

    return (
        <div className={classes.chatReply}>
            <div className={classes.chatVideo}>
                
            </div>
            <div className={classes.chatButton}>Spela in svar</div>
            <div className={classes.chatButton}>Nesta fråga</div>
        </div>
    )

}


const ChatMessage = ({message}) => {
    const classes = useStyles()

    if (!message) {
        return false
    }

    return (
        <ReactMarkdown className={classes.chatMessage} source={message} />
    )

}
  
const SectionChat = ({sectionType, title, message, media = [], links = [], localeId, selected, onClick}) => {
    const classes = useStyles()

    title = title && title[localeId]
    message = message && message[localeId]
    
    return (
        <section className={classes.chatSection} aria-selected={selected} onClick={onClick}>
            <ChatMedia media={media} />
            <ChatMessage message={message} />
            { sectionType === "chatLinks" && <ChatLinks links={links} /> }
            { sectionType === "chatText" && <ChatReply /> }
            { sectionType === "chatVideo" && <ChatVideoReply /> }
        </section>
    )
}

const templates = {
    "chat": SectionChat,
    "chatText": SectionChat,
    "chatVideo": SectionChat,
    "chatLinks": SectionChat,
}


const PreviewChat = ({formData, formContext}) => {
    const classes = useStyles()

    const currentLocale = formContext && formContext.currentLocale
    const defaultLocale = formContext && formContext.defaultLocale
    const locale = currentLocale || defaultLocale || formData.locale
    const localeId = "locale:"+locale

    const currentId = formContext.currentId
    const onCurrentId = formContext.onCurrentId

    const sections = formData && formData.content && formData.content.sections;

    const renderSection = (section, index) => {
        const { sectionType } = section;
        const SectionTemplate = templates && templates[sectionType]

        if (!SectionTemplate) {
            return <p>No template for {sectionType}</p>
        }

        const id = "root_content_body_sections_"+index
        const selected = id.startsWith(currentId)

        return <SectionTemplate {...section} localeId={localeId} selected={selected} onClick={() => onCurrentId(id)}/>
    }
    
    return (
        <article className={classes.chat}>
        { sections && sections.map(renderSection) }

            {JSON.stringify(formData)}

        </article>
    )
  
}

export default PreviewChat