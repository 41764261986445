import registry from "@frontend-components/admin/lib/components/AppPrimusFDV/components/registry"
import PrimusChildrenField from "./PrimusChildrenField"

export default {
    "fieldtypes": {
        ...registry.fieldtypes,
    },
    "fields": {
        ...registry.fields,
        "primusChildren": PrimusChildrenField
    },
    "layouts": {
        ...registry.layouts,
    },
    "widgets": {
        ...registry.widgets,
    }
}