let initialState = {
};

const searchById = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'REQUEST_SEARCH':
            return {
                ...state,
                [action.id]: {
                    query: [action.query],
                    resultsByPage: {},
                    resultsLoaded: []
                }
            }

        case 'RECEIVE_SEARCH':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    ...action.data,
                }
            }

        case 'RECEIVE_SEARCH_PAGE':

            const resultsByPage = {
                ...state[action.id].resultsByPage,
                [action.page]: action.models
            }

            let resultsLoaded = []

            Object.values(resultsByPage).map(page => {
                resultsLoaded = resultsLoaded.concat(page)
            })

            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    resultsByPage: resultsByPage,
                    resultsLoaded: resultsLoaded

                }
            }

        default:
            return state

    }
       
}

export default searchById;