import React, { useRef, useState, useEffect } from 'react';
import Color from 'color';
import WidgetBase from "../Widget/WidgetBase"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import UserIcon from "./UserIcon"
import { FilterOwnersControlled as FilterOwners, OwnersList } from "../Owners"

const getContrast = (color) => {

    color = Color(color);

    let contrast;

    if (color.isDark()) {
        contrast = 'white';
    } else {
        contrast = 'black';
    }
    
    return contrast

}

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        position: "relative",
    },
    header: {
        backgroundColor: props => { return props.backgroundColor },
        color: props => { return getContrast(props.backgroundColor) },
        width: "100%",
        height: theme.spacing(15), // 120px
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: theme.spacing(8),
        margin: theme.spacing(2)
    },
    info: {
        display: ""
    },
    title: {
        fontSize: "18px",
        lineHeight: "20px",
        fontWeight: "normal"
    },
    email: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "normal",
        opacity: 0.85
    },
    body: {
        display: "block",
        position: "absolute",
        top: theme.spacing(15),
        right: 0,
        bottom: 0,
        left: 0
    }
}));

const OwnerWidget = ({user = {}, onSelect, ...props}) => {
    const [overflow, setOverflow] = useState(false)
    const bodyRef = useRef()

    const imageUrl = user.imageUrl || props.imageUrl
    const initials = user.initials || props.initials
    const name = user.name || props.name || "Name"
    const email = user.email || props.email
    const owners = user.owners || props.owners

    const theme = user.theme || props.theme
    const backgroundColor = theme && theme.primaryColor || "#ccc"

    const classes = useStyles({backgroundColor})

    useEffect(() => {
        const height = bodyRef.current && bodyRef.current.offsetHeight
        const itemsHeight = owners && owners.length && owners.length * 57;

        if (itemsHeight > height) {
            setOverflow(true)
        }

    });

    return (
        <WidgetBase {...props} classes={classes}>
            <header className={classes.header}>
                <UserIcon className={classes.icon} imageUrl={imageUrl} name={name} initials={initials} className={classes.icon} />
                <div className={classes.info}>
                    <Typography component="h2" className={classes.title}>{name}</Typography>
                    <Typography component="h3" className={classes.email}>{email}</Typography>
                </div>
            </header>
            <div className={classes.body} ref={bodyRef}>
                { overflow && <FilterOwners owners={owners} onSelect={onSelect} /> }
                { !overflow && <OwnersList owners={owners} onSelect={onSelect} /> }
            </div>
        </WidgetBase>
    )

}

OwnerWidget.defaultProps = {
    type: "owner",
    size: "4:6",
}

export default OwnerWidget;