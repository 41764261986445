import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import CollectionFinder from "./CollectionFinder"
import CollectionAdmin from "./CollectionAdmin"
import CollectionEditor from "./CollectionEditor"

import SchemaFinder from "./SchemaFinder"
import SchemaAdmin from "./SchemaAdmin"
import SchemaEditor from "./SchemaEditor"

import SiteFinder from "./SiteFinder"
import SiteAdmin from "./SiteAdmin"
import SiteEditor from "./SiteEditor"

import UserFinder from "./UserFinder"
import UserAdmin from "./UserAdmin"
import UserEditor from "./UserEditor"

import AuthSite from "./AuthSite"
import AuthUser from "./AuthUser"
import AuthApp from "./AuthApp"

class AdminRoutes extends Component {

    render() {

        return (
            <Switch>
                <Route path="/:app/collection/:collectionId/schema/:uniqueId/edit" component={ SchemaEditor } />
                <Route path="/:app/collection/:collectionId/schema" component={ SchemaAdmin } />

                <Route path="/:app/collection/:uniqueId/edit" component={ CollectionEditor } />
                <Route path="/:app/collection/:uniqueId" component={ CollectionAdmin } />
                <Route path="/:app/collection" component={ CollectionFinder } />

                <Route path="/:app/schema/:uniqueId/edit" component={ SchemaEditor } />
                <Route path="/:app/schema" component={ SchemaFinder } />

                <Route path="/:app/user/:uniqueId/edit" component={ UserEditor } />
                <Route path="/:app/user" component={ UserFinder } />

                <Route path="/:app/site/:uniqueId/edit" component={ SiteEditor } />
                <Route path="/:app/site" component={ SiteFinder } />

                <Route path="/:app" component={ SiteAdmin } />

            </Switch>
        )       

        return (
            <Switch>
                <Route path="/:app/auth/site/:siteId/user/:userId/:tabId" component={ AuthSite } />
                <Route path="/:app/auth/site/:siteId/user/:userId" component={ AuthSite } />
                <Route path="/:app/auth/site/:siteId/app/:appId" component={ AuthSite } />
                <Route path="/:app/auth/site/:siteId/:tabId" component={ AuthSite } />
                <Route path="/:app/auth/site/:siteId" component={ AuthSite } />

                <Route path="/:app/auth/user/:userId/site/:siteId" component={ AuthUser } />
                <Route path="/:app/auth/user/:userId/:tabId" component={ AuthUser } />
                <Route path="/:app/auth/user/:userId" component={ AuthUser } />

                <Route path="/:app/auth/app/:appId/site/:siteId" component={ AuthApp } />
                <Route path="/:app/auth/app/:appId/:tabId" component={ AuthApp } />
                <Route path="/:app/auth/app/:appId" component={ AuthApp } />

            </Switch>
        )
    }
}

export default AdminRoutes