import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import _ from "lodash"

import SettingsButton from "./SettingsButton"
import SettingsDropdown from "./SettingsDropdown"

import SkinOptions from "./SettingsList"

const useStyles = makeStyles(theme => ({
    skin: {
        position: "absolute",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        fontSize: 'inherit',
        width: '1.15em',
        height: '1.15em',
        borderRadius: '1em',
        backgroundColor: props => { return props.backgroundColor },
        color: props => { return props.color },
        margin: "-0.075em"
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: '0.75em',
        fontWeight: "bold",
        lineHeight: '1',
        textAlign: "center",
    }
}));

const SkinIcon = ({style}) => {
    const classes = useStyles(style)
    return (
        <Paper className={classes.skin} elevation={1}>
            <b className={classes.label}>A</b>
        </Paper>
    )
}

const getOptions = ({options = [], styles = {}}) => {
    let _options = [], _byValue = {};

    options.map((item, index) => {

        if (typeof item === "string") {
            item = { label: item, value: item }
        }

        const style = styles && styles[item.value];

        item = {
            ...item,
            icon: SkinIcon({style}),
            style: style
        }

        _options.push(item)
        _byValue[item.value] = item
        

    })    

    _options = _.orderBy(_options, 'order', 'desc'); 

    return {
        byValue: _byValue,
        options: _options,
    }

}

const SettingsButtonRef = React.forwardRef((props, ref) => {
    return <SettingsButton {...props} forwardedRef={ref} />;
});

const SettingsSkin = ({type = "skin", name, label, value, onChange, ...props}) => {
    const [expanded, setExpanded] = React.useState(false);
    const anchorRef = React.useRef(null);

    const { options, byValue } = getOptions(props)
    const current = byValue[value] || options[0]

    const onToggle = (event) => {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const onSelect = (item) => {
        if (onChange) {
            onChange(name, item.value)
        }
        setExpanded(false);
    }

    const onCollapse = () => {
        setExpanded(false);
    };


    return (
        <React.Fragment>
            <SettingsButtonRef {...current} type={type} onToggle={onToggle} expanded={expanded} ref={anchorRef} />
            <SettingsDropdown expanded={expanded} anchorEl={anchorRef.current} onCollapse={onCollapse}>
                <SkinOptions value={current && current.value} options={options} onSelect={onSelect} />
            </SettingsDropdown>
        </React.Fragment>            
    )

}

export default SettingsSkin;