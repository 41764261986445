import React, { Component } from 'react';
import InboxHeader from "./InboxHeader"

const LayoutInboxParent = ({formData, formContext, children}) => {
    
    return (
        <article className="admin-inbox-editor">
            <InboxHeader {...formData} onSelect={formContext && formContext.onSelect} />
            { children }
        </article>
    )

}

export default LayoutInboxParent