export default {
    "type": "object",
    "properties": {
        "sectionType": {
            "enum": ["chatText"]
        },
        "message": {
            "type": "localizedString"
        },
    }
}