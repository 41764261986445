import { isObject } from "./"

export function getUiOptions(uiSchema) {
    // get all passed options from ui:widget, ui:options, and ui:<optionName>
    return Object.keys(uiSchema)
        .filter(key => key.indexOf("ui:") === 0)
        .reduce((options, key) => {
            const value = uiSchema[key];

            if (key === "ui:widget" && isObject(value)) {
                console.warn(
                    "Setting options via ui:widget object is deprecated, use ui:options instead"
                );
                return {
                    ...options,
                    ...(value.options || {}),
                    widget: value.component,
                };
            }
            if (key === "ui:options" && isObject(value)) {
                return { ...options, ...value };
            }
            return { ...options, [key.substring(3)]: value };
        }, {});
}