export default {
    "ui:fieldset": [
        "metadata"
    ],
    "metadata": {
        "ui:layout": "document",
        "ui:nav": "menu",
        "ui:fieldset": [
            "location",
            "keywords",
            "license"
        ],
        "location": {
            "ui:layout": "section",
            "ui:title": "Lokasjon",
            "ui:icon": "near_me",
            "ui:fieldset": [
                "geocoder",
                "geoinput"
            ],
            "geocoder": {
                "ui:layout": "geocoder",
                "ui:fieldset": [
                    "placeId", "lat", "lng", "zoom"
                ],
                "ui:options": {
                    "placeholder": "Finn sted"
                }
            },
            "geoinput": {
                "ui:grid": 3,
                "ui:fieldset": [
                    "placeId", "lat", "lng", "zoom", "address", "country"
                ],
                "lat": {
                    "ui:cols": 1
                },
                "lng": {
                    "ui:cols": 1
                },
                "zoom": {
                    "ui:cols": 1
                }
            }
        },
        "keywords": {
            "ui:layout": "section",
            "ui:title": "Stikkord",
            "ui:icon": "label"
        },
        "license": {
            "ui:layout": "section",
            "ui:title": "Lisenser",
            "ui:icon": "copyright"
        },

    }
}