import React, { useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    googlemap: {
        backgroundColor: "#e5e3df",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%"
    }
}));

const GeopointMap = ({className, center, zoom, onChange, options, children}) => {
    const [map, setMap] = useState(null) 

    const handleChange = () => {
        map && onChange && onChange(map)
    }

    const classes = useStyles()
    
    return (
        <GoogleMap
            id="mymap"
            options={options}
            clickableIcons={false}
            mapContainerClassName={className || classes.googlemap}
            center={center}
            zoom={zoom}
            onLoad={(map) => setMap(map)}
            onZoomChanged={handleChange}
            onDragEnd={handleChange}
        >
            {children}
        </GoogleMap>
    )        

}

GeopointMap.defaultProps = {
    options: {
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false
    },
    center: {
        lat: undefined,
        lng: undefined,
    },
    zoom: 12
}

export default GeopointMap;