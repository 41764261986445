export default {
    "ui:fieldset": [
        "uniqueId",
        "content"
    ],
    "ui:options": {
        "grid": true,
        "spacing": 2
    },
    "content": {
        "ui:spacing": 2,
        "ui:fieldset": [
            "filedata"
        ],
        "filedata": {
            "ui:fieldset": [
                "name",
                "size",
                "type"
            ],
        }
    }
}