import React from 'react';
import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
    },
}));

const StatusDate = ({className, datetime, locale = "nb", format = "relative", children}) => {
    const classes = useStyles()

    if (!datetime && children) {
        datetime = children
    }

    if (!datetime) {
        return false
    }

    // format value

    moment.locale(locale)

    let date;
    
    if (format === "relative") {
        date = moment(datetime).fromNow()
    } else if (format) {
        date = moment(datetime).format(format)
    }

    return (
        <time datetime={datetime} className={className || classes.root}>{date}</time>
    )
}

StatusDate.propTypes = {
    datetime: PropTypes.string,
    locale: PropTypes.string,
    format: PropTypes.string,
};

export default StatusDate;