export default {
    "type": "object",
    "properties": {
        "title": {
            "type": "string",
        },
        "type": {
            "type": "string",
            "enum": ["create", "upload"],
            "default": "create"
        }
    },
    "dependencies": {
        "type" : {
            "oneOf": [
                {
                    "properties": {
                        "type": {
                            "enum": ["create"]
                        },
                        "icon": {
                            "type": "string",
                        },
                        "documentType": {
                            "type": "string"
                        }
                    }
                },
                {
                    "properties": {
                        "type": {
                            "enum": ["upload"]
                        },
                        "icon": {
                            "type": "string",
                        },
                        "mediaType": {
                            "type": "string"
                        }
                    }
                }
            ]
        }
    }
}    