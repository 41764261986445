import { API } from '../settings';
import { requestApp } from '../actions/app';
import { requestCollection, receiveCollection } from '../actions/collection';

export function getSiteApp(props) {
    const { appData, site } = props;
    
    let url = API + '/admin/api/collections/search?collectionType=' + appData.collectionType + "&siteId=" + site.id;

    return function (dispatch) {
        dispatch(requestCollection());
        dispatch(requestApp(appData));


        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(
          data => dispatch(getSiteCollections(data, props))
        )

    }
    
}

export function getSiteCollections(data, props) {
    const { models } = data;

    let collection;
    
    if (models && models[0] && models[0].uniqueId) {
        collection = models[0]
    }
    
    return function (dispatch) {
        if (collection) {
            dispatch(receiveCollection(collection))
        } else {
            dispatch(createSiteCollection(props))
        }
    }
    
}

export function createSiteCollection(props) {
    const { appData, site } = props;

    const collectionType = appData.collectionType
    const appName = collectionType + ":" + site.name;
    
    let data = {
        siteId: site.id,
        imageUrl: "",
        collectionType: collectionType,
        defaultLocale: "en",
        name: appName,
        title: appName,
        description: "",
        content: {
            collectionType: collectionType,
            siteId: site.id,
            title: appName,
            name: appName
        }
    }

      const url = API + '/admin/api/collections';
      const payload = JSON.stringify(data);
    
      return function (dispatch) {
    
        fetch(url, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
          body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(receiveCollection(data))
        )
      
      }
    
}