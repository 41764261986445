const initialState = {
	name : "Rockheim",
}

// app state

const owner = (state = initialState, action) => {

	switch (action.type) {

    case 'REQUEST_OWNER':

  		return Object.assign({}, state, {
  		})

    case 'RECEIVE_OWNER_DATA':
    
      return action.data
  	
    default:

      return state

    }

}

export default owner;