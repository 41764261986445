import React from 'react';
import UploadDropzone from '../Upload/UploadDropzone';
import LayoutButtons from "../Layout/LayoutButtons";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        border: "1px solid",
        borderColor: theme.palette.divider,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}))

const MediaUpload = ({className, mediaType, buttons, uploadProgress, onUpload, ...props}) => {

    const classes = useStyles({mediaType})

    if (uploadProgress) {
        return (
            <div className={className || classes.root}>
                { uploadProgress + "/100"}
            </div>
        )
    }

    if (buttons && buttons.length) {

        buttons = buttons.map((button, index) => {

            if (index > 0) {
                return {
                    ...button,
                    variant: "text",
                    color: "inherit"
                }
    
            }

            return {
                ...button,
                variant: "outlined",
                color: "inherit"
            }
        })


        return (
            <div className={className || classes.root}>
                <LayoutButtons {...props} buttons={buttons} />
            </div>
        )
    }

    return (
        <UploadDropzone {...props} onUpload={onUpload}>
            <div className={className || classes.root}>
                Upload media
            </div>
        </UploadDropzone>
    )

}

export default MediaUpload;