import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import ModuleBase from "../Module/ModuleBase"
import ModuleDate from "../Module/ModuleDate"
import ModuleLabel from "../Module/ModuleLabel"

import InboxAttachment from "./InboxAttachment"
import InboxModuleHeader from "./InboxModuleHeader"

import getInboxModule from "./utils/getInboxModule"

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        width: "100%",
        minHeight: theme.spacing(7),
        position: "relative",
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(1.5),

        "& + $root": {
            borderTop: "1px solid",
            borderColor: "#f2f2f2",
        },

        "&:hover": {
            backgroundColor: "#f2f2f2",
            cursor: "pointer",
            "& $subject": {
                textDecoration: "underline"
            }
        }

    },
    footer: {
        marginTop: theme.spacing(1.5),
//        marginBottom: theme.spacing(2.5),
        paddingLeft: theme.spacing(22),
    },
    content: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    header: {
        minWidth: theme.spacing(22),
        maxWidth: theme.spacing(22),
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
    },
    body: {
        display: "inline-block",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    subject: {
        display: "inline",
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
    },
    excerpt: {
        display: "inline",
        fontFamily: "Akkurat, sans-serif",
        marginLeft: theme.spacing(.5),
        opacity: ".85"
    },
    datetime: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        whiteSpace: "noWrap",
        opacity: ".75"
    },


}));

const InboxAuthor = ({unread, shortName, name, size}) => {
    const classes = useStyles()

    if (size === "small") {
        return <b className={classes.name} data-unread={unread}>{shortName}</b>
    }

    return <b className={classes.name} data-unread={unread}>{name}</b>

}

const InboxAuthors = ({authors = []}) => {
    const classes = useStyles()

    if (!authors.length) {
        return false
    }

    if (authors.length > 2) {
        return (
            <Typography noWrap className={classes.authors}>
                <InboxAuthor {...authors[0]} size="small" />
                 ...
                 <InboxAuthor {...authors[authors.length-1]} size="small" />
            </Typography>
        )
    }

    if (authors.length === 2) {
        return (
            <Typography noWrap className={classes.authors}>
                <InboxAuthor {...authors[0]} size="small" />,
                <InboxAuthor {...authors[authors.length-1]} size="small" />
            </Typography>
        )
    }

    return (
        <Typography noWrap className={classes.authors}>
            <InboxAuthor {...authors[0]} />
        </Typography>
    )

}

const InboxModule = ({onSelect, ...props}) => {
    const { datetime, subject, message, attachments, label, labelColor } = getInboxModule(props)

    const classes = useStyles()

    const handleSelect = (event, item) => {
        event.stopPropagation();
        onSelect && onSelect(item)
    }

    return (
        <ModuleBase className={classes.root} onClick={(e) => handleSelect(e, props)}>
            <div className={classes.content}>
                <InboxModuleHeader {...props} className={classes.header} />
                <ModuleLabel label={label} color={labelColor} />
                <Typography noWrap className={classes.body}>
                    <b className={classes.subject}>{subject}</b>
                    <i className={classes.excerpt}>{message}</i>
                </Typography>
                <ModuleDate className={classes.datetime} datetime={datetime} />
            </div>

            { attachments && <footer className={classes.footer}>
                {attachments.map((attachment, index) => {
                    return (
                        <InboxAttachment {...attachment} size="small" onClick={(e) => handleSelect(e, attachment)} key={index} />
                    )
                })}
            </footer> }
         </ModuleBase>
    )

}

export default InboxModule;