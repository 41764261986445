import React from 'react';
import Button from './Button';

import './ButtonTertiary.scss';

class ButtonTertiary extends React.Component {
    render() {
        return (
            <Button {...this.props} className="admin-button  admin-button--tertiary" />
        );
    }
}

export default ButtonTertiary;
