import React from 'react';
import PropTypes from "prop-types"
import Dropzone from 'react-dropzone'

const UploadDropzone = ({className, accept, multiple, onUpload, children}) => {

    const handleDrop = (acceptedFiles) => {
        onUpload && onUpload(acceptedFiles)
    }

    return (
        <Dropzone multiple={multiple} accept={accept} onDrop={handleDrop}>
            {({getRootProps, getInputProps}) => (
                <div {...getRootProps()} className={className}>
                    <input {...getInputProps()} />
                    {children}
                </div>
            )}
        </Dropzone>    
    )
    
}

UploadDropzone.propTypes = {
    onUpload: PropTypes.func
}

UploadDropzone.defaultProps = {
    multiple: true,
    accept: "image/*, video/*, audio/*",
}

export default UploadDropzone