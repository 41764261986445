import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
    return (
        <SvgIcon>
            <path d="M20 21L20 19L4 19L4 21L20 21Z" fill="black"/>
            <path d="M4 12C4 13.6569 5.34315 15 7 15V15C8.65685 15 10 13.6569 10 12V12C10 10.3431 8.65685 9 7 9V9C5.34315 9 4 10.3431 4 12V12Z" fill="black"/>
        </SvgIcon>
    );
}