import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import DialogHeader from "./DialogHeader"
import DialogBody from "./DialogBody"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    header: {
    },
    body: {
    }
}));

const Dialog = ({children, padding = 0, ...props}) =>{

    const classes = useStyles()

    return (
        <section className={classes.root}>
            <DialogHeader {...props}  />
            <DialogBody padding={padding}>
                {children}
            </DialogBody>
        </section>
    )

}

Dialog.propTypes = {
    title: PropTypes.string,
    padding: PropTypes.number,
    search: PropTypes.shape({
        placeholder: PropTypes.string,
        q: PropTypes.string,
        onChange: PropTypes.func,
    }),
    onClose: PropTypes.func
}


export default Dialog