import React, { Component } from 'react';
import Button from './Button';

import './ButtonOption.scss';

class ButtonOption extends Component {
    render() {
        return (
            <Button {...this.props} className="admin-button  admin-button--option" />
        );
    }
}

export default ButtonOption;
