import React, { Component } from 'react';
//import { ResponsivePie } from '@nivo/pie'
import { ResponsivePie } from './pie'

import "./ChartLine.scss"

import PieTooltip from "./ChartPieTooltip"
import PieLegend from "./ChartPieLegend"

class ChartPie extends Component {

    static defaultProps = {
        data: [],
        theme: {},

        innerRadius: 0.25,
        padAngle: 1,

        cornerRadius: 0,
        borderWidth: 0,
        
        enableRadialLabels: false,
        enableSlicesLabels: false,
        
        sortByValue: false,

        margin: {
            top: 0, 
            right: 0, 
            bottom: 0, 
            left: 0
        },
        
        animate: false,
        isInteractive: true

    }
    
    getProps = () => {
        const { renderTooltip } = this.props;
        
        return {
            ...this.props,
        }
        
    }
    
    renderTooltip = (slice) => {
        const { data, colors } = this.props;
        
        if (this.props.renderTooltip) {
            return this.props.renderTooltip({data: data, colors: colors, slice: slice});
        }

        return (
            <PieTooltip {...this.props} slice={slice} />
        )
        
    }    
    
    render() {
        const props = this.getProps()
        
        return (
            <ResponsivePie {...props} />
        )
    
    }

}

export default ChartPie