export const REQUEST_EDITOR = 'REQUEST_EDITOR'
export const RECEIVE_EDITOR = 'RECEIVE_EDITOR'

export const REQUEST_EDITOR_PROPS = 'REQUEST_EDITOR_PROPS'
export const RECEIVE_EDITOR_PROPS = 'RECEIVE_EDITOR_PROPS'

export const REQUEST_EDITOR_LOAD = 'REQUEST_EDITOR_LOAD'
export const RECEIVE_EDITOR_LOAD = 'RECEIVE_EDITOR_LOAD'

export const REQUEST_EDITOR_SAVE = 'REQUEST_EDITOR_SAVE'
export const RECEIVE_EDITOR_SAVE = 'RECEIVE_EDITOR_SAVE'

export const REQUEST_EDITOR_EDIT = 'REQUEST_EDITOR_EDIT'
export const RECEIVE_EDITOR_EDIT = 'RECEIVE_EDITOR_EDIT'

export const REQUEST_EDITOR_SCHEMA = 'REQUEST_EDITOR_SCHEMA'
export const RECEIVE_EDITOR_SCHEMA = 'RECEIVE_EDITOR_SCHEMA'

export const REQUEST_EDITOR_UISCHEMA = 'REQUEST_EDITOR_UISCHEMA'
export const RECEIVE_EDITOR_UISCHEMA = 'RECEIVE_EDITOR_UISCHEMA'

export const REQUEST_EDITOR_FORMDATA = 'REQUEST_EDITOR_FORMDATA'
export const RECEIVE_EDITOR_FORMDATA = 'RECEIVE_EDITOR_FORMDATA'

export const REQUEST_EDITOR_UNIQUE_ID = 'REQUEST_EDITOR_UNIQUE_ID'
export const RECEIVE_EDITOR_UNIQUE_ID = 'RECEIVE_EDITOR_UNIQUE_ID'

export const REQUEST_EDITOR_FORMCONTEXT = 'REQUEST_EDITOR_FORMCONTEXT'
export const RECEIVE_EDITOR_FORMCONTEXT = 'RECEIVE_EDITOR_FORMCONTEXT'

export const RECEIVE_EDITOR_CHANGE = 'RECEIVE_EDITOR_CHANGE'

export const REQUEST_EDITOR_PARENTS = 'REQUEST_EDITOR_PARENTS'
export const RECEIVE_EDITOR_PARENTS = 'RECEIVE_EDITOR_PARENTS'

export const REQUEST_EDITOR_ACTION = 'REQUEST_EDITOR_ACTION'
export const RECEIVE_EDITOR_ACTION = 'RECEIVE_EDITOR_ACTION'

export const REQUEST_EDITOR_LANGUAGES = 'REQUEST_EDITOR_LANGUAGES'
export const RECEIVE_EDITOR_LANGUAGES = 'RECEIVE_EDITOR_LANGUAGES'

export const REQUEST_EDITOR_CURRENT_ID = 'REQUEST_EDITOR_CURRENT_ID'
export const RECEIVE_EDITOR_CURRENT_ID = 'RECEIVE_EDITOR_CURRENT_ID'

export const REQUEST_EDITOR_LOCALE = 'REQUEST_EDITOR_LOCALE'
export const RECEIVE_EDITOR_LOCALE = 'RECEIVE_EDITOR_LOCALE'

export const REQUEST_EDITOR_DIALOG = 'REQUEST_EDITOR_DIALOG'
export const RECEIVE_EDITOR_DIALOG = 'RECEIVE_EDITOR_DIALOG'
export const RECEIVE_EDITOR_DIALOG_FORMDATA = 'RECEIVE_EDITOR_DIALOG_FORMDATA'

export const REQUEST_EDITOR_ERROR = 'REQUEST_EDITOR_ERROR'
export const RECEIVE_EDITOR_ERROR = 'RECEIVE_EDITOR_ERROR'

export const EDITOR_SECTION_TOGGLE = 'EDITOR_SECTION_TOGGLE'
export const EDITOR_SECTION_EXPAND = 'EDITOR_SECTION_EXPAND'
export const EDITOR_SECTION_COLLAPSE = 'EDITOR_SECTION_COLLAPSE'

export const REQUEST_EDITOR_REDIRECT = 'REQUEST_EDITOR_REDIRECT'
export const RECEIVE_EDITOR_REDIRECT = 'RECEIVE_EDITOR_REDIRECT'

export function requestEditor() {

    return {
        type: REQUEST_EDITOR,
    }
  
}

export function receiveEditor(editor) {

    return {
        type: RECEIVE_EDITOR,
        editor: editor
    }
  
}

// load

export function requestEditorLoad() {
    return {
        type: REQUEST_EDITOR_LOAD
    }
}

export function receiveEditorLoad(formData) {
    return {
        type: RECEIVE_EDITOR_LOAD,
        formData: formData
    }
}

// save

export function requestEditorSave() {
    return {
        type: REQUEST_EDITOR_SAVE
    }
}

export function receiveEditorSave(formData) {
    return {
        type: RECEIVE_EDITOR_SAVE,
        formData: formData
    }
}

// edit

export function requestEditorEdit(formData) {
    return {
        type: REQUEST_EDITOR_EDIT,
        formData: formData
    }
}

export function receiveEditorEdit(formData) {
    return {
        type: RECEIVE_EDITOR_EDIT,
        uniqueId: formData && formData.uniqueId,
        formData: formData
    }
}



export function receiveEditorProps(props) {

    return {
        type: RECEIVE_EDITOR_PROPS,
        props: props
    }
  
}

export function receiveEditorUniqueId(uniqueId) {

    return {
        type: RECEIVE_EDITOR_UNIQUE_ID,
        uniqueId: uniqueId
    }
  
}

// change

export function receiveEditorChange(formData) {

  return {
    type: RECEIVE_EDITOR_CHANGE,
    formData: formData
  }
  
}

// schema

export function requestEditorSchema() {

  return {
    type: REQUEST_EDITOR_SCHEMA
  }
  
}

export function receiveEditorSchema(schema) {

  return {
    type: RECEIVE_EDITOR_SCHEMA,
    schema: schema,
  }
  
}

// uiSchema

export function requestEditorUiSchema() {

  return {
    type: REQUEST_EDITOR_UISCHEMA
  }
  
}

export function receiveEditorUiSchema(uiSchema) {

  return {
    type: RECEIVE_EDITOR_UISCHEMA,
    uiSchema: uiSchema
  }
  
}

// formData

export function requestEditorFormData() {

    return {
      type: REQUEST_EDITOR_FORMDATA
    }
    
  }
  
export function receiveEditorFormData(formData) {
  
    return {
      type: RECEIVE_EDITOR_FORMDATA,
      formData: formData
    }
    
}

// formContext

export function requestEditorFormContext() {

    return {
      type: REQUEST_EDITOR_FORMCONTEXT
    }
    
  }
  
export function receiveEditorFormContext(formContext) {
  
    return {
      type: RECEIVE_EDITOR_FORMCONTEXT,
      formContext: formContext
    }
    
}

// redirect

export function requestEditorRedirect() {

    return {
        type: REQUEST_EDITOR_REDIRECT
    }
    
  }
  
export function receiveEditorRedirect(redirect) {
  
    return {
        type: RECEIVE_EDITOR_REDIRECT,
        redirect: redirect
    }
    
}

// action

export function requestEditorAction() {

    return {
        type: REQUEST_EDITOR_ACTION
    }
    
  }
  
export function receiveEditorAction(primaryAction) {
  
    return {
        type: RECEIVE_EDITOR_ACTION,
        primaryAction: primaryAction
    }
    
}
// languages

export function requestEditorLanguages() {

    return {
        type: REQUEST_EDITOR_LANGUAGES
    }
    
  }
  
export function receiveEditorLanguages(languages) {
  
    return {
        type: RECEIVE_EDITOR_LANGUAGES,
        languages: languages
    }
    
}

// parents

export function requestEditorParents() {

    return {
        type: REQUEST_EDITOR_PARENTS
    }
    
  }
  
export function receiveEditorParents(parents) {
  
    return {
        type: RECEIVE_EDITOR_PARENTS,
        parents: parents
    }
    
}

// currentId

export function requestEditorCurrentId() {

    return {
        type: REQUEST_EDITOR_CURRENT_ID
    }
    
  }
  
export function receiveEditorCurrentId(id) {
  
    return {
        type: RECEIVE_EDITOR_CURRENT_ID,
        id: id
    }
    
}

// locale

export function receiveEditorLocale(locale) {
  
    return {
        type: RECEIVE_EDITOR_LOCALE,
        locale: locale,
    }
    
}

// dialog

export function requestEditorDialog() {

    return {
      type: REQUEST_EDITOR_DIALOG
    }
    
  }
  
export function receiveEditorDialog(dialog) {
  
    return {
      type: RECEIVE_EDITOR_DIALOG,
      dialog: dialog
    }
    
}

export function receiveEditorDialogFormData(formData) {
  
    return {
        type: RECEIVE_EDITOR_DIALOG_FORMDATA,
        formData: formData
    }
    
}

// error

export function receiveEditorError(error) {

    return {
        type: RECEIVE_EDITOR_ERROR,
        error: error
    }
    
}