import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontFamily: "Akkurat, sans-serif !important"
    },
    overrides: {
        MuiButtonGroup: {
            grouped: {
            }
        },
        MuiButton: {
            root: {
                fontFamily: "Akkurat, sans-serif",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: 1,
            },
            outlined: {
            },
        }
    }
});

export default theme