import React, { Component } from 'react';
import PreviewLabel from "./PreviewLabel"

class PreviewArray extends Component {
    
    renderItem = (item, index) => {
        
        const { schema } = this.props;
        
        return (
            <p>{ JSON.stringify(item) }</p>
        )
        
    }
    
    render() {
      
      const { id, schema, formData } = this.props;

      return (
          <section className="admin-preview__field  field--array">
              <PreviewLabel {...this.props} />
              {this.props.children}
          </section>
      )

    }

}

export default PreviewArray;