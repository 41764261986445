export default {
    "type": "object",
    "properties": {
        "clientId": {
            "type": "number",
            "enum": [1,2,3],
            "enumNames": ["Norsk Folkemuseum","Maihaugen","Nasjonalmuseet"]
        },
        "guideFee": {
            "type": "boolean"
        },
        "payment": {
            "type": "string",
            "enum": ["creditcard","cash"],
            "enumNames": ["Kredittkort","Kontant"]
        },
    }
}