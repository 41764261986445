import React, { Component } from 'react';
import PropTypes from "prop-types";
import { AnalyticsChart } from "../"

class AnalyticsWidgetAcquisition extends Component {

    render() {

        return (
            <AnalyticsChart {...this.props} type="bar" stacked="true" />
        )

    }

}

export default AnalyticsWidgetAcquisition;