import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        color: "inherit",
    },
    figure: {
        width: props => { return props.size },
        height: props => { return props.size },
        margin: "12px",
        "& svg, & img": {
            boxSizing: "inherit",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            margin: "auto",
        }
    },
    img: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        maxWidth: "64px",
        maxHeight: "64px",
        margin: "auto"
    },
    label: {
        display: "block",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "12px",
        marginTop: "-1em",
    }
}));

const AppsGridItem = ({title, imageUrl, icon, size = "large", onClick}) => {

    const sizes = {
        "small": 36,
        "medium": 48,
        "large": 64
    }

    size = sizes[size] || size || 64
    
    const classes = useStyles({size})

    return (
        <ButtonBase className={classes.root} disableRipple={true} onClick={onClick}>
            <IconButton className={classes.figure} component="figure">
                { !icon && imageUrl && <img className={classes.img} src={imageUrl} /> }
                { icon }
            </IconButton>
            <Typography className={classes.label} noWrap>{title}</Typography>
        </ButtonBase>
    )

}

export default AppsGridItem;