import React, { Component } from 'react';
import moment from "moment"

import { ButtongroupInline, ButtonSecondary } from '../';
import { ModuleFooter } from '../Module';

class LayoutInboxCompose extends Component {
    
    static defaultProps = {
        formData: {}
    }

    onSave = (status) => {
        const { id, index, schema, formData, formContext } = this.props;
        
        const newFormData = {
            ...formData,
            createdAt: moment().format(),
        }
        
        if (formContext.onSaveMessage) {
            formContext.onSaveMessage({
                index: index,
                formData: newFormData
            })
        }
    }

    onDiscard = () => {
        const { id, index, schema, formData, formContext } = this.props;

        if (formContext.onDiscardDraft) {
            formContext.onDiscardDraft({
                index: index,
                formData: formData
            })
        }
    }

    renderFooter = () => {

        const saveButton = {
            title: "Send",
            onClick: () => this.onSave('new'),
            children: [
                {
                    title: "Save as draft",
                    onClick: () => this.onSave('draft')
                },
                {
                    title: "Save and publish",
                    onClick: () => this.onSave('publish')
                }
            ]
        }
        
        const submitButton = {
            title: "Save",
            onClick: this.onSave
        }

        const discardButton = {
            title: "Discard",
            onClick: this.onDiscard
        }
        
        return (
            <ModuleFooter>
                <ButtongroupInline>
                    <ButtonSecondary {...submitButton} />
                    <ButtonSecondary {...discardButton} />
                </ButtongroupInline>
            </ModuleFooter>
        )
        
    }
        
    render() {
        return (
            <article className="admin-inbox-editor__compose">
                { this.props.children }
                { this.renderFooter() }
            </article>
        )

    }

}

export default LayoutInboxCompose