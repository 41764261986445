import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LayoutFormat from "../Layout/LayoutFormat"
import LayoutGrid from "../Layout/LayoutGrid"

import ModuleBase from "./ModuleBase"
import ModuleHeader from "./ModuleHeader"
import ModuleBody from "./ModuleBody"
import ModuleFooter from "./ModuleFooter"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        zIndex: 2,

        "& + *": {
            zIndex: 1
        },

        display: "flex",
        flexDirection: "column",

    },
    header: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: "auto",
        left: 0,
        display: "flex",
        zIndex: 2,
        alignItems: "center",
        justifyContent: "flex-start",
        height: theme.spacing(7),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: "inherit",

        "& + *": {
            zIndex: 1,
            marginTop: theme.spacing(7)
        }

    },
    body: {
        flexGrow: 1,
        marginTop: theme.spacing(7),
        position: "relative",
        padding: props => { return theme.spacing(props.padding) },
    },
    footer: {
        borderTop: "1px solid",
        borderColor: theme.palette.divider,
        padding: props => { return theme.spacing(props.padding) }
    }

}));

const EditModule = ({id, status, format, padding = 2, buttons = [], onSave, toolbar, children, _action, ...props }) => {
    const classes = useStyles({padding})

    if (!buttons.length) {
        buttons.push({
            label: "Save",
            onClick: onSave
        })
    }

    if (!buttons.length && !children) {
        return false
    }

    toolbar = toolbar.map(item => {
        if (item.type === "edit") {
            return false
        }

        return item

    })

    return (
        <ModuleBase className={classes.root} id={id} expanded={true} elevation={4} status={status}>
            <LayoutFormat {...props}>
                <ModuleHeader {...props} className={classes.header} status={status} toolbar={toolbar} />
                {children}
            </LayoutFormat>
            <ModuleFooter {...props} className={classes.footer} buttons={buttons} />
        </ModuleBase>
    )    

    return (
        <ModuleBase className={classes.root} id={id} expanded={true} elevation={4} status={status}>
            <LayoutFormat {...props}>
                <ModuleHeader {...props} className={classes.header} status={status} toolbar={toolbar} />
                <ModuleBody className={classes.body} padding={padding}>
                    {children}
                </ModuleBody>
            </LayoutFormat>
            <ModuleFooter {...props} className={classes.footer} buttons={buttons} />
        </ModuleBase>
    )    

}

EditModule.propTypes = {
    onSave: PropTypes.func,
    menu: PropTypes.array,
    title: PropTypes.string,
    children: PropTypes.node
}

export default EditModule;
