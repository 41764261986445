import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
    list: {
        position: "relative",
        listStyle: "none",
        padding: theme.spacing(.5),
        margin: 0,
    },
    item: {
        display: "block",
        minWidth: theme.spacing(20),
        "&[data-variant=text] + [data-variant=outlined]": {
            marginTop: theme.spacing(.5)
        }
    },
    button: {
        borderRadius: 0,
        "&.MuiButton-text": {
            padding: theme.spacing(1),
            justifyContent: "flex-start",
        },
        "&.MuiButton-outlined": {
            padding: theme.spacing(1),
            justifyContent: "center"
        }
    },
    icon: {
        color: "inherit",
        "& + $label": {
            marginLeft: theme.spacing(1)
        }
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: '14px',
        lineHeight: '24px',
        textTransform: 'none',
        letterSpacing: 0,
    }
}));

const ListItem = ({style, value, icon, label, selected, variant = "text", color, onClick, ...props}) => {
    const classes = useStyles(props)
    return (
        <li className={classes.item} data-variant={variant}>
            <Button className={classes.button} aria-selected={selected} variant={variant} value={value} color={color} fullWidth={true} onClick={onClick}>
                { icon && <Icon className={classes.icon}>{icon}</Icon> }
                <b className={classes.label}>{label}</b>
            </Button>
        </li>
    )
}

const List = ({children, ...props}) => {
    const classes = useStyles(props)
    return (
        <ul className={classes.list}>
            {children}
        </ul>
    )
}

const OptionsList = ({cols, rows, options = [], onSelect, ...props}) => {

    return (
        <List>
            {options.map((item, index) => (
                <ListItem {...item} key={index} onClick={() => onSelect(item)}>{item.value}</ListItem>
            ))}
        </List>
    )

}

export default OptionsList;