import React, { Component } from 'react';
import NavSettings from "../NavSettings/NavSettings"
import NavToolbar from "../NavToolbar/NavToolbar"
import ButtonSave from "../NavToolbar/ButtonSave"
import NavTabs from "../NavTabs/NavTabs"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        zIndex: 2,
        alignItems: "center",
        justifyContent: "flex-end",
        height: theme.spacing(7),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: "inherit"
    },
    navTabs: {
        flexGrow: 1,
//        flexBasis: "100%",
        marginLeft: theme.spacing(1),
        color: "inherit"
    },
    navSettings: {
        display: "flex",
        margin: theme.spacing(1),
    },
    navToolbar: {
        display: "flex",
        color: "inherit"
    },
}));

const MediaToolbar = ({editing, toolbar, settings, onEdit, onSave, menu}) => {
    const classes = useStyles()

    toolbar = toolbar.map(item => {
        if (item.type === "edit") {
            return false
        }

        return item

    })

    return (
        <header className={classes.root}>
            { menu && <NavTabs className={classes.navTabs} tabs={menu} />}
            { settings && <NavSettings className={classes.navSettings} settings={settings} /> }
            { toolbar && <NavToolbar className={classes.navToolbar} toolbar={toolbar} /> }
        </header>
    )

}

export default MediaToolbar;