import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"

import SettingsButton from "./SettingsButton"
import SettingsDropdown from "./SettingsDropdown"
import SettingsList from "./SettingsList"

import ISO6391 from 'iso-639-1'

const useStyles = makeStyles(theme => ({
    icon: {
        position: "absolute",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        fontSize: 'inherit',
        width: '1.25em',
        height: '1.25em',
        border: "1px solid",
        borderColor: "#ccc",
        margin: "-0.125em"
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: '0.5em',
        fontWeight: "bold",
        textTransform: "uppercase",
        lineHeight: '1',
        textAlign: "center",
    }
}));

const LocaleIcon = ({value}) => {
    const classes = useStyles()
    return (
        <div className={classes.icon}>
            <b className={classes.label}>{value}</b>
        </div>
    )
}

const getOptions = ({options = [], styles = {}}) => {
    let _options = [], _byValue = {};

    options.map((item, index) => {

        if (typeof item === "string") {
            item = {
                label: ISO6391.getNativeName(item) || item,
                value: item
            }
        }

        const style = styles && styles[item.value];

        item = {
            ...item,
            icon: LocaleIcon(item),
            style: style
        }

        _options.push(item)
        _byValue[item.value] = item
        

    })    

    _options = _.orderBy(_options, 'order', 'desc'); 

    return {
        byValue: _byValue,
        options: _options,
    }

}

const SettingsButtonRef = React.forwardRef((props, ref) => {
    return <SettingsButton {...props} forwardedRef={ref} />;
});

const LocaleSettings = ({type = "locale", name, label, value, onChange, ...props}) => {
    const [expanded, setExpanded] = React.useState(false);
    const anchorRef = React.useRef(null);

    const { options, byValue } = getOptions(props)
    const current = byValue[value] || options[0]

    const onToggle = (event) => {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const onSelect = (item) => {
        if (onChange) {
            onChange(name, item.value)
        }
        setExpanded(false);
    }

    const onCollapse = () => {
        setExpanded(false);
    };


    return (
        <React.Fragment>
            <SettingsButtonRef {...current} type={type} onToggle={onToggle} expanded={expanded} ref={anchorRef} />
            <SettingsDropdown expanded={expanded} anchorEl={anchorRef.current} onCollapse={onCollapse}>
                <SettingsList value={current && current.value} options={options} onSelect={onSelect} />
            </SettingsDropdown>
        </React.Fragment>            
    )

}

export default LocaleSettings;