export default {
    "select": {
//        "icon": "icon",
        "title": "title"
    },
    prepare({formData}) {
        const localeId = "locale:no"
        const title = formData.title && formData.title[localeId] || "Untitled"

        const sectionType = formData.sectionType;

        const icons = {
            "text": "subject",
            "media": "image",
            "objects": "museum",
        }

        return {
//            icon: icons && icons[sectionType],
            title: title,
        }

    }
}