import React from "react"
import { retrieveSchema, mergeObjects, toIdSchema, getDefaultRegistry, getUiOptions } from './';

export function getFieldType(props) {
    const type = props && props.schema && props.schema._type || props && props.schema && props.schema.type;
    const registry = props && props.registry || getDefaultRegistry()
    const { fieldtypes, fields } = registry;
    
    let field = {}

    if (type && type in fieldtypes) {
        const getField = fieldtypes[type];
        field = getField({...props})
        
        field.schema = {
            ...field.schema,
            _type: type
        }

    } else {
        field = props
    }
    
    return field
}