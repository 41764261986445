export default {
  "select": {
    "imageUrl": "imageUrl",
    "title": "title",
    "name": "name",
    "label": "label",
    "description" : "description",
  },
  prepare({formData}) {
    let { isSystemAdmin, name, authUsername, authorizedCollections = [], authorizedSites = [], content } = formData

    // metadata

    let metadata = [];

    authorizedSites.length && metadata.push(authorizedSites.length + ' sites')
    authorizedCollections.length && metadata.push(authorizedCollections.length + ' apps')

    // imageUrl
    
    let imageUrl;
    
    if (content && content.avatar && content.avatar.media && content.avatar.media.imageUrl) {
        imageUrl = content.avatar.media.imageUrl;
    }

    const title = name
    const description = metadata.length && metadata.join(' – ') || authUsername

    // label

    let label;

    if (isSystemAdmin) {
        label = "User/Admin"
    } else if (metadata.length) {
        label = "User/Auth"
    } else {
        label = "User"
    }

    return {
        imageUrl: imageUrl,
        title: title,
        name: name,
        label: label,
        description: description,
    }
    
  }
  
}