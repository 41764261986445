import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LayoutFinder, MenuList, MenuItem, MenuLink } from "@frontend-components/admin"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: "white",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        padding: theme.spacing(2,2,2,1)
    },
}));

const PreviewMenuItem = ({children, hidden, ...props}) => {

    const label = props.title + " – " + props.url

    if (children) {
        return (
            <MenuItem {...props} expanded={true}>
                { !hidden && <MenuLink {...props} label={label} expanded={true} /> }
                <MenuList expanded={true}>
                    { children.map(child => <PreviewMenuItem {...child} />)}
                </MenuList>
            </MenuItem>
        )
    }

    if (hidden) {
        return false
    }

    return (
        <MenuItem {...props}>
            <MenuLink {...props} label={label} />
        </MenuItem>
    )

}

const PreviewCollectionMenu = ({app}) => {

    const classes = useStyles()

    const menu = app.menu;
    

    return (
        <LayoutFinder title="Menu">
            <MenuList>
                {menu && menu.map(item => <PreviewMenuItem {...item} />)}
            </MenuList>
        </LayoutFinder>
    )

}

function mapStateToProps(state) {
	return {
        formData: state.editor.formData,
        formContext: state.editor.formContext,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewCollectionMenu);
