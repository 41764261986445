import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NavSearch from "../NavSearch/NavSearch"
import ButtonClose from "./ButtonClose"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "absolute",
        width: "100%",
        height: theme.spacing(8),
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: "auto",
        left: 0
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "18px",
        fontWeight: "bold",
        marginLeft: theme.spacing(3),
    },
    close: {
        position: "absolute",
        top: 0,
        right: 0,
        margin: theme.spacing(1)
    },
    search: {
        display: "flex",
        position: "relative",
        backgroundColor: "inherit",
        color: "inherit",
        width: "100%",
        height: theme.spacing(8),
        overflow: "hidden",
        fontFamily: "Akkurat, sans-serif"
    }
}));

const DialogHeader = ({className, title, search, onClose}) =>{

    const classes = useStyles()

    return (
        <Paper component="header" elevation={1} square={true} className={className || classes.root}>
            { title && <Typography className={classes.title}>{title}</Typography> }
            { !search && <ButtonClose className={classes.close} onClick={onClose} /> }
            { search && <NavSearch {...search} className={classes.search} onReset={onClose} /> }
        </Paper>
    )

}

DialogHeader.propTypes = {
    title: PropTypes.string,
    search: PropTypes.shape({
        placeholder: PropTypes.string,
        q: PropTypes.string,
        onChange: PropTypes.func,
    })
}


export default DialogHeader