import React from 'react';

import { AppBase, AppSidebar, AppContent } from "@frontend-components/admin"
import { FinderBase, FinderHeader, FinderContent } from "@frontend-components/admin"
import { NavAction, NavPath, NavSettings } from "@frontend-components/admin"
import { NavCalendar } from "../components"

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    content: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        marginTop: theme.spacing(8)
    },
    navAction: {
        position: "relative",
        zIndex: 2,
        margin: theme.spacing(3)
    },
    navMenu: {
        overflowY: "scroll",
        marginRight: theme.spacing(3)
    },
    navCalendar: {
        margin: theme.spacing(1)
    },
    navCategories: {
        margin: theme.spacing(3)
    }
}));

const NavCategories = () => {

    const classes = useStyles()

    return (
        <nav className={classes.navCategories}>
            <ul>
                <li>Navnedager</li>
            </ul>
        </nav>
    )

}

const CalendarLayout = ({primaryAction, query: {date, year, month}, onSelect, children, title, parents, settings}) => {

    const classes = useStyles()



    return (
        <FinderBase>
            <AppContent>
                <FinderHeader>
                    <NavPath parents={parents} title={title} onSelect={onSelect} />
                    { settings && <NavSettings settings={settings} /> }
                </FinderHeader>
                <div className={classes.content}>
                    {children}
                </div>
            </AppContent>
        </FinderBase>
    )

    return (
        <AppBase>
            <AppSidebar width={240}>
                <NavAction className={classes.navAction} primaryAction={primaryAction} onSelect={onSelect}  />
                <NavCalendar className={classes.navCalendar} date={date} onSelect={onSelect} />
                <NavCategories />


            </AppSidebar>
            <AppContent>
                <FinderHeader>
                    <NavPath parents={parents} title={title} onSelect={onSelect} />
                    { settings && <NavSettings settings={settings} /> }
                </FinderHeader>
                <div className={classes.content}>
                    {children}
                </div>
            </AppContent>
        </AppBase>
    )

}

export default CalendarLayout