import React, { Component } from 'react';
import moment from "moment"
import InboxMessage from "./InboxMessage"

class InboxEditorChild extends Component {
    
    static defaultProps = {
        buttons: [],
        settings: []
    }
    
    state = {
        editing: false,
        expanded: false,
        replying: false,
        prevFormData: {},
        reply: {}
    }
    
    onToggle = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    onEdit = () => {
        const { id, index, schema, formData, formContext } = this.props;

        this.setState({
            prevFormData: formData,
            editing: true
        })

        if (formContext.onEditMessage) {
            formContext.onEditMessage({
                index: index,
                formData: formData
            })
        }

    }

    onChangeMessage = (message) => {
        const { index, formData, formContext } = this.props;

        const newFormData = {
            ...formData,
            message: message
        }
        
        if (this.props.onChange) {
            this.props.onChange(newFormData)
        }

        if (formContext.onChangeMessage) {
            formContext.onChangeMessage({
                index: index,
                formData: newFormData
            })
        }

    }

    onDiscardDraft = () => {
        const { id, index, schema, formData, formContext } = this.props;

        if (formContext.onDiscardDraft) {
            formContext.onDiscardDraft({
                index: index,
                formData: formData
            })
        }
        
        if (this.props.onChange) {
            this.props.onChange(this.state.prevFormData)
        }

        this.setState({
            editing: false
        })
    }
    
    onReply = () => {
        const { id, index, schema, formData, formContext } = this.props;
        const { author } = formContext;
        
        const parentId = formData.uniqueId
        const replyTo = formData.author.name + " <" + formData.author.email + ">"   
        const replyFrom = author.name + " <" + author.email + ">"   
        const subject = "Re: " + formData.subject
        
        const reply = {
            parentId: parentId,
//            status: "draft",
            author: author,
            subject: subject,
            from: replyFrom,
            to: replyTo,
            message: ""
        }
        
        this.setState({
            replying: true,
            reply: reply
        })
        
    }

    onChangeReply = (message) => {
        
        this.setState({
            reply: {
                ...this.state.reply,
                message: message
            }
        })
        
    }
    
    onDiscardReply = () => {
        const { index, formContext } = this.props;
        const { reply } = this.state;

        if (formContext.onDiscardReply) {
            formContext.onDiscardReply({
                index: index,
                formData: reply
            })
        }
        
        this.setState({
            replying: false,
            reply: {
                expanded: false,
                message: ""
            }
        })
        
    }
    
    onSaveReply = (status) => {
        const { id, index, schema, formContext } = this.props;
        const { reply } = this.state;
        
        if (!status) {
            status = "draft"
        }
        
        let newFormData = {
            ...reply,
            createdAt: moment().format(),
            status: status
        }

        if (this.props.onAddAfter) {
            this.props.onAddAfter(newFormData)
        }

        if (formContext.onSaveReply) {
            formContext.onSaveReply({
                index: index,
                formData: newFormData
            })
        }
        
        this.setState({
            replying: false,
            reply: {}
        })
        
    }
    
    onSave = (status) => {
        const { id, index, schema, formData, formContext } = this.props;
        
        if (!formData.status) {
            status = "draft"
        }
        
        let newFormData = {
            ...formData,
            createdAt: formData.createdAt || moment().format(),
            updatedAt: moment().format(),
            status: status
        }

        if (this.props.onChange) {
            this.props.onChange(newFormData)
        }

        if (formContext.onSaveMessage) {
            formContext.onSaveMessage({
                index: index,
                formData: newFormData
            })
        }
        
        this.setState({
            expanded: false,
            editing: false
        })

    }
    
    onDelete = () => {
        const { id, index, schema, formData, formContext } = this.props;

        if (formContext.onDeleteMessage) {
            formContext.onDeleteMessage({
                index: index,
                formData: formData
            })
        }

        if (this.props.onDelete) {
            this.props.onDelete()
        }

        this.setState({
            expanded: false,
            editing: false
        })

    }
    
    getReplyButtons = () => {

        return [
            {
                label: "Send",
                onClick: () => this.onSaveReply(),
                children: [
                    {
                        label: "Save as draft",
                        onClick: () => this.onSaveReply('draft')
                    },
                    {
                        label: "Save and publish",
                        onClick: () => this.onSaveReply('publish')
                    },
                ]
            },
            {
                label: "Discard",
                onClick: () => this.onDiscardReply()
            }
        ]

    }
    
    getEditButtons = () => {

        return [
            {
                label: "Save",
                onClick: () => this.onSave(),
                children: [
                    {
                        label: "Save as open",
                        onClick: () => this.onSave('open')
                    },
                    {
                        label: "Save and close",
                        onClick: () => this.onSave('close')
                    }
                ]
            },
            {
                label: "Delete",
                onClick: () => this.onDelete()
            },
            {
                label: "Cancel",
                onClick: () => this.onDiscardDraft()
            }
        ]

    }

    getToolbar = () => {
        const { editable } = this.props
        
        const { reply } = this.state;
        
        if (reply.expanded) {
            return false
        }
        
        let toolbar = []

        toolbar.push({
            icon: "reply",
            onClick: () => this.onReply()
        })

        if (editable) {
            toolbar.push({
                icon: "edit",
                onClick: () => this.onEdit()
            })
        }
        
        return toolbar;
        
    }
    
    isFirstMessage = () => {
        const { index, formContext } = this.props;

        if (index === 0) {
            return true
        }
        
        return false        
    }
    
    isLastMessage = () => {
        const { index, formContext } = this.props;
        const { messagesCount } = formContext;

        if (index+1 === messagesCount) {
            return true
        }
        
        return false        
    }
    
    render() {
        const { formData } = this.props;
        const { editing, expanded, replying, reply } = this.state;
        
        const label = formData.label || formData.status || undefined

        const isFirstMessage = this.isFirstMessage() 
        const isLastMessage = this.isLastMessage() 

        const toolbar = this.getToolbar()

        let buttons;

        if (editing) {
            buttons = this.getEditButtons()
        } else if (replying) {
            buttons = this.getReplyButtons()
        } else if (isLastMessage) {
            buttons = [{
                label: "Reply",
                onClick: () => this.onReply(),
            }]
        }

        const preview = {
            ...formData,
            label: label,
            editing: editing,
            expanded: expanded || isLastMessage,
            replying: replying,
            toolbar: toolbar,
            buttons: buttons
        }


        return (
            <InboxMessage {...preview} reply={reply} onToggle={this.onToggle} onChangeMessage={this.onChangeMessage} onChangeReply={this.onChangeReply}/>
        )
        
    }

}

export default InboxEditorChild