export const getInboxLabel = ({ label, labelColor, appName, appColor, ownerName }) => {

    if (!label) {
        label = []

        if (appName) {
            label.push(appName);
        }        

        if (ownerName && ownerName !== "user") {
            label.push(ownerName)
        }

        if (label.length > 1) {
            label = label.join('/').toUpperCase()
        } else {
            label = label.join('/')
        }

        
    }

    // app
    
    if (!labelColor && appColor) {
        labelColor = appColor
    }

    return {
        label: label,
        labelColor: labelColor
    }
    
}

export default getInboxLabel