import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: theme.spacing(5.25),
        marginLeft: theme.spacing(-.5),
        marginRight: theme.spacing(-.5),
        color: "inherit",
    },
    icon: {
        fontSize: "18px",
        color: "inherit",
        "& + $label": {
            marginLeft: theme.spacing(.5)
        },
        "& > svg": {
            fontSize: "1em",
            fill: "currentColor"
        }
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: "1",
        textTransform: "uppercase",
    },
}));

const SettingsButton = ({style, icon, label, value, disabled, expanded, controls, onToggle, forwardedRef}) => {

    const classes = useStyles()

    if (icon) {
        return (
            <IconButton className={classes.button} ref={forwardedRef}
                disabled={disabled} 
                aria-controls={expanded ? controls : undefined}
                aria-expanded={expanded ? 'true' : undefined}
                aria-label="options"    
                aria-haspopup="menu"
                onClick={onToggle}>
                    <Icon className={classes.icon}>{icon}</Icon>
            </IconButton>
        )
    }

    return (
        <Button className={classes.button} style={style} ref={forwardedRef}
            disabled={disabled} 
            aria-controls={expanded ? controls : undefined}
            aria-expanded={expanded ? 'true' : undefined}
            aria-label="options"    
            aria-haspopup="menu"
            onClick={onToggle}>
                { icon && <Icon className={classes.icon}>{icon}</Icon> }
                <b className={classes.label}>{label || value}</b>
        </Button>
    )

}

export default SettingsButton