import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const AuthSiteField = ({authRoles, sitesById, ...props}) => {
    const { registry, formData, uiSchema, onChange } = props

    const siteId = formData && formData.site && formData.site.id;
    const site = sitesById[siteId] || formData && formData.site;

    const handleAuthRole = (role) => {
        const newFormData = ({
            site: {
                id: siteId
            },
            role: {
                id: role && role.id
            }
        })
        onChange && onChange(newFormData)
    }

    const handleAuthRemove = () => {
        const newFormData = ({
            site: {
                id: siteId
            },
            role: {}
        })
        onChange && onChange(newFormData)
    }

    const newUiSchema = {
        ...uiSchema,
        "ui:status": formData && formData.status,
        "ui:auth": site,
        "ui:onAuthRole": handleAuthRole,
        "ui:onAuthRemove": handleAuthRemove,
        "ui:role": formData && formData.role,
        "ui:roles": authRoles,
        "ui:layout": "authItem",
    }

    const { ObjectField } = registry.fields;

    return (
        <ObjectField {...props} uiSchema={newUiSchema} registry={registry} />
    )


}

// mapStateToProps

const mapStateToProps = (state) => {
	return {
        authRoles: state.authRoles.models,
        sitesById: state.sitesById,
	};
}  

// export default

export default connect(
    mapStateToProps,
)(AuthSiteField);