import documents from './documents';
import media from './media';
import mediaImage from './mediaImage';
import mediaVideo from './mediaVideo';
import mediaAudio from './mediaAudio';
import mediaUpload from './mediaUpload';
import search from './search';
import ekultur from './ekultur';
import trash from './trash';
import latest from './latest';

export default {
    "type": "array",
    "items": {
        "type": "object",
        "properties": {
            "title": {
                "type": "string",
            },
            "pathname": {
                "type": "string",
            },
            "type": {
                "type": "string",
                "enum": ["documents","media","media/image","media/video","media/audio","media/upload","ekultur"],
                "default": "documents"
            }
        },
        "dependencies": {
            "type" : {
                "oneOf": [
                    documents,
                    media,
                    mediaImage,
                    mediaVideo,
                    mediaAudio,
                    mediaUpload,
                    search,
                    ekultur,
                    latest,
                    trash
                ]
            }
        }
    }
}    