import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LayoutHeader from "./LayoutHeader"
import LayoutGrid from "./LayoutGrid"
import LayoutFooter from "./LayoutFooter"

const useStyles = makeStyles(theme => ({
    fieldset: {
        width: "100%",
        padding: props => { return theme.spacing(props.padding) }
    }
}));

const LayoutFieldset = ({name, grid = false, spacing = 1, padding = 0, toolbar, children, ...props}) => {
    const classes = useStyles({padding, spacing});

    return (
        <section className={classes.fieldset} data-layout="fieldset" data-name={name}>
            <LayoutHeader {...props} />
            <LayoutGrid grid={grid} padding={padding} spacing={spacing}>
                {children}
            </LayoutGrid>
            <LayoutFooter {...props} />
        </section>
    )

}

export default LayoutFieldset;