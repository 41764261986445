import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AutocompleteBase } from "@frontend-components/admin"
import { searchModel, getModel, getModelChildren } from '../../redux/functions/model';

import getOptionsTree from "./utils/getOptionsTree"

const getModelsTree = (arr) => {

    var tree = [],
          mappedArr = {},
          arrElem,
          mappedElem;

      // First map the nodes of the array to an object -> create a hash table.
      for(var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.id] = arrElem;
        mappedArr[arrElem.id]['children'] = [];
      }


      for (var id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
          mappedElem = mappedArr[id];
          // If the element is not at the root level, add it to its parent array of children.
          if (mappedElem.parentId) {
            mappedArr[mappedElem['parentId']]['children'].push(mappedElem);
          }
          // If the element is at the root level, add it to first level elements array.
          else {
            tree.push(mappedElem);
          }
        }
      }
      return tree;

}

const SelectPlaceField = ({collection, search, queryId, ...props}) => {

    const query = {
        collectionId: collection && collection.id,
        documentType: "place",
        fl: "id,parentId,uniqueId,title"
    }

    const getPlaces = () => {
        props.searchModel('documents', queryId, query)
    }

    useEffect(() => {
        getPlaces()
    }, [])

    const [options, setOptions] = useState([])

    const getOptions = () => {
        const models = search && search[queryId] && search[queryId].models || []
        const options = getOptionsTree(models)

        
        setOptions(options)

    }

    useEffect(() => {
        getOptions()
    }, [search[queryId]])

    return (
        <p>x {JSON.stringify(options)}</p>
    )

}

SelectPlaceField.defaultProps = {
    queryId: 'autocomplete/place',
    query: {
        documentType: "place"
    }
}

const mapStateToProps = (state) => {
	return {
        collection: state.collection,
        search: state.search
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        searchModel,
        getModel, getModelChildren,
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectPlaceField);