import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import packageJSON from "../../package.json"

import { login, logout } from '../redux/functions/user';
//import { getSiteByName } from '../redux/functions/site';
//import { getSchemas } from '../redux/functions/admin';

//import { getQueryString } from "../redux/functions/query";

import { getAppLayout, appToggle, appExpand, appCollapse } from '../redux/functions/app';
import { getNavParents, getNavItem, getNavQuery } from '../redux/functions/nav';

import { bulkReset } from '../redux/functions/bulk';

import AppLayout from "./AppLayout"
import AppRoutes from "./AppRoutes"

import qs from 'query-string';
import moment from 'moment';

const App = ({user, site, app, menuByUrl, appsByUser, appsBySite, ...props}) => {

    const sq = props.location && props.location.search && qs.parse(props.location.search) || {}
    const { userId, q } = sq;

    useEffect(() => {
        if (userId) {
            props.login(userId)
        } else {
            props.login()
        }
    }, []);

    useEffect(() => {
        app.layout && props.getAppLayout(app.layout)
    }, [app.layout, app.name])

    // login + logout

    const handleLogin = () => {
        props.login()
    }

    const handleLogout = () => {
        props.getAppLayout('login');
        props.logout()
    }

    const handleToggle = (section) => {
        props.appToggle(section);
    }

    // get props

    const userWithApps = {
        ...user,
        apps: appsByUser,
        owners: appsBySite
    }

    const primaryAction = app && app.primaryAction || app && app.edit && app.edit[0]

    const drawer = {
        ...app.drawer,
        onToggle: () => props.appToggle('drawer')
    }

    const handleSearch = (q) => {

        if (q) {
            sq.q = q.replace(' ', '+')
        } else {
            delete sq.q
        }

        let query = qs.stringify(sq)

        const url = props.location.pathname + "?" + query;

        if (props.history) {
            props.history.replace(url)
        }

    }

    const handleReset = () => {
        if (q) {
            handleSearch('')
        } else {
            props.appToggle('search')
        }
    }

    if (q) {
        app.search.expanded = true
    }

    const search = {
        ...app.search,
        onChange: (q, event) => handleSearch(q),
        onReset: (q, event) => handleReset(),
        onToggle: (section) => props.appToggle('search')
    }

    const header = {
        ...app.header,
        title: app && app.title,
        subtitle: site && site.title || user && user.name,
    }

    const subview = {
        ...app.subview,
    }

    const sidebar = {
        ...app.sidebar,
    }

    // calendar

    const { yyyy, mm, dd } = props.match.params;

    let currentYear, currentMonth, currentDate;

    if (yyyy && mm && dd) {
        currentDate = yyyy + "-" + mm + "-" + dd
    }

    if (yyyy && mm) {
        currentMonth = mm
    }

    if (yyyy) {
        currentYear = yyyy
    }    

    const handleSelect = ({date, url}) => {

        if (date) {
            const query = qs.stringify({
                ...sq,   
                date: date
            })
    
            url = props.location.pathname + "?" + query;
            url = app.root + "/" + moment(date).format('YYYY/MM/DD');
        } else if (q) {
            url && props.history.push(url + "?" + qs.stringify({q:q}));
        } else {
            url && props.history.push(url);
        }

    }

    const calendar = app && app.calendar;

    const version = packageJSON && packageJSON.version

    const layout = {
        header: header,
        search: search,
        drawer: drawer,
        sidebar: sidebar.disabled && false || sidebar,
        subview: subview,
    }

    const menu = app && app.menu
    const currentUrl = props.location.pathname



    return (
        <AppLayout 
            user={userWithApps} 
            site={site} 
            app={app} 
            menu={menu}
            menuByUrl={menuByUrl}
            currentUrl={currentUrl}
            calendar={calendar}
            currentYear={currentYear}
            currentMonth={currentMonth}
            currentDate={currentDate}
            primaryAction={primaryAction}
            {...layout} 
            onLogin={handleLogin} onLogout={handleLogout} 
            onSearch={handleSearch} onSelect={handleSelect} onToggle={handleToggle}>
            <AppRoutes {...props} />
        </AppLayout>
    )

}

// mapStateToProps

const mapStateToProps = (state) => {
	return {
		"user": state.user,
        "site": state.site,
        "app": state.app,
        "appLayout": state.appLayout,
        "menuByUrl": state.menuByUrl,
        "appsByUser": state.appsByUser,
        "appsBySite": state.appsBySite    
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        login, logout,
        getAppLayout, appToggle, appExpand, appCollapse,
        getNavParents, getNavItem, getNavQuery,
        bulkReset
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);