import React, { Component } from 'react';
import PreviewLabel from "./PreviewLabel"

class PreviewNumber extends React.Component {

  render() {
      
    const { formData, className } = this.props;
    
    return (
        
        <div className={className}>
            <PreviewLabel {...this.props} />
            <p className="field__number">{formData}</p>
        </div>
    
    )
  
  }
  
}

export default PreviewNumber;