export default {
    "select": {
        "title" : "title",
        "description" : "description",
        "label": "label"
    },
    prepare({formData}) {
        let { title, content, description } = formData

        title = content && content.title || title;

        return {
            title: title,
            description: description,
            label: "Produkt"
        }

    }
  
}