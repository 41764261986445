import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LocaleSettings } from "../NavSettings/"

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "24px",
    }
}));

const NavLocale = ({className, currentLocale = undefined, languages = [], onLocale}) => {

    const classes = useStyles()

    return (
        <nav className={className || classes.root}>
            <LocaleSettings name="locale" value={currentLocale} options={languages} onChange={(name, value) => onLocale(value)} />
        </nav>
    )
        
}

export default NavLocale