import sectionType from './sectionType';
import sectionText from './sectionText';
import sectionMedia from './sectionMedia';
import sectionEkultur from './sectionEkultur';

import sectionChat from './sectionChat';
import sectionChatLinks from './sectionChatLinks';
import sectionChatText from './sectionChatText';
import sectionChatVideo from './sectionChatVideo';
import sectionChatImage from './sectionChatImage';

import sectionQa from './sectionQuestion';

export default {
    "type": "object",
    "properties": {
        "sectionType": sectionType,
        "sectionId": {
            "type": "string"
        },
        "title": {
            "type": "localizedString"
        },
        "titleHidden": {
            "type": "boolean"
        }
    },
    "dependencies": {
        "sectionType" : {
            "oneOf": [
                sectionChat,
                sectionChatLinks,
                sectionChatText,
                sectionChatVideo,
                sectionChatImage
            ]
        }
    }
}