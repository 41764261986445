import React, { Component } from 'react';

import "./FieldLanguage.scss"

class FieldLanguage extends Component {
    
    render() {
        const { language } = this.props;
        
        if (!language) {
            return false
        }
        
        return (
            <p className="admin-field__language">Language: { language }</p>
        )
        
    }

}

export default FieldLanguage;