import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReplyIcon from '@material-ui/icons/Reply';
import Typography from '@material-ui/core/Typography';

import UserIcon from "../User/UserIcon"
import ModuleLabel from "../Module/ModuleLabel"
import ModuleDate from "../Module/ModuleDate"

import getInboxMessage from "./utils/getInboxMessage"

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        width: "100%",
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(2),
        userSelect: "none",

        "&[data-unread=true]": {
            "& $name": {
                fontWeight: "bold"
            }
        }

    },
    avatar: {
        position: "absolute",
        top: 0,
        left: 0,
        width: theme.spacing(4.5), 
        height: theme.spacing(4.5),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    reply: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(2),
    },
    author: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "baseline"
    },
    metadata: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
//        lineHeight: "24px",
        display: "flex",
//        alignItems: "center",

        "& > * + *": {
            marginLeft: theme.spacing(.5)
        }

    },
    icon: {
        fontSize: "18px",
        marginRight: theme.spacing(0.5)
    },
    name: {

        "& + $datetime": {
            marginLeft: '.5em'
        },

        "& + $email": {
            marginLeft: '.5em'
        }

    },
    datetime: {
        fontSize: "14px",
        opacity: 0.85
    },
    email: {
        fontSize: "14px",
        opacity: 0.85
    }
}));

const InboxMessageHeader = ({expanded, unread, replyTo, onClick, ...props }) => {
    const { label, labelColor, status, author, datetime } = getInboxMessage(props)

    const classes = useStyles()

    if (replyTo) {
        return (
            <header className={classes.root} onClick={onClick} data-unread={unread} data-status={status}>
                <UserIcon {...author} className={classes.avatar} />
                <Typography className={classes.metadata}>
                    <ReplyIcon className={classes.icon} />
                    <i className={classes.recipient}>to {replyTo}</i>
                </Typography>
            </header>
        )
    }

    if (expanded) {
        return (
            <header className={classes.root} onClick={onClick} data-unread={unread} data-status={status}>
                <UserIcon {...author} className={classes.avatar} />
                <Typography className={classes.author}>
                    <b className={classes.name}>{author && author.name}</b>
                    <i className={classes.email}>{author && author.email }</i>
                </Typography>
                <div className={classes.metadata}>
                    <ModuleLabel label={label} labelColor={labelColor} />
                    <ModuleDate className={classes.datetime} datetime={datetime} />
                </div>
            </header>
        )
    }

    return (
        <header className={classes.root} onClick={onClick} data-unread={unread} data-status={status}>
            <UserIcon {...author} className={classes.avatar} />
            <Typography className={classes.author}>
                <b className={classes.name}>{author && author.name}</b>
                <ModuleDate className={classes.datetime} datetime={datetime} />
            </Typography>
        </header>
    )

}

export default InboxMessageHeader