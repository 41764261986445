import layout from './schemaSectionMediaLayout';

export default {
    "type": "object",
    "properties": {
        "sectionType": {
            "enum": ["objects"]
        },
        "sectionLayout": layout,
        "objects": {
            "type": "array",
            "items": {
                "type": "reference",
                "documentType": "object",
                "referenceType": "object"
            }
        }
    }
}