import React from 'react';
import IconButton from "@material-ui/core/IconButton"
import ErrorIcon from '@material-ui/icons/Warning';
import TrashIcon from '@material-ui/icons/Delete';
import DefaultIcon from '@material-ui/icons/RemoveRedEye';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import StatusDate from "./StatusDate"

import "./NavStatus.scss"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    icon: {
    },
    statusLabel: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    statusDate: {
        fontFamily: "Akkurat, sans-serif",
        marginRight: theme.spacing(1)
    }
}));

const NavStatus = ({className, status = "new", statusLabel, statusMessage, statusDate}) => {
    const classes = useStyles();

    let icon = <DefaultIcon />
        
    if (status === "trash") {
        icon = <TrashIcon />
    }

    if (status === "error") {
        icon = <ErrorIcon />
    }

    return (
        <nav className={className || classes.root}>
            <IconButton className={classes.icon} size="small">{icon}</IconButton>
            <strong className={classes.statusLabel}>{statusLabel || status}</strong>
            {statusDate && <StatusDate className={classes.statusDate}>{statusDate}</StatusDate>}
            {statusMessage && <span className={classes.statusMessage}>{statusMessage}</span>}
        </nav>
    )

}

export default NavStatus