export default {
    "ui:layout": "module",
    "ui:options": {
        "editable": true,
        "collapsible": true,
        "removable": true,
        "hideable": true
    },
    "ui:settings": [
        "moduleSize"
    ],
    "ui:fieldset": [
        "content",
        "media"
    ],
    "content": {
        "ui:fieldset": [
            "headline",
            "description",
            "creditLine"
        ],
        "description": {
            "ui:widget": "textarea"
        }
    }
}