import React, { Component } from 'react';
import { FinderSection } from '@frontend-components/admin';

class TemplateDashboardApps extends Component {
  
  static defaultProps = {
    results: []
  }
  
  renderItem = (item, index) => {
    
    const { uniqueId, url, title } = item;
    
    if (!uniqueId) {
      return false
    }
    
    return (
        <li>
        <a href={url}>{title}</a>
        </li>
    )
    
  }

  render() {
    
    const { results } = this.props;

    return (
      <FinderSection { ...this.props }>
        <ul>
        { results.map( this.renderItem ) }
        </ul>
      </FinderSection>
    )

  }

}

export default TemplateDashboardApps;
