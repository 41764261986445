// REPORT

export const REQUEST_REPORTS = 'REQUEST_REPORTS'
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS'

export function requestReports(id) {

  return {
    type: REQUEST_REPORTS,
    id: id,
  }
  
}

export function receiveReports(id, data) {
    
    return {
        type: RECEIVE_REPORTS,
        id: id,
        data: data
    }
    
  
}

