import React, { Component } from 'react';
import "./WidgetDescription.scss"

class WidgetDescription extends Component {

    render() {
        const { description, trending } = this.props;
        
        if (!description) {
            return false
        }

        return (
            <p className="admin-widget__description" data-trending={trending}>{description}</p>
        )

    }

}

export default WidgetDescription;