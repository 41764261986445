import React from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
        "& .MuiInputBase-root": {
            fontFamily: "Akkurat, sans-serif",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(1),
        },
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
        "& .MuiAutocomplete-inputRoot[class*=MuiFilledInput-root] .MuiAutocomplete-input": {
            padding: theme.spacing(.25, .5)
        },
        "& .MuiAutocomplete-tag": {
            margin: theme.spacing(.25)
        }
    }
}));

const TagsChip = ({label, disabled, ...props}) => {
    const classes = useStyles()

    if (disabled) {
        return (
            <Chip className={classes.chip} variant="outlined" size="small" label={label} />
        )
    }

    return (
        <Chip className={classes.chip} variant="outlined" size="small" label={label} {...props} />
    )

}

const TagsField = ({id, disabled, label = "Tags", value = [], options = [], placeholder = "Add tag", helperText, onChange, ...props}) => {

    const classes = useStyles()

    const renderTags = (value, getTagProps) => {
        return (
            value.map((option, index) => (
                <TagsChip label={option} disabled={disabled} {...getTagProps({index})} />
            ))
        )
    }

    const handleChange = (event, value, reason) => {
        const tags = value.map(option => {
            return getOptionLabel(option).toLowerCase()
        })

        onChange && onChange(tags)
    }

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
            filtered.push({
                value: params.inputValue,
                label: `+ "${params.inputValue}"`,
            });
        }

        return filtered;
    }

    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }

        // Regular option
        return option.value;
    }

    const renderOption = (option) => {
        if (typeof option === 'string') {
            return option;
        }

        return option.label
    }

    return (
        <Autocomplete
            className={classes.root}
            multiple
            id={id}
            options={options}
            disabled={disabled}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            value={value}
            freeSolo
            renderTags={renderTags}
            renderInput={(params) => (
                <TextField {...params} className={classes.input} variant="filled" label={label} placeholder={placeholder} helperText={helperText} />
            )}
            onChange={handleChange}
      />        
    )

}

export default TagsField