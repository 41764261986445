import React from 'react';
import Mediabutton from "./MediaButton"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    }
}));

const MediaButtonbar = ({buttons, onAdd, onUpload}) => {

    const classes = useStyles()

    if (!buttons) {
        return false
    }

    return (
        <nav className={classes.root}>
            { buttons.map((button, index) => {
                return (
                    <Mediabutton {...button} key={index} />
                )
            })}
        </nav>
    )

}

export default MediaButtonbar;