
let initialState = {
    schema: {},
    formData: undefined,
    query: {},
    selected: []
}

const dialog = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'REQUEST_EDITOR_DIALOG':
            return initialState
        
        case 'RECEIVE_EDITOR_DIALOG':
            return action.dialog

        case 'RECEIVE_EDITOR_DIALOG_FORMDATA':
            return {
                ...state,
                formData: action.formData
            }

        case 'REQUEST_DIALOG_SELECTED': 
            return {
                ...state,
                selected: []
            }

        case 'RECEIVE_DIALOG_SELECTED': 
            return {
                ...state,
                selected: action.selected
            }

        default:
            return state

    }

}

export default dialog;