import SelectLicenseWidget from "./SelectLicenseWidget"

export default {
    fields: {
    },
    widgets: {
        "selectLicense": SelectLicenseWidget,
    }
}

