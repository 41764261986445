import { requestEditor, receiveEditor } from '../actions/editor';
import { requestEditorSchema, receiveEditorSchema } from '../actions/editor';
import { requestEditorUiSchema, receiveEditorUiSchema } from '../actions/editor';
import { requestEditorFormData, receiveEditorFormData } from '../actions/editor';
import { requestEditorFormContext, receiveEditorFormContext } from '../actions/editor';

import { requestEditorParents, receiveEditorParents } from '../actions/editor';
import { requestEditorLanguages, receiveEditorLanguages } from '../actions/editor';
import { requestEditorAction, receiveEditorAction } from '../actions/editor';

import { requestEditorCurrentId, receiveEditorCurrentId } from '../actions/editor';
import { requestEditorLocale, receiveEditorLocale } from '../actions/editor';
import { requestEditorDialog, receiveEditorDialog, receiveEditorDialogFormData } from '../actions/editor';

import { receiveEditorChange } from '../actions/editor';
import { requestEditorRedirect, receiveEditorRedirect } from '../actions/editor';

export function getEditor(editor) {

    return function(dispatch) {
        dispatch(receiveEditor(editor))
    }

}

export function getEditorSchema(schema) {

  return function(dispatch) {
    dispatch(receiveEditorSchema(schema))
  }
  
}

export function getEditorUiSchema(uiSchema) {

  return function(dispatch) {
    dispatch(receiveEditorUiSchema(uiSchema))
  }
  
}

export function getEditorFormData(formData) {

  return function(dispatch) {
    dispatch(receiveEditorFormData(formData))
  }
  
}

export function getEditorFormContext(formContext) {

    return function(dispatch) {
      dispatch(receiveEditorFormContext(formContext))
    }
    
}

// change

export function editorChange(formData) {

    return function(dispatch) {
      dispatch(receiveEditorChange(formData))
    } 
    
}

// redirect
  
export function getEditorRedirect(redirect) {

    return function(dispatch) {
      dispatch(receiveEditorRedirect(redirect))
    }
    
  }

// parents
  
export function getEditorParents(parents) {

    return function(dispatch) {
      dispatch(receiveEditorParents(parents))
    }
    
  }
  
// languages
  
export function getEditorLanguages(languages) {

  return function(dispatch) {
    dispatch(receiveEditorLanguages(languages))
  }
  
}

// action
  
export function getEditorAction(action) {

    return function(dispatch) {
      dispatch(receiveEditorAction(action))
    }
    
}
  
// currentId

export function editorCurrentId(id) {

    return function(dispatch) {
        dispatch(receiveEditorCurrentId(id))
    } 
    
}
  
// locale

export function editorLocale(locale) {

    return function(dispatch) {
        dispatch(receiveEditorLocale(locale))
    } 
  
}

// dialog

export function editorDialog(dialog) {

    return function(dispatch) {
        dispatch(requestEditorDialog())
        dispatch(receiveEditorDialog(dialog))
    } 
    
}
  
export function dialogChange(formData) {

    return function(dispatch) {
        dispatch(receiveEditorDialogFormData(formData))
    } 
    
}
    