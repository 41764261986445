import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar'

import Grid from "./layers/Grid"
import Axes from "./layers/Axes"
import Bars from "./layers/Bars"
import BarsItem from "./layers/BarsItem"

import BarTooltip from "./ChartBarTooltip"

import "./ChartBar.scss"

class ChartBar extends Component {
    
    static defaultProps = {
        data: [],
        keys: [],
        theme: {},
        groupMode: "grouped",
        padding: 0.1,
        innerPadding: 0,
        margin: {
            top: 0, 
            right: 60, 
            bottom: 60, 
            left: 0
        },
        offset: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },
        xFormat: "date",
        yFormat: "integer",
        xScale: {
            type: "point"
        },
        yScale: {
            type: "linear",
            stacked: false,
            min: 0, 
            max: 'auto'
        },
        gridXValues: 5,
        gridYValues: 5,
        enableGridX: false,
        enableGridY: true,
        enableLabel: false,
        animate: false
    }

    state = {
        currentBar: undefined
    }

    setCurrentBar = (bar, event) => {
        this.setState({
            currentBar: bar
        })
    }
    
    getAxisTop = () => {
        return null;
    }

    getAxisRight = () => {
        const { axisRight, margin, yFormat, gridYValues } = this.props;
        
        let tickSize;
        
        return {
            format: yFormat,
            tickSize: margin.right || 0,
            tickValues: gridYValues,
            ...axisRight,
        }
        
    }

    getAxisBottom = () => {
        const { axisBottom, xTickValues, xFormat, dimension, startDate, endDate } = this.props;

        return {
            dimension: dimension,
            startDate: startDate,
            endDate: endDate,
            format: xFormat,
            tickSize: 5,
            tickValues: xTickValues || undefined,
            ...axisBottom,
//            tickValues: 5,
//            tickSize: 0, 
//            tickPadding: 6, 
//            tickRotation: 0
        }
        
    }

    getAxisLeft = () => {
        return null;
    }

    renderAxes = (props) => {
        const { width, height, innerWidth, innerHeight, xScale, yScale, axisTop, axisRight, axisBottom, axisLeft } = props;
        
        return (
            <Axes
                key="axes"
                width={width}
                height={height}
                xScale={xScale}
                yScale={yScale}
                top={axisTop}
                right={axisRight}
                bottom={axisBottom}
                left={axisLeft}
            />
        )
        
    }
    
    renderGrid = (props) => {
        const { width, height, innerWidth, innerHeight, enableGridX, enableGridY, xScale, yScale, gridXValues, gridYValues } = props;
        
        return (
            <Grid
                key="grid"
                width={width}
                height={height}
                xScale={enableGridX ? xScale : null}
                yScale={enableGridY ? yScale : null}
                xValues={gridXValues}
                yValues={gridYValues}
            />
        )
        
    }

    renderBars = (props) => {
        const { currentBar } = this.state;        
        const { renderTooltip, onTooltip } = props;

        return (
            <Bars {...props}
                current={currentBar}
                setCurrent={this.setCurrentBar}
                tooltip={this.renderTooltip}
            />
        )
        
    }

    getLayers = () => {
        return [
            this.renderGrid,
            this.renderAxes,
//            this.renderBars,
            'bars', 
            'markers', 
            'legends', 
            'annotations',
            'legends'
        ]
        
    }

    getMargin = () => {
        const { outerMargin, margin } = this.props;
        
        if (outerMargin && margin) {
            
            return {
                top: outerMargin.top + margin.top,
                right: outerMargin.right + margin.right,
                bottom: outerMargin.bottom + margin.bottom,
                left: outerMargin.left + margin.left
            }    
            
        }
        
        return margin
        
    }
    
    renderTooltip = (props) => {
        
        if (this.props.renderTooltip) {
            return this.props.renderTooltip(props)
        }
        
        return (
            <BarTooltip {...this.props} bar={props} />
        )
        
    }
    
    getProps = () => {
        
        return {
            ...this.props,
            layers: this.getLayers(),
            axisTop: this.getAxisTop(),
            axisRight: this.getAxisRight(),
            axisBottom: this.getAxisBottom(),
            axisLeft: this.getAxisLeft(),
            tooltip: this.renderTooltip,
            barComponent: BarsItem,
            margin: this.getMargin()
        }
        
    }
        
    render() {
        const props = this.getProps()
        
        console.log('BAR DATA', props)
        
        return (
            <ResponsiveBar {...props} />
        )
    
    }

}

export default ChartBar