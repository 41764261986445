import React from 'react';
import ColorSettings from "./ColorSettings"

const TextColorSettings = ({variant = "text", buttonLabel = "T", backgroundColor = "#7f7f7f", ...props}) => {

    return (
        <ColorSettings {...props} variant={variant} backgroundColor={backgroundColor} />
    )
    
}

export default TextColorSettings;