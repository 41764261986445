import React, { Component } from 'react';
import TextareaAutosize from "react-textarea-autosize";
import FieldLanguage from "./FieldLanguage"

import "./FieldInput.scss"

class FieldInput extends Component {
    static defaultProps = {
        type: "text",
        value: "",
        required: false,
        autofocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    }

    onChange = (event) => {
        const { value } = event.target;

        this.setState({
            value: value
        });

        if (this.props.onChange) {
            this.props.onChange(value, event);
        }
    }

    getClassName = () => {
        const { className, type } = this.props;

        let classNames = []
        
        classNames.push('admin-field__input');

        if (type) {
            classNames.push('admin-field__input--' + type);
        }
        
        return classNames.join(' ');
    }

    render() {
        const { id, language, type, value, readonly, disabled, placeholder, pattern, rows, step, min, max } = this.props;
        
        const className = this.getClassName();
        
        if (rows) {
            
            return (

                <div className={ className }>
                    <FieldLanguage language={language} />
                    <TextareaAutosize id={id} placeholder={placeholder} readOnly={readonly} rows={rows} value={value}
                        onFocus={event => this.onFocus(event)}
                        onBlur={event => this.onBlur(event)}
                        onChange={event => this.onChange(event)} />
                </div>
                
            )
            
        }
        
        return (
            <div className={ className }>
                <FieldLanguage language={language} />
                <input id={id} type={type} placeholder={placeholder} pattern={pattern} disabled={disabled} readOnly={readonly} value={value}
                    step={step} 
                    min={min} 
                    max={max}
                    onFocus={event => this.onFocus(event)}
                    onBlur={event => this.onBlur(event)}
                    onChange={event => this.onChange(event)} />
            </div>
        )
    }
}

export default FieldInput;