import React from 'react';
import PropTypes from 'prop-types';
import EditModule from "./EditModule"
import ListModule from "./ListModule"
import TableModule from "./TableModule"
import GridModule from "./GridModule"
import CardModule from "./CardModule"

const templates = {
    "list": ListModule,
    "table": TableModule,
    "grid": GridModule,
    "card": CardModule,
}

const LayoutModule = ({
    variant = "list",
    size = "small",
    type = undefined,
    imageUrl = undefined, 
    title = undefined, 
    description = undefined, 
    status = undefined,
    author = undefined,
    datetime = undefined,
    children,
    ...props
}) => {

    if (props.expanded) {
        return (
            <EditModule {...props} title={title}>
                {children}
            </EditModule>
        )
    }

    const ModuleTemplate = templates && templates[variant] || ListModule

    return (
        <ModuleTemplate
            {...props}
            size={size}
            type={type}
            elevation={1}
            imageUrl={imageUrl}
            title={title}
            description={description}
            status={status}
            author={author}
            datetime={datetime}
        />
    )

}

LayoutModule.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.any,
    status: PropTypes.string,
    datetime: PropTypes.string,
    author: PropTypes.string,
    status: PropTypes.string
}

export default LayoutModule;
