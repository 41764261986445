import LayoutObject from "./LayoutObject"
import LayoutArray from "./LayoutArray"

//import LayoutGrid from "./LayoutGrid"
import LayoutList from "./LayoutList"
import LayoutField from "./LayoutField"
import LayoutFieldset from "./LayoutFieldset"
import LayoutFormat from "./LayoutFormat"
import LayoutThumbnail from "./LayoutThumbnail"

export default {
    "fieldtypes": {
    },
    "fields": {
    },
    "layouts": {
        "object": LayoutObject,
        "array": LayoutArray,
        "list": LayoutList,
        "format": LayoutFormat,
        "field": LayoutField,
        "fieldset": LayoutFieldset,
        "thumbnail": LayoutThumbnail,
//        "grid": LayoutGrid,
    },
    "widgets": {
    }
}