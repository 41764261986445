import React, { Component } from 'react';
import { Dashboard } from "./"

class LayoutDashboard extends Component {

    render() {
        return (
            <Dashboard {...this.props}>
                {this.props.children}
            </Dashboard>
        )
    }
}

export default LayoutDashboard;