import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getUiOptions } from '../Schema/utils';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100%",
        padding: props => { return theme.spacing(props.padding) },
    }
}));

const LayoutGridItem = ({uiSchema, children, ...props}) => {
    const uiOptions = uiSchema && getUiOptions(uiSchema);

    const gridCols = props.gridCols || uiOptions && uiOptions.gridCols

    if (gridCols && typeof gridCols === 'number' && gridCols !== 0) {
        return (
            <Grid item xs={gridCols}>{children}</Grid>
        )
    }

    if (gridCols && typeof gridCols === "object") {
        return (
            <Grid item {...gridCols}>{children}</Grid>
        )
    }

    return (
        <Grid item xs={12}>{children}</Grid>
    )

}

const LayoutGrid = ({grid = true, padding = 0, spacing = 2, alignItems, alignContent, justify, children}) => {

    const classes = useStyles({padding})

    if (!grid) {
        return (
            <section className={classes.root}>
                {children}                
            </section>
        )
    }

    const items = React.Children.toArray(children)

    return (
        <section className={classes.root}>
            <Grid container alignItems={alignItems} alignContent={alignContent} justify={justify} spacing={spacing}>
                {items.map((child, index) => {
                    return (
                        <LayoutGridItem {...child.props} key={index}>
                            { child }
                        </LayoutGridItem>
                    )
                }) }
            </Grid>
        </section>
    )

}

export default LayoutGrid