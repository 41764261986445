import defaultRegistry from "../../components/registry"
import BulkStringField from "./BulkStringField"

export default {
    "fieldtypes": {
        ...defaultRegistry.fieldtypes
    },
    "fields": {
        ...defaultRegistry.fields,
        "bulkString": BulkStringField,
    },
    "layouts": {
        ...defaultRegistry.layouts,
    },
    "widgets": {
        ...defaultRegistry.widgets,
    }
}