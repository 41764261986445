import React, { Component } from 'react';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import moment from 'moment';

class ChartSwarmPlot extends Component {

    static defaultProps = {
        colors: ['red'],
        margin: { 
            top: 24, 
            right: 60, 
            bottom: 60, 
            left: 24
        },
        enableGridX: false,
        enableGridY: true,
        yScaleMin: "0",
        yScaleMax: "auto",
        theme: {
            background: 'transparent',
            fontFamily: 'Akkurat, sans-serif',
            fontSize: 12,
            textColor: '#333333',
            grid: {
                line: {
                    stroke: '#dddddd',
                    strokeWidth: 1,
                },
            },
            crosshair: {
                line: {
                    stroke: '#000000',
                    strokeWidth: 1,
                    strokeOpacity: 0.25,
                    strokeDasharray: '2 2',
                }
            }
        },
        styleById: {
            primary: {
                strokeWidth: 2
            },
            secondary: {
                strokeDasharray: '2, 2',
                strokeWidth: 2
            }
        },
        data: [
          {
            "id": "0.0",
            "group": "group A",
            "price": 211,
            "volume": 17
          },
          {
            "id": "0.1",
            "group": "group A",
            "price": 280,
            "volume": 10
          },
          {
            "id": "0.2",
            "group": "group A",
            "price": 372,
            "volume": 20
          },
          {
            "id": "0.3",
            "group": "group A",
            "price": 222,
            "volume": 7
          },
          {
            "id": "0.4",
            "group": "group A",
            "price": 233,
            "volume": 13
          },
          {
            "id": "0.5",
            "group": "group A",
            "price": 192,
            "volume": 16
          },
          {
            "id": "0.6",
            "group": "group A",
            "price": 495,
            "volume": 11
          },
          {
            "id": "0.7",
            "group": "group A",
            "price": 229,
            "volume": 20
          },
          {
            "id": "0.8",
            "group": "group A",
            "price": 484,
            "volume": 14
          },
          {
            "id": "0.9",
            "group": "group A",
            "price": 50,
            "volume": 6
          },
          {
            "id": "0.10",
            "group": "group A",
            "price": 328,
            "volume": 5
          },
          {
            "id": "0.11",
            "group": "group A",
            "price": 76,
            "volume": 9
          },
          {
            "id": "0.12",
            "group": "group A",
            "price": 351,
            "volume": 9
          },
          {
            "id": "0.13",
            "group": "group A",
            "price": 102,
            "volume": 7
          },
          {
            "id": "0.14",
            "group": "group A",
            "price": 0,
            "volume": 9
          },
          {
            "id": "0.15",
            "group": "group A",
            "price": 416,
            "volume": 13
          },
          {
            "id": "0.16",
            "group": "group A",
            "price": 215,
            "volume": 16
          },
          {
            "id": "0.17",
            "group": "group A",
            "price": 284,
            "volume": 18
          },
          {
            "id": "0.18",
            "group": "group A",
            "price": 386,
            "volume": 9
          },
          {
            "id": "0.19",
            "group": "group A",
            "price": 365,
            "volume": 11
          },
          {
            "id": "0.20",
            "group": "group A",
            "price": 301,
            "volume": 11
          },
          {
            "id": "0.21",
            "group": "group A",
            "price": 58,
            "volume": 15
          },
          {
            "id": "0.22",
            "group": "group A",
            "price": 458,
            "volume": 17
          },
          {
            "id": "0.23",
            "group": "group A",
            "price": 360,
            "volume": 4
          },
          {
            "id": "0.24",
            "group": "group A",
            "price": 341,
            "volume": 17
          },
          {
            "id": "0.25",
            "group": "group A",
            "price": 19,
            "volume": 7
          },
          {
            "id": "0.26",
            "group": "group A",
            "price": 114,
            "volume": 9
          },
          {
            "id": "0.27",
            "group": "group A",
            "price": 59,
            "volume": 11
          },
          {
            "id": "0.28",
            "group": "group A",
            "price": 478,
            "volume": 5
          },
          {
            "id": "0.29",
            "group": "group A",
            "price": 359,
            "volume": 7
          },
          {
            "id": "0.30",
            "group": "group A",
            "price": 266,
            "volume": 6
          },
          {
            "id": "0.31",
            "group": "group A",
            "price": 336,
            "volume": 11
          },
          {
            "id": "0.32",
            "group": "group A",
            "price": 137,
            "volume": 4
          },
          {
            "id": "0.33",
            "group": "group A",
            "price": 95,
            "volume": 13
          },
          {
            "id": "0.34",
            "group": "group A",
            "price": 106,
            "volume": 20
          },
          {
            "id": "0.35",
            "group": "group A",
            "price": 56,
            "volume": 13
          },
          {
            "id": "0.36",
            "group": "group A",
            "price": 244,
            "volume": 4
          },
          {
            "id": "0.37",
            "group": "group A",
            "price": 360,
            "volume": 4
          },
          {
            "id": "0.38",
            "group": "group A",
            "price": 439,
            "volume": 6
          },
          {
            "id": "0.39",
            "group": "group A",
            "price": 249,
            "volume": 19
          },
          {
            "id": "0.40",
            "group": "group A",
            "price": 217,
            "volume": 4
          },
          {
            "id": "0.41",
            "group": "group A",
            "price": 309,
            "volume": 12
          },
          {
            "id": "0.42",
            "group": "group A",
            "price": 97,
            "volume": 20
          },
          {
            "id": "0.43",
            "group": "group A",
            "price": 259,
            "volume": 13
          },
          {
            "id": "0.44",
            "group": "group A",
            "price": 446,
            "volume": 10
          },
          {
            "id": "0.45",
            "group": "group A",
            "price": 399,
            "volume": 5
          },
          {
            "id": "0.46",
            "group": "group A",
            "price": 105,
            "volume": 12
          },
          {
            "id": "0.47",
            "group": "group A",
            "price": 370,
            "volume": 8
          },
          {
            "id": "0.48",
            "group": "group A",
            "price": 291,
            "volume": 11
          },
          {
            "id": "0.49",
            "group": "group A",
            "price": 267,
            "volume": 8
          },
          {
            "id": "0.50",
            "group": "group A",
            "price": 117,
            "volume": 15
          },
          {
            "id": "0.51",
            "group": "group A",
            "price": 385,
            "volume": 4
          },
          {
            "id": "0.52",
            "group": "group A",
            "price": 285,
            "volume": 5
          },
          {
            "id": "0.53",
            "group": "group A",
            "price": 423,
            "volume": 5
          },
          {
            "id": "0.54",
            "group": "group A",
            "price": 433,
            "volume": 14
          },
          {
            "id": "0.55",
            "group": "group A",
            "price": 91,
            "volume": 4
          },
          {
            "id": "0.56",
            "group": "group A",
            "price": 47,
            "volume": 14
          },
          {
            "id": "0.57",
            "group": "group A",
            "price": 130,
            "volume": 7
          },
          {
            "id": "0.58",
            "group": "group A",
            "price": 369,
            "volume": 17
          },
          {
            "id": "0.59",
            "group": "group A",
            "price": 432,
            "volume": 20
          },
          {
            "id": "0.60",
            "group": "group A",
            "price": 405,
            "volume": 8
          },
          {
            "id": "0.61",
            "group": "group A",
            "price": 184,
            "volume": 18
          },
          {
            "id": "0.62",
            "group": "group A",
            "price": 416,
            "volume": 17
          },
          {
            "id": "0.63",
            "group": "group A",
            "price": 443,
            "volume": 16
          }
        ]
    }
    
    render() {
        const { data, theme, colors, margin, enableGridX, enableGridY } = this.props;
        
        return (
            <ResponsiveSwarmPlot
                data={data}
                colors={colors}
                enableGridX={enableGridX}
                enableGridY={enableGridY}
                groups={['group A']}
                value="price"
                valueFormat="$.2f"
                valueScale={{ type: 'linear', min: 0, max: 500 }}
                size={{ key: 'volume', values: [ 4, 20 ], sizes: [ 6, 20 ] }}
                layout="horizontal"
                forceStrength={4}
                simulationIterations={100}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.6
                        ],
                        [
                            'opacity',
                            0.5
                        ]
                    ]
                }}
                margin={0}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                motionStiffness={50}
                motionDamping={10}
            />
        )
    
    }

}

export default ChartSwarmPlot