export function isMediaObject(schema) {
    if (schema.type === "object" && schema.properties) {
        if (schema.properties.hasOwnProperty('mediaId')) {
            return true
        }
    }

    return false;
}

export function getMediaObject(props, mediaType) {
    if (!mediaType && props.mediaType) {
        mediaType = props.mediaType;
    }

    let { content, hotspot, focalpoint, cropdata, properties } = props;

    let schema = {
        "type": "object",
        "properties": {
            "mediaId": {
                "type": "string",
                "readonly": true
            },
            "media": {
                "type": "object",
                "properties": {
                    "mediaType": {
                        "type": "string",
                        "readonly": true
                    }
                }
            }
        }
    }

    if (mediaType) {
        schema.properties.media.properties.mediaType = {
            "type": "string",
            "default": mediaType,
            "readonly": true
        };
    }

    if (mediaType === "image") {
        focalpoint = true
        cropdata = true
        
        if (focalpoint) {
            schema.properties.focalpoint = {
                "type": "object",
                "properties": {
                    "x": {
                        "type": "number",
                        "readonly": true
                    },
                    "y": {
                        "type": "number",
                        "readonly": true
                    }
                }
            }
        }

        if (cropdata) {
            schema.properties.cropdata = {
                "type": "object",
                "properties": {
                    "x": {
                        "type": "number",
                        "readonly": true
                    },
                    "y": {
                        "type": "number",
                        "readonly": true
                    }
                }
            }
        }

    }

    if (content) {
        schema.properties.content = content;
    }

    if (properties) {
        schema.properties = {
            ...schema.properties,
            ...properties
        }
    }

    return schema
}

export function getMediaButtons(props) {
    
    let { uiOptions, schema } = props;
    let { buttons } = uiOptions;

    // buttons
    
    if (!buttons) {
        buttons = ["upload"]
    }
    
    return buttons;
    
}

export function getMediaObjectUi(props) {
    let { uiOptions, schema, formData, isArrayItem } = props;
    
    /*
    
    // collapsible
    
    let collapsible, editable

    collapsible = false;
    editable = true;

    // deletable, restorable
    
    let hideable, deletable, restorable;
    
    hideable = true;
    deletable = true;

    */

    let mediaId, media, uploadProgress

    if (formData && formData.mediaId) {
        mediaId = formData.mediaId
    }
    
    if (formData && formData.media) {
        media = formData.media
    }

    if (formData && formData.uploadProgress) {
        uploadProgress = formData.uploadProgress
    }
    
    // defaults

    let { collapsible, expanded, editable, hideable, deletable, restorable, layout, fieldset, nav, hotspot, focalpoint, cropdata } = uiOptions;
    
    collapsible = Boolean(uiOptions.collapsible) || false
    editable = Boolean(uiOptions.editable) || false
    hideable = Boolean(uiOptions.hideable) || true
    deletable = Boolean(uiOptions.deletable) || true
    
    if (!layout && isArrayItem) {
        layout = "module";
    } else if (!layout) {
        layout = "media";
    }
    
    // buttons
    
    const buttons = getMediaButtons(props);
    
    // hotspot, focalpoint, cropdata
    
    if (!fieldset) {

        fieldset = []
    
        if (schema.properties.content) {
            fieldset.push("content")
        }
        
        if (hotspot) {
            focalpoint = true
            cropdata = true
        }
    
        if (focalpoint) {
            fieldset.push("focalpoint")
        }

        if (cropdata) {
            fieldset.push("cropdata")
        }
        
    }
    
    // fieldset + nav
    
    if (fieldset && fieldset.length) {
//        collapsible = true;
        editable = true;
        nav = true;
    }
    

    /*    
    if (layout === "module" && fieldset && fieldset.length) {
    }

    if (layout === "media") {
        
        if (fieldset && fieldset.length) {
            editable = true;
            collapsible = true;
            nav = true;
        }
        
    }
    */
    
    // title, label, imageUrl
    
    let title, label, imageUrl;
    
    if (media && media.title) {
        title = media.title;
    }

    if (media && media.mediaType) {
        label = media.mediaType;
    }

    if (media && media.imageUrl) {
        imageUrl = media.imageUrl;
    }

    
    return {
        ...uiOptions,

        mediaId: mediaId,
        media: media,

        uploadProgress: uploadProgress,

        title: title,
        label: label,
        imageUrl: imageUrl,

        collapsible: collapsible,
        expanded: expanded,
        editable: editable,

        hideable: hideable,
        deletable: deletable,
        restorable: restorable,

        layout: layout,
        fieldset: fieldset,
        nav: nav,
        buttons: buttons
    }

    
}