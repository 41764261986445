export default {
    editor: {
        "Loading": "Loading",
        "Saving": "Saving",
        "Save": "Save",
        "Save as draft": "Save as draft",
        "Save and publish": "Save and publish",
        "Save and exit": "Save and exit",
        "Save as": "Save as"
    }
}