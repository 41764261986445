import React from 'react';
import Dropzone from 'react-dropzone';
import ButtonBase from '@material-ui/core/ButtonBase';

const UploadButton = ({classes, className, accept, multiple, onUpload, children}) => {

    return (
        <Dropzone
            multiple={multiple}
            accept={accept}
            onDrop={event => onUpload(event)}>
            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                <ButtonBase {...getRootProps()} classes={classes} className={className}>
                    <input {...getInputProps()} />
                    {children}
                </ButtonBase>
            )}
        </Dropzone>
    )

}

export default UploadButton;
