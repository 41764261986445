import { getMenuChildren } from "./getMenuChildren"

import getMenuUploads from "./getMenuUploads"

export const getCollectionMenu = ({app, user, collection}) => {

    const collectionId = collection && collection.id
    const children = collection && collection.content && collection.content.menu
    const documentTypes = collection && collection.content && collection.content.documentTypes

//    const uploadsMenu = getMenuUploads()

    const schemasMenu = documentTypes && documentTypes.length && documentTypes.map(child => {

        const { model } = child;

        return {
            "pathname": model.uniqueId,
            "title": model.title
        }
    })

    const childrenWithAdmin = [
        ...children,
//        uploadsMenu,
        {
            "role": "group",
            "icon": "settings",
            "pathname": "settings",
            "title": "Settings",
            "query": {
                "models": "schemas",
                "collectionId": collectionId
            },
        }
    ]

    if (user && user.isSystemAdmin) {
        return getMenuChildren({children: childrenWithAdmin, ...app})
    }

    return getMenuChildren({children: children, ...app})

}

export default getCollectionMenu