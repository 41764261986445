export default {
    "select": {
      "imageUrl" : "imageUrl",
      "title" : "title",
      "description" : "description",
      "metadata" : "metadata",
      "typeLabel": "typeLabel"
    },
    prepare({formData, formContext}) {
      let { documentType, locale, imageUrl, title, content, description } = formData
  
      const { defaultLocale } = formContext
  
      const localeId = "locale:" + defaultLocale;
  
      let metadata = []
      
      if (content) {
  
          title = content.title && content.title[localeId]
          description = content.description && content.description[localeId]
          imageUrl = content.image && content.image.media && content.image.media.imageUrl        
  
          const { location, sections, hasArticles } = content
  
          if (location && location.lat && location.lng) {
              
              if (location.address) {
                  metadata.push(location.address)
              } else {
                  metadata.push("Sted")
              }
              
          }
  
          if (sections && sections.length) {
              metadata.push(sections.length + ' seksjoner')
          }
  
          if (hasArticles && hasArticles.length) {
              metadata.push(hasArticles.length + ' artikler')
          }
          
                  
      }
  
      return {
        imageUrl: imageUrl,
        title: title,
        description: description,
        metadata: metadata,
        typeLabel: documentType
      }
      
    }
    
  }