import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    time: {
        fontFamily: "Akkurat Mono, monospace",
        fontSize: "14px",
        lineHeight: 1,
        marginTop: theme.spacing(.25)
    },
    progress: {
        flexGrow: 1,
        margin: theme.spacing(0, 2)
    }
}));

export const getTime = (timeInSeconds) => {

    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

//    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);

    if (hours) {
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
    }

    return minutes + ':' + pad(seconds, 2);
}

const MediaTimeControls = ({className, buffered, duration = 0, currentTime = 0, onChange}) => {

    const classes = useStyles()

    const timePlayed = getTime(currentTime)
    const timeTotal = getTime(duration)

    const handleChange = (event, value) => {
        onChange && onChange(value) 
    }

    return (
        <div className={className || classes.root}>
            <div className={classes.time}>{timePlayed}</div>
            <Slider className={classes.progress} min={0} max={duration} value={currentTime} onChange={handleChange} />
            <div className={classes.time}>{timeTotal}</div>
        </div>
    )

}

export default MediaTimeControls