import React, { Component } from 'react';
import { IframeChildren, IframeUrl } from "../"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: 0,
        margin: 0,
        border: 0
    }
}));


const PreviewIframe = ({url, children, ...props}) => {
    const classes = useStyles()

    if (url) {
        return (
            <IframeUrl {...props} url={url} className={classes.root}/>
        )
    }

    return (
        <IframeChildren {...props} className={classes.root}>
            {children}
        </IframeChildren>
    )

}

export default PreviewIframe;