import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: 1,
        overflowY: "scroll",
        padding: props => { return theme.spacing(props.padding) },
        top: theme.spacing(8),
        right: 0,
        bottom: 0,
        left: 0
    }
}));

const DialogBody = ({className, padding = 0, children}) => {
    const classes = useStyles({padding})

    return (
        <div className={className || classes.root}>
            {children}
        </div>
    )
}

export default DialogBody;