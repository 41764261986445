import React, { Component } from 'react';
import TableRow from "./AnalyticsTableRow"

class AnalyticsTableBody extends Component {
    
    static defaultProps = {
        rows: []
    }
    
    getClassName = () => {
        return "admin-analytics-table__body"
    }
    
    renderRow = (row, index) => {
        const { dimensionHeaders, metricHeaders, totals, sortBy, urlPattern } = this.props;
        
        return (
            <TableRow {...row} urlPattern={urlPattern} dimensionHeaders={dimensionHeaders} metricHeaders={metricHeaders} totals={totals} sortBy={sortBy} key={index} />
        )
        
    }
    
    render() {
        const { rows, sortedRows } = this.props;
        const className = this.getClassName();
        
        return (
            
            <tbody className={className}>
                { sortedRows.map(this.renderRow) }
            </tbody>

        )
        
    }
    
}

export default AnalyticsTableBody;