export default {
  "select": {
    "imageUrl" : "imageUrl",
    "title" : "title",
    "description" : "description",
    "metadata" : "metadata",
    "label": "label"
  },
  prepare(selection) {
    let { locale, imageUrl, title, content, description } = selection

    const label = 'kp/article';
    
    let metadata = []
    
    if (content) {
        const { header, sections, isPartOf, location } = content;
        
        let localeId
        
        if (locale) {
            localeId = "locale:" + locale;
        }
        
        if (header) {

            if (header.title && header.title[localeId]) {
                title = header.title[localeId]
            }
    
            if (header.description && header.description[localeId]) {
                description = header.description[localeId]
            }
    
            if (header.image && header.image.media && header.image.media.imageUrl) {
                imageUrl = header.image.media.imageUrl;
            }

        }

        if (location && location.lat && location.lng) {
            
            if (location.address) {
                metadata.push(location.address)
            } else {
                metadata.push("Sted")
            }
            
        }

        if (sections && sections.length) {
            metadata.push(sections.length + ' seksjoner')
        }

        if (isPartOf && isPartOf.length) {
            metadata.push(isPartOf.length + ' tema')
        }
        
                
    }

    return {
      imageUrl: imageUrl,
      title: title,
      description: description,
      metadata: metadata,
      label: label
    }
    
  }
  
}