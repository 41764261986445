import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAppLayout } from '../redux/functions/app';
import { Dashboard, DashboardGrid } from '@frontend-components/admin';

import {
    WidgetSessionsByOwner,
    WidgetOwnersVsTotal,
    WidgetLiveReport
} from "../AppAnalytics"


import {
    WidgetInbox
} from "../AppInbox"


import { 
    WidgetUser, 
    WidgetUserApps,
    WidgetUserCalendar,
    WidgetUserAnalytics,
} from "./"

class UserDashboard extends Component {
    
    componentDidMount() {
        this.onLoad()
    }
    
    componentDidUpdate = (prevProps) => {

        if (prevProps.user !== this.props.user ||
            !this.props.location ||
            prevProps.location !== this.props.location) {
                this.onLoad()
        }
    
    }

    onSelect = ({url}) => {
        url && this.props.history.push(url)
    }
    
    onLoad = () => {
        this.props.getAppLayout("dashboard");
    }
    
    render() {
        const { app, user } = this.props;
    
        return (
            <Dashboard>
                <DashboardGrid>
                    <WidgetOwnersVsTotal id="/user/owners" theme={user.theme} widgetSize="m:3" />
                    <WidgetLiveReport id="/user/live" theme={user.theme} widgetSize="m:3" />
                    <WidgetUserApps user={user} widgetSize="xl:xs" onSelect={this.onSelect} />
                    <WidgetUser user={user}  widgetSize="s:m" onSelect={this.onSelect} />
                    <WidgetInbox user={user} widgetSize="l:m" onSelect={this.onSelect} />
                </DashboardGrid>
            </Dashboard>
        )

    }

}

function mapStateToProps(state) {
	return {
    	app: state.app,
      	user: state.user
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAppLayout }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDashboard);