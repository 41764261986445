import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IframeUrl extends Component {

    static propTypes = {
        className: PropTypes.string,
        url: PropTypes.string,

        // Callback function called when iFrame sends the parent window a message.
        onReceiveMessage: PropTypes.func,

        /*    
            Callback function called when iframe loads. 
            We're simply listening to the iframe's `window.onload`.
            To ensure communication code in your iframe is totally loaded,
            you can implement a syn-ack TCP-like handshake using `postMessage` and `onReceiveMessage`.
        */
        onLoad: PropTypes.func,
        
        /*
            You can pass it anything you want, we'll serialize to a string
            preferablly use a simple string message or an object.
            If you use an object, you need to follow the same naming convention
            in the iframe so you can parse it accordingly.
        */
        postMessage: PropTypes.any.isRequired,
        
        /*
            Enable use of the browser's built-in structured clone algorithm for serialization
            by settings this to `false`. 
            Default is `true`, using our built in logic for serializing everything to a string.
        */
        serializeMessage: PropTypes.bool,

        /*
            Always provide a specific targetOrigin, not *, if you know where the other window's document should be located. Failing to provide a specific target discloses the data you send to any interested malicious site.
        */
        targetOrigin: PropTypes.string        

    }

    static defaultProps = {
        serializeMessage: true,
        targetOrigin: "*",
        postMessage: ""        
    }

    constructor() {
        super();
        this.onReceiveMessage = this.onReceiveMessage.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }
    
    componentDidMount() {
        window.addEventListener("message", this.onReceiveMessage);
        this._frame.addEventListener("load", this.onLoad);
    }
    
    componentWillUnmount() {
        window.removeEventListener("message", this.onReceiveMessage, false);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.postMessage !== this.props.postMessage) {
            this.sendMessage();
        }
    }
    
    onReceiveMessage(event) {
        if (this.props.onReceiveMessage) {
            this.props.onReceiveMessage(event);
        }
    }

    onLoad = () => {
        if (this.props.onLoad) {
            this.props.onLoad();
        }

        // TODO: Look into doing a syn-ack TCP-like handshake
        //       to make sure iFrame is ready to REALLY accept messages, not just loaded.
        // send intial props when iframe loads

        this.sendMessage();
    }

    serializepostMessage = (data) => {

        // Rely on the browser's built-in structured clone algorithm for serialization of the
        // message as described in
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
        if (!this.props.serializeMessage) {
            return data;
        }

        // To be on the safe side we can also ignore the browser's built-in serialization feature
        // and serialize the data manually.
        if (typeof data === "object") {
            return JSON.stringify(data);
        } else if (typeof data === "string") {
            return data;
        } else {
            return `${data}`;
        }

    }

    sendMessage = () => {
        // Using postMessage data from props will result in a subtle but deadly bug,
        // where old data from props is being sent instead of new postMessage.
        // This is because data sent from componentWillReceiveProps is not yet in props but only in nextProps.
        const { postMessage, targetOrigin } = this.props;
        const serializedData = this.serializepostMessage(postMessage);
        this._frame.contentWindow.postMessage(serializedData, targetOrigin);
    }
    
    render() {
        const { className, url } = this.props;
 
        return (
            <iframe ref={el => {this._frame = el}} className={className} src={url} />
        )
 
    }

}

export default IframeUrl;