import React, { useRef } from 'react';
import { WidgetSearch, WidgetScroller, OwnersList } from "../"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        width: "100%",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    search: {
        display: "flex",
        position: "relative",
        backgroundColor: "transparent",
        color: "black",
        height: "36px",
        overflow: "hidden",
        fontFamily: "Akkurat, sans-serif",
        margin: "12px"
    },
    scroller: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    results: {
        paddingTop: theme.spacing(7),
        display: "block",
        width: "100%"
    },
    nohits: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        padding: theme.spacing(1.5)
    }
}));

const FilterOwnersUncontrolled = ({className, search, q, owners = [], onSearch, onSelect}) => {
    const classes = useStyles()

    if (!owners.length) {
        return (
            <div className={className || classes.root}>
                <WidgetSearch {...search} value={q} onChange={onSearch} />
                <WidgetScroller className={classes.scroller}>
                    <div className={classes.results}>
                        <p className={classes.nohits}>Ingen treff</p>
                    </div>
                </WidgetScroller>
            </div>
        )
    }

    const placeholder = search.placeholder.replace('{hits}', owners.length)

    return (
        <div className={className || classes.root}>
            <WidgetSearch {...search} placeholder={placeholder} value={q} onChange={onSearch} />
            <WidgetScroller className={classes.scroller}>
                <OwnersList className={classes.results} owners={owners} onSelect={onSelect} />
            </WidgetScroller>
        </div>
    )

}

FilterOwnersUncontrolled.defaultProps = {
    search: {
        placeholder: "Søk i {hits} eiere"
    }
}

export default FilterOwnersUncontrolled;