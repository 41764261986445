import React, { useEffect, useState } from 'react';
import { getUiOptions, getDefaultFormState } from "@frontend-components/admin"

const BulkStringField = ({schema, uiSchema, formData, ...props}) => {

    let newFormData
    
    if (formData === "**multiple**") {
        newFormData = null
    } else {
        newFormData = formData
    }

    const newSchema = {
        ...schema,
        "default": formData && formData !== "**multiple**" && formData
    }

    const newUiSchema = {
        ...uiSchema,
        "ui:placeholder": formData && formData === "**multiple**" && "[Multiple values]"
    }

    const { registry } = props;
    const { StringField } = registry.fields

    return (
        <StringField {...props} formData={newFormData} schema={newSchema} uiSchema={newUiSchema} />
    )

    return (
        <div>
            <StringField {...props} formData={newFormData} schema={newSchema} uiSchema={newUiSchema} />
            {JSON.stringify(formData)}
            {JSON.stringify(newSchema)}
        </div>
    )
    
}

export default BulkStringField