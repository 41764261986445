const initialState = []

const userApps = (state = initialState, action) => {

    switch (action.type) {
    	
        case 'REQUEST_USER_APPS':
            return []
        
        case 'RECEIVE_USER_APPS':
            return action.apps
        
        default:
            return state
    
    }

}

export default userApps;