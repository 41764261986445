import getInboxAuthors from "./getInboxAuthors"
import getInboxLabel from "./getInboxLabel"
import getInboxBody from "./getInboxBody"
import getUrlFromProps from "./getUrlFromProps"

export const getInboxModule = (props) => {
    const { inboxType, title, messages, messagesCount, updatedAt, createdAt, uniqueId, urlPattern } = props;

    const datetime = updatedAt || createdAt;

    if (!messages) {
        return props
    }
        
    // url
    
    const url = getUrlFromProps(props);

    // authors

    const { authors, authorNames } = getInboxAuthors(props);

    // messages
    
    let count = parseInt(messagesCount) || messages && parseInt(messages.length);
    
    if (count < 2) {
        count = undefined
    }
    
    // first message
    
    const m1 = messages[0]
    const { author, subject, message } = m1;
    
    const { text, markdown } = getInboxBody({message})

    // label + color

    const { label, labelColor } = getInboxLabel(props)
    
    // from
    
    return {
        ...props,
        url: url,
        datetime: datetime,
        author: author,
        authors: authors,
        authorNames: authorNames,
        messagesCount: count,
        subject: subject || title,
        message: text,
        label: label,
        labelColor: labelColor
    }    
}

export default getInboxModule