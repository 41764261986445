import React from 'react';
import { LayoutList } from "../components"

import ResultsLoader from "./ResultsLoader"
import ResultsFooter from "./ResultsFooter"
import ResultsModel from "./ResultsModel"

const ResultsList = ({layout = "list", ...props}) => {
    const { resultsLoaded = [] } = props;

    return (
        <ResultsLoader {...props}>
            <LayoutList padding={2}>
                {resultsLoaded.map((model, index) => {
                    return (
                        <ResultsModel model={model} layout={layout} {...props} key={index} />
                    )
                })}
            </LayoutList>
            <ResultsFooter {...props} />
        </ResultsLoader>
    )

}

export default ResultsList