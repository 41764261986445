import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
    return (
        <SvgIcon>
            <rect x="4" y="3" width="16" height="2" fill="black"/>
            <path d="M14 12C14 13.6569 15.3431 15 17 15V15C18.6569 15 20 13.6569 20 12V12C20 10.3431 18.6569 9 17 9V9C15.3431 9 14 10.3431 14 12V12Z" fill="black"/>
        </SvgIcon>
    );
}