import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Dashboard, DashboardGrid } from '@frontend-components/admin';
  
class TemplateDashboard extends Component {

    render() {
        
        const { header } = this.props;

        return (
            
            <Dashboard>
                <DashboardGrid>
                    { this.props.children }
                </DashboardGrid>
            </Dashboard>
    
        )
        
    }

}


function mapStateToProps(state) {
	return {
    	app: state.app
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateDashboard);

