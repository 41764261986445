import _ from "lodash"
import { API } from '../settings';
import { 
    requestAuthApps, receiveAuthApps, receiveAuthAppByName, receiveAuthAppById,
    requestAuthSites, receiveAuthSites, receiveAuthSiteByName, receiveAuthSiteById,
    requestAuthSiteApps, receiveAuthSiteApps,
    requestAuthSiteCollections, receiveAuthSiteCollections,
    requestAuthUsers, receiveAuthUsers, receiveAuthUserById, 
    requestAuthRoles, receiveAuthRoles
} from '../actions/auth';

import { siteApps } from "../apps/"
import { getSiteById } from "./authSites"

export function getAuthApps() {
    
    const models = Object.values(siteApps)
    
    const apps = models.map(app => {
        return getAppAuthData(app)
    })
    
    return function(dispatch) {
        dispatch(requestAuthApps())
        dispatch(receiveAuthApps(apps))
        
        apps.map(app => {
            dispatch(getAppByName(app.name))
        })
        
    }
    
}

export function getAppById(id) {

    const url = API + "/admin/api/collections/" + id;
    
    return function (dispatch) {

        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getAppAuthUsers(id, data)),
        )

    }

}

export function getAppAuthUsers(id, app) {
    const url = API + '/admin/api/users/search';

    return function (dispatch) {

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getAppAuthUsersData(id, app, data)),
        )

    }

}

export function getAppAuthUsersData(id, app, data) {
    const { models } = data;
    
    let authUsers = []
    
    models.map(user => {
        const { authorizedCollections } = user
        
        authorizedCollections.map(authApp => {
            const { collection, role } = authApp;
            
            if (collection.id === app.id) {
                authUsers.push({
                    ...user,
                    userId: user.id,
                    collectionId: collection.id,
                    roleId: role.id
                })
            }
            
        })
        
        
    })
    
    app = {
        ...app,
        authUsers: authUsers
    }
    
    app = getAppAuthData(app)
    
    return function(dispatch) {
        dispatch(receiveAuthAppById(id, app))
    }
    
}


export function getAppByName(name) {
    
    const app = getAppAuthData(siteApps[name])
  
    return function (dispatch) {
        dispatch(getAppAuthCollections(app))
    }

}

export function getAppAuthCollections(app) {
    const { collectionType } = app;
    
    const url = API + "/admin/api/collections/search?collectionType=" + collectionType;

    return function (dispatch) {

        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getAppAuthCollectionsSites(app, data)),
        )

    }

}

export function getAppAuthCollectionsSites(app, data) {
    const { appId, appName } = app;
    const { models } = data;
    
    let authSitesById = {}, authCollectionsById = {}
    
    models.map(collection => {
        collection = getAppAuthData(collection);

        const { id, siteId, siteName, collectionType, status } = collection;

        authCollectionsById[id] = getAppAuthData(collection)
        
        const site = {
            appId: appId,
            siteId: siteId,
            collectionType: collectionType,
            collectionId: id,
            appStatus: status
        }
        
        authSitesById[siteId] = site;
        
    })
    
    app = {
        ...app,
        authCollections: Object.values(authCollectionsById),
//        authCollectionsById: authCollectionsById,
        authSites: Object.values(authSitesById),
//        authSitesById: authSitesById
    }
    
    
    app = getAppAuthData(app)
    
    
    return function (dispatch) {

        app.authCollections.map(app => {
            dispatch(getAppById(app.id, app))
        })
        
        /*
        
        app.authSites.map(site => {
            dispatch(getSiteById(site.siteId))
        })

        */
        
        dispatch(receiveAuthAppByName(appName, app))
    }
    
}

export function getAppAuthData(app) {
    let { id, name, status, authSites, authUsers, authCollections } = app;
    
    let appStatus = status;
    
    if (!id) {
        appStatus = undefined
    }
    
    let authSitesById = {}, authUsersById = {}, authCollectionsById = {}
    
    if (authSites && authSites.length) {
        authSites.map(site => {
            authSitesById[site.siteId] = site
        })
    }

    if (authUsers && authUsers.length) {
        authUsers.map(user => {
            authUsersById[user.userId] = user
        })
    }

    if (authCollections && authCollections.length) {
        authCollections.map(collection => {
            authCollectionsById[collection.collectionId] = collection
        })
    }
    
    return {
        ...app,
        authSitesById: authSitesById,
        authUsersById: authUsersById,
        authCollectionsById: authCollectionsById,
        
        appId: id || name,
        appName: name,
        appStatus: appStatus,
        collectionId: id
    }
    
}

