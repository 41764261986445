import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import CollectionRoutes from "../AppCollection/CollectionRoutes"
import DocumentEditor from "../AppDocuments/DocumentEditor"

class KpRoutes extends Component {
  
  render() {
    
    return (
      
        <Switch>
            <Route path="/:siteName/:appName/tree/:parentId/:uniqueId" component={ CollectionRoutes } />
            <Route path="/:siteName/:appName/tree/:uniqueId" component={ CollectionRoutes } />

            <Route path="/:siteName/:appName/media/:mediaType/:uniqueId/edit" component={ CollectionRoutes } />
            <Route path="/:siteName/:appName/media/:uniqueId/edit" component={ CollectionRoutes } />

            <Route path="/:siteName/:appName/:documentType/:uniqueId/edit" component={ DocumentEditor } />
            <Route path="/:siteName/:appName/:uniqueId/edit" component={ DocumentEditor } />

            <Route path="/:siteName/:appName/:parent" component={ CollectionRoutes } />
            <Route path="/:siteName/:appName" component={ CollectionRoutes } />

        </Switch>

    )

  }

}

export default KpRoutes;