import User from './User';
import UserDashboard from './UserDashboard';

import EditProfile from './EditProfile';

import WidgetUser from './WidgetUser';
import WidgetUserCalendar from './WidgetUserCalendar';
import WidgetUserApps from './WidgetUserApps';

export {
    UserDashboard,
    EditProfile,
    WidgetUser,
    WidgetUserCalendar,
    WidgetUserApps
}

export default User