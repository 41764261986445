const getInboxAuthor = ({name, status, ...author}) => {
        
    if (name) {

        const names = name.split(" ");

        name = names[0]

        if (name.length > 12) {
            name = name.substr(0, 12) + "."
        }

        if (names.length > 1) {
            for (let i = 1; i < names.length; i++) {
                name = name + " " + names[i].substr(0, 1) + "."
            }
        } 
        
    }

    return {
        ...author,
        name: name,
        status: status
    }

}

export const getInboxAuthors = ({messages = []}) => {

    let read = []
    let readAuthors = []

    let unread = []
    let unreadAuthors = []

    let unique = []
    let uniqueAuthors = []
    
    messages.map(message => {
    
        let { author } = message;
        const { name } = author;
        const names = name.split(" ");

        const firstName = names[0];
        const lastName = names[names.length-1];
        const shortName = firstName + " " + lastName.substr(0,1) + "."


        author = {
            ...author,
            firstName: firstName,
            lastName: lastName,
            shortName: shortName,
            unread: message.unread
        }
        
        if (message.unread && !unread.includes(name)) {
            unread.push(name)
            unreadAuthors.push(author)
            
        } else if (!read.includes(name)) {
            read.push(name)
            readAuthors.push(author)
        }
        
        if (!unique.includes(name)) {
            unique.push(name)
            uniqueAuthors.push(author)
        }
        
    })

    return {
        authors: uniqueAuthors,
        authorNames: unique
    }
    
    /*
    return {
        unique: uniqueAuthors,
        read: readAuthors,
        unread: unreadAuthors
    };
    */

    let authors = []

    if (unique.length <= 2) {
        authors = unique
    }        

    if (read.length && unread.length) {
        authors = unread
        authors.push(read[0])
    }

    return authors
    
}

export default getInboxAuthors