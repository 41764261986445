import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getApp } from './app';
import qs from 'query-string';
import moment from 'moment';

import CalendarLayout from "./CalendarLayout"
import CalendarQuery from "./CalendarQuery"
import CalendarResults from "./CalendarResults"

import { useTranslation } from 'react-i18next';

const CalendarFinder = ({app, ...props}) => {
    const { t, i18n } = useTranslation();

    const sq = props.location && props.location.search && qs.parse(props.location.search) || {}

    const { yyyy, mm, dd } = props.match.params;

    let year, month, date;

    if (yyyy && mm && dd) {
        date = yyyy + "-" + mm + "-" + dd
    }

    if (yyyy && mm) {
        month = mm
//        month = yyyy + "-" + mm
    }

    if (yyyy) {
        year = yyyy
    }

    const selectDate = ({date, url}) => {
        const query = qs.stringify({
            ...sq,   
            date: date
        })

        url = props.location.pathname + "?" + query;
        url = app.root + "/" + moment(date).format('YYYY/MM/DD');
        
        props.history.replace(url)

        //        url && props.history.push(url);
    }

    const calendar = {
        date: date,
        onSelect: selectDate
    }

    const query = {
        models: "documents",
        documentType: "event",
        date: date,
        year: year,
        month: month,
    }

    const handleSetting = (name, value) => {
        const query = qs.stringify({
            ...sq,   
            [name]: value
        })

        const url = props.location.pathname + "?" + query;
        
        props.history.replace(url)
    }

    let settings = []

//    const { rowsOptions, layoutOptions, sortOptions } = props;

    const layoutOptions = ["year","month","date","schedule"]

    if (layoutOptions) {

        settings.push({
            "name": "layout",
            "value": sq.layout || undefined,
            "onChange": handleSetting,
            "options": layoutOptions.map(value => {
                return {
                    label: t("layout:"+value || value),
                    value: value
                }
            })

        })

    }

    let title

    if (date) {
        title = moment(date).format('LL')
    } else if (year && month) {
        title = moment(year + "-" + month + "-01").format('MMMM YYYY')
    } else if (year) {
        title = year
    }


    return (
        <CalendarLayout {...app} settings={settings} query={query} title={title} onSelect={selectDate}>
            <CalendarQuery {...props} query={query} onSelect={selectDate} />
        </CalendarLayout>
    )

}

function mapStateToProps(state) {
	return {
        user: state.user,
        app: state.app,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarFinder);

