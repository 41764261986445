import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        marginBottom: props => { return theme.spacing(props.spacing)},

        "& > header": {
            position: "sticky",
            zIndex: 2,
            top: 0,
        }

    },
    header: {
        display: "flex",
        width: "100%",
        alignItems: "baseline",
    },
    title: {
        fontSize: "14px",
        fontWeight: "bold",
    }
}));

const FinderSection = ({expanded = true, spacing = 2, label, title, description, action = [], children}) => {

    const classes = useStyles({spacing})

    if (!title && label) {
        title = label
    }

    const renderHeader = () => {

        if (title) {
            return (
                <header className={classes.header}>
                    <div className={classes.leading}>
                        <Typography className={classes.title} nowrap>{title}</Typography>
                        { description &&  <Typography className={classes.description}>{description}</Typography> }
                    </div>
                </header>
            )
        }
        
    }

    return (
        <section className={classes.root} aria-expanded={expanded}>
            { renderHeader() }
            { expanded && children }
        </section>
    )

}

export default FinderSection;