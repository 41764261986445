import { getUiOptions, retrieveSchema, getDefaultFormState } from './';
import _ from "lodash";

export function getUiFieldset(props) {
    const { id, idSchema, schema, uiSchema, formData, registry } = props;
    const { definitions, formContext } = registry;

    const uiOptions = getUiOptions(uiSchema);
    const fieldset = uiOptions.fieldset || false;
    
    if (!fieldset) {
        return false
    }

    let uiFieldset = [];

    if (fieldset) {

        fieldset.forEach(name => {
            
            // get fieldId

            let fieldId;

            if (id && name) {
                fieldId = id + '_' + name
            } else {
                fieldId = idSchema.$id;
            }

            // selected?
            
            let selected = false;

            if (formContext.currentId && formContext.currentId.startsWith(fieldId)) {
                selected = true;
            }

            // schema, uiSchema, formData

            const fieldSchema = schema && schema.properties && schema.properties[name] && retrieveSchema(schema.properties[name], definitions, formData[name])
            const fieldFormData = fieldSchema && formData && formData[name] && getDefaultFormState(fieldSchema, formData[name])
            const fieldUiSchema = uiSchema && uiSchema[name] || {}
            const fieldUiOptions = fieldUiSchema && getUiOptions(fieldUiSchema)

            uiFieldset.push({
                id: fieldId,
                name: name,
                selected: selected,
                schema: fieldSchema,
                uiSchema: fieldUiSchema,
                uiOptions: fieldUiOptions,
                formData: fieldFormData
            })
        })

    }

    const selected = _.find(uiFieldset, function (field) {
        return field.selected === true;
    })

    if (uiFieldset[0] && !selected) {
        uiFieldset[0].selected = true;
    }

    return uiFieldset;
}
