import React, { useState } from 'react';
import DateInputBase from "./DateInputBase"

const DateField = ({id, label, value, helperText, onChange}) => {

    return (
        <DateInputBase id={id} label={label} value={value} helperText={helperText} onChange={onChange} />
   )

}

export default DateField