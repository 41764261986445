import React from "react"

import LicenseIconCC from "./icons/CC"
import LicenseIconBy from "./icons/By"
import LicenseIconNd from "./icons/Nd"
import LicenseIconNc from "./icons/Nc"
import LicenseIconSa from "./icons/Sa"
import LicenseIconPd from "./icons/Pd"
import LicenseIconPdm from "./icons/Pdm"
import LicenseIconCopyright from "./icons/Copyright"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        "& * + *": {
            marginLeft: theme.spacing(1)
        }
    },
    icon: {
        fontSize: "inherit",
        fill: "currentColor",
        "& *": {
            fill: "currentColor"
        }
    }
}))

const icons = {
    "cc": LicenseIconCC,
    "by": LicenseIconBy,
    "nd": LicenseIconNd,
    "nc": LicenseIconNc,
    "sa": LicenseIconSa,
    "pd": LicenseIconPd,
    "pdm": LicenseIconPdm,
    "copyright": LicenseIconCopyright,
}

const LicenseIcon = ({className, license}) => {

    const licenseIcons = license.split('-') || [license]

    const classes = useStyles()

    return (
        <div className={className || classes.root}>
            {licenseIcons.map(icon => {

                const Icon = icons && icons[icon]

                if (Icon) {
                    return <Icon className={classes.icon} />
                }

                return false

            })}
        </div>
    )

    return (
        <p>{JSON.stringify(licenseIcons)}</p>
    )


}

export default LicenseIcon