import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AnalyticsWidget } from '@frontend-components/admin';

import report3 from './example.report3.json';

class WidgetAppReport extends Component {
    
    render() {
        const { app } = this.props;
        const url = app.root + "/analytics"
    
        return (
            <AnalyticsWidget data={report3} title="Besøk siste uke" summary="33.3% flere besøkende" url={url} label="Flere tall" icon="trending_up" {...this.props} />
        )
    
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app
	};
}  

export default connect(
  mapStateToProps,
)(WidgetAppReport);