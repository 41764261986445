import React, { Component } from 'react';
import ButtonFilter from "./ImageFiltersButton"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "50%",
        paddingRight: theme.spacing(1),
        overflowX: "scroll"
    }
}));

const FilterOptions = ({className, imageFilters, imageUrl, options = [], onChange}) => {
    const classes = useStyles()

    const applyFilter = ({imageFilters}) => {
        onChange && onChange(imageFilters)
    }

    return (
        <footer className={className || classes.root}>
            {options.map((option, index) => {
                const selected = imageFilters === option.imageFilters
                return (
                    <ButtonFilter {...option} imageUrl={imageUrl} selected={selected} key={index} onClick={() => applyFilter(option)} />
                )
            })}
        </footer>
    )
 
}

FilterOptions.defaultProps = {
    options: [
        {
            label: "Original",
            imageFilters: {}
        },
        {
            label: "Vignette",
            imageFilters: {
                vignette: true,
            }
        },
        {
            label: "Duotone",
            imageFilters: {
                duotone: true,
            }
        },
        {
            label: "Max contrast",
            imageFilters: {
                contrast: 100,
            }
        },
        {
            label: "Min contrast",
            imageFilters: {
                contrast: -50,
            }
        },
        {
            label: "Max bright",
            imageFilters: {
                brightness: 50,
            }
        },
        {
            label: "Min bright",
            imageFilters: {
                brightness: -50,
            }
        },
        {
            label: "Blur 0",
            imageFilters: {
                blur: 0,
            }
        },
        {
            label: "Blur 1",
            imageFilters: {
                blur: 1,
            }
        },
        {
            label: "Blur 2",
            imageFilters: {
                blur: 2,
            }
        }
    ],    
}

export default FilterOptions;