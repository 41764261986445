import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadModel, editModel, saveModel, getModel } from '../redux/functions/model';
import qs from 'query-string';

import AppEditor from '../AppEditor';

import DocumentSidebar from "../AppDialog/DialogApp"
import DocumentPreview from './DocumentPreview';

const DocumentEditor = ({modelName = "documents", app, registry, formData, formContext, schemasByName, modelsById, ...props}) => {
    const [schemaType, setSchemaType] = useState(null)
    const [schemaId, setSchemaId] = useState(null)
    const [schema, setSchema] = useState(null)
    const [uiSchema, setUiSchema] = useState(null)

    const sq = props.location && props.location.search && qs.parse(props.location.search) || {}
    
    const documentType = props.match.params.documentType
    const uniqueId = props.match.params.uniqueId

    const parentId = props.match.params.parentId
    const parentModel = parentId && modelsById[parentId] || undefined

    useEffect(() => {
        parentId && props.getModel(modelName, parentId);
    }, [parentId])

    const referenceId = props.match.params.referenceId
    const referenceModel = referenceId && modelsById[referenceId] || undefined

    useEffect(() => {
        referenceId && props.getModel(modelName, referenceId);
    }, [referenceId])

    useEffect(() => {
        if (!uniqueId && documentType) {
//            setSchemaType(documentType)
            setSchemaType(app.root + "/" + documentType)
        } else if (uniqueId) {
            setSchemaType(undefined)
            props.loadModel(modelName, uniqueId);
        }
    }, [uniqueId])

    const uniqueModel = modelsById && modelsById[uniqueId]

    useEffect(() => {
        if (uniqueModel && uniqueModel.source) {
            setSchemaType("document/"+uniqueModel.source)
        } else if (uniqueModel && uniqueModel.documentType) {
            setSchemaType(app.root + "/" + uniqueModel.documentType)
        }
    }, [uniqueModel])    

    const modelSchema = schemaType && schemasByName && schemasByName[schemaType]

    useEffect(() => {
        modelSchema && modelSchema.id && setSchemaId(modelSchema.id)
        modelSchema && modelSchema.schema && setSchema(modelSchema.schema)
        modelSchema && modelSchema.uiSchema && setUiSchema(modelSchema.uiSchema)
    }, [modelSchema])

    // edit

    const collectionId = app && app.collectionId
    const languages = app && app.languages;
    const defaultLocale = app && app.defaultLocale || app && app.languages[0];

    useEffect(() => {
        if (uniqueModel) {
            props.editModel(uniqueModel)
        } else {
            props.editModel({
                schemaId: schemaId,
                locale: defaultLocale,
                parentId: parentModel && parentModel.id,
                collectionId: collectionId,
                documentType: documentType,
                status: "new"
            })
        }
    }, [schemaId, defaultLocale])

    const onSubmit = ({formData, redirect}) => {
        const status = formData && formData.status

        if (status === "copy") {
            props.saveModel(modelName, {
                schemaId: formData && formData.schemaId,
                collectionId: formData && formData.collectionId,
                locale: formData && formData.locale,
                documentType: formData && formData.documentType,
                content: formData && formData.content,
                status: "new",
                title: formData.title + " (copy)"
            });
        } else {
            props.saveModel(modelName, formData, redirect && false)
        }

        if (redirect) {
            props.history.replace(redirect);
            redirect = null
        }

    }

    const onEditChild = ({formData}) => {
        const childUrl = app.root + "/" + uniqueId + "/children/" + formData.documentType + "/" + formData.uniqueId + "/edit"
        props.history.push(childUrl);
    }

    const onCreateChild = ({documentType}) => {
        const childUrl = app.root + "/" + uniqueId + "/children/" + documentType + "/<new>/edit"
        props.history.push(childUrl);
    }

    const onEditMedia = ({id, formData: { mediaId, media: { mediaType } }}) => {
        const mediaUrl = app.root + "/media/" + mediaId + "/edit/" + uniqueId

        props.history.push({
            pathname: mediaUrl,
            search: "?backUrl=" + props.location.pathname + "&backId=" + id
        })

   }    
    const onEditReference = ({id, formData: { referenceId, reference: { documentType } }}) => {
         const referenceUrl = app.root + "/" + referenceId + "/edit/" + uniqueId

        props.history.push({
            pathname: referenceUrl,
            search: "?backUrl=" + props.location.pathname + "&backId=" + id
        })

    }

    const getReferenceParent = (referenceModel) => {
        const { backUrl, backId } = sq

        let referenceUrl;

        if (backUrl && backId) {
            referenceUrl = decodeURI(backUrl).replace(' ', '+') + "#" + backId
        } else {
            referenceUrl = app.root + "/" + referenceModel.uniqueId + "/edit"
        }

        referenceModel && setParents([{
            title: referenceModel.title,
            url: referenceUrl
        }])
    }

    const [parents, setParents] = useState(app && app.parents)

    useEffect(() => {
        referenceModel && getReferenceParent(referenceModel)
        app && !parentId && !referenceId && setParents(app && app.parents)
    }, [app.parents, referenceModel])

    /*
    useEffect(() => {
        parentId && modelsById[parentId] && getParentDocument(parentId)
        referenceId && modelsById[referenceId] && getParentDocument(referenceId)
        app && !parentId && !referenceId && setParents(app && app.parents)
    }, [modelsById, app])
    */
    
    if (!schemaType || !schema || !schemaId || !defaultLocale) {
        return (
            <ul>
                <li>source: {JSON.stringify(uniqueModel && uniqueModel.source)}</li>
                <li>uniqueId: {JSON.stringify(uniqueId)}</li>
                <li>schemaType: {JSON.stringify(schemaType)}</li>
                <li>schemaId: {JSON.stringify(schemaId)}</li>
                <li>defaultLocale: {JSON.stringify(defaultLocale)}</li>
                <li>schema: {JSON.stringify(schema)}</li>
            </ul>
        )
    }   

    let preview;

    if (formContext && formContext.preview) {
        preview = formContext.preview
    } else {
        preview = {
            template: DocumentPreview
        }
    }

    if (!app.uniqueId) {
        return <p>No app</p>
    }

    const newFormContext = {
        schemaId: schemaId,
        uniqueId: uniqueId,
        parentId: parentId,
        referenceId: referenceId,
        parents: parents,
        languages: languages,
        preview: preview,
        sidebar: {
            template: DocumentSidebar
        },
        onEditMedia: onEditMedia,
        onEditReference: onEditReference,
        onCreateChild: onCreateChild,
        onEditChild: onEditChild,
        ...formContext
    }
        
    if (!newFormContext) {
        return (
            <p>Get formContext</p>
        )
    }

    if (!schemaType || !schemaId) {
        return (
            <p>Get model</p>
        )
    }

    return (
        <AppEditor {...props}
            registry={registry}
            schema={schema}
            uiSchema={uiSchema}
            formContext={newFormContext}
            onSubmit={onSubmit}
        />
    )
  
}

// mapStateToProps

function mapStateToProps(state) {
	return {
        app: state.app,
        schemasByName: state.schemasByName,
        modelsById: state.modelsById
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        loadModel, editModel, saveModel, getModel,
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentEditor);

