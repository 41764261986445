import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditModule from "./EditModule"
import ListModule from "./ListModule"
import TableModule from "./TableModule"
import GridModule from "./GridModule"

const templates = {
    "list": ListModule,
    "table": TableModule,
    "grid": GridModule
}

const Module = ({
    variant = "list",
    imageUrl = undefined, 
    title = undefined, 
    description = undefined, 
    label = undefined,
    status = undefined,
    author = undefined,
    datetime = undefined,
    children,
    ...props
}) => {

    if (props.editable && props.expanded) {
        return (
            <EditModule {...props} title={title}>
                {children}
            </EditModule>
        )
    }

    const ModuleTemplate = templates && templates[variant] || ListModule

    return (
        <ModuleTemplate
            {...props}
            imageUrl={imageUrl}
            title={title}
            description={description}
            label={label}
            status={status}
            author={author}
            datetime={datetime}
        />
    )

}

Module.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    status: PropTypes.string,
    datetime: PropTypes.string,
    author: PropTypes.string,
    status: PropTypes.string
}

export default Module;
