import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LayoutHeader from "./LayoutHeader"
import LayoutBody from "./LayoutBody"
import LayoutFooter from "./LayoutFooter"

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100%",
        padding: props => { return theme.spacing(props.padding) },


        "& > header": {
            position: "sticky"
        }

    },
    list: {
        "& > * + *": {
            marginTop: props => { return theme.spacing(props.spacing) }, 
        }
    }
}));

const LayoutList = ({spacing = 0, padding = 0, children, ...props}) => {
    const classes = useStyles({padding, spacing});

    return (
        <section className={classes.root} data-layout="list">
            <LayoutHeader {...props} />
            <div className={classes.list}>
                {children}
            </div>
            <LayoutFooter {...props} />
        </section>
    )

}

export default LayoutList;