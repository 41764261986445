export const REQUEST_APP = 'REQUEST_APP'
export const RECEIVE_APP = 'RECEIVE_APP'

export const RECEIVE_APP_LAYOUT = 'RECEIVE_APP_LAYOUT'

export const RECEIVE_APP_HEADER = 'RECEIVE_APP_HEADER'
export const RECEIVE_APP_SEARCH = 'RECEIVE_APP_SEARCH'
export const RECEIVE_APP_SUBVIEW = 'RECEIVE_APP_SUBVIEW'

export const REQUEST_APP_QUERY = 'REQUEST_APP_QUERY'
export const RECEIVE_APP_QUERY = 'RECEIVE_APP_QUERY'

export const REQUEST_APP_THEME = 'REQUEST_APP_THEME'
export const RECEIVE_APP_THEME = 'RECEIVE_APP_THEME'

export const RECEIVE_APP_SIDEBAR = 'RECEIVE_APP_SIDEBAR'
export const RECEIVE_APP_BUTTON = 'RECEIVE_APP_BUTTON'
export const RECEIVE_APP_MENU = 'RECEIVE_APP_MENU'
export const RECEIVE_APP_LANGUAGES = 'RECEIVE_APP_LANGUAGES'

export const TOGGLE_APP_SECTION = 'TOGGLE_APP_SECTION'
export const EXPAND_APP_SECTION = 'EXPAND_APP_SECTION'
export const COLLAPSE_APP_SECTION = 'COLLAPSE_APP_SECTION'

// app

export function requestApp(app) {
    return {
        type: REQUEST_APP,
        app: app
    }
}

export function receiveApp(app) {
    return {
        type: RECEIVE_APP,
        app: app
    }
}

export function receiveAppLayout(layout) {
  
  return {
    type: RECEIVE_APP_LAYOUT,
    layout: layout
  }
  
}

export function receiveAppHeader(header) {
    const { title, subtitle } = header;
  
    return {
        type: RECEIVE_APP_HEADER,
        title: title,
        subtitle: subtitle
    }
  
}

export function receiveAppSubview(subview) {

    return {
        type: RECEIVE_APP_SUBVIEW,
        subview: subview
    }
  
}

export function receiveAppSearch(search) {
  
  return {
    type: RECEIVE_APP_SEARCH,
    search: search
  }
  
}

export function requestAppQuery(key) {
  
  return {
    type: REQUEST_APP_QUERY,
    key: key
  }
  
}

export function receiveAppQuery(key, value) {
  
  return {
    type: RECEIVE_APP_QUERY,
    key: key,
    value: value
  }
  
}

export function receiveTemplateSidebar(sidebar) {
  
  return {
    type: RECEIVE_APP_SIDEBAR,
    sidebar: sidebar
  }
  
}

export function receiveAppButton(button) {
  
  return {
    type: RECEIVE_APP_BUTTON,
    button: button
  }
  
}

export function receiveAppMenu(menu) {
  
  return {
    type: RECEIVE_APP_MENU,
    menu: menu
  }
  
}

export function receiveAppLanguages(languages) {
  
  return {
    type: RECEIVE_APP_LANGUAGES,
    languages: languages
  }
  
}

export function requestAppTheme() {
  
  return {
    type: REQUEST_APP_THEME,
  }
  
}

export function receiveAppTheme(theme) {
  
  return {
    type: RECEIVE_APP_THEME,
    theme: theme
  }
  
}

export function toggleAppSection(name) {
  
  return {
    type: TOGGLE_APP_SECTION,
    name: name
  }
  
}

export function expandAppSection(name) {
  
  return {
    type: EXPAND_APP_SECTION,
    name: name
  }
  
}

export function collapseAppSection(name) {
  
  return {
    type: COLLAPSE_APP_SECTION,
    name: name
  }
  
}