import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from '../redux/functions/model';
import { getSearch } from '../redux/functions/search';

import AppFinder from "../AppFinder"

import FinderQuery from "../AppFinder/FinderQuery"
import FinderSection from '../AppFinder/FinderSection';
import FinderModel from "../AppFinder/FinderModel"

const DocumentChildren = ({models = "documents", modelsById, searchById, ...props}) => {
    const parentId = props.match.params.parentId

    const query = {
        id: props.location.pathname,
        models: models,
        parentId: parentId,
        fl: "uniqueId,title"
    }

    useEffect(() => {
        props.getModel(models, parentId);
        query && props.getSearch(query);
    }, [parentId])

    const parentModel = modelsById && modelsById[parentId]
    const children = query && query.id && searchById && searchById[query.id] && searchById[query.id].models

    const editUrlPattern = props.location && props.location.pathname + "/edit/:uniqueId/edit"

    const renderChildren = () => {

        const label = children.length + " children"

        return (
            <FinderSection label={label}>
                {children.map((model, index) => {
                    return (
                        <FinderModel {...props} model={model} />
                    )
                })}
            </FinderSection>
        )

    }


    return (
        <AppFinder {...props}>
            { parentModel && <FinderModel {...props} model={parentModel} /> }
            { children && renderChildren() }
       </AppFinder>
    )

}


const mapStateToProps = (state) => {
	return {
        modelsById: state.modelsById,
        searchById: state.searchById,
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getModel,
        getSearch
    }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentChildren);