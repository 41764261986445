import authorize from "./authorize"

import locations from "./locations";
import openinghours from "./openinghours";

import siteIdentifiers from "./siteIdentifiers";

import siteCredentials from "./siteCredentials";

import siteLogos from "./siteLogos";
import siteColors from "./siteColors";
import siteImages from "./siteImages";


export default {
  type: 'object',
  properties: {
    logo: {
      type: "image",
    },
    title: {
      type: "string",
    },
    description: {
      type: "string",
    },
    name: {
      type: "string",
    },
    abbr: {
      type: "string",
    },
    domain: {
      type: "string",
    },
    identifiers: siteIdentifiers,
    locations: locations,
    openinghours: openinghours,
    credentials: siteCredentials,
    logos: siteLogos,
    images: siteImages,
    colors: siteColors,
    authorize: authorize
  }        
}