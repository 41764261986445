import uiPreview from "./uiPreview"

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "content": {
        "ui:field": "pageContent",
        "dtStart": {
        },
        "dtEnd": {
        }
    }
}