import React, { useState, useEffect } from 'react';
import FinderSection from "../components/Finder/FinderSection"
import FinderModel from "../AppFinder/FinderModel"
import { useTranslation } from 'react-i18next';

const UploadQueue = ({models = [], requested = 0, received = 0, ...props}) => {
    const { t, i18n } = useTranslation('upload');

    if (!models.length) {
        return false
    }

    const count = received;

    const title = t('{{count}} new uploads', {count, received, requested});

    return (
        <FinderSection title={title}>
            { models.map((model, index) => {
                return (
                    <FinderModel {...props} model={model} layout="list" key={index} />
                )
            })}
        </FinderSection>
    )

}

export default UploadQueue