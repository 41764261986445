// initialState

let initialState = {
  uniqueId: false
};

// collection state

const collection = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_COLLECTION':
    
        return initialState

  		return Object.assign({}, initialState, {
  		})

    case 'RECEIVE_COLLECTION':

      return action.data

    default:

      return state

    }

}

export default collection;