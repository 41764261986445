import React from 'react';
import Button from './Button';

import './ButtonEdit.scss';

class ButtonEdit extends React.Component {
    getClassName = () => {
        const { action } = this.props;

        let classNames = []

        classNames.push('admin-button');
        classNames.push('admin-button--edit');

        if (action) {
            classNames.push('admin-button--edit-' + action);
        }

        return classNames.join(' ');
    }

    render() {
        const className = this.getClassName();

        return (
            <Button {...this.props} className={className} />
        );
    }
}

export default ButtonEdit;
