import React, { Component } from 'react'
import { Overlay } from "../"

class LayoutOverlay extends Component {

    render() {
        return (
            <Overlay {...this.props}>
                { this.props.children }
            </Overlay>
        )
    }
}

export default LayoutOverlay;