import React from "react";

const colors = {
    "blue": "#0067b6",
    "red": "#eb314e"
}

export function getAppIcon({ fill, text, type = "filled"}) {
    
    if (fill && colors[fill]) {
        fill = colors[fill]
    }
    
    if (type === "filled") {

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                <rect x="10" y="10" width="44" height="44" fill={fill}></rect>
                <text x="32" y="34" fill="white" fontFamily="Akkurat, sans-serif" fontSize="18" fontWeight="bold" dominantBaseline="middle" textAnchor="middle">{text}</text>
            </svg>
        )
    
    } else {

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                <rect x="10" y="10" width="44" height="44" fill={fill}></rect>
                <rect x="13" y="13" width="38" height="38" fill="white"></rect>
                <text x="32" y="34" fill="black" fontFamily="Akkurat, sans-serif" fontSize="18" fontWeight="bold" dominantBaseline="middle" textAnchor="middle">{text}</text>
            </svg>
        )
        
    }

}

export default getAppIcon