import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Widget, WidgetHeader, WidgetBody, WidgetFooter, WidgetTitle, WidgetSpinner } from "../"

import { Chart, ChartTooltip, FormatMetric } from "../"

import Color from 'color';
import _ from "lodash"
import moment from "moment"

import "./AnalyticsReportLive.scss"

class AnalyticsReportLive extends Component {

    static propTypes = { 
        title: PropTypes.string,
        chartTitle: PropTypes.string,
        listTitle: PropTypes.string,
        loadingTitle: PropTypes.string,
        loadingDescription: PropTypes.string,
        scope: PropTypes.string
    }
    
    static defaultProps = {
        backgroundColor: "white",

        reportType: "live",
        widgetType: "analytics-live",
        widgetSize: "1:4",

        loadingTitle: "Live rapport",
        
        title: "Aktive brukere akkurat nå",
        chartTitle: "Sidevisninger per minutt",
        listTitle: "Mest aktive sider",
        
        activeUsersReport: undefined,
        pageviewsReport: undefined
    }
    
    state = {
        style: {},
        colors: [
            "white",
            "rgba(255,255,255,0.25)"
        ],
        loading: true,
        activeUsers: undefined,
        activePages: undefined,
        pageviewsChart: undefined
    }

    componentDidMount() {
        this.getColors();
        this.getPageviewsChart()
        this.getActiveUsers()
        this.getActivePages()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.activeUsersReport !== this.props.activeUsersReport) {
            this.getActiveUsers()
            this.getActivePages()
        }

        if (prevProps.theme !== this.props.theme || prevProps.backgroundColor !== this.props.backgroundColor) {
            this.getColors()
        }

        if (prevProps.pageviewsReport !== this.props.pageviewsReport) {
            this.getPageviewsChart()
        }

    }

    getColors = () => {
        let { backgroundColor, theme } = this.props;

        backgroundColor = Color(backgroundColor)
        
        let color;
        
        if (theme) {
            backgroundColor = "white"
            color = theme && theme.primaryColor || "black"
        } else if (backgroundColor.isDark()) {
            color = "white"
        } else {
            color = "black"
        }

        const style = {
            backgroundColor: backgroundColor,
            color: color
        }
        
        // colors

        let colors = []
        
        if (color === "white") {
            colors = [
                "white",
                "rgba(255,255,255,0.25)",
            ]
        } else {
            colors = [
                color,
                "rgba(0,0,0,0.125)"
            ]
        }
        
        this.setState({
            style: style,
            colors: colors
        })
        
        
    }
    
    getActivePages =() => {
        const { activeUsersReport } = this.props;
        
        if (!activeUsersReport) {
            return false
        }
        
        const { query, columnHeaders, rows } = activeUsersReport;

        const metric = query.metrics[0];
        
        const keys = columnHeaders.map((col, index) => {
            return col.name
        })
        
        const data = rows.map((row, r) => {
            
            let page = {};
            
            row.map((value, index) => {
                page[keys[index]] = value;
            })
            
            return page;
            
        })

        const activePages = _.orderBy(data, "rt:activeUsers", 'desc'); // Use Lodash to sort array by 'value'
        
        this.setState({
            activePages: activePages
        })
        
    }
    
    getActiveUsers = () => {
        const { activeUsersReport } = this.props;

        if (!activeUsersReport) {
            return false
        }

        const { query, totalsForAllResults } = activeUsersReport;
        
        const metric = query.metrics[0];
        const value = totalsForAllResults[metric];
        
        const activeUsers = {
            label: metric,
            value: value
        }

        this.setState({
            loading: false,
            activeUsers: activeUsers
        })
        
    }
    
    getPageviewsChart = () => {
        const { pageviewsReport } = this.props;

        if (!pageviewsReport) {
            return false
        }

        const { columnHeaders, rows } = pageviewsReport;

        const keys = columnHeaders.map((col, index) => {
            return col.name
        })
        
        const data = rows.map((row, r) => {

            let dimension = {
                id: row[0],
                value: parseInt(row[1])
            };
            
            row.map((value, index) => {
                dimension[keys[index]] = value;
            })
            
            return dimension;
            
        })
        
        const pageviewsChart = {
            type: "bar",
            data: data,
            keys: ['value'],
            enableGridX: false,
            enableGridY: false,
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        }

        this.setState({
            pageviewsChart: pageviewsChart
        })
        
    }
    
    renderTooltip = (props) => {
        const { data } = props;
        
        const minutesAgo = moment().subtract(data['rt:minutesAgo'], "minutes").fromNow()
        
        return (
            <ChartTooltip>
                <h2 className="admin-tooltip__title">{ minutesAgo }</h2>
                <h3 className="admin-tooltip__value"><FormatMetric name="rt:activeUsers" type="INTEGER" value={data.value} /></h3>
            </ChartTooltip>
        )
        
        return (
            <ChartTooltip>{JSON.stringify(data)}</ChartTooltip>
        )
        
    }
    
    renderChart = () => {
        const { chartTitle } = this.props;
        const { pageviewsChart, colors } = this.state;

        const loading = this.state.loading || this.props.loading;

        if (!pageviewsChart || loading) {
            return false
        }
        
        return (
            <section className="chart">
                <Chart {...pageviewsChart} renderTooltip={this.renderTooltip} colors={colors} />
            </section>
        )

        return (
            <section className="chart">
                <h2 className="chart__title">{chartTitle}</h2>
                <Chart {...pageviewsChart} renderTooltip={this.renderTooltip} colors={colors} />
            </section>
        )
        
    }
    
    renderListItem = (page, index) => {
        
        return (
            <li className="list-item" key={index}>
                <b className="list-item__label">{ page['rt:pagePath']}</b>
                <i className="list-item__value">{ page['rt:activeUsers']}</i>
            </li>
        )
        
    }
    
    renderDetails = () => {
        const { listTitle } = this.props;
        const { activePages } = this.state;

        const loading = this.state.loading || this.props.loading;
        
        if (!activePages || loading) {
            return false
        }
        
        const top5 = activePages.slice(0, 5);

        return (
            <section className="details">
                <h2 className="list__title">{listTitle}</h2>
                <ul className="list">
                { top5.map(this.renderListItem) }
                </ul>
            </section>
        )
        
    }
    
    renderSummary = () => {
        const { title, loadingTitle } = this.props;
        const { activeUsers, style } = this.state;

        const loading = this.state.loading || this.props.loading;
        
        if (!activeUsers || loading) {

            return (
                <section className="summary">
                    <h1 className="summary__title">{loadingTitle}</h1>
                    <WidgetSpinner style={style} />
                </section>
            )        

        }

        return (
            <section className="summary">
                <h1 className="summary__title">{title}</h1>
                <h2 className="summary__value">{activeUsers.value}</h2>
            </section>
        )        

    }
    
    render() {
        const { style } = this.state;        
        
        return (
            <Widget {...this.props} style={style}>
                <WidgetHeader>
                    { this.renderSummary() }
                    { this.renderChart() }
                </WidgetHeader>
                <WidgetFooter>
                    { this.renderDetails() }
                </WidgetFooter>
            </Widget>
        )

    }

}

export default AnalyticsReportLive;