import moduleMedia from "../module/uiSchemaModuleMedia"

export default {
    "ui:layout": "block",
    "ui:options": {
        "collapsible": true,
        "hideable": true
    },
    "ui:title": {
        "select": "sectionType"
    },
    "ui:description": {
        "select": "title"
    },
    "ui:fieldset": [
        "header",
        "body"
    ],
    "ui:settings": [
        "sectionType",
        "sectionLayout"
    ],
    "header": {
        "ui:grid": 3,
        "ui:fieldset": [
            "title",
            "titleHidden",
            "description"
        ],
        "title": {
            "ui:cols": 2,
            "ui:label": false,
            "ui:placeholder": "Tittel"
        },
        "titleHidden": {
            "ui:cols": 1,
            "ui:title": "Skjul tittel",
            "ui:widget": "switch"
        },
        "description" : {
            "ui:title": "Beskrivelse",
            "ui:widget" : "textarea",
        }
    },
    "body": {
        "ui:fieldset": [
            "media",
            "objects",
            "bodytext"
        ],
        "media" : {
            "ui:title": "Media",
            "ui:layout": "fieldset",
            "ui:buttons": ["sidebar", "upload"],
            "items": moduleMedia
        },
        "objects" : {
            "ui:title": "Objekter",
            "ui:buttons": [
                {
                    "type": "sidebar/ekultur",
                    "label": "Finn objekt"
                },
                {
                    "label": "Legg til"
                }
            ]
        },
        "bodytext" : {
            "ui:title": "Brødtekst",
            "ui:widget" : "editor"
        }
    }
}