import React, { Component } from 'react';
import { Button, ButtonIcon, ButtonLabel } from './';
import Dropzone from 'react-dropzone';

class ButtonUpload extends Component {
    static defaultProps = {
        className: "admin-button  admin-button--secondary",
        label: "Upload",
        icon: "",
    };

    onUpload = (acceptedFiles, rejectedFiles) => {
        if (this.props.onUpload) {
            this.props.onUpload(acceptedFiles, rejectedFiles)
        }
    }

    render() {
        const { className, label, icon, accept, multiple } = this.props;
        
        return (
            <Dropzone
                multiple={multiple}
                accept={accept}
                onDrop={event => this.onUpload(event)}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                    <Button {...getRootProps()} className={className}>
                        <input {...getInputProps()} />
                        <ButtonIcon icon={icon} />
                        <ButtonLabel label={label} />
                    </Button>
                )}
            </Dropzone>
        )
    }
}

export default ButtonUpload;
