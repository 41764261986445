
import React, { Component } from 'react';
import { getUiOptions, getDefaultFormState } from "@frontend-components/admin"

const GeopointField = (props) => {
    const { registry, schema, uiSchema, formData } = props
    const { ObjectField } = registry.fields;

    const handleChange = (formData) => {
 
        const newFormData = getDefaultFormState(schema, formData)
        
        console.log('GeopointField:onChange', newFormData)
        
        if (props.onChange) {
            props.onChange(newFormData);
        }
        
    }   

    const newUiSchema = {
        ...uiSchema,
        "ui:location": formData || {},
        "ui:onChange": handleChange
    }

    return (
        <ObjectField {...props} onChange={handleChange} uiSchema={newUiSchema} />
    )

}

export default GeopointField;