import React, { Component } from 'react';
import "./FieldSelect.scss"

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

class FieldSelect extends Component {
    static defaultProps = {
        value: "",
        required: false,
        disabled: false,
        readonly: false,
        autofocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onBlur = (event) => {

        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (event) => {

        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    }

    getValue = (event) => {
    
        const { multiple } = this.props;
        
        if (multiple) {
            return [].slice.call(event.target.options).filter(function (o) {
                return o.selected;
            }).map(function (o) {
                return o.value;
            });
        } else {
            return event.target.value;
        }
    
    }

    onChange = (event) => {
        const value = this.getValue(event);
        
        this.setState({
            value: value
        });

        if (this.props.onChange) {
            this.props.onChange(value, event);
        }
    }
    
    renderOption = (option, index) => {
        const { value, label, disabled, options } = option;

        return (
            <MenuItem disabled={disabled} value={value} key={index}>{label}</MenuItem>
        )

        /*

        if (label && options) {
            return (
                <optgroup label={label} key={index}>
                    { options.map(this.renderOption)}
                </optgroup>
            )
        }
        
        if (!value) {
            
            return (
                <option key={ index } value="" disabled={ disabled }>{ label }</option>
            )

        }
        
        return (
            <option key={ index } value={ value } disabled={ disabled }>{ label }</option>
        )

        */
        
    }
    
    render() {
        const { id, type, label, value, required, disabled, readonly, autofocus, multiple, options } = this.props;

        return (
            <FormControl className="admin-field__select" variant="filled" fullWidth={true}>
                <InputLabel id={id+"-label"}>{label}</InputLabel>
                    <Select
                         fullWidth={true}
                        id={id}
                        labelId={id+"-label"}
                        multiple={multiple} 
                        required={ required }
                        disabled={ disabled || readonly }
                        autoFocus={ autofocus }
                        value={value}
                        onFocus={(event) => this.onFocus(event)} 
                        onBlur={(event) => this.onBlur(event)} 
                        onChange={(event) => this.onChange(event)}
                    >

                    { options.map(this.renderOption) }

                </Select>
            </FormControl>
        )

        /*

        return (
            
            <div className="admin-field  field--select">
            
              <select id={ id } 
                multiple={ multiple } 
                required={ required }
                disabled={ disabled || readonly }
                autoFocus={ autofocus }
                value={ value }
                onFocus={(event) => this.onFocus(event)} 
                onBlur={(event) => this.onBlur(event)} 
                onChange={(event) => this.onChange(event)}>
                
                  { options.map(this.renderOption) }
                
                </select>

            </div>
        )

        */
    }
}

export default FieldSelect;