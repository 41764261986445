export default {
    "ui:layout": "section",
    "ui:icon": "apps",
    "ui:title": "Applikasjon",
    "ui:options": {
        "spacing": 2
    },
    "ui:fieldset": [
        "header",
        "footer"
    ],
    "header": {
        "ui:layout": "thumbnail",
        "ui:fieldset": [
            "icon",
            "content"
        ],
        "ui:options": {
            "grid": true,
            "spacing": 2
        },
        "icon": {
            "ui:gridCols": 4,
            "ui:format": "1:1",
            "ui:buttons": ["upload"]
        },
        "content": {
            "ui:spacing": 2,
            "ui:grid": true,
            "ui:gridCols": 8,
            "ui:fieldset": [
                "title",
                "description"
            ],
            "title": {
                "ui:title" : "Tittel",
            },
            "description": {
                "ui:title" : "Beskrivelse",
                "ui:help" : "Brukes kun internt i applikasjonen for gjenfinning.",
                "ui:widget": "textarea"
            },
        }
    },
    "footer": {
        "ui:fieldset": [
            "siteId",
            "collectionType",
            "name"
        ],
        "ui:options": {
            "grid": true,
            "spacing": 2
        },
        "siteId": {
            "ui:title" : "Eier",
            "ui:placeholder": "Finn og velg eier",
            "ui:widget": "siteId"
        },
        "collectionType": {
            "ui:title" : "Type samling",
            "ui:placeholder": "Velg type"
        },
        "name": {
            "ui:title" : "Unikt navn",
            "ui:description" : "NB: Kort navn, uten mellomrom.",
            "ui:help" : "Brukes for å lage brukervennlige url-er i admin.",
        }
    }
}