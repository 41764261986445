import React from "react";
import Color from 'color';
import SvgIcon from '@material-ui/core/SvgIcon';

const OwnerIcon = ({className, defaultColor = "#000", color, label, theme, title, abbr}) => {
    let fill = color || theme && theme.primaryColor || defaultColor
    let text = label || abbr || title && title.substr(0, 3).toUpperCase()

    fill = Color(fill);

    let contrast;

    if (fill.isDark()) {
        contrast = 'white';
    } else {
        contrast = 'black';
    }

    return (
        <SvgIcon className={className} title={title}>
            <circle cx="12" cy="12" r="12" fill={fill}></circle>
            <text x="12" y="12.75" fill={contrast} fontFamily="Akkurat, sans-serif" fontSize="9" fontWeight="normal" dominantBaseline="middle" textAnchor="middle">{text}</text>
        </SvgIcon>
    );
}

export default OwnerIcon