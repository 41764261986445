import React, { useState, useRef, useEffect } from "react"
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

import ButtonBase from '@material-ui/core/ButtonBase';

import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
    },
    input: {

    },
    editor: {
        width: "100%",

        "& $toolbar": {
            display: "none"
        },

        "&[data-focus=true]": {
            "& $toolbar": {
                display: "flex"
            }
        }

    },
    toolbar: {
        marginLeft: theme.spacing(-1.5),
        marginRight: theme.spacing(-1.5),
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        userSelect: "none"
    },
    controls: {
        display: "flex",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

        "& + $controls": {
            borderLeft: "1px solid",
            borderColor: theme.palette.divider,
        }

    },
    button: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "1em",

        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),

        color: theme.palette.text.secondary,

        "&[aria-selected=true]": {
            fontWeight: "bold",
            color: theme.palette.text.primary
        }

    },
    input: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "1em",

        "& h1": {
            fontSize: "2em",
            fontWeight: "bold",
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& h2": {
            fontSize: "1.5em",
            fontWeight: "bold",
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& h3": {
            fontSize: "1.25em",
            fontWeight: "bold",
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& h4": {
            fontSize: "1em",
            fontWeight: "bold",
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& blockquote": {
            margin: "1em"
        },

        "& pre": {
            fontFamily: "Akkurat mono, monospace",
            margin: "1em"
        },

        "& [data-block=true]": {
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& p": {
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& ol": {
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& ul": {
            marginTop: "1em",
            marginBottom: "1em"
        },

        "& strong": {
            fontWeight: "bold"
        },

        "& em": {
            fontStyle: "italic"
        },

        "& code": {
            fontFamily: "Akkurat mono, monospace",
        },

    }
}));

const BLOCK_TYPES = [
	{label: 'H1', style: 'header-one'},
	{label: 'H2', style: 'header-two'},
	{label: 'H3', style: 'header-three'},
	{label: 'H4', style: 'header-four'},
//	{label: 'H5', style: 'header-five'},
//	{label: 'H6', style: 'header-six'},
	{label: 'Quote', style: 'blockquote'},
	{label: 'UL', style: 'unordered-list-item'},
	{label: 'OL', style: 'ordered-list-item'},
//	{label: 'Code', style: 'code-block'},
];

const INLINE_STYLES = [
	{label: 'Bold', style: 'BOLD'},
	{label: 'Italic', style: 'ITALIC'},
//	{label: 'Underline', style: 'UNDERLINE'},
//	{label: 'Code', style: 'CODE'},
];


const StyleButton = ({style, onToggle, selected, label}) => {
	const onMouseDown = (e) => {
        e.preventDefault();
        onToggle(style);
    }
    
    const classes = useStyles();
	
	return (
		<ButtonBase className={classes.button} aria-selected={selected} onMouseDown={onMouseDown}>
			{label}
		</ButtonBase>
	);

}

const BlockStyleControls = ({editorState, onToggle}) => {
	const selection = editorState.getSelection();
	const blockType = editorState
	.getCurrentContent()
	.getBlockForKey(selection.getStartKey())
	.getType();

    const classes = useStyles();

	return (
		<div className={classes.controls}>
			{BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    selected={type.style === blockType}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                    />
            )}
		</div>
	);
};


const InlineStyleControls = ({editorState, onToggle}) => {
	const currentStyle = editorState.getCurrentInlineStyle();

    const classes = useStyles();

    return (
		<div className={classes.controls}>
			{INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    selected={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                    />
            )}
		</div>
	);
};

const DraftEditor = ({id, label = "Draft editor", helperText, placeholder = "Write some text", value, onChange}) => {

    const markdownString = value;
    const rawData = markdownToDraft(markdownString);
    const contentState = convertFromRaw(rawData);

    const [editorState, setEditorState] = useState(
        () => EditorState.createWithContent(contentState),
    );

    const [editorFocus, setEditorFocus] = useState(false)

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';

    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    const toggleBlockType = (blockType) => {
		handleChange(
			RichUtils.toggleBlockType(
				editorState,
				blockType
			));
	}

	const toggleInlineStyle = (inlineStyle) => {
		handleChange(
			RichUtils.toggleInlineStyle(
				editorState,
				inlineStyle
			)
		);
    }
    
    
    const handleChange = (editorState) => {
        setEditorState(editorState)

        const content = editorState.getCurrentContent();
        const rawObject = convertToRaw(content);
        const value = draftToMarkdown(rawObject);

        onChange && onChange(value)
  
    }

    const labelId = id + "-label"

    const shrinkLabel = () => {
        return value && true || placeholder && true || false
    }

    const classes = useStyles()

    const editorRef = useRef(null)

    const EditorBase = () => {
        return (
            <div className={classes.editor} data-focus={editorFocus}>
                <div className={classes.toolbar}>
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                        />
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                        />
                </div>
                <div className={classes.input}>
                    <Editor 
                        ref={editorRef}
                        placeholder={placeholder}
                        editorState={editorState}
                        onFocus={() => setEditorFocus(true)}
                        onBlur={() => setEditorFocus(false)}
                        onChange={handleChange} />
                </div>
            </div>
        )
    
    }

    return (
        <div>
            <FormControl variant="filled" fullWidth={true}>
                <InputLabel shrink={shrinkLabel} id={labelId}>{label}</InputLabel>
                <FilledInput multiline={true} fullWidth={true} inputComponent={EditorBase} className={editorFocus && " Mui-focused"} />
            </FormControl>
            {helperText && <FormHelperText>{helperText}</FormHelperText> }
        </div>
    )

}

export default DraftEditor
