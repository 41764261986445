import SettingsWidget from "./SettingsWidget"

export default {
    "fieldtypes": {
    },
    "fields": {
    },
    "layouts": {
    },
    "widgets": {
        "grid": SettingsWidget,
        "skin": SettingsWidget,
        "size": SettingsWidget,
        "align": SettingsWidget,
        "valign": SettingsWidget,
        "placement": SettingsWidget,
    }
}