import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const AuthorizedSitesField = (props) => {
    const {formData, authSites, registry, uiSchema, onChange} = props

    const handleAdd = (site) => {
        const newFormData = [
            ...formData,
            {site: site}
        ]
        onChange && onChange(newFormData)
    }

    const authSiteIds = formData.map(auth => {
        return auth.site.id
    })

    const sites = authSites.filter(site => !authSiteIds.includes(site.id))

    const title = formData.length + " authorized sites"

    const search = {
        placeholder: "Filter sites",
    }

    const newUiSchema = {
        ...uiSchema,
        "ui:title": title,
        "ui:search": search,
        "ui:results": sites,
        "ui:onAddAuth": handleAdd,
        "ui:layout": "authList",
        "items": {
            "ui:field": "authSite",
        }
    }

    const { ArrayField } = registry.fields;

    return (
        <ArrayField {...props} uiSchema={newUiSchema} />
    )

}

// mapStateToProps

const mapStateToProps = (state) => {
	return {
        authSites: state.authSites.models,
	};
}  

// export default

export default connect(
    mapStateToProps,
)(AuthorizedSitesField);