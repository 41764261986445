import React from 'react';
import PropTypes from "prop-types";

import NavLogin from "./NavLogin"

import ButtonUser from "./ButtonUser"
import UserDropdown from "./UserDropdown"

import UserInfo from "./UserInfo"
import UserAppInfo from "./UserAppInfo"

const UserButtonRef = React.forwardRef((props, ref) => {
    return <ButtonUser {...props} forwardedRef={ref} />;
});

const NavUser = ({className, app, user = undefined, onLogin, onLogout, ...props}) => {
    const [expanded, setExpanded] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = (event) => {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const handleCollapse = () => {
        setExpanded(false);
    };

    const handleLogout = () => {
        setExpanded(false);
        onLogout && onLogout()
    };

    if (!user || !user.uniqueId) {
        return (
            <NavLogin onLogin={onLogin} />
        )
    }

    return (
        <nav className={className}>
            <UserButtonRef {...user} onClick={handleToggle} expanded={expanded} ref={anchorRef} />
            <UserDropdown expanded={expanded} anchorEl={anchorRef.current} arrowRef={anchorRef} onCollapse={handleCollapse}>
                <UserInfo {...user} />
                <UserAppInfo {...app} onLogout={handleLogout} />
            </UserDropdown>
        </nav>
    )
        
}

NavUser.propTypes = {
    app: PropTypes.node,
    user: PropTypes.node,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func
}

export default NavUser