export default {
    "ui:layout": "document",
    "ui:nav": "sidebar",
    "ui:fieldset": [
        "organization",
        "siteIdentifiers",
        "brandLogos",
        "brandColors",
        "brandImages",
        "authorize"
    ],
    "organization": {
        "ui:layout": "section",
        "ui:icon": "business",
        "ui:title": "Organisasjon",
        "ui:fieldset": [
            "content",
        ],
        "ui:grid": 3,
        "logo": {
            "ui:title" : "Logo",
            "ui:cols": 1,
        },
        "content": {
            "ui:cols": 3,
            "ui:fieldset": [
                "title",
                "name",
                "abbr"
            ],
            "title": {
                "ui:cols": 2,
                "ui:title": "Tittel",
                "ui:help": "Navnet på organisasjonen"
            },
            "description": {
                "ui:cols": 2,
                "ui:title": "Beskrivelse",
                "ui:widget": "textarea"
            },
            "name": {
                "ui:cols": 2,
                "ui:title" : "Navn",
                "ui:help": "Unikt navn"
            },
            "abbr": {
                "ui:cols": 2,
                "ui:title" : "Signatur",
                "ui:help": "Kortnavn"
            }
        }
    },
    "siteIdentifiers": {
        "ui:layout": "section",
        "ui:icon": "vpn_key",
        "ui:title": "Identifiers",
        "ui:fieldset": [
            "identifiers"
        ],
        "identifiers": {
            "ui:title": "[count] identifiers",
            "items": {
                "ui:layout": "module",
                "ui:title": {
                    "select": "identifier"
                },
                "identifier": {
                    "ui:help": "Used for lookups"
                },
                "ui:options": {
                    "editable": true,
                    "collapsible": true
                }
            }
        }
        
    },
    "siteLocations": {
        "ui:layout": "section",
        "ui:icon": "location_on",
        "ui:title": "Lokasjoner",
        "ui:fieldset": [
            "locations"
        ],
        "locations": {
            "ui:title": "[count] steder",
            "items": {
                "ui:layout": "module",
                "ui:options": {
                    "editable": true,
                    "collapsible": true
                }
            }
        }
    },
    "siteOpeninghours": {
        "ui:layout": "section",
        "ui:icon": "access_time",
        "ui:title": "Åpningstider",
        "ui:fieldset": [
            "openinghours"
        ],
        "openinghours": {
            "ui:title": "[count] åpningstider",
            "items": {
                "ui:layout": "module",
                "ui:options": {
                    "editable": true,
                    "collapsible": true
                },
                "ui:description": {
                    "select": "dateFrom"
                },
                "days": {
                    "ui:title": "Ukedager",
                    "ui:options": {
                        "addable": false,
                        "removable": false,
                        "orderable": false
                    },
                    "items": {
                        "ui:grid": 4,
                        "day": {
                            "ui:label": false,
                            "ui:cols": 1
                        },
                        "open": {
                            "ui:label": false,
                            "ui:title": "Åpen",
                            "ui:widget": "switch",
                            "ui:cols": 1
                        },
                        "opensAt": {
                            "ui:label": false,
                            "ui:cols": 1
                        },
                        "closesAt": {
                            "ui:label": false,
                            "ui:cols": 1
                        }
                    }
                }
            }
        }
    },
    "credentials": {
        "ui:layout": "section",
        "ui:icon": "settings",
        "ui:title": "Integrasjoner",
        "ui:fieldset": [
            "googleMyBusiness",
            "googleAnalytics"
        ],
        "googleAnalytics": {
            "ui:title": "Google Analytics"
        },
        "googleMyBusiness": {
            "ui:title": "Google My Business"
        }
    },
    "authorize": {
        "ui:layout": "section",
        "ui:icon": "lock",
        "ui:title": "Tilganger",
        "ui:fieldset": [
            "services",
            "products"
        ],
        "services": {
            "ui:title": "Tjenester",
            "ui:options": {
                "addable": false
            },
            "items": {
                "ui:fieldset": [
                    "accessType"
                ],
                "ui:layout": "module",
                "ui:title": {
                    "select": "title"
                },
                "ui:description": {
                    "select": "accessType"
                },
                "ui:options": {
                    "collapsible": true,
                    "editable": true
                }
            }
        },
        "products": {
            "ui:title": "Produkter",
            "ui:options": {
                "addable": false
            },
            "items": {
                "ui:fieldset": [
                    "accessType"
                ],
                "ui:layout": "module",
                "ui:title": {
                    "select": "title"
                },
                "ui:description": {
                    "select": "accessType"
                },
                "ui:options": {
                    "collapsible": true,
                    "editable": true
                }
            }
        },
        "apps": {
            "ui:title": "Applikasjoner",
            "items": {
                "ui:layout": "module",
                "ui:title": {
                    "select": "title"
                },
                "ui:description": {
                    "select": "accessType"
                },
                "ui:options": {
                    "collapsible": true,
                    "editable": true
                }
            },
            "ui:buttons": [
                {
                    "type": "sidebar/apps/site",
                    "icon": "search",
                    "title": "Finn applikasjon"
                }
            ]
        }
    },
    "brandLogos": {
        "ui:layout": "section",
        "ui:icon": "security",
        "ui:title": "Logoer",
        "ui:fieldset": [
            "logos"
        ],
        "logos": {
            "ui:title": "[count] logoer",
            "ui:help": "Legg inn logoer som skal være tilgjengelig i alle applikasjoner.",
            "items": {
                "ui:layout": "module",
                "ui:options": {
                    "collapsible": true,
                    "editable": true
                }
            },
            "ui:buttons": ["upload"]
        },
    },
    "brandColors": {
        "ui:layout": "section",
        "ui:icon": "palette",
        "ui:title": "Farger",
        "ui:fieldset": [
            "colors"
        ],
        "colors": {
            "ui:title": "[count] fargeprofiler",
            "ui:help": "Legg inn fargeprofiler for å gjøre dem tilgjengelig i alle applikasjoner.",
            "items": {
                "ui:layout": "module",
                "ui:title": {
                    "select": "name"
                },
                "ui:color": {
                    "select": "value"
                },
                "ui:description": {
                    "select": "value"
                },
                "ui:options": {
                    "collapsible": true,
                    "editable": true
                },
                "value": {
                    "ui:widget": "colorpicker"
                }
            }
        },
    },
    "brandImages": {
        "ui:layout": "section",
        "ui:icon": "image",
        "ui:title": "Bilder",
        "ui:fieldset": [
            "images"
        ],
        "images": {
            "ui:title": "[count] profilbilder",
            "ui:help": "Legg inn profilbilder som skal være tilgjengelig i alle applikasjoner.",
            "items": {
                "ui:layout": "module",
                "ui:options": {
                    "collapsible": true,
                    "editable": true
                }
            },
            "ui:buttons": ["upload"]
        }
    }
}