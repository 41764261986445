import React, { Component } from 'react';

// Class

class PreviewImage extends Component {
  
  render() {
    
    const { formData, format } = this.props;
    const { media } = formData;
    const { imageUrl } = media;
    
    return (

      <img src={ imageUrl } />
      
    )
    
  }
  
}

export default PreviewImage;