import React from "react";

import widgetMap from "./widgetMap";
import { getSchemaType } from "./getSchemaType"

export function getWidget(schema, widget, registeredWidgets = {}) {
    const type = getSchemaType(schema);

    function mergeOptions(Widget) {
        // cache return value as property of widget for proper react reconciliation
        if (!Widget.MergedWidget) {
            const defaultOptions =
                (Widget.defaultProps && Widget.defaultProps.options) || {};
            Widget.MergedWidget = ({ options = {}, ...props }) => (
                <Widget options={{ ...defaultOptions, ...options }} {...props} />
            );
        }
        return Widget.MergedWidget;
    }

    if (typeof widget === "function") {
        return mergeOptions(widget);
    }

    if (typeof widget !== "string") {
        throw new Error(`Unsupported widget definition: ${typeof widget}`);
    }

    if (registeredWidgets.hasOwnProperty(widget)) {
        const registeredWidget = registeredWidgets[widget];
        return getWidget(schema, registeredWidget, registeredWidgets);
    }

    if (!widgetMap.hasOwnProperty(type)) {
        throw new Error(`No widget for type "${type}"`);
    }

    if (widgetMap[type].hasOwnProperty(widget)) {
        const registeredWidget = registeredWidgets[widgetMap[type][widget]];
        return getWidget(schema, registeredWidget, registeredWidgets);
    }

    throw new Error(`No widget "${widget}" for type "${type}"`);
}