import React, { Component } from 'react';
import PropTypes from "prop-types";

import { Widget, WidgetHeader, WidgetFooter, WidgetSpinner } from "../"
import { Chart, FormatMetric } from "../"

import { getChartColors, getGaChartByType, getGaDataByMetric } from "../../resources/functions/"

import Color from 'color';
import _ from "lodash"

import "./AnalyticsReportOwnersVsTotal.scss"
import "../Chart/ChartLegend.scss"

class AnalyticsReportRegVsPub extends Component {

    static propTypes = { 
        title: PropTypes.string,
        listTitle: PropTypes.string,
        loadingTitle: PropTypes.string,
        
        report: PropTypes.object,
        compareReport: PropTypes.object,
    }
    
    static defaultProps = {
        widgetType: "analytics-owners-vs-total",
        widgetSize: "1:1",

        loadingTitle: "Trafikk denne uken",

        title: "Trafikk denne uken",
        description: "{percent} av hele DigitaltMuseum",
        colors: []

    }
    
    state = {
        style: {},
        loading: true,
        summary: undefined,
        legend: undefined,
        chart: undefined
    }

    componentDidMount() {
        this.getReport()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.report !== this.props.report) {
            this.getReport()
        }
    }

    getReport = () => {
        const { report, compareReport, metric } = this.props;
        
        // set state
        
        this.setState({
            loading: true,
            summary: undefined,
            legend: undefined,
            chart: undefined
        })
        
        if (!report) {
            return false
        }
        
        // load data

        const chart = getGaChartByType("pie", report, metric, "dm:owner")
        
        if (!chart) {
            return false
        }
        
        let compareChart;
        
        if (compareReport) {
            compareChart = getGaDataByMetric(compareReport, metric)
        }
        
        // get data

        let data = chart.data;

        // get colors
        
        let colors = getChartColors({...this.props, data: data})
        
        // set legends

        let legend = []

        data.map((item, index) => {
            
            legend.push({
                ...item,
                color: colors[index]
            })
            
        })
        
        // total + other
        
        const chartTotal = chart.total;
        
        let compare, compareTotal, comparePercent;
        
        if (compareChart && compareChart.total) {
            compareTotal = compareChart.total;
            comparePercent = chartTotal / compareTotal * 100;

            const other = compareTotal - chartTotal;
            
            if (other > 0) {
    
                data.push({
                    id: "other",
                    label: "Andre",
                    value: other
                })
                
                colors.push("rgba(0,0,0,.125)")
                
                legend.push({
                    label: "Andre",
                    value: other,
                    color: "rgba(0,0,0,.125)"
                })
                
            }
            
        }
        
        this.setState({
            loading: false,
            summary: {
                total: chartTotal,
                compare: compareTotal,
                percent: comparePercent
            },
            legend: legend,
            chart: {
                ...chart,
                data: data,
                colors: colors
            }
        })
        
    }

    renderChart = () => {
        const { chartTitle } = this.props;
        const { chart } = this.state;
        
        return (
            <Chart {...chart} type="pie" enableSlicesLabels={false} />
        )
        
    }
    
    renderLegendItem = (item, index) => {
        const { label, value, color } = item;

        const style = {
            backgroundColor: color
        }
        
        return (
            <li key={index}>
                <div className="admin-legend__chip" style={style}></div>
                <b className="admin-legend__label">{label}</b>
                <i className="admin-legend__value"><FormatMetric type="INTEGER" value={value} /></i>
            </li>
        )
        
    }
    
    renderLegend = () => {
        let { legend } = this.state;
        
        if (!legend) {
            return false
        }
        
        let size = "small";
        
        if (legend.length > 4) {
            size = "medium"
        }

        if (legend.length > 8) {
            size = "large"
        }
        
        return (
            <section className="admin-legend" data-count={legend.length} data-size={size}>
                <ul>
                    { legend.map(this.renderLegendItem) }
                </ul>
            </section>
        )
        
    }
    
    renderSummary = () => {
        let { title, description, loadingTitle } = this.props;
        const { summary, style } = this.state;

        const loading = this.state.loading || this.props.loading;
        
        if (loading || !summary) {

            return (
                <section className="summary">
                    <h1 className="summary__title">{loadingTitle}</h1>
                    <WidgetSpinner style={style} />
                </section>
            )        
            
        }

        let { total, compare, percent } = summary;
        
        if (percent > 0) {

            percent = parseFloat(percent).toFixed(1) + "%";
            description = description.replace("{percent}", percent)

            return (
                <section className="summary">
                    <h1 className="summary__title">{title}</h1>
                    <h2 className="summary__value"><FormatMetric type="INTEGER" value={total} decimals="0"/></h2>
                    <p className="summary__description">{description}</p>
                </section>
            )        
            
        }

        return (
            <section className="summary">
                <h1 className="summary__title">{title}</h1>
                <h2 className="summary__value"><FormatMetric type="INTEGER" value={total} decimals="0"/></h2>
            </section>
        )        

    }
    
    render() {
        const { style, data } = this.state
        
        return (
            <Widget {...this.props} style={style}>
                <WidgetHeader>
                    { this.renderSummary() }
                    { this.renderChart() }
                </WidgetHeader>
                <WidgetFooter>
                    { this.renderLegend() }
                </WidgetFooter>
            </Widget>
        )

    }

}

export default AnalyticsReportRegVsPub;