import Turndown from "turndown"

const turndown = new Turndown({
    headingStyle: "atx",
    bulletListMarker: "-"
})

export const getInboxBody = ({message}) => {
    let html = document.createElement("div"); 
    html.innerHTML = message;
    
    const text = html.textContent || html.innerText || message;

    let markdown = message;
    
    if (message) {
        markdown = turndown.turndown(message)
    }

    return {
        text: text,
        markdown: markdown
    }        

}

export default getInboxBody