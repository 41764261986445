export const FAVOURITE_ADD = 'FAVOURITE_ADD'
export const FAVOURITE_REMOVE = 'FAVOURITE_REMOVE'

export function addFavouriteItem(uniqueId, model) {

    return {
        type: FAVOURITE_ADD,
        uniqueId: uniqueId,
        model: model
    }
  
}

export function removeFavouriteItem(uniqueId) {

    return {
        type: FAVOURITE_REMOVE,
        uniqueId: uniqueId
    }
  
}