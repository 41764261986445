export default {
    "type": 'object',
    "properties": {
        "content": {
            "type": "object",
            "properties": {
                "avatar": {
                    "type": "image",
                },
                "name": {
                    "type": "string",
                },
                "email": {
                    "type": "string",
                },
                "defaultLocale": {
                    "type": "string",
                    "enum": [
                        "en",
                        "nb",
                        "nn",
                        "sv"
                    ],
                    "enumNames": [
                        "English",
                        "Norsk – Bokmål",
                        "Norsk – Nynorsk",
                        "Svensk"
                    ]
                }
            }
        }
    }
}