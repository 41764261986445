import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import UserIcon from "../User/UserIcon"

const useStyles = makeStyles(theme => ({
    icon: {
        width: "36px",
        height: "36px",
        margin: "-6px"
    }
}));

const ButtonUser = ({onClick, expanded, forwardedRef, ...props}) => {

    const classes = useStyles();

    return (
        <IconButton className={classes.root} onClick={onClick} aria-expanded={expanded} ref={forwardedRef}>
            <UserIcon {...props} className={classes.icon} />
        </IconButton>
    )



}

export default ButtonUser;