import React from 'react';
import AppFinder from "../AppFinder"
import FinderQuery from "../AppFinder/FinderQuery"

const MediaFinder = ({app, query, ...props}) => {

    /*

    const pathname = props.location.pathname;
    const query = menuByUrl && menuByUrl[pathname] && menuByUrl[pathname].query || {}

    */

    const layoutOptions = [
        "gallery",
        "list"
    ]

    const layout = layoutOptions[0]

    const editUrlPattern = props.location && props.location.pathname + "/:uniqueId/edit"

    return (
        <AppFinder {...props} layoutOptions={layoutOptions}>
            <FinderQuery {...props} query={query} layout={layout} editUrlPattern={editUrlPattern} />
        </AppFinder>
    )

}

export default MediaFinder