import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import ImagePreview from "./ImagePreview"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        position: "relative",
        width: theme.spacing(8),
        height: theme.spacing(12),
        marginRight: theme.spacing(1),
        color: "inherit",
    },
    image: {
        position: "relative",
        border: "1px solid",
        borderRadius: "2px",
        borderColor: "#666",
        width: theme.spacing(8),
        height: theme.spacing(8),
        "&[aria-selected=true]": {
            borderWidth: "2px",
            borderColor: "white"
        }
    },
    label: {
        position: "absolute",
        top: theme.spacing(8),
        width: "100%",
        textAlign: "center",
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        fontSize: "12px",
        marginTop: theme.spacing(0.5)
    },
}));

const ButtonFilter = ({imageUrl, imageFilters = {}, label = "Filter", selected, onClick, ...props}) => {
    const classes = useStyles();

    return (
        <div className={classes.button}>
            <ButtonBase className={classes.image} onClick={onClick} aria-selected={selected}>
                <ImagePreview imageUrl={imageUrl} imageFilters={imageFilters} {...props} />
            </ButtonBase>
            <Typography noWrap className={classes.label}>
                {label}
            </Typography>
        </div>
    )
} 

export default ButtonFilter;