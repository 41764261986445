import React from 'react';
import AppFinder from "../AppFinder"
import FinderQuery from "../AppFinder/FinderQuery"

const DocumentFinder = ({query, ...props}) => {

    const rowsOptions = [
        10,
        20,
        30,
        40,
        50
    ]

    const sortOptions = [
        "title", 
        "createdAt DESC",
        "updatedAt DESC"
    ]

    const layoutOptions = [
        "table",
        "list",
        "grid"
    ]

    const layout = layoutOptions[0]

    const editUrlPattern = props.location && props.location.pathname + "/:uniqueId/edit"

    return (
        <AppFinder {...props} rowsOptions={rowsOptions} sortOptions={sortOptions} layoutOptions={layoutOptions}>
            <FinderQuery {...props} query={query} layout={layout} editUrlPattern={editUrlPattern} />
        </AppFinder>
    )

}

export default DocumentFinder