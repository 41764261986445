import _ from "lodash"
import { API } from '../settings';
import { 
    requestAuthApps, receiveAuthApps, receiveAuthAppByName, receiveAuthAppById,
    requestAuthSites, receiveAuthSites, receiveAuthSiteByName, receiveAuthSiteById,
    requestAuthSiteApps, receiveAuthSiteApps,
    requestAuthSiteCollections, receiveAuthSiteCollections,
    requestAuthUsers, receiveAuthUsers, receiveAuthUserById, 
    requestAuthRoles, receiveAuthRoles
} from '../actions/auth';

import { siteApps } from "../apps/"

import { getSiteById } from "./authSites"
import { getAppById } from "./authApps"

// ROLES

export function getAuthRoles() {
    const url = API + '/admin/api/roles';
  
    return function (dispatch) {
        dispatch(requestAuthRoles())

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(receiveAuthRoles(data)),
        )

    }

}

// APPS

export function setAppAuth(id, authData) {
    const { collectionType, siteId, status } = authData;
    
    return function(dispatch) {
        
        console.log('authId', id)
        console.log('authData', authData)
    
        if (id) {
            dispatch(setAppAuthStatus(id, status))
        } else {
            dispatch(saveAppAuth(authData))
        }

    }

}

export function setAppAuthStatus(id, status) {

  const url = API + '/admin/api/collections/' + id;

  return function (dispatch) {
    fetch(url, {
        method: "GET",
        headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
        },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
        dispatch(saveAppAuth({...data, status: status})),
    )

  }
    
}

export function saveAppAuth(data) {
  const url = API + '/admin/api/collections';
  
  let { name, defaultLocale, collectionType, siteId, siteName } = data;
  
  data = {
      ...data,
      name: name || collectionType + "/" + siteId,
      defaultLocale: defaultLocale || "en"
  }

  const payload = JSON.stringify(data);
  
  return function (dispatch) {

    fetch(url, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: payload
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(saveAppAuthUpdate(data))
    )
  
  }
    
}

export function saveAppAuthUpdate(data) {
    const { id, siteId } = data;
    
    return function(dispatch) {
        dispatch(getAppById(id))
        dispatch(getSiteById(siteId))
    }
    
    
}

// USERS

export function getAuthUsers() {
    const url = API + '/admin/api/users/search';

    return function (dispatch) {
        dispatch(requestAuthUsers())

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getAuthUsersData(data)),
        )

    }
    
}

export function getAuthUsersData(data) {
    const { models } = data;
    
    let users = []
    
    models.map(user => {
        users.push(getUserAuthData(user))
    })
    
    return function(dispatch) {
        dispatch(receiveAuthUsers(users))

        users.map(user => {
            dispatch(receiveAuthUserById(user.id, user))
        })

    }
    
}

export function getUserById(id) {
    const url = API + '/admin/api/users/' + id;
  
    return function (dispatch) {

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(receiveAuthUserById(id, getUserAuthData(data)))
        )

  }

}

export function getUserAuthData(user) {

    let authSites = [], authSitesByName = {}, authSitesById = {}

    user && user.authorizedSites && user.authorizedSites.map(auth => {
        let { site, role } = auth;
        
        if (site && site.id) {
            
            site = {
                ...site,
                siteId: site.id,
                roleId: role.id,
                userId: user.id
            }
            
            authSites.push(site)
            authSitesByName[site.name] = site
            authSitesById[site.id] = site
        }
        
        
    })

    let authApps = [], authAppsByName = {}, authAppsById = {}

    user && user.authorizedCollections && user.authorizedCollections.map(auth => {
        let { collection, role } = auth;
        
        if (collection && collection.id) {

            const app = {
                ...collection,
                collectionId: collection.id,
                roleId: role.id,
                userId: user.id
            }
            
            authApps.push(app)
            authAppsByName[app.name] = app
            authAppsById[app.id] = app
        }
        
    })
    
    return {
        ...user,
        userId: user.id,
        authSites: authSites,
        authSitesByName: authSitesByName,
        authSitesById: authSitesById,
        authApps: authApps,
        authAppsByName: authAppsByName,
        authAppsById: authAppsById
    }
    
}

// SET USER AUTH

export function setUserAuth(id, authData) {
    const url = API + '/admin/api/users/' + id;
    
    return function (dispatch) {

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(saveUserAuth(id, authData)),
        )

  }
    
    
}

export function saveUserAuth(id, authData) {
    const { collectionId, siteId, roleId } = authData

    let authorizedSites = [], authorizedCollections = []

    if (siteId && !collectionId) {

        authorizedSites = [
            {
                site: {
                    id: siteId
                },
                role: {
                    id: roleId
                }
            }
        ]

    }

    if (collectionId) {

        authorizedCollections = [
            {
                collection: {
                    id: collectionId
                },
                role: {
                    id: roleId
                }
            }
        ]

    }
    
    const data = {
        id: id,
        authorizedSites: authorizedSites,
        authorizedCollections: authorizedCollections
    }
    
    const url = API + '/admin/api/authorize/user';
    const payload = JSON.stringify(data);
    
    console.log('AUTH', payload);
    
    return function (dispatch) {
    
        fetch(url, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
          body: payload
        })
        .then(
          response => response.json(),
          error => console.log('An error occurred.', error)
        )
        .then(auth =>
          dispatch(saveUserAuthUpdate(id, siteId, collectionId))
        )
    
    }
    
}

export function saveUserAuthUpdate(userId, siteId, collectionId) {
    
    return function(dispatch) {
        dispatch(getUserById(userId))

        if (siteId) {
            dispatch(getSiteById(siteId))
        }

        if (collectionId) {
            dispatch(getAppById(collectionId))
        }

    }
    
}