import React, { Component } from 'react';
import PreviewLabel from "../PreviewLabel"
import "./PreviewMedia.scss"

// Components

import PreviewImage from './PreviewImage';
import PreviewVideo from './PreviewVideo';
import PreviewAudio from './PreviewAudio';

const templates = {
  "image": PreviewImage,
  "video": PreviewVideo,
  "audio": PreviewAudio
}

// Class

class PreviewMedia extends Component {
  
  static defaultProps = {
    format: "16:9",
  }
  
  getMediaType = () => {
    const { media } = this.props.formData;
    
    if (!media) {
      return undefined
    }
    
    const { mediaType, imageUrl } = media;
    
    if (mediaType) {
        return mediaType
    } else if (imageUrl) {
        return "image"
    }
    
    return undefined
      
  }
  
  getClassName = () => {

    const mediaType = this.getMediaType()
      
      let classNames = []
      
      classNames.push('field__media');

        if (mediaType) {
          classNames.push('media--' + mediaType);
        }
    
    return classNames.join(' ')

      
  }

  renderMedia = () => {

    const { formData, format } = this.props;
    const { mediaId, media, content } = formData;
    const { title, mediaType, imageUrl, resources } = media || {};

    const className = this.getClassName();
    
    let MediaTemplate;
    
    if (templates[mediaType] && resources) {
      MediaTemplate = templates[mediaType];
    } else if (imageUrl) {
      MediaTemplate = templates["image"];
    }

    if (MediaTemplate) {

      return (
          
        <div className={ className } data-format={ format } >
          <MediaTemplate { ...this.props } />
        </div>
        
      )
        
    }

    return (

      <div className={ className } data-format={ format } >

        <div className="media__preview">
          <h2 className="media__title">{ title }</h2>
          <h2 className="media__description">{ mediaType }</h2>
        </div>

      </div>
      
    )
    
    
  }
  
  renderContent = () => {
    
    const { content } = this.props.formData;
    
    if (!content) {
      return false;
    }
    
    return false;
    
    const { headline, description, category, creditLine } = content;
    
    if (creditLine) {

      return (

        <figcaption className="media__caption">
          <strong>{ headline }</strong> {' '}
          { description } {' '}
          <em className="media__credit" data-prefix={ category }>{ creditLine }</em>
        </figcaption>
        
      )
      
    }
    
    return (
      
      <figcaption className="media__caption">
        <strong>{ headline }</strong> {' '}
        { description }
      </figcaption>
      
    )
    
  }
  
  render() {

    const { id, formData } = this.props;

    if (!formData) {
      return false;
    }

    const mediaType = this.getMediaType()
    
    return (

      <figure className="admin-preview__field  field--media">
        <PreviewLabel id={ id || "media"} type={mediaType} />
        { this.renderMedia() }
        { this.renderContent() }
      </figure>

    )
    
  }
  
}

export default PreviewMedia;