import React, { useState } from 'react';
import SchemaBase from "./SchemaBase"

const SchemaEditor = (props) => {
    const { schema, uiSchema, registry, formContext } = props;

    const languages = formContext.languages || ["en"]
    const defaultLocale = languages[0]

    const [formData, setFormData] = useState(props.formData || undefined)
    const [currentLocale, setCurrentLocale] = useState(formContext.currentLocale || defaultLocale)
    const [currentId, setCurrentId] = useState(formContext.currentId)

    const onChange = ({formData}) => {
        setFormData(formData)
    }

    const onCurrentId = (currentId) => {
        console.log('onCurrentId', currentId)
        setCurrentId(currentId)
        props.onCurrentId && props.onCurrentId(currentId)
    }

    const onToggleId = (id) => {
        if (!id) {
            return false
        }

        let parentId = id.split('_');
        parentId.pop();
        parentId = parentId.join('_');
        
        let expandId = undefined

        if (id && currentId && currentId.startsWith(id)) {
            expandId = parentId;            
        } else {
            expandId = id;
        }

        onCurrentId(expandId)
        
    }

    const onEdit = ({id, ...props}) => {
        onToggleId(id)
    }

    const onSave = ({id, ...props}) => {
        onToggleId(id)
    }

    const onExpand = ({id, ...props}) => {
        onToggleId(id)
    }

    const onCollapse = ({id, ...props}) => {
        onToggleId(id)
    }

    const onToggle = ({id, ...props}) => {
        onToggleId(id)
    }

    const onLocale = (locale) => {
        setCurrentLocale(locale)
    }

    const onUpload = (upload, event) => {
        formContext.onUpload && formContext.onUpload(upload, event)
    }

    const onDialog = (dialog, event) => {
        formContext.onDialog && formContext.onDialog(dialog, event)
    }

    const onAfterChange = (props) => {
        console.log('afterChange', props)
    }

    const onAfterAdd = ({id, formData}) => {
        console.log('afterAdd', id)
        const index = formData && formData.length && formData.length-1;
        const itemId = id + "_" + index;
        onCurrentId(itemId)
    }

    const onAfterRemove = ({id}) => {
        onCurrentId(id)
    }

    const onSelect = (select) => {
        props.onSelect && props.onSelect(select)
    }

    const onSubmit = (submit) => {
        props.onSubmit && props.onSubmit(submit)
    }

    return (
        <SchemaBase
            {...registry}
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            formContext={{
                ...formContext,
    
                languages: languages,
                defaultLocale: defaultLocale,
                currentLocale: currentLocale,
    
                currentId: currentId,
    
                onLocale: onLocale,
                onExpand: onExpand,
                onCollapse: onCollapse,
                onToggle: onToggle,
    
                onEdit: onEdit,
                onSave: onSave,
    
                onUpload: onUpload,
                onDialog: onDialog,

                onAfterChange: onAfterChange,
                onAfterAdd: onAfterAdd,
                onAfterRemove: onAfterRemove,
    
                onSelect: onSelect,
                onSubmit: onSubmit,
            }}
            onChange={onChange}
        />
    )

}

SchemaEditor.defaultProps = {
    uniqueId: undefined,
    parentId: undefined,
    referenceId: undefined,
    schema: undefined,
    uiSchema: undefined,
    formData: undefined,
    formContext: {
        
    },
    registry: {
        fieldtypes: {},
        fields: {},
        layouts: {},
        widgets: {}
    }
}


export default SchemaEditor