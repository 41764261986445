import { combineReducers } from 'redux'

import user from './reducers/user'
import userApps from './reducers/userApps'

import schemas from './reducers/schemas'
import schemasByName from './reducers/schemasByName'
import schemasById from './reducers/schemasById'

import authRoles from './reducers/authRoles'
import authSites from './reducers/authSites'
import authCollections from './reducers/authCollections'

import sitesById from './reducers/sitesById'
import collectionsById from './reducers/collectionsById'

import auth from "./reducers/auth"
import app from './reducers/app'
import appLayout from './reducers/appLayout'

import appsByUser from './reducers/appsByUser'
import appsBySite from './reducers/appsBySite'

import menuByUrl from './reducers/menuByUrl'

import owner from './reducers/owner'

import searchById from './reducers/searchById'
import uploadById from './reducers/uploadById'

import modelsById from "./reducers/modelsById"
import modelsBySource from "./reducers/modelsBySource"

import referencesById from "./reducers/referencesById"

import inbox from './reducers/inbox'


import data from './reducers/data'

import site from './reducers/site'
import collection from './reducers/collection'

import nav from './reducers/nav'
import navParents from './reducers/navParents'

import dashboard from './reducers/dashboard'

import finder from './reducers/finder'
import bulk from './reducers/bulk'
import favourites from './reducers/favourites'

import editor from './reducers/editor'
import dialog from './reducers/dialog'

import sidebar from './reducers/sidebar'

import usersById from './reducers/usersById'

import search from './reducers/search'
import reports from './reducers/reports'

const reducers = combineReducers({
    user,
    userApps,
    usersById,

    schemas,
    schemasByName,
    schemasById,

    authRoles,
    authSites,
    authCollections,



    sitesById,
    collectionsById,
    
    site,
    collection,

    app,
    appLayout,
    appsByUser,
    appsBySite,

    menuByUrl,

    nav,
    navParents,

    search,
    searchById,
    uploadById,
 
    modelsById,
    modelsBySource,
    referencesById,

    owner,

    inbox,

    data,

    dashboard,

    finder,
    bulk,
    favourites,

    editor,
    dialog,
    sidebar,

    auth,

    reports
})
 
export default reducers