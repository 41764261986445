export default {
    "ui:field": "collectionSchemaReference",
    "ui:layout": "module",
    "ui:preview": {
        "select": {
            "title": "title"
        },
        prepare({formData}) {
            const { model } = formData

            const title = model && model.title

            return {
                title: title
            }

        }
    },
    "ui:buttons": [
        {
            "type": "save",
            "label": "Lagre"
        },
        {
            "type": "edit",
            "label": "Rediger schema"
        }
    ],
    "ui:options": {
        "editable": true,
        "deletable": true,
        "restorable": true,
        "hideable": true
    },
    "ui:nav": true,
    "ui:fieldset": [
        "content",
        "model"
    ],
    "model": {
        "ui:title": "Schema",
        "ui:spacing": 2
    },
    "content": {
        "ui:fieldset": [
            "icon",
            "label",
            "role",
            "hidden"
        ],
        "ui:title": "Meny",
        "ui:spacing": 2,
        "icon": {
            "ui:title": "Velg ikon",
            "ui:widget": "icon"
        },
        "label": {
            "ui:title": "Tittel"
        },
        "role": {
            "ui:title": "Rolle i meny"
        },
        "hidden": {
            "ui:title": "Skjul dokumenttype"
        }
    },
    "ui:settings": [
        "role"
    ]
}
