import React, { Component } from 'react';
import "./AnalyticsTable.scss"

import Color from "color"
import _ from "lodash"

import TableHead from './AnalyticsTableHead';
import TableBody from './AnalyticsTableBody';

class AnalyticsTable extends Component {
    
    static defaultProps = {
        urlPattern: undefined,
        report: undefined,
        sortBy: undefined,
        sortOrder: 'ascending',
    }
    
    state = {
        report: undefined,
        sortBy: undefined,
        sortOrder: undefined
    }
    
    componentDidMount() {
        this.getReport()
    }

    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.report !== this.props.report) {
            this.getReport()
        }
        
    }
    
    getReport = () => {
        const { report, sortBy, sortOrder, urlPattern } = this.props;
        
        this.setState({
            totals: [],
            maximums: [],
            minimums: [],
            sortedRows: [],
            rows: [],
        })

        if (!report) {
            return false
        }
        
        const dimensionHeaders = report.columnHeader.dimensions;
        const metricHeaders = report.columnHeader.metricHeader.metricHeaderEntries;
        
        const { totals, maximums, minimums, rows } = report.data;
        
        const { orderBys } = report.request;
        
        let defaultSortBy, defaultSortOrder;
        
        if (orderBys && orderBys[0]) {
            defaultSortBy = orderBys[0].fieldName;
            defaultSortOrder = orderBys[0].sortOrder;
        }

        const sortedRows = rows.map((row => {

            let metric = {}

            dimensionHeaders.map((d, index) => {
                metric[d] = row.dimensions[index]
            })

            metricHeaders.map((m, index) => {
                const { name } = m;
                metric[name] = parseFloat(row.metrics[0].values[index])
            })
            
            return {
                ...row,
                ...metric
            }
                        
            
        }))
        
        this.setState({
            urlPattern: urlPattern,
            dimensionHeaders: dimensionHeaders,
            metricHeaders: metricHeaders,
            totals: totals[0].values,
            maximums: maximums[0].values,
            minimums: minimums[0].values,
            sortedRows: sortedRows,
            rows: rows,
            sortBy: sortBy || defaultSortBy || metricHeaders[0].name,
            sortOrder: sortOrder || defaultSortOrder
        })
        
    }
    
    getClassName = () => {
        return "admin-analytics-table"
    }

    onSort = (sortBy, sortOrder) => {
        
        if (this.props.onSort) {
            this.props.onSort(sortBy, sortOrder)
        }
        
        let { sortedRows } = this.state;
        
        sortedRows = _.orderBy(sortedRows, sortBy, sortOrder); // Use Lodash to sort array by 'name'
        
        console.log('sortedRows', sortedRows)
        
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
            sortedRows: sortedRows
        })
        
    }

    getThemeStyle = () => {
        const { theme } = this.props;

        if (!theme) {
            return {}
        }
        
        const { primaryColor } = theme;
        
        if (!primaryColor) {
            return {}
        }
    
        const backgroundColor = Color(primaryColor)
        
        let color;
        
        if (backgroundColor.isDark()) {
            color = "white"
        } else {
            color = "black"
        }
        
        return {
            backgroundColor: backgroundColor,
            color: color
        }
        
    }
    
    render() {
        const className = this.getClassName();
        
        const { metricHeaders, metricRows, sortBy, sortOrder } = this.state;
        
        if (!metricHeaders) {
            return false
        }
        
        const thWidth = 30;
        const thStyle = {
            width: thWidth + "%"
        }
        
        const tdWidth = (100 - thWidth) / (metricHeaders.length);
        const tdStyle = {
            width: tdWidth + "%"
        }
        
        const themeStyle = this.getThemeStyle()
        
        return (

            <table className={className}>
                <TableHead {...this.state} thStyle={thStyle} tdStyle={tdStyle} themeStyle={themeStyle} onSort={this.onSort} />
                <TableBody {...this.state} thStyle={thStyle} tdStyle={tdStyle} onSort={this.onSort} />
            </table>
            
        )
        
    }
    
}

export default AnalyticsTable;