export default {
  "type": "string",
  "enum": [
    "grid",
    "grid-wide",
    "slideshow",
    "slideshow-wide"
  ],
  "enumNames": [
    "Grid",
    "Grid Wide",
    "Slideshow",
    "Slideshow Wide"
  ],
  "default" : "grid"
}