import React, { Component } from 'react';
import PropTypes from "prop-types";

import { Widget, WidgetHeader, WidgetTab, WidgetBody, WidgetFooter, WidgetLoading } from "../"
import { getGaDataByMetrics } from "../../resources/functions/"
import { FormatMetric } from "../"
import { Chart } from "../"

import "./AnalyticsReportMetrics.scss"

class AnalyticsReportMetrics extends Component {
    static propTypes = { 
        loadingTitle: PropTypes.string,
        loadingDescription: PropTypes.string,
        scope: PropTypes.string
    }

    static defaultProps = {
        loadingTitle: "Loading metrics report",
        reportType: "metrics",
        scope: undefined,
        widgetType: "analytics-report-metrics",
        widgetSize: "2:6",
        theme: {},
        report: undefined,
        metrics: [],
        dimension: undefined,
        type: "line",
        compare: true,
        stacked: false,
        debug: false
    }
    
    state = {
        tabIndex: 0,
        tabs: undefined,
        loading: true,
        values: {}
    }
    
    componentDidMount() {
        this.getReport()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.report !== this.props.report) {
            this.getReport()
        }
    }
    
    getCompareHeader = (col) => {
        const { data } = col;
        const { metric, xFormat, yFormat } = data[0]
        
        const s1 = data[0];
        const s2 = data[1];
        
        let value = <FormatMetric type="INTEGER" value={s1.total} /> // formatNumber(s1.total);

        let change, trending;
        
        if (s1.total > s2.total) {
            change = (s1.total - s2.total) / s1.total * 100;
            trending = "up"
        } else if (s2.total > s1.total) {
            change = (s2.total - s1.total) / s2.total * 100;
            trending = "down"
        } else {
            change = 0
            trending = "flat"
        }
        
        change = <FormatMetric type="PERCENT" value={change} /> // formatPercent(change)
        
        if (yFormat === "PERCENT") {
            value = <FormatMetric type="PERCENT" value={s1.average} /> // formatPercent(s1.average)
        }

        if (yFormat === "TIME") {
            value = <FormatMetric type="DURATION" value={s1.average} /> // formatDuration(s1.average)
        }
        
        return {
            id: metric,
            label: metric,
            value: value,
            change: change,
            trending: trending
        }
                
    }
    
    getHeader = (col) => {
        const { metric, xFormat, yFormat, data, total, average } = col;

        if (!data.length) {
            return false
        }

        const { compare } = this.props;
        
        if (compare && data[0] && data[1]) {
            return this.getCompareHeader(col)
        }

        let value = <FormatMetric type="INTEGER" value={total} /> // formatNumber(total);
        
        if (yFormat === "PERCENT") {
            value = <FormatMetric type="PERCENT" value={average} /> // formatPercent(average)
        }

        if (yFormat === "TIME") {
            value = <FormatMetric type="DURATION" value={average} /> // formatDuration(average)
        }
        
        return {
            id: metric,
            label: metric,
            value: value
        }
        
    }
    
    
    onSelectTab = (index) => {
        this.setState({
            tabIndex: index
        })
    }
    
    renderChart = (metric, index) => {
        let { theme, colors, type, compare, stacked } = this.props;
        
        let yScaleMax = "auto";

        const { maximum, minimum, yFormat, data, startDate, endDate, days } = metric;
        
        if (yFormat === "PERCENT") {
            yScaleMax = Math.ceil((maximum+1)/10)*10
            console.log("MAX", yScaleMax)
        }
        
        if (index === this.state.tabIndex) {

            return (
                <Chart {...metric} type={type} compare={compare} stacked={stacked} yScaleMax={yScaleMax} outerMargin={{top: 20, right: 20, bottom: 20, left: 20}} theme={theme} colors={colors} key={index} />
            )

        }
        
        return false
        
    }
    
    renderTab = (tab, index) => {
        const { theme } = this.props;
        const { id, label, value, change, trending } = this.getHeader(tab)

        const selected = this.state.tabIndex === index;
        
        if (change && trending) {

            return (
                <WidgetTab theme={theme} selected={selected} onClick={() => this.onSelectTab(index)} key={index}>
                    <h2 className="admin-metric__label"><FormatMetric name={id} /></h2>
                    <h3 className="admin-metric__value">{value}</h3>
                    <p className="admin-metric__change" data-trending={trending}>{change}</p>
                </WidgetTab>
            )

        }

        return (
            <WidgetTab theme={theme} selected={selected} onClick={() => this.onSelectTab(index)} key={index}>
                <h2 className="admin-metric__label">{label}</h2>
                <h3 className="admin-metric__value">{value}</h3>
            </WidgetTab>
        )
        
        
    }
    
    getReport = () => {
        const { report, metrics, dimension } = this.props;
        
        this.setState({
            tabs: undefined,
            loading: true
        })
        
        if (!report) {
            return false
        }
        
        const data = getGaDataByMetrics(report, metrics, dimension)
        const tabs = Object.values(data)
        
        this.setState({
            tabs: tabs,
            loading: false
        })
        
        
    }
    
    render() {
        const { theme, widgetType, widgetSize, debug, reportType, scope, loadingTitle, loadingDescription } = this.props;
        const { tabs, values } = this.state;

        const loading = this.state.loading || this.props.loading;

        if (loading) {
            return (
                <Widget {...this.props}>
                    <WidgetLoading theme={theme} title={loadingTitle} description={loadingDescription} />            
                </Widget>
            )
        }
        
        return (
            <Widget {...this.props}>
                <WidgetHeader>
                    { tabs.map(this.renderTab) }
                </WidgetHeader>
                <WidgetBody>
                    { tabs.map(this.renderChart) }
                </WidgetBody>
            </Widget>
        )
        
        
    }

}

export default AnalyticsReportMetrics;