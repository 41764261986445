import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSiteByName } from '../redux/functions/site';

import SiteRoutes from "./SiteRoutes"

class Site extends Component {
  
    componentDidMount() {
        this.getSite();
    }

    componentDidUpdate = (prevProps) => {
      
        if (prevProps.match.params.siteName !== this.props.match.params.siteName) {
            this.getSite();
        }
    
    }
    
    getSite = () => {
        const { siteName } = this.props.match.params;
    
        if (!siteName) {
            return false;
        }
    
        this.props.getSiteByName(siteName)

    }

    render() {
        const { site } = this.props;
        
        if (!site.uniqueId) {
            
            return (
                <p>Loading site</p>
            )
            
        }
    
        return (
            <SiteRoutes {...this.props} />
        )

    }

}

function mapStateToProps(state) {
	return {
    	site: state.site
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
    getSiteByName
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Site);

