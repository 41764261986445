export default {
    "ui:layout": "section",
    "ui:options" : {
        "expanded": true,
        "collapsible": true,
        "spacing": 2
    },
    "ui:title": "Tema",
    "ui:preview": {
        "select": {
            "description": "description"
        },
        prepare({formData, formContext}) {
            const localeId = "locale:" + formContext.currentLocale
            const description = formData.title && formData.title[localeId]  
    
            return {
                description: description
            }
    
        }
    },
    "ui:fieldset": [
        "image",
        "title",
        "leadtext",
        "bodytext"
    ],
    "image": {
        "ui:field": "media",
        "ui:format": "2:1",
        "ui:options": {
            "editable": true,
            "cropdata": true,
            "focalpoint": true,
            "adjust": true
        },
        "ui:buttons": [
            {
                "title": "Finn bilde",
                "type": "sidebar/media/image"
            },
            "upload"
        ]
    },
    "title": {
        "ui:title": "Tittel"
    },
    "leadtext": {
        "ui:title": "Ingress",
        "ui:widget": "textarea"
    },
    "bodytext": {
        "ui:title": "Brødtekst",
        "ui:widget": "textarea"
    }
}