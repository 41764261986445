import React from 'react';

import FinderModel from "./FinderModel"
import FinderSection from "../components/Finder/FinderSection"

import ResultsLoader from "./ResultsLoader"
import ResultsHeader from "./ResultsHeader"
import ResultsPages from "./ResultsPages"

const ResultsList = ({layout = "list", ...props}) => {
    const { models = [] } = props

    return (
        <ResultsLoader {...props}>
            <FinderSection>
                <ResultsHeader {...props} />
                    {models.map((model, index) => {
                        return (
                            <FinderModel model={model} layout={layout} {...props} key={index} />
                        )
                    })}
                <ResultsPages {...props} />
            </FinderSection>
        </ResultsLoader>
    )

}

export default ResultsList