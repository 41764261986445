import React, { useRef, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ImageFocalpoint from "./ImageFocalpointBase"

const useStyles = makeStyles(theme => ({
    root: {
//        backgroundColor: "#333",
//        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    preview: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
//        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(6)
    },
    footer: {
        position: "absolute",
        top: "auto",
        right: 0,
        left: 0,
        bottom: 0,
        paddingTop: theme.spacing(1),
        height: theme.spacing(5),
    },
    description: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "12px",
        textAlign: "center"
    }
}));


const EditImageFocalpoint = ({className, imageUrl, imageFocalpoint = {}, imageCropdata, imageFilters, onChange, ...props}) => {
    const classes = useStyles()

    const handleChange = (imageFocalpoint) => {
        onChange && onChange(imageFocalpoint)
    }

    return (
        <div className={className || classes.root}>
            <div className={classes.preview}>
                <ImageFocalpoint imageUrl={imageUrl} imageCropdata={imageCropdata} imageFilters={imageFilters} imageFocalpoint={imageFocalpoint} onChange={handleChange} />
            </div>
            <footer className={classes.footer}>
                <Typography nowrap className={classes.description}>
                    Klikk på bildet for å sette fokuspunkt
                </Typography>
            </footer>
        </div>
    )

}

export default EditImageFocalpoint;