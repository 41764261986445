import sidebar from "../sidebar/uiSchema"
import search from "../search/uiSchema"
import upload from "../upload/uiSchema"

export default {
    "ui:layout": "section",
    "ui:icon": "settings",
    "ui:title": "Innstillinger",
    "ui:fieldset": [
        "sidebar",
        "search",
        "upload",
    ],
    "sidebar": sidebar,
    "search": search,
    "upload": upload,
}