import React, { useState } from "react"
import { getUiOptions } from "./"

const isExpanded = (props) => {
    const { id, name, uiOptions, registry } = props;
    const { collapsible, editable, expanded } = uiOptions;
    
    const { formContext } = registry;
    const { currentId } = formContext;

    if (editable && expanded && !currentId) {
        return true
    } else if (editable && currentId && currentId.startsWith(id)) {
        return true
    } else if (editable) {
        return false
    } else if (collapsible && expanded && !currentId) {
        return true
    } else if (collapsible && currentId && currentId.startsWith(id)) {
        return true
    } else if (collapsible) {
        return false
    }
    
    return;
}

const isEditing = (props) => {
    const { id, uiOptions, registry } = props;
    const { editable, expanded } = uiOptions;

    const { formContext } = registry;
    const { currentId } = formContext;

    if (editable && expanded) {
        return true
    } else if (editable && currentId && currentId.startsWith(id)) {
        return true
    }
    
    return false;
}

export const getUiLayout = ({schema = {}, ...props}) => {
    const { id, idPrefix, name, idSchema, formData, uiSchema, registry } = props;
    const uiOptions = getUiOptions(uiSchema)

    // layout
    let { layout, widget } = uiOptions;
    
    // title, description, summary, help
    let { title, description, help } = uiOptions;

    title = title && typeof title === "string" && title || schema.title || name || formData && formData.title && typeof formData.title === "string" && formData.title || undefined;
    description = description && typeof description === "string" && description || undefined;
    help = description && typeof description === "string" && description || uiOptions.help || undefined;

    // disabled, readonly, autofocus
    const disabled = Boolean(props.disabled || uiSchema["ui:disabled"]);
    const readonly = Boolean(props.readonly || uiSchema["ui:readonly"]);
    const autofocus = Boolean(props.autofocus || uiSchema["ui:autofocus"]);
    
    // editable, editing
    const editable = uiOptions.editable && Boolean(uiOptions.editable) || false;
    const editing = isEditing({...props, uiOptions})
    
    // collapsible + expanded
    const collapsible = uiOptions.collapsible && Boolean(uiOptions.collapsible) || false;
    const expanded = isExpanded({...props, uiOptions})

    /*
    
    let expanded;
    
    if (uiOptions.expanded === false) {
        expanded = Boolean(uiOptions.expanded)
    } else if (uiOptions.expanded) {
        expanded = Boolean(uiOptions.expanded)
    } else {
        expanded = isExpanded(props)
    }
    */
    
    // hideable, deletable, restorable
    let hideable = Boolean(uiOptions.hideable);
    let deletable = Boolean(uiOptions.deletable);
    let restorable = Boolean(uiOptions.restorable);
    
    // orderable
    let orderable;

    if (uiOptions.orderable === false) {
        orderable = false
    } else {
        orderable = true
    }
    
    // addable + removable
    let addable, removable;

    if (uiOptions.addable === false) {
        addable = false
    } else {
        addable = Boolean(uiOptions.addable)
    }

    if (uiOptions.removable === false) {
        removable = false
    } else {
        removable = Boolean(uiOptions.removable) || props.isArrayItem && props.hasRemove
    }

    // default array layout

    if (!layout && schema.type === "array") {
        layout = "array"
    }

    if (!layout && schema.type === "object") {
        layout = "object"
    }
    
    // simple layouts

    if (!layout && (
        schema.type === "string" ||
        schema.type === "number" ||
        schema.type === "integer" ||
        schema.type === "boolean")
    ) {
        layout = "field"
    }

    // show label?
    let label;
    
    if (uiOptions.label === false) {
        label = false
    } else if (schema.type === "object") {
        label = schema.title && true || uiOptions.title && true || false
    } else if (schema.type === "boolean" && (widget === "select" || widget === "radio")) {
        label = true
    } else if (schema.type === "boolean") {
        label = false;
    } else {
        label = true;
    }

    // show header?
    let header

    if (!label) {
        header = false
    }

    // show footer?
    let footer

    if (schema.type == "object" && !uiOptions.buttons) {
        footer = false
    }

    return {
        ...uiOptions,

        name: name,
        
        id: id,
        idPrefix: idPrefix,

        header: header,
        footer: footer,
        
        collapsible: collapsible,
        expanded: expanded,
        
        editable: editable,
        editing: editing,

        addable: addable,
        removable: removable,
        hideable: hideable,
        deletable: deletable,
        restorable: restorable,

        disabled: disabled,
        readonly: readonly,
        autofocus: autofocus,
        
        label: label,

        title: title || undefined,
        description: description,
        help: help,
        
        layout: layout,

    }
  
}