import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModuleBase from "../Module/ModuleBase"
import ModuleMedia from '../Module/ModuleMedia';
import ModuleTitle from '../Module/ModuleTitle';
import ModuleLabel from '../Module/ModuleLabel';
import ModuleDescription from '../Module/ModuleDescription';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "& + $root": {
            marginTop: theme.spacing(.5)
        },

        "&:hover": {
            cursor: "pointer",
            boxShadow: theme.shadows[4]
        }

    },
    small: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "& > * + *": {
            marginLeft: theme.spacing(.5)
        }

    },
    body: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        maxWidth: "100%",
        flexBasis: "100%",
        overflow: "hidden",
        zIndex: 1,
        margin: theme.spacing(1)
    },
    media: {
        display: "flex",
    },
    content: {
        width: "100%",
        maxWidth: "100%",
        flexDirection: "column",
        overflow: "hidden",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    header: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        "& > * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
    footer: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",

        "& > * + *": {
            marginLeft: theme.spacing(.5)
        }

    },
}));

const InboxAttachment = ({ size = "medium", imageUrl, title, description, label, documentType, uniqueId, commentCount, onClick }) => {

    const classes = useStyles()
       
    let metadata = []
    
    if (uniqueId) {
        metadata.push('#' + uniqueId)
    }
    if (commentCount) {
        metadata.push(commentCount + " comments")
    }
    if (description) {
        metadata.push(description)
    }

    description = metadata.join(' – ')

    if (size === "small") {

        return (
            <ModuleBase elevation={1} className={classes.root} onClick={onClick}>
                <div className={classes.small}>
                    <ModuleMedia imageUrl={imageUrl} width="36px" height="36px" />
                    <header className={classes.header}>
                        <ModuleLabel className={classes.label} label={label || documentType} />
                        <ModuleDescription description={description} />
                    </header>
                </div>
            </ModuleBase>
        )    
            
    }

    return (
        <ModuleBase elevation={1} className={classes.root} onClick={onClick}>
            <div className={classes.body}>
                <div className={classes.media}>
                    <ModuleMedia imageUrl={imageUrl} width="48px" height="48px" />
                </div>
                <div className={classes.content}>
                    <header className={classes.header}>
                        <ModuleTitle title={title} />
                    </header>
                    <footer className={classes.footer}>
                        <ModuleLabel label={label || documentType} />
                        <ModuleDescription description={description} />
                    </footer>
                </div>
            </div>
        </ModuleBase>
    )    

}

InboxAttachment.propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    status: PropTypes.string,
    author: PropTypes.string,
    datetime: PropTypes.string,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
}

export default InboxAttachment;
