import React from 'react';
import { sortableContainer } from 'react-sortable-hoc';

import ArrayFieldSortableItem from "./ArrayFieldSortableItem"

const ArrayFieldSortableList = sortableContainer((props) => {
    const { arrayProps, items } = props;

    const { registry, canAdd, onAddItem, onChange } = arrayProps
    const { SchemaLayout } = registry.fields;

    return (
        <SchemaLayout {...arrayProps} onAdd={canAdd && onAddItem} onChange={onChange}>
            {items.map((value, index) => {
                const itemProps = { ...arrayProps, itemIndex: index }
                return (
                    <ArrayFieldSortableItem {...itemProps} value={value} index={index} key={`item-${index}`} />
                )
            })}
        </SchemaLayout>
    )

});

export default ArrayFieldSortableList;