export default {
  "type": "string",
  "format": "size",
  "enum": [
    "small",
    "medium",
    "large"
  ],
  "enumNames": [
    "S",
    "M",
    "L"
  ],
  "default" : "small"
}