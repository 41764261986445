import React, { Component } from 'react';
import "./DashboardHeader.scss";

class DashboardTitle extends Component {

    render() {
        const { title } = this.props;
        
        if (!title) {
            return false
        }

        return (
            <h2 className="admin-dashboard__title">{ title }</h2>
        )
    }
}

export default DashboardTitle;