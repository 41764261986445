// initialState

let initialState = {
};

// editor state

const modelsBySource = (state = initialState, action) => {

	switch (action.type) {

        case 'RECEIVE_MODEL':

            if (!action.sourceId) {
                return state
            }

            const id = action.source + "/" + action.sourceId

            return {
                ...state,
                [id]: action.data
            }

        default:
            return state
        }

}

export default modelsBySource;