import React, { Component } from 'react';

class WidgetFooter extends Component {
    
    static defaultProps = {
    }
    
    render() {

        return (
            <footer className="admin-widget__footer">
                { this.props.children }
            </footer>
        )
    }
}

export default WidgetFooter;