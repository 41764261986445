import moment from 'moment';

import { getGaBarByDimension } from "./gaChartBar"
import { getGaChoroplethByCountry } from "./gaChartChoropleth"

export const gaTimeDimensions = [
    "ga:date",
    "ga:year",
    "ga:month",
    "ga:week",
    "ga:day",
    "ga:hour",
    "ga:minute",
    "ga:nthMonth",
    "ga:nthWeek",
    "ga:nthDay",
    "ga:nthMinute",
    "ga:dayOfWeek",
    "ga:dayOfWeekName",
    "ga:dateHour",
    "ga:dateHourMinute",
    "ga:yearMonth",
    "ga:yearWeek",
    "ga:isoWeek",
    "ga:isoYear",
    "ga:isoYearIsoWeek",
    "ga:nthHour",
]

export function getGaDataByDimensions(report = undefined, metrics = [], dimensions = []) {

    if (!report) {
        return {
            error: "no report"
        }
    }
    
    // get metrics
    
    if (!metrics.length) {
        metrics = getGaMetrics(report)
    }
    
    // get dimensions

    if (!dimensions.length) {
        dimensions = getGaDimensions(report);
    }
    
    // get data by dimensions
    
    let dataByDimensions = []
    
    dimensions.map(dimension => {
        
        metrics.map(metric => {
            dataByDimensions[dimension + "/" + metric] = getGaDataByMetric(report, metric, dimension)
        }) 
        
    })
    
    return dataByDimensions
    
}

export function getGaDataByMetrics(report = undefined, metrics = [], dimension = undefined) {
    
    if (!report) {
        return {
            error: "no report"
        }
    }
    
    // get metrics
    
    if (!metrics.length) {
        metrics = getGaMetrics(report)
    }
    
    // get default dimension
    
    if (!dimension) {
        dimension = getGaDefaultDimension(report);
    }

    // get cols by dimension and metric
    
    const dataByMetrics = {}
    
    metrics.map((metric) => {
        dataByMetrics[metric] = getGaDataByMetric(report, metric, dimension)
    })

    return dataByMetrics;
    
}

export function getGaChartByType(type = "line", report, metric = undefined, dimension = undefined) {
    
    if (type === "choropleth") {
        return getGaChoroplethByCountry(report, undefined, "ga:countryIsoCode")
    }

    if (type === "bar") {
        return getGaBarChart(report, metric, dimension)
    }
    
    if (type === "line" || type === "area") {
        return getGaLineChart(report, metric, dimension)
    }

    if (type === "pie") {
        return getGaPieChart(report, metric, dimension)
    }
    
    
}

export function getGaLineChart(report, metric = undefined, dimension = undefined) {
    
    let data, chart;
    
    if (metric) {
        chart = getGaDataByMetric(report, metric, dimension)
    } else {
        data = getGaDataByMetrics(report)
        chart = getGaColsBySeries(Object.values(data))
    }
    
    return chart;
    
}

export function getGaBarChart(report, metric = undefined, dimension = undefined) {

    let data, chart;
    
    if (metric) {
        data = getGaDataByMetric(report, metric, dimension)
        chart = getGaDataByKeys(data)
    } else {
        data = getGaDataByMetrics(report)
        chart = getGaColsByKeys(Object.values(data))
    }
    
    
    
    return chart;
    
}

export function getGaPieChart(report, metric = undefined, dimension = undefined) {

    const data = getGaDataByMetric(report, metric, dimension)
    
    let chart = {
        data: [],
        total: 0
    }
    
    Object.values(data.data).map(serie => {
        const { id, total } = serie;

        const label = serie.data && serie.data[0] && serie.data[0][dimension];
        
        chart.total = chart.total + total;
        
        chart.data.push({
            ...serie,
            id: id,
            label: label,
            value: total,
        })
        
    })
    
    return chart;
    
}

export function getGaColsBySeries(cols) {
    
    let index = 0;
    let seriesByIndex = {}
    
    cols.map((col) => {

        col.data.map((serie) => {
            const { id } = serie;
            seriesByIndex[index] = {
                ...serie,
                id: id,
            }
            index++;
        })

    })
    
    return {
        ...cols[0],
        data: Object.values(seriesByIndex),
    }
    
}

export function getGaColsByKeys(cols) {
    
    let keys = []

    let seriesById = {}
    
    cols.map((col) => {
        
        const data = getGaDataByKeys(col)
        
        data.data.map((serie) => {
            const { id, points } = serie;
            
            if (!seriesById[id]) {
                seriesById[id] = serie;
            } else {
                seriesById[id] = {
                    ...seriesById[id],
                    ...serie
                };
            }
            
        })
        
        keys = keys.concat(data.keys);
        
    })
    
    return {
        ...cols[0],
        data: Object.values(seriesById),
        keys: keys
    }
    
    
}

export function getGaDataByKeys(chart) {
    const { dimension, metric, data } = chart;
    
    let keys = [], values = [];
    
    let seriesByX = {}
    
    data.map((serie) => {
        const { id } = serie;
        
        keys.push(id);
        
        serie.data.map((point) => {
            const { x, y, datetime } = point;
            
            if (!seriesByX[x]) {
                seriesByX[x] = {
                    id: x,
                    [dimension]: x,
                    points: []
                }
                
            }

            seriesByX[x][id] = y;
            seriesByX[x].points.push(point);
            
        })
        
    })
    
    return {
        ...chart,
        data: Object.values(seriesByX),
        keys: keys
    }
    
}

export function getGaDataByMetric(report, metric, dimension) {
    const { request } = report;
    
    if (!request) {
        return {
            error: "No request"
        }
    }

    // get defaults

    if (!metric) {
        metric = getGaDefaultMetric(report);
    }

    if (!dimension) {
        dimension = getGaDefaultDimension(report);
    }
    
    // get dateRanges

    const dateRanges = getGaDateRanges(report)

    if (!dateRanges) {
        return {
            error: "No dateRanges"
        }
    }
    
    // get unique series by dateRange and dimensions

    const { rows } = report.data;
    
    let series = {}

    const dimensionNames = getDimensionNames(report)
    const metricNames = getMetricNames(report)
    const metricType = getMetricTypeByName(report, metric)
    
    dateRanges.map((range, rangeIndex) => {
        
        range.xFormat = "STRING"
        range.yFormat = metricType

        range.dimension = dimension;
        range.metric = metric;
        range.metricType = metricType;
        
        rows.map((row, index) => {
            
            let serie = {}
            let serieId = [];

            serieId.push('startDate:' + range.startDate);
            serieId.push('endDate:' + range.endDate);

            serieId.push(metric);
            serieId.push(dimension);

            // get dimensions + x
            
            row.dimensions.map((dValue, dIndex) => {
                const dName = dimensionNames[dIndex];
                serie[dName] = dValue;
                
                if (dName === dimension) {
                    serie.x = dValue
                }
                
                // add to id if not a time dimension
                
                if (!gaTimeDimensions.includes(dName)) {
                    serieId.push(dName + ":" + dValue)
                }
                
                
            })
            
            // get values
            
            const { values } = row.metrics[range.id];
            
            values.map((mValue, mIndex) => {
                const mName = metricNames[mIndex];

                serie[mName] = mValue;

                if (mName === metric) {
                    serie.y = parseFloat(mValue)
                    serie.value = serie.y
                }

            })
            
            // get time dimensions
            
            serie = getGaSerieTimeDimensions(range, serie)
            
            // id

            serieId = serieId.join('/')
            
            // push serie to series
            
            if (!series[serieId]) {
                series[serieId] = {
                    ...range,
                    id: serieId,
                    data: []
                }
            }
            
            series[serieId].data.push(serie)
            
        })
        
    })
    
    // series totals

    const data = Object.values(series).map((serie, index) => {

        serie = getGaSerieTotals(serie)

        return {
            ...serie,
//            id: index
        }
        
    })
    
    
    const metricTotals = getGaMetricTotals(data);

    const length = metricTotals.data[0].data.length;
    
    const scope = getGaScope(dimension, length);
    
    return {
        ...dateRanges[0],
        xFormat: "STRING",
        yFormat: metricType,
        dimension: dimension,
        metric: metric,
        metricType: metricType,
        scope: scope,
        ...metricTotals
    }

}

export function getGaScope(dimension, length) {
    
    let scope;

    if (dimension === "ga:nthYear") {
        scope = length + "years"
    }    

    if (dimension === "ga:nthMonth") {
        scope = length + "months"
    }    
    
    if (dimension === "ga:nthWeek") {
        scope = length + "weeks"
    }    

    if (dimension === "ga:nthDay") {
        scope = length + "days"
    }    

    if (dimension === "ga:nthHour") {
        scope = length + "hours"
    }    

    if (dimension === "ga:nthMinute") {
        scope = length + "minutes"
    }    
    
    return scope;
    
}

export function getGaMetricTotals(data) {
    let total = 0, count = 0, minimum = undefined, maximum = undefined;

    data.map((serie, index) => {

        total = total + serie.total;
        count = count + serie.count;
        
        if (!minimum) {
            minimum = serie.minimum
        }
        
        if (serie.minimum < minimum) {
            minimum = serie.minimum
        }

        if (!maximum) {
            maximum = serie.maximum
        }
        
        if (serie.maximum > maximum) {
            maximum = serie.maximum
        }
        
    })
    
    return {
        total: total,
        count: count,
        minimum: minimum,
        maximum: maximum,
        average: total / count,
        data: data
    }
    
}

export function getGaSerieTimeDimensions(range, serie) {
    
    const { dimension, startDate, endDate } = range;
    
    // get datetime
    
    let year, month, week, day, hour, minute, datetime;

    if (dimension === "ga:nthMinute" && serie[dimension]) {
        datetime = moment(startDate).add(serie[dimension], 'minutes').format()
        year = moment(datetime).format('YYYY')
        month = moment(datetime).format('MM')
        week = moment(datetime).format('ww')
        day = moment(datetime).format('DD')
        hour = moment(datetime).format('HH')
        minute = moment(datetime).format('MM')
    }

    if (dimension === "ga:nthHour" && serie[dimension]) {
        datetime = moment(startDate).add(serie[dimension], 'hours').format()
        year = moment(datetime).format('YYYY')
        month = moment(datetime).format('MM')
        week = moment(datetime).format('ww')
        day = moment(datetime).format('DD')
        hour = moment(datetime).format('HH')
    }

    if (dimension === "ga:nthDay" && serie[dimension]) {
        datetime = moment(startDate).add(serie[dimension], 'days').format()
        year = moment(datetime).format('YYYY')
        month = moment(datetime).format('MM')
        week = moment(datetime).format('ww')
        day = moment(datetime).format('DD')
    }

    if (dimension === "ga:nthWeek" && serie[dimension]) {
        datetime = moment(startDate).startOf('week').add(serie[dimension], 'weeks').format()
        year = moment(datetime).format('YYYY')
        month = moment(datetime).format('MM')
        week = moment(datetime).format('ww')
    }

    if (dimension === "ga:nthMonth" && serie[dimension]) {
        datetime = moment(startDate).startOf('month').add(serie[dimension], 'months').format()
        year = moment(datetime).format('YYYY')
        month = moment(datetime).format('MM')
    }
    
    return {
        ...serie,
        datetime: datetime,
        year: year,
        month: month,
        week: week,
        day: day,
        hour: hour,
        minute: minute
    }
    
    return serie;

    
}

export function getGaSerieTotals(serie) {
    let total = 0, count = 0, minimum = undefined, maximum = undefined;
    
    serie.data.map((row, index) => {
        const { y } = row;
        
        total = total + y;
        count = count + 1;
        
        if (!minimum) {
            minimum = y
        }
        
        if (y < minimum) {
            minimum = y
        }

        if (!maximum) {
            maximum = y
        }
        
        if (y > maximum) {
            maximum = y
        }
        
    })
    
    return {
        ...serie,
        total: total,
        count: count,
        minimum: minimum,
        maximum: maximum,
        average: total / count
    }
    
}

/*

export function getGaSerieByRange(report, range, metric, dimension) {
    const dimensionNames = getDimensionNames(report)
    const metricNames = getMetricNames(report)

    const { rows } = report.data;
    
    let series = []
    
    rows.map((row, index) => {
        let serie = {}
        
        row.dimensions.map((dValue, dIndex) => {
            const dName = dimensionNames[dIndex];
            serie[dName] = dValue;
        })
        
        const { values } = row.metrics[range.id];
        
        values.map((mValue, mIndex) => {
            const mName = metricNames[mIndex];
            serie[mName] = mValue;
        })
        
        if (dimension === "ga:nthHour" && serie[dimension]) {
            serie.datetime = moment(range.startDate).add(serie[dimension], 'hours').format()
        }

        if (dimension === "ga:nthDay" && serie[dimension]) {
            serie.datetime = moment(range.startDate).add(serie[dimension], 'days').format()
        }

        if (serie[dimension]) {
            serie.x = serie[dimension]
        }
        
        if (serie[metric]) {
            serie.y = serie[metric]
        }
        
        series.push(serie)
        
    })

    const { totals, minimums, maximums, rowCount } = report.data;
    
    let serieTotals = {}, serieAverages = {}, serieMinimums = {}, serieMaximums = {}
    
    totals[range.id].values.map((mValue, mIndex) => {
        const mName = metricNames[mIndex];
        serieTotals[mName] = parseFloat(mValue);
        serieAverages[mName] = parseFloat(mValue) / rowCount;
    })

    minimums[range.id].values.map((mValue, mIndex) => {
        const mName = metricNames[mIndex];
        serieMinimums[mName] = parseFloat(mValue);
    })

    maximums[range.id].values.map((mValue, mIndex) => {
        const mName = metricNames[mIndex];
        serieMaximums[mName] = parseFloat(mValue);
    })
    
    const metricType = getMetricTypeByName(report, metric)
    
    return {
        ...range,
        xFormat: "STRING",
        yFormat: metricType,
        dimension: dimension,
        metric: metric,
        metricType: metricType,
        total: serieTotals[metric],
        average: serieAverages[metric],
        minimum: serieMinimums[metric],
        maximum: serieMaximums[metric],
        count: rowCount,
        data: series
    }
    
    return series;
    
}

*/


export function getGaDefaultMetric(report) {
    const { request } = report;
    
    if (request['metrics']) {
        return request['metrics'][0].expression
    }
    
    return false;
    
}

export function getGaDefaultDimension(report) {
    const { request } = report;
    
    if (request['orderBys'] && request['orderBys'].length) {
        return request['orderBys'][0].fieldName
    }
    
    if (request['dimensions'] && request['dimensions'].length) {
        return request['dimensions'][0].name
    }
    
    return false;
    
}

export function getGaMetrics(report) {
    const { request } = report;
    
    if (!request['metrics']) {
        return {
            error: "No metrics"
        }
    }
    
    return request['metrics'].map((m) => {
        return m.expression
    })
    
}

export function getGaDimensions(report) {
    const { request } = report;
    
    if (!request['dimensions']) {
        return {
            error: "No dimensions"
        }
    }
    
    return request['dimensions'].map((d) => {
        return d.name;
    })
    
}




export function getDimensionNames(report) {
    return report.columnHeader.dimensions;
}

export function getMetricNames(report) {
    const { metricHeaderEntries } = report.columnHeader.metricHeader;

    return metricHeaderEntries.map((metric) => {
        return metric.name
    })
    
}

export function getMetricTypeByName(report, name) {
    const { metricHeaderEntries } = report.columnHeader.metricHeader;

    const metric = metricHeaderEntries.filter((m) => {
        if (m.name === name) {
            return true
        }
    })
    
    if (metric && metric[0] && metric[0].type) {
        return metric[0].type;
    }
    
    return name;
    
}

export function getGaDateRanges(report) {
    const { request } = report;

    if (!request['dateRanges']) {
        return {
            error: "No dateRanges"
        }
    }

    return request['dateRanges'].map((range, index) => {

        const startDate = getGaDate(range.startDate)
        const endDate = getGaDate(range.endDate)
        const days = getGaDays(startDate, endDate)
    
        return {
            id: index,
            index: index,
            startDate: startDate,
            endDate: endDate,
            days: days
        }    
        
    })
    
}

export function getGaDays(startDate, endDate) {
    
    startDate = getGaDate(startDate)
    endDate = getGaDate(endDate)
    
    return moment(endDate).diff(moment(startDate), 'days') + 1
}

export function getGaWeeks(startDate, endDate) {
    
    startDate = getGaDate(startDate)
    endDate = getGaDate(endDate)
    
    return moment(endDate).diff(moment(startDate), 'weeks')
}

export function getGaMonths(startDate, endDate) {
    
    startDate = getGaDate(startDate)
    endDate = getGaDate(endDate)
    
    return moment(endDate).diff(moment(startDate), 'months')
}


export function getGaDate(date) {
    
    let daysAgo;

    if (date.toLowerCase().includes('daysago')) {
        daysAgo = parseInt(date)
        date = moment().subtract(daysAgo, 'days').format('YYYY-MM-DD')
    } else if (date.toLowerCase().includes('yesterday')) {
        daysAgo = 1
        date = moment().subtract(daysAgo, 'days').format('YYYY-MM-DD')
    } else if (date.toLowerCase().includes('today')) {
        daysAgo = 0
        date = moment().subtract(daysAgo, 'days').format('YYYY-MM-DD')
    } else {
        daysAgo = 0
        date = moment(date).subtract(daysAgo, 'days').format('YYYY-MM-DD')
    }
    
    
    return date;
    
}