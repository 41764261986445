import React from "react";

export default (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <path fill="#CCCCCC" d="M46,17 L18,17 C15.78,17 14.02,18.8 14.02,21 L14,49 C14,51.2 15.78,53 18,53 L46,53 C48.2,53 50,51.2 50,49 L50,21 C50,18.8 48.2,17 46,17 Z"/>
        <path fill="#CCCCCC" d="M46,14 L18,14 C15.78,14 14.02,15.8 14.02,18 L14,46 C14,48.2 15.78,50 18,50 L46,50 C48.2,50 50,48.2 50,46 L50,18 C50,15.8 48.2,14 46,14 Z"/>
        <path fill="#0666B0" fillRule="nonzero" d="M14,38 L14,46 C14,48.2 15.76,50 17.98,50 L46,50 C48.2,50 50,48.2 50,46 L49.98,38 C49.98,38 13.98,38 14,38 Z"/>
        <path fill="#F2F2F2" d="M46,38 L38,38 C38,41.32 35.3,44 32,44 C28.7,44 26,41.32 26,38 L17.98,38 L17.98,18 L46,18 L46,38 Z"/>
        <g fill="#000000" transform="rotate(45 -5.692 47.713)">
          <rect width="10" height="2" y="8"/>
          <rect width="2" height="10" x="8"/>
        </g>
        <rect width="2" height="20" x="31" y="10" fill="#000000"/>
      </g>
    </svg>
)