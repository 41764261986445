import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchModel } from '../redux/functions/model';
import { InboxWidget } from '@frontend-components/admin';

class WidgetInbox extends Component {
    
    static defaultProps = {
        url: "/inbox",
        urlPattern: "/inbox/:appName/:ownerName/:uniqueId",
        query: {
            id: '/user/inbox',
            root: '/inbox',
            models: "documents",
            documentType: "message",
            sort: "updatedAt desc",
            rows: 5
        }
    }

    componentDidMount() {
        this.getWidget();
    }
    
    getWidget = () => {
        const { query } = this.props;
        this.props.searchModel(query.models, query.id, query);
    }

    getResult = (result, index) => {
        const { content } = result;
        
        if (!content.messages) {
            return
        }
        
        let { inboxType, appName, appColor, ownerName } = content;
        
        if (appName === "forum" || appName === "inbox") {
            appColor = undefined
        }
        
        return {
            ...result,
            ...content,
            appName: appName || "app",
            appColor: appColor,
            ownerName: ownerName || "user"
        }
        
        return result;
        
    }
  
    getData = () => {
        const { data, query } = this.props;      
        
        let results;               
        
        if (data[query.id] && data[query.id].models) {
            
            const { models, count, rows } = data[query.id];
            
            results = models.map(this.getResult);
            
            results = results.filter((result) => {
                if (result) {
                    return true
                }
            })
            
            return {
                results: results,
                count: count,
                rows: rows
            }
            

        }
        
        return {
            results: []
        }
        
    }

    render() {
        const { url, urlPattern, onSelect } = this.props;
        const data = this.getData()
    
        return (
            <InboxWidget {...this.props} data={data} url={url} urlPattern={urlPattern} onSelect={onSelect} />
        )
    
    }

}

function mapStateToProps(state) {
	return {
      	user: state.user,
      	data: state.data
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      searchModel
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetInbox);

