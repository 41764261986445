import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsWidget } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

class WidgetObjectTypes extends Component {

    static defaultProps = {
        widgetSize: "3:2",
        title: "Hvilke objekttyper er brukerne interessert i?",
        description: "Antall besøk etter objekttype",
        id: "chart",
        query: {
            countryId: "no",
            offset: 0,
            owner: 1,
            reportType: 0,
            rows: 1000,
            startDate: "7daysAgo",
            endDate: "yesterday",
            dimensions: "city",
            metrics: "sessions,percentNewSessions,newUsers,bounceRate,pageviewsPerSession,avgSessionDuration",
            orderBy: "sessions",
            sort: "descending",
        },
    }

    componentDidMount() {
        this.getReport()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.id !== this.props.id) {
            this.getReport()
        }
        
    }
    
    getReport = () => {
        const { dimensions, id, query } = this.props;
        
        let newDimensions = dimensions.split(',');
        
        newDimensions.unshift('nthDay')
        
        this.props.getReports({
            ...query,
            dimensions: "dimension4",
            metrics: "sessions",
            orderBy: "dimension4",
            id: id
        })
        
    }
    
    render() {
        const { theme, data, id } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        return (
            <AnalyticsWidget {...this.props} type="bar" stacked={true} report={report} theme={theme} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	theme: state.app.theme,
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetObjectTypes);