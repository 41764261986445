import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import qs from 'query-string';

import { SchemaBase, getUiPreview } from "@frontend-components/admin"

import { getAppLayout } from '../redux/functions/app';
import { getNavParents } from '../redux/functions/nav';
import { getEditor, getEditorRedirect, getEditorFormData, getEditorSchema, getEditorUiSchema, getEditorFormContext, getEditorParents, getEditorLanguages, editorCurrentId, editorLocale, editorDialog, dialogChange, editorChange } from '../redux/functions/editor';
import { getSchemaUpload } from '../redux/functions/uploadSchema';

import defaultRegistry from "../components/registry"
import registry from "./components/registry"

class AppEditor extends Component {

    static defaultProps = {
        uniqueId: undefined,
        parentId: undefined,
        referenceId: undefined,
        schema: undefined,
        uiSchema: undefined,
        formData: undefined,
        formContext: undefined,
        editor: {
            uniqueId: undefined
        },
        registry: {
            fieldtypes: {},
            fields: {},
            layouts: {},
            widgets: {}
        }
    }

    componentDidMount() {
        this.getEditor()
    }
  
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.uniqueId !== this.props.uniqueId) {
            this.getEditor()
        }

        if (prevProps.schema !== this.props.schema) {
            this.getEditor()
        }

        if (prevProps.uiSchema !== this.props.uiSchema) {
            this.getEditor()
        }

        if (this.props.formContext && this.props.formContext.parents && prevProps.formContext.parents !== this.props.formContext.parents) {
            this.getParents()
        }

        if (this.props.editor.uniqueId && prevProps.editor.uniqueId !== this.props.editor.uniqueId) {
            this.getUrl()
        }

    }

    getSchema = () => {
        const { schema } = this.props;
        this.props.getEditorSchema(schema);
    }

    getUiSchema = () => {
        const { uiSchema } = this.props;
        this.props.getEditorUiSchema(uiSchema);
    }

    getParents = () => {
        const { parents } = this.props.formContext;
        this.props.getEditorParents(parents);
    }

    getAction = () => {
        const { editor, parentId, referenceId, uniqueId, formContext, t } = this.props;

        let action

        if (formContext.primaryAction) {

            action = formContext.primaryAction

        } else {
    
            action = {
                type: "save",
                label: t("Save"),
                onClick: () => this.onSubmit(''),
                children: [
                    {
                        role: "group",
                        children: [
                            {
                                label: t("Save as draft"),
                                onClick: () => this.onSubmit('draft'),
                            },
                            {
                                label: t("Save and publish"),
                                onClick: () => this.onSubmit('publish'),
                            }
                        ]
                    }
                ]
            }
    
            if (editor.redirect) {
                action.children.push({
                    label: t("Save and exit"),
                    onClick: () => this.onSubmit(''),
                })
    
            } else if (uniqueId) {
                action.children.push({
                    label: t("Save as") + " ...",
                    onClick: () => this.onSubmit('copy'),
                })
            }
    
        }
        
        return action
    }

    getEditor = () => {
        const { collection, parentId, referenceId, uniqueId, schemaId, schema, uiSchema } = this.props;
        const { editor, location, formContext } = this.props;

        if (!schema || !uiSchema) {
            return false
        }

        let currentId;

        if (location && location.hash) {
            currentId = location.hash.replace('#', '');
        }

        const languages = formContext.languages || ["en"]
        const defaultLocale = languages[0]

        this.props.getAppLayout('editor')
        this.props.getNavParents(this.props.menuByUrl, this.props.location.pathname)

        this.props.getEditor({
            parentId: parentId,
            referenceId: referenceId,
            uniqueId: uniqueId,
            schemaId: schemaId,
            schema: schema,
            uiSchema: uiSchema,
            formContext: {
                collectionId: collection && collection.id,
                languages: languages && languages.length && languages,
                defaultLocale: defaultLocale,
                currentLocale: defaultLocale,

                currentId: currentId,
    
                onCurrentId: this.onCurrentId,
                onLocale: this.onLocale,
    
                onExpand: this.onExpand,
                onCollapse: this.onCollapse,
                onToggle: this.onToggle,
    
                onEdit: this.onEdit,
                onSave: this.onSave,
    
                onUpload: this.onUpload,
                onUploadReplace: this.onUploadReplace,
    
                onDialog: this.onDialog,

                onAfterAdd: this.onAfterAdd,
                onAfterRemove: this.onAfterRemove,
                onAfterChange: this.onAfterChange,

                onBack: this.onBack,

                onSelect: this.onSelect,
                onSubmit: this.onSubmit,
                ...formContext,
            }
        })


    }

    getUrl = () => {
        const { uniqueId } = this.props.editor;
        const { path, params } = this.props.match;
        const { hash, search } = this.props.location;

        // set redirect Url

        const { backUrl, backId } = qs.parse(search)

        if (backUrl && backId) {
            this.props.getEditorRedirect({
                pathname: backUrl,
                hash: "#" + backId
            })
        }

        // update location

        let location = path;
    
        if (hash) {
            location = location + hash;
        }
    
        for (let key in params) {
      
            let value;
          
            if (key === "uniqueId") {
                value = uniqueId
            } else {
                value = params[key];
            }

            if (value) {
                location = location.replace(':'+key+"*", value);
                location = location.replace(':'+key, value);
            } else {
                location = location.replace(':'+key+"*", '');
                location = location.replace(':'+key, '');
            }

            location = location.replace("//", "/");

        }

        location = location.replace("<new>", uniqueId);
    
        this.props.history.replace(location);

    }    

    onCurrentId = (currentId) => {
        console.log('editor:onCurrentId', currentId);

        const { dialog, location } = this.props;

        const hashUrl = location.pathname + "#" + currentId; 
        this.props.history.replace(hashUrl);

        this.props.editorCurrentId(currentId)

        if (dialog.id && currentId !== dialog.id) {
            this.onDialogClose()
        }

    }

    onToggleId = (id) => {
        const { currentId } = this.props.editor.formContext

        if (!id) {
            return false
        }

        let parentId = id.split('_');
        parentId.pop();
        parentId = parentId.join('_');
        
        let expandId = undefined

        if (id && currentId && currentId.startsWith(id)) {
            expandId = parentId;            
        } else {
            expandId = id;
        }

        this.onCurrentId(expandId)
        
    }

    onExpand = ({id}) => {
        this.onToggleId(id)
    }

    onCollapse = ({id}) => {
        this.onToggleId(id)
    }

    onToggle = ({id}) => {
        this.onToggleId(id)
    }

    onEdit = ({id}) => {
        this.onToggleId(id)
    }

    onSave = ({id}) => {
        this.onToggleId(id)
    }

    onChange = ({formData}) => {
        const { schema, formContext, uiSchema } = this.props.editor;

        const uiPreview = getUiPreview({formData, formContext, uiSchema}) || {}

        const name = uiPreview && uiPreview.name || formData && formData.name
        const title = uiPreview && uiPreview.title || formData && formData.title
        const description = uiPreview && uiPreview.description || null
        const imageUrl = uiPreview && uiPreview.imageUrl || formData && formData.imageUrl

        this.props.editorChange({
            ...formData,
            ...uiPreview,
        })
    }

    onLocale = (locale) => {
        this.props.editorLocale(locale)
    }

    onSubmit = (status, redirect) => {
        const { editor } = this.props;

        console.log('redirect', redirect)

        let formData = {
            ...editor.formData,
            status: status || editor.formData.status
        }

        if (status === "copy") {
            delete formData.uniqueId
            delete formData.id

            formData = {
                ...formData,
                title: formData.title && formData.title + " copy",
                name: formData.name && formData.name + "-copy",
                status: "new"
            }
        }

        if (this.props.onSubmit) {
            this.props.onSubmit({
                ...editor,
                formData: formData,
                redirect: redirect
            })
        }


        /*

        if (this.props.onSubmit) {
            this.props.onSubmit({
                ...this.props.editor,
                formData: {
                    ...formData,
                    schemaId: schemaId,
                    locale: formData.locale || formContext.defaultLocale,
                    status: status || formData.status
                },
                redirect
            })
        }

        */

        this.onDialogClose()
        
    }

    onUpload = (props, acceptedFiles = []) => {
        const { formContext } = props;
        const collectionId = formContext && formContext.collectionId

        const uploadData = {
            collectionId: collectionId,
            status: "upload"
        }

        console.log("uploadData", props)

        getSchemaUpload({...props, acceptedFiles, uploadData})
    
    }
    
    onUploadReplace = (acceptedFiles = []) => {

        const { schema, formData } = this.props.editor
 
        const uploadData = {
            collectionId: formData && formData.collectionId,
            uniqueId: formData && formData.uniqueId,
            status: formData && formData.status,
            content: formData && formData.content || {}
        }

        const handleChange = ({media}) => {
            this.onChange({formData:media})
        }

        getSchemaUpload({schema, formData, onChange: handleChange, acceptedFiles, uploadData})
       
    }

    onDialog = (dialog, event) => {

        this.onCurrentId(dialog.id)
        this.props.editorDialog({
            ...dialog,
            expanded: true,
            onClose: this.onDialogClose
        })
    }

    onDialogClose = () => {
        this.props.editorDialog({
            expanded: false
        })
    }

    onAfterAdd = ({id, formData}) => {
        const childIndex = formData && formData.length && formData.length-1;
        const childId = id + "_" + childIndex;

        this.onCurrentId(childId)
    }

    onAfterRemove = ({id}) => {
        this.onCurrentId(id)
    }

    onAfterChange = ({id, formData, ...props}) => {
        const { dialog } = this.props;

        const dialogId = dialog && dialog.id;
        const dialogFormData = dialog && dialog.formData;

        if (!dialogId) {
            return false
        }
        
        if (id === dialogId && formData !== dialogFormData) {
            this.props.dialogChange(formData)
        }
    }

    onBack = (event) => {
        const { parents } = this.props.formContext;

        if (parents && parents.length) {
            this.onSelect(parents[parents.length-1], event)
        }
        
    }

    onSelect = ({url}) => {

        console.log('onSelect', url)

        if (url) {
            this.props.history.push(url);
        }

    }

    getRegistry = () => {

        /*
        const { registry } = this.props;

        if (!registry) {
            return defaultRegistry
        }
        */

        return {
            fieldtypes: {
//                ...defaultRegistry.fieldtypes,
                ...registry.fieldtypes,
                ...this.props.registry.fieldtypes
            },
            fields: {
//                ...defaultRegistry.fields,
                ...registry.fields,
                ...this.props.registry.fields
            },
            layouts: {
//                ...defaultRegistry.layouts,
                ...registry.layouts,
                ...this.props.registry.layouts
            },
            widgets: {
//                ...defaultRegistry.widgets,
                ...registry.widgets,
                ...this.props.registry.widgets
            }
        }
        
    }

    render() {
        const { app, editor, t } = this.props;
        const { isLoading, isSaving, schema, uiSchema, formData, formContext } = editor

        let primaryAction;

        if (isLoading) {
            primaryAction = {
                label: t("Loading") + " ...",
                disabled: true
            }
        } else if (isSaving) {
            primaryAction = {
                label: t("Saving") + " ...",
                disabled: true
            }
        } else {
            primaryAction = this.getAction()
        }

        const registry = this.getRegistry()

        if (isLoading || !schema || !uiSchema || !formData || !formContext) {

            return (
                <div>
                    <p>schema: {schema && "OK"}</p>
                    <p>uiSchema: {uiSchema && "OK"}</p>
                    <p>formData: {formData && "OK"}</p>
                    <p>formContext: {formContext && "OK"}</p>
                </div>
            )

        }

        return (
            <SchemaBase
                {...registry}
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                formContext={{
                    ...formContext,
                    primaryAction: primaryAction
                }}
                onChange={this.onChange}
            />
        )

    }

}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        site: state.site,
        collection: state.collection,
        menuByUrl: state.menuByUrl,
        editor: state.editor,
        uniqueId: state.editor.uniqueId,
        dialog: state.dialog,
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        getAppLayout,
        getNavParents,
        getEditor, getEditorFormData, getEditorSchema, getEditorUiSchema, getEditorFormContext, 
        getEditorRedirect, getEditorParents, getEditorLanguages, 
        editorCurrentId, editorLocale, editorDialog, editorChange, dialogChange,
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('editor')(AppEditor));
