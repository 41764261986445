import React, { Component } from 'react';

import {
    Widget,
    LayoutWidgetHeader,
    NavSettings, 
    NavAction,
    Button
} from "../"

class LayoutWidget extends Component {

    static defaultProps = {
        id: false,
        collapsible: true,
        expanded: false,
        editable: false,
        editing: false,
        widgetType: "",
        widgetSize: "",
        formData: {}
    }

    onAdd = () => {
        if (this.props.onAdd) {
            this.props.onAdd()
        }
    }
    
    onEdit = () => {
        if (this.props.onEdit) {
            this.props.onEdit()
        }
    }

    onSave = () => {
        if (this.props.onSave) {
            this.props.onSave()
        }
    }

    renderSettings = () => {
        const { editable, expanded, settings } = this.props;
        
        if (editable || expanded && settings) {
            return (
                <NavSettings settings={settings} />
            )
        }
        
    }
    
    renderAdd = () => {
        const { addable } = this.props;
        
        if (addable) {
            return (
                <Button type="add" icon="add_circle" />
            )
        }
        
    }

    getPreview = () => {
        let { formData, imageUrl, icon, media, name, title, description, widgetType, widgetSize } = this.props;
        
        if (formData && formData.image && formData.image.media && formData.image.media.imageUrl) {
            imageUrl = formData.image.media.imageUrl
        }
        
        return {
            ...this.props,
            widgetSize: formData.widgetSize || widgetSize,
            widgetType: formData.widgetType || widgetType,
            imageUrl: imageUrl,
            title: formData.title || title,
            description: formData.description || description,
        }
        
    }
    
    render() {
        const { id, editable, editing, expanded, addable, settings, menu, style } = this.props;
        const { imageUrl, title, description, widgetType, widgetSize } = this.getPreview()
        
        if (editable && editing) {
            
            return (
                <Widget widgetType={widgetType} widgetSize={widgetSize} expanded={true} style={style}>
                    <NavAction buttonType="secondary" button={{ title: "Save", onClick: this.onSave }} />
                    { this.props.children }
                </Widget>
            )

        }
        
        if (expanded) {

            return (
                <Widget widgetType={widgetType} widgetSize={widgetSize} expanded={true} style={style}>
                    <NavSettings settings={settings} />
                    { this.props.children }
                </Widget>
            )
            
        }
        
        if (addable) {

            return (
                <Widget widgetType={widgetType} widgetSize={widgetSize} expanded={false} onClick={this.onAdd} style={style}>
                    <LayoutWidgetHeader {...this.props} />
                    { this.renderAdd() }
                </Widget>
            )
            
        }

        return (
            <Widget widgetType={widgetType} widgetSize={widgetSize} expanded={false} style={style}>
                <LayoutWidgetHeader {...this.props} />
            </Widget>
        )


    }

}

export default LayoutWidget;