import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LayoutFinder, FinderSection, AnalyticsTable, AnalyticsReport } from '@frontend-components/admin';
import AppFinder from "../AppFinder"

import analyticsTable from './example.analyticsTable.json';

class FinderAnalytics extends Component {
    
    state = {
        sortBy: "",
        sortOrder: ""
    }
    
    onSort = (by, order) => {
        
        console.log('sortBy', by);
        console.log('sortOrder', order);
        
        this.setState({
            sortBy: by,
            sortOrder: order
        })
        
    }
    
    render() {
        const { app } = this.props;
        const { parents } = app;
    
        return (
            
            <AppFinder {...this.props}>
                <AnalyticsTable {...analyticsTable} {...this.state} onSort={this.onSort} />
            </AppFinder>
        
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app,
    	nav: state.nav,
    	site: state.site,
    	collection: state.collection,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinderAnalytics);