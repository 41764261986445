import React, { Component } from 'react';
import ArrayFieldItem from './ArrayFieldItem';
import ArrayFieldSortable from './ArrayFieldSortable';

import {
    getUiOptions,
    retrieveSchema,
    toIdSchema,
} from "../utils";

class ArrayFieldNormal extends Component {
    
    renderItem = (item, index) => {
        const { id, idPrefix, formData, schema, uiSchema, errorSchema, registry, canRemove } = this.props;
        const { definitions } = registry;

        const itemSchema = retrieveSchema(schema.items, definitions, item);
        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;
        //        const itemIdPrefix = idSchema.$id + "_" + index;

        const itemId = this.props.id + "_" + index;
        const itemIdSchema = toIdSchema(
            itemSchema,
            itemId,
            definitions,
            item,
            idPrefix
        );

        const itemUiSchema = uiSchema.items;
        
        const canMoveUp = index > 0;
        const canMoveDown = index < formData.length - 1;
        
        return (
            <ArrayFieldItem 
                {...this.props}

                index={index} 
                
                itemId={itemId}
                itemIdPrefix={itemId}
                
                canRemove={canRemove}
                canMoveUp={canMoveUp}
                canMoveDown={canMoveDown}

                itemSchema={itemSchema}
                itemUiSchema={itemUiSchema}
                itemIdSchema={itemIdSchema}
                itemErrorSchema={itemErrorSchema}
                itemData={item}

                key={index} />
        )

    }

    render() {
        const { registry, uiSchema, canAdd, onAddItem, onChange, items } = this.props;
        
        const uiOptions = getUiOptions(uiSchema);
        
        if (uiOptions.sortable || uiOptions.draggable) {
            return (
                <ArrayFieldSortable {...this.props} />
            )
        }
        
        const { SchemaLayout } = registry.fields;

        return (
            <SchemaLayout {...this.props} onAdd={canAdd && onAddItem} onChange={onChange}>
                { items.length && items.map(this.renderItem) }
            </SchemaLayout>
        )

    }

}

export default ArrayFieldNormal;