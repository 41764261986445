import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel, loadModel, editModel, saveModel } from '../redux/functions/model';

import AppEditor from "../AppEditor"

import SidebarTemplate from "../AppDialog/Sidebar"
import PreviewTemplate from "./SchemaPreview"

import modelSchema from "./schemas/schema"
import registry from "./components/registry"

const SchemaEditor = ({app, editor, modelsById, models = "schemas", schemaType = "document", ...props}) => {
    const { collectionId, uniqueId } = props.match.params;

    const onLoad = () => {
        if (uniqueId === "<new>") {
            props.editModel({
                status: "new",
                schemaType: schemaType
            });
        } else if (models && uniqueId) {
            props.loadModel(models, uniqueId);
        }
    }

    useEffect(() => {
        onLoad()
    }, [uniqueId])

    const getReferences = () => {
        collectionId && props.getModel('collections', collectionId);
    }

    useEffect(() => {
        getReferences()
    }, [collectionId])

    const collection = collectionId && modelsById && modelsById[collectionId]

    const getParent = (parent) => {
        const parentUrl = parent && app.root + "/collection/" + parent.uniqueId + "/edit"

        setParents([{
            uniqueId: parent.uniqueId,
            title: parent.title,
            url: parentUrl
        }])

    }

    const [parents, setParents] = useState(app && app.parents)

    useEffect(() => {
        collection && getParent(collection)
        app && !collection && setParents(app && app.parents)
    }, [collection, app])
 
    const onSubmit = ({formData, redirect}) => {

        formData = {
            ...formData,
            collectionId: collection && collection.id
        }

        props.saveModel(models, formData, redirect && false)

        if (redirect) {
            props.history.replace(redirect);
        }

    }

    if (!app.uniqueId) {
        return "Loading"
    }

    const formContext = {
        parents: parents,
        languages: app && app.languages,
        preview: {
            template: PreviewTemplate
        },
        sidebar: {
            template: SidebarTemplate
        }
    }

    const { schema, uiSchema } = modelSchema
    /*


    if (!schema || !uiSchema || !formContext) {
        return <p>Loading schema</p>
    }
    */

    return (
        <AppEditor {...props}
            registry={registry}
            schema={schema}
            uiSchema={uiSchema}
            formContext={formContext}
            onSubmit={onSubmit}
        />
    )


}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        modelsById: state.modelsById
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        getModel, loadModel, editModel, saveModel
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SchemaEditor);