// initialState

let initialState = {
};

// app state

const inbox = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_SEARCH':

      return Object.assign({}, state, {
        [action.id]: {
          models: []
        }
      })

    case 'RECEIVE_SEARCH':

      return Object.assign({}, state, {
        [action.id]: action.data
      })

    case 'REQUEST_MODEL':

      return Object.assign({}, state, {
        [action.id]: {
        }
      })

    case 'RECEIVE_MODEL':

      return Object.assign({}, state, {
        [action.id]: action.data
      })

    default:

      return state

    }

}

export default inbox;