import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import InboxAttachment from "./InboxAttachment"
import ModuleLabel from "../Module/ModuleLabel"

import getInboxLabel from "./utils/getInboxLabel"

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(8),
        marginBottom: theme.spacing(1.5)
    },
    attachments: {
        marginTop: theme.spacing(1.5)
    },
    title: {
        display: "inline",
        fontFamily: "Akkurat, sans-serif",
        fontSize: '24px',
        lineHeight: '1.25',
        marginBottom: theme.spacing(1.5)
    },
    label: {
        fontSize: "12px",
        display: "inline-block",
        verticalAlign: "top",
        marginTop: '.25em',
        marginLeft: '.5em'
    }
}));

const InboxHeader = ({title = "Untitled", messages = [], attachments, onSelect, ...props}) => {
    const classes = useStyles()

    const { label, labelColor } = getInboxLabel(props)

    if (attachments) {
        return (
            <header className={classes.root}>
                <Typography component="h1" className={classes.title}>{title}</Typography>
                <ModuleLabel className={classes.label} label={label} color={labelColor} />
                { attachments && 
                    <div className={classes.attachments}>
                        { attachments.map((attachment, index) => {
                            return (
                                <InboxAttachment {...attachment} onClick={() => onSelect && onSelect(attachment)} key={index} />
                            )
                        })}
                    </div>
                }
            </header>
        )
        
    }
    
    const subject = messages && messages.length && messages[0].subject || title

    return (
        <header className={classes.root}>
            <Typography component="h1" className={classes.title}>{subject}</Typography>
            <ModuleLabel className={classes.label} label={label} color={labelColor} />
        </header>
    )
    
}

export default InboxHeader