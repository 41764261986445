import updatedAt from './queryUpdatedAt';
import status from './queryStatus';
import sort from './querySort';
import rows from './queryRows';

export default {
    "type": "object",
    "properties": {
        "createdAt": updatedAt,
        "updatedAt": updatedAt,
        "deletedAt": updatedAt,
        "q": {
            "type": "string"
        },
        "status": status,
        "sort": sort,
        "rows": rows
    }
}