import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Schema from './Schema';

const API = "https://kompass.dimu.org/admin/api"

class SchemaWithState extends Component {

    static defaultProps = {
        formData: {}
    }

    state = {
        formData: {},
        language: "no",
    }

    componentDidMount() {
        this.setState({
            formData: this.props.formData,
        })
    }

    uploadFile = (uploadUrl, file, data, onUpdate) => {
        console.log('example:uploadFile', uploadUrl);

        let payload = new FormData();
        payload.append('file', file);

        var xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", (event) => this.uploadProgress(event, data, onUpdate), false);
        xhr.addEventListener("load", (event) => this.uploadProgress(event, data, onUpdate), false);
        //  xhr.addEventListener("error", uploadFailed, false);
        //  xhr.addEventListener("abort", uploadCanceled, false);
        xhr.open("POST", uploadUrl);
        xhr.send(payload);
    }

    uploadProgress = (event, data, onUpdate) => {
        let progress, media;

        if (event.lengthComputable) {
            media = {
                mediaType: data && data.mediaType
            }
            progress = Math.round(event.loaded * 100 / event.total);
        } else {
            media = data
            progress = 100;
        }

        console.log('example:uploadProgress', progress);

        onUpdate({
            mediaId: data.uniqueId,
            media: media,
            uploadProgress: progress
        })


    }

    receiveUploadUrl = (file, data, onAdd, onUpdate) => {
        console.log('example:receiveUploadUrl', data);

        const { uniqueId, uploadUrl } = data;
        
        if (uniqueId && uploadUrl) {
            
            onAdd({
                mediaId: data.uniqueId,
                media: data
            })
            
            this.uploadFile(uploadUrl, file, data, onUpdate)
            
        }

    }

    requestUploadUrl = (file, onAdd, onUpdate) => {
        console.log('example:requestUploadUrl', file);

        const { type, name } = file;

        let data = {
            title: name,
            content: {
                title: name,
                filename: name
            }
        }

        const payload = JSON.stringify(data);

        let url = API + '/media/upload';

        if (type.startsWith('image')) {
            url = url + '/image'
        } else if (type.startsWith('video')) {
            url = url + '/video'
        } else if (type.startsWith('audio')) {
            url = url + '/audio'
        } else {
            url = url + '/misc'
        }

        fetch(url, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
            body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => this.receiveUploadUrl(file, data, onAdd, onUpdate))
    }

    onUpload = (upload) => {
        console.log('example:onUpload', upload);
        
        const { acceptedFiles, onAdd, onUpdate } = upload;

        acceptedFiles.forEach(file => {
            this.requestUploadUrl(file, onAdd, onUpdate);
        });

    }    

    onChange = (newState) => {
        console.log('example:onChange', newState);

        this.setState({
            ...newState
        })
    }

    onSubmit = ({formData, errors}) => {
        console.log('example:onSubmit', formData);

        this.setState({
            formData: formData,
            errors: errors
        })
    }
    
    renderErrors = () => {
        const { errors } = this.state;
        
        if (errors) {
            
            return (
                <ul style={{border: "1px solid", margin: "10px", padding: "10px" }}>
                { errors.map(error => { return ( <li>{JSON.stringify(error)}</li> ) }) }
                </ul>
            )
            
        }
        
    }
    
    render() {
        const { schema, uiSchema } = this.props;
        const { formData, errors } = this.state;

        return (
            <div>
            <Schema {...this.props} formData={formData} 
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                onUpload={this.onUpload} />

                    <p>errors</p>
                    { this.renderErrors(errors) }
    
                    <p>formData:</p>
                    { JSON.stringify(formData) }
    
                    <p>schema:</p>
                    { JSON.stringify(schema) }

                    <p>uiSchema:</p>
                    { JSON.stringify(uiSchema) }

            </div>
        )
    }
}

export default SchemaWithState
