export default {
  "select": {
    "imageUrl" : "imageUrl",
    "title" : "title",
    "description" : "description",
    "metadata" : "metadata",
    "label": "label"
  },
  prepare(selection) {
    let { imageUrl, title, content, description } = selection
    
    if (content) {
        
        if (content.title) {
            title = content.title
        }

        if (content.description) {
            description = content.description
        }
                
    }

    let label = 'dm/event';
    
    let metadata = []
    
    return {
      imageUrl: imageUrl,
      title: title,
      description: description,
      metadata: metadata,
      label: label
    }
    
  }
  
}