import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { CollectionRoutes } from "../AppCollection"

import PrimusEditor from "./PrimusEditor"
import PrimusReader from "./PrimusReader"
import PrimusPreview from "./PrimusPreview"

class PrimusRoutes extends Component {
  
  render() {
    
    return (
      
        <Switch>
            <Route path="/:siteName/:appName/:parentId/children/:documentType/:uniqueId/read" component={ PrimusReader } />
            <Route path="/:siteName/:appName/:parentId/children/:documentType/:uniqueId/view" component={ PrimusPreview } />
            <Route path="/:siteName/:appName/:parentId/children/:documentType/:uniqueId/edit" component={ PrimusEditor } />
            <Route path="/:siteName/:appName/:documentType/:uniqueId/read" component={ PrimusReader } />
            <Route path="/:siteName/:appName/:documentType/:uniqueId/view" component={ PrimusPreview } />
            <Route path="/:siteName/:appName/:documentType/:uniqueId/edit" component={ PrimusEditor } />
            <Route path="/:siteName/:appName/:uniqueId/read" component={ PrimusReader } />
            <Route path="/:siteName/:appName/:uniqueId/view" component={ PrimusPreview } />
            <Route path="/:siteName/:appName/:uniqueId/edit" component={ PrimusEditor } />
            <Route path="/:siteName/:appName" component={ CollectionRoutes } />
        </Switch>

    )

  }

}

export default PrimusRoutes;