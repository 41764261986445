import React, { Component } from 'react';
import "./WidgetHeader.scss"

class WidgetHeader extends Component {
    static defaultProps = {
        style: {}
    }
    
    render() {
        const { style, expanded } = this.props;

        return (
            <header className="admin-widget__header" style={style} aria-expanded={expanded}>
                {this.props.children}
            </header>
        )

    }

}

export default WidgetHeader;