const group = {
    "properties": {
        "type": {
            "enum": ["group"]
        },
    }
}

export {
    group
}

export default group