import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDefaultFormState } from '@frontend-components/admin';
import { getSelected } from '../redux/functions/dialog';

import ResultsList from "./ResultsList"
import ResultsGallery from "./ResultsGallery"

const layouts = {
    "list": ResultsList,
    "gallery": ResultsGallery
}

const SidebarResults = ({query = {}, dialog, models = [], layout = "list", ...props}) => {
    let { schema, formData } = dialog;

    let itemSchema;

    if (schema.type === "array") {
        itemSchema = schema.items
    } else {
        itemSchema = schema
    }

    const [selected, setSelected] = useState([])

    const getSelected = (formData) => {
        let items = []

        if (schema.type === "array") {
            items = formData
        } else {
            items = [formData]
        }
    
        const selected = items.map(item => {
            if (query.models === "media") {
                return item && item.mediaId
            } else if (query.models === "documents") {
                return item && item.referenceId || item && item.linkId
            }
            return item && item.uniqueId
        })

        setSelected(selected)
    }

    useEffect(() => {
        getSelected(formData)
    }, [formData])

    const getItem = ({uniqueId, model}) => {

        if (query.models === "media" && itemSchema.properties.mediaId) {
            return getDefaultFormState(itemSchema, {
                mediaId: uniqueId,
                media: model
            })
        } else if (query.models === "documents" && itemSchema.properties.referenceId) {
            return getDefaultFormState(itemSchema, {
                referenceId: uniqueId,
                reference: model
            })
        } else if (itemSchema.properties.linkId) {
            return getDefaultFormState(itemSchema, {
                linkId: uniqueId,
                link: model
            })
        }

        return getDefaultFormState(itemSchema, model)

    }

    const removeItem = ({uniqueId}) => {

        if (query.models === "media" && itemSchema.properties.mediaId) {
            return formData.filter(item => item.mediaId !== uniqueId)
        } else if (query.models === "documents" && itemSchema.properties.referenceId) {
            return formData.filter(item => item.referenceId !== uniqueId)
        } else if (itemSchema.properties.linkId) {
            return formData.filter(item => item.linkId !== uniqueId)
        } else {
            return formData.filter(item => item.uniqueId !== uniqueId)
        }

    }

    const handleChange = (formData) => {
        const newFormData = getDefaultFormState(schema, formData)
        dialog.onChange(newFormData);
        getSelected(newFormData);
    }

    const handleAdd = (model) => {
        const uniqueId = model && model.uniqueId
        const item = getItem({uniqueId, model})

        let newFormData;

        if (schema.type === "array") {
            newFormData = [].concat(formData, item);
        } else {
            newFormData = item
        }

        handleChange(newFormData);
     
    }
    
    const handleRemove = (model) => {
        const uniqueId = model && model.uniqueId

        let newFormData;

        if (schema.type === "array") {
            newFormData = removeItem({uniqueId})
        } else {
            newFormData = getItem({uniqueId: undefined, model: {}})
        }

        handleChange(newFormData);

    }
    
    const ResultsTemplate = layout && layouts[layout]

    if (!ResultsTemplate) {
        return (
            <p>No template for {layout}</p>
        )
    }

    return (
        <ResultsTemplate {...props} query={query} models={models} modelsSelected={selected} onAdd={handleAdd} onRemove={handleRemove} />
    )

}

const mapStateToProps = (state) => {
	return {
        dialog: state.dialog,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getSelected,
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarResults);