import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: "bold",
    },
    description: {
        marginLeft: theme.spacing(1),
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        lineHeight: "24px",
    }
}));

const LayoutHeader = ({className, padding = 1, title, description}) => {
    const classes = useStyles({padding});

    if (!title) {
        return false
    }

    return (
        <header className={className || classes.root}>
            <Typography noWrap component="h2" className={classes.title}>
            { title }
            </Typography>
            <Typography noWrap component="h3" className={classes.description}>
                {description}
            </Typography>
        </header>
    )

}

export default LayoutHeader;