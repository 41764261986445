export const getCollectionSearch = ({search, ...props}) => {

    if (!search.enabled) {
        search.disabled = true
    }

    return {
        disabled: !search.enabled,
        placeholder: search.placeholder || "Søk"
    }
    
}

export default getCollectionSearch