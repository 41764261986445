import React, { Component } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from "prop-types"
import Paper from '@material-ui/core/Paper'
import Color from 'color';

import { getWidgetGrid } from "./utils"

import theme from "./Widget.theme"
import clsx from 'clsx';

const getContrast = (color) => {
    let contrast;

    color = Color(color);

    if (color.isDark()) {
        contrast = 'white';
    } else {
        contrast = 'black';
    }

    return contrast;
}

const useStylesBase = makeStyles(theme => ({
    root: {
        position: "relative",
        justifySelf: "stretch",
        alignSelf: "stretch"
    },
    leadingIcons: {
        display: "flex"
    },
    trailingIcons: {
        display: "flex"
    }
}));

const WidgetBase = ({
        id, 
        name,
        size,
        classes, 
        className, 
        status, 
        selectable = false,
        selected = false,
        collapsible = false,
        expanded = false, 
        toolbar,
        settings,
        elevation = 1, 
        onClick, 
        style = {
            backgroundColor: "white"
        },
        children,
        ...props
    }) => {

    const classesBase = useStylesBase(style);

    if (classes) {
        classes.root = clsx(classes.root, classesBase.root)
    } else {
        classes = classesBase
    }

    const { cols, rows } = getWidgetGrid(props.widgetSize || size)

    if (!elevation) {
        return (
            <ThemeProvider theme={theme}>
                <article
                    className={classes.root}
                    data-id={id}
                    data-name={name}
                    data-size={size}
                    data-cols={cols}
                    data-rows={rows}
                    data-status={status}
                    aria-expanded={expanded}
                    aria-selected={selected}
                    onClick={onClick}>
                        {children}
                </article>
            </ThemeProvider>
        )
    }


    return (
        <ThemeProvider theme={theme}>
            <Paper component="article" square={true}
                className={classes.root}
                data-id={id}
                data-name={name}
                data-size={size}
                data-cols={cols}
                data-rows={rows}
                data-status={status}
                aria-expanded={expanded}
                aria-selected={selected}
                onClick={onClick}>
                    {children}
            </Paper>
        </ThemeProvider>
    )

}

WidgetBase.propTypes = {
    id: PropTypes.string,
    elevation: PropTypes.number,
    draggable: PropTypes.bool,
    draggableHandle: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    collapsible: PropTypes.bool,
    expanded: PropTypes.bool,
    onToggle: PropTypes.func,
    deletable: PropTypes.bool,
    restorable: PropTypes.bool,
    onDelete: PropTypes.func,
    onRestore: PropTypes.func,
    onErase: PropTypes.func,
    draggable: PropTypes.bool,
    hideable: PropTypes.bool,
    hidden: PropTypes.bool
}

WidgetBase.defaultProps = {
    id: "widget",
    name: undefined,
    expanded: false,
    selected: false,
    size: "1:1",
    style: {}
}

export default WidgetBase;