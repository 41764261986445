import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        overflow: "hidden",
        marginTop: theme.spacing(8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginTop: 0            
        }
    },
}));

const AppBody = ({className, expanded = false, children}) => {

    const classes = useStyles()

    return (
        <div className={className || classes.root} aria-expanded={expanded}>
            {children}
        </div>
    )
}

export default AppBody;