import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { WidgetBase, WidgetScroller, WidgetButton } from "../"
import { InboxResults } from "../"

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        position: "relative",
    },
    header: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: "auto",
        left: 0,
        height: theme.spacing(15),
        borderBottom: "1px solid #ccc",
        borderColor: "#ccc"
    },
    footer: {
        position: "absolute",
    	zIndex: 3,
    	top: 0,
    	right: 0,
    	bottom: "auto",
    	left: "auto",
    	margin: theme.spacing(2)
    },
    body: {
        position: "absolute",
    	zIndex: 1,
        top: theme.spacing(15),
        right: 0,
        bottom: 0,
        left: 0
    },
    title: {
        fontSize: "20px",
        fontWeight: "normal",
        lineHeight: "30px",
        margin: "20px"
    },
    description: {
        fontSize: "14px",
        fontWeight: "bold",
        lineJeight: "24px",
        
        position: "absolute",
        top: "auto",
        right: 0,
        bottom: 0,
        left: 0,
        
        margin: "20px",
        marginBottom: "12px"
    }
}));

const InboxWidget = ({title, description, button, url, data, onSelect, ...props}) => {
    const classes = useStyles()

    const results = data.results || data.models || []
    const resultsCount = data.count || results.length || undefined;
    const resultsRows = data.rows || results.length;

    description = description.replace("{rows}", resultsRows)
    description = description.replace("{count}", resultsCount)

    return (
        <WidgetBase {...props} size={props.size || props.widgetSize}>
            <header className={classes.header}>
                <Typography component="h2" className={classes.title}>{title}</Typography>
                <Typography component="h3" className={classes.description}>{description}</Typography>
            </header>
            <WidgetScroller className={classes.body}>
                <InboxResults results={results} onSelect={onSelect} />
            </WidgetScroller>
            <footer className={classes.footer}>
                <WidgetButton {...button} onClick={() => onSelect(button)} />
            </footer>
        </WidgetBase>
    )

}

InboxWidget.defaultProps = {
    size: "12:6",
    title: "Inbox",
    description: "{rows} of {count} messages",
    button: {
        "url": "/inbox",
        "label": "Go to Inbox"
    },
    urlPattern: "/inbox/:uniqueId",
    data: {
        results: []
    }
}

export default InboxWidget;