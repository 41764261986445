import React, { Component } from 'react';

import Dropdown from "../Dropdown/Dropdown"
import ButtongroupFilters from "./ButtongroupFilters"
import ButtonFilter from "./ButtonFilter"

import './NavFilter.scss';

class NavFilter extends Component {
    static defaultProps = {
        className: "nav-filter",
        buttonType: "filter",
        align: "left",
        valign: "top",
        filters: []
    }
    
    state = {
        filters: [],
        expanded: false,
        selected: undefined
    }
    
    componentDidMount() {
        this.getFilters()
    }

    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.filters !== this.props.filters) {
            this.getFilters()
        }
    }
    
    getClassName = () => {
        const { className, align } = this.props;
        
        let classNames = []
        
        classNames.push(className);
        classNames.push(className + '--' + align);
        
        return classNames.join(' ')
        
    }
    
    getFilters = () => {
        const { filters } = this.props;
        let selected = filters.find(filter => filter.selected === true);

        if (!selected) {
            selected = filters[0]
        }
        
        this.setState({
            filters: filters,
            selected: selected
        })
        
    }

    onSelect = (filter, event) => {
        
        if (this.props.onSelect) {
            this.props.onSelect(filter, event)
        }

        this.setState({
            selected: filter,
            expanded: false
        })
        
    }
    
    onToggle = () => {
        
        this.setState({
            expanded: !this.state.expanded
        })
        
    }

    renderFilter = (filter, index) => {
        const { selected } = this.state;
        const { title } = filter;
        
        if (filter === selected) {
            return (
                <ButtonFilter key={index} {...filter} icon="check" selected={true} />
            )
        }

        return (
            <ButtonFilter key={index} {...filter} selected={false} onClick={(event) => this.onSelect(filter, event)} />
        )

    }
    
    renderDropdown = () => {
        const { align, valign } = this.props;
        const { filters, expanded } = this.state;

        if (expanded) {
            return (
                <Dropdown align={align} valign={valign}>
                    <ButtongroupFilters>
                        {filters.map(this.renderFilter)}
                    </ButtongroupFilters>
                </Dropdown>
            )
        }
        
    }

    render() {
        const { expanded, selected } = this.state;
        const { buttonType } = this.props;
        
        const className = this.getClassName()
        
        return (
            <nav className={className}>
                <ButtonFilter {...selected} url={undefined} selected={false} haspopup={true} expanded={expanded} buttonType={buttonType} onClick={this.onToggle} />
                { this.renderDropdown() }
            </nav>
        )
    }
}

export default NavFilter;