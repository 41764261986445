import React, { Component } from 'react';
import "./WidgetGrid.scss"

class WidgetGrid extends Component {
    
    render() {
        return (
            <div className="admin-widget__grid">
                {this.props.children}
            </div>
        )
    }
}

export default WidgetGrid;