import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearch } from '../redux/functions/search';
import { getQueryParams } from '../redux/functions/query';
import { NavSearch } from '../components';
import { makeStyles } from '@material-ui/core/styles';
import SidebarResults from "./SidebarResults"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        backgroundColor: "#eee",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    search: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: "auto",
        left: 0,
        display: "flex",
        backgroundColor: "inherit",
        color: "black",
        width: "100%",
        height: theme.spacing(8),
        overflow: "hidden",
    },
    body: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        paddingTop: theme.spacing(8),
    }
}));


const SidebarSearch = ({dialog = {}, query = {}, search = {}, theme, searchById, layout, ...props}) => {

    const classes = useStyles()

    const [q, setQ] = useState("")

    const handleChange = (q) => {
        setQ(q)
    }

    const handleReset = () => {
        if (q) {
            setQ("")
        } else if (dialog.onClose) {
            dialog.onClose()
        }
    }

    search = {
        ...search,
        q: q,
        onReset: () => handleReset(),
        onChange: (q) => handleChange(q)
    }

    const [page, setPage] = useState(1)

    const handlePage = (page) => {
        setPage(page)
    }


    // query

    let id = "/models:" + query.models + "/siteId:" + query.siteId + "/collectionId:" + query.collectionId + "/q:" + q

    let sort = query.sort || "title ASC"
    let start = query.start || 0
    let rows = query.rows || 20
    let status = query.status || "NOT trash"

    let fl

    if (query.fl) {
        fl = query.fl
    } else {
        fl = "uniqueId,modelName,documentType,mediaType,title,description,status,mediaWidth,mediaHeight"
    }

    query = {
        ...query,
        id: id,
        start: start,
        rows: rows,
        sort: sort,
        status: status,
        fl: fl,
        q: q && q + "*" || "",
    }

    const getSearch = () => {
        props.getSearch(query);
    }

    useEffect(() => {
        getSearch()
    }, [id, q])

    const getPage = () => {
        props.getSearch({
            ...query,
            page: page,
            start: rows * (page-1)
        });
    }

    useEffect(() => {
        getPage()
    }, [page])

    const results = searchById && searchById[id];
    const resultsByPage = results && resultsByPage || []

    // pages
    
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)

    const getPages = () => {
        const resultsPages = Math.ceil(results.count/results.rows)
        const resultsPage = Math.ceil((results.start+results.rows)/results.rows)

        setPages(resultsPages)
        setCurrentPage(resultsPage)
    }

    useEffect(() => {
        results && results.count && getPages()
    }, [results, resultsByPage])

    // remove nothing if not expanded

    if (!dialog.expanded) {
        return false
    }

    // renderResults


    return (
        <section className={classes.root}>
            <NavSearch {...search} className={classes.search} />
            <div className={classes.body}>
                <SidebarResults {...results} layout={layout} query={query} pages={pages} page={currentPage} onPage={handlePage} />
            </div>
        </section>
    )

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getSearch,
  }, 
dispatch);

const mapStateToProps = (state) => {
	return {
        app: state.app,
        dialog: state.dialog,
        searchById: state.searchById
	};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SidebarSearch);