import "./ChartLegend.scss"

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Legend extends Component {
    
    render() {
        
        return (
            <article className="admin-legend">
                { this.props.children }
            </article>
        )
    }

}

export default Legend;