import React from 'react'
import { getUiOptions } from "@frontend-components/admin"

const PageEditorField = (props) => {
    const { id, schema, uiSchema, formData, formContext, registry } = props;
    const { uniqueId, status, statusCode, statusMessage, title, createdAt, updatedAt, deletedAt } = formData;
    const { untitled, parents, languages, currentLocale } = formContext;

    /*
    const collapsible = uiSchema.content && uiSchema.settings;
    const expanded = currentId && currentId.startsWith('root_settings') || false

    const handleToggle = () => {
        currentId && currentId.startsWith('root_settings') && onExpand && onExpand({'id':'root'}) || onExpand && onExpand({'id':'root_settings'})
    }
    */

    // back

    const { onBack } = props.formContext

    const handleBack = (props) => {
        onBack && onBack(props)
    }

    // select

    const { onSelect } = props.formContext

    const handleSelect = (props) => {
        onSelect && onSelect(props)
    }

    // locale

    const { onLocale } = props.formContext

    const handleLocale = (props) => {
        onLocale && onLocale(props)
    }

    // toggle 

    const uiOptions = getUiOptions(uiSchema)

    const fieldset = uiOptions.fieldset;

    const primaryFieldset = fieldset && fieldset[0]
    const secondaryFieldset = fieldset && fieldset[1]

    const primaryId = primaryFieldset && id + "_" + primaryFieldset;
    const secondaryId = secondaryFieldset && id + "_" + secondaryFieldset;

    const { currentId, onExpand, onToggle } = props.formContext;
    const secondaryExpanded = secondaryId && currentId && currentId.startsWith(secondaryId)

    const handleToggle = ({props}) => {

        console.log('EDITOR toggle', id)

        if (secondaryExpanded) {
            onExpand({id: id, ...props})
        } else if (secondaryId) {
            onExpand({id: secondaryId, ...props})
        }

    }

    // primary + secondary actions

    const primaryAction = formContext.primaryAction
    
    const newUiSchema = {
        ...uiSchema,
        "ui:id": "editor",
        "ui:primaryId": primaryId,
        "ui:secondaryId": secondaryId,
        "ui:onToggle": handleToggle,
        "ui:editable": true,
        "ui:editing": true,
        "ui:untitled": untitled,
        "ui:title": secondaryId || title,
        "ui:parents": parents,
        "ui:primaryAction": primaryAction,
        "ui:status": formContext.status || status,
        "ui:statusLabel": formContext.statusLabel,
        "ui:statusDate": formContext.statusDate || updatedAt || createdAt || deletedAt,
        "ui:statusCode": formContext.statusCode || statusCode,
        "ui:statusMessage": formContext.statusMessage || statusMessage,
        "ui:languages": languages,
        "ui:currentLocale": currentLocale,
        "ui:onLocale": handleLocale,
        "ui:onSelect": handleSelect,
        "ui:onBack": handleBack,
        "ui:layout": "pageEditor"
    }

    const { ObjectField } = registry.fields;

    return (
        <ObjectField {...props} uiSchema={newUiSchema} />
    )

}

export default PageEditorField;