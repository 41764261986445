export const REQUEST_PARENTS = 'REQUEST_PARENTS'
export const RECEIVE_PARENTS = 'RECEIVE_PARENTS'

export function requestParents() {
    return {
        type: REQUEST_PARENTS,
    }
}

export function receiveParents(parents) {
    return {
        type: RECEIVE_PARENTS,
        parents: parents,
    }
}

export const REQUEST_MENU_BY_URL = 'REQUEST_MENU_BY_URL'
export const RECEIVE_MENU_BY_URL = 'RECEIVE_MENU_BY_URL'

export function requestMenuByUrl() {

    return {
        type: REQUEST_MENU_BY_URL,
    }
    
}

export function receiveMenuByUrl(data) {

    return {
        type: RECEIVE_MENU_BY_URL,
        data: data
    }
    
}

export const REQUEST_MENU_ITEM = 'REQUEST_MENU_ITEM'
export const RECEIVE_MENU_ITEM = 'RECEIVE_MENU_ITEM'
export const RECEIVE_MENU_ITEM_COUNT = 'RECEIVE_MENU_ITEM_COUNT'
export const RECEIVE_MENU_ITEM_CHILDREN = 'RECEIVE_MENU_ITEM_CHILDREN'

export function requestMenuIteml(item) {
    return {
        type: REQUEST_MENU_ITEM,
        url: item.url,
    }
}

export function receiveMenuItem(item) {
    return {
        type: RECEIVE_MENU_ITEM,
        url: item.url,
        item: item,
    }
}

export function receiveMenuItemCount({url, count}) {
    return {
        type: RECEIVE_MENU_ITEM_COUNT,
        url: url,
        count: count,
    }
}

export function receiveMenuItemChildren({url, children}) {
    return {
        type: RECEIVE_MENU_ITEM_CHILDREN,
        url: url,
        children: children,
    }
}

