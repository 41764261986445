import React, { Component } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import theme from "./theme"
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        margin: theme.spacing(-0.5),
        "& > *": {
            margin: theme.spacing(0.5),
        }
    },
    buttongroup: {
    },
    button: {
    },
    dropdown: {
    }
}));

const EditComboButton = ({children, ...props}) => {
    const { label } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClick = ({onClick}) => {
        setOpen(false);

        onClick && onClick()

    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const classes = useStyles()

    return (
        <React.Fragment>
            <ButtonGroup variant="outlined" ref={anchorRef} aria-label="edit">
                <Button onClick={() => handleClick(props)}>{label}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex: 2000}}>
                <Paper square={true}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                            {children.map((option, index) => {
                                const { label, disabled, selected } = option
                                return (
                                    <MenuItem key={index}
                                        disabled={disabled}
                                        selected={selected}
                                        onClick={() => handleClick(option)}>
                                        {label}
                                    </MenuItem>
                                )
                            })}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </React.Fragment>
    )

}

const EditButton = ({label, onClick, children}) => {
    const classes = useStyles()

    return (
        <Button variant="outlined" onClick={onClick}>
            {label}
        </Button>
    )

}

const NavEdit = ({className, buttons = []}) => {
    const classes = useStyles()

    return (
        <ThemeProvider theme={theme}>
            <ButtonGroup className={className || classes.root}>
                { buttons.map((button, index) => {

                    if (button.children) {
                        return (
                            <EditComboButton {...button} key={index} />
                        )
                    }

                    return (
                        <EditButton {...button} key={index} />
                    )
                })}
            </ButtonGroup>
        </ThemeProvider>
    )

}

export default NavEdit