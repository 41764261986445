import React from 'react';
import { WidgetSearch, WidgetScroller } from "../"

import AppsList from "./AppsList"
import AppsGrid from "./AppsGrid"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        width: "100%",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    search: {
        display: "flex",
        position: "relative",
        backgroundColor: "transparent",
        color: "black",
        height: "36px",
        overflow: "hidden",
        fontFamily: "Akkurat, sans-serif",
        margin: "12px"
    },
    scroller: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    results: {
        marginTop: theme.spacing(7),
        display: "flex",
        width: "100%",
        flexWrap: "wrap"
    },
    nohits: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        padding: theme.spacing(1.5)
    }
}));

const FilterAppsUncontrolled = ({className, search, q, apps = [], layout = "list", onSearch, onSelect}) => {

    const classes = useStyles()

    if (!apps.length) {
        return (
            <div className={className || classes.root}>
                <WidgetSearch {...search} value={q} onChange={onSearch} />
                <WidgetScroller className={classes.scroller}>
                    <div className={classes.results}>
                        <p className={classes.nohits}>Ingen treff</p>
                    </div>
                </WidgetScroller>
            </div>
        )
    }

    const placeholder = search.placeholder.replace('{hits}', apps.length)

    return (
        <div className={className || classes.root}>
            <WidgetSearch {...search} placeholder={placeholder} value={q} onChange={onSearch} />
            <WidgetScroller className={classes.scroller}>
                { layout === "grid" && <AppsGrid className={classes.results} apps={apps} onSelect={onSelect} /> }
                { layout === "list" && <AppsList className={classes.results} apps={apps} onSelect={onSelect} /> }
            </WidgetScroller>
        </div>
    )

}

FilterAppsUncontrolled.defaultProps = {
    search: {
        placeholder: "Søk i {hits} applikasjoner"
    }
}

export default FilterAppsUncontrolled;