import { getLocalizedStringSchema, getLocalizedStringFormData } from "./"

export function getLocalizedStringField(props) {
    
    const schema = getLocalizedStringSchema(props)
    const formData = getLocalizedStringFormData({...props, schema: schema})
    
    return {
        ...props,
        formData: formData,
        schema: schema,
        uiSchema: {
            ...props.uiSchema,
            "ui:field": "localizedString"
        }
    }
    
}