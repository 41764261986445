import React, { Component } from 'react';
import PreviewLabel from "../PreviewLabel"

import "./PreviewReference.scss"

class PreviewReference extends React.Component {

  render() {
      const { id, formData } = this.props;
      const { reference, referenceType } = formData;
      const { documentType, title, description, uniqueId } = reference;

    return (

      <article className="admin-preview__field  field--reference">
        <PreviewLabel id={ id || "reference"} type={referenceType} />
        <h2 className="reference__title">{ title }</h2>
        <p className="reference__id">{ uniqueId }</p>
      </article>
    
    )
  
  }
  
}

export default PreviewReference;