import React, { Component } from 'react';

import Document from "./Document"
import DocumentSidebar from './DocumentSidebar';
import DocumentContent from './DocumentContent';

import "./Document.scss";

import NavMenu from "../NavMenu/NavMenu"

class LayoutDocument extends Component {
    static defaultProps = {
        menu: []
    }

    renderSidebar = () => {
        const { menu } = this.props;

        return (
            <DocumentSidebar>
                <NavMenu menu={menu} />
            </DocumentSidebar>
        )

    }

    render() {

        return (
            <Document>
                { this.renderSidebar() }
                <DocumentContent>
                { this.props.children }
                </DocumentContent>
            </Document>
        )
    
    }

}

export default LayoutDocument;