export default {
    "messages": {
        "items": {
            "ui:layout": "module",
            "ui:title": {
                "select": "subject"
            },
            "ui:description": {
                "select": "message"
            },
            "ui:fieldset": [
                "subject",
                "message"
            ],
            "message": {
                "ui:widget": "textarea"
            }
        }
    }
}