import { getFieldType, retrieveSchema, mergeObjects, toIdSchema, getDefaultRegistry, getDefaultFormState, getUiOptions } from './';

export function getFieldSchema(props) {
    const { schema } = getFieldType(props);
    return schema || {};
}

export function getFieldUiSchema(props) {
    const { uiSchema } = getFieldType(props);
    return uiSchema || {};
}

export function getField(props) {
    props = getFieldType(props);
    
    // registry
    const registry = props && props.registry || getDefaultRegistry()
    const { definitions, fields, formContext } = registry;

    // get props
    let { formData, schema, uiSchema, errorSchema, idSchema, idPrefix, name } = props;
    
    // defs
    if (schema.definitions) {
        Object.keys(schema.definitions).forEach(function (key) {
            schema.definitions[key] = getFieldSchema({...props, schema: schema.definitions[key]});
        });
    }

    // properties
    if (schema.properties) {
        Object.keys(schema.properties).forEach(function (key) {
            schema.properties[key] = getFieldSchema({...props, schema: schema.properties[key]})
        });
    }

    // items
    if (schema.items) {
        schema.items = getFieldSchema({...props, schema: schema.items});
    }

    // additional items
    if (schema.additionalItems) {
        schema.additionalItems = getFieldSchema({...props, schema: schema.additionalItems});
    }
    
    // retrieve schema
    schema = retrieveSchema(schema, definitions, formData);
    
    // get idSchema
    idSchema = mergeObjects(
        toIdSchema(schema, null, definitions, formData, idPrefix),
        idSchema
    );

    // set id
    let id;

    if (idPrefix && name) {
        id = idPrefix + '_' + name
    } else {
        id = idSchema.$id;
    }
    
    // disabled, readonly, autofocus, hidden

    const disabled = Boolean(props.disabled || schema.disabled || uiSchema["ui:disabled"]);
    const readonly = Boolean(props.readonly || schema.readonly || uiSchema["ui:readonly"]);
    const autofocus = Boolean(props.autofocus || uiSchema["ui:autofocus"]);
    const hidden = Boolean(props.hidden || uiSchema["ui:hidden"]);
    
    // hidden

    if (hidden) {
        uiSchema["ui:widget"] = "hidden"
    }

    const { __errors, ...fieldErrorSchema } = errorSchema;

    // get uiOptions
    
    let uiOptions = getUiOptions(uiSchema);

    // set language
    
    return {
        ...props,
        id: id,
        idSchema: idSchema,
        schema: schema,
        uiSchema: uiSchema,
        uiOptions: uiOptions,
        formData: formData,
        formContext: formContext,
        registry: registry,
        disabled: disabled,
        readonly: readonly,
        hidden: hidden,
        autofocus: autofocus,
        errorSchema: errorSchema,
        rawErrors: __errors,
    };

}