import mediaSize from "./mediaSize"

export default {
    "type": "array",
    "items": {
        "type": "media",
        "properties": {
            "format": {
                "type": "string",
                "default": "1:1"
            },
            "layout": {
                "type": "string",
                "default": "frame"
            },
            "size": mediaSize,
            "content": {
                "type": "object",
                "properties": {
                    "headline": {
                        "type": "string"
                    },
                    "description": {
                        "type": "string"
                    },
                    "creditLine": {
                        "type": "string"
                    }
                }
            }
        }
    }
}