export default {
  type: "array",
  items: {
      type: "image",
      content: {
            type: "object",              
            properties: {
                headline: {
                    type: "string"
                },
                description: {
                    type: "string"
                }
          }
      }
  }
}