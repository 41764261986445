import group from "./group/uiSchema"
import program from "./program/uiSchema"
import order from "./order/uiSchema"
import payment from "./payment/uiSchema"
import uiPreview from "./uiPreview"

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "ui:fieldset": [
        "content"
    ],
    "content": {
        "ui:field": "pageContent",
        "ui:fieldset": [
            "booking","program","order","payment"
        ],
        "booking": {
            "ui:layout": "section",
            "ui:title": "Gruppe",
            "ui:fieldset": [
                "title",
                "type",
                "visitors",
                "language",
                "options"
            ],
            "ui:options": {
                "collapsible": true,
                "expanded": true,
                "grid": true,
                "spacing": 2
            },
            "title": {
                "ui:title": "Tittel"
            },
            "type": {
                "ui:title": "Type gruppe",
            },

            "visitors": {
                "ui:fieldset": [
                    "adults",
                    "children",
                    "free"
                ],
                "ui:options": {
                    "grid": true,
                    "spacing": 2
                },
                "adults": {
                    "ui:title": "Antall voksne",
                    "ui:gridCols": 2
                },
                "children": {
                    "ui:title": "Antall barn",
                    "ui:gridCols": 2
                },
                "free": {
                    "ui:title": "Gratisbesøkende",
                    "ui:gridCols": 2
                },
            },

            "language": {
                "ui:title": "Språk",
            },
            "options": {
                "ui:title": "Tilleggsopplysninger",
                "ui:label": false,
                "ui:widget": "checkboxes"
            }
        },
        "program": program,
        "order": order,
        "payment": payment
    }
}