
export default {
    "ui:field": "bulkEditor",
    "ui:fieldset": [
        "status",
        "content"
    ],
    "status": {
        "ui:field": "bulkString",
        "ui:title": "Status",
        "ui:placeholder": "Status",
        "ui:help": "Sett status.",
    },
    "content": {
        "ui:fieldset": [
            "license",
            "copyright",
            "copyrightYear",
        ],
        "license": {
            "ui:widget": "selectLicense",
            "ui:title": "Lisens",
            "ui:help": "Velg en lisens for dette innholdet.",
        },
        "copyright": {
            "ui:title": "Copyright",
            "ui:help": "Beskrivelse av opphavsrett"
        },
        "copyrightYear": {
            "ui:title": "Copyright year",
            "ui:help": "Beskrivelse av opphavsrett"
        }
    }
}