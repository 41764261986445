import React, { Component } from 'react';

import './AdminBody.scss';

class AdminBody extends Component {
    static defaultProps = {
        expanded: true
    };

    render() {
        const { expanded } = this.props;

        return (
            <div className="admin__body" aria-expanded={expanded}>
                {this.props.children}
            </div>
        )
    }
}

export default AdminBody;