import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from "@material-ui/core/ButtonBase"

const useStyles = makeStyles(theme => ({
    root: {
    }
}));

const ModuleLink = ({className, onClick, children, ...props}) => {
    const classes = useStyles();

    if (!onClick) {
        return children
    }

    return (
        <ButtonBase className={className || classes.root} {...props} onClick={onClick}>
            {children}
        </ButtonBase>
    )

}

export default ModuleLink;