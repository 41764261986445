import mediaLayout from "./mediaLayout"
import mediaTypes from "./mediaTypes"
import mediaQuery from "./mediaQuery"

const query = {
    ...mediaQuery,
    "properties": {
        ...mediaQuery.properties,
        "mediaType": mediaTypes
    }
}

export default {
    "properties": {
        "type": {
            "enum": ["media"]
        },
        "query": query,
        "layout": mediaLayout
    }
}