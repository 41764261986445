import category from './imageCategory';

export default {
  "type": "object",
  "properties": {

    "title": {
      "type": "localizedString"
    },
    "headline": {
      "type": "localizedString"
    },
    "description": {
      "type": "localizedString"
    },
    "descriptionWriter": {
      "type": "string"
    },
    "license": {
        "type": "string"
    },
    "keywords": {
      "type": "string"
    },

    "category": category,
    
    "filename": {
      "type": "string",
      "readonly": true
    },

    "locationShown": {
      "type": "string"
    },
    "locationCreated": {
      "type": "string"
    },

    "creditLine": {
      "type": "localizedString"
    },
    "creator": {
      "type": "string"
    },
    "creatorJobtitle": {
      "type": "string"
    },
    "creatorContactInfo": {
      "type": "string"
    },
    "copyrightNotice": {
      "type": "string"
    },
    "copyrightOwner": {
      "type": "string"
    },

    "license": {
      "type": "string"
    },
    "instructions": {
      "type": "string"
    }

  }
}