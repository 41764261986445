import React, { Component } from 'react';
import "./AdminHeader.scss";

class AdminHeader extends Component {
    
    static defaultProps = {
        expanded: true
    }
    
    render() {

        const { expanded } = this.props

        return (
            <header className="admin__header" aria-expanded={expanded}>
                {this.props.children}
            </header>
        )
    }
}

export default AdminHeader;