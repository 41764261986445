import {defineMessages} from "react-intl";
import {type Messages} from "./MessageDescriptor"

const messages: Messages = {

  ["loadingTitle"]: {
    id: "ga.report.sessions.byCountry",
    defaultMessage: "Besøk etter land",
  },
  ["title"]: {
    id: "ga.report.sessions.byCountry.title",
    defaultMessage: "Besøk etter land",
  }

};

const definedMessages: typeof messages = defineMessages(messages);

export default definedMessages;