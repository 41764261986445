import React, { Component } from "react";
import PropTypes from "prop-types";
import * as types from "../utils/types";

import {
    getField,
    getFieldset,
    isSelect,
    deepEquals,
    getSchemaType,
} from "../utils";

const COMPONENT_TYPES = {
    array: "ArrayField",
    boolean: "BooleanField",
    integer: "NumberField",
    number: "NumberField",
    object: "ObjectField",
    string: "StringField"
};

class SchemaField extends Component {

    static defaultProps = {
        uiSchema: {},
        idSchema: {},
        errorSchema: {},
        disabled: false,
        readonly: false,
        autofocus: false
    };
    
    static propTypes = {
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object,
        idSchema: PropTypes.object,
        formData: PropTypes.any,
        errorSchema: PropTypes.object,
        registry: types.registry.isRequired,
    };
    
    shouldComponentUpdate(nextProps) {
        // if schemas are equal idSchemas will be equal as well,
        // so it is not necessary to compare
        return !deepEquals(
            { ...this.props, idSchema: undefined },
            { ...nextProps, idSchema: undefined }
        );
    }
    
    getFieldComponent = (props) => {
        const { schema, uiSchema, uiOptions, idSchema, registry } = props;
        const { fields } = registry;

        const field = uiOptions["field"];
    
        if (typeof field === "function") {
            return field;
        }
    
        if (typeof field === "string" && field in fields) {
            return fields[field];
        }
    
        const componentName = COMPONENT_TYPES[getSchemaType(schema)];
    
        // If the type is not defined and the schema uses 'anyOf' or 'oneOf', don't
    
        if (!componentName && (schema.anyOf || schema.oneOf)) {
            return () => null;
        }

        const { UnsupportedField } = registry.fields;

        return componentName in fields
            ? fields[componentName]
            : () => {
                return (
                    <UnsupportedField
                        schema={schema}
                        idSchema={idSchema}
                        reason={`Unknown field type ${schema.type}`}
                    />
                );
            };
            
    }

    renderComponent = (props) => {
        const { schema, uiSchema, registry } = props;
        
        const FieldComponent = this.getFieldComponent(props)
    
        const _AnyOfField = registry.fields.AnyOfField;
        const _OneOfField = registry.fields.OneOfField;
    
        return (
            <React.Fragment>
                <FieldComponent {...props} />
    
                {/*
                    If the schema `anyOf` or 'oneOf' can be rendered as a select control, don't
                    render the selection and let `StringField` component handle
                    rendering
                */}
    
                {schema.anyOf && !isSelect(schema) && (
                    <_AnyOfField
                        disabled={props.disabled}
                        errorSchema={props.errorSchema}
                        formData={props.formData}
                        idPrefix={props.idPrefix}
                        idSchema={props.idSchema}
                        onBlur={props.onBlur}
                        onChange={props.onChange}
                        onFocus={props.onFocus}
                        options={schema.anyOf}
                        baseType={schema.type}
                        registry={registry}
                        safeRenderCompletion={props.safeRenderCompletion}
                        uiSchema={uiSchema}
                    />
                )}
    
                {schema.oneOf && !isSelect(schema) && (
                    <_OneOfField
                        disabled={props.disabled}
                        errorSchema={props.errorSchema}
                        formData={props.formData}
                        idPrefix={props.idPrefix}
                        idSchema={props.idSchema}
                        onBlur={props.onBlur}
                        onChange={props.onChange}
                        onFocus={props.onFocus}
                        options={schema.oneOf}
                        baseType={schema.type}
                        registry={registry}
                        safeRenderCompletion={props.safeRenderCompletion}
                        uiSchema={uiSchema}
                    />
                )}
            </React.Fragment>
        );

    }

    render() {
        const props = getField(this.props);
        const { registry } = props;

        // if fieldset, render fieldset

        /*

        const fieldset = getFieldset(props);
        const { SchemaFieldset } = registry.fields

        if (fieldset) {
            return (
                <SchemaFieldset {...fieldset} />
            )
        }

        */

        // render component
        
        return this.renderComponent(props)        
    }

}

export default SchemaField