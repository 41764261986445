import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100%",
        tableLayout: "fixed",
        padding: props => { return theme.spacing(props.padding) },

        fontFamily: "Akkurat, sans-serif",

        "& tr + tr": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider
        },

        "& thead th": {
            position: "sticky",
            zIndex: 2,
            backgroundColor: "inherit",
            top: 0
        },

        "& th": {
            textAlign: "left"
        }

    },
}));

const LayoutTable = ({spacing = 1, padding = 0, children}) => {
    const classes = useStyles({padding});

    return (
        <table className={classes.root} container data-spacing={spacing} data-layout="table">
            {children}
        </table>
    )

}

export default LayoutTable;