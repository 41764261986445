import category from './videoCategory';
import dependencies from './videoDependencies';

export default {
  "type": "object",
  "properties": {
    "title": {
      "type": "string"
    },
    "headline": {
      "type": "string"
    },
    "description": {
      "type": "string"
    },
    "descriptionWriter": {
      "type": "string"
    },
    "keywords": {
      "type": "string"
    },

    "volume": {
      "type": "integer",
      "minimum": 0,
      "maximum": 100
    },

    "category": category,

    "filename": {
      "type": "string",
      "readonly": true
    },

    "creditLine": {
      "type": "string"
    },
    "creator": {
      "type": "string"
    },
    "creatorJobtitle": {
      "type": "string"
    },
    "creatorContactInfo": {
      "type": "string"
    },
    "copyrightNotice": {
      "type": "string"
    },
    "copyrightOwner": {
      "type": "string"
    },
    "license": {
      "type": "string"
    },
    "instructions": {
      "type": "string"
    }



  },
  "dependencies": dependencies
}