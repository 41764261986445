import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import CalendarTable from "./CalendarTable"
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    table: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",

        "& thead": {
            display: "flex",
            width: "100%",
            flexDirection: "column",
        },

        "& th": {
            flexBasis: 0,
            flexGrow: 1,
        },

        "& th + th": {
            borderLeft: "1px solid",
            borderColor: theme.palette.divider
        },
       
        "& tbody": {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            flexGrow: 1
        },

        "& tr": {
            display: "flex",
            width: "100%",
            flexBasis: 0,
            flexGrow: 1
        },

        "& tr + tr": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider
        },

        "& td": {
            flexBasis: 0,
            flexGrow: 1,
        },

        "& td + td": {
            borderLeft: "1px solid",
            borderColor: theme.palette.divider
        }

    },
    weekday: {
        display: "flex",
        margin: theme.spacing(1),
        alignItems: "center",
        justifyContent: "left",
        fontFamily: "Akkurat, sans-serif",
        fontSize: '12px',
        lineHeight: 1,
    },
    date: {

    },
    dateButton: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        margin: 0,
        fontFamily: "Akkurat, sans-serif",
        fontSize: '12px',
        color: theme.palette.text.disabled,
        lineHeight: 1,
        "&[data-month=true]": {
            color: theme.palette.text.primary
        },
        "&[data-today=true]": {
            border: "1px solid",
            borderColor: theme.palette.primary.main
        },
        "&[data-selected=true]": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
    }
}));

const EventsList = ({events}) => {

    return (
        <ul>
            { events.map(event => {
                return (
                    <li>{event.title}</li>
                )
            })}
        </ul>
    )

}

const DateButton = ({selected, dt, dtMonth, onClick}) => {

    const classes = useStyles()

    return (
        <IconButton className={classes.dateButton} onClick={onClick}
            data-selected={selected}
            data-month={dtMonth}>
            {moment(dt).format('D')}
        </IconButton>
    )

}

const LayoutCalendar = ({date, eventsByDate = {}, onSelect}) => {

    const classes = useStyles()

    const renderWeekday = ({dt, weekday}) => {
//        const weekday = moment(dt).format('dd').substr(0, 1).toUpperCase()

        return (
            <div className={classes.weekday}>{weekday}</div>
        )
    }

    const renderDate = ({dt, dtMonth}) => {

        const selected = date === dt

        const events = eventsByDate && eventsByDate[dt]

        return (
            <div className={classes.date}>
                <DateButton dt={dt} dtMonth={dtMonth} selected={selected} onClick={(e) => onSelect && onSelect({date:dt}, e)}>
                    {moment(dt).format('D')}
                </DateButton>
                {events && <EventsList events={events} />}
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <CalendarTable dt={date} className={classes.table} renderWeekday={renderWeekday} renderDate={renderDate} />
        </div>
    )

}

export default LayoutCalendar;
