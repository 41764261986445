import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OwnerWidget } from '@frontend-components/admin';

class WidgetOwner extends Component {
    
    static defaultProps = {
        addable: true,
        removable: true
    }
    
    getTheme = () => {
        const { site } = this.props;

        if (!site) {
            return false;
        }
        
        let primaryColor;
        
        if (site.content && site.content.colors && site.content.colors[0]) {
            primaryColor = site.content.colors[0].value;
        }
        
        return {
            primaryColor: primaryColor
        }
        
        
    }
    
    getApps = () => {
        const { site, user } = this.props;
        
        if (!user || !user.owners) {
            return false;
        }
        
        const siteApps = user.owners.filter((owner => {
            if (owner.id === site.id) {
                return true
            }
        }))
        
        if (siteApps[0] && siteApps[0].apps) {
            return siteApps[0].apps
        }
        
    }
    
    getImageUrl = () => {
        const { site } = this.props;
        
        if (!site.content) {
            return false;
        }

        let image;
        
        if (site.content && site.content.images && site.content.images[0]) {
            image = site.content.images[0];
        }
        
        if (image && image.media && image.media.imageUrl) {
            return image.media.imageUrl
        }
        
    }
    
    getOwner = () => {
        const { site } = this.props;

        if (!site) {
            return false
        }
        
        const apps = this.getApps()
        const imageUrl = this.getImageUrl()
        const theme = this.getTheme()
        
        return {
            ...site,
            abbr: site.abbr || site.name,
            imageUrl: imageUrl,
            apps: apps,
            theme: theme
        }
        
    }
    
    render() {
        const { widgetSize, onSelect } = this.props;
        const owner = this.getOwner()
        
        return (
            <OwnerWidget widgetSize={widgetSize} owner={owner} onSelect={onSelect} />
        )
    
    }

}


function mapStateToProps(state) {
	return {
      	site: state.site,
      	user: state.user
	};
}  

export default connect(
  mapStateToProps,
)(WidgetOwner);