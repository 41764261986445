import topic from './topic/';
import article from './article/';
import device from './device/';
import page from './page/';
import pageList from './pageList/';
import pageGrid from './pageGrid/';
import pageTimeline from './pageTimeline/';
import pageAnnotate from './pageAnnotate/';
import pageMedia from './pageMedia/';

/*
import device from "@frontend-components/admin/lib/components/AppKp/schemas/device"
import page from "@frontend-components/admin/lib/components/AppKp/schemas/page"
import pageList from "@frontend-components/admin/lib/components/AppKp/schemas/pageList"
import pageGrid from "@frontend-components/admin/lib/components/AppKp/schemas/pageGrid"
import pageTimeline from "@frontend-components/admin/lib/components/AppKp/schemas/pageTimeline"
import pageAnnotate from "@frontend-components/admin/lib/components/AppKp/schemas/pageAnnotate"
import pageMedia from "@frontend-components/admin/lib/components/AppKp/schemas/pageMedia"
*/

const schemas = [
    topic,
    article,
    device,
    page,
    pageList,
    pageGrid,
    pageTimeline,
    pageAnnotate,
    pageMedia,
]

export default schemas;