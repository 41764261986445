import { getAppData } from '../../redux/functions/app';
import { getSiteCollection } from '../../redux/functions/appCollection';

import schemas from '../schemas/';

export function getApp(props) {
    const { site, collection } = props;

    const appTitle = "Primus FDV"
    const appName = "primus"
    const appRoot = "/" + site.name + "/" + appName
    
    props = {
        ...props,
        appTitle: appTitle,
        appName: appName,
        appRoot: appRoot,
    }
    
    const appEdit = getAppAction(props)
    const appMenu = getAppMenu(props)
    
    const app = {
        uniqueId: collection && collection.id,
        siteId: site && site.id,
        collectionId: collection && collection.id,
        root: appRoot,
        name: appName,
        title: appTitle,
        theme: {
            primaryColor: "#2d2f38" || '#2C2F38' || '#0069BD' || "#4299f9"
        },
        search: {
            "variant": "grow",
            "placeholder": "Søk i " + appTitle
        },
        edit: appEdit,
        menu: appMenu,
//w        menuTree: appRoot,
        schemas: schemas,
        sidebar: {
            disabled: true
        },
        languages: [
            "no", "sv", "en"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
    }

}


export function getAppAction(props) {
    const { appRoot } = props;

    return [
        {
      label: "Lag ny",
      icon: "",
      children: [
        {
            title: "Bygning",
            url: appRoot + "/building/<new>/edit",
        },
        {
            title: "Tilstand",
            url: appRoot + "/condition/<new>/edit",
        },
        {
            title: "Avvik",
            url: appRoot + "/damage/<new>/edit",
        },
        {
            title: "Oppgaver",
            url: appRoot + "/task/<new>/edit",
        },
        {
            title: "Arbeid",
            url: appRoot + "/work/<new>/edit",
        },
        {
          title: "Last opp",
          icon: "cloud_upload",
          url: appRoot + "/upload",
            action: {
                actionType: "upload"
            },
        }
      ]
    }]
    
}

export function getAppMenu(props) {
    const { appRoot, collection } = props;
    
    const collectionId = collection.id;

    let navHome = {
        icon: "home",
        title: "Primus FDV",
        url: appRoot
    }

    let navBuilding = {
        title: "Bygninger",
        icon: "home",
        url: appRoot + "/building",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "building",
            status: "NOT trash"
        },
    }
    
    let navDamage = {
        title: "Avvik",
        icon: "view_quilt",
        url: appRoot + "/damage",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "damage",
            status: "NOT trash"
        }
    }

    let navCondition = {
        title: "Tilstand",
        icon: "view_quilt",
        url: appRoot + "/condition",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "condition",
            status: "NOT trash"
        }
    }

    let navTasks = {
        title: "Oppgaver",
        icon: "view_quilt",
        url: appRoot + "/task",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "task",
            status: "NOT trash"
        }
    }

    let navWork = {
        title: "Bygningsarbeid",
        icon: "view_quilt",
        url: appRoot + "/work",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "work",
            status: "NOT trash"
        }
    }

   return [
        navHome,
        navBuilding,
        navDamage,
        navCondition,
        navTasks,
        navWork,
    ]

}