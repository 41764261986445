import React from 'react';
import { MenuList, MenuItem, MenuLink } from "../NavMenu/"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    menu: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: "auto",
        left: 0,
        bottom: 0,
        width: props => { return props.menuWidth },
        marginLeft: props => { return "-" + props.menuWidth },
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginLeft: 0,
        },
        "&[aria-expanded=true] + *":  {
            marginLeft: props => { return props.menuWidth },
        }
    },
    menuList: {
        margin: theme.spacing(3),
        "& > li + li": {
            marginTop: theme.spacing(1)
        }
    }
}));

const PageMenu = ({menuWidth = 224, menu = undefined}) => {
    const classes = useStyles({menuWidth});

    if (!menu) {
        return false
    }

    return (
        <aside className={classes.menu} aria-expanded={true}>
            <MenuList className={classes.menuList}>
                {menu.map((item, index) => {
                    return (
                        <MenuItem>
                            <MenuLink {...item} />
                        </MenuItem>
                    )
                })}
            </MenuList>
        </aside>
    )

}

export default PageMenu;