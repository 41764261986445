import { getAppData } from '../../redux/functions/app';
import { getUserCollection } from '../../redux/functions/appCollection';

//import schemas from '../schemas/';

export function getApp(props) {
    const { user, site, collection } = props;
    
    const appTitle = "Forum"
    const appName = "forum"
    const appRoot = "/forum"
    
    props = {
        ...props,
        appTitle: appTitle,
        appName: appName,
        appRoot: appRoot,
    }
    
    const appButton = getAppButton(props)
    const appMenu = getAppMenu(props)
    const appSearch = getAppSearch(props)
    
    const app = {
        root: appRoot,
        name: appName,
        theme: {
            primaryColor: "#fff"
        },
        header: {
            title: appTitle,
            subtitle: user.name || "N/A"
        },
        sidebar: {
            expanded: true,
        },
        search: appSearch,
        button: appButton,
        menu: appMenu,
//        schemas: schemas,
        languages: [
            "no", "sv", "en", "de", "es", "fr"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
        dispatch(getUserCollection({...props, app: app}))
    }
    
}

export function getAppSearch(props) {
    const { appTitle } = props;
    
    return {
        "placeholder": "Søk i " + appTitle
    }
    
}

export function getAppButton(props) {
    const { appRoot } = props;

    return {
      title: "Compose",
      url: appRoot + "/section/category/<new>",
      icon: ""
    }
    
}

export function getAppMenu(props) {
    const { appRoot, collection } = props;
    
   return [
      {
        title: "Innboks",
        icon: "home",
        url: appRoot
      }
    ]

}