import React, { Component } from 'react';
import { FinderSection } from '@frontend-components/admin';
import AuthModuleUser from "./AuthModuleUser"

class AuthSectionUsers extends Component {
    
    static defaultProps = {
        users: []
    }
    
    renderUser = (user, index) => {

        return (
            <AuthModuleUser {...user} key={index} />
        )
        
    }

    render() {
        const { title, users } = this.props;
        
        return (
            <FinderSection title={title}>
                { users.map(this.renderUser) }
            </FinderSection>
        )
        
    }

}

export default AuthSectionUsers