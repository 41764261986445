export default {
     "ui:fieldset": [
        "header",
        "hasArticles",
    ],
    "header": {
        "ui:layout": "section",
        "ui:options" : {
            "collapsible": true,
        },
        "ui:title": "Tema",
        "ui:description": {
            "select": "title"
        },
        "ui:fieldset": [
            "image",
            "title",
            "leadtext",
            "bodytext"
        ],
        "image": {
            "ui:field": "media",
            "ui:format": "2:1",
            "ui:options": {
                "editable": true,
                "cropdata": true,
                "focalpoint": true,
                "adjust": true
            },
            "ui:buttons": [
                {
                    "title": "Finn bilde",
                    "type": "sidebar/media/image"
                },
                "upload"
            ]
        },
        "title": {
            "ui:title": "Tittel"
        },
        "leadtext": {
            "ui:title": "Ingress",
            "ui:widget": "textarea"
        },
        "bodytext": {
            "ui:title": "Brødtekst",
            "ui:widget": "textarea"
        }
    },
    "hasArticles": {
        "ui:title": "[count] artikler",
        "ui:preview": {
            "select": {
                "title": "title"
            },
            prepare({formData}) {
                const count = formData && formData.length;
                if (count) {
                    return {
                        "title": count + " artikler"
                    }
                }
                return {
                   "title": "Ingen artikler"
                }
            }
        },
        "ui:layout": "section",
        "ui:options" : {
            "collapsible": true,
            "sortable": true
        },
        "ui:buttons": [
            {
                "type": "sidebar",
                "label": "Finn artikkel"
            },
            {
                "type": "add",
                "label": "Legg til"
            }
        ]
    }
}