import React from "react";
import PropTypes from "prop-types";
//import { asNumber, guessType } from "../Schema/utils";
import SelectField from "./SelectField"

const guessType = function guessType(value) {
    if (Array.isArray(value)) {
        return "array";
    } else if (typeof value === "string") {
        return "string";
    } else if (value == null) {
        return "null";
    } else if (typeof value === "boolean") {
        return "boolean";
    } else if (!isNaN(value)) {
        return "number";
    } else if (typeof value === "object") {
        return "object";
    }
    // Default to string if we can't figure it out
    return "string";
};

const asNumber = (value) => {
    if (value === "") {
        return undefined;
    }
    if (/\.$/.test(value)) {
        // "3." can't really be considered a number even if it parses in js. The
        // user is most likely entering a float.
        return value;
    }
    if (/\.0$/.test(value)) {
        // we need to return this as a string here, to allow for input like 3.07
        return value;
    }
    const n = Number(value);
    const valid = typeof n === "number" && !Number.isNaN(n);

    if (/\.\d*0$/.test(value)) {
        // It's a number, that's cool - but we need it as a string so it doesn't screw
        // with the user when entering dollar amounts or other values (such as those with
        // specific precision or number of significant digits)
        return value;
    }

    return valid ? n : value;
}

const nums = new Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
function processValue(schema, value) {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === "") {
    return undefined;
  } else if (type === "array" && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === "boolean") {
    return value === "true";
  } else if (type === "number") {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every(x => guessType(x) === "number")) {
      return asNumber(value);
    } else if (schema.enum.every(x => guessType(x) === "boolean")) {
      return value === "true";
    }
  }

  return value;
}

const selectValue = (value, selected, all) => {
    const at = all.indexOf(value);
    const updated = selected.slice(0, at).concat(value, selected.slice(at));
    // As inserting values at predefined index positions doesn't work with empty
    // arrays, we need to reorder the updated selection to match the initial order
    return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
}
  
const deselectValue = (value, selected) => {
    return selected.filter(v => v !== value);
}

function getValue(event, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter(o => o.selected)
      .map(o => o.value);
  } else {
    return event.target.value;
  }
}

function SelectWidget(props) {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
//    placeholder,
  } = props;
  const { enumOptions, enumDisabled } = options;
  const emptyValue = multiple ? [] : "";

  let selectOptions = []

    enumOptions.map(({ value, label }, i) => {
        const disabled = enumDisabled && enumDisabled.indexOf(value) != -1;
        selectOptions.push({
            label: label,
            value: value,
            disabled: disabled
        })
    })

    const label = options.title || props.title || props.label
    const placeholder = options.placeholder || props.placeholder
    const helperText = options.help || undefined
    const icon = options.icon

    const handleChange = (event) => {
        onChange(processValue(schema, event.target.value));
    }

    if (multiple) {
        return (
            <p>Multiselect is not available</p>
        )
    }


  return (
    <SelectField
      id={id}
      placeholder={placeholder}
      multiple={multiple}
      options={selectOptions}
      className="form-control"
      icon={icon}
      label={label}
      helperText={helperText}
      value={typeof value === "undefined" ? emptyValue : value}
      required={required}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      onBlur={
        onBlur &&
        (event => {
          const newValue = getValue(event, multiple);
          onBlur(id, processValue(schema, newValue));
        })
      }
      onFocus={
        onFocus &&
        (event => {
          const newValue = getValue(event, multiple);
          onFocus(id, processValue(schema, newValue));
        })
      }
      onChange={handleChange} />
  );
}

SelectWidget.defaultProps = {
  autofocus: false,
};

if (process.env.NODE_ENV !== "production") {
  SelectWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      enumOptions: PropTypes.array,
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };
}

export default SelectWidget;
