import React from 'react';
import moment from 'moment';

const CalendarTable = ({dt, className, ...props}) => {

    if (dt) {
        dt = moment(dt)
    } else {
        dt = moment()
    }

    const monthStart = moment(dt).startOf('month').format('YYYY-MM-DD');
    const monthEnd = moment(dt).endOf('month').format('YYYY-MM-DD');
    
    const dtStart = moment(dt).startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
    const dtEnd = moment(dt).endOf('month').endOf('isoWeek').format('YYYY-MM-DD');
    const weeks = moment(dtEnd).diff(dtStart, 'week');

    console.log('dt', dt)

    let table = [];
    
    for (let w = 0; w <= weeks; w++) {
        let datetime = moment(dtStart).add(w, 'weeks');

        datetime = moment(datetime).set('hour', 0);
        datetime = moment(datetime).set('minute', 0);
        datetime = moment(datetime).set('second', 0);
        
        let cols = [];

        cols.push(datetime)

        for (let d = 0; d < 6; d++) {
            datetime = moment(datetime).add(1, 'days').format('YYYY-MM-DD');
    
    	    cols.push(datetime)
        }

	    table.push(cols)
    }

    const renderWeekday = (dt) => {
        dt = moment(dt).format('YYYY-MM-DD')

        const weekday = moment(dt).format('dddd')
        const ddd = moment(dt).format('ddd').toUpperCase()
        const dd = moment(dt).format('dd').toUpperCase()
        const d = moment(dt).format('dd').substr(0, 1).toUpperCase()

        if (props.renderWeekday) {
            return props.renderWeekday({dt: dt, weekday: weekday, ddd: ddd, dd: dd, d: d})
        }

        return (
            <div>{d}</div>
        )
        
    }
    
    const renderDate = (dt) => {

        dt = moment(dt).format('YYYY-MM-DD')
        const dtMonth = moment(dt).isSameOrAfter(monthStart, 'day') && moment(dt).isSameOrBefore(monthEnd, 'day')

        if (props.renderDate) {
            return props.renderDate({dt: dt, dtMonth: dtMonth})
        }

        return (
            <div>{moment(dt).format('D')}</div>
        )

    }

    return (
        <table className={className}>
            <thead>
                <tr>
                    {table[0].map((date, w) => (<th key={w}>{ renderWeekday(date)}</th>))}
                </tr>
            </thead>
            <tbody>
                {table.map((row, r) => {
                    return (
                        <tr key={r}>
                            {row.map((date, c) => (<td key={c}>{ renderDate(date)}</td>))}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
    
}


export default CalendarTable;
