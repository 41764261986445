import React from 'react';
import { connect } from 'react-redux';

import PreviewFormat from "./PreviewFormat"
import PreviewIframe from "./PreviewIframe"

const DocumentPreview = ({app, schemasByName, formData, formContext, editor}) => {

    const source = formData && formData.source;
    const documentType = formData && formData.documentType;

    const appDocumentType = documentType && app && app.root + "/" + documentType
    const sourceDocumentType = source && "document/" + source

    let modelSchema

    if (sourceDocumentType && schemasByName[sourceDocumentType]) {
        modelSchema = schemasByName[sourceDocumentType]
    } else if (appDocumentType && schemasByName[appDocumentType]) {
        modelSchema = schemasByName[appDocumentType]
    } else if (documentType) {
        modelSchema = schemasByName["document/"+documentType]
    }

    if (!modelSchema) {
        return <p>No preview for {appDocumentType}</p>
    }

    const preview = modelSchema.preview;

    if (!preview) {
        return <p>No preview for {appDocumentType}</p>    
    }

    if (preview && preview.template === "iframe" && preview.url) {
        return (
            <PreviewFormat {...preview}>
               <PreviewIframe {...preview} {...editor} />
            </PreviewFormat>
        )
    }

    if (preview && preview.template) {
        const PreviewTemplate = preview.template;

        return (
            <PreviewFormat {...preview}>
                <PreviewTemplate {...preview} {...editor} />
            </PreviewFormat>
        )
    }

    return (
        <p>{JSON.stringify(formData)}</p>
    )
    
}

function mapStateToProps(state) {
	return {
        app: state.app,
        editor: state.editor,
        schemasByName: state.schemasByName,
        formData: state.editor.formData,
        formContext: state.editor.formContext
	};
}  

export default connect(
  mapStateToProps,
)(DocumentPreview);
