import React from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import MenuItem from "@material-ui/core/MenuItem"

import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        "& $root": {
            marginTop: theme.spacing(1)
        },
    },
    label: {
    },
    list: {
        "& li": {
            minHeight: theme.spacing(5),
            "&.sortable-helper": {
                boxShadow: theme.shadows[12],
                zIndex: 2000
            }
        },
        "& li + li": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider,
        }
    }
}));

const AuthRoles = ({roles = [], roleId, onAuthRole, onAuthRemove}) => {

    if (!roles) {
        return (
            <Button variant="outlined" size="small">Add</Button>
        )
    }

    return (
        <ButtonGroup size="small">
            {roles.map((role, index) => {

                const selected = role.id === roleId

                return (
                    <Button key={index} variant={selected && "contained"} color={selected && "primary"} onClick={() => onAuthRole(role)}>
                       { role.name }
                    </Button>
                )
            })}
            { roleId && 
                <Button onClick={onAuthRemove}>
                    Revoke
                </Button>
            }
        </ButtonGroup>
    )

}

const AuthItem = ({title, description, status, auth, role, roles = [], onClick, onAuthRole, onAuthRemove}) => {

    const classes = useStyles()

    title = auth.title || auth.name;
    description = auth.description || status || role && role.name;

    return (
        <MenuItem className={classes.root} onClick={onClick} data-status={status}>
            <ListItemText primary={title} secondary={description} />
            <ListItemSecondaryAction>
                <AuthRoles roles={roles} roleId={role && role.id} onAuthRole={onAuthRole} onAuthRemove={onAuthRemove} />
            </ListItemSecondaryAction>
        </MenuItem>
    )
    
}

export default AuthItem