import React from 'react';
import Buttongroup from "./Buttongroup";

import './ButtongroupToolbar.scss';

class ButtongroupToolbar extends React.Component {
    render() {
        return (
            <Buttongroup {...this.props} className="admin-buttongroup  admin-buttongroup--toolbar">
                {this.props.children}
            </Buttongroup>
        )
    }
}

export default ButtongroupToolbar;