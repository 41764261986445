import React, { Component } from 'react';
import PropTypes from "prop-types";

class WidgetTab extends Component {
    static propTypes = { 
        selected: PropTypes.bool,
    }
    
    static defaultProps = {
        theme: {
            primaryColor: "black"
        },
        selected: false
    }
    
    getStyle = () => {
        const { selected, theme } = this.props;
        
        if (!selected) {
            return {}
        }
        
        return {
            borderColor: theme.primaryColor || "black"
        }
        
        let style = {}
        
    }
    
    render() {
        const { selected, onClick } = this.props;
        
        const style = this.getStyle()

        return (
            <div className="admin-widget__tab" style={style} aria-selected={selected} onClick={onClick}>
                { this.props.children}
            </div>
        )
        
    }

}

export default WidgetTab;