import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel, loadModel, saveModel } from '../redux/functions/model';
import { editorChange } from '../redux/functions/editor';

class InboxEditor extends Component {

    static defaultProps = {
        model: "documents",
        schema: {
            name: 'message',
            id: 49
        }
    }
    
    render() {
        return (
            <p>InboxEditor</p>
        )
      
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app,
    	user: state.user,

    	collection: state.collection,

    	owner: state.owner,
    	nav: state.nav,
    	site: state.site,

        editor: state.editor,
        data: state.editor.data
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ getModel, loadModel, saveModel, editorChange }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InboxEditor);

