import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPreview } from '../redux/functions/schema';

import { FinderModule } from '@frontend-components/admin';

class ModuleParent extends Component {

    static defaultProps = {
        moduleLayout: "table",
        moduleAction: ["edit"],
        model: {
            title: "Loading ..."
        }
    }

    getSchema = (model) => {
        const { app, schema, schemas } = this.props;
        const { documentType, mediaType } = model;

        const documentSchema = app.root + "/" + documentType;
        const mediaSchema = "/media/" + mediaType;

        if (schema) {
            return schema
        }

        if (documentType && schemas[documentSchema]) {
            return schemas[documentSchema]
        }

        if (mediaType && schemas[mediaSchema]) {
            return schemas[mediaSchema]
        }

        return undefined

    }

    getPreview = (model) => {
        const { app, moduleLayout } = this.props;
        let { urlPattern, editUrlPattern, refsUrlPattern } = this.props;

        const schema = this.getSchema(model)

        if (schema) {
            model = getPreview(schema, model)
        }

        urlPattern = urlPattern || app.root + "/:uniqueId/edit"
        editUrlPattern = editUrlPattern || app.root + "/:uniqueId/edit"
        refsUrlPattern = refsUrlPattern || app.root + "/:uniqueId/refs"

        return {
            ...model,
            urlPattern: urlPattern,
            editUrlPattern: editUrlPattern,
            refsUrlPattern: refsUrlPattern,
            moduleLayout: moduleLayout
        }

    }

    render() {
        const { model } = this.props;

        const preview = this.getPreview(model)

        return (
            <FinderModule {...preview} />
        )
    
  }

}

function mapStateToProps(state) {
	return {
        app: state.app,
        schemas: state.schemas
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModuleParent);