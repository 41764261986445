import mediaLayout from "./mediaLayout"
import mediaTypes from "./mediaTypes"
import query from "./latestQuery"

export default {
    "properties": {
        "type": {
            "enum": ["latest"]
        },
        "query": query,
        "layout": mediaLayout
    }
}