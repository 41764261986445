// initialState

let initialState = {
};

// app state

const data = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_SEARCH':

      return Object.assign({}, state, {
        [action.id]: {
          models: undefined
        }
      })

    case 'RECEIVE_SEARCH':

      return Object.assign({}, state, {
        [action.id]: action.data
      })

    case 'RECEIVE_SEARCH_INDEX':

      return Object.assign({}, state, {
        [action.id]: {
            ...state[action.pathname],
            [action.index]: action.data
        }
      })

    case 'REQUEST_MODEL':

      return Object.assign({}, state, {
        [action.id]: {
        }
      })

    case 'RECEIVE_MODEL':

      return Object.assign({}, state, {
        [action.id]: action.data
      })

    case 'REQUEST_REFERENCES':

      return Object.assign({}, state, {
        [action.id]: {
          model: {},
          references: []
        }
      })

    case 'RECEIVE_REFERENCES':

      return Object.assign({}, state, {
        [action.id]: action.data
      })

    default:

      return state

    }

}

export default data;