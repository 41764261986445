import React, { Component } from 'react';
import OwnersListItem from "./OwnersListItem";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
    }
}));

const OwnersList = ({className, owners = undefined, children, onSelect}) => {
    const classes = useStyles()

    return (
        <section className={className || classes.root}>
            {owners && owners.length && owners.map((owner, index) => {
                return (
                    <OwnersListItem {...owner} onClick={() => onSelect(owner)} key={index} />
                )
            })}
            {children}
        </section>
    )
}

export default OwnersList;