import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadModel, editModel, saveModel, getModel } from '../redux/functions/model';
import { editorChange } from '../redux/functions/editor';
import { getUploadStatus, getSchemaUpload } from '../redux/functions/upload';
import qs from 'query-string';

import AppEditor from '../AppEditor';
import MediaSidebar from "../AppDialog/Sidebar"
import MediaPreview from './MediaPreview/';
import registry from "./components/registry"

const MediaEditor = ({modelName = "media", app, editor, formData, formContext, schemasByName, modelsById, ...props}) => {
    const [schemaType, setSchemaType] = useState(null)
    const [schemaId, setSchemaId] = useState(null)
    const [schema, setSchema] = useState(null)
    const [uiSchema, setUiSchema] = useState(null)

    const sq = props.location && props.location.search && qs.parse(props.location.search) || {}

    const mediaType = props.match.params.mediaType
    const uniqueId = props.match.params.uniqueId

    const parentId = props.match.params.parentId
    const parentModel = parentId && modelsById[parentId] || undefined

    const referenceId = props.match.params.referenceId
    const referenceModel = referenceId && modelsById[referenceId] || undefined

    useEffect(() => {
        referenceId && props.getModel('documents', referenceId);
    }, [referenceId])

    useEffect(() => {
        setSchemaType(undefined)
        props.getUploadStatus(uniqueId)
        props.loadModel(modelName, uniqueId)
    }, [uniqueId])

    const uniqueModel = modelsById && modelsById[uniqueId]
    const schemaPathname = uniqueModel && uniqueModel.mediaType && "media/" + uniqueModel.mediaType

    useEffect(() => {
        uniqueModel && uniqueModel.mediaType && setSchemaType(schemaPathname)
/*        if (uniqueModel && uniqueModel.source) {
            setSchemaType(uniqueModel.source)
        } else if (uniqueModel && uniqueModel.mediaType) {
            setSchemaType(uniqueModel.mediaType)
        }*/
    }, [uniqueModel])    

    const modelSchema = schemaType && schemasByName && schemasByName[schemaType]

    useEffect(() => {
        modelSchema && modelSchema.id && setSchemaId(modelSchema.id)
        modelSchema && modelSchema.schema && setSchema(modelSchema.schema)
        modelSchema && modelSchema.uiSchema && setUiSchema(modelSchema.uiSchema)
    }, [modelSchema])

    // edit

    const collectionId = app && app.collectionId
    const languages = app && app.languages;
    const defaultLocale = app && app.defaultLocale || app && app.languages[0];    

    const onSubmit = ({formData, redirect}) => {
        const status = formData && formData.status

        if (status === "copy") {
            props.saveModel(modelName, {
                schemaId: formData && formData.schemaId,
                collectionId: formData && formData.collectionId,
                documentType: formData && formData.documentType,
                content: formData && formData.content,
                status: "new",
                title: formData.title + " (copy)"
            });
        } else {
            props.saveModel(modelName, formData, redirect && false)
//            formData.content && saveChildren(formData.content)
        }

        if (redirect) {
            props.history.replace(redirect);
            redirect = null
        }

    }

    const replaceMedia = ({mediaId, media}) => {
        props.editorChange(media)
    }

    const onUploadReplace = (acceptedFiles) => {
        getSchemaUpload({acceptedFiles, schema, uploadData: uniqueModel, onChange: replaceMedia})
    }

    const onEditChild = ({formData}) => {
        const childUrl = app.root + "/" + uniqueId + "/children/" + formData.documentType + "/" + formData.uniqueId + "/edit"
        props.history.push(childUrl);
    }

    const onCreateChild = ({documentType}) => {
        const childUrl = app.root + "/" + uniqueId + "/children/" + documentType + "/<new>/edit"
        props.history.push(childUrl);
    }
   
    const onEditReference = ({id, formData: { referenceId, reference: { documentType } }}) => {
        const referenceUrl = app.root + "/" + uniqueId + "/references/" + documentType + "/" + referenceId + "/edit"

        props.history.push({
            pathname: referenceUrl,
            search: "?backUrl=" + props.location.pathname + "&backId=" + id
        })

    }

    const getReferenceParent = (referenceModel) => {
        const { backUrl, backId } = sq

        let referenceUrl;

        if (backUrl && backId) {
            referenceUrl = decodeURI(backUrl).replace(' ', '+') + "#" + backId
        } else {
            referenceUrl = app.root + "/" + referenceModel.uniqueId + "/edit"
        }

        referenceModel && setParents([{
            title: referenceModel.title,
            url: referenceUrl
        }])
    }

    const [parents, setParents] = useState(app && app.parents)

    useEffect(() => {
        referenceModel && getReferenceParent(referenceModel)
        app && !parentId && !referenceId && setParents(app && app.parents)
    }, [app.parents, referenceModel])


    if (!schemaType || !schema) {
        return (
            <ul>
                <li>schemaType: {JSON.stringify(schemaType)}</li>
                <li>schema: {JSON.stringify(schema)}</li>
            </ul>
        )
    }   

    let preview;

    if (formContext && formContext.preview) {
        preview = formContext.preview
    } else {
        preview = {
            template: MediaPreview
        }
    }

    const newFormContext = {
        schemaId: schemaId,
        uniqueId: uniqueId,
        parentId: parentId,
        referenceId: referenceId,
        parents: parents,
        languages: languages,
        preview: preview,
        sidebar: {
            template: MediaSidebar
        },
        onEditReference: onEditReference,
        onCreateChild: onCreateChild,
        onEditChild: onEditChild,
        onUploadReplace: onUploadReplace,
        ...formContext
    }
        
    if (!newFormContext) {
        return (
            <p>Get formContext</p>
        )
    }

    if (!schemaType) {
        return (
            <p>Get model</p>
        )
    }

    return (
        <AppEditor {...props}
            registry={registry}
            schema={schema}
            uiSchema={uiSchema}
            formContext={newFormContext}
            onSubmit={onSubmit}
        />
    )
  
}

// mapStateToProps

function mapStateToProps(state) {
	return {
        app: state.app,
        schemasByName: state.schemasByName,
        modelsById: state.modelsById
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        loadModel, editModel, saveModel, getModel,
        editorChange,
        getUploadStatus
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaEditor);

