export default {
    "ui:options": {
        "grid": true,
        "spacing": 2
    },
    "ui:fieldset": [
        "license",
        "copyright",
        "copyrightYear",
        "credits"
    ],
    "license": {
        "ui:widget": "selectLicense",
        "ui:title": "Lisens",
        "ui:help": "Velg en lisens for dette innholdet.",
    },
    "copyright": {
        "ui:title": "Copyright",
        "ui:help": "Beskrivelse av opphavsrett"
    },
    "copyrightYear": {
        "ui:title": "Copyright year",
        "ui:help": "Beskrivelse av opphavsrett"
    },
    "credits": {
        "ui:field": "arrayTable",
        "ui:title": "Kreditering",
        "ui:addItemLabel": "Legg til kreditering",
        "ui:addItemHelp": "Person eller organisasjon",
        "items": {
            "ui:options": {
                "grid": true,
                "spacing": 2,
            },
            "type": {
                "ui:title": "Type",
                "ui:widget": "select"
            },
            "name": {
                "ui:title": "Navn",
            }
        }
    }
}