export default {
    "type": "object",
    "properties": {
        "sectionType": {
            "enum": ["text"]
        },
        "bodytext": {
            "type" : "localizedString"
        }
    }
}