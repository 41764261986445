import React, { Component } from 'react';
import { RouterLink } from "../"

class WidgetTitle extends Component {

    render() {
        const { title, url } = this.props;
        
        if (!title) {
            return false
        }

        if (url) {

            return (
                <h2 className="admin-widget__title"><RouterLink url={url}>{title}</RouterLink></h2>
            )
            
        }

        return (
            <h2 className="admin-widget__title">{title}</h2>
        )

    }

}

export default WidgetTitle;