import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ImageBase from "./ImageBase"
import qs from 'query-string';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
//        backgroundColor: "#333",
//        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    }
}));

const ImagePreview = ({
        className,
        mediaUrl,
        mimeType,
        imageCropdata = undefined,
        imageFilters = undefined,
        uploadProgress = undefined,
        uploadStatus = undefined,
        ...props
    }) => {

    const classes = useStyles()

    const [imageUrl, setImageUrl] = useState(props.imageUrl || undefined)

    const getImageUrl = ({imageUrl}) => {
        
        if (!imageUrl) {
            return
        }


        const imageUrlParts = imageUrl && imageUrl.split("?")
        const imageParams = imageUrlParts && imageUrlParts[1] && qs.parse(imageUrlParts[1])

        if (!imageParams) {
            return imageUrl
        }

        if (mimeType && mimeType === "image/svg+xml") {
            imageParams.mediaType = "image/svg"
        }

        const query = qs.stringify({
            ...imageParams,
            uploadStatus: uploadStatus,
            uploadProgress: uploadProgress,
        })


        const newImageUrl = imageUrlParts[0] + "?" + query
        
        return newImageUrl

    }

    useEffect(() => {
        setImageUrl(getImageUrl(props))
    }, [props.imageUrl, uploadStatus, uploadProgress])

    return (
        <figure className={className || classes.root} data-layout="imagePreview">
            <ImageBase 
                imageUrl={imageUrl} 
                imageCropdata={imageCropdata}
                imageFilters={imageFilters} />
        </figure>
    )

}

export default ImagePreview;