import React, { Component } from 'react';
import PropTypes from "prop-types";
import { FormattedMessage, FormattedNumber } from "react-intl";
import moment from "moment";

import messages from "./FormatMetric.messages";

class FormatMetric extends Component {
    static contextTypes = { intl: PropTypes.object }

    static propTypes = { 
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.number,
        decimals: PropTypes.number
    }
    
    static defaultProps = {
        decimals: 2
    }
    
    formatInteger = () => {
        let { value } = this.props;

        if (this.context.intl) {
            return (
                <FormattedNumber value={value} />
            )
        }

        value = new Intl.NumberFormat('de-DE').format(value)

        return value;
        
    }
    
    formatFloat = () => {
        let { value, decimals } = this.props;

        if (this.context.intl) {
            return (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={decimals} />
            )
        }

        value = parseFloat(value).toFixed(decimals);
        value = new Intl.NumberFormat('de-DE').format(value)
        
        return value
        
    }
    
    formatPercent = () => {
        let { value, decimals } = this.props;
        
        value = parseFloat(value).toFixed(decimals) + "%";
        
        return value;
        
    }
    
    formatTime = () => {
        let { value } = this.props;

        value = moment().startOf('day').add(value, "seconds").format('HH:mm:ss');
        
        return value;
    }
     
    formatDuration = () => {
        let { value } = this.props;

        var sec_num = parseInt(value, 10);
    
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        
        if (hours) {
            return hours + 'h ' + minutes +'m ' + seconds + 's'
        }
    
        if (minutes) {
            return minutes + 'm ' + seconds + 's'
        }
    
        return seconds + 's'        
        
    }
    
    formatValue = () => {
        const { type, value, decimals } = this.props;
        
        if (type === "INTEGER") {
            return this.formatInteger()
        } 

        if (type === "FLOAT") {
            return this.formatFloat()
        }

        if (type === "PERCENT") {
            return this.formatPercent()
        }

        if (type === "TIME") {
            return this.formatTime()
        }

        if (type === "DURATION") {
            return this.formatDuration()
        }
        
        return value
        
    }
    
    formatName = () => {
        const { name } = this.props;

        if (messages[name]) {
            return messages[name]
        }

        return name
    }

    render() {
        const { name, type, value } = this.props;
        
        if (value) {
            return this.formatValue()
        }
        
        if (name) {
            return this.formatName()
        }
        
        return null

    }
    
}

export default FormatMetric
