export function isReferenceObject(schema) {
    if (schema.type === "object" && schema.properties) {
        if (schema.properties.hasOwnProperty('referenceType')) {
            return true
        }
    }

    return false;
}

export function getReferenceObject(schema, props) {
    const { documentType, referenceType, _reverseReferenceType, _reverseReference, reference, content, properties } = schema;

    schema = {
        ...schema,
        "type": "object",
        "properties": {
            "referenceId": {
                "type": "string",
                "readonly": true
            },
            "referenceType": {
                "type": "string",
                "default": referenceType,
                "readonly": true
            },
            "reference": {
                "type": "object",
                "properties": {
                    "documentType": {
                        "type": "string",
                        "default": documentType,
                        "readonly": true
                    },
                    "title": {
                        "type": "string",
                        "default": "Untitled " + documentType
                    }
                }
            },
            "_action": {
                "type": "string",
                "readonly": true,
                "default": "create"
            }
        }
    }
    
    if (_reverseReferenceType) {
        schema.properties._reverseReferenceType = {
            "type": "string",
            "default": _reverseReferenceType,
            "readonly": true
        }
    }

    if (_reverseReference) {
        schema.properties._reverseReference = {
            "type": "string",
            "default": _reverseReference,
            "readonly": true
        }
    }

    if (reference) {
        schema.properties.reference = reference;
    }

    schema.properties.reference.properties._action = {
        "type": "string",
        "readonly": true
    }

    if (!schema.properties.reference.properties.title) {
        schema.properties.reference.properties.title = {
            "type": "string",
            "default": "Untitled " + documentType
        }
    }

    schema.properties.reference.properties.documentType = {
        "type": "string",
        "default": documentType,
        "readonly": true
    }

    if (content) {
        schema.properties.content = content;
    }

    if (properties) {
        schema.properties = {
            ...schema.properties,
            ...properties
        }
    }

    return schema;
}

export function getReferenceAction(props) {

    let { schema, formData } = props;
    
    const { _action, referenceId } = formData;
    
    if (typeof _action === "undefined" && typeof referenceId === "undefined" || !referenceId) {
        
        formData = {
            ...formData,
            "_action": "create",
            "reference": {
                ...formData.reference,
                "_action": "create"
            }
        }
        
    }

    return formData;
    
}

export function getReferenceObjectUi(props) {

    const { uiOptions, formData, isArrayItem } = props;

    // uniqueId

    let uniqueId, media

    if (formData && formData.referenceId) {
        uniqueId = formData.referenceId
    }
    
    // reference
    
    let reference;

    if (formData && formData.reference) {
        reference = formData.reference;
    }
    
    // media

    if (formData && formData.media) {
        media = formData.media
    }

    // defaults

    let { collapsible, expanded, editable, hideable, deletable, restorable, layout, fieldset, nav } = uiOptions;

    collapsible = Boolean(uiOptions.collapsible) || true
    editable = Boolean(uiOptions.editable) || true
    hideable = Boolean(uiOptions.hideable) || true
    deletable = Boolean(uiOptions.deletable) || true
    
    // if referenceId, object is restorable
    
    if (formData && formData.referenceId) {
        restorable = true;
    }

    // layout, fieldset + nav

    if (!layout) {
        layout = "module";
    }

    if (layout === "module" && fieldset && fieldset.length) {
        nav = true;
    }
    
    // title, label, imageUrl
    
    let title, label, imageUrl;
    
    if (reference && reference.title) {
        title = reference.title;
    }

    if (reference && reference.documentType) {
        label = reference.documentType;
    }

    if (reference && reference.imageUrl) {
        imageUrl = reference.imageUrl;
    }

    return {
        ...uiOptions,
        uniqueId: uniqueId,

        title: title,
        label: label,
        imageUrl: imageUrl,

        collapsible: collapsible,
        editable: editable,

        hideable: hideable,
        deletable: deletable,
        restorable: restorable,

        layout: layout,
        fieldset: fieldset,
        nav: nav
    }
    
    
}

