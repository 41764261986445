export const REQUEST_BULK = 'REQUEST_BULK'

export const BULK_ADD = 'BULK_ADD'
export const BULK_REMOVE = 'BULK_REMOVE'

export const BULK_DELETE = 'BULK_DELETE'
export const BULK_ERASE = 'BULK_ERASE'
export const BULK_RESTORE = 'BULK_RESTORE'

export const RECEIVE_BULK_EDIT = 'RECEIVE_BULK_EDIT'


export function requestBulk() {

  return {
    type: REQUEST_BULK,
  }
  
}

export function addBulkItem(uniqueId, model) {

    return {
        type: BULK_ADD,
        uniqueId: uniqueId,
        model: model
    }
  
}

export function removeBulkItem(uniqueId) {

    return {
        type: BULK_REMOVE,
        uniqueId: uniqueId
    }
  
}

export function deleteBulkItem(id) {

  return {
    type: BULK_DELETE,
    id: id
  }
  
}

export function eraseBulkItem(id) {

  return {
    type: BULK_ERASE,
    id: id
  }
  
}

export function restoreBulkItem(id) {

  return {
    type: BULK_RESTORE,
    id: id
  }
  
}

export function receiveBulkEdit(formData) {

    return {
        type: RECEIVE_BULK_EDIT,
        formData: formData
    }
  
}