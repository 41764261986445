import React, { Component } from 'react';
import Buttongroup from "./Buttongroup";

import './ButtongroupInline.scss';

class ButtongroupInline extends Component {
    render() {
        return (
            <Buttongroup {...this.props} className="admin-buttongroup  admin-buttongroup--inline">
                {this.props.children}
            </Buttongroup>
        )
    }
}

export default ButtongroupInline;