import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "24px",
        margin: theme.spacing(1),
        color: "inherit"
    }
}));

const ButtonTab = ({ icon, label, title, disabled, onClick, children }) => {
    const classes = useStyles();

    return (
        <ButtonBase className={classes.root} disabled={disabled} onClick={onClick}>
            {label || title || children}
        </ButtonBase>
    )
} 

export default ButtonTab