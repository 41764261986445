import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
    root: {
//        height: theme.spacing(7),
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
        "& .MuiInputBase-root": {
            fontFamily: "Akkurat, sans-serif",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
        },
        "& .MuiFormHelperText-root": {
            fontFamily: "Akkurat, sans-serif"
        },
    },
    select: {
        position: "relative",
        display: "flex",
    },
    startAdornment: {
        position: "absolute",
        left: 0,
        top: 0,
        right: "auto",
        bottom: 0,
        "& + *": {
            paddingLeft: theme.spacing(7)
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const SelectedChips = ({selected}) => {
    const classes = useStyles()

    return (
        <div className={classes.chips}>
            {selected && selected.length && selected.map((option, index) => (
                <Chip key={index} label={option.label} className={classes.chip} />
            ))}
        </div>
    )
}

const SelectField = ({
        id, 
        icon,
        label = "Select", 
        value = undefined,
        placeholder, 
        helperText,
        options = [], 
        multiple = false,
        onChange,
        ...props
    }) => {


    const classes = useStyles()

    const labelId = id + "-label"

    const shrinkLabel = () => {
        return placeholder && true || value && true || false
    }

    const displayEmpty = () => {
        return value && true || placeholder && true || false
    }

    const renderValue = (value) => {
        const selected = options.filter(o => o.value === value)
        return selected && selected[0] && selected[0].label || value || placeholder
    }

    const renderSelected = (value) => {
        const selected = options.filter(o => value.includes(o.value))
        return <SelectedChips selected={selected} />
    }

    let iconPrefix;

    if (icon) {
        iconPrefix = <Icon className={classes.iconPrefix}>{icon}</Icon>
    }

    let startAdornment, endAdornment;

    if (iconPrefix) {
        startAdornment = <div className={classes.startAdornment} position="start">{iconPrefix}</div>
    }

    return (
        <FormControl className={classes.root} variant="filled" fullWidth={true}>
            <InputLabel className={iconPrefix && classes.iconPrefixLabel} shrink={shrinkLabel} id={labelId}>{label}</InputLabel>
                <div className={classes.select}>
                {startAdornment}
                <Select
                    fullWidth={true}
                    displayEmpty={displayEmpty}
                    label={label}
        
                    id={id}
                    value={value}
                    renderValue={renderValue}
                    displayEmpty={placeholder && true}

                    onChange={onChange}>
                    {options.map((option, index) => {
                        return (
                            <MenuItem value={option.value} {...option} key={index}>{option.label}</MenuItem>
                        )
                    })}
                </Select>
            </div>
            {helperText && <FormHelperText>{helperText}</FormHelperText> }
        </FormControl>
    )

}

export default SelectField