import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {__RouterContext as RouterContext} from 'react-router';
import { Link } from 'react-router-dom';

class RouterLink extends Component {
    static contextType = RouterContext

    render() {
        const { className, style, selected, expanded, value, disabled, haspopup, target, onClick, onToggle, tabindex, controls } = this.props;

        const url = this.props.to || this.props.url;
        
        const external = url.startsWith('//') || url.startsWith('http://') || url.startsWith('https://')        
        
        if (!external && url && this.context && this.context.history) {
            return (
                <Link to={url} className={className} style={style} onClick={onClick} target={target}
                    tabindex={tabindex}
                    aria-controls={controls}
                    aria-haspopup={haspopup}
                    aria-selected={selected}
                    aria-expanded={expanded}>
                    {this.props.children} 
                </Link>
            );
        }

        if (url) {
            return (
                <a href={url} className={className} style={style} onClick={onClick} target={target}
                    tabindex={tabindex}
                    aria-controls={controls}
                    aria-haspopup={haspopup}
                    aria-selected={selected}
                    aria-expanded={expanded}>
                    {this.props.children}
                </a>
            )
        }

        return (
            <div className={className} style={style} onClick={onClick} onToggle={onToggle}
                value={value}
                disabled={disabled}
                tabindex={tabindex}
                aria-controls={controls}
                aria-haspopup={haspopup}
                aria-selected={selected}
                aria-expanded={expanded}>
                {this.props.children}
            </div>
        );
    }
}

export default RouterLink;