import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDefaultFormState } from '@frontend-components/admin';
// import { getSelected } from '../redux/functions/dialog';

import { getSearch } from '../redux/functions/search';
import { addMediaSource } from '../redux/functions/source';

import ResultsList from "./ResultsList"
import ResultsGallery from "./ResultsGallery"

const layouts = {
    "list": ResultsList,
    "gallery": ResultsGallery
}

const MediaResults = ({schema, formData, onChange, query = {}, searchById = {}, layout = "list", ...props}) => {
    const results = searchById && searchById[query.id];
    const resultsByPage = results && resultsByPage || []

    // handle page

    const handlePage = (page) => {
        props.getSearch({
            ...query,
            page: page,
            start: query.rows * (page-1),
            nextToken: results && results.nextToken
        });

    }

    // pages
    
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)

    const getPages = () => {
        const resultsPages = Math.ceil(results.count/results.rows)
        const resultsPage = Math.ceil((results.start+results.rows)/results.rows)

        setPages(resultsPages)
        setCurrentPage(resultsPage)
    }

    useEffect(() => {
        results && results.count && getPages()
    }, [results, resultsByPage])

    // schema

    let itemSchema;

    if (schema.type === "array") {
        itemSchema = schema.items
    } else {
        itemSchema = schema
    }

    const [modelsSelected, setModelsSelected] = useState([])

    const getSelected = (formData) => {
        let items = []

        if (schema.type === "array") {
            items = formData
        } else {
            items = [formData]
        }

        const selected = items.map(({media, mediaId}) => { 

            if (media.source && media.sourceId) {
                return media.source + "/" + media.sourceId
            }

            return mediaId
        })

        setModelsSelected(selected)
    }

    useEffect(() => {
        getSelected(formData)
    }, [formData])


    const handleChange = (formData) => {
        const newFormData = getDefaultFormState(schema, formData)
        onChange(newFormData);
        getSelected(newFormData);
    }


    const addModel = (model) => {

        const item = getDefaultFormState(itemSchema, {
            mediaId: model.uniqueId,
            media: model
        })

        let newFormData;

        if (schema.type === "array") {
            newFormData = [].concat(formData, item);
        } else {
            newFormData = item
        }

        handleChange(newFormData);

    }

    const handleAdd = (model) => {
        const { uniqueId, sourceId, mediaType } = model;

        console.log('addModel', model)

        if (sourceId) {

            console.log('sourceId', sourceId)


            const source = {
                ...model,
                identifier: sourceId,
                mimeType: mediaType,
                collectionId: query.collectionId
            }

            console.log('source', source)

            props.addMediaSource({
                ...model,
                identifier: sourceId,
                mimeType: mediaType,
                collectionId: query.collectionId
            }, (model) => addModel(model))

        } else if (uniqueId) {
            addModel(model)
        }
     
    }

    const removeItem = ({uniqueId, source, sourceId}) => {
        return formData.filter(item => item.mediaId !== uniqueId)
    }
    
    const handleRemove = (model) => {
        const uniqueId = model && model.uniqueId

        console.log('remove', model)

        let newFormData;

        if (schema.type === "array") {
            newFormData = removeItem({uniqueId})
        } else {
            newFormData = getDefaultFormState(itemSchema, {
                mediaId: undefined,
                media: {}
            })
        }

        handleChange(newFormData);

    }
    
    const ResultsTemplate = layout && layouts[layout]

    if (!ResultsTemplate) {
        return (
            <p>No template for {layout}</p>
        )
    }

    return (
        <ResultsTemplate {...results} query={query} modelsSelected={modelsSelected} onAdd={handleAdd} onRemove={handleRemove} pages={pages} page={currentPage} onPage={handlePage} />
    )

}

const mapStateToProps = (state) => {
	return {
        searchById: state.searchById
//        dialog: state.dialog,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
//    getSelected,
    getSearch,
    addMediaSource,
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaResults);