export default {
    "ui:layout": "section",
    "ui:title": "Program",
    "ui:options": {
        "collapsible": true,
        "grid": true,
        "spacing": 2
    },
    "ui:buttons": [
        {
            "label": "Legg til flere punkter på programmet"
        }
    ],
    "items": {
        "ui:fieldset": [
            "dtStart",
            "dtEnd",
            "placeId",
            "programId",
            "personId",
            "comments"
        ],
        "ui:options": {
            "grid": true,
            "spacing": 2
        },
        "dtStart": {
            "ui:title": "Fra dato",
        },
        "dtEnd": {
            "ui:title": "Til dato",
        },
        "placeId": {
            "ui:field": "selectPlace",
            "ui:title": "Sted",
            "ui:placeholder": "Finn museum og avdeling",
        },
        "comments": {
            "ui:title": "Kommentarer",
            "ui:widget": "textarea"
        }
    }
}