import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Gallery from "../Layout/LayoutGallery"
import GridModule from "../Module/GridModule"

const ModelsGallery = ({mediaWidth = undefined, mediaHeight = 180, mediaLayout = "cover", padding = 0, spacing = 1, models = [], debug = false}) => {

    return (
        <Gallery padding={padding} spacing={spacing} debug={debug}>
            { models.map((model, index) => {

                let width, height;
                    
                if (model.mediaWidth && model.mediaHeight) {
                    width = model.mediaWidth
                    height = model.mediaHeight
                } else if (model.imageWidth && model.imageHeight) {
                    width = model.imageWidth
                    height = model.imageHeight
                }

                model = {
                    ...model,
                    width: Math.floor(mediaHeight * (width / height)) || mediaHeight,
                    mediaHeight: mediaHeight,
                    mediaLayout: mediaLayout
                }

                return (
                    <GridModule {...model} width={model.width} key={index} />
                )
            })}
        </Gallery>
    )

}

export default ModelsGallery