import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Button } from "../Button"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        border: "1px solid",
        borderRadius: "18px",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "18px",
        padding: "9px 18px"
    }
}));

const WidgetButton = ({label, children, onClick}) => {
    const classes = useStyles()

    return (
        <ButtonBase className={classes.root} onClick={onClick}>
            { label || children }
        </ButtonBase>
    )

}

export default WidgetButton;