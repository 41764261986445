import getModelsTree from "./getModelsTree"

const getOptionsChildren = (children, options = [], labels = []) => {

    children.map(child => {

        options.push({
            ...child,
            label: labels.length && labels.join('/') + "/" + child.title || child.title,
            value: child.uniqueId
        })

        child.labels = labels

        if (child.children && child.children.length) {
            child.labels.push(child.title)
            options = getOptionsChildren(child.children, options, child.labels)
        }

    })

    return options

}

const getOptionsTree = (models) => {

    const modelsTree = getModelsTree(models)

    return getOptionsChildren(modelsTree)

}

export default getOptionsTree