import React, { useRef, useState, useEffect } from 'react';
import PropTypes from "prop-types";

import ButtonApps from "./ButtonApps"
import AppsDropdown from "./AppsDropdown"

import AppsByUser from "./AppsByUser"
import AppsByOwner from "./AppsByOwner"
import AppsOwnersList from "./AppsOwnersList"

const AppsButtonRef = React.forwardRef((props, ref) => {
    return <ButtonApps {...props} forwardedRef={ref} />;
});

const NavApps = ({className, uniqueId, user = {}, owner, apps, owners, onSelect, ...props}) => {
    const [expanded, setExpanded] = useState(props.expanded);
    const [currentOwnerId, setCurrentOwnerId] = useState(owner && owner.uniqueId);
    const anchorRef = useRef(null);

    useEffect(() => {
        if (owner && owner.uniqueId) {
            setCurrentOwnerId(owner.uniqueId)
        }
    }, [owner]);

    if (!apps && user && user.apps) {
        apps = user.apps
    }

    if (!owners && user && user.owners) {
        owners = user.owners
    }

    if (!apps) {
        return null
    }

    const handleToggle = (event) => {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const selectOwner = (owner) => {
        setCurrentOwnerId(owner && owner.uniqueId)
        onSelect && onSelect(owner)
    }

    const unselectOwner = () => {
        setCurrentOwnerId(null)
    }

    const selectApp = (item) => {
        setExpanded(false);
        onSelect && onSelect(item)
    }

    const handleCollapse = () => {
        setExpanded(false);
    };

    let currentOwner;

    if (owners && currentOwnerId) {
        currentOwner = owners.filter(owner => owner.uniqueId === currentOwnerId)[0]
    }

    if (!user.uniqueId) {
        return false
    }

    return (
        <nav className={className}>
            <AppsButtonRef onClick={handleToggle} expanded={expanded} ref={anchorRef} />
            <AppsDropdown expanded={expanded} anchorEl={anchorRef.current} arrowRef={anchorRef} onCollapse={handleCollapse}>
                { apps && <AppsByUser apps={apps} onSelect={selectApp} /> }
                { currentOwner && <AppsByOwner {...currentOwner} onCollapse={unselectOwner} onSelect={selectApp} /> }
                { !currentOwner && owners && <AppsOwnersList owners={owners} onSelect={selectOwner} /> }
            </AppsDropdown>
        </nav>
    )
        
}

NavApps.propTypes = {
    apps: PropTypes.array,
    owners: PropTypes.array,
    ownerId: PropTypes.string,
    onSelect: PropTypes.func
}

export default NavApps