import React, { Component } from 'react';
import Button from './Button';
import './ButtonToolbar.scss';

class ButtonToolbar extends Component {

    render() {
        let className = "admin-button  admin-button--toolbar";

        return (
            <Button {...this.props} className={className} />
        )
    }

}

export default ButtonToolbar;
