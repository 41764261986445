import React, { Component } from 'react';
import { getUiOptions, getUiSettings, getDefaultFormState } from "../Schema/utils"

class ReferenceField extends Component {

    static defaultProps = {
        model: "documents",
        formData: {
            referenceId: undefined,
            referenceType: undefined,
            reference: {
                uniqueId: undefined,
                documentType: undefined,
                title: undefined,
                _action: undefined
            },
            _action: undefined
        },
        defaultOptions: {
            "removable": true,
            "editable": true,
        }
    }

    state = {
        parentId: undefined,
        referenceId: undefined
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.schema !== this.props.schema) {

        }
    }

    onChange = (formData) => {
        const { schema, registry } = this.props;
        const { _action, referenceId, reference } = formData;

        // if reference and no referenceId, action = "create"

        if (!referenceId) {
            formData._action = "create"
        }

        if (!reference.uniqueId) {
            formData.reference._action = "create"
        }

        const newFormData = getDefaultFormState(schema, formData)

        if (this.props.onChange) {
            this.props.onChange(newFormData);
        }
        
    }

    onDelete = () => {
        const { formData } = this.props;

        console.log('ref:onDelete')
       
        this.onChange({
            ...formData,
            _action: "delete"
        })

    }

    onRestore = () => {
        const { formData } = this.props;

        console.log('ref:onRestore')

        this.onChange({
            ...formData,
            _action: "restore"
        })

    }

    onDialog = () => {
        const { id, schema, uiSchema, formData, formContext, onChange } = this.props;

        if (formContext.onDialog) {
            formContext.onDialog({
                id: id,
                type: "reference",
                schema: schema,
                uiSchema: uiSchema,
                formData: formData,
                onChange: onChange
            })
        }

    }

    onEditReference = () => {
        const { id, schema, uiSchema, formData, formContext, onChange } = this.props;

        if (formContext.onEditReference) {
            formContext.onEditReference({
                id: id,
                type: "reference",
                schema: schema,
                uiSchema: uiSchema,
                formData: formData,
                onChange: onChange
            })
        }

    }

    onSettingsChange = (name, value) => {
        const { formData, onChange } = this.props;

        onChange({
            ...formData,
            content: {
                ...formData.content,
                [name]: value
            }
        })

    }
    
    getUiPreview = () => {

        return {
            "select": {
                "_action": "_action",
                "type": "type",
                "title": "title",
                "description": "description",
                "imageUrl": "imageUrl",
                "status": "status",
                "referenceId": "referenceId",
                "reference": "reference"
            },
            prepare({formData}) {
                const { _action, reference, referenceId } = formData;

                let status = reference && reference.status;

                if (_action === "delete") {
                    status = "trash"
                }

                return {
                    _action: _action,
                    type: reference && reference.documentType,
                    title: reference && reference.title,
                    description: reference && reference.description,
                    imageUrl: reference && reference.imageUrl,
                    status: status,
                    referenceId: referenceId,
                    reference: reference
                }
            }                
        }

    }

    getUiReference = () => {
        const { uiSchema } = this.props;

        const uiReference = uiSchema && uiSchema.reference || {} 
        const uiOptions = getUiOptions(uiReference);

        return {
            "ui:title": "Referanse" || uiOptions.title,
            "ui:options": {
                "label": uiOptions.label || false,
            },
            ...uiReference
        }

    }

    getUiContent = () => {
        const { uiSchema } = this.props;

        const uiContent = uiSchema && uiSchema.content || {} 
        const uiOptions = getUiOptions(uiContent);

        return {
            "ui:title": "Innhold" || uiOptions.title,
            "ui:options": {
                "label": uiOptions.label || false,
            },
            ...uiContent
        }
        
    }

    getUiSchema = () => {
        const { formData, schema, uiSchema, defaultOptions } = this.props;
    
        let uiOptions = getUiOptions(uiSchema)
    
        let fieldset = uiOptions.fieldset || []
    
        if (!uiOptions.fieldset && schema.properties) {
        
            Object.keys(schema.properties).map(name => {
                const type = schema.properties[name] && schema.properties[name].type || undefined;
            
                if (type === "object") {
                    fieldset.push(name)
                }
            })
        
        }   

        // add navigation

        uiOptions.fieldset = fieldset

        if (fieldset.length > 1) {
            uiOptions.nav = true
        }

        // layout

        if (!uiOptions.layout) {
            uiOptions.layout = "module"
        }

        // deletable + restorable

        if (formData && formData.referenceId) {
            uiOptions.deletable = true
            uiOptions.restorable = true
        }

        // buttons

        let uiButtons = uiOptions.buttons || ["save"]

        if (formData && formData.reference && formData.reference.uniqueId) {

            uiButtons.push({
                label: "Edit reference",
                onClick: this.onEditReference
            })
    
        } else {

            uiButtons.push({
                label: "Edit reference",
                disabled: "true"
            })

        }

        // getUiSettings

        let uiSettings;

        if (schema.properties.content && uiOptions.settings) {

            uiSettings = getUiSettings(({
                formData: formData && formData.content || {},
                schema: schema.properties.content,
                uiSchema: {
                    ...uiOptions.content,
                    "ui:settings": uiOptions.settings
                },
                onSettingsChange: this.onSettingsChange
            }))

        }

        return {
            "ui:options": {
                ...defaultOptions,
                ...uiOptions
            },
            "reference": this.getUiReference(),
            "content": this.getUiContent(),
            "ui:preview": this.getUiPreview(),
            "ui:settings": uiSettings,
            "ui:buttons": uiButtons
        }
    }

    getRegistry = () => {
        const { registry } = this.props;

        return {
            ...registry,
            formContext: {
                ...registry.formContext,
                onDelete: this.onDelete,
                onRestore: this.onRestore,
            }
        }

    }

    render() {
        const { schema, formData } = this.props;

        const registry = this.getRegistry()
        const uiSchema = this.getUiSchema()

        const { ObjectField } = registry.fields;

        const newFormData = getDefaultFormState(schema, formData)

        return (
            <ObjectField {...this.props} registry={registry} uiSchema={uiSchema} formData={newFormData} onChange={this.onChange} />
        )

    }

}

export default ReferenceField