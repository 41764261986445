import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getReports } from '../redux/functions/reports';
import { AnalyticsReportMetrics } from '@frontend-components/admin';

import AppFinder from "../AppFinder"

const scopes = {
    minute: {
        startDate: "today,yesterday",
        endDate: "today,yesterday",
        dimensions: "nthMinute",
        orderBy: "nthMinute"
    },
    hour: {
        startDate: "today,yesterday",
        endDate: "today,yesterday",
        dimensions: "nthHour",
        orderBy: "nthHour"
    },
    day: {
        startDate: "7daysAgo,14daysAgo",
        endDate: "yesterday,8daysAgo",
        dimensions: "nthDay",
        orderBy: "nthDay"
    },
    weeks: {
        startDate: "28daysAgo,56daysAgo",
        endDate: "yesterday,29daysAgo",
        dimensions: "nthWeek",
        orderBy: "nthWeek"
    },
    months: {
        startDate: "90daysAgo,180daysAgo",
        endDate: "yesterday,91daysAgo",
        dimensions: "nthMonth",
        orderBy: "nthMonth"
    },
    years: {
        startDate: "1000daysAgo",
        endDate: "yesterday",
        dimensions: "year",
        orderBy: "year"
    }
    
}

class AnalyticsCharts extends Component {

    static defaultProps = {
        query: {
            countryId: "no",
            offset: 0,
            owner: 1,
            reportType: 0,
            rows: 1000,
            startDate: "7daysAgo,14daysAgo",
            endDate: "yesterday,8daysAgo",
            dimensions: "nthDay",
            metrics: "users,sessions,bounceRate,avgSessionDuration",
            orderBy: "nthDay",
            sort: "ascending",
        }
    }

    
    componentDidMount() {
        this.getReports()
    }
    
    getQuery = (scope, index) => {
        const { pathname } = this.props.location;
        const { query } = this.props;
        
        const scopeQuery = scopes[scope];
        
        this.props.getReports({
            ...query,
            ...scopeQuery,
            id: scope
        })
        
        
    }
    
    getReports = () => {
        Object.keys(scopes).map(this.getQuery)
    }
    
    renderReport = (id, index) => {
        const { data, theme } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        return (
            <div>
                <p>{ id }</p>
                <AnalyticsReportMetrics report={report} theme={theme} />
            </div>
        )
        
    }
    
    render() {

        return (
            
            <AppFinder {...this.props}>
                { Object.keys(scopes).map(this.renderReport) }
            </AppFinder>
        
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	data: state.reports,
    	theme: state.app.theme,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsCharts);