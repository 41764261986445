const ics = `
BEGIN:VCALENDAR
METHOD:PUBLISH
VERSION:2.0
X-WR-CALNAME:Navnedager
PRODID:-//Apple Inc.//Mac OS X 10.8.2//EN
X-APPLE-CALENDAR-COLOR:#44A703
X-WR-TIMEZONE:Europe/Oslo
CALSCALE:GREGORIAN
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8D2A4EDD-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031227
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Stefan\, Steffen
DTSTART;VALUE=DATE:20031226
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:A9298EAE-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031213
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Pia\, Peggy
DTSTART;VALUE=DATE:20031212
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:40C93C6C-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031108
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ingebrigt\, Ingelin
DTSTART;VALUE=DATE:20031107
DTSTAMP:20050920T120238Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C528BE5E-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030809
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Evy\, Yvonne
DTSTART;VALUE=DATE:20030808
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0EA6D3DD-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030723
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Malene\, Malin\, Mali
DTSTART;VALUE=DATE:20030722
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5CD2193A-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030411
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Ingvald\, Ingveid
DTSTART;VALUE=DATE:20030410
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6F4E2FA3-760C-4293-B3DB-D8A37636EDAE
DTEND;VALUE=DATE:20071101
TRANSP:OPAQUE
SUMMARY:Edit\, Edna
DTSTART;VALUE=DATE:20071031
DTSTAMP:20071102T133336Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EE27476E-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030720
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Gerhard\, Gjert
DTSTART;VALUE=DATE:20030719
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CD0E4610-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030223
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Tim\, Tina
DTSTART;VALUE=DATE:20030222
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0EDDBD79-6B97-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030707
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Torgrim\, Torgunn
DTSTART;VALUE=DATE:20030706
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2F1BF188-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031028
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Sturla\, Sture
DTSTART;VALUE=DATE:20031027
DTSTAMP:20050920T120241Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:738504AA-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031001
TRANSP:OPAQUE
SUMMARY:Helge\, Helga\, Helge
DTSTART;VALUE=DATE:20030930
DTSTAMP:20050920T120252Z
SEQUENCE:1
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DEE78-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030118
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Anton\, Tønnes
DTSTART;VALUE=DATE:20030117
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DF90C-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030121
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Lina\, Line
DTSTART;VALUE=DATE:20030120
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8D8D0297-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030830
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Johan\, Jone\, Jo
DTSTART;VALUE=DATE:20030829
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E0A62-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030126
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Paul\, Pål
DTSTART;VALUE=DATE:20030125
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:23B52CD6-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031105
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Otto\, Ottar
DTSTART;VALUE=DATE:20031104
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:36027114-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031029
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Simon\, Simen
DTSTART;VALUE=DATE:20031028
DTSTAMP:20050920T120246Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E22F8-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030130
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Herdis\, Hermod
DTSTART;VALUE=DATE:20030129
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A2F86D04-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030906
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Brede\, Brian\, Njål
DTSTART;VALUE=DATE:20030905
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:A9E82094-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031012
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Kevin\, Kennet\, Kent
DTSTART;VALUE=DATE:20031011
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E1866-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030127
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Esten\, Øystein
DTSTART;VALUE=DATE:20030126
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:971E732A-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031117
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Edmund\, Edgar
DTSTART;VALUE=DATE:20031116
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:200B9F86-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030725
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Kristina\, Kristin\, Kristi
DTSTART;VALUE=DATE:20030724
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C2C31C30-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031015
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Kaia\, Kai
DTSTART;VALUE=DATE:20031014
DTSTAMP:20050920T120238Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E6E3530C-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030915
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ingebjørg\, Ingeborg
DTSTART;VALUE=DATE:20030914
DTSTAMP:20050920T120246Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E4AFD8EF-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030813
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Klara\, Camilla
DTSTART;VALUE=DATE:20030812
DTSTAMP:20050920T120254Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EDA28B5C-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031021
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Henki\, Heine\, Henrikke
DTSTART;VALUE=DATE:20031020
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F5BBD4CC-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031129
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ruben\, Rut
DTSTART;VALUE=DATE:20031128
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3220B044-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030924
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Snorre\, Snefrid
DTSTART;VALUE=DATE:20030923
DTSTAMP:20050920T120254Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:470689E8-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030511
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Asbjørg\, Asbjørn\, Espen
DTSTART;VALUE=DATE:20030510
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DF7DBA6C-5264-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030324
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Gerd\, Gerda
DTSTART;VALUE=DATE:20030323
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:267E975C-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031203
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Borghild\, Borgny\, Bård
DTSTART;VALUE=DATE:20031202
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0F11ADA1-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030603
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Lisa\, Lise\, Liss
DTSTART;VALUE=DATE:20030602
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:886FCB99-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030417
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Magnus\, Mons
DTSTART;VALUE=DATE:20030416
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DCB61B04-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030718
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Guttorm\, Gorm
DTSTART;VALUE=DATE:20030717
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:BCD520B8-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030530
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Magnar\, Magnhild
DTSTART;VALUE=DATE:20030529
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E46927E4-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031020
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Tora\, Tore
DTSTART;VALUE=DATE:20031019
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:52DC01D8-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030512
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Magda\, Malvin
DTSTART;VALUE=DATE:20030511
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5DA9297C-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031207
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Nils\, Nikolai
DTSTART;VALUE=DATE:20031206
DTSTAMP:20050920T120238Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:748BEDF3-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031113
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Torkjell\, Torkil
DTSTART;VALUE=DATE:20031112
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0681160E-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030919
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Henriette\, Henry
DTSTART;VALUE=DATE:20030918
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:800F3607-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031210
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Anniken\, Annette
DTSTART;VALUE=DATE:20031209
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:88A69B59-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030630
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Mikael\, Mikal\, Mikkel
DTSTART;VALUE=DATE:20030629
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CBA53A31-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030716
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Oddmun\, Oddrun
DTSTART;VALUE=DATE:20030715
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:71BA6ED4-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031209
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Marlene\, Marion\, Morgan
DTSTART;VALUE=DATE:20031208
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:697CE038-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030316
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Christel\, Christer
DTSTART;VALUE=DATE:20030315
DTSTAMP:20050920T120248Z
SEQUENCE:4
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:1A29581A-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031104
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Raymond\, Roy
DTSTART;VALUE=DATE:20031103
DTSTAMP:20050920T120253Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:80CADE47-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030829
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Artur\, August
DTSTART;VALUE=DATE:20030828
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:458836ED-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031219
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Kristoffer\, Kate
DTSTART;VALUE=DATE:20031218
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EFE8425E-2C06-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20040101
TRANSP:OPAQUE
SUMMARY:Sylfest\, Sylvia\, Sylvi
DTSTART;VALUE=DATE:20031231
DTSTAMP:20050920T120253Z
SEQUENCE:1
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:61E6814C-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030513
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Normann\, Norvald
DTSTART;VALUE=DATE:20030512
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:676D4268-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030827
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Øyvind\, Eivind\, Even
DTSTART;VALUE=DATE:20030826
DTSTAMP:20050920T120237Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9172139E-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030904
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Alise\, Alvhild\, Vilde
DTSTART;VALUE=DATE:20030903
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F3AEE6C4-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030301
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Maren\, Marina
DTSTART;VALUE=DATE:20030228
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:97E6C799-C33C-41A1-9886-BFB43EEDA6F0
DTEND;VALUE=DATE:20060826
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ludvig\, Lovise\, Louise\, Ebba
DTSTART;VALUE=DATE:20060825
DTSTAMP:20060825T080539Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:A720E5BE-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030219
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Frode\, Frøydis
DTSTART;VALUE=DATE:20030218
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:631659B8-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030610
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Trygve\, Tyra
DTSTART;VALUE=DATE:20030609
DTSTAMP:20050920T120249Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DCF69-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030110
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Gunn\, Gunnar
DTSTART;VALUE=DATE:20030109
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E29CC-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030201
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Idun\, Ivar
DTSTART;VALUE=DATE:20030131
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9E1C762C-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030805
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Arnhild\, Arna\, Arne
DTSTART;VALUE=DATE:20030804
DTSTAMP:20050920T120253Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DCBF2-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030109
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Torfinn\, Turid
DTSTART;VALUE=DATE:20030108
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6FB50D10-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030212
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Inge\, Yngve
DTSTART;VALUE=DATE:20030211
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2358BC57-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031027
RRULE:FREQ=YEARLY;INTERVAL=1;UNTIL=20101025
TRANSP:OPAQUE
SUMMARY:Amandus\, Aaanda
DTSTART;VALUE=DATE:20031026
DTSTAMP:20101025T192332Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:516D4473-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031206
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Stine\, Ståle
DTSTART;VALUE=DATE:20031205
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A49C3A18-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030529
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Vilhelm\, William\, Willy
DTSTART;VALUE=DATE:20030528
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D9B5580A-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030225
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Mats\, Mattias\, Mattis
DTSTART;VALUE=DATE:20030224
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EF8CDA70-6B92-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030507
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Guri\, Gyri
DTSTART;VALUE=DATE:20030506
DTSTAMP:20050920T120237Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:759CE91C-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030611
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Tor\, Tord
DTSTART;VALUE=DATE:20030610
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:99ECCB8C-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030905
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ida\, Idar
DTSTART;VALUE=DATE:20030904
DTSTAMP:20050920T120241Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8DE926FB-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030215
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Hjørdis\, Jardar
DTSTART;VALUE=DATE:20030214
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9706941A-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030419
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Eileen\, Eilert
DTSTART;VALUE=DATE:20030418
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C0F8AD19-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030221
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Haldis\, Halldor
DTSTART;VALUE=DATE:20030220
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C345A5AC-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030910
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Trygve\, Tyra\, Trym
DTSTART;VALUE=DATE:20030909
DTSTAMP:20050920T120245Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3E9EDF33-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031204
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Sveinung\, Svein
DTSTART;VALUE=DATE:20031203
DTSTAMP:20050920T120253Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:AF7C6075-6B92-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030503
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Åsa\, Åse
DTSTART;VALUE=DATE:20030502
DTSTAMP:20050920T120238Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D0DF5810-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031017
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Flemming\, Finn
DTSTART;VALUE=DATE:20031016
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:53882036-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031110
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Tordis\, Teodor
DTSTART;VALUE=DATE:20031109
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A2AE3BCA-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030304
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Gunnbjørg\, Gunnveig
DTSTART;VALUE=DATE:20030303
DTSTAMP:20050920T120246Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F274A3FA-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030517
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Sara\, Sirene
DTSTART;VALUE=DATE:20030516
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:160CA5A6-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030401
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Vebjørn\, Vegard
DTSTART;VALUE=DATE:20030331
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8872833E-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030313
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Gregor\, Gro
DTSTART;VALUE=DATE:20030312
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D4947275-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030811
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Lorents\, Lars\, Lasse
DTSTART;VALUE=DATE:20030810
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C49721CB-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030715
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Solfrid\, Solrun
DTSTART;VALUE=DATE:20030714
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A0C4C90C-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031011
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Fridtjof\, Frida\, Frits
DTSTART;VALUE=DATE:20031010
DTSTAMP:20050920T120238Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:A7F86A4E-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030302
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Audny\, Audun
DTSTART;VALUE=DATE:20030301
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CAB3F927-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030911
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Tord\, Tor
DTSTART;VALUE=DATE:20030910
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D2346871-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030224
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Torstein\, Torunn
DTSTART;VALUE=DATE:20030223
DTSTAMP:20050920T120238Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D0A17908-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031216
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Hilda\, Hilde
DTSTART;VALUE=DATE:20031215
DTSTAMP:20050920T120249Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2304F242-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030622
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Trine\, Trond
DTSTART;VALUE=DATE:20030621
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:505A1F4B-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030731
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Aurora\, Audhild\, Aud
DTSTART;VALUE=DATE:20030730
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5A2774E8-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030801
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Elin\, Eline
DTSTART;VALUE=DATE:20030731
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EBBAEDD6-5264-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030326
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Mari\, Maria\, Marie
DTSTART;VALUE=DATE:20030325
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D7542A78-6B92-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030506
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Gudbrand\, Gullborg
DTSTART;VALUE=DATE:20030505
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:66C2E124-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031112
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Martin\, Morten\, Martine
DTSTART;VALUE=DATE:20031111
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:957B6A58-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030308
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Are\, Arild
DTSTART;VALUE=DATE:20030307
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6DA7EAFD-D1AB-44AC-A225-1EBD84FFF375
DTEND;VALUE=DATE:20061119
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Magne\, Magny
DTSTART;VALUE=DATE:20061118
DTSTAMP:20061115T105429Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:095DF87D-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031024
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Severin\, Søren
DTSTART;VALUE=DATE:20031023
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9ADB3DAE-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031010
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Leidulf\, Leif
DTSTART;VALUE=DATE:20031009
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F7786527-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031022
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Bergljot\, Birger
DTSTART;VALUE=DATE:20031021
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5C6C7176-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031003
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Live\, Liv
DTSTART;VALUE=DATE:20031002
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DB4C0-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030106
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Hanna\, Hanne
DTSTART;VALUE=DATE:20030105
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9D711BD8-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030901
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Berta\, Berte
DTSTART;VALUE=DATE:20030831
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B2AAE4FE-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031013
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Valter\, Vibeke
DTSTART;VALUE=DATE:20031012
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:42E22B3F-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030926
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ingvar\, Yngvar
DTSTART;VALUE=DATE:20030925
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8073E159-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030902
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Solveig\, Solvor
DTSTART;VALUE=DATE:20030901
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6988EC67-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031004
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Evald\, Evelyn
DTSTART;VALUE=DATE:20031003
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CFC519A7-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030617
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Lillian\, Lilly
DTSTART;VALUE=DATE:20030616
DTSTAMP:20050920T120250Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:010AE5EC-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030602
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Solveig\, Solvår
DTSTART;VALUE=DATE:20030601
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3A28F267-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030925
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Jan\, Jens
DTSTART;VALUE=DATE:20030924
DTSTAMP:20050920T120249Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3BF415BF-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030510
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Jesper\, Kasper
DTSTART;VALUE=DATE:20030509
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E7277011-5264-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030325
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Rikke\, Ulrikke
DTSTART;VALUE=DATE:20030324
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:68B7689A-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030211
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Ingfrid\, Ingrid
DTSTART;VALUE=DATE:20030210
DTSTAMP:20050920T120254Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D79483EA-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030913
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Jofrid\, Jorid
DTSTART;VALUE=DATE:20030912
DTSTAMP:20050920T120237Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:1DF29799-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030402
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Arve\, Arvid
DTSTART;VALUE=DATE:20030401
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:A79D9B9C-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031119
RRULE:FREQ=YEARLY;INTERVAL=1;UNTIL=20061117
TRANSP:OPAQUE
SUMMARY:Megne\, Magny
DTSTART;VALUE=DATE:20031118
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:59F6225A-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031101
TRANSP:OPAQUE
SUMMARY:Edit\, Edna
DTSTART;VALUE=DATE:20031031
DTSTAMP:20050920T120252Z
SEQUENCE:1
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E0378-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030125
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Jarl\, Jarle\, Roar
DTSTART;VALUE=DATE:20030124
DTSTAMP:20050920T120241Z
SEQUENCE:4
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DA24A-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20021122
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=11
TRANSP:OPAQUE
SUMMARY:Mariann\, Marianne
DTSTART;VALUE=DATE:20021121
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:88E48602-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030802
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Peder\, Petra
DTSTART;VALUE=DATE:20030801
DTSTAMP:20050920T120241Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FEBC8BE6-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030706
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Mina\, Mirjam
DTSTART;VALUE=DATE:20030705
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E2669-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030131
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Gunda\, Gunnhild
DTSTART;VALUE=DATE:20030130
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3B8EDD46-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030823
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Harriet\, Harry
DTSTART;VALUE=DATE:20030822
DTSTAMP:20050920T120253Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D0725703-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030912
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Dagny\, Dag
DTSTART;VALUE=DATE:20030911
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:BE2B5782-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030808
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Didrik\, Doris
DTSTART;VALUE=DATE:20030807
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4904BAA2-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031205
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Barbara\, Barbro
DTSTART;VALUE=DATE:20031204
DTSTAMP:20050920T120249Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4654C78A-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030523
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Henning\, Henny
DTSTART;VALUE=DATE:20030522
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:240E89BE-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030605
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Ida\, Idar
DTSTART;VALUE=DATE:20030604
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A070EA7A-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031118
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Hugo\, Hogne\, Hugleik
DTSTART;VALUE=DATE:20031117
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B1226572-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030702
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Arnhild\, Arnljot
DTSTART;VALUE=DATE:20030701
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:12B1960D-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030818
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Verner\, Wenche
DTSTART;VALUE=DATE:20030817
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DAAB8218-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031126
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Katarina\, Katrine\, Kari
DTSTART;VALUE=DATE:20031125
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C635DE3F-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030427
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Tea\, Terese
DTSTART;VALUE=DATE:20030426
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DE225C44-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030914
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Stian\, Stig
DTSTART;VALUE=DATE:20030913
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4DF08582-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030624
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Snefrid\, Snorre
DTSTART;VALUE=DATE:20030623
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EC0EA5B9-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030814
TRANSP:OPAQUE
SUMMARY:Anny\, Anine\, Ann
DTSTART;VALUE=DATE:20030813
DTSTAMP:20050920T120235Z
SEQUENCE:1
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:951A1A0A-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030804
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Oline\, Oliver\, Olve
DTSTART;VALUE=DATE:20030803
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3AA056B6-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030728
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Marita\, Rita
DTSTART;VALUE=DATE:20030727
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0A978F0C-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030519
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Eirik\, Erik\, Erika
DTSTART;VALUE=DATE:20030518
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:310A87A6-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030822
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ragnvald\, Ragni
DTSTART;VALUE=DATE:20030821
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FEDB9162-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030816
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Margot\, Mary\, Marielle
DTSTART;VALUE=DATE:20030815
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CC6F36CC-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030810
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ronald\, Ronny
DTSTART;VALUE=DATE:20030809
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2DD9A845-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030205
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Vera\, Veronika
DTSTART;VALUE=DATE:20030204
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:09E2E4B5-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030817
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Brynjulf\, Brynhild
DTSTART;VALUE=DATE:20030816
DTSTAMP:20050920T120241Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:27D10762-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030321
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Joakim\, Kim
DTSTART;VALUE=DATE:20030320
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DC88E-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20021125
TRANSP:OPAQUE
SUMMARY:Gudrun\, Guro
DTSTART;VALUE=DATE:20021124
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2C7F11A0-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031106
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Egil\, Egon
DTSTART;VALUE=DATE:20031105
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E1F78-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030129
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Karl\, Karoline
DTSTART;VALUE=DATE:20030128
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:463C1801-6B97-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030711
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Anita\, Anja
DTSTART;VALUE=DATE:20030710
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:133F1AC0-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031025
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Eilif\, Eivor
DTSTART;VALUE=DATE:20031024
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:ADA3BF69-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031120
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Elisabet\, Lisbet
DTSTART;VALUE=DATE:20031119
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FE4F00CF-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031023
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Karianne\, Karine\, Kine
DTSTART;VALUE=DATE:20031022
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:48966E3A-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030730
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Olav\, Ola\, Ole
DTSTART;VALUE=DATE:20030729
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:7825A2F6-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031006
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Brynjar\, Boye\, Bo
DTSTART;VALUE=DATE:20031005
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B0BE939E-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030514
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Linda\, Linn
DTSTART;VALUE=DATE:20030513
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DD9D6-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030113
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Reinert\, Reinhard
DTSTART;VALUE=DATE:20030112
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0272AF12-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030722
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Johanne\, Janne\, Jane
DTSTART;VALUE=DATE:20030721
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B995AF20-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030714
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Mildrid\, Melissa\, Mia
DTSTART;VALUE=DATE:20030713
DTSTAMP:20050920T120238Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DA5E8B4A-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030618
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Hildebjørg\, Hildegunn
DTSTART;VALUE=DATE:20030617
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:225BECB3-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030820
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Sigvald\, Sigve
DTSTART;VALUE=DATE:20030819
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:7D915FD4-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030612
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Dag\, Dagny
DTSTART;VALUE=DATE:20030611
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FA070F8D-6B92-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030508
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Mai\, Maia\, Maiken
DTSTART;VALUE=DATE:20030507
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:829BB694-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030317
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Gudmund\, Gudny
DTSTART;VALUE=DATE:20030316
DTSTAMP:20050920T120250Z
SEQUENCE:4
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:75DCEF0A-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030213
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Randi\, Randulf
DTSTART;VALUE=DATE:20030212
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:75E4FB50-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030415
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Ellinor\, Nora
DTSTART;VALUE=DATE:20030414
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B26796FC-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030713
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Elias\, Eldar
DTSTART;VALUE=DATE:20030712
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CC6294B1-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030531
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Gard\, Geir
DTSTART;VALUE=DATE:20030530
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FE94EC0B-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030518
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Harald\, Ragnhild
DTSTART;VALUE=DATE:20030517
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:1D0A5744-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030922
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Trine\, Trond
DTSTART;VALUE=DATE:20030921
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:327B5755-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030623
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Kyrre\, Kåre
DTSTART;VALUE=DATE:20030622
DTSTAMP:20050920T120238Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:67CA79AA-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030413
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Julie\, Julius
DTSTART;VALUE=DATE:20030412
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E2D20-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030202
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Birte\, Bjarte
DTSTART;VALUE=DATE:20030201
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4124F2D0-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030207
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Dorte\, Dortea
DTSTART;VALUE=DATE:20030206
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B18FFB30-2C06-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031229
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Unni\, Une\, Unn
DTSTART;VALUE=DATE:20031228
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:96CF29EA-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030528
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Cato\, Katinka
DTSTART;VALUE=DATE:20030527
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:251316E0-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030521
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Bjørnar\, Bjørnhild
DTSTART;VALUE=DATE:20030520
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DDCA5D2C-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031019
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Kjersti\, Kjerstin
DTSTART;VALUE=DATE:20031018
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B67E3B27-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031214
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Lucia\, Lydia
DTSTART;VALUE=DATE:20031213
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0D6EC790-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030331
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Holger\, Olga
DTSTART;VALUE=DATE:20030330
DTSTAMP:20050920T120254Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E1BF8-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030128
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Gaute\, Gry
DTSTART;VALUE=DATE:20030127
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8639B34F-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030903
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Lisa\, Lise\, Liss
DTSTART;VALUE=DATE:20030902
DTSTAMP:20050920T120238Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:1A5AEE58-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030604
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Alise\, Alvhild
DTSTART;VALUE=DATE:20030603
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:940EED00-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031009
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Benedikte\, Bente
DTSTART;VALUE=DATE:20031008
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5D30ABBC-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030826
RRULE:FREQ=YEARLY;INTERVAL=1;UNTIL=20060824
TRANSP:OPAQUE
SUMMARY:Ludvig\, Lovise\, Louise
DTSTART;VALUE=DATE:20030825
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A576483C-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030303
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Erna\, Ernst
DTSTART;VALUE=DATE:20030302
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E0F62B24-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030226
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Viktor\, Viktoria
DTSTART;VALUE=DATE:20030225
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:07CCC900-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030509
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Åge\, Åke
DTSTART;VALUE=DATE:20030508
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DE0D8-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030115
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Herbjørg\, Herbjørn
DTSTART;VALUE=DATE:20030114
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:59AD2F1A-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031221
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Abraham\, Amund
DTSTART;VALUE=DATE:20031220
DTSTAMP:20050920T120245Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6E38C8CE-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030414
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Asta\, Astrid
DTSTART;VALUE=DATE:20030413
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4BEF877B-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030208
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Rigmor\, Rikard
DTSTART;VALUE=DATE:20030207
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:283A81F8-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030403
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Sigvart\, Sivert
DTSTART;VALUE=DATE:20030402
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0C4FA06A-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030621
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Tage\, Tobias
DTSTART;VALUE=DATE:20030620
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5EB1C692-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030210
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Leikny\, Lone
DTSTART;VALUE=DATE:20030209
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:7B627FB4-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031224
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Sigurd\, Sjur
DTSTART;VALUE=DATE:20031223
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8D7BE367-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030527
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Annbjørg\, Annlaug
DTSTART;VALUE=DATE:20030526
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:82BC64D6-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031007
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Målfrid\, Møyfrid
DTSTART;VALUE=DATE:20031006
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5C7A1FCC-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030929
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Lena\, Lene
DTSTART;VALUE=DATE:20030928
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:394E3B1C-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030522
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Eli\, Ellen\, Helene
DTSTART;VALUE=DATE:20030521
DTSTAMP:20050920T120254Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4EB0E030-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030319
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Aleksander\, Edvard
DTSTART;VALUE=DATE:20030318
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:A97EE080-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030615
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Ingebjørg\, Ingeborg
DTSTART;VALUE=DATE:20030614
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:54A3FE80-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030928
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Dagmar\, Dagrun
DTSTART;VALUE=DATE:20030927
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CD3EE408-6B92-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030505
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Mona\, Monika
DTSTART;VALUE=DATE:20030504
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D66B6DDC-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030601
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Pernille\, Preben
DTSTART;VALUE=DATE:20030531
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8ED446A9-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030310
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Sindre\, Sverre
DTSTART;VALUE=DATE:20030309
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DDD60-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030114
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Gislaug\, Gisle
DTSTART;VALUE=DATE:20030113
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D0088721-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031125
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Gudrun\, Guro
DTSTART;VALUE=DATE:20031124
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:360034CE-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031107
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Leonard\, Lennart
DTSTART;VALUE=DATE:20031106
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:88431EF1-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030214
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Svanhild
DTSTART;VALUE=DATE:20030213
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:89F4C5B7-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031211
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Judit\, Jytte
DTSTART;VALUE=DATE:20031210
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4818EED7-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031109
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ingvild\, Yngvild
DTSTART;VALUE=DATE:20031108
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:1D2E3A1F-6B97-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030708
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Hulda\, Håvard
DTSTART;VALUE=DATE:20030707
DTSTAMP:20050920T120250Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:84454711-2C06-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031228
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Narve\, Natalie
DTSTART;VALUE=DATE:20031227
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:86635849-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030314
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Greta\, Grete
DTSTART;VALUE=DATE:20030313
DTSTAMP:20050920T120235Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:49E1D396-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030608
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Regine\, Rose
DTSTART;VALUE=DATE:20030607
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9AA21414-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030217
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Jill\, Juliane
DTSTART;VALUE=DATE:20030216
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:95B20B2E-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031212
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Daniel\, Dan
DTSTART;VALUE=DATE:20031211
DTSTAMP:20050920T120245Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:57517732-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030209
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Åshild\, Åsne
DTSTART;VALUE=DATE:20030208
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:439CF47B-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030320
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Josef\, Josefine
DTSTART;VALUE=DATE:20030319
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CBCBF226-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030428
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Charles\, Charlotte\, Lotte
DTSTART;VALUE=DATE:20030427
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9605C66C-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030614
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Stian\, Stig
DTSTART;VALUE=DATE:20030613
DTSTAMP:20050920T120250Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:397DDB0A-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030406
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Eirin\, Irene
DTSTART;VALUE=DATE:20030405
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:90C24FA5-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030309
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Beate\, Betty
DTSTART;VALUE=DATE:20030308
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:33754A7F-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030727
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Anna\, Anne\, Ane
DTSTART;VALUE=DATE:20030726
DTSTAMP:20050920T120249Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:492B8BE4-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030927
RRULE:FREQ=YEARLY;INTERVAL=1;UNTIL=20080925
TRANSP:OPAQUE
SUMMARY:Einar\, Eedre
DTSTART;VALUE=DATE:20030926
DTSTAMP:20080915T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:BC4DCEEA-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030425
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Albert\, Olaug
DTSTART;VALUE=DATE:20030424
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:44AB020D-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031030
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Noralf\, Norunn
DTSTART;VALUE=DATE:20031029
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3508E22E-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030405
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Nancy\, Nanna
DTSTART;VALUE=DATE:20030404
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C33009F7-6B92-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030504
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Gjermund\, Gøril
DTSTART;VALUE=DATE:20030503
DTSTAMP:20050920T120238Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C45A501E-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030703
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Kjartan\, Kjellfrid
DTSTART;VALUE=DATE:20030702
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DA3A83CC-5264-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030323
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Paula\, Pauline
DTSTART;VALUE=DATE:20030322
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:03A3A8BC-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031202
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Arnold\, Arnljot\, Arnt
DTSTART;VALUE=DATE:20031201
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6230C336-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030412
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Ulf\, Ylva
DTSTART;VALUE=DATE:20030411
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:AC10366E-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030907
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Sollaug\, Siri\, Siv
DTSTART;VALUE=DATE:20030906
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F38AB62C-5264-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030327
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Gabriel\, Glenn
DTSTART;VALUE=DATE:20030326
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F70922D0-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030917
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Lillian\, Lilly
DTSTART;VALUE=DATE:20030916
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E5DC2537-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030227
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Inger\, Ingjerd
DTSTART;VALUE=DATE:20030226
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:61760B7F-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030525
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Ester\, Iris
DTSTART;VALUE=DATE:20030524
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:04AB82EE-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031102
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Veslemøy\, Vetle
DTSTART;VALUE=DATE:20031101
DTSTAMP:20050920T120249Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D77AF19D-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031018
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Marta\, Marte
DTSTART;VALUE=DATE:20031017
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:51AED050-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030825
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Belinda\, Bertil
DTSTART;VALUE=DATE:20030824
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:48FED43A-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030824
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Signe\, Signy
DTSTART;VALUE=DATE:20030823
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DD2D4-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030111
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Sigmund\, Sigrun
DTSTART;VALUE=DATE:20030110
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:764984E4-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030828
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Roald\, Rolf
DTSTART;VALUE=DATE:20030827
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:BEAFFB2D-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031123
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Cecilie\, Sissel\, Silje
DTSTART;VALUE=DATE:20031122
DTSTAMP:20050920T120246Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DB13E-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030105
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Roar\, Roger
DTSTART;VALUE=DATE:20030104
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6B628FDF-6B92-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030502
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Filip\, Valborg
DTSTART;VALUE=DATE:20030501
DTSTAMP:20050920T120248Z
SEQUENCE:4
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:159D8966-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030921
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Tobias\, Tage
DTSTART;VALUE=DATE:20030920
DTSTAMP:20050920T120241Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9549B244-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030216
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Sigbjørn\, Sigfrid
DTSTART;VALUE=DATE:20030215
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:410B4016-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030729
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Reidar\, Reidun
DTSTART;VALUE=DATE:20030728
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:BABE681C-2C06-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031230
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Vidar\, Vemund
DTSTART;VALUE=DATE:20031229
DTSTAMP:20050920T120250Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:846E1BDC-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031225
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Adam\, Eva
DTSTART;VALUE=DATE:20031224
DTSTAMP:20050920T120245Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DD666-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030112
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Børge\, Børre
DTSTART;VALUE=DATE:20030111
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:724DD334-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031223
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Ingemar\, Ingar
DTSTART;VALUE=DATE:20031222
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8D669CAA-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030613
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Jofrid\, Jorid
DTSTART;VALUE=DATE:20030612
DTSTAMP:20050920T120237Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:406CE96B-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030407
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Asmund\, Åsmund
DTSTART;VALUE=DATE:20030406
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:7E20753C-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030416
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Odin\, Ove
DTSTART;VALUE=DATE:20030415
DTSTAMP:20050920T120250Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8E1E1A4A-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030418
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Elise\, Elsa\, Else
DTSTART;VALUE=DATE:20030417
DTSTAMP:20050920T120238Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8BE6861C-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031008
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Birgitte\, Birgit\, Berit
DTSTART;VALUE=DATE:20031007
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E6E56A3F-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030516
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Halvar\, Halvor
DTSTART;VALUE=DATE:20030515
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0FC0C138-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031103
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Tore\, Tuva
DTSTART;VALUE=DATE:20031102
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3E28BD5A-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030206
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Agate\, Ågot
DTSTART;VALUE=DATE:20030205
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DF1FA-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030119
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Hild\, Hildur
DTSTART;VALUE=DATE:20030118
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4A9A4BED-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030408
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Oddveig\, Oddvin
DTSTART;VALUE=DATE:20030407
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:AF57EA5A-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030423
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Oddgeir\, Oddny
DTSTART;VALUE=DATE:20030422
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9C4CCB70-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030420
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Arnfinn\, Arnstein
DTSTART;VALUE=DATE:20030419
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DBD34-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030108
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Eldbjørg\, Knut
DTSTART;VALUE=DATE:20030107
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EC306FF1-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030429
TRANSP:OPAQUE
SUMMARY:Vivi\, Vivian
DTSTART;VALUE=DATE:20030428
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:584736D6-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030625
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Jan\, Jens
DTSTART;VALUE=DATE:20030624
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8C93A762-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030311
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Edel\, Edle
DTSTART;VALUE=DATE:20030310
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:17045B14-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030724
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Brita\, Brit\, Britt
DTSTART;VALUE=DATE:20030723
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DC51C-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20021124
TRANSP:OPAQUE
SUMMARY:Klaus\, Klemet
DTSTART;VALUE=DATE:20021123
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B5ADC55A-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030807
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Gunnlaug\, Gunnleiv
DTSTART;VALUE=DATE:20030806
DTSTAMP:20050920T120252Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C1616D24-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030426
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Mark\, Markus
DTSTART;VALUE=DATE:20030425
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E52713AB-2C06-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031231
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:David\, Diana\, Dina
DTSTART;VALUE=DATE:20031230
DTSTAMP:20050920T120238Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:18602207-2C91-47A3-A11A-19DD8CD0D84F
DTEND;VALUE=DATE:20101027
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Amandus\, Aamanda
DTSTART;VALUE=DATE:20101026
DTSTAMP:20101025T192333Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:A872EEDE-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030422
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Janikke\, Jeanette
DTSTART;VALUE=DATE:20030421
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2E076C32-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030404
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Gunnvald\, Gunnvor
DTSTART;VALUE=DATE:20030403
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:05F5D239-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030330
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Jonas\, Jonathan
DTSTART;VALUE=DATE:20030329
DTSTAMP:20050920T120250Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EEB130AC-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030228
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Laila\, Lill
DTSTART;VALUE=DATE:20030227
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DFC9A-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030122
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Agnes\, Agnete
DTSTART;VALUE=DATE:20030121
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:7BEA13FE-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031114
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Kirsten\, Kirsti
DTSTART;VALUE=DATE:20031113
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B9327A8A-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030616
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Aslak\, Eskil
DTSTART;VALUE=DATE:20030615
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:003D5F91-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030329
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Asta
DTSTART;VALUE=DATE:20030328
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DA684-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030102
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Jesus
DTSTART;VALUE=DATE:20030101
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3B7FE564-5266-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030501
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Gina\, Gitte
DTSTART;VALUE=DATE:20030430
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:61CF7481-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030626
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Ingvar\, Yngve
DTSTART;VALUE=DATE:20030625
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:558433C4-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031002
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Rebekka\, Remi
DTSTART;VALUE=DATE:20031001
DTSTAMP:20050920T120249Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:BA02370E-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031014
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Torgeir\, Terje\, Tarjei
DTSTART;VALUE=DATE:20031013
DTSTAMP:20050920T120249Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E307E-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030203
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Jomar\, Jostein
DTSTART;VALUE=DATE:20030202
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CF15842E-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030704
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Rakel\, Rasmus
DTSTART;VALUE=DATE:20030703
DTSTAMP:20050920T120240Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E0012-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030123
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Ivan\, Vanja
DTSTART;VALUE=DATE:20030122
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:085DF4D7-2C13-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031201
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Andreas\, Anders
DTSTART;VALUE=DATE:20031130
DTSTAMP:20050920T120236Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DE43E-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030116
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Laura\, Laurits
DTSTART;VALUE=DATE:20030115
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DB862-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030107
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Aslaug\, Åslaug
DTSTART;VALUE=DATE:20030106
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DADBC-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030104
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Alf\, Alfred
DTSTART;VALUE=DATE:20030103
DTSTAMP:20050920T120242Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:AE62B553-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030806
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Osvald\, Oskar
DTSTART;VALUE=DATE:20030805
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9BADC2FA-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030305
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Ada\, Odd
DTSTART;VALUE=DATE:20030304
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C461E017-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031215
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Steinar\, Stein
DTSTART;VALUE=DATE:20031214
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:1BDDBE3C-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030520
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Torjus\, Truls
DTSTART;VALUE=DATE:20030519
DTSTAMP:20050920T120249Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D3AD8902-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030717
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Susanne\, Sanna
DTSTART;VALUE=DATE:20030716
DTSTAMP:20050920T120254Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F68B5B90-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030815
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Hallgeir\, Hallgjerd
DTSTART;VALUE=DATE:20030814
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8CBB04DA-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031116
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Oddfrid\, Oddvar
DTSTART;VALUE=DATE:20031115
DTSTAMP:20050920T120239Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5DFD52D8-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031111
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Gudbjørg\, Gudveig
DTSTART;VALUE=DATE:20031110
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DF58C-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030120
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Margunn\, Marius
DTSTART;VALUE=DATE:20030119
DTSTAMP:20050920T120254Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C8179900-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030222
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Samuel\, Selma
DTSTART;VALUE=DATE:20030221
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:0DAC274A-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030920
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Konstanse\, Connie
DTSTART;VALUE=DATE:20030919
DTSTAMP:20050920T120249Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F3F0030B-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031218
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Inga\, Inge
DTSTART;VALUE=DATE:20031217
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2DB44A75-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030606
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Brede\, Brian
DTSTART;VALUE=DATE:20030605
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:297AC024-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030821
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Bernhard\, Bernt
DTSTART;VALUE=DATE:20030820
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:292964B6-2C20-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030726
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Jakob\, Jack\, Jim
DTSTART;VALUE=DATE:20030725
DTSTAMP:20050920T120253Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:97BE9904-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030307
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Annfrid
DTSTART;VALUE=DATE:20030306
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E6741BE2-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030619
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Henriette\, Henry
DTSTART;VALUE=DATE:20030618
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:CA683F5D-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031016
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Hedvig\, Hedda
DTSTART;VALUE=DATE:20031015
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:6C5A4A28-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030627
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Einar\, Endre
DTSTART;VALUE=DATE:20030626
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:51B424A2-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031220
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Iselin\, Isak
DTSTART;VALUE=DATE:20031219
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:9486DABC-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030701
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Hege\, Helga\, Helge
DTSTART;VALUE=DATE:20030630
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FA5D383C-5264-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030328
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Rudi\, Rudolf
DTSTART;VALUE=DATE:20030327
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DC128-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20021123
TRANSP:OPAQUE
SUMMARY:Cesilie\, Sissel\, Silje
DTSTART;VALUE=DATE:20021122
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:BBB2CBB2-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030909
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Amalie\, Alma\, Allan
DTSTART;VALUE=DATE:20030908
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3B77E566-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030607
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Siv\, Solaug
DTSTART;VALUE=DATE:20030606
DTSTAMP:20050920T120249Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A2208592-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030421
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Kjellaug\, Kjellrun
DTSTART;VALUE=DATE:20030420
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C7C03DFF-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031124
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Klement\, Klaus
DTSTART;VALUE=DATE:20031123
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EAF91D40-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031128
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Torlaug\, Torleif
DTSTART;VALUE=DATE:20031127
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8A7A84D4-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030312
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Edvin\, Tale
DTSTART;VALUE=DATE:20030311
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:A1593FB0-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030218
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Sandra\, Sondre
DTSTART;VALUE=DATE:20030217
DTSTAMP:20050920T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:295E9CE4-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030923
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Kyrre\, Kåre
DTSTART;VALUE=DATE:20030922
DTSTAMP:20050920T120254Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B476F73A-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030908
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Regine\, Rose
DTSTART;VALUE=DATE:20030907
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4F868655-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030524
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Oddlaug\, Oddleif
DTSTART;VALUE=DATE:20030523
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:C35D5471-6B93-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030515
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Karsten\, Kristen\, Kristian
DTSTART;VALUE=DATE:20030514
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:EE7616CE-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030916
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Aslak\, Eskil
DTSTART;VALUE=DATE:20030915
DTSTAMP:20050920T120242Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F44BC6A3-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030620
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Connie\, Konstance
DTSTART;VALUE=DATE:20030619
DTSTAMP:20050920T120246Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:71AD14DA-2C19-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031005
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Frans\, Frank
DTSTART;VALUE=DATE:20031004
DTSTAMP:20050920T120237Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DEABA-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030117
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Hilmar\, Hjalmar
DTSTART;VALUE=DATE:20030116
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:5B05B8A4-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030318
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Gjertrud\, Trude
DTSTART;VALUE=DATE:20030317
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:54FBB27A-6B95-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030609
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Allan\, Alma
DTSTART;VALUE=DATE:20030608
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E06EE-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030124
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Emil. Emma
DTSTART;VALUE=DATE:20030123
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FDB2727D-2C1D-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030918
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Hildebjørg\, Hildegunn
DTSTART;VALUE=DATE:20030917
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:99C82DF0-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030306
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Patricia\, Patrick
DTSTART;VALUE=DATE:20030305
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:3745CC52-6B97-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030710
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Gøran\, Ørjan
DTSTART;VALUE=DATE:20030709
DTSTAMP:20050920T120236Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:679B983C-2C01-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031222
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Tomas\, Tom\, Tommy
DTSTART;VALUE=DATE:20031221
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:8F0DD1E2-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030803
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Karen\, Karin
DTSTART;VALUE=DATE:20030802
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:80EAAB36-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030629
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Lena\, Lene
DTSTART;VALUE=DATE:20030628
DTSTAMP:20050920T120243Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:67686D5A-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031208
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Hallfrid\, Hallstein
DTSTART;VALUE=DATE:20031207
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:AC5A16FE-5244-11D7-8E05-003065D64940
DTEND;VALUE=DATE:20030220
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Ella\, Elna
DTSTART;VALUE=DATE:20030219
DTSTAMP:20050920T120237Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:D0AF4E68-5264-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030322
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Bengt\, Bent
DTSTART;VALUE=DATE:20030321
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E89F82B2-2BF7-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031217
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Oddbjørg\, Oddbjørn
DTSTART;VALUE=DATE:20031216
DTSTAMP:20050920T120243Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F1A0EE68-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030430
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Toralf\, Torolf
DTSTART;VALUE=DATE:20030429
DTSTAMP:20050920T120245Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F2ED1BB3-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030705
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Ulla\, Ulrik
DTSTART;VALUE=DATE:20030704
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:50828B5C-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030409
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Asle\, Atle
DTSTART;VALUE=DATE:20030408
DTSTAMP:20050920T120239Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:75A6480C-6B94-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030526
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=5
TRANSP:OPAQUE
SUMMARY:Ragna\, Ragnar
DTSTART;VALUE=DATE:20030525
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:77251595-6B96-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030628
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=6
TRANSP:OPAQUE
SUMMARY:Dagmar\, Dagrun
DTSTART;VALUE=DATE:20030627
DTSTAMP:20050920T120248Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:F77A4BCF-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030721
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Margareta\, Margit\, Marit
DTSTART;VALUE=DATE:20030720
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:DD478C98-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030812
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Torvald\, Tarald
DTSTART;VALUE=DATE:20030811
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:64F19F78-2C1E-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030930
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Mikael\, Mikal\, Mikkel
DTSTART;VALUE=DATE:20030929
DTSTAMP:20050920T120244Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:2CB842A2-6B97-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030709
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Synne\, Synnøve
DTSTART;VALUE=DATE:20030708
DTSTAMP:20050920T120254Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:96416D48-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030831
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Benjamin\, Ben
DTSTART;VALUE=DATE:20030830
DTSTAMP:20050920T120250Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378E340A-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030204
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2
TRANSP:OPAQUE
SUMMARY:Ansgar\, Asgeir
DTSTART;VALUE=DATE:20030203
DTSTAMP:20050920T120237Z
SEQUENCE:4
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:378DAA3A-2179-11D7-BC18-003065D64940
DTEND;VALUE=DATE:20030103
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=1
TRANSP:OPAQUE
SUMMARY:Dagfinn\, Dagfrid
DTSTART;VALUE=DATE:20030102
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E62F802D-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030719
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Arnulf\, Ørnulf
DTSTART;VALUE=DATE:20030718
DTSTAMP:20050920T120246Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:AA214588-55E2-4928-9439-23B5F5DB0E0F
DTEND;VALUE=DATE:20080927
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Einar\, Endre
DTSTART;VALUE=DATE:20080926
DTSTAMP:20080915T120252Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:E4B74D36-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031127
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Konrad\, Kurt
DTSTART;VALUE=DATE:20031126
DTSTAMP:20050920T120241Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4F87D951-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031031
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Aksel\, Ånund\, Ove
DTSTART;VALUE=DATE:20031030
DTSTAMP:20050920T120250Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:57B0BC84-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030410
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Ranveig\, Rønnaug
DTSTART;VALUE=DATE:20030409
DTSTAMP:20050920T120241Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:84B033AC-5263-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030315
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=3
TRANSP:OPAQUE
SUMMARY:Mathilde\, Mette
DTSTART;VALUE=DATE:20030314
DTSTAMP:20050920T120251Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:4DAD58E2-6B97-11D7-8B90-003065D64940
DTEND;VALUE=DATE:20030712
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=7
TRANSP:OPAQUE
SUMMARY:Kjell\, Kjetil
DTSTART;VALUE=DATE:20030711
DTSTAMP:20050920T120247Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:198CF150-2C1A-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031026
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Margrete\, Merete\, Märta
DTSTART;VALUE=DATE:20031025
DTSTAMP:20050920T120248Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:19FCA4D1-2C1F-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20030819
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Tormod\, Torodd
DTSTART;VALUE=DATE:20030818
DTSTAMP:20050920T120247Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:FF7B82F6-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031130
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Sofie\, Sonja
DTSTART;VALUE=DATE:20031129
DTSTAMP:20050920T120251Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B48BBF12-5265-11D7-8ED0-003065D64940
DTEND;VALUE=DATE:20030424
RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=4
TRANSP:OPAQUE
SUMMARY:Georg\, Jørgen\, Jørn
DTSTART;VALUE=DATE:20030423
DTSTAMP:20050920T120253Z
SEQUENCE:3
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165605Z
UID:B76C9449-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031121
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Halvdan\, Helle
DTSTART;VALUE=DATE:20031120
DTSTAMP:20050920T120240Z
SEQUENCE:2
END:VEVENT
BEGIN:VEVENT
CREATED:20080916T165604Z
UID:82969600-2C12-11D8-8ABC-0003934532AC
DTEND;VALUE=DATE:20031115
RRULE:FREQ=YEARLY;INTERVAL=1
TRANSP:OPAQUE
SUMMARY:Fredrik\, Fred\, Freddy
DTSTART;VALUE=DATE:20031114
DTSTAMP:20050920T120235Z
SEQUENCE:2
END:VEVENT
END:VCALENDAR
`;

export default ics