import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUiOptions } from "@frontend-components/admin"
import { getSchemaUpload } from '../../redux/functions/uploadSchema';
import { saveMedia } from '../../redux/functions/media';

const MediaAudiotrackField = (props) => {
    const { formContext, formData, uiSchema } = props;
    const { mediaId, media } = formData;

    const handleSave = () => {
        console.log('media', media)
        props.saveMedia({formData: media})

        formContext && formContext.onCollapse && formContext.onCollapse(props)

    }

    const saveButton = {
        "label": "Save",
        "onClick": () => handleSave()
    }

    const newUiSchema = {
        ...uiSchema,
        "ui:layout": "module",
        "ui:options": {
            "editable": true
        },
        "ui:preview": {
            "select": {
                "title": "title",
            },
            prepare({formData}) {
                const { mediaId, media } = formData

                return {
                    title: media && media.title
                }

            }
        },
        "ui:nav": true,
        "ui:fieldset": [
            "media",
            "audio"
        ],
        "ui:buttons": [
            saveButton
        ],
        "media": {
            "ui:fieldset": [
                "locale",
                "title",
                "description",
                "filename"
            ],
            "locale": {
                "ui:widget": "isoLanguage"
            }
        },
        "audio": {
            "ui:layout": "format",
            "ui:format": "2:1",
            "ui:fieldset": [
                "media"
            ],
            "media": {
                "ui:layout": "mediaPreview",
                "ui:media": media,
            }
        }
    }


    const { registry } = props;
    const { ObjectField } = registry.fields

    const newRegistry = {
        
    }
    
    return (
        <ObjectField {...props} formData={formData} uiSchema={newUiSchema} />
    )
    
}

const mapStateToProps = (state) => {
	return {
        editor: state.editor
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        getSchemaUpload,
        saveMedia
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaAudiotrackField);
