import schemas from "@frontend-components/admin/lib/components/AppPrimusFDV/schemas"

const building = {
    ...schemas.building,
    uiSchema: {
        ...schemas.building.uiSchema,
        "content": {
            ...schemas.building.uiSchema.content,
            "damage": {
                ...schemas.building.uiSchema.content.damage,
                "ui:field": "primusChildren"
            },
            "condition": {
                ...schemas.building.uiSchema.content.condition,
                "ui:field": "primusChildren"
            },
            "work": {
                ...schemas.building.uiSchema.content.work,
                "ui:field": "primusChildren"
            },
            "task": {
                ...schemas.building.uiSchema.content.task,
                "ui:field": "primusChildren"
            }
        }
    }
}

export default [
    schemas.building,
    schemas.damage,
    schemas.condition,
    schemas.task,
    schemas.work
]