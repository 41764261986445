import { API } from '../settings';

import { requestApp, receiveApp, receiveAppLayout, receiveAppHeader, receiveAppSubview, receiveAppSearch, receiveAppMenu, receiveAppButton, receiveAppLanguages, requestAppTheme, receiveAppTheme } from '../../redux/actions/app';
import { toggleAppSection, expandAppSection, collapseAppSection } from '../../redux/actions/app';

import { requestSchema, receiveSchema } from '../../redux/actions/schema';
import { requestMenuByUrl, receiveMenuByUrl } from '../../redux/actions/nav';

import { requestAppQuery, receiveAppQuery } from '../../redux/actions/app';
import { requestPathname, receivePathname, requestParents, receiveParents, receiveNavItem } from '../actions/nav';
import { getParents, getNav, getNavItem } from '../../redux/functions/nav';
import { getQueryString } from './query';

import documentSchemas from "../../AppDocuments/schemas"
import mediaSchemas from "../../AppMedia/schemas"

const defaultSchemas = [
    ...documentSchemas,
    ...mediaSchemas
]

export const getApp = (app) => {
    return function (dispatch) {
        dispatch(getAppData(app))
    }
}

export const getAppData = (app) => {
    const { schemas, menu } = app;

    return function(dispatch) {
        dispatch(requestApp(app))

        defaultSchemas.map((schema, index) => {
            dispatch(getAppSchema(schema))
        });

        if (schemas) {
            schemas.map((schema, index) => {
                dispatch(getAppSchema(schema, app))
            });
        }
      
        if (menu) {
            dispatch(getAppMenu(menu))
//            dispatch(getNav(menu));
        }

        dispatch(receiveApp(app));
    }
  
}

export const getAppSchema = (schema, app) => {

    let pathname

    if (app && app.root) {
        pathname = app.root + "/" + schema.name
    } else {
        pathname = schema.schemaType + "/" + schema.name
    }

    schema = {
        ...schema,
        pathname: pathname
    }

    return function(dispatch) {
        dispatch(requestSchema(schema))
        dispatch(receiveSchema(schema));
    }    
}

export const getAppMenuByUrl = (menu, urls = {}, level = 0) => {
  
    menu.forEach((item) => {
        item.level = level      
      
        if (item.url) {
            urls[item.url] = item;
        }
      
        if (item.children) {
            urls = getAppMenuByUrl(item.children, urls, level++);
        }
    });
    
    return urls;
    
}

export const getAppMenu = (menu) => {
    const menuByUrl = getAppMenuByUrl(menu);

    return function(dispatch) {
        dispatch(requestMenuByUrl())
        dispatch(receiveMenuByUrl(menuByUrl));
    }    

}

export function getAppLayout(layout) {
    
    return function (dispatch) {

        if (layout === "login") {
            dispatch(collapseAppSection('header'));
            dispatch(collapseAppSection('subview'));
            dispatch(collapseAppSection('sidebar'));
        }

        if (layout === "dashboard") {
            dispatch(expandAppSection('header'));
            dispatch(collapseAppSection('subview'));
            dispatch(collapseAppSection('sidebar'));
        }

        if (layout === "finder") {
            dispatch(expandAppSection('header'));
            dispatch(collapseAppSection('subview'));
            dispatch(expandAppSection('sidebar'));
        }

        if (layout === "finder/subview") {
            dispatch(expandAppSection('header'));
            dispatch(expandAppSection('subview'));
            dispatch(collapseAppSection('sidebar'));
        }

        if (layout === "upload") {
            dispatch(expandAppSection('header'));
            dispatch(expandAppSection('subview'));
            dispatch(collapseAppSection('sidebar'));
        }

        if (layout === "editor") {
            dispatch(collapseAppSection('header'));
            dispatch(collapseAppSection('subview'));
            dispatch(collapseAppSection('sidebar'));
        }

        dispatch(receiveAppLayout(layout));
      
    }
    
}

/*

export function getAppRoute(menuByUrl, pathname) {
    const parents = getParents(menuByUrl, pathname);

    return function(dispatch) {
        dispatch(receivePathname(pathname));
        dispatch(receiveParents(parents));
    }

}

export function appRoute(nav, pathname) {

  const parents = getParents(nav, pathname);
  const qs = getQueryString();
  

  return function(dispatch) {
    dispatch(requestPathname());
    dispatch(receivePathname(pathname));
    dispatch(requestParents());
    dispatch(receiveParents(parents));

  console.log('parents', parents)
    
    if (qs) {
      
      Object.keys(qs).forEach(key => {
        dispatch(receiveAppQuery(key, qs[key]));
      });
      
    }

  } 
  
}

export function appQuery(name, value) {
    
  return function(dispatch) {
    
    if (value) {
        dispatch(expandAppSection('search'));
        dispatch(receiveAppQuery(name, value));
    } else {
        dispatch(collapseAppSection('search'));
        dispatch(receiveAppQuery(name, ''));
    }
    
  } 
  
}

*/

export function appSubview(subview) {

  return function(dispatch) {
    dispatch(receiveAppSubview(subview));
  } 
  
}

export function appToggle(name) {

  return function(dispatch) {
    dispatch(toggleAppSection(name));
  }
    
}

export function appExpand(name) {

  return function(dispatch) {
    dispatch(expandAppSection(name));
  }
    
}

export function appCollapse(name) {

  return function(dispatch) {
    dispatch(collapseAppSection(name));
  }
    
}