import { API } from '../settings';
import { receiveMenuItemCount } from '../actions/nav';
import qs from 'query-string';

export function getNavCount({url, query}) {
    const { models } = query;
    
    const fetchUrl = API + '/admin/api/' + models + '/search?' + qs.stringify({...query, rows: 0});

    return function(dispatch) {
        fetch(fetchUrl, {
            method: "GET",
            headers: {
                "Accept": "application/json",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveMenuItemCount({url: url, count: data.count > 0 && data.count}))
        })
    }

}