import size from "./schemaModuleMediaSize"

export default {
    "type": "media",
    "properties": {
        "mediaFormat": {
            "type": "string",
            "default": "1:1"
        },
        "mediaLayout": {
            "type": "string",
            "default": "frame"
        },
        "content": {
            "type": "object",
            "properties": {
                "headline": {
                    "type": "string"
                },
                "description": {
                    "type": "string"
                },
                "creditLine": {
                    "type": "string"
                }
            }
        },
        "moduleSize": size
    }
}