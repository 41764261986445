import React, { Component } from 'react';
import "./DashboardSection.scss";

import { DashboardHeader, DashboardTitle, DashboardLabel } from "./"

class DashboardSection extends Component {
    
    renderHeader = () => {
        const { title, label } = this.props;
        
        if (title) {
            return (
                <DashboardHeader>
                    <DashboardTitle title={title} />
                </DashboardHeader>
            )
        }

        if (label) {
            return (
                <DashboardHeader>
                    <DashboardLabel label={label} />
                </DashboardHeader>
            )
        }
        
        return false;
        
    }
    
    render() {
        
        return (
            <section className="admin-dashboard__section">
                { this.renderHeader() }
                { this.props.children }
            </section>
        )

    }
}

export default DashboardSection;