import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAppLayout, appSubview } from '../redux/functions/app';
import { getNavParents, getNavItem, getNavQuery } from '../redux/functions/nav';

import { getFinderQuery } from '../redux/functions/finder';
import { getFinderSortOptions, getFinderViewOptions } from '../redux/functions/finder';
import { finderSort, finderView } from '../redux/functions/finder';
import { bulkReset } from '../redux/functions/bulk';

import { LayoutFinder } from '@frontend-components/admin';
import { AdminSidebar, AdminContent, AdminAction } from '@frontend-components/admin';


import FinderLayout from "./FinderLayout"
import FinderContent from "./FinderContent"
import FinderAction from "./FinderAction"

import FinderQuery from "./FinderQuery"
import FinderResults from "./FinderResults"
import FinderBulk from "./FinderBulk"

import qs from 'query-string';

import { useTranslation } from 'react-i18next';

const AppFinder = ({app, menuByUrl, ...props}) => {
    const { t, i18n } = useTranslation();

    // reset app
    useEffect(() => {
        props.bulkReset()
    }, [app.name])

    // get menu counts
    useEffect(() => {
        app.menu && app.menu.map(item => {
            item.query && props.getNavQuery(item)
        })
    }, [app.menu])

    // get parents
    const pathname = props.location.pathname

    useEffect(() => {
        props.getNavParents(menuByUrl, pathname)
    }, [pathname])

    // get menu query
    const itemByUrl = menuByUrl && menuByUrl[pathname]

    useEffect(() => {
        itemByUrl && itemByUrl.query && props.getNavQuery(itemByUrl)
    }, [pathname])

    // parse url querey
    let sq = props.location && props.location.search && qs.parse(props.location.search) || {}

    const handleSetting = (name, value) => {
        const query = qs.stringify({
            ...sq,   
            [name]: value
        })

        const url = props.location.pathname + "?" + query;
        
        props.history.replace(url)
    }

    let settings = []

    const { rowsOptions, layoutOptions, sortOptions } = props;

    if (layoutOptions) {

        settings.push({
            "name": "layout",
            "value": sq.layout || undefined,
            "onChange": handleSetting,
            "options": layoutOptions.map(value => {
                return {
                    label: t("layout:"+value || value),
                    value: value
                }
            })

        })

    }

    if (sortOptions) {

        settings.push({
            "name": "sort",
            "value": sq.sort || undefined,
            "onChange": handleSetting,
            "options": sortOptions.map(value => {
                return {
                    label: t("sort:"+value || value),
                    value: value
                }
            })

        })

    }

    if (rowsOptions) {

        settings.push({
            "name": "rows",
            "value": sq.rows || undefined,
            "onChange": handleSetting,
            "options": rowsOptions.map(value => {
                return {
                    label: t("rows:"+value || value),
                    value: value
                }
            })

        })

    }

    // bulk
    
    const { bulkCount } = props;

    const resetBulk = () => {
        props.getAppLayout('finder');
        props.bulkReset()
    }

    const getBulkAction = () => {
        if (bulkCount) {
            props.getAppLayout('finder/subview');
            props.appSubview({
                expanded: true,
                title: "Bulk",
                subtitle: bulkCount + " selected",
                onBack: () => resetBulk()
            })

        } else {
            props.getAppLayout('finder');
            props.appSubview(undefined)
        }
    }    

    const handleSelect = ({url}) => {
        if (bulkCount) {
            props.getAppLayout('finder');
            props.bulkReset()
        }
        if (url) {
            props.history.push(url);
        }
    }    

   
    useEffect(() => {
        getBulkAction()
    }, [bulkCount])

    const { finder, search, query, schema, children } = props;

    // app props

    const parents = app && app.parents
    const menu = app && app.menu
//    const menuByUrl = props && props.menuByUrl
    const primaryAction = app && app.primaryAction || app && app.edit && app.edit[0]

    // bulk expanded?

    return (
        <React.Fragment>
            <FinderContent expanded={!bulkCount}>
                <FinderLayout settings={settings} menu={menu} menuByUrl={menuByUrl} primaryAction={primaryAction} search={search} parents={parents} onSelect={handleSelect}>
                    { children || <FinderQuery {...props} query={query} schema={schema} /> }
                </FinderLayout>
            </FinderContent>
            <FinderAction expanded={bulkCount && true}>
                <FinderBulk {...props} query={query} />
            </FinderAction>
        </React.Fragment>
    )

}

AppFinder.defaultProps = {
    schema: undefined,
    query: undefined,
    sortOptions: undefined,
    sort: undefined,
    viewOptions: undefined,
    view: undefined
}

function mapStateToProps(state) {
	return {
        app: state.app,
        searchById: state.searchById,
        menuByUrl: state.menuByUrl,
        finder: state.finder,
        bulkCount: state.bulk.count,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getAppLayout, appSubview,
    getNavParents, getNavItem, getNavQuery,
    getFinderQuery,
    getFinderSortOptions, getFinderViewOptions,
    finderView, finderSort,
    bulkReset
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppFinder);