import MediaChildrenField from "./MediaChildrenField"
import MediaArtworkField from "./MediaArtworkField"
import MeiaAudiotrackField from "./MediaAudiotrackField"
import MediaCaptionsField from "./MediaCaptionsField"

export default {
    "fieldtypes": {
    },
    "fields": {
        "mediaChildren": MediaChildrenField,
        "mediaArtwork": MediaArtworkField,
        "mediaAudiotrack": MeiaAudiotrackField,
        "mediaCaptions": MediaCaptionsField,
    },
    "layouts": {
    },
    "widgets": {
    }
}