import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserAuth, setAppAuth } from "../redux/functions/auth"
import { getPreview } from '../redux/functions/schema';
import { AuthModule } from '@frontend-components/admin';

import schema from "./schemas/site/"

class Module extends Component {

    static defaultProps = {
        urlPattern: "/admin/auth/site/:siteId",
        editUrlPattern: "/admin/auth/site/:uniqueId/edit",
        action: ['edit']
    }

    onAddUser = () => {
        const { rolesOptions } = this.props
        
        const defaultValue = rolesOptions[rolesOptions.length-1].value;
        
        this.onAuthUser(defaultValue);
    }

    onRemoveUser = () => {
        this.onAuthUser(undefined);
    }
    
    onAuthUser = (authId) => {
        const { collectionId, siteId, userId } = this.props;

        if (collectionId && userId) {
            this.props.setUserAuth(userId, {
                collectionId: collectionId,
                roleId: authId
            })
        } else if (siteId && userId) {
            this.props.setUserAuth(userId, {
                siteId: siteId,
                roleId: authId
            })
        }
        
    }
    
    onAuthSite = (authId) => {
        const { collectionId, collectionType, siteId } = this.props;
        
        if (collectionType && siteId) {
            this.props.setAppAuth(collectionId, {
                siteId: siteId,
                collectionType: collectionType,
                status: authId
            })

        }
        
    }
    
    getAuth = () => {
        let { rolesOptions, roleId, userId, siteName, siteId, collectionType, status, action } = this.props;

        // user

        const site = getPreview(schema, {
            ...this.props
        });
        
        let authOptions, authValue;
        
        if (userId && rolesOptions && roleId) {
            authOptions = rolesOptions
            authValue = roleId || undefined
        } else if (siteId && collectionType) {

            if (!status || status !== "publish") {
                authOptions = [
                    {
                        label: "Activate",
                        value: "publish"
                    }
                ]
            } else {
                authOptions = [
                    {
                        label: "Active",
                        value: "publish"
                    },
                    {
                        label: "Deactivate",
                        value: "trash"
                    }
                ]
            }

            authValue = status || undefined
            action = []
            
        }

        // metadata or label

        let metadata, label;
        
        if (!authOptions) {
            metadata = this.getMetadata()
        } else {
            label = this.getLabel()
        }

        return {
            ...site,
            authType: "site",
            authOptions: authOptions,
            authValue: authValue,
            label: label,
            metadata: metadata,
            action: action
        }        
        
    }

    getMetadata = () => {
        const { authApps, authCollections, authUsers } = this.props;

        const metadata = []
        
        const appsCount = authApps && authApps.length;
        
        if (appsCount > 1) {
            metadata.push(appsCount + " apps")
        } else if (appsCount) {
            metadata.push(appsCount + " app")
        }

        const collectionsCount = authCollections && authCollections.length;
        
        if (collectionsCount > 1) {
            metadata.push(collectionsCount + " collections")
        } else if (collectionsCount) {
            metadata.push(collectionsCount + " collections")
        }

        const usersCount = authUsers && authUsers.length;
        
        if (usersCount > 1) {
            metadata.push(usersCount + " users")
        } else if (usersCount) {
            metadata.push(usersCount + " users")
        }
        
        return metadata;
        
    }

    getLabel = () => {
        const { userId, appName, siteName } = this.props;
        
        let labels = []

        if (userId) {
            labels.push("User")
        }
        
        if (siteName) {
            labels.push(siteName)
        }

        if (appName) {
            labels.push(appName)
        }
        
        return labels.join('/')
        
    }
    
    render() {
        const site = this.getAuth()
        
        const { userId } = site;
        
        if (userId) {

            return (
                <AuthModule {...site} onAuth={this.onAuthUser} onAdd={this.onAddUser} onRemove={this.onRemoveUser} />
            )
            
        }
        
        return (
            <AuthModule {...site} onAuth={this.onAuthSite} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
        rolesOptions: state.auth.rolesOptions
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      setUserAuth, setAppAuth
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Module);