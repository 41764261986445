import React, { Component } from 'react';
import Module from './TemplateDashboardSectionModule';

import { FinderSection } from '@frontend-components/admin';

class TemplateDashboardSectionResults extends Component {
  
  static defaultProps = {
    results: []
  }
  
  renderItem = (item, index) => {
    
    const { uniqueId } = item;
    
    if (!uniqueId) {
      return false
    }
    
    return (
      <Module { ...this.props } item={ item } key={ index } />
    )
    
  }

  render() {
    
    const { results } = this.props;

    return (
      <FinderSection { ...this.props } >
        { results.map( this.renderItem ) }
      </FinderSection>
    )

  }

}

export default TemplateDashboardSectionResults;
