import editChild from './editChild';

let oneOf = editChild.dependencies.type.oneOf;

oneOf.push({
    "properties": {
        "type": {
            "enum": ["group"]
        },
        "children": {
            "type": "array",
            "items": editChild
        }
    }
})

export default {
    "type": "object",
    "properties": {
        "title": {
            "type": "string",
        },
        "type": {
            "type": "string",
            "enum": ["group", "create", "upload"],
            "default": "create"
        }
    },
    "dependencies": {
        "type": {
            oneOf: oneOf
        }
    }
}    