import React, { Component } from 'react';
//import "./AppBar.scss";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: 10,
        height: "64px",
        top: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        marginTop: "-64px",
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
        "& > * ": {
            marginLeft: theme.spacing(.5),
            marginRight: theme.spacing(.5),
        },
        "&[aria-expanded=true]": {
            marginTop: 0
        }
    }
}));

const AdminNav = ({expanded, children}) => {
    const classes = useStyles();

    return (
        <nav className={classes.root} aria-expanded={expanded}>
            { children }
        </nav>
    )

}

export default AdminNav;