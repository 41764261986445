import icalToolkit from 'ical-toolkit';
import moment from 'moment';
import _ from "lodash"

export const getIcalEvent = ({vEvent, query: {year, month, date}}) => {

    if (!year) {
        year = moment().format('YYYY')
    }

    if (!month) {
        month = moment().format('MM')
    }

    if (!date) {
        month = moment().format('DD')
    }

    let dtStart, dtEnd, title, rrule

    Object.keys(vEvent).map(key => {

        if (key.startsWith('DTSTART')) {
            dtStart = vEvent[key]
        }

        if (key.startsWith('DTEND')) {
            dtEnd = vEvent[key]
        }

        if (key.startsWith('SUMMARY')) {
            title = vEvent[key]
        }

        if (key.startsWith('RRULE')) {
            rrule = vEvent[key]
        }

    })

    let rrules = rrule && rrule.split(';')

    let rules = {}

    rrules && rrules.map(rule => {
        const split = rule.split('=')

        const name = split[0]
        const value = split[1]

        rules[name] = value
        
    })

    if (rules['FREQ']) {

        if (rules['FREQ'] === "YEARLY") {
            dtStart = year + moment(dtStart).format('-MM-DD')
            dtEnd = year + moment(dtEnd).format('-MM-DD')
        }

    }

    dtStart = year + moment(dtStart).format('-MM-DD')
    dtEnd = year + moment(dtEnd).format('-MM-DD')

    return {
//        ...vEvent,
        rrule: rrule,
        rules: rules,
        dtStart: dtStart,
        dtEnd: dtEnd,
        title: title
    }

}

export const getIcalEventsByDate = ({ics, query}) => {

    const data = icalToolkit.parseToJSON(ics);

    const vCalendars = data && data.VCALENDAR 

    let eventsByDate = {}

    vCalendars && vCalendars.map(vCalendar => {

        const vEvents = vCalendar && vCalendar.VEVENT

        const sortedEvents = _.orderBy(vEvents, 'DTSTART', 'asc'); // Use Lodash to sort array by 'name'

        sortedEvents && sortedEvents.map(vEvent => {

            const event = getIcalEvent({vEvent, query})

            const { dtStart } = event

            if (!eventsByDate[dtStart]) {
                eventsByDate[dtStart] = []
            }

            eventsByDate[dtStart].push(event)
            
        })

    })
    


    return eventsByDate


}