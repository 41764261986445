import openinghoursDays from './openinghoursDays';

export default {
    type: "array",
    items: {
        type: "object",
        default: {
            days: [
                {
                    day: "1",
                    open: true
                },
                {
                    day: "2",
                    open: true
                },
                {
                    day: "3",
                    open: true
                },
                {
                    day: "4",
                    open: true
                },
                {
                    day: "5",
                    open: true
                },
                {
                    day: "6",
                    open: false
                },
                {
                    day: "0",
                    open: false
                }
            ],
        },
        properties: {
            title: {
                type: "string"
            },
            days: openinghoursDays,
            location: {
                type: "object",
                properties: {
                    placeId: {
                        type: "string"
                    },
                    place: {
                        type: "object",
                        properties: {
                            title: {
                                type: "string"
                            },
                            address: {
                                type: "string"
                            },
                            lat: {
                                type: "string"
                            },
                            lng: {
                                type: "string"
                            }
                        }
                    },
                    title: {
                        type: "string"
                    }
                }
            },
            dateFrom: {
                type: "string",
                format: "date"
            },
            dateTo: {
                type: "string",
                format: "date"
            }
        }
    }
}