import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppFinder from '../AppFinder';

class UserAdmin extends Component {

    static defaultProps = {
        query: {
            pathname: "/admin/user",
            models: "users",
            status: "NOT trash",
//            fl: "imageUrl,title,description,uniqueId,status,createdAt,createdByName,updatedAt,updatedByName,deletedAt,deletedByName"
        }
    }

    render() {

        return (
            <AppFinder {...this.props} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAdmin);
