import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import CollectionRoutes from "../AppCollection/CollectionRoutes"
import MediaEditor from "../AppMedia/MediaEditor"


import AppLoader from "../App/AppLoader"
import KioskEditor from "./KioskEditor"
import KioskImport from './KioskImport';

const KioskRoutes = ({site, collection, app, ...props}) => {
  
    if (!site.uniqueId || !collection.uniqueId || !app.uniqueId) {
        return <AppLoader site={site} collection={collection} app={app}></AppLoader>
    }

    return (
        <Switch>
            <Route path="/:siteName/:appName/media/:parents*/:uniqueId/edit/:referenceId*" component={ MediaEditor } />
            <Route path="/:siteName/:appName/:documentType*/<new>/edit" component={ KioskEditor } />
            <Route path="/:siteName/:appName/:parents*/:uniqueId/edit/:referenceId*" component={ KioskEditor } />
            <Route path="/:siteName/:appName/:parents*/" component={ CollectionRoutes } />
            <Route path="/:siteName/:appName" component={ CollectionRoutes } />
        </Switch>
    )

}

const mapStateToProps = (state) => {
	return {
    	site: state.site,
        collection: state.collection,
        app: state.app
	};
}  

export default connect(
    mapStateToProps,
)(KioskRoutes);