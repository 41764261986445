import metadata from "./schemaMetadata"
import sections from './schemaSections'

export default {
    "type": "object",
    "properties": {
        "location": {
            "type": "object",
            "properties": {
                "placeId": {
                    "type": "string",
                    "readonly": true
                },
                "lat": {
                    "type": "number"
                },
                "lng": {
                    "type": "number"
                },
                "zoom": {
                    "type": "number"
                },
                "address": {
                    "type": "string"
                },
                "country": {
                    "type": "string"
                }
            }
        },
//        "metadata": metadata,
        "header": {
            "type": "object",
            "properties": {
                "image": {
                    "type": "image"
                },
                "title": {
                    "type": "localizedString"
                },
                "leadtext": {
                    "type": "localizedString"
                }
            }
        },
        "sections" : sections,
        "isPartOf": {
            "type": "array",
            "items": {
                "type": "reference",
                "documentType": "topic",
                "referenceType": "articleIsPartOf",
                "_reverseReferenceType": "topicHasArticles",
                "_reverseReference": "hasArticles[]"
            }
        }
    }
}