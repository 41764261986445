import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModuleBase from "./ModuleBase"
import ModuleBaseLink from "./ModuleLink"
import ModuleActionPrimary from "./ModuleActionPrimary"
import ModuleActionbar from "./ModuleActionbar"


import ModuleMedia from './ModuleMedia';
import ModuleTitle from './ModuleTitle';
import ModuleLabel from './ModuleLabel';
import ModuleMetadata from './ModuleMetadata';
import ModuleDescription from './ModuleDescription';
import ModuleStatus from './ModuleStatus';
import ModuleByline from './ModuleByline';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        display: "flex",
        width: "100%",
        minHeight: theme.spacing(8),
        justifyContent: "flex-start",
        alignItems: "center",
        userSelect: "none",

        "&[role=button]": {
            cursor: "pointer",

            "& *": {
                pointerEvents: "none"
            },

        },

        "&[data-elevated=true]": {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },

        "& + $root": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider
        },

        "&.sortable-helper": {
            borderTopColor: "transparent",
            boxShadow: theme.shadows[12],
            zIndex: 2000,
            "& $trailingIcons": {
                display: "none"
            }
        },

        "&[data-status=erased]": {

            "& figure": {
                opacity: "0.5"
            },

            "& $content": {
                opacity: "0.5"
            }

        },

        "&[data-status=trash]": {

            "& figure": {
                opacity: "0.5"
            },

            "& $content": {
                opacity: "0.5"
            }

        },

    },
    item: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        userSelect: "none",
    },
    actionbar: {
        zIndex: 3,
        display: "flex",
    },
    body: {
    },
    media: {
        display: "flex",
    },
    content: {
        width: "100%",
        maxWidth: "100%",
        flexDirection: "column",
        overflow: "hidden",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        margin: theme.spacing(1)
    },
    header: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        "& * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
    footer: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        "& * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
    uploadProgress: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    progress: {
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
//        opacity: 0.25,
        height: theme.spacing(.25),
        top: "auto",
        right: "auto",
        bottom: 0,
        left: 0,
        width: props => { return props.uploadProgress + "%" }
    }

}));

const ListModule = ({uploadProgress, imageUrl, untitled, typeLabel, type, title, description, metadata, status, statusLabel, author, datetime, onClick, onEdit, ...props }) => {
    const classes = useStyles({uploadProgress: uploadProgress})

    return (
        <ModuleBase {...props} className={classes.root} status={status} onClick={onClick}>
            <div className={classes.item}>
                <ModuleActionPrimary {...props} />
                <div className={classes.media}>
                    <ModuleMedia imageUrl={imageUrl} width="48px" height="48px" />
                </div>
                <div className={classes.content}>
                    <header className={classes.header}>
                        <ModuleTitle status={status} untitled={untitled} title={title} onClick={!onClick && onEdit} />
                        <ModuleStatus statusLabel={statusLabel} status={statusLabel || status} />
                        <ModuleByline author={author} datetime={datetime} />
                    </header>
                    <footer className={classes.footer}>
                        <ModuleLabel label={typeLabel || type} />
                        <ModuleMetadata metadata={metadata} />
                        <ModuleDescription description={description} />
                    </footer>
                </div>
                { uploadProgress && 
                    <div className={classes.uploadProgress}>
                        <div className={classes.progress}></div>
                    </div>                
                }
                <ModuleActionbar {...props} className={classes.actionbar} />
            </div>
        </ModuleBase>
    )    

}

ListModule.propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    typeLabel: PropTypes.string,
    metadata: PropTypes.array,
    status: PropTypes.string,
    statusLabel: PropTypes.string,
    author: PropTypes.string,
    datetime: PropTypes.string,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
}

ListModule.defaultProps = {
    size: "small"
}

export default ListModule;
