import React from "react";
import { Museum24Icon as icon } from '@frontend-components/admin/es/resources/icons';

export default {
    sortOrder: 1.2,
    uniqueId: "app/m24",
    appType: "site/product",
    collectionType: "m24",
    name: "m24",
    icon: icon,
    title: "Museum24"
};