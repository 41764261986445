import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import PreviewLabel from "./PreviewLabel"

// Component

class PreviewLocalizedString extends React.Component {

  render() {
      
    const { formData, language, className } = this.props;
    
    if (!language) {
      return false;
    }

    if (!formData) {
      return false;
    }
    
    const localeId = "locale:" + language;
    const localizedString = formData[localeId] || "";
    
    const labelType = "localizedString:" + language;
    
    return (
        <div className={className}>
            <PreviewLabel {...this.props} type={labelType} />
          <ReactMarkdown className="field__string" source={ localizedString } />
      </div>
    )
  
  }
  
}

export default PreviewLocalizedString;