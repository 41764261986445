import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel, loadModel, editModel, saveModel } from '../redux/functions/model';
import { getSearch } from '../redux/functions/search';

import FinderSection from '../components/Finder/FinderSection';

import AppFinder from "../AppFinder"
import FinderModel from '../AppFinder/FinderModel';

const CollectionSchemas = ({models = [], ...props}) => {

    const editUrlPattern = "/admin/schema/:uniqueId/edit"

    if (!models.length) {
        return (
            <p>Loading schemas ....</p>
        )
    }
    
    const title = models.length + " schemas"

    return (
        <FinderSection title={title}>
            {models.map((model, index) => {
                return (
                    <FinderModel model={model} layout="list" editUrlPattern={editUrlPattern} {...props} key={index} />
                )
            })}
        </FinderSection>
    )

}

const CollectionSettings = ({collection, searchById, ...props}) => {

    const collectionId = collection && collection.id

    const query = {
        id: props.location.pathname + "/schemas",
        models: "schemas",
        status: "NOT trash",
        collectionId: collectionId,
        fl: "id,uniqueId,title"
    }

    useEffect(() => {
        props.getSearch(query)
    }, collectionId)

    const schemaSearch = searchById && searchById[query.id] && searchById[query.id]

    const collectionUrl = "/admin/collection/" + collection.uniqueId
    const collectionEditUrl = collectionUrl + "/edit"

   
    return (
        <AppFinder {...props}>

            <FinderSection>
                { collection && <FinderModel {...props} layout="list" model={collection} editUrl={collectionEditUrl} /> }
            </FinderSection>

            <CollectionSchemas {...props} {...schemaSearch} />

        </AppFinder>
    )

}

function mapStateToProps(state) {
	return {
        collection: state.collection,
        searchById: state.searchById
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getModel,
        getSearch
    }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionSettings);
