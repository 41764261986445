import { requestFinder, receiveFinder } from "../actions/finder"
import { requestFilter, receiveFilter } from '../actions/filter';

import { requestFinderQuery, receiveFinderQuery } from '../actions/finder';

import { requestFinderResults, receiveFinderResults } from '../actions/finder';
import { receiveBulkChange, receiveBulkToggle } from '../actions/finder';
import { receiveModelChange, receiveModelToggle } from '../actions/finder';

import { receiveFinderViewOptions, receiveFinderSortOptions } from '../actions/finder';
import { receiveFinderView, receiveFinderSort } from '../actions/finder';

export function getFinder(finder) {
    return function(dispatch) {
        dispatch(requestFinder())
        dispatch(receiveFinder(finder))
    }
}

export function getFinderViewOptions(options) {
    return function(dispatch) {
        dispatch(receiveFinderViewOptions(options))
    }
}

export function finderView(view) {
    return function(dispatch) {
        dispatch(receiveFinderView(view))
    }
}

export function getFinderSortOptions(options) {
    return function(dispatch) {
        dispatch(receiveFinderSortOptions(options))
    }
}

export function finderSort(sort) {
    return function(dispatch) {
        dispatch(receiveFinderSort(sort))
    }
}

export function getFinderQuery(query) {
    return function(dispatch) {
        dispatch(requestFinderQuery())
        dispatch(receiveFinderQuery(query))
    }
}

export function getFinderResults(results) {
    return function(dispatch) {
        dispatch(requestFinderResults())
        dispatch(receiveFinderResults(results))
    }
}

export function addFinderResults(results) {
    return function(dispatch) {
        dispatch(receiveFinderResults(results))
    }
}

export function finderFilter(pathname, name, value) {

  return function(dispatch) {
    
    if (value) {
      dispatch(receiveFilter(pathname, name, value));
    } else {
      dispatch(requestFilter(pathname));
    }
    
  } 
  
}

export function bulkToggle() {
    return function(dispatch) {
        dispatch(receiveBulkToggle())
    }
}

export function bulkSelectAll() {
    return function(dispatch) {
        dispatch(receiveBulkChange({'selected': true}))
    }
}

export function bulkUnselectAll() {
    return function(dispatch) {
        dispatch(receiveBulkChange({'selected': false}))
    }
}

export function modelChange(id, data) {
    return function(dispatch) {
        dispatch(receiveModelChange(id, data))
    }
}

export function modelToggle(id) {
    return function(dispatch) {
        dispatch(receiveModelToggle(id))
    }
}