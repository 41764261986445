import React, { Component } from 'react';
import { getUiOptions, getDefaultFormState } from "../Schema/utils"
import { getCurrentLocale } from "./utils"

class LocalizedStringField extends Component {

    static defaultProps = {
       
    }

    state = {
        expanded: false 
    }

    onToggle = () => {
        this.setState(prevState => ({
            expanded: !prevState.expanded
        }))
    }

    onChange = (formData) => {
        const { schema } = this.props;

        const newFormData = getDefaultFormState(schema, formData)
        
        console.log('LocalizedString:onChange', newFormData)
        
        if (this.props.onChange) {
            this.props.onChange(newFormData);
        }
        
    }

    getUiSchema = () => {
        const { schema, uiSchema } = this.props;

        const currentLocale = getCurrentLocale(this.props)
        const uiOptions = getUiOptions(uiSchema)

        let localizedUiSchema = {
            "ui:fieldset": [
                "locale:"+currentLocale
            ]
        }
    
        Object.keys(schema.properties).map(name => {
            const localeProps = name.split(':')
            const locale = localeProps[1]

            const title = uiOptions.title && uiOptions.title + ":" + locale;
            const help = uiOptions.help;

            localizedUiSchema[name] = {
                "ui:options": {
                    "placeholder": uiOptions.placeholder,
                    "widget": uiOptions.widget || "text",
                    "title": title, 
                    "language": locale,
                    "help": help
                },
            }
        
        })
    
        return {
            ...localizedUiSchema,
            "ui:layout": "field",
            "ui:collapsible": true,
            "ui:expanded": false
        }
        
    }

    getField = () => {
        const uiSchema = this.getUiSchema()

        return {
            ...this.props,
            uiSchema: uiSchema
        }

    }

    render() {
        const { schema, uiSchema, formData, registry } = this.getField();
        const { ObjectField } = registry.fields;

        return (
            <ObjectField {...this.props} schema={schema} uiSchema={uiSchema} formData={formData} onChange={this.onChange} />
        )

        return (
            <div>

            <p>{JSON.stringify(uiSchema)}</p>
            <ObjectField {...this.props} schema={schema} uiSchema={uiSchema} formData={formData} onChange={this.onChange} />
            </div>
        )

    
    }

}

export default LocalizedStringField;