import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "white",
        color: "black",
    },
    section: {
        height: theme.spacing(8),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    button: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        color: "inherit",

        "& + $content": {
            paddingRight: theme.spacing(8)
        }

    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "18px",
        fontWeight: "bold",
        marginLeft: theme.spacing(1),
    },
    subtitle: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "18px",
        fontWeight: "normal",
        marginLeft: "0.25em"
    }
}));

const AppSubview = ({className, expanded, title, subtitle, onBack}) => {
    const classes = useStyles()

    return (
        <div className={className || classes.root} aria-expanded={expanded}>
            <div className={classes.section}>
                <IconButton className={classes.button} onClick={onBack}>
                    <BackIcon />
                </IconButton>
                <div className={classes.content}>
                    <Typography component="h1" className={classes.title}>{title && subtitle && title + ":" || title}</Typography>
                    <Typography component="h2" className={classes.subtitle}>{subtitle}</Typography>
                </div>
            </div>
        </div>
    )

}

export default AppSubview;