import React, { Component } from 'react';
import AdminTheme from './AdminTheme';

import '../../resources/fonts/Akkurat.scss';
import '../../resources/fonts/AkkuratMono.scss';
import '../../resources/fonts/MaterialIcons.scss';
import "./Admin.scss";

/**
 * Admin wrapper component.
 */

class Admin extends Component {

    render() {
        const { theme } = this.props;

        if (theme) {
            return (
                <div className="admin">
                    <AdminTheme theme={theme}>
                        {this.props.children}
                    </AdminTheme>
                </div>
            )
        }

        return (
            <div className="admin">
                {this.props.children}
            </div>
        )
    }
}

export default Admin;