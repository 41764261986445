import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import UploadDropzone from "../components/Upload/UploadDropzone"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: theme.spacing(16),
        border: "1px solid",
        borderColor: theme.palette.divider,
        marginBottom: props => { return theme.spacing(props.spacing)},
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        fontWeight: "normal"
    },
}));

const ResultsHeader = ({spacing = 2, requested = 0, received = 0, onUpload}) => {
    const { t, i18n } = useTranslation('upload');

    let title

    if (requested > 0 && requested < received) {
        title = t('Uploading {{requested}} of {{received}}', { requested, received }) + "...";
    } else {
        title = t('Click or drop files to upload');
    }
    
    const classes = useStyles({spacing})

    const accept = [
        "image/*",
        "video/*",
        "audio/*",
        "application/pdf"
    ]

    return (
        <UploadDropzone className={classes.root} accept={accept} onUpload={onUpload}>
            <Typography className={classes.title}>{title}</Typography>
        </UploadDropzone>
    )

}

export default ResultsHeader