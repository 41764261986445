import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Typography from "@material-ui/core/Typography"
import UploadButton from "./MediaButtonUpload"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        border: "1px solid",
        borderColor: "#999",
        borderRadius: 0,
        padding: theme.spacing(1.5),
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        textTransform: "capitalize",
        fontSize: "14px",
        lineHeight: "1",
        fontWeight: "bold",
        width: "100%",
    }
}));

const MediaButton = ({ buttonType, icon = "add", label, type, link, onClick, onUpload, ...props }, index) => {
    const classes = useStyles()

    if (onUpload) {
        return (
            <UploadButton {...props} onUpload={onUpload} className={classes.root}>
                <Typography nowrap className={classes.label}>{label}</Typography>
            </UploadButton>
        )
    }

    return (
        <ButtonBase className={classes.root} onClick={onClick}>
            <Typography className={classes.label}>{label}</Typography>
        </ButtonBase>
    )

}

export default MediaButton;