import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AnalyticsHomeDefault from "./AnalyticsHomeDefault"
import AnalyticsHomeDm from "./AnalyticsHomeDm"

// ?countryId=no&offset=0&owner=1,2,3,4&reportType=0&rows=50&startDate=2019-07-01,2018-07-01&endDate=2019-07-01,2018-07-01&orderBy=nthDay&metrics=uniquePageviews,users,sessions&dimensions=dimension1,dimension2,dimension3,dimension4,nthDay&sort=descending

class AnalyticsHome extends Component {
    
    render() {
        const { app } = this.props;

        /*
        
        if (app.name === "dm") {
            return (
                <AnalyticsHomeDm {...this.props} />
            )
            
        }
        */

        return (
            <AnalyticsHomeDefault {...this.props} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsHome);