export const getCollectionAction = ({documentTypes, root, ...props}) => {

    let groupCount = 1
    let groupsById = {
        1: []
    }

    documentTypes.length && documentTypes.map((item, index) => {

        const { role, hidden, model, icon, label, uniqueId } = item;

        if (hidden) {
            return
        }

        const { name } = model;

        const title = label || "New " + name

        const url = name && root + "/" + name + "/<new>/edit"

        if (role === "split" || role === "group") {
            groupCount++;
        }

        if (!groupsById[groupCount]) {
            groupsById[groupCount] = []
        }

        groupsById[groupCount].push({
            title: title,
            icon: icon,
            name: name,
            url: url
        })

        if (role === "group") {
            groupCount++;
        }


    })

    let children = []

    Object.values(groupsById).map(group => {
        children.push({
            role: "group",
            children: group
        })
    })

    children.push({
        role: "group",
        url: root + "/upload",
        icon: "cloud_upload",
        title: "Last opp"
    })

    return {
        title: "Create",
        children: children
    }

}

export default getCollectionAction