import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import "./FieldRange.scss"

class FieldRange extends Component {
    static defaultProps = {
        value: "",
        required: false,
        autofocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    }

    onChange = (event, value) => {
        this.setState({
            value: value
        });

        if (this.props.onChange) {
            this.props.onChange(value, event);
        }
    }
    
    getAriaValue = (value) => {
        return value
    }

    render() {
        const { id, disabled, readonly, value, step, min, max, marks } = this.props;

        return (
            <div className="admin-field  field--range">
                <Slider
                    defaultValue={value}
                    getAriaValueText={this.getAriaValue}
                    aria-labelledby={id}
                    valueLabelDisplay="auto"
                    step={step}
                    marks={marks}
                    min={min}
                    max={max}
                    onChange={(event, value) => this.onChange(event, value)}
                />
            </div>
        )
    }
}

export default FieldRange;