export const getPrimaryAction = (app) => {
    const { root } = app;

    return {
        title: "Nytt innhold",
        icon: "",
        children: [
            {
                title: "Tema",
                url: root + "/topic/<new>/edit",
            },
            {
                title: "Spørreskjema",
                url: root + "/questionnaire/<new>/edit",
            },
            {
                title: "Artikkel",
                url: root + "/article/<new>/edit",
            },
            {
                "role": "group",
                "children": [
                    {
                        title: "Liste",
                        url: root + "/page/list/<new>/edit",
                    },
                    {
                        title: "Mosaikk",
                        url: root + "/page/grid/<new>/edit",
                    },
                    {
                        title: "Tidslinje",
                        url: root + "/page/timeline/<new>/edit",
                    },
                    {
                        title: "Bilde, video og lyd",
                        url: root + "/page/media/<new>/edit",
                    },
                    {
                        title: "Bildekart",
                        url: root + "/page/annotate/<new>/edit",
                    }
                ]
            },
            {
                role: "group",
                title: "Last opp",
                icon: "cloud_upload",
                url: root + "/media/upload",
                action: {
                    actionType: "upload"
                }
            }
        ]
    }

}