import React from 'react';
import SectionTheme from "./SectionTheme"
import SectionBase from "./SectionBase"
import SectionHeader from "./SectionHeader"
import SectionBody from "./SectionBody";
import SectionFooter from "./SectionFooter"
import Collapse from '@material-ui/core/Collapse';

const LayoutSection = ({expanded = true, position = "relative", collapsible, title, description, buttons, children, ...props}) => {

    if (collapsible) {

        return (
            <SectionBase position={position} collapsible={collapsible} expanded={expanded}>
                <SectionHeader {...props} title={title} description={description} collapsible={collapsible} expanded={expanded} />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <SectionBody position={position} {...props}>
                        { children }
                    </SectionBody>
                    { expanded && <SectionFooter {...props} buttons={buttons} /> }
                </Collapse>
            </SectionBase>
        )
            
    }

    return (
        <SectionBase position={position} collapsible={collapsible} expanded={expanded}>
            <SectionHeader {...props} title={title} description={description} collapsible={collapsible} expanded={expanded} />
            <SectionBody position={position} {...props}>
                { children }
            </SectionBody>
            { expanded && <SectionFooter {...props} buttons={buttons} /> }
        </SectionBase>
    )

}

LayoutSection.defaultProps = {
    padding: 2,
    spacing: 0
}

export default LayoutSection;