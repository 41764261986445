import uiPreview from "./uiPreview"

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "ui:fieldset": [
        "content"
    ],
    "content": {
        "ui:field": "pageContent",
        "ui:nav": true,
        "ui:fieldset": [
            "ownerInfo"
        ],
        "ownerInfo": {
            "ui:layout": "section",
            "ui:title": "Eier",
            "ui:fieldset": [
                "owner",
                "ownerName"
            ],
            "ui:options": {
                "spacing": 2
            }
        }
    }
}