import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"

import AppTheme from "./AppTheme"
import AppBase from "./AppBase"
import AppNav from "./AppNav"
import AppHeader from "./AppHeader"
import AppBody from "./AppBody"
import AppSidebar from "./AppSidebar"
import AppContent from "./AppContent"

import NavAction from "../NavAction/NavAction"
import NavMenu from "../NavMenu/NavMenu"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    header: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: 'auto',
        left: 0,
        height: theme.spacing(8),
        overflow: "hidden",
        marginTop: theme.spacing(-8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginTop: 0            
        }
    },
    nav: {
        position: "absolute",
        zIndex: 2000,
        top: 0,
        right: 0,
        marginTop: theme.spacing(-8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginTop: 0            
        },
        height: theme.spacing(8),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",

        "& > button": {
        }
    },
    body: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        marginTop: theme.spacing(8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginTop: 0            
        }
    },
    sidebar: {

    },
    sidebarAction: {
        position: "relative",
        zIndex: 2,
        margin: "24px"
    },
    sidebarMenu: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        paddingTop: "84px",
        marginRight: "16px",
        marginLeft: "0",
        overflowY: "scroll"	
    }
}));

const LayoutApp = ({theme, app = {}, menuByUrl = {}, user, owner, nav = {}, header = {}, sidebar = {}, search = {}, subview = {}, onToggle, onSelect, onLogin, onLogout, children}) => {

    header = {
        title: app && app.title,
        subtitle: owner && owner.title || user && user.name,
        ...header
    }

    sidebar = {
        ...app.sidebar,
        ...sidebar,
    }

    const primaryAction = app && app.primaryAction
    const menu = app && app.menu

    search = {
        ...app.search,
        ...search
    }

    nav = {
        app: app,
        user: user,
        owner: owner,
    }

    if (search && search.expanded) {
        nav.expanded = false
    }

    if (subview && subview.expanded) {
        nav.expanded = false
    }

    const classes = useStyles({header})

    return (
        <AppTheme theme={theme || app && app.theme}>
            <AppBase>
                <AppHeader className={classes.header} {...header} search={search} subview={subview} onToggle={onToggle}>
                    <AppNav {...nav} onLogin={onLogin} onLogout={onLogout} onSelect={onSelect} />
                </AppHeader>
                <AppBody className={classes.body} expanded={header && !header.expanded && true}>
                    { sidebar && <AppSidebar {...sidebar}>
                        { primaryAction && <NavAction className={classes.sidebarAction} primaryAction={primaryAction} placement="bottom-start" onSelect={onSelect} /> }
                        { menu && <NavMenu className={classes.sidebarMenu} menu={menu} menuByUrl={menuByUrl} onSelect={onSelect} /> }
                        { sidebar.children && sidebar.children }
                    </AppSidebar> }
                    <AppContent>
                        { children }
                    </AppContent>
                </AppBody>
            </AppBase>
        </AppTheme>
    )

}

LayoutApp.defaultProps = {
    app: {},
    header: {
        expanded: true
    },
    nav: {
        expanded: true
    }
}

LayoutApp.propTypes = {
    app: PropTypes.node,
    user: PropTypes.node,
    owner: PropTypes.node,
    menuByUrl: PropTypes.object,
    header: PropTypes.node,
    search: PropTypes.node,
    subview: PropTypes.node,
    sidebar: PropTypes.node,
}

export default LayoutApp;