import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableModule from "../Module/TableModule"

class ModelsTable extends Component {

    static defaultProps = {
        models: [],
        moduleFooter: true
    };

    renderItem = (item, index) => {
        const { moduleFooter } = this.props;

        return (
            <TableModule {...item} moduleFooter={moduleFooter} key={index} />
        )
        
    }

    render() {
        const { models, groups } = this.props;

        return (
            <section>
                { models.map(this.renderItem )}
            </section>
        )
    }
}

export default ModelsTable;