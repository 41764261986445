import React, { Component } from 'react';
import PropTypes from "prop-types";

import { Widget, WidgetHeader, WidgetBody, WidgetTitle, WidgetDescription, WidgetLoading, Chart, FormatMetric } from "../"
import { getGaChartByType } from "../../resources/functions/"
// import { getGaChartByType, getGaDate, getGaDays, getGaWeeks, getGaMonths } from "../../resources/functions/"

import "./AnalyticsWidget.scss"

class AnalyticsWidget extends Component {

    static propTypes = { 
        loadingTitle: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        trending: PropTypes.string,
        tooltipTitle: PropTypes.string,
        tooltipLabel: PropTypes.string,
        scope: PropTypes.string
    }

    static defaultProps = {
        loadingTitle: "Loading widget",
        title: "Widget",
        widgetType: "analytics",
        widgetSize: "1:2",
        report: undefined,
        metric: undefined,
        dimension: undefined,
        scope: undefined,
        type: "line",
        compare: false,
        stacked: false,
        labels: undefined,
        debug: false,
    }
    
    state = {
        values: {},
        title: undefined,
        description: undefined,
        loading: true,
        chart: undefined
    }

    componentDidMount() {
        this.getReport()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.report !== this.props.report) {
            this.getReport()
        }
    }
    
    
    getReport = () => {
        let { report, metric, dimension, scope, type, compare, stacked, tooltipTitle, tooltipLabel } = this.props;
        
        let chart;
        
        if (report) {
            chart = getGaChartByType(type, report, metric, dimension)
        }
        
        // use chart to set metric and scope
        
        if (chart) {
            metric = chart.metric;
            scope = scope || chart.scope || undefined;
        }
        
        // get id
        
        let id = [];
        
        if (metric) {
            id.push(metric.replace("ga:", "")) 
        } else {
            id.push("widget") 
        }
        
        if (scope) {
            id.push(scope)
        }
            
        id = id.join('.')
        
        // set state
        
        this.setState({
            id: id,
            loading: true
        })
        
        if (!chart) {
            return false
        }

        // set state
        
        this.setState({
            loading: false,
            chart: {
                ...chart,
                type: type,
                compare: compare,
                stacked: stacked,
                tooltipTitle: tooltipTitle,
                tooltipLabel: tooltipLabel,
            }
        })
        
    }

    getSize = () => {
        const { widgetSize } = this.props;
        
        if (!widgetSize) {
          return {}
        }

        const sizes = {
            "xs": 2,
            "s": 4,
            "m": 8,
            "l": 12,
            "xl": 16
        }
        
        const grid = widgetSize.split(":")
        
        let cols = grid[0]
        let rows = grid[1]
        
        cols = sizes[cols] || parseInt(cols);
        rows = sizes[rows] || parseInt(rows);
        
        return {
            cols: cols,
            rows: rows
        }
        
    }
    
    getMargins = () => {
        const { cols, rows } = this.getSize();
        const { chart } = this.state;
        
        let margin = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
        
        let offset = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
        
        if (chart.type === "pie") {
            return {
                margin: margin,
                offset: offset
            }
        }
        
        const rowTop = {
            1: 24,
            2: 48,
            3: 60,
            4: 72,
            5: 84
        }
        
        margin.top = rows && rowTop[rows];
        
        if (rows > 5) {
            margin.top = rows && rowTop[5];
        }
        
        if (margin.top) {
            offset.top = margin.top * -1
        }
        
        return {
            margin: margin,
            offset: offset
        }
        
    }

    renderChart = () => {
        const { theme, colors } = this.props;
        const { chart } = this.state;

        const { margin, offset } = this.getMargins()
        
        if (chart) {
            return (
                <Chart {...chart} margin={margin} offset={offset} theme={theme} colors={colors} />
            )
        }
        
    }
    
    render() {
        const { widgetSize, theme, debug, url, title, description, trending, loadingTitle } = this.props;
        const { id, values, chart } = this.state;
        
        const loading = this.state.loading || this.props.loading;

        if (debug) {
            return (
                <pre>{JSON.stringify(chart, null, 2)}</pre>
            )
        }

        if (loading) {

            return (
                <Widget {...this.props}>
                    <WidgetLoading theme={theme} title={loadingTitle} />
                </Widget>
            )
            
        }
        
        return (
            <Widget {...this.props}>
                <WidgetHeader> 
                    <WidgetTitle title={title} url={url} />
                    <WidgetDescription description={description} trending={trending} />
                </WidgetHeader>
                <WidgetBody>
                    { this.renderChart() }
                </WidgetBody>
            </Widget>
        )


    }

}

export default AnalyticsWidget;