import React, { Component } from 'react';
import Button from './Button';

import './ButtonAction.scss';

class ButtonAction extends Component {
    render() {
        return (
            <Button {...this.props} className="admin-button  admin-button--action" />
        );
    }
}

export default ButtonAction;
