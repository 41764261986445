export default {
    "ui:title": "[count] profilbilder",
    "ui:help": "Legg inn profilbilder som skal være tilgjengelig i alle applikasjoner.",
    "items": {
        "ui:layout": "module",
        "ui:options": {
            "collapsible": true,
            "editable": true
        }
    },
    "ui:buttons": ["sidebar","upload"]
}