import React, { useEffect, useState } from 'react';
import { getUiOptions } from "@frontend-components/admin"

const CollectionSchemaReferenceField = (props) => {
    
    const { uiSchema, formContext } = props
    const { onEditSchema } = formContext
    
    const uiOptions = getUiOptions(uiSchema)

    const { buttons } = uiOptions;

    const uiButtons = buttons && buttons.map(button => {

        if (button.type === "edit") {
            return {
                ...button,
                onClick: () => onEditSchema(props)
            }
        }

        return button

    })

    const newUiSchema = {
        ...uiSchema,
        "ui:buttons": uiButtons
    }
    

    const { ObjectField } = props.registry.fields

    return (
        <ObjectField {...props} uiSchema={newUiSchema} />
    )
    
}

export default CollectionSchemaReferenceField