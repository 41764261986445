import React from "react";
import { KulturPunktIcon as icon } from '@frontend-components/admin/es/resources/icons';

export default {
    sortOrder: 1.3,
    uniqueId: "app/kp",
    appType: "site/product",
    collectionType: "kp",
    name: "kp",
    icon: icon,
    title: "KulturPunkt"
};