import React from "react"
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"

import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        "& $root": {
            marginTop: theme.spacing(1)
        },
    },
    label: {
    },
    list: {
        "& li": {
            minHeight: theme.spacing(5),
            "&.sortable-helper": {
                boxShadow: theme.shadows[12],
                zIndex: 2000
            }
        },
        "& li + li": {
            borderTop: "1px solid",
            borderColor: theme.palette.grey[500],
        }
    }
}));

const EditMenu = ({label, title, buttons = [], children, onAddItem, ...props}) => {

    const classes = useStyles()

    const header = label && <ListSubheader className={classes.label}>{title}</ListSubheader>

    return (
        <section className={classes.root}>
            <List className={classes.list} subheader={header}>
                {children}
                <MenuItem onClick={() => onAddItem()}>
                    <ListItemIcon><Icon>add</Icon></ListItemIcon>
                    <ListItemText>Legg til</ListItemText>
                </MenuItem>
            </List>
        </section>
    )

}

export default EditMenu