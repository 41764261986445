export default {
    "ui:layout": "section",
    "ui:icon": "palette",
    "ui:title": "Theme",
    "ui:grid": 2,
    "ui:fieldset": [
        "palette",
    ],
    "palette": {
        "ui:title" : "Palette",
        "ui:options": {
            "grid": true,
            "spacing": 2
        },
        "primary": {
            "ui:gridCols": 6,
            "ui:title": "Primary color",
            "ui:field": "collectionColor",      
        },
        "secondary": {
            "ui:gridCols": 6,
            "ui:title": "Secondary color",
            "ui:field": "collectionColor",
        }
    }
}