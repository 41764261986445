import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUiOptions } from '../../../components';
import { getSearch } from '../../../redux/functions/search';

const ParentIdField = ({collection, editor, searchById, ...props}) => {

    const { schema, uiSchema } = props;

    const uiOptions = getUiOptions(uiSchema)
    const documentType = uiOptions.documentType || "*"
    const uniqueId = editor && editor.uniqueId;

    const query = {
        id: "/children/" + uniqueId,
        models: "documents",
        documentType: documentType,
        collectionId: collection && collection.id,
        fl: "id,parentId,uniqueId,title",
        q: "uniqueId:NOT " + uniqueId
    }

    useEffect(() => {
        props.getSearch(query)
    }, [uniqueId])

    const [enumOptions, setEnumOptions] = useState([])
    const [enumNames, setEnumNames] = useState([])

    const getOptions = () => {
        const models = searchById && searchById[query.id] && searchById[query.id].models || []
        // const options = models.length && getOptionsTree(models) || []

        let optionsEnum = [
            undefined
        ], optionsNames = [
            "No parentId"
        ]

        models.map(model => {
            optionsEnum.push(model.id)
            optionsNames.push(model.title)
        })

        setEnumOptions(optionsEnum)
        setEnumNames(optionsNames)

    }

    useEffect(() => {
        getOptions()
    }, [searchById[query.id]])

    const { StringField } = props.registry.fields;

    const newSchema = {
        ...schema,
        enum: enumOptions,
        enumNames: enumNames
    }

    return (
        <StringField {...props} schema={newSchema} />
    )

}

const mapStateToProps = (state) => {
	return {
        collection: state.collection,
        editor: state.editor,
        searchById: state.searchById
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        getSearch
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParentIdField);