import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearch } from '../../redux/functions/search';
import { AutocompleteWidget } from "@frontend-components/admin"

const CollectionIdWidget = ({searchById, collectionId, value, ...props}) => {

    const query = {
        id: "/widget/collectionId",
        models: "collections",
        fl: "title,id",
        status: "NOT trash"
    }

    useEffect(() => {
        props.getSearch(query)
    }, [])

    const models = searchById && searchById[query.id] && searchById[query.id].models || []

    const enumOptions = models && models.map(model => {
        const { title, id } = model
        return {
            label: title,
            value: id.toString()
        }
    })

    const options = {
        ...props.options,
        enumOptions: enumOptions || props.options.enumOptions
    }

    return (
        <AutocompleteWidget {...props} value={value || collectionId} options={options}/>
    )

}

const mapStateToProps = (state) => {
	return {
        collectionId: state.editor.formData.collectionId,
        searchById: state.searchById,
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        getSearch
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CollectionIdWidget);