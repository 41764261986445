import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppFinder from '../AppFinder';

const CollectionFinder = (props) => {

    const query = {
        models: "collections",
        status: "NOT trash",
        collectionType: "exhibition",
        fl: "uniqueId,status,title"
    }

    const editUrlPattern = "/admin/collection/:uniqueId/edit"

    return (
        <AppFinder {...props} query={query} editUrlPattern={editUrlPattern} />
    )

}

const mapStateToProps = (state) => {
	return {
        app: state.app
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionFinder);
