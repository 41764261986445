import image from './image';
import video from './video';
import audio from './audio';

const schemas = [
  image,
  video,
  audio
]

export default schemas;