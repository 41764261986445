import React from "react";
import PropTypes from "prop-types";
import InputBase from "./InputBase"

const BaseInputWidget = (props) => {
  // Note: since React 15.2.0 we can't forward unknown element attributes, so we
  // exclude the "options" and "schema" ones here.
  if (!props.id) {
    console.log("No id for", props);
    throw new Error(`no id for props ${JSON.stringify(props)}`);
  }
  const {
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    schema,
    formContext,
    registry,
    rawErrors,
    ...inputProps
} = props;

  // If options.inputType is set use that as the input type
  if (options.inputType) {
    inputProps.type = options.inputType;
  } else if (!inputProps.type) {
    // If the schema is of type number or integer, set the input type to number
    if (schema.type === "number") {
      inputProps.type = "number";
      // Setting step to 'any' fixes a bug in Safari where decimals are not
      // allowed in number inputs
      inputProps.step = "any";
    } else if (schema.type === "integer") {
      inputProps.type = "number";
      // Since this is integer, you always want to step up or down in multiples
      // of 1
      inputProps.step = "1";
    } else {
      inputProps.type = "text";
    }
  }

    // If multipleOf is defined, use this as the step value. This mainly improves
    // the experience for keyboard users (who can use the up/down KB arrows).
    if (schema.multipleOf) {
        inputProps.step = schema.multipleOf;
    }

    if (typeof schema.minimum !== "undefined") {
        inputProps.min = schema.minimum;
    }

    if (typeof schema.maximum !== "undefined") {
        inputProps.max = schema.maximum;
    }

    const _onChange = (event) => {
        return props.onChange(event.target.value === "" ? options.emptyValue : event.target.value);
    };

    const label = options.title || props.title || props.label || null
    const helperText = options.help || null
    const placeholder = options.placeholder || props.placeholder || null
    const icon = options.icon || null
    const prefix = options.prefix || null

    return (
        <InputBase
            {...inputProps}
            readOnly={readonly}
            disabled={disabled}
            autoFocus={autofocus}
            label={label}
            placeholder={placeholder}
            icon={icon}
            prefix={prefix}
            value={value == null ? "" : value}
            helperText={helperText}
//            inputProps={inputProps}
            list={schema.examples ? `examples_${inputProps.id}` : null}
            onChange={_onChange}
            onBlur={onBlur && (event => onBlur(inputProps.id, event.target.value))}
            onFocus={onFocus && (event => onFocus(inputProps.id, event.target.value))}
        />
    )
}

BaseInputWidget.defaultProps = {
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
};

BaseInputWidget.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
}

export default BaseInputWidget;