import React from "react";

export function getProfileIcon(sex = "female") {
    
    if (sex === "male") {

        return (
            <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                <path d="M42.2485437,41.2219625 C41.6777,46.3771813 37.30745,50.3868688 32.000075,50.3868688 C26.6940125,50.3868688 22.3247,46.3794313 21.7518875,41.2261813 L8.06148125,48.215525 L8.000075,56.0000563 L37.0302312,56.0000563 L40.7642,56.0000563 L55.9999812,56.0000563 L55.9999812,48.215525 L42.2485437,41.2219625 Z" id="Fill-1" fill="#0666B0"></path>
                <path d="M38.0937875,40.0743406 L38.0937875,22.2114031 L25.9062875,22.2114031 L25.9062875,40.0743406 L24.0312875,40.0743406 C24.0312875,44.4753406 27.5990375,48.0430906 32.0000375,48.0430906 C36.4009438,48.0430906 39.9687875,44.4753406 39.9687875,40.0743406 L38.0937875,40.0743406 Z" id="Fill-4" fill="#BBBBBB"></path>
                <path d="M45.5107531,21.5964406 C45.3740656,20.7503469 44.9240656,20.3687844 44.1953469,20.3405656 L43.8271906,20.3405656 C42.8268781,13.3248781 37.9396906,7.99997188 32.0613781,7.99997188 C32.0408469,7.99997188 32.0205031,8.00081563 31.9999719,8.00100313 C31.9794406,8.00081563 31.9590031,7.99997188 31.9384719,7.99997188 C26.0601594,7.99997188 21.1729719,13.3248781 20.1727531,20.3405656 L19.8045031,20.3405656 C19.0757844,20.3687844 18.6258781,20.7503469 18.4891906,21.5964406 C18.1528156,23.6779719 18.7582531,25.5180969 20.0826594,27.1160656 C20.4039406,27.5038156 20.6137531,27.9024406 20.7615969,28.3660344 L20.7632844,28.3311594 C22.4764719,33.9832531 26.8333156,38.0000656 31.9384719,38.0000656 C31.9590031,38.0000656 31.9794406,37.9991281 31.9999719,37.9990344 C32.0205031,37.9991281 32.0408469,38.0000656 32.0613781,38.0000656 C37.1665344,38.0000656 41.5234719,33.9832531 43.2365656,28.3311594 L43.2383469,28.3660344 C43.3861906,27.9024406 43.5958156,27.5038156 43.9172844,27.1160656 C45.2416906,25.5180969 45.8471281,23.6779719 45.5107531,21.5964406" id="Fill-6" fill="#DDDDDD"></path>
                <path d="M44.0364781,20.4106437 C42.4512594,20.6274875 41.7622906,19.4553312 41.0549469,18.405425 C40.6611031,17.8208937 40.4252281,17.1203937 40.1738844,16.4513 C39.6568531,15.0752375 38.9458531,13.825175 37.9388844,12.7478937 C37.2418531,12.0022062 36.4505094,11.7567687 35.4959469,12.2387375 C35.1478531,12.414425 34.7846656,12.5605812 34.4272906,12.717425 C32.8389781,13.4151125 31.2501031,13.4142687 29.6613219,12.7184562 C29.2464781,12.5368625 28.8285406,12.3620187 28.4180094,12.1711437 C27.6147594,11.79755 26.9261656,11.9993937 26.3196969,12.585425 C25.2311656,13.6370187 24.4518219,14.9042375 23.9782906,16.32305 C23.4995094,17.7575187 22.7239156,18.9608 21.6413844,19.9957062 C21.1499469,20.465675 20.5473219,20.3735187 19.9635406,20.4096125 C20.7239469,16.2158 22.4584156,12.5778312 25.9620406,9.96276875 C29.7234781,7.1552375 35.3171656,7.30205 39.0672594,10.7753 C41.7484156,13.2584562 43.2432594,16.3681437 43.9490094,19.90355 C43.9826656,20.0716437 44.0075094,20.2415187 44.0364781,20.4106437" id="Fill-9" fill="#CCCCCC"></path>
            </svg>
        )
        
    }
    
    // female

    return (
        <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
            <path d="M20.030075,35.44205 C20.030075,35.44205 24.0881375,38.0000187 32.0789188,38.0000187 C40.0697,38.0000187 43.9266688,35.4557375 43.9266688,35.4557375 L43.9698875,22.243175 L20.030075,22.243175" id="Fill-1" fill="#666666"></path>
            <path d="M38.09375,40.0671125 L38.09375,22.204175 L25.90625,22.204175 L25.90625,40.0671125 L24.03125,40.0671125 C24.03125,46.8952062 27.599,52.4304875 32,52.4304875 C36.401,52.4304875 39.96875,46.8952062 39.96875,40.0671125 L38.09375,40.0671125 Z" id="Fill-3" fill="#BBBBBB"></path>
            <path d="M43.953125,23.0072656 C43.953125,31.2914844 38.6015938,38.0072656 32,38.0072656 C25.3984063,38.0072656 20.046875,31.2914844 20.046875,23.0072656 C20.046875,14.7229531 25.3984063,8.00726562 32,8.00726562 C38.6015938,8.00726562 43.953125,14.7229531 43.953125,23.0072656" id="Fill-5" fill="#DDDDDD"></path>
            <path d="M39.96875,40.0625375 L39.9686563,40.0625375 C39.966125,46.492475 38.82125,52.2310062 37.0301563,56.0000375 L56,56.0000375 L56,48.2155062 L39.96875,40.0625375 Z" id="Fill-7" fill="#0666B0"></path>
            <path d="M24.03125,40.0625375 L8.0615,48.2155062 L8,56.0000375 L26.9698437,56.0000375 C25.17875,52.2310062 24.033875,46.492475 24.0313437,40.0625375 L24.03125,40.0625375 Z" id="Fill-10" fill="#0666B0"></path>
            <path d="M33.1931656,7.99999063 C33.5849469,8.08886563 33.9807594,8.16320938 34.3679469,8.26877188 C40.1905719,9.85577188 44.0561656,13.4840844 45.7044781,19.3013656 C46.3092594,21.4357719 46.0511656,23.6418031 45.6396969,25.7992719 C45.1725406,28.2487719 44.6294469,30.6837406 44.1576031,33.1323969 C44.0107906,33.8944906 43.9886656,34.6805844 43.9094469,35.4557094 C43.6543531,35.1078969 43.3717906,34.7767719 43.1496969,34.4091781 C42.5099469,33.3508344 42.2927281,32.1523344 42.0533844,30.9637719 C41.9170719,28.4905531 41.8063531,26.0156469 41.6356344,23.5448656 C41.5134781,21.7743031 40.8520719,20.4188656 39.1322281,19.4746156 C35.7980094,17.6440531 33.6078219,14.7148344 32.0616031,11.2877094 C31.9945719,11.1485844 31.9144156,11.0142406 31.8626656,10.8697719 C31.5199156,9.91427188 31.1832594,8.95689688 30.8446344,7.99999063 L33.1931656,7.99999063 Z" id="Fill-13" fill="#777777"></path>
            <path d="M30.8446062,7.99999063 C31.1832312,8.95689688 31.5198875,9.91427188 31.8626375,10.8697719 C31.9144812,11.0142406 31.9946375,11.1485844 32.0616687,11.2877094 C31.86995,11.5770219 31.6492625,11.8514281 31.4909187,12.1578031 C29.8039812,15.4228344 27.5036375,18.1119594 24.2110437,19.8603969 C23.3099187,20.3388969 22.75745,21.0928344 22.59995,22.0421469 C22.3328562,23.6514594 22.145075,25.2779281 22.0150437,26.9045844 C21.9072312,28.2537406 21.9307625,29.6133031 21.8961687,30.9683656 C21.6931062,31.7663656 21.54395,32.5833031 21.2717938,33.3572094 C21.0012312,34.1263344 20.6849187,34.8996781 20.012825,35.4420219 C19.9542312,33.2421781 19.4284812,31.1184594 18.9606687,28.9847094 C18.4729813,26.7599281 17.984825,24.5423656 17.9400125,22.2417406 C17.8906062,19.7014906 18.5642,17.3911156 19.923575,15.2794906 C22.1479813,11.8240531 25.1875437,9.42583438 29.2065125,8.35145938 C29.7161375,8.21514688 30.2357,8.11624063 30.7506687,7.99999063 L30.8446062,7.99999063 Z" id="Fill-16" fill="#888888"></path>
        </svg>
    )   

    
}



export default getProfileIcon("female")