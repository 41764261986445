import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontSize: "14",
        fontFamily: [
            '"Akkurat"',
            '"sans-serif"',
            ].join(','),
        },
        h2: {
            fontSize: "18",
            lineHeight: "1",
        }
  });

  export default theme