import React, { Component } from 'react';
import { FinderSection } from '@frontend-components/admin';
import AuthModuleApp from "./AuthModuleApp"

class AuthSectionApps extends Component {
    
    static defaultProps = {
        apps: []
    }
    
    renderApp = (app, index) => {

        return (
            <AuthModuleApp {...app} key={index} />
        )
        
    }

    render() {
        const { title, apps } = this.props;
        
        return (
            <FinderSection title={title}>
                { apps.map(this.renderApp) }
            </FinderSection>
        )
        
    }

}

export default AuthSectionApps