import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAuthRoles, getAuthUsers, getUserById } from "../redux/functions/auth"
import { getAuthSites, getSiteById } from "../redux/functions/authSites"

import { getUrlFromProps } from '@frontend-components/admin/lib/resources/functions';

import Finder from '../AppFinder';
import { FinderTabs, FinderSection } from '@frontend-components/admin';

import AuthSectionApps from "./AuthSectionApps"
import AuthSectionAppsAdd from "./AuthSectionAppsAdd"
import AuthSectionSites from "./AuthSectionSites"
import AuthSectionSitesAdd from "./AuthSectionSitesAdd"
import AuthSectionUsers from "./AuthSectionUsers"
import AuthSectionUsersAdd from "./AuthSectionUsersAdd"


import AuthModuleUser from "./AuthModuleUser"
import AuthModuleSite from "./AuthModuleSite"
import AuthModuleApp from "./AuthModuleApp"

class AuthUser extends Component {
    
    static defaultProps = {
        urlPattern: "/admin/auth/user/:userId"
    }
    
    componentDidMount() {
        this.props.getAuthRoles()
        this.props.getAuthUsers()
        this.props.getAuthSites()
        this.getAuth()
    }
    
    componentDidUpdate = (prevProps) => {

        if (prevProps.match.params.userId !== this.props.match.params.userId) {
            this.getAuth()
        }

        if (prevProps.match.params.siteId !== this.props.match.params.siteId) {
            this.getAuth()
        }

        if (prevProps.match.params.appId !== this.props.match.params.appId) {
            this.getAuth()
        }

    }
    
    getAuth = () => {
        const { userId, siteId, appId } = this.props.match.params
        
        if (userId) {
            this.props.getUserById(userId)
        }

        if (siteId) {
            this.props.getSiteById(siteId)
        }
        
    }
    
    getSiteApps = (currentUser, currentSite) => {
        const { urlPattern } = currentSite;
        const { userId, siteId } = this.props.match.params;

        let siteApps = []

        currentSite.authApps.map(app => {
            const { collectionId, status } = app;
            
            const roleId = collectionId && currentUser.authAppsById[collectionId] && currentUser.authAppsById[collectionId].roleId

            app = {
                ...app,
                userId: userId,
                siteId: siteId,
                roleId: roleId,
                urlPattern: urlPattern + "/app/:appId",
                moduleSize: "small"
            }
            
            if (status === "publish") {
                siteApps.push(app)
            }
            
        })
        
        return siteApps        

    }

    getUserSites = (currentUser) => {
        const { authSites, urlPattern } = currentUser;
        const { sitesById } = this.props;
        
        return authSites.map(site => {

            return {
                ...sitesById[site.id],
                ...site,
                urlPattern: urlPattern + "/site/:siteId",
                moduleSize: "small"
            }
        })

    }
    
    getUserApps = (currentUser) => {
        const { authApps, urlPattern } = currentUser;

        return authApps.map(app => {
            return {
                ...app,
                urlPattern: urlPattern + "/app/:collectionId",
                moduleSize: "small"
            }
        })
        
    }
    
    getCurrentUser = () => {
        const { urlPattern, usersById } = this.props;
        const { userId } = this.props.match.params;
        
        let currentUser;        
        
        if (userId && usersById[userId]) {
            currentUser = { 
                ...usersById[userId],
                urlPattern: urlPattern
            }
        }            
        
        return currentUser
        
    }
    
    getCurrentSite = (currentUser) => {
        const { authSitesById, urlPattern } = currentUser;
        const { sitesById } = this.props;
        const { siteId } = this.props.match.params;
        
        if (!sitesById[siteId]) {
            return false
        }

        return {
            ...sitesById[siteId],
            ...authSitesById[siteId],
            urlPattern: urlPattern + "/site/:siteId",
            moduleSize: "small"
        }
        
    }

    render() {
        const currentUser = this.getCurrentUser()
        
        if (!currentUser) {
            return false
        }

        const currentSite = this.getCurrentSite(currentUser)
        
        // user + site
        
        if (currentSite) {
            
            const siteApps = this.getSiteApps(currentUser, currentSite);
            
            return (
                <Finder {...this.props}>
                    <AuthSectionUsers users={[currentUser]} />
                    <AuthSectionSites sites={[currentSite]} title="Current site" />
                    <AuthSectionApps apps={siteApps} title="Authorize apps" />
                </Finder>
                
            )
            
        }
        
        // user

        const userSites = this.getUserSites(currentUser)            
        const userApps = this.getUserApps(currentUser)            

        // tabs

        const { urlPattern } = currentUser;
        const { tabId } = this.props.match.params;
        
        const siteTab = {
            id: "site",
            url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern})
        }

        const appTab = {
            id: "app",
            url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern + "/app"})
        }

        return (
            <Finder {...this.props}>
                <AuthSectionUsers users={[currentUser]} />
                <FinderTabs onSelect={this.onSelectTab} tabId={tabId}>
                    <FinderSection {...siteTab} title={userSites.length + " sites"}>
                        <AuthSectionSites sites={userSites} title="" />
                        <AuthSectionSitesAdd {...this.props} sites={userSites} userId={currentUser.userId} />
                    </FinderSection>
                    <FinderSection {...appTab} title={userApps.length + " apps"}>
                        <AuthSectionApps apps={userApps} title="" />
                        <AuthSectionAppsAdd {...this.props} apps={userApps} userId={currentUser.userId} />
                    </FinderSection>
                </FinderTabs>
            </Finder>
        )

    }

}

function mapStateToProps(state) {
	return {
        parents: state.app.parents,
        users: state.auth.users,
    	usersById: state.auth.usersById,
    	sitesById: state.auth.sitesById
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getAuthRoles, 
      getAuthUsers, getUserById, 
      getAuthSites, getSiteById, 
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthUser);
