import React, { Component } from 'react';
import { ChartBar } from '../'

import Color from 'color';


class ChartBarGrouped extends Component {
    
    static defaultProps = {
        groupMode: "grouped",
    }

    getColors = () => {
        const { theme } = this.props;
        
        return [
            theme.primaryColor || "black",
            "#ccc"
        ]
        
    }

    render() {
        
        const colors = this.getColors()

        return (
            <ChartBar {...this.props} colors={colors} />
        )
    
    }

}

export default ChartBarGrouped