import { API } from '../settings';
import { requestSite, receiveSite } from '../actions/site';
import { getApp, getAppLayout } from '../functions/app';
import { getContrast } from '../functions/color';
import { getOwnerIcon } from '@frontend-components/admin/es/resources/icons';

// GET SITE COLORS

export function getSiteColors(site) {
    const colors = site && site.content && site.content.colors;

    if (!colors) {
        return []
    }    
    
    return colors
    
}

export function getSiteTheme(site) {
    const colors = getSiteColors(site)
    
    if (!colors.length) {
        return {}
    }
    
    const primaryColor = colors[0].value
    const primaryContrast = getContrast(primaryColor)
    
    return {
        primaryColor: primaryColor,
        primaryContrast: primaryContrast,
        darkColor: primaryColor,
        lightColor: "#999"
    }
    
}

export function getWidgetStyle(site) {
    const {primaryColor, primaryContrast} = getSiteTheme(site)
    
    if (primaryColor && primaryContrast) {

        return {
            backgroundColor: primaryColor,
            color: primaryContrast
        }
        
    }

    return {}
    
}

// GET SITE APP

export function getSiteApp(site) {
    
    const app = {
        root: "/" + site.name + "/",
        name: "Dashboard",
        header: {
            title: "Dashboard",
            subtitle: site.title || site.name
        },
        theme: {
            primaryColor: "#fff"
        }
    }

    return function (dispatch) {
        dispatch(getApp(app))
    }
    
}


export function getSiteDashboard(site) {
    
    return function (dispatch) {
        dispatch(getSiteApp(site))
        dispatch(getAppLayout('dashboard'))
    }
    
}

export function getSiteSettings(site) {
    
    return function (dispatch) {
        dispatch(getSiteApp(site))
        dispatch(getAppLayout('editor'))
    }
    
}

// GET SITE

export function getSite(uniqueId) {
  
  let url = API + '/admin/api/sites/' + uniqueId;

  return function (dispatch) {

    dispatch(requestSite());

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(
      data => dispatch(receiveSite(data))
    )

  }
  
}

export function getSiteData(site) {
    const theme = getSiteTheme(site)

    const { name, title, content } = site;
    
    let abbr = content && content.abbr || name;
    abbr = abbr.substr(0,3).toUpperCase();

    const icon = getOwnerIcon({fill: theme.primaryColor, text: abbr})
    const url = "/" + name;
    
    return {
        ...site,
        url: url,
        abbr: abbr,
        icon: icon,
        theme: theme
    }
    
}

// GET SITE BY NAME

export function getSiteByName(name) {
  
  let url = API + '/admin/api/sites/search?name=' + encodeURIComponent(name);

  return function (dispatch) {

    dispatch(requestSite());

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(
      data => dispatch(receiveSite(getSiteData(data.models[0])))
    )

  }
  
}

// GET SITE BY ID

export function getSiteById(id) {
  
  let url = API + '/admin/api/sites/search?id=' + encodeURIComponent(id);

  return function (dispatch) {

    dispatch(requestSite());

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(
      data => dispatch(receiveSite(data.models[0]))
    )

  }
  
}