export default {
    "ui:fieldset": [
        "metadata"
    ],
    "metadata": {
        "ui:layout": "document",
        "ui:nav": "menu",
        "ui:fieldset": [
            "page",
            "keywords",
            "license"
        ],
        "page": {
            "ui:fieldset": [
                "title",
                "parentId"
            ],
            "parentId": {
                "ui:widget": "parentId"
            }
        }
    }
}