import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Gallery from "../Layout/LayoutGallery"
import GridModule from "../Module/GridModule"

const ModelsGrid = ({mediaWidth = 180, mediaHeight = 180, mediaLayout = "contain", models = [], debug = false}) => {

    return (
        <Gallery debug={debug}>
            { models.map((model, index) => {

                model = {
                    ...model,
                    width: mediaWidth,
                    mediaHeight: mediaHeight,
                    mediaLayout: mediaLayout
                }

                return (
                    <GridModule {...model} width={model.width} />
                )
            })}
        </Gallery>
    )

}

export default ModelsGrid