import authorizeApps from "./authorizeApps"

function getApp(name, title, appType) {
    
    return {
        type: "object",
        properties: {
            name: {
                type: "string",
                default: name,
                readonly: true
            },
            title: {
                type: "string",
                default: title,
                readonly: true
            },
            appType: {
                type: "string",
                default: appType,
                readonly: true
            },
            accessType: {
                type: "string",
                enum: [
                    "demo",
                    "full"
                ]
            }
        }
    }
    
}

const services = {
    type: "array",
    items: [
        getApp("dashboard", "Dashboard", "service"),
        getApp("settings", "Settings", "service"),
    ],
    additonalItems: false
}

const products = {
    type: "array",
    items: [
        getApp("dm", "DigitaltMuseum", "product"),
        getApp("m24", "Museum24", "product"),
        getApp("kp", "KulturPunkt", "product"),
        getApp("primus", "primus", "product"),
        getApp("kompass", "Kompass", "product"),
        getApp("minner", "Minner", "product"),
    ],
    additonalItems: false
}


export default {
    type: 'object',
    properties: {
        services: services,
        products: products,
        apps: authorizeApps
    }
}