import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        overflowY: "scroll",
        maxHeight: props => { return props.maxHeight }
    }
}));

const WidgetResults = ({className, children, ...props}) => {

    const classes = useStyles(props)

    return (
        <section className={className || classes.root}>
            { children }
        </section>
    )

}

export default WidgetResults;