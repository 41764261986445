import media from "./mediaUi"
import links from "./linksUi"
import ekultur from "./ekulturUi"
import uiPreview from "./uiPreview"

export default {
    "ui:layout": "section",
    "ui:preview": uiPreview,
    "ui:options": {
        "collapsible": true,
        "hideable": true,
        "deletable": true,
        "padding": 2,
        "spacing": 2
    },
    "ui:settings": [
        "sectionType",
        "sectionLayout"
    ],
    "ui:fieldset": [
        "head",
        "body"
    ],
    "head": {
        "ui:fieldset": [
            "title",
            "titleHidden",
            "description",
        ],
        "ui:options": {
            "grid": true,
            "spacing": 2
        },
        "title": {
            "ui:gridCols": 9,
            "ui:label": false,
            "ui:placeholder": "Tittel"
        },
        "titleHidden": {
            "ui:gridCols": 3,
            "ui:title": "Skjul tittel",
            "ui:widget": "switch"
        },
        "description" : {
            "ui:gridCols": 12,
            "ui:title": "Beskrivelse",
            "ui:widget" : "textarea",
        },
    },
    "body": {
        "ui:fieldset": [
            "media",
            "references",
            "bodytext",
            "message",
            "links",
        ],
        "media" : media,
        "references" : ekultur,
        "message" : {
            "ui:title": "Beskjed",
            "ui:widget" : "textarea",
        },
        "bodytext" : {
            "ui:title": "Brødtekst",
            "ui:widget" : "texteditor"
        },
        "links" : links,
    }
}