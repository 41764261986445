import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserAuth } from "../redux/functions/auth"
import { getPreview } from '../redux/functions/schema';
import { AuthModule } from '@frontend-components/admin';

import schema from "./schemas/user/"

class Module extends Component {
    
    static defaultProps = {
        urlPattern: "/admin/auth/user/:userId",
        editUrlPattern: "/admin/auth/user/:uniqueId/edit",
        action: ['edit']
    }
    
    onAddUser = () => {
        const { rolesOptions } = this.props
        
        const defaultValue = rolesOptions[rolesOptions.length-1].value;
        
        this.onAuthUser(defaultValue);
    }

    onRemoveUser = () => {
        this.onAuthUser(undefined);
    }
    
    onAuthUser = (authId) => {
        const { collectionId, siteId, userId } = this.props;

        if (collectionId && userId) {
            this.props.setUserAuth(userId, {
                collectionId: collectionId,
                roleId: authId
            })
        } else if (siteId && userId) {
            this.props.setUserAuth(userId, {
                siteId: siteId,
                roleId: authId
            })
        }
        
    }
    
    getAuth = () => {
        let { rolesOptions, userId, siteId, collectionId, roleId, action } = this.props;

        // user

        const user = getPreview(schema, {
            ...this.props
        });
        
        // auth
        
        let authOptions, authValue, authStatus;

        if (collectionId && userId && roleId) {
            authOptions = rolesOptions
            authValue = roleId
            authStatus = roleId
        } else if (siteId && userId && roleId) {
            authOptions = rolesOptions;
            authValue = roleId;
            authStatus = roleId
        }
        
        // metadata or label

        let metadata, label;
        
        if (authOptions) {
            label = this.getLabel()
            action = ['remove']
        } else {
            metadata = this.getMetadata()
        }
        

        return {
            ...user,
            authType: "user",
            authOptions: authOptions,
            authValue: authValue,
            authStatus: authStatus,
            label: label,
            metadata: metadata,
            action: action
        }        
        
    }
    
    getMetadata = () => {
        const { authSites, authApps } = this.props;

        const metadata = []
        
        const sitesCount = authSites && authSites.length;
        
        if (sitesCount > 1) {
            metadata.push(sitesCount + " sites")
        } else if (sitesCount) {
            metadata.push(sitesCount + " site")
        }
    
        const appsCount = authApps && authApps.length;
        
        if (appsCount > 1) {
            metadata.push(appsCount + " apps")
        } else if (sitesCount) {
            metadata.push(appsCount + " app")
        }
        
        return metadata;
        
    }
    
    getLabel = (user) => {
        const { appName, siteName } = this.props;
        
        let labels = []

        if (siteName) {
            labels.push(siteName)
        }

        if (appName) {
            labels.push(appName)
        }
        
        return labels.join('/')
        
    }
    
    render() {
        const user = this.getAuth()
        
        return (
            <AuthModule {...user} onAuth={this.onAuthUser} onAdd={this.onAddUser} onRemove={this.onRemoveUser} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
        rolesOptions: state.auth.rolesOptions
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      setUserAuth
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Module);