import React, { Component } from 'react';
import { formatLabel, formatValue } from './lib';
import { FormatMetric } from "../"

class AnalyticsTableHead extends Component {
    
    getClassName = () => {
        return "admin-analytics-table__head"
    }
    
    onSort = (by, order) => {
        
        if (this.props.onSort) {
            this.props.onSort(by, order)
        }
        
    }

    renderMetricHeader = (metric, index) => {
        const { sortBy, sortOrder, tdStyle, themeStyle } = this.props;
        const { name, type } = metric;
        
        let selected, order, style;

        if (sortBy === name) {
            selected = true
            
            style = {
                ...tdStyle,
                ...themeStyle
            }
            
            if (sortOrder === "ascending" || sortOrder === "asc") {
                order = "desc"
            } else {
                order = "asc"
            }
            
        } else {
            order = "desc"
            style = tdStyle
        }
        
        return (
            <th className="admin-analytics-table__label" aria-selected={selected} style={style} key={index} onClick={() => this.onSort(name, order)}>
                <b><FormatMetric name={name} /></b>
                <i data-sort={sortOrder}></i>
            </th>
        )
        
    }

    renderMetricTotal = (metric, index) => {
        const { sortBy, sortOrder, tdStyle, totals } = this.props;
        const { name, type } = metric;
        
        const total = parseInt(totals[index]);
        
        let selected;

        if (sortBy === name) {
            selected = true
        }

        let summary;
        
        if (type === "INTEGER") {
            summary = "% av total"
        } else {
            summary = "% ift gj. snitt"
        }
        
        return (
            <td className="admin-analytics-table__total" style={tdStyle} aria-selected={selected} key={index}>
                <b><FormatMetric {...metric} value={total} /></b>
                <i>{ summary }</i>
            </td>
        )
        
    }
        
    renderDimension = (dimension, index) => {
        
        
        
    }
    
    render() {
        const { thStyle, metricHeaders, dimensions } = this.props;
        
        const className = this.getClassName();
        
        return (

            <thead className={className}>
            
                <tr>
                    <th style={thStyle}></th>
                    { metricHeaders.map(this.renderMetricHeader) }
                </tr>

                <tr>
                    <th style={thStyle}></th>
                    { metricHeaders.map(this.renderMetricTotal) }
                </tr>

            </thead>

            
        )
        
    }
    
}

export default AnalyticsTableHead;