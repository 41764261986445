import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: props => { return props.minWidth },
    },
}));

const ActionDropdown = ({children, minWidth = 192}) => {
    const classes = useStyles({minWidth})

    return (
        <Paper elevation={16} square={true} className={classes.root}>
            {children}
        </Paper>
    )
}

export default ActionDropdown