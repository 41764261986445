import React from "react";
import TimeInput from "./TimeInput"

const TimeWidget = ({options, ...props}) => {
    const label = options.title || props.title || props.label || null
    const helperText = options.help || null

    return (
        <TimeInput
            {...props}
            label={label}
            helperText={helperText}
        />
    )
}

export default TimeWidget;