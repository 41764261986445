// initialState

let initialState = {
};

// editor state

const uploadsById = (state = initialState, action) => {

	switch (action.type) {

        case 'REQUEST_UPLOAD':
            return {
                ...state,
                [action.id]: {
                    requested: 0,
                    received: 0,
                    models: []
                }
            }

        case 'RECEIVE_UPLOAD':

            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    ...action.data,
                }
            }

        case 'REQUEST_UPLOAD_MODEL':

            const requested = state[action.id].requested + 1

            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    requested: requested
                }
            }

        case 'RECEIVE_UPLOAD_MODEL':

            const received = state[action.id].received + 1
            const models = state[action.id].models || []

            models.push(action.data)

            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    received: received,
                    models: models
                }
            }

        default:
            return state

    }

}

export default uploadsById;