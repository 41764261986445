import { getAppData } from '../../redux/functions/app';
import { getSiteCollection, getUserCollection } from '../../redux/functions/appCollection';

//import schemas from "../schemas"

export function getApp(props) {
    const { user } = props;
    
    const appTitle = "Statistikk"
    const appName = "analytics"
    const appRoot = "/analytics"
    
    props = {
        ...props,
        appRoot: appRoot
    }

    const appMenu = getAppMenu(props)
    const appEdit = getAppButton(appRoot)
    const appSearch = getAppSearch(appRoot)
    
    const app = {
        root: appRoot,
        name: appName,
        title: appTitle,
        theme: {
            "primaryColor": "#3154ea",
            "lightColor": "#8aa7f7",
            "darkColor": "#24439f"
        },
        /*
        header: {
            title: appTitle,
            subtitle: user.name || "N/A"
        },
        */
        sidebar: {
            expanded: true
        },
        search: appSearch,
        edit: appEdit,
        menu: appMenu,
//        schemas: schemas
    }

    return function(dispatch) {
        dispatch(getAppData(app))
//        dispatch(getSiteCollection({...props, app: app}))
        dispatch(getUserCollection({...props, app: app}))
    }
    
}

export function getAppSearch(appRoot) {
    
    return {
        "placeholder": "Søk i statistikk"
    }
    
}

export function getAppButton(appRoot) {

    return [{
      title: "Ny rapport",
      icon: "",
      children: [
        {
          children: [
            {
              title: "Artikkel",
              url: appRoot + "/article/<new>/edit",
            },
            {
              title: "Arrangement",
              url: appRoot + "/event/<new>/edit",
            },
            {
              title: "Innboks",
              url: appRoot + "/inbox/<new>/edit",
            }
          ]
        },
        {
          title: "Last opp",
          icon: "cloud_upload",
          url: appRoot + "/upload",
        }
      ]
    }]

    
}

export function getAppMenu(props) {
    
    const { appRoot } = props;
    
    const byOwner = getMenuBySite(props)
    const byApps = getMenuByApps(props)


   return [
      {
        title: "Statistikk",
        icon: "bar_chart",
        url: appRoot
      },
      {
          role: "group",
        title: "Eiere",
        icon: "business",
        url: appRoot + "/owner",
        chart: {
            template: "sessionsByOwner"
        },
        query: {
            dimensions: "deviceCategory"
        },
        children: byOwner,
        count: byOwner.length
      },
      {
        role: "group",
        title: "Applikasjoner",
        icon: "apps",
        url: appRoot + "/app",
        chart: {
            template: "sessionsByApp"
        },
        query: {
            dimensions: "operatingSystem"
        },
        children: byApps,
        count: byApps.length
      },
      {
        role: "group",
        title: "Geografi",
        icon: "language",
        url: appRoot + "/geo",
        children: [
          {
            title: "Land",
            url: appRoot + "/geo/country",
            query: {
                dimensions: "country,countryIsoCode"
            }
          },
          {
            title: "Byer",
            url: appRoot + "/geo/city",
            query: {
                dimensions: "city"
            }
          },
          {
            title: "Språk",
            url: appRoot + "/geo/language",
            query: {
                dimensions: "language"
            }
          }
        ]
      },
      {
        role: "group",
        title: "Teknologi",
        icon: "computer",
        url: appRoot + "/technology",
        children: [
          {
            title: "Nettleser",
            url: appRoot + "/technology/browser",
            query: {
                dimensions: "browser"
            }
          },
          {
            title: "Operativsystem",
            url: appRoot + "/technology/system",
            query: {
                dimensions: "operatingSystem"
            }
          },
          {
            title: "Skjermoppløsning",
            url: appRoot + "/technology/screen",
            query: {
                dimensions: "screenResolution"
            }
          }
        ]
      }
    ]
    
}

export function getMenuBySite(props) {
    const { user, appRoot } = props;
    const { appsBySite } = user;
    
    let menu = []
    
    if (!appsBySite) {
        return menu
    }

    appsBySite.map((site) => {
        
        let siteMenu = {
            title: site.title,
            url: appRoot + "/owner/" + site.name
        }
        
        let children = []
        
        site.apps.map((app) => {
            const { name, appType } = app;
            
            if (appType !== "site/product") {
                return
            }
            
            children.push({
                title: app.title,
                url: siteMenu.url + "/" + app.name
            })
            
        })
        
        menu.push({
            ...siteMenu,
            children: children,
            count: children.length
        })
        
    })
    
    return menu;
    
}

export function getMenuByApps(props) {
    const { user, appRoot } = props;
    const { appsBySite } = user;
    
    let menu = []
    
    if (!appsBySite) {
        return menu
    }
    
    let apps = {}

    appsBySite.map((site) => {
        
        site.apps.map((app) => {
            const { name, appType } = app;
            
            if (appType !== "site/product") {
                return
            }
            
            if (!apps[name]) {
                apps[name] = {
                    title: app.title,
                    url: appRoot + "/app/" + app.name,
                    count: 0,
                    children: []
                }
            }
            
            const appSiteMenu = {
                title: site.title,
                url: apps[name].url + "/" + site.name
            }
            
            if (!apps[name].children.includes(appSiteMenu)) {
                apps[name].children.push(appSiteMenu)
            }
            
            apps[name].count = apps[name].count + 1;
            
            
        })
        
    })
    
    return Object.values(apps);
    
}