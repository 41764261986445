import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserWidget } from '@frontend-components/admin';

const WidgetUser = ({user, widgetSize, onSelect}) => {

    return (
        <UserWidget user={user} widgetSize={widgetSize} onSelect={onSelect} />
    )
}

function mapStateToProps(state) {
	return {
      	user: state.user
	};
}  

export default connect(
  mapStateToProps,
)(WidgetUser);