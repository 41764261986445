export default {
    type: "array",
    minItems: 7,
    maxItems: 7,
    items: [
        {
            day: 1,
            open: true
        }
    ],  
    items: {
        type: "object",
        properties: {
            day: {
                type: "string",
                enum: ["1","2","3","4","5","6","0"],
                enumNames: [
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                    "Sun"
                ],
                readonly: true
            },
            open: {
                type: "boolean"
            }
        },
        dependencies: {
            open: {
                oneOf: [
                    {
                        properties: {
                            open: {
                                "enum": [
                                    true
                                ]
                            },
                            opensAt: {
                                type: "string",
                                format: "time"
                            },
                            closesAt: {
                                type: "string",
                                format: "time"
                            }
        
                        }
                    }
                ]
            }
        }
    }
}    