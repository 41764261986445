export default {
    "ui:options": {
        "grid": true,
        "spacing": 2
    },
    "ui:fieldset": [
        "category",

        "director",
        "producer",
        "series",
        "season",
        "episode",
  
        "locationShot",
        "locationShown",
  
        "album",
        "artist",
        "composer",
        "copywriter",
        "releaseYear",
  
        "recordCompany",
        "catalogNumber"
  
    ],
    "category": {
        "ui:title": "Kategori",
        "ui:placeholder": "Velg kategori"
    },
    "keywords": {
        "ui:widget": "tags",
        "ui:title": "Stikkord",
        "ui:help": "Enter keyword terms or phrases to describe the subject of content in the photograph.",
    },
    "license": {
        "ui:widget": "selectLicense",
        "ui:title": "Lisens",
        "ui:help": "Velg en lisens for dette innholdet.",
    },
    "copyright": {
        "ui:title": "Copyright",
        "ui:help": "Beskrivelse av opphavsrett"
    },
    "copyrightYear": {
        "ui:title": "Copyright year",
        "ui:help": "Beskrivelse av opphavsrett"
    },
    "director": {
        "ui:title": "Regissør"
    },
    "producer": {
        "ui:title": "Produsent"
    },
    "series": {
        "ui:title": "Serie",
        "ui:help": "Evt. serie videoen er en del av."
    },
    "season": {
        "ui:title": "Sesong",
        "ui:help": "Evt. sesong videoen er den del av.."
    },
    "episode": {
        "ui:title": "Episode",
        "ui:help": "Evt. episode av en serie eller sesong."
    },
    "locationShot": {
        "ui:title": "Steder spilt inn",
        "ui:widget": "textarea",
        "ui:help": "Steder hvor videon ble spilt inn."
    },
    "locationShown": {
        "ui:title": "Steder som vises",
        "ui:widget": "textarea",
        "ui:help": "Steder som vises i videoen."
    },
    "album": {
        "ui:title": "Albumtittel",
        "ui:help": "Tittel på albumet låten er utgitt på."
    },
    "artist": {
        "ui:title": "Artist"
    },
    "composer": {
        "ui:title": "Komponist"
    },
    "copywriter": {
        "ui:title": "Tekstforfatter",
    },
    "releaseYear": {
        "ui:title": "Utgivelsesår"
    },
    "recordCompany": {
        "ui:title": "Plateselskap",
        "ui:help": "Plateselskap og/eller label låten er utgitt på."
    },
    "catalogNumber": {
        "ui:title": "Katalognummer",
        "ui:help": "Plateselskapets katalognummer."
    },
}