let enumValues = []
let i = 5;

while (i <= 100) {
    enumValues.push(i)
    i = i + 5
}


export default {
  type: "number",
  enum: enumValues,
//  default: 20
}