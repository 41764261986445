import { API } from '../settings';
import { requestDocument, receiveDocument } from '../actions/document';
import { requestMedia, receiveMedia } from '../actions/media';
import { requestBulk, addBulkItem, removeBulkItem, deleteBulkItem, eraseBulkItem, restoreBulkItem, receiveBulkEdit } from '../actions/bulk';
import { requestUpload, receiveUploadItem, receiveUploadStatus } from '../actions/upload';
import { saveModel, deleteModel, eraseModel, restoreModel } from './model';

import qs from 'query-string';

export const bulkEraseAll = ({models, ...query}) => {

    let url = API + '/admin/api/' + models + '/search';

    query.fl = "uniqueId"
    query.status = "trash"
  
    if (query) {
        url = url + '?' + qs.stringify(query);
    }
    

    return function (dispatch) {
  
        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {

            console.log('ERASE ALL', data)

            data.models && data.models.map(model => {
                dispatch(eraseModel(models, model.uniqueId))
            })


        })

  }
    
}

export function bulkEdit(formData) {

  return function(dispatch) {
    dispatch(receiveBulkEdit(formData))
  }
  
}

export function bulkDelete(id) {

  return function(dispatch) {
    dispatch(deleteBulkItem(id))
  }
  
}

export function bulkErase(id) {

  return function(dispatch) {
    dispatch(eraseBulkItem(id))
  }
  
}

export function bulkRestore(id) {

  return function(dispatch) {
    dispatch(restoreBulkItem(id, 'restore'))
  }
  
}

// add + remove

export function bulkAdd(id, data) {
  
  return function (dispatch) {
    dispatch(addBulkItem(id, data))
  }
  
}

export function bulkRemove(id) {

  return function(dispatch) {
    dispatch(removeBulkItem(id))
  }
  
}

// remove all

export function bulkReset() {

    return function(dispatch) {
        dispatch(requestBulk())
    }
  
}

