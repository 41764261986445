// Apps

import admin from "./admin.js";
import dashboard from "./dashboard.js";

import userDashboard from "./userDashboard.js";
import userProfile from "./userProfile.js";
import userInbox from "./userInbox.js";
import userCalendar from "./userCalendar.js";
import userMedia from "./userMedia.js";
import userAnalytics from "./userAnalytics.js";
import userContacts from "./userContacts.js";

import siteDashboard from "./siteDashboard.js";
import siteSettings from "./siteSettings.js";
import siteMedia from "./siteMedia.js";
import siteAnalytics from "./siteAnalytics.js";

import appPrimus from "./appPrimus.js";
import appKompass from "./appKompass.js";
import appKiosk from "./appKiosk.js";
import appDM from "./appDM.js";
import appM24 from "./appM24.js";
import appKp from "./appKp.js";
import appMinner from "./appMinner.js";

export {
    admin,
    dashboard,
    userDashboard,
    userProfile,
    userInbox,
    userCalendar,
    userMedia,
    userAnalytics,
    userContacts,
    siteDashboard,
    siteSettings,
    siteMedia,
    siteAnalytics,
    appPrimus,
    appDM,
    appM24,
    appKompass,
    appKp,
    appKiosk,
    appMinner
}

export const userApps = {
    "dashboard": userDashboard,
    "profile": userProfile,
    "inbox": userInbox,
    "calendar": userCalendar,
    "media": userMedia,
    "analytics": userAnalytics,
    "contacts": userContacts,
}

export const siteApps = {
    "dm": appDM,
    "m24": appM24,
    "kp": appKp,
    "kiosk": appKiosk,
    "primus": appPrimus,
    "minner": appMinner,
    "kompass": appKompass,
}

export default {
    "admin": admin,
    "dashboard": dashboard,

    "user/dashboard": userDashboard,
    "user/profile": userProfile,
    "user/inbox": userInbox,
    "user/calendar": userCalendar,
    "user/media": userMedia,
    "user/analytics": userAnalytics,
    "user/contacts": userContacts,

    "site/dashboard": siteDashboard,
    "site/settings": siteSettings,
//    "site/media": siteMedia,
//    "site/analytics": siteAnalytics,

    "site/dm": appDM,
    "site/m24": appM24,
    "site/kp": appKp,
    "site/kiosk": appKiosk,

    "site/primus": appPrimus,
    "site/kompass": appKompass,
    "site/minner": appMinner

}