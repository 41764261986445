import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsChart } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

class ChartObjectTypes extends Component {

    static defaultProps = {
        loadingTitle: "Mest besøkte objekttyper",
        title: "Hvilke objekttyper besøkes mest?",
        type: "area",
        stacked: true,
        tooltipLabel: ":ga:screenColors",
        scopes: {
            today: {
                startDate: "today",
                endDate: "today",
                dimensions: "nthHour",
                orderBy: "nthHour"
            },
            yesterday: {
                startDate: "yesterday",
                endDate: "yesterday",
                dimensions: "nthHour",
                orderBy: "nthHour"
            },
            ['7days']: {
                startDate: "7daysAgo",
                endDate: "yesterday",
                dimensions: "nthDay",
                orderBy: "nthDay"
            },
            ['28days']: {
                startDate: "28daysAgo",
                endDate: "yesterday",
                dimensions: "nthWeek",
                orderBy: "nthWeek"
            },
            ['quarter']: {
                startDate: "91daysAgo",
                endDate: "yesterday",
                dimensions: "nthMonth",
                orderBy: "nthMonth"
            },
            ['year']: {
                startDate: "364daysAgo",
                endDate: "yesterday",
                dimensions: "nthMonth",
                orderBy: "nthMonth"
            }
        },
        query: {
            reportType: 0,
            countryId: "no",
            offset: 0,
            owner: 1,
            rows: 10,
            startDate: "28daysAgo",
            endDate: "7daysAgo",
            dimensions: "nthDay,screenColors",
            metrics: "sessions",
            orderBy: "nthDay",
            sort: "ascending",
        },
    }
    
    componentDidMount() {
        this.getData()
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.id !== this.props.id) {
            this.getData()
        }
        
        if (prevProps.scope !== this.props.scope) {
            this.getData()
        }

    }

    
    getData = () => {
        let { query, scopes, id, scope } = this.props;

        if (scope && scopes[scope]) {
            query = {
                ...query,
                ...scopes[scope]
            }
        }

        this.props.getReports({
            ...query,
            id: id
        })
        
        
    }
    
    render() {
        const { theme, data, scope, id } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        return (
            <AnalyticsChart {...this.props} scope={scope} report={report} theme={theme} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	theme: state.app.theme,
    	data: state.reports,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChartObjectTypes);