import React, { useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import Cropper from 'react-cropper';
import "./ImageCropper.scss"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }
}));

const ImageCropper = ({className, imageUrl, imageCropdata, options, onChange}) => {
    const classes = useStyles()
    const cropperRef = useRef(null);

    if (!imageUrl) {
        return (
            <p>No imageUrl for cropper</p>
        )
    }

    const onReady = () => {
        cropperRef.current.setData(imageCropdata);
    }

    const onCrop = () => {
        const cropdata = cropperRef.current.getData();

        const newFormData = {
            ...cropdata,
        }

        onChange && onChange(newFormData)

    }

    return (
        <Cropper
            className={className || classes.root}
            ref={cropperRef}
            src={imageUrl}
            {...options}
            ready={onReady.bind(this)}
            crop={onCrop.bind(this)} />
    )

}

ImageCropper.defaultProps = {
    imageUrl: undefined,
    options: {
        viewMode: 0,
        dragMode: "crop",
        initialAspectRatio: undefined,
        aspectRatio: undefined,
        responsive: true,
        restore: true,
        checkCrossOrigin: false,
        checkOrientation: true,
        modal: true,
        guides: true,
        center: true,
        highlight: true,
        background: true,
        autoCrop: true,
        autoCropArea: 1,
        movable: true,
        rotatable: true,
        scalable: true,
        zoomable: true,
        zoomOnTouch: true,
        zoomOnWheel: true,
        wheelZoomRatio: 0.1,
        cropBoxMovable: true,
        cropBoxResizable: true,
        toggleDragModeOnDblclick: true
    },
    imageCropdata: {
        "x": undefined,
        "y": undefined,
        "width": undefined,
        "height": undefined,
        "rotate": undefined
    }
}

export default ImageCropper;