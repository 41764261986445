import { getUiOptions } from "./"

const getVisibilityOptions = (props) => {
    const { hideable, deletable, restorable, removable, formData, onRestore, onDelete, onRemoveItem, onSettingsChange } = props;

    // hidden
    
    let hidden = false;
    
    if (formData && formData.hidden) {
        hidden = formData.hidden
    }
    
    // hideable

    let options = []
    
    if (hideable && onSettingsChange) {
        
        options.push({
            name: "hidden",
            icon: "visibility",
            label: "Visible",
            value: false,
            onClick: () => onSettingsChange('hidden', false)
        })

        options.push({
            name: "hidden",
            icon: "visibility_off",
            label: "Hidden",
            value: true,
            onClick: () => onSettingsChange('hidden', true)
        })
        
    }
    
    // deletable && restorable
    
    if (deletable && restorable) {
        
        if (formData && formData._action === "delete") {
            options.push({
                variant: "outlined",
                name: "restore",
                label: "Restore",
                onClick: onRestore
            })            
        } else {
            options.push({
                variant: "outlined",
                color: "warning",
                name: "remove",
                label: "Delete",
                onClick: onDelete
            })            
        }

    // deletable
        
    } else if (deletable && onDelete) {

        options.push({
            variant: "outlined",
            color: "warning",
            name: "remove",
            label: "Delete",
            onClick: onDelete
        })            

    // removable

    } else if (removable && onRemoveItem) {

        options.push({
            variant: "outlined",
            color: "warning",
            name: "remove",
            label: "Remove",
            onClick: onRemoveItem
        })            

    }
    
    // set icon

    let icon;

    if (formData && formData._action && formData._action === "delete") {
        icon = "restore"
    } else if (hidden) {
        icon = "visibility_off"
    } else {
        icon = "visibility"
    }
    
    return {
        name: "visibility",
        icon: icon,
        value: hidden,
        options: options
    }
    
}

const getVisibilityToolbar = (props, toolbar = []) => {
    const { deletable, restorable, hideable, addable, removable, formData, onDelete, onRestore, onRemoveItem, onSettingsChange } = props;

    // restore

    if (deletable && restorable && formData && formData._action && formData._action === "delete") {
            
        toolbar = [];

        toolbar.push({
            name: "restore",
            icon: "restore",
            onClick: onRestore
        });
        
        return toolbar;

    // hideable => visibility options
    
    } else if (hideable && (deletable || restorable)) {

        let visibility = getVisibilityOptions(props, toolbar)
        toolbar.push(visibility)

    // hideable

    } else if (hideable && formData.hidden) {

        toolbar.push({
            name: "hidden",
            icon: "visibility_off",
            value: true,
            onClick: () => onSettingsChange('hidden', false)
        })

    } else if (hideable) {

        toolbar.push({
            name: "hidden",
            icon: "visibility",
            value: true,
            onClick: () => onSettingsChange('hidden', true)
        })
       
    // deletable

    } else if (deletable) {
        toolbar.push({
            name: "remove",
            icon: "remove_circle",
            onClick: onDelete
        })

    // removable
        
    } else if (removable && onRemoveItem) {
        toolbar.push({
            name: "remove",
            icon: "remove_circle",
            onClick: onRemoveItem
        })
        
    }

    return toolbar

}

const getArrayItemToolbar = (props, toolbar = []) => {

    const {
        draggable, orderable,
        hasToolbar, hasMoveUp, hasMoveDown,
        onMoveUp, onMoveDown
    } = props;

    if (draggable) {
        return toolbar
    }

    if (orderable === false) {
        return toolbar
    }

    if (hasMoveUp) {
        toolbar.push({
            name: "moveUp",
            icon: "arrow_upward",
            onClick: onMoveUp
        })
    } else if (hasToolbar) {
        toolbar.push({
            name: "moveUp",
            icon: "arrow_upward",
            disabled: true
        })
    }

    if (hasMoveDown) {
        toolbar.push({
            name: "moveDown",
            icon: "arrow_downward",
            onClick: onMoveDown
        })
    } else {
        toolbar.push({
            name: "moveDown",
            icon: "arrow_downward",
            disabled: true
        })
    }
    
    return toolbar;
    
}

const getEditableToolbar = (props, toolbar = []) => {
    const { editable, editing, onEdit, onSave } = props

    if (editable && editing) {
        toolbar.unshift({
            label: "Save",
            onClick: onSave
        })
    } else if (editable) {
        toolbar.unshift({
            icon: "edit",
            onClick: onEdit
        })
    }

    return toolbar

}

export const getUiToolbar = (props) => {
    const { isArrayItem, uiSchema } = props;
    const uiOptions = getUiOptions(uiSchema)

    if (uiOptions.toolbar) {
        return uiOptions.toolbar
    }

    let toolbar = []

    if (isArrayItem) {
        toolbar = getArrayItemToolbar(props)
    }

 //   toolbar = getEditableToolbar(props, toolbar)
    toolbar = getVisibilityToolbar(props, toolbar)
    
    return toolbar;    
  
}