// initialState

let initialState = {
    isLoading: true,
    uniqueId: undefined,
    siteId: undefined,
    collectionId: undefined,
    name: '',
    root: '',
    title: '',
    pathname: '',
    parents: [],
    filters: [],
    languages: [],
    theme: {
        primaryColor: undefined
    },
    button: undefined,
    edit: [],
    menu: [],
    menuTree: undefined,
    layout: "login",
    drawer: {
        expanded: false
    },
    nav: {
        expanded: false
    },
    header: {
        expanded: false
    },
    search: {
        expanded: false
    },
    sidebar: {
        expanded: false
    },
    subview: {
        expanded: false
    }
};

// app state

const app = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'REQUEST_APP':
            return {
                ...initialState,
                header: state.header,
                sidebar: state.sidebar,
                layout: state.layout,
                isLoading: true
            }

        case 'RECEIVE_APP':
            return {
                ...state,
                ...action.app,
                isLoading: false
            }

        case 'RECEIVE_APP_SEARCH':

            return {
                ...state,
                search: action.search
            }

        case 'RECEIVE_APP_QUERY':

            return {
                ...state,
                search: {
                    ...state.search,
                    [action.key]: action.value
                }
            }

        case 'RECEIVE_APP_SUBVIEW':

            return {
                ...state,
                subview: action.subview
            }

    case 'RECEIVE_PATHNAME':
        
      return Object.assign({}, state, {
        pathname: action.pathname,
      })

    case 'RECEIVE_PARENTS':
        
      return Object.assign({}, state, {
        parents: action.parents,
      })

    case 'RECEIVE_FILTERS':
        
      return Object.assign({}, state, {
        filters: action.filters,
      })

    case 'RECEIVE_APP_LAYOUT':
        
      return Object.assign({}, state, {
        layout: action.layout,
      })

    case 'RECEIVE_APP_LANGUAGES':

      return Object.assign({}, state, {
        languages: action.languages,
      })

    case 'RECEIVE_APP_HEADER':

      return Object.assign({}, state, {
        header: {
          ...state.action,
          title: action.title,
          subtitle: action.subtitle
        }
      })

    case 'REQUEST_APP_QUERY':

      return Object.assign({}, state, {
        search: {
          ...state.search,
          [action.key]: ""
        }
      })


    case 'RECEIVE_APP_BUTTON':

      return Object.assign({}, state, {
          button: action.button,
      })

    case 'RECEIVE_APP_MENU':

      return Object.assign({}, state, {
        menu: action.menu,
      })

    case 'REQUEST_APP_THEME':

      return Object.assign({}, state, {
        theme: {}
      })

    case 'RECEIVE_APP_THEME':

      return Object.assign({}, state, {
        theme: action.theme
      })

    case 'TOGGLE_APP_SECTION':

        return Object.assign({}, state, {
            [action.name]: {
                ...state[action.name],
                expanded: !state[action.name].expanded
            }
        })
  
    case 'EXPAND_APP_SECTION':

        return Object.assign({}, state, {
            [action.name]: {
                ...state[action.name],
                expanded: true
            }
        })
  
    case 'COLLAPSE_APP_SECTION':

        return Object.assign({}, state, {
            [action.name]: {
                ...state[action.name],
                expanded: false
            }
        })

    default:

      return state

    }

}

export default app;