import booking from './booking';
import place from './place';
import program from './program';
import person from './person';
import product from './product';

const schemas = [
    booking,
    place,
    program,
    person,
    product
]

export default schemas;