import React, { Component } from 'react';

import BarsItem from "./BarsItem"

class Bars extends Component {
    
    getBarGroups = () => {
        const { bars } = this.props;
        
        let groups = {}
        
        bars.map((bar) => {
            const { x, y, width, data } = bar;
            let { index } = data;
            
            if (!groups[index]) {
                groups[index] = {
                    id: index,
                    x: x,
                    y: y,
                    width: 0,
                    bars: []
                }
            }
            
            groups[index].width = groups[index].width+width;
            groups[index].bars.push(bar)
            
        })
        
        return Object.values(groups)
        
    }
    
    getBar = (bar, index) => {
        const { groupMode } = this.props;
        
        let { width, x } = bar;
        
        if (groupMode === "grouped") {
            width = width + 6 * index
            x = x - index * 12;
        } else {
            width = width - 12
            x = x + 6
        }
        
        return {
            ...bar,
            width: width,
            x: x
        }
        
    }

    renderBar = (bar, index) => {

//        const barprops = this.getBar(bar, index);

        bar = {
            ...bar,
            id: bar.key
        }
    
        const { current } = this.props;
        const isCurrent = current && current.id === bar.id;
        
        return (
            <BarsItem {...this.props}
                bar={bar}
                isCurrent={isCurrent} />
        )
        
    }
    
    renderGroup = (group, index) => {
        const { id, x, y, bars } = group;

        const className = "bars  bars--" + id;

        return (
            <g key={index} className={className}>
                { bars.map(this.renderBar) }
            </g>
        )
        
    }

    render() {
        const groups = this.getBarGroups();

        console.log('BARS', this.props);
        
        return (
            <g className="bars">
                { groups.map(this.renderGroup) }
            </g>
        )
        
    }

}

export default Bars