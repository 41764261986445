// initialState

let initialState = {
    roles: undefined,
    rolesSchema: undefined,
    rolesOptions: undefined,
    users: [],
    usersById: {},
    sites: [],
    sitesById: {},
    sitesByName: {},
    apps: [],
    appsById: {},
    appsByName: {},
    rolesBySite: {}
};

const auth = (state = initialState, action) => {

    switch (action.type) {
  	
        case 'REQUEST_AUTH_ROLES': 
            return {
                ...state,
                roles: undefined
            }

        case 'RECEIVE_AUTH_ROLES':
        
            let rolesSchema = {
                type: "number",
                enum: [],
                enumNames: []
            }
            
            let options = []
        
            action && action.data && action.data.models.map(role => {
                rolesSchema.enum.push(role.id)
                rolesSchema.enumNames.push(role.name)
                options.push({
                    label: role.name,
                    value: role.id
                })
            })
            
            return {
                ...state,
                rolesSchema: rolesSchema,
                rolesOptions: options,
                roles: [action.data]
            }

        case 'REQUEST_AUTH_APPS': 
            return {
                ...state,
                apps: []
            }

        case 'RECEIVE_AUTH_APPS': 
            return {
                ...state,
                apps: action.data
            }

        case 'RECEIVE_AUTH_APP_BY_NAME': 
        
            return {
                ...state,
                appsByName: {
                    ...state.appsByName,
                    [action.name]: action.data
                }
            }

        case 'RECEIVE_AUTH_APP_BY_ID': 
        
            return {
                ...state,
                appsById: {
                    ...state.appsById,
                    [action.id]: action.data
                }
            }

        case 'REQUEST_AUTH_SITES': 
            return {
                ...state,
                sites: []
            }

        case 'RECEIVE_AUTH_SITES': 
            return {
                ...state,
                sites: action.data
            }

        case 'RECEIVE_AUTH_SITE_BY_ID': 
        
            return {
                ...state,
                sitesById: {
                    ...state.sitesById,
                    [action.id]: action.data
                }
            }

        case 'RECEIVE_AUTH_SITE_BY_NAME': 
        
            return {
                ...state,
                sitesByName: {
                    ...state.sitesByName,
                    [action.name]: action.data
                }
            }

        case 'REQUEST_AUTH_SITE_COLLECTIONS': 
            return {
                ...state,
                sitesByName: {
                    ...state.sitesByName,
                    [action.id]: {
                        ...state.sitesByName[action.id],
                        authCollections: []
                    }
                }
            }

        case 'RECEIVE_AUTH_SITE_COLLECTIONS': 

            return {
                ...state,
                sitesByName: {
                    ...state.sitesByName,
                    [action.id]: {
                        ...state.sitesByName[action.id],
                        authCollections: action.data
                    }
                }
            }

        case 'REQUEST_AUTH_SITE_APPS': 
            return {
                ...state,
                sitesByName: {
                    ...state.sitesByName,
                    [action.id]: {
                        ...state.sitesByName[action.id],
                        authApps: []
                    }
                }
            }

        case 'RECEIVE_AUTH_SITE_APPS': 

            const apps = action && action.data;
        
            return {
                ...state,
                sitesByName: {
                    ...state.sitesByName,
                    [action.id]: {
                        ...state.sitesByName[action.id],
                        authApps: apps || []
                    }
                }
            }

        case 'REQUEST_AUTH_USERS': 
            return {
                ...state,
                users: []
            }

        case 'RECEIVE_AUTH_USERS': 
            return {
                ...state,
                users: action.data
            }

        case 'REQUEST_AUTH_USER_BY_ID': 
            return {
                ...state,
                usersById: {
                    ...state.usersById,
                    [action.id]: undefined
                }
            }

        case 'RECEIVE_AUTH_USER_BY_ID':
        
            return {
                ...state,
                usersById: {
                    ...state.usersById,
                    [action.id]: action.data
                }
            }

        default:
            return state

    }

}

export default auth;