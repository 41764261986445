import React, { Component } from 'react';
import PropTypes from "prop-types";
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#eee",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "12px"
    },    
    info: {
        flexBasis: "100%",
    },
    typography: {
        display: "block",
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "normal",
        fontSize: "14px"
    },
    button: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        fontSize: "14px",
        padding: "6px 9px",
        border: "1px solid"
    }
}));

const UserAppInfo = ({version = 1, date, onLogout}) => {
    const classes = useStyles()

    return (
        <footer className={classes.root}>
            <div className={classes.info}>
                <Typography className={classes.typography}><strong>Version</strong> {version}</Typography>
                <Typography className={classes.typography} datetime={date}><strong>Date</strong> {date}</Typography>
            </div>
            <ButtonBase className={classes.button} onClick={onLogout}>Logout</ButtonBase>
        </footer>
    )

}

UserAppInfo.propTypes = {
    version: PropTypes.string,
    date: PropTypes.string,
    onLogout: PropTypes.func
}

export default UserAppInfo;
