import React from 'react';

class ButtonIcon extends React.Component {

    render() {

        const { label } = this.props;
        
        if (!label) {
            return false;
        }
        
        return (
			<i className="admin-button__label">{ label }</i>
        )

    }

}

// export default

export default ButtonIcon;