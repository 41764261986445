import React from "react";

const icon = (

<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
  <g fill="none" fillRule="evenodd">
    <path fill="#CCCCCC" d="M46,17 L18,17 C15.78,17 14.02,18.8 14.02,21 L14,49 C14,51.2 15.78,53 18,53 L46,53 C48.2,53 50,51.2 50,49 L50,21 C50,18.8 48.2,17 46,17 Z"/>
    <path fill="#F2F2F2" d="M32,0 L4,0 C1.78,0 0.02,1.8 0.02,4 L0,32 C0,34.2 1.78,36 4,36 L32,36 C34.2,36 36,34.2 36,32 L36,4 C36,1.8 34.2,0 32,0 Z" transform="translate(14 14)"/>
    <path fill="#0666B0" fillRule="nonzero" d="M28,32 C30.21,32 32,30.21 32,28 C32,25.79 30.21,24 28,24 C25.79,24 24,25.79 24,28 C24,30.21 25.79,32 28,32 Z M28,34 C25.33,34 20,35.34 20,38 L20,40 L36,40 L36,38 C36,35.34 30.67,34 28,34 Z"/>
    <path fill="#666666" d="M50.5005312,14.5005312 L45.5005312,14.5005312 C43.2805312,14.5005312 41.5205312,16.3005312 41.5205312,18.5005312 L41.5005312,22.5005312 C41.5005312,22.5005312 50.5005312,22.5005312 50.5005312,22.5005312 C50.5005312,17.1050234 50.5005312,14.4383567 50.5005312,14.5005312 Z" transform="rotate(90 46 18.5)"/>
    <path fill="#999999" d="M50.5005312,41.5005312 L45.5005312,41.5005312 C43.2805312,41.5005312 41.5205312,43.3005312 41.5205312,45.5005312 L41.5005312,49.5005312 C41.5205312,49.5005312 50.5005312,49.5005312 50.5005312,49.5005312 C50.5005312,44.1050234 50.5005312,41.4383567 50.5005312,41.5005312 Z" transform="matrix(0 -1 -1 0 91.5 91.5)"/>
    <rect width="8" height="9" x="42" y="23" fill="#777777"/>
    <rect width="8" height="9" x="42" y="32" fill="#888888"/>
  </g>
</svg>
        
);

export default {
    uniqueId: "user/contacts",
    appType: "user/service",
    name: "contacts",
    icon: icon,
    title: "Kontakter"
};