export default {
    "ui:field": "pageSettings",
    "ui:nav": true,
    "ui:fieldset": [
        "page",
        "keywords",
        "license",
        "location"
    ],
    "page": {
        "ui:layout": "section",
        "ui:fieldset": [
            "title",
            "parentId"
        ],
        "parentId": {
            "ui:field": "parentId",
            "ui:documentType": "topic",
            "ui:widget": "autocomplete",
            "ui:title": "ParentId"
        }
    },
    "location": {
        "ui:layout": "section",
        "ui:fieldset": [
            "title",
            "parentId"
        ],
        "parentId": {
            "ui:field": "parentId",
            "ui:documentType": "topic",
            "ui:widget": "autocomplete",
            "ui:title": "ParentId"
        }
    }

   
}