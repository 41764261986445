import React from "react";
import TexteditorField from "./TexteditorField"

const TexteditorWidget = ({value = "", options, onChange, ...props}) =>  {

    const label = options.title || props.title || props.label || null
    const helperText = options.help || null

    return (
        <TexteditorField {...props} label={label} helperText={helperText} value={value} onChange={onChange} />
    )
    
}

export default TexteditorWidget;
