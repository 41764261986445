import items from "./itemsUi"

const mediaDefault = {
    "type": "media",
    "role": "group",
    "pathname": "media",
    "icon": "image",
    "title": "Media",
    "query": {
        "status": "NOT trash",
    },
    "children": [
        {
            "type": "media/image",
            "pathname": "image",
            "title": "Bilder",
            "query": {
                "status": "NOT trash",
            }
        },
        {
            "type": "media/video",
            "pathname": "video",
            "title": "Video",
            "query": {
                "status": "NOT trash",
            }
        },
        {
            "type": "media/audio",
            "pathname": "audio",
            "title": "Audio",
            "query": {
                "status": "NOT trash",
            }
        },
    ]
}

const latestDefault = {
    "type": "documents",
    "role": "group",
    "pathname": "latest",
    "icon": "access_time",
    "title": "Siste",
    "query": {
        "status": "NOT trash",
        "sort": "updatedAt"
    },
    "children": [
        {
            "type": "documents",
            "pathname": "today",
            "title": "I dag",
            "query": {
                "updatedAt": "today",
                "status": "NOT trash",
                "sort": "updatedAt"
            }
        },
        {
            "type": "documents",
            "pathname": "week",
            "title": "Denne uken",
            "query": {
                "updatedAt": "lastWeek",
                "status": "NOT trash",
                "sort": "updatedAt"
            }
        },
        {
            "type": "documents",
            "pathname": "month",
            "title": "Denne måneden",
            "query": {
                "updatedAt": "lastMonth",
                "status": "trash",
                "sort": "updatedAt"
            }
        }
    ]
}

const trashDefault = {
    "type": "documents",
    "role": "group",
    "pathname": "trash",
    "icon": "delete",
    "title": "Slettet",
    "query": {
        "status": "trash",
        "sort": "deletedAt"
    },
    "children": [
        {
            "type": "documents",
            "pathname": "today",
            "title": "I dag",
            "query": {
                "deletedAt": "today",
                "status": "trash",
                "sort": "deletedAt"
            }
        },
        {
            "type": "documents",
            "pathname": "week",
            "title": "Denne uken",
            "query": {
                "deletedAt": "lastWeek",
                "status": "trash",
                "sort": "deletedAt"
            }
        },
        {
            "type": "documents",
            "pathname": "month",
            "title": "Denne måneden",
            "query": {
                "deletedAt": "lastMonth",
                "status": "trash",
                "sort": "deletedAt"
            }
        }
    ]
}

export default {
    "ui:layout": "section",
    "ui:title": "Menu",
    "ui:icon": "menu",
    "ui:options": {
        "label": false,
        "draggable": true
    },
    "ui:buttons": [
        {
            "icon": "add",
            "label": "Legg til"
        },
        {
            "icon": "add",
            "label": "Media",
            "item": mediaDefault
        },
        {
            "icon": "add",
            "label": "Latest",
            "item": latestDefault
        },
        {
            "icon": "add",
            "label": "Trash",
            "item": trashDefault
        }
    ],
    "ui:buttonGroupProps": {
        "fullWidth": true,
    },
    "items": {
        ...items,
        "ui:deletable": true,
        "ui:hideable": true,
        "ui:settings": ["role"],
        "menu": {
            ...items.menu,
            "children": {
                "ui:layout": "list",
                "ui:preview": {
                    "select": {
                        "title": "title"
                    },
                    prepare({formData}) {
                        const count = formData.length;
                        const title = count + " seksjoner"
                        return {
                            title: title
                        }
                    }
                },
                "ui:options": {
                    "draggable": true
                },
                "ui:buttons": [
                    {
                        "icon": "add",
                        "label": "Legg til"
                    }
                ],
                "ui:buttonGroupProps": {
                    "fullWidth": true,
                },
                "items": items
            }            
        }
    }
}