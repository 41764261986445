export default {
    "type": "object",
    "properties": {
        "id": {
            "type": "string"
        },
        "title": {
            "type": "string"
        },
        "content": {
            "type": "object",
            "properties": {
                "deviceId": {
                    "type": "number"
                },
                "deviceTitle": {
                    "type": "string"
                },
                "deviceType": {
                    "type": "string",
                    "enum": ["windows","ipad"],
                    "enumNames": ["Windows","iPad"]
                },
                "serialNumber": {
                    "type": "string",
                },
                "placement": {
                    "type": "string",
                },
                "backgroundImage": {
                    "type": "image"
                },
                "hasPages": {
                    "type": "array",
                    "items": {
                        "type": "reference",
                        "documentType": "page",
                        "referenceType": "deviceHasPages",
                        "_reverseReferenceType": "pageIsPartOf",
                        "_reverseReference": "isPartOf[]",
                    }
                }
            }
        }
    }
}