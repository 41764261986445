export default {
  "type": "string",
  "enum": [
    "photograph",
    "illustration",
    "vector",
    "painting",
    "other"
  ],
  "enumNames": [
    "Fotografi",
    "Illustrasjon",
    "Vektor",
    "Maleri",
    "Annet"
  ]
}