import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCollectionByName, getCollectionApp } from './app';

import CollectionRoutes from './CollectionRoutes';

const CollectionApp = ({user, site, collection, app, ...props}) => {
    const { appName } = props.match.params

    useEffect(() => {
        props.getCollectionByName(appName)
    }, [appName])

    const userId = user.id;
    const siteId = site.id;
    const collectionId = collection.id;

    useEffect(() => {
        userId && siteId && collectionId && props.getCollectionApp({collection, site, user})
    }, [collectionId])

    if (!userId || !siteId || !collectionId || !app.uniqueId) {
        return (
            <div>
                <p>Loading site .... {site.uniqueId}</p>
                <p>Loading collection .... {collection.uniqueId}</p>
                <p>Loading app .... {app.uniqueId}</p>
            </div>
        )
    }

    return (
        <CollectionRoutes {...props} />
    )
 
}

const mapStateToProps = (state) => {
	return {
        site: state.site,
        user: state.user,
        collection: state.collection,
        app: state.app
	}
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
    getCollectionByName, getCollectionApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionApp);

