import React, { Component } from 'react';
import AppsGridItem from "./AppsGridItem";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    grid: {
        display: "flex",
        flexWrap: "wrap",
        margin: "auto",
    }
}));

const AppsGrid = ({className, apps = undefined, size, children, onSelect}) => {
    const classes = useStyles()

    return (
        <section className={className || classes.root}>
            <div className={classes.grid}>
                {apps && apps.length && apps.map(app => {
                    return (
                        <AppsGridItem {...app} size={size} onClick={() => onSelect(app)} />
                    )
                })}
                {children}
            </div>
        </section>
    )

}

AppsGrid.defaultProps = {
    size: "large"
}

export default AppsGrid;