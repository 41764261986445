import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InboxWidget } from '@frontend-components/admin';

const EventsList = () => {

    return <p>events</p>
}

class WidgetInbox extends Component {
    
    static defaultProps = {
        expanded: true,
        removable: false
    }

    getEvents = () => {
        
        return [
            {
                title: "Tor-Arne Hetland Hva koster DigitaltMuseum?"
            },
            {
                title: "Jonas Fjeld Kan jeg kjøpe Kompass?"
            },
            {
                title: "Solbjørg Sørensen: Hvor bor du?"
            },
            {
                title: "Tor-Arne Hetland Hva koster DigitaltMuseum?"
            },
            {
                title: "Jonas Fjeld Kan jeg kjøpe Kompass?"
            },
            {
                title: "Solbjørg Sørensen: Hvor bor du?"
            }
        ]
        
    }

    render() {
        const { app } = this.props;
        const url = app.root + "/inbox"
        
        const events = this.getEvents()
    
        return (
            <InboxWidget icon="inbox" title="6 nye meldinger" label="Gå til innboks" url={url} {...this.props}>
                <EventsList events={events} />
            </InboxWidget>
        )
    
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app
	};
}  

export default connect(
  mapStateToProps,
)(WidgetInbox);