import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getApp } from './app';

import CalendarRoutes from "./CalendarRoutes"

const AppCalendar = ({app, ...props}) => {

    useEffect(() => {
        props.getApp(props);
    }, [])

    const layoutOptions = ["year","month","date","schedule"]

    
    return (
        <CalendarRoutes {...props} />
    )

}

function mapStateToProps(state) {
	return {
        user: state.user,
        app: state.app,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppCalendar);

