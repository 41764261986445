const initialState = {
    models: []
}

const authRoles = (state = initialState, action) => {

    switch (action.type) {
    	
        case 'REQUEST_AUTH_ROLES': 
            return initialState

        case 'RECEIVE_AUTH_ROLES':
            return action.data

        default:
            return state
    
    }

}

export default authRoles;