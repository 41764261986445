import settings from "./uiSchemaSettings";
import content from "./uiSchemaContent";

export default {
    "ui:fieldset": [
        "settings",
        "content"
    ],
    "settings": settings,
    "content": content
}