import _ from 'lodash';
import { getUiOptions, getUiFieldset } from "./"

export const getUiMenu = (props) => {
    const { uiSchema, registry } = props;
    const { formContext } = registry

    if (!formContext) {
        return false
    }

    const uiLayout = getUiOptions(uiSchema)
    const uiFieldset = getUiFieldset(props)

    const onExpand = formContext.onExpand

    const { currentId } = formContext
    const { nav } = uiLayout;

    if (!nav) {
        return false;
    }

    let menu = []

    if (uiFieldset) {

        uiFieldset.forEach(field => {

            const { id, name, uiSchema } = field;
            
            const uiOptions = getUiOptions(uiSchema);

            let selected = false;

            if (currentId && currentId.startsWith(id)) {
                selected = true;
            }
            
            const title = uiOptions['title'] || name;
            const icon = uiOptions['icon'] || false;
            
            menu.push({
                ...field,
                selected: selected,
                title: title,
                icon: icon,
                onClick: () => onExpand(field),
            })

        });
        
    }

    if (!menu.length) {
        return false
    }

    const selected = _.find(menu, function (field) {
        return field.selected === true;
    })

    if (menu[0] && !selected) {
        menu[0].selected = true;
    }

    return menu;
    
}