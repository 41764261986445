import React, { Component } from 'react';
import { FormatMetric, FormatDimension } from "../"
import { gaTimeDimensions, getLabelFromProps } from "../../resources/functions/"

import Tooltip from "./ChartTooltip"

class TooltipPoints extends Component {
    
    static defaultProps = {
        tooltipTitle: undefined,
        tooltipLabel: undefined,
        compare: false
    }
    
    getLabel = (id, data) => {
        const { tooltipLabel } = this.props;

        console.log('tooltipProps', this.props)
        
        let labels = [];

        // label from tooltipLabel        
        
        if (tooltipLabel) {
            return getLabelFromProps({...data, label: tooltipLabel})
        }

        // label from id        
        
        const identifiers = id.split('/');
        
        identifiers.map((key) => {
            
            const keyParts = key.split(':')
            
            if (keyParts[0] === "startDate" || keyParts[0] === "endDate") {
              return
            } 
            
            if (gaTimeDimensions.includes(key)) {
                return
            }
            
            labels.push(key)
            
        })
        
        return labels.join(' ');
        
        
    }

    renderLabel = (name, index) => {

        return (
            <i className="admin-tooltip__label">
                <FormatMetric name={name} key={index}/>
            </i>
        )
    
    }
    
    renderPoint = (point, index) => {
        let { dimension, metricType } = this.props;
        
        const { id, color, data, serieId } = point;
        const { datetime, x, y } = data;
        
        console.log('DATA', data);
        
        const label = this.getLabel(serieId, data);
        
        const style = {
            backgroundColor: color
        }

        if (!y) {
            return false
        }
        
        return (
            <li key={index}>
                <div className="admin-tooltip__chip" style={style}></div>
                {label.split(' ').map(this.renderLabel)}
                <i className="admin-tooltip__value"><FormatMetric type={metricType} value={y} /></i>
            </li>
        )
        
    }
    
    renderTitle = (data) => {
        const { dimension, tooltipTitle } = this.props;
        
        if (tooltipTitle) {
            const title = getLabelFromProps({...data, label: tooltipTitle})
            
            return (
                <h2 className="admin-tooltip__title">{title}</h2>
            )
            
        }        

        const { datetime, x } = data;

        let value;

        if (dimension.startsWith('ga:nth')) {
            value = datetime;
        } else {
            value = x;
        }
        
        return (
            <h2 className="admin-tooltip__title"><FormatDimension name={dimension} value={value} /></h2>
        )
        
    }
    
    renderSeries = (series) => {
        return (
            <Tooltip>
                { this.renderTitle(series[0].data)}
                <ul>
                { series.map(this.renderPoint) }
                </ul>
            </Tooltip>
        )
        
    }
    
    render() {
        const { slice, point } = this.props;
        
        let series;
        
        if (slice && slice.points) {
            return this.renderSeries(slice.points)
        }

        if (point) {
            series = this.getSeries([point]);
            return this.renderSeries([point])
        }

        return (
            <Tooltip>
            <p>No points</p>
            </Tooltip>
        )
        
    }

}

export default TooltipPoints