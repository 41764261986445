export default {
    "type": "string",
    "enum": [
        "list",
        "mosaic",
        "imagelegend",
        "imagemap",
        "gallery",
        "autoplay",
        "timeline"
    ],
    "enumNames": [
        "Liste",
        "Mosaikk",
        "Bildeforklaring",
        "Bildekart",
        "Mediagalleri",
        "Autoplay",
        "Tidslinje"
    ],
    "default" : "list"
}