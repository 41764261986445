import React from 'react';
import DocumentEditor from "../AppDocuments/DocumentEditor"
//import registry from "./components/registry"
import registry from "@frontend-components/admin/lib/components/AppKp/components/registry"

const KioskEditor = (props) => {

    return (
        <DocumentEditor {...props}
            registry={registry}
        />

    )

}

export default KioskEditor;