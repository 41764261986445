import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Preview, NavPreview } from "../"

class LayoutPreview extends Component {
    static propTypes = {
    };

    static defaultProps = {
    };

    render() {
        return (
            <Preview>
                {this.props.children}
            </Preview>
        )
    }

}

export default LayoutPreview