import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel, loadModel, editModel, saveModel } from '../redux/functions/model';
import { getSearch } from '../redux/functions/search';
import { LayoutFinder } from "@frontend-components/admin"
import AppFinder from "../AppFinder"
import FinderQuery from '../AppFinder/FinderQuery';
import FinderResults from '../AppFinder/FinderResults';
import FinderModel from '../AppFinder/FinderModel';

const CollectionAdmin = ({models = "collections", modelsById, searchById, ...props}) => {
    const { uniqueId } = props.match.params;

    useEffect(() => {
        props.getModel(models, uniqueId)
    }, [uniqueId])

    const collection = uniqueId && modelsById && modelsById[uniqueId]
    const collectionId = collection && collection.id

    const schemasQuery = {
        id: props.location.pathname + "/schemas",
        models: "schemas",
        status: "NOT trash",
        collectionId: collectionId,
        fl: "id,uniqueId,title"
    }

    useEffect(() => {
        props.getSearch(schemasQuery)
    }, collectionId)

    const schemaModels = searchById && searchById[schemasQuery.id] && searchById[schemasQuery.id].models

    return (
        <AppFinder>xxx

            { collection && <FinderModel model={collection} /> }

            <FinderResults models={schemaModels} />

        </AppFinder>
    )

}

function mapStateToProps(state) {
	return {
        modelsById: state.modelsById,
        searchById: state.searchById
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getModel,
        getSearch
    }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionAdmin);
