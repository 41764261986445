import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSiteDashboard } from '../redux/functions/site';

import { TemplateDashboard } from "../AppDashboard/"

import { WidgetOwner } from "./"
import { WidgetSessions, WidgetRegVsPub, WidgetLiveReport } from "../AppAnalytics"

class SiteDashboard extends Component {

    componentDidMount() {
        this.getApp();
    }

    componentDidUpdate = (prevProps) => {
      
        if (prevProps.match.params.location !== this.props.match.params.location) {
          this.getApp();
        }

        if (prevProps.site !== this.props.site) {
          this.getApp();
        }
    
    }
    
    getApp = () => {
        const { site } = this.props;
        this.props.getSiteDashboard(site);
    }

    onSelect = ({url}) => {
        url && this.props.history.push(url)
    }
    
    render() {
        const { app, theme } = this.props;
        const { pathname } = this.props.location;
        
        return (
            <TemplateDashboard {...app}>
                <WidgetOwner widgetSize="s:8" onSelect={this.onSelect} />
                <WidgetRegVsPub id={pathname + "/regVsPub"} theme={theme} widgetSize="s:m" />
                <WidgetSessions id={pathname + "/yesterday"} scope="yesterday" theme={theme} widgetSize="s:xs" />
                <WidgetSessions id={pathname + "/7d"} scope="7days" theme={theme} widgetSize="s:xs" />
                <WidgetSessions id={pathname + "/28d"} scope="28days" theme={theme} widgetSize="s:xs" />
                <WidgetSessions id={pathname + "/90d"} scope="90days" theme={theme} widgetSize="s:xs" />
                <WidgetSessions id={pathname + "/year"} scope="yearToDate" theme={theme} widgetSize="m:s" />
            </TemplateDashboard>
            
        )
    
    }

}


// mapStateToProps

function mapStateToProps(state) {
	return {
      	site: state.site,
      	theme: state.site.theme,
      	app: state.app,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getSiteDashboard }, 
dispatch);

// export default

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteDashboard);

