import React from 'react';
import NavSearch from "../NavSearch/NavSearch"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        backgroundColor: "white",
        color: "black",
        height: theme.spacing(8),
    },
}));

const AppSearch = ({className, variant, expanded = false, placeholder, q, onChange, onToggle, onReset}) => {
    const classes = useStyles()

    return (
        <NavSearch className={className || classes.root} variant={variant} expanded={expanded} placeholder={placeholder} q={q} onChange={onChange} onToggle={onToggle} onReset={onReset} />
    )

}

export default AppSearch;