import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const RadioBase = ({disabled, name, label, value, checked, onChange}) => {

    return (
        <FormControlLabel
            control={<Radio disabled={disabled} color="primary" name={name} checked={checked} value={value} onChange={onChange} value={value} />}
            label={label}
        />
    )

}

export default RadioBase