import React, { Component } from 'react';

import OptionsButton from "./OptionsButton"
import OptionsDropdown from "./OptionsDropdown"
import OptionsList from "./OptionsList"

const OptionsButtonRef = React.forwardRef((props, ref) => {
    return <OptionsButton {...props} forwardedRef={ref} />;
});


const ButtonOptions = ({options = [], onAction, ...button}) => {
    const [expanded, setExpanded] = React.useState(false);
    const anchorRef = React.useRef(null);

    const onToggle = (event) => {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const onSelect = ({onClick}) => {
        onClick && onClick()
        setExpanded(false);
    }

    const onCollapse = () => {
        setExpanded(false);
    };

    if (!button) {
        return false
    }

    if (options.length) {
        return (
            <React.Fragment>
                <OptionsButtonRef {...button} onSelect={onSelect} onToggle={onToggle} expanded={expanded} ref={anchorRef} />
                <OptionsDropdown expanded={expanded} anchorEl={anchorRef.current} onCollapse={onCollapse}>
                    <OptionsList options={options} onSelect={onSelect} />
                </OptionsDropdown>
            </React.Fragment>
        )
    }

    return (
        <OptionsButton {...button} />
    )
        
}

export default ButtonOptions