import React, { useState, useEffect } from 'react';
import Color from 'color';
import { getUiOptions, getDefaultFormState } from "@frontend-components/admin"
import { makeStyles } from "@material-ui/core/styles" 
import ButtonBase from "@material-ui/core/ButtonBase" 
import Typography from "@material-ui/core/Typography" 

import createPalette from '@material-ui/core/styles/createPalette';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    mainColor: {
        position: "relative",
        paddingBottom: "50%",
    },
    swatches: {
        display: "flex",
        width: "100%",
        color: props => { return props.contrastText },
        "& > *": {
            flexBasis: 0,
            flexGrow: 1,
        }
    },
    swatch: {
        position: "relative",
        paddingBottom: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > *": {
            position: "absolute",
            top: "50%",
//            bottom: "auto",
//            left: 0,
//            right: 0,
            transform: "translateY(-50%)"
        }
    },
}));

const ColorInput = ({light, main, dark, contrastText, onChange}) => {

    const classes = useStyles({contrastText})

    const handleChange = (event) => {
        onChange(event.target.value)
    }

    return (
        <div className={classes.mainColor} style={{backgroundColor: main}}>
            <input type="color" value={main} onChange={handleChange} />
        </div>
    )
}

const ColorSwatch = ({label, color}) => {

    const classes = useStyles()

    return (
        <ButtonBase style={{backgroundColor: color}}>
            <div className={classes.swatch}>
                <b className={classes.swatchLabel}>{color}</b>
            </div>
        </ButtonBase>
    )

}

const ColorSwatches = ({light, dark, main, contrastText}) => {

    const classes = useStyles({contrastText})

    return (
        <div className={classes.swatches}>
            <ColorSwatch color={light} />
            <ColorSwatch color={dark} />
        </div>
    )
}

const CollectionColorField = ({formData, schema, uiSchema, onChange, ...props}) => {
    const { main, light, dark, contrastText } = formData


    const handleChange = (value) => {

        const palette = createPalette({primary: { main: value}})
        const color = palette.primary

        onChange && onChange(color)

    }

    const uiOptions = getUiOptions(uiSchema)

    const title = uiOptions.title || schema.title

    const classes = useStyles()

    return (
        <div className={classes.root}>
            {title}

            { formData.main }

            <ColorInput {...formData} onChange={handleChange} />
            <ColorSwatches {...formData} />

        </div>
    )
    
}

export default CollectionColorField;
