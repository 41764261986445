import React, { Component } from 'react';
import FilterOwnersUncontrolled from "./FilterOwnersUncontrolled"

class FilterOwnersControlled extends Component {

    static defaultProps = {
        owners: [],
    }
    
    state = {
        q: "",
        filteredOwners: []
    }
    
    componentDidMount() {
        this.filterOwners()
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.owners !== this.props.owners) {
            this.filterOwners()
        }
        
        if (prevState.q !== this.state.q) {
            this.filterOwners()
        }
        
    }
    
    filterOwners = () => {
        const { owners } = this.props;
        const { q } = this.state;
        
        const filteredOwners = owners.filter((owner) => {
            const { title } = owner;
            
            if (q && !title.toLowerCase().includes(q.toLowerCase())) {
                return false
            }
            
            return true

        })
        
        this.setState({
            filteredOwners: filteredOwners
        })
        
        
    }
    
    
    onSearch = (q, event) => {
        event.preventDefault()
        
        this.setState({
            q: q
        })
        
    }

    render() {
        const { q, filteredOwners } = this.state;

        return (
            <FilterOwnersUncontrolled {...this.props} q={q} onSearch={this.onSearch} owners={filteredOwners} />
        )

    }

}

export default FilterOwnersControlled;