import Color from 'color';

export function getContrast(color) {
    let contrast;

    color = Color(color);

    if (color.isDark()) {
        contrast = 'white';
    } else {
        contrast = 'black';
    }

    return contrast;
}
