export const REQUEST_COLLECTION = 'REQUEST_COLLECTION'
export const RECEIVE_COLLECTION = 'RECEIVE_COLLECTION'

export const REQUEST_COLLECTION_BY_ID = 'REQUEST_COLLECTION_BY_ID'
export const RECEIVE_COLLECTION_BY_ID = 'RECEIVE_COLLECTION_BY_ID'

export function requestCollection() {

    return {
        type: REQUEST_COLLECTION,
    }
  
}

export function receiveCollection(data) {
    
    if (!data) {
        data = {
            uniqueId: undefined
        }
    }
    
    return {
        type: RECEIVE_COLLECTION,
        id: data && data.id,
        data: data
    }
  
}

export function requestCollectionById(id) {
    
    return {
        type: REQUEST_COLLECTION_BY_ID,
        id: id
    }
  
}

export function receiveCollectionById(data) {
    
    return {
        type: RECEIVE_COLLECTION_BY_ID,
        id: data && data.id,
        data: data
    }
  
}