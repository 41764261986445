import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';

class IframeChildren extends Component {

    state = {};

    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
    }

    static defaultProps = {
        
    };

    componentDidMount() {
        this.getHead()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.children !== prevProps.children) {
        }
    }

    iframeDidMount = () => {
        this.getHead()
    }

    getHead = () => {
        let stylesheets = [], styles = [];

        const linkTags = document.head.querySelectorAll('[rel="stylesheet"]');
        const styleTags = document.head.getElementsByTagName("style");

        for (let i = 0; i < linkTags.length; i++) {
            let styleheet = linkTags[i].getAttribute('href');
            stylesheets.push(styleheet);
        }

        for (let i = 0; i < styleTags.length; i++) {
            let style = styleTags[i].innerHTML;
            styles.push(style);
        }

        let head = [];

        stylesheets.forEach((stylesheet) => {
            head.push(<link type="text/css" rel="stylesheet" href={stylesheet} />);
        });

        styles.forEach((style) => {
            head.push(<style>{style}</style>);
        });

        this.setState({
            head: head
        })
    }

    render() {
        const { className } = this.props;
        const { head } = this.state;

        if (!head) {
            return false;
        }

        const template = '<!DOCTYPE html><html><head></head><body id="preview-iframe"></body></html>';

        return (
            <Frame className={className} initialContent={template} head={head} mountTarget="#preview-iframe" contentDidMount={this.iframeDidMount}>
                {this.props.children}
            </Frame>
        )
    }
}

export default IframeChildren;