import React from "react";

export default (
    <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
        <path d="M50,24 C52.2,24 54,25.8 54,28 L54,46 C54,48.2 52.2,50 50,50 L25,50 L18,57 L18,43 L18.02,28 C18.02,25.8666667 19.6749495,24.1094582 21.7994852,24.0049197 L22,24 L50,24 Z" id="Path" fill="#CCCCCC" transform="translate(36.000000, 40.500000) scale(-1, 1) translate(-36.000000, -40.500000) "></path>
        <path d="M42,13 C44.2,13 46,14.8 46,17 L46,35 C46,37.2 44.2,39 42,39 L17,39 L10,46 L10,32 L10.02,17 C10.02,14.8666667 11.6749495,13.1094582 13.7994852,13.0049197 L14,13 L42,13 Z" id="Path" fill="#0666B0"></path>
        <g id="Group" transform="translate(19.000000, 25.000000)" fill="#FFFFFF">
            <circle id="Oval" cx="1.5" cy="1.5" r="1.5"></circle>
            <circle id="Oval" cx="9" cy="1.5" r="1.5"></circle>
            <circle id="Oval" cx="16.5" cy="1.5" r="1.5"></circle>
        </g>
    </svg>
)