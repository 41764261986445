import React from "react"
import Dialog from "./Dialog"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    },
});

const LayoutDialog = ({theme, children, ...props}) => {

    if (theme === "dark") {
        return (
            <ThemeProvider theme={darkTheme}>
                <Dialog {...props}>{children}</Dialog>
            </ThemeProvider>
        )
    }

    return (
        <Dialog {...props}>{children}</Dialog>
    )

}

export default LayoutDialog