import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { uuid, deepMap, isTabList, isTab, isTabPanel, isTabNode, isTabDisabled, getTabsCount } from "./lib/tabs"

let canUseActiveElement;
try {
  canUseActiveElement = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.activeElement
  );
} catch (e) {
  // Work around for IE bug when accessing document.activeElement in an iframe
  // Refer to the following resources:
  // http://stackoverflow.com/a/10982960/369687
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/12733599
  canUseActiveElement = false;
}

class TabsUncontrolled extends Component {
  
  static defaultProps = {
    className: "admin-tabs",
    focus: false,
  };
  
  static propTypes = {
    domRef: PropTypes.func,
    focus: PropTypes.bool,
    forceRenderTabPanel: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number.isRequired,
  };
  
  setSelected = (index, event) => {
    // Check index boundary
    if (index < 0 || index >= this.getTabsCount()) return;

    const { selectedIndex } = this.props;
    
    if (this.props.onSelect) {
      this.props.onSelect(index, selectedIndex, event);
    }

  }
  
  getNextTab = (index) => {
    const count = this.getTabsCount();

    // Look for non-disabled tab from index to the last tab on the right
    for (let i = index + 1; i < count; i++) {
      if (!isTabDisabled(this.getTab(i))) {
        return i;
      }
    }

    // If no tab found, continue searching from first on left to index
    for (let i = 0; i < index; i++) {
      if (!isTabDisabled(this.getTab(i))) {
        return i;
      }
    }

    // No tabs are disabled, return index
    return index;
  }

  getPrevTab = (index) => {
    let i = index;

    // Look for non-disabled tab from index to first tab on the left
    while (i--) {
      if (!isTabDisabled(this.getTab(i))) {
        return i;
      }
    }

    // If no tab found, continue searching from last tab on right to index
    i = this.getTabsCount();
    while (i-- > index) {
      if (!isTabDisabled(this.getTab(i))) {
        return i;
      }
    }

    // No tabs are disabled, return index
    return index;
  }

  getFirstTab = () => {
    const count = this.getTabsCount();

    // Look for non disabled tab from the first tab
    for (let i = 0; i < count; i++) {
      if (!isTabDisabled(this.getTab(i))) {
        return i;
      }
    }

    return null;
  }

  getLastTab = () => {
    let i = this.getTabsCount();

    // Look for non disabled tab from the last tab
    while (i--) {
      if (!isTabDisabled(this.getTab(i))) {
        return i;
      }
    }

    return null;
  }
  
  getTabsCount = () => {
    const { children } = this.props;
    return getTabsCount(children);
  }

  getTab = (index) => {
    return this.tabNodes[`tabs-${index}`];
  }

  getChildren = () => {

    const {
      children,
      disabledTabClassName,
      focus,
      forceRenderTabPanel,
      selectedIndex,
      selectedTabClassName,
      selectedTabPanelClassName,
    } = this.props;

    this.tabIds = this.tabIds || [];
    this.panelIds = this.panelIds || [];
    let diff = this.tabIds.length - this.getTabsCount();

    // Add ids if new tabs have been added
    // Don't bother removing ids, just keep them in case they are added again
    // This is more efficient, and keeps the uuid counter under control
    while (diff++ < 0) {
      this.tabIds.push(uuid());
      this.panelIds.push(uuid());
    }
    
    let tabIndex = 0;
    let panelIndex = 0;

    // Map children to dynamically setup refs
    return deepMap(children, child => {
      let result = child;
      
      if (isTab(child)) {
        
        const selected = selectedIndex === tabIndex;
        
        const props = {
          id: this.tabIds[tabIndex],
          panelId: this.panelIds[tabIndex],
          selected: selected,
//          focus: selected && (focus || wasTabFocused),
        };

        if (forceRenderTabPanel) props.forceRender = forceRenderTabPanel;
        if (selectedTabPanelClassName)
          props.selectedClassName = selectedTabPanelClassName;

        tabIndex++;

        result = cloneElement(child, props);

      }
      
      if (isTabPanel(child)) {

        const selected = selectedIndex === panelIndex;
        
        const props = {
          id: this.panelIds[panelIndex],
          tabId: this.tabIds[panelIndex],
          selected: selected,
        };

        if (forceRenderTabPanel) props.forceRender = forceRenderTabPanel;
        if (selectedTabPanelClassName)
          props.selectedClassName = selectedTabPanelClassName;

        panelIndex++;

        result = cloneElement(child, props);
      }

      return result;
    });
  }
  
  isTabFromContainer = (node) => {
    // return immediately if the clicked element is not a Tab.
    if (!isTabNode(node)) {
      return false;
    }

    // Check if the first occurrence of a Tabs container is `this` one.
    let nodeAncestor = node.parentElement;
    do {
      if (nodeAncestor === this.node) return true;
      if (nodeAncestor.getAttribute('role') === "dialog") break;

      nodeAncestor = nodeAncestor.parentElement;
    } while (nodeAncestor);

    return false;
  }
  
  onClick = (e) => {
    let node = e.target;
    // eslint-disable-next-line no-cond-assign
    do {
      if (this.isTabFromContainer(node)) {
        if (isTabDisabled(node)) {
          return;
        }

        const index = [].slice
          .call(node.parentNode.children)
          .filter(isTabNode)
          .indexOf(node);
        this.setSelected(index, e);
        return;
      }
    } while ((node = node.parentNode) !== null);
  };
  
  onKeyDown = (e) => {
    if (this.isTabFromContainer(e.target)) {
      let { selectedIndex: index } = this.props;
      let preventDefault = false;
      let useSelectedIndex = false;

      if (e.keyCode === 32 || e.keyCode === 13) {
        preventDefault = true;
        useSelectedIndex = false;
        this.onClick(e);
      }

      if (e.keyCode === 37 || e.keyCode === 38) {
        // Select next tab to the left
        index = this.getPrevTab(index);
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 39 || e.keyCode === 40) {
        // Select next tab to the right
        index = this.getNextTab(index);
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 35) {
        // Select last tab (End key)
        index = this.getLastTab();
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 36) {
        // Select first tab (Home key)
        index = this.getFirstTab();
        preventDefault = true;
        useSelectedIndex = true;
      }

      // This prevents scrollbars from moving around
      if (preventDefault) {
        e.preventDefault();
      }

      // Only use the selected index in the state if we're not using the tabbed index
      if (useSelectedIndex) {
        this.setSelected(index, e);
      }
    }
  };
    
  render() {

    const {
      children, // unused
      className,
      disabledTabClassName, // unused
      domRef,
      focus, // unused
      forceRenderTabPanel, // unused
      onSelect, // unused
      selectedIndex, // unused
      selectedTabClassName, // unused
      selectedTabPanelClassName, // unused
      ...attributes
    } = this.props;
    
    return (
      <div
        { ...attributes }
        className={ className }
        role="dialog"
        onClick={ this.onClick }
        onKeyDown={ this.onKeyDown }
        ref={node => {
          this.node = node;
          if (domRef) domRef(node);
        }}
        data-tabs
      >
      
        {this.getChildren()}
      </div>
    );
  
  }
  
}

export default TabsUncontrolled;