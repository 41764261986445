import React, { Component } from 'react';
import Buttongroup from "./Buttongroup";

import "./ButtongroupOptions.scss";

class ButtongroupOptions extends Component {
    getClassName = () => {
        const { name, type } = this.props;
        let classNames = []

        classNames.push('admin-buttongroup');
        classNames.push('admin-buttongroup--options');

        if (type) {
            classNames.push('admin-buttongroup--options-' + type);
        } 

        if (name) {
//            classNames.push('buttongroup--options-' + name.replace('_', ''));
        }

        return classNames.join(' ')
    }

    render() {
        const className = this.getClassName();

        return (
            <Buttongroup {...this.props} className={className} role="group">
                {this.props.children}
            </Buttongroup>
        )
    }
}

export default ButtongroupOptions;
