import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        zIndex: 1,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: "hidden",
    },
}));

const AppBase = ({children, theme, ...props}) => {
    const classes = useStyles()

    return (
        <main className={classes.root}>
            {children}
        </main>
    )
}

export default AppBase