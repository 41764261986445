import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Finder from "../AppFinder/"
import { LayoutFinder, FinderGrid } from '@frontend-components/admin';

import ReportMetrics from './ReportMetrics';
import WidgetLiveReport from './WidgetLiveReport';
import ReportAcquisition from './ReportAcquisition';
import ReportSessionsByCountry from './ReportSessionsByCountry';

// ?countryId=no&offset=0&owner=1,2,3,4&reportType=0&rows=50&startDate=2019-07-01,2018-07-01&endDate=2019-07-01,2018-07-01&orderBy=nthDay&metrics=uniquePageviews,users,sessions&dimensions=dimension1,dimension2,dimension3,dimension4,nthDay&sort=descending

class AnalyticsHome extends Component {
    
    static defaultProps = {
        defaultFilter: "7days",
        filtersById: {
            yesterday: {
                title: "I går"
            },
            ['7days']: {
                title: "Siste uke"
            },
            ['28days']: {
                title: "Siste 4 uker"
            },
            ['90days']: {
                title: "Siste kvartal"
            },
            ['180days']: {
                title: "Siste halvår"
            },
            ['year']: {
                title: "Siste år"
            }
        }
    }
    
    state = {
        filters: [],
        scope: "7days"
    }
    
    getFilters = () => {
        const { pathname, hash } = this.props.location;
        const { filtersById, defaultFilter } = this.props;
        
        let selectedId;

        if (hash) {
            selectedId = hash.replace('#', '')
        } else {
            selectedId = defaultFilter;
        }
        
        let scope;

        const filters = Object.keys(filtersById).map(id => {
            
            let selected;
            
            if (id === selectedId) {
                selected = true;
                scope = id
            }
            
            const filter = {
                ...filtersById[id],
                id: id,
                url: pathname + "#" + id,
                selected: selected
            }
            
            return filter
            
        })
        
        console.log('SCOPE', scope)
        
        this.setState({
            filters: filters,
            scope: scope
        })

    }
    
    componentDidMount() {
        this.getFilters()
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        if (!prevProps.location.hash && this.props.location.hash) {
            this.getFilters()
        }

        if (prevProps.location.hash !== this.props.location.hash) {
            this.getFilters()
        }
        
    }
    
    render() {
        const { theme } = this.props;
        const { filters, scope } = this.state
        const { pathname } = this.props.location
        
        return (
            
            <Finder {...this.props} filters={filters}>
                <FinderGrid>
                    <ReportMetrics id={pathname + "/metrics/" + scope} scope={scope}  widgetSize="2:6" />
                    <WidgetLiveReport id={pathname + "/live"} backgroundColor={theme.primaryColor} widgetSize="1:6" />
                    <ReportAcquisition id={pathname + "/acquisition/" + scope} scope={scope} widgetSize="2:5" />
                    <ReportSessionsByCountry id={pathname + "/byCountry"} scope={scope} widgetSize="1:5" />
                </FinderGrid>
            </Finder>
        
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app,
    	theme: state.app.theme,
    	nav: state.nav,
    	site: state.site,
    	collection: state.collection,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsHome);