import React, { Component } from 'react';

import {
    getWidget,
    getUiOptions,
} from "../utils";

class ArrayFieldFiles extends Component {

    onSelectChange = (value) => {
        this.props.onChange(value);
    }

    render() {

        const {
            schema,
            uiSchema,
            idSchema,
            name,
            disabled,
            readonly,
            autofocus,
            onBlur,
            onFocus,
            registry,
//            registry = getDefaultRegistry(),
            rawErrors,
        } = this.props;
        const title = schema.title || name;
        const items = this.props.formData;
        const { widgets, formContext } = registry;
        const { widget = "files", ...options } = getUiOptions(uiSchema);
        const Widget = getWidget(schema, widget, widgets);
        return (
            <Widget
                options={options}
                id={idSchema && idSchema.$id}
                multiple
                onChange={this.onSelectChange}
                onBlur={onBlur}
                onFocus={onFocus}
                schema={schema}
                title={title}
                value={items}
                disabled={disabled}
                readonly={readonly}
                formContext={formContext}
                autofocus={autofocus}
                rawErrors={rawErrors}
            />
        );


    }
}

export default ArrayFieldFiles;