import React, { Component } from 'react';
import FilterAppsUncontrolled from "./FilterAppsUncontrolled"

class FilterAppsControlled extends Component {

    static defaultProps = {
        apps: [],
        layout: "list"
    }
    
    state = {
        q: "",
        filteredApps: []
    }
    
    componentDidMount() {
        this.filterApps()
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.apps !== this.props.apps) {
            this.filterApps()
        }
        
        if (prevState.q !== this.state.q) {
            this.filterApps()
        }
        
    }
    
    filterApps = () => {
        const { apps } = this.props;
        const { q } = this.state;
        
        const filteredApps = apps.filter((owner) => {
            const { title } = owner;
            
            if (q && !title.toLowerCase().includes(q.toLowerCase())) {
                return false
            }
            
            return true

        })
        
        this.setState({
            filteredApps: filteredApps
        })
        
        
    }
    
    onSearch = (q, event) => {
        event.preventDefault()
        
        this.setState({
            q: q
        })
        
    }

    render() {
        const { q, filteredApps } = this.state;

        return (
            <FilterAppsUncontrolled {...this.props} q={q} onSearch={this.onSearch} apps={filteredApps} />
        )

    }

}

export default FilterAppsControlled;