import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MediaVolumeButton from "./MediaVolumeButton"
import MediaVolumeSlider from "./MediaVolumeSlider"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
}));

const AudioControls = ({className, muted, volume, onClick, onChange}) => {

    const classes = useStyles()

    return (
        <div className={className || classes.root}>
            <MediaVolumeButton muted={muted} onClick={onClick} />
            <MediaVolumeSlider muted={muted} min={0} max={1} value={volume} onChange={onChange} />
        </div>
    )

}

export default AudioControls