/*
 * This file is part of the nivo project.
 *
 * Copyright 2016-present, Raphaël Benitte.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { useTheme, useValueFormatter } from '@nivo/core'

import "./ChartTooltip.scss"

class Tooltip extends Component {
    
    render() {
        const { renderContent } = this.props;

        if (this.props.children) {

            return (
                <article className="admin-tooltip">
                    { this.props.children }
                </article>
            )

        } else if (typeof renderContent === 'function') {
            return renderContent()
        }
        
        return (
            <article className="admin-tooltip">
                { JSON.stringify(this.props) }
            </article>
        )
        
    }

}

export default Tooltip;

/*

const BasicTooltip = memo(({ id, value: _value, format, enableChip, color, renderContent }) => {
    const theme = useTheme()
    const formatValue = useValueFormatter(format)
    
    if (this.props.children) {
        return (
            <article className="admin-tooltip">
                { this.props.children }
            </article>
        )
    }

    let content
    if (typeof renderContent === 'function') {
        content = renderContent()
    } else {
        let value = _value
        if (formatValue !== undefined && value !== undefined) {
            value = formatValue(value)
        }
        content = (
            <div style={theme.tooltip.basic}>
                {value !== undefined ? (
                    <span>
                        {id}: <strong>{isNaN(value) ? String(value) : value}</strong>
                    </span>
                ) : (
                    id
                )}
            </div>
        )
    }

    return <div>{content}</div>
})

BasicTooltip.displayName = 'BasicTooltip'
BasicTooltip.propTypes = {
    id: PropTypes.node.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    enableChip: PropTypes.bool.isRequired,
    color: PropTypes.string,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    renderContent: PropTypes.func,
}
BasicTooltip.defaultProps = {
    enableChip: false,
}

export default BasicTooltip

*/
