import React, { useState } from 'react';
import PropTypes from "prop-types"

import FinderBase from "../components/Finder/FinderBase"
import FinderHeader from "../components/Finder/FinderHeader"
import FinderBody from "../components/Finder/FinderBody"

import NavPath from "../components/NavPath/NavPath"
import NavSettings from "../components/NavSettings/NavSettings"


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    root: {
        display: "flex",
        flexDirection: "column"
    },

    header: {


    },

    body: {
        flexGrow: 1
    },

    navAction: {
        position: "relative",
        zIndex: 2,
        margin: theme.spacing(3)
    },
    navMenu: {
        overflowY: "scroll",
        marginRight: theme.spacing(3)
    },
    navCalendar: {
        margin: theme.spacing(1)
    },
}));

const LayoutFinder = ({backgroundColor, expanded, title, parents, children, settings, primaryAction, calendar, menu, menuByUrl, onSelect}) => {

    const classes = useStyles()

    return (
        <FinderBase expanded={expanded}>
            <FinderHeader>
                <NavPath parents={parents} title={title} onSelect={onSelect} />
                { settings && <NavSettings settings={settings} /> }
            </FinderHeader>
            <FinderBody>
                { children }
            </FinderBody>
        </FinderBase>
    )

}

LayoutFinder.propTypes = {
    backgroundColor: PropTypes.string,
    expanded: PropTypes.bool,
    title: PropTypes.string,
    parents: PropTypes.array,
    filters: PropTypes.array,
    action: PropTypes.array,
    views: PropTypes.array,
    search: PropTypes.object,
}


export default LayoutFinder;