import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchModel } from '../redux/functions/model';
import { FinderSection, AuthModule, WidgetSearch } from '@frontend-components/admin';

import AuthModuleUser from './AuthModuleUser';

class AuthAddUser extends Component {
    
    state = {
        q: "",
    }

    static defaultProps = {
        users: [],
        search: {
            models: []
        }
    }
    
    onSearch = (q) => {
        this.setState({
            q: q
        })
        
        this.props.searchModel('users', "/auth/users/add", {name: q + "*"})
        
    }
    
    renderUser = (user, index) => {
        const { siteId, appId } = this.props.match.params;
        const { users } = this.props;
        
        let add = true;
        
        users.filter(skip => {
            if (user.id === skip.userId) {
                add = false
            }
        })
        
        user = {
            ...user,
            siteId: siteId,
            collectionId: appId,
            userId: user.id,
            moduleSize: "small"
        }
        
        if (add) {
            return (
                <AuthModuleUser {...user} key={index} action={["add"]} />
            )
        }

        return (
            <AuthModuleUser {...user} key={index} action={["remove"]} />
        )

        
    }
    
    renderResults = () => {
        const { models } = this.props.search;
        
        if (models) {
            return (
                <FinderSection title={models.length + " users found"}>
                    { models.map(this.renderUser) }
                </FinderSection>                
            )
        }
        
    }
    
    render() {
        const { q } = this.state;
        
        return (
            <FinderSection title="Add user">
                <FinderSection>
                    <WidgetSearch placeholder="Find user by name, email or identifier" value={q} onChange={this.onSearch} />
                </FinderSection>
                { this.renderResults() }
            </FinderSection>
        )
        
    }

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      searchModel
  }, 
dispatch);


function mapStateToProps(state) {
	return {
        search: state.data["/auth/users/add"],
	};
}  

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthAddUser);