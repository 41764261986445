import Analytics from './Analytics';

import AnalyticsHome from './AnalyticsHome';

import AnalyticsReport from "./AnalyticsReport";
import AnalyticsFinder from './AnalyticsFinder';

import WidgetSessions from "./WidgetSessions";
import WidgetSessionsByOwner from "./WidgetSessionsByOwner";

import WidgetLiveReport from "./WidgetLiveReport";
import WidgetRegVsPub from "./WidgetRegVsPub";
import WidgetOwnersVsTotal from "./WidgetOwnersVsTotal"

export {
    AnalyticsHome,
    AnalyticsReport,
    WidgetSessions,
    WidgetSessionsByOwner,
    WidgetLiveReport,
    WidgetRegVsPub,
    WidgetOwnersVsTotal
}

export default Analytics;