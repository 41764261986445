import React from "react";
import DraftEditor from "./DraftEditor/DraftEditor"

const TexteditorField = ({label, helperText, value, onChange, ...props}) =>  {

    return (
        <DraftEditor label={label} helperText={helperText} value={value} onChange={onChange} />
    )
    
}

export default TexteditorField;
