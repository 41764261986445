import PropTypes from "prop-types";

const REQUIRED_FIELD_SYMBOL = "*";

function TitleField() {
    return false;
}

if (process.env.NODE_ENV !== "production") {
    TitleField.propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        required: PropTypes.bool,
    };
}

export default TitleField;
