import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getApp } from './app';

import AdminRoutes from "./AdminRoutes"

const AdminApp = ({user, app, ...props}) => {

    useEffect(() => {
        props.getApp({user, app, ...props})
    }, [user.uniqueId, app.name])

    if (!user.uniqueId) {
        return false
    }

    if (!app.uniqueId) {
        return (
            <div>
                <p>Loading app .... {app.uniqueId}</p>
            </div>
        )
    }
    
    return (
        <AdminRoutes {...props} />
    )

}

function mapStateToProps(state) {
	return {
        user: state.user,
        app: state.app
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminApp);

