import { API } from '../settings';
import { receiveMenuItem } from '../actions/nav';
import qs from 'query-string';

// GET TREE

export function getNavByDocumentType(parent) {
    const { query } = parent;
    const { models } = query;
    
    const fetchUrl = API + '/admin/api/' + models + '/search?' + qs.stringify({...query, fl: "id,title,uniqueId,documentType"});

    return function(dispatch) {
  
        fetch(fetchUrl, {
          method: "GET",
          headers: {
            "Accept": "application/json",
          },
        })
        .then(
          response => response.json(),
          error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(getNavDocumentTypeParent(parent, data))
        )

  }

}

export function getNavDocumentTypeParent(parent, data) {
    const { models } = data;
    
    let documentTypes = []

    if (models) {
        models.map((model) => {
            if (model.documentType && !documentTypes.includes(model.documentType)) {
                documentTypes.push(model.documentType)
            }
        })
    }

    return function(dispatch) {

        let children = []

        if (documentTypes) {
            documentTypes.map((documentType) => {
                const child = getNavDocumentTypeChild(parent, documentType)
                children.push(child)
            })
        
        }
        
        if (children.length) {
            dispatch(receiveMenuItem({...parent, count: children.length, children: children}))
            
            children.map((child) => {
                dispatch(receiveMenuItem(child))
            })
            
        }
        
    }
    
}

export function getNavDocumentTypeChild(parent, documentType) {
    const query = parent.query;
    const url = parent.url + "/" + documentType;
    const title = documentType

    const child = {
        url: url,
        title: title,
        query: {
            ...query,
            documentType: documentType,
        }
    }
    
    return child;
    
}