import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { AppTheme, AppBase, AppHeader, AppNav, AppBody, AppSidebar, AppContent } from '../components';
import { NavAction } from '../components';
import { AppsList, OwnersList } from '../components';
import { NavCalendar, NavMenuControlled as NavMenu } from "../components"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    header: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: 'auto',
        left: 0,
        height: theme.spacing(8),
        overflow: "hidden",
        marginTop: theme.spacing(-8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginTop: 0            
        }
    },
    nav: {
        position: "absolute",
        zIndex: 2000,
        top: 0,
        right: 0,
        marginTop: theme.spacing(-8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            [theme.breakpoints.up('sm')]: {
                marginTop: 0
            }
        },
        height: theme.spacing(8),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    body: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        marginTop: theme.spacing(8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginTop: 0            
        }
    },
    drawer: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        zIndex: 1,
        top: 0,
        right: "auto",
        left: 0,
        bottom: 0,
        width: props => { return props.width },
        marginRight: props => { return "-" + props.width + "px" },
        transition: ".125s ease-out",

        overflowY: "scroll",

        "& + *": {
            transition: ".125s ease-out",
            boxShadow: theme.shadows[4]
        },

        "&[aria-expanded=true]":  {
            marginLeft: 0,
            [theme.breakpoints.down('md')]: {
            }
        },
        "&[aria-expanded=true] + *":  {
            marginLeft: props => { return props.width },
            marginRight: props => { return props.width * -1 },
            [theme.breakpoints.down('md')]: {
            }
        },
    },
    navAction: {
        position: "relative",
        zIndex: 2,
        margin: "24px"
    },
    navScroll: {
        overflowY: "scroll"	
    },
    navCalendar: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    navMenu: {
        /*
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        paddingTop: "84px",
        marginRight: "16px",
        marginLeft: "0",
        */
    }
}));

const AppDrawer = ({expanded, width = 224, children}) => {
    const classes = useStyles({width})

    return (
        <aside className={classes.drawer} aria-expanded={expanded}>
            {children}
        </aside>
    )
}



const AppLayout = ({
        app, user, site, drawer, header, search, subview, sidebar, primaryAction, 
        calendar, currentDate, currentMonth, currentYear,
        menu, menuByUrl, currentUrl, 
        onLogin, onLogout, onSelect, children, ...props
    }) => {

    const classes = useStyles({width: 256})

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    header = {
        ...header,
        title: app && app.title,
        subtitle: site && site.title || user && user.name,
    }

    if (isMobile) {
        header = {
            ...header,
            menu: drawer
        }
    }

    const navCalendar = {
        ...calendar,
        date: currentDate,
        onSelect: onSelect
    }

    const navMenu = {
        menu: menu,
        menuByUrl: menuByUrl,
        currentUrl: currentUrl,
        onSelect: onSelect
    }

    let appNav = {
        expanded: true
    }

    if (subview && subview.expanded) {
        appNav.expanded = false
    }

    if (search && search.expanded) {
        appNav.expanded = false
    }

    if (!app.theme) {
        return children
    }

    return (
        <AppTheme theme={app && app.theme}>
            <AppDrawer expanded={drawer.expanded}>
                { menu && <NavMenu {...navMenu} /> }
                { user && user.apps && <AppsList apps={user.apps} onSelect={onSelect} /> }
                { user && user.owners && <OwnersList owners={user.owners} onSelect={onSelect} /> }
            </AppDrawer>
            <AppBase>
                <AppHeader {...header} className={classes.header} search={search} subview={subview}>
                    <AppNav {...appNav} user={user} owner={site} className={classes.nav} onLogin={onLogin} onLogout={onLogout} onSelect={onSelect} />
                </AppHeader>
                <AppBody expanded={header && !header.expanded && true}>
                    <AppSidebar {...sidebar} width={240}>
                        { primaryAction && <NavAction className={classes.navAction} primaryAction={primaryAction} placement="bottom-start" onSelect={onSelect} /> }
                        <div className={classes.navScroll}>
                            { calendar && <NavCalendar className={classes.navCalendar} {...navCalendar} /> }
                            { menu && <NavMenu className={classes.navMenu} {...navMenu} /> }
                        </div>
                        </AppSidebar>
                    <AppContent>
                        { children }
                    </AppContent>
                </AppBody>
            </AppBase>
        </AppTheme>
    )

}

AppLayout.defaultProps = {
    drawer: {}
}

export default AppLayout

