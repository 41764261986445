import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "baseline",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        color: theme.palette.text.secondary
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        color: theme.palette.text.primary
    }
}));

const ResultsLoader = ({models = [], count, rows, children, loadingtTitle, emptyTitle}) => {
    const { t, i18n } = useTranslation('search');

    const classes = useStyles()

    let title;

    if (rows && !count) {
        title = emptyTitle || t('No hits')
    } else if (!models || !models.length) {
        title = loadingtTitle || t('Fetching data, please wait') + "..."
    } 

    if (title) {
        return (
            <header className={classes.root}>
                <Typography className={classes.title}>{title}</Typography>
            </header>
        )
    }

    return children

}

export default ResultsLoader