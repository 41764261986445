import links from "../mediaLinks/uiSchema"

export default {
    "ui:layout": "section",
    "ui:title": "Innhold",
    "ui:icon": "link",
    "ui:fieldset": [
        "links"
    ],
    "ui:settings": [
        "mediaLayout",
    ],
    "links": links
}