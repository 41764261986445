import { API } from '../settings';
import { requestSearch, receiveSearch, receiveSearchPage } from '../actions/search';
import qs from 'query-string';

// SEARCH

export function getSearch({models, id, page, ...query}) {
    let url = API + '/admin/api/' + models + '/search';
  
    if (query) {
        url = url + '?' + qs.stringify(query);
    }
    

    return function (dispatch) {
        !page && dispatch(requestSearch(id, query))
  
        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveSearch(id, data))
            dispatch(receiveSearchPage(id, {...data, page: page}))
        })

  }

}