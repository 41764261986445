import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme"

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

moment.locale("nb"); // it is required to select default locale manually

const DateInput = ({
    id = "date-input",
    label = "Date", 
    value = undefined,
    helperText,
    inputFormat = "DD.MM.YYYY",
    format = "YYYY-DD-MM",
    onChange
    }) => {

    const [selectedDate, setSelectedDate] = useState(value || new Date());

    const handleChange = (date) => {
        setSelectedDate(date);
        const formattedDate = moment(date).format(format)
        onChange && onChange(formattedDate, date)
    };

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    id={id}
                    label={label}
                    value={selectedDate}
                    helperText={helperText}
                    inputVariant="filled"
                    format={inputFormat}
                    onChange={handleChange}
                    PopoverProps={{
                        anchorOrigin: {
                            "vertical": 'bottom',
                            "horizontal": 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        }
                    }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    )

}

export default DateInput