import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BulkEditor from "../AppBulk/BulkEditor"

const FinderBulk = ({bulkList = [], ...props}) => {

    if (!bulkList) {
        return <p>Please wait while fetching data ...</p>
    }

    if (!bulkList.length) {
        return <p>No models selected ...</p>
    }

    return (
        <BulkEditor {...props} />
    )


}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        bulkList: state.bulk.list,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinderBulk);