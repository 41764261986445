import React, { Component } from 'react';

class Crosshair extends Component {
    
    renderLine = (props) => {
        const { id, x1, x2, y1, y2 } = props;
        
        const className = "crosshair  crosshair--" + id;

        return (

            <line
                className={className}
                x1={x1}
                x2={x2}
                y1={y1}
                y2={y2}
                fill="none"
            />
        )
        
    }
    
    render() {
        const { width, height, x, y, type, offset } = this.props;

        const xLine = { id: "x", x1: x, x2: x, y1: 0+offset.top, y2: height+offset.bottom }
        const yLine = { id: "y", x1: 0, x2: width, y1: y, y2: y }
        
        if (type === "x") {
            return this.renderLine(xLine)
        }
        
        if (type === "y") {
            return this.renderLine(yLine)
        }

        return (
            <g className="crosshair">
                { this.renderLine(xLine) }
                { this.renderLine(yLine) }
            </g>
        )
    
    }

}

export default Crosshair