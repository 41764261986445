import React, { Component } from 'react';

import {
    Widget,
    WidgetHeader,
    WidgetMedia,
    WidgetTitle,
    WidgetDescription,
} from "../"

class LayoutWidgetHeader extends Component {

    static defaultProps = {
        id: false,
        expanded: false
    }

    getPreview = () => {
        let { formData, imageUrl, icon, image, name, title, description, widgetType, widgetSize } = this.props;

        if (formData && formData.icon) {
            icon = formData.icon
        } 
        
        if (formData && formData.image && formData.image.media && formData.image.media.imageUrl) {
            imageUrl = formData.image.media.imageUrl
        } 

        if (formData && formData.widgetType) {
            widgetType = formData.widgetType
        } 

        if (formData && formData.widgetSize) {
            widgetSize = formData.widgetSize
        } 

        return {
            ...this.props,
            widgetSize: widgetSize,
            widgetType: widgetType,
            icon: icon,
            imageUrl: imageUrl,
            title: title,
            description: description,
        }
        
    }
        
    render() {
        const { expanded, widgetType, imageUrl, icon, name, title, description } = this.getPreview()
        
        return (
            <WidgetHeader expanded={expanded}>
                <WidgetMedia widgetType={widgetType} name={title || name} imageUrl={imageUrl} icon={icon} />
                <WidgetTitle title={title || name} />
                <WidgetDescription description={description} />
            </WidgetHeader>
        )
        
    }

}

export default LayoutWidgetHeader;