export default {
    type: "string",
    enum: [
        "document",
        "media",
        "object"
    ],
    enumNames: [
        "Document",
        "Media",
        "Object"
    ],
    default: "document"
}