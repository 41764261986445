import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Color from 'color';

import '../../resources/sass/reset.scss';
import '../../resources/fonts/Akkurat.scss';
import '../../resources/fonts/AkkuratMono.scss';
import '../../resources/fonts/MaterialIcons.scss';

const getOverrides = () => {

    return {
        MuiInputBase: {
            root: {
                fontFamily: "Akkurat, sans-serif",
            }
        },
        MuiFilledInput: {
            root: {
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0"
            }
        },
        MuiFormLabel: {
            root: {
            }
        },
        MuiFormHelperText: {
            root: {
            }
        },
        MuiButtonGroup: {
            grouped: {
            }
        },
        MuiButton: {
            root: {
                fontFamily: "Akkurat, sans-serif",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: 1,
            },
            outlined: {
            },
        }
    }

}

const getColor = (color = "#666") => {
    return Color(color).hex();
}

const getPalette = ({primaryColor, secondaryColor}) => {

    return {
        primary: {
            main: getColor(primaryColor), 
        },
        secondary: {
            main: getColor(secondaryColor || primaryColor), 
        }
    }

}

const getTheme = ({palette, ...theme}) => {

    return createMuiTheme({
        typography: {
            fontFamily: "Akkurat, sans-serif !important"
        },
        palette: palette || getPalette(theme),
        overrides: getOverrides(theme)
    })

}

const AppTheme = ({children, ...props}) => {
    const theme = getTheme(props.theme || {})

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default AppTheme