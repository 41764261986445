import React, { Component } from 'react';

class LayoutInboxArray extends Component {
    
    static defaultProps = {
        formData: []
    }
    
    render() {
        const { formData } = this.props;
        
        if (!formData.length) {
            return false
        }

        return (
            <div className="admin-inbox-editor__array">
                { this.props.children }
            </div>
        )

    }

}

export default LayoutInboxArray