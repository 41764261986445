import mediaLayout from "./mediaLayout"
import objectTypes from "./ekulturTypes"
import mediaQuery from "./mediaQuery"

export default {
    "properties": {
        "type": {
            "enum": ["trash"]
        },
        "layout": mediaLayout
    }
}