import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchBase = ({label, value, onChange}) => {

    return (
        <FormControlLabel
            control={<Switch checked={value} color="primary" onChange={onChange} value={value} />}
            label={label}
        />
    )

}

export default SwitchBase