import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { CollectionRoutes } from "../AppCollection"

import BookingEditor from "./BookingEditor"

class BookingRoutes extends Component {
  
    render() {

        return (
            <Switch>
                <Route path="/:siteName/:appName/:documentType*/:uniqueId/edit" component={ BookingEditor } />
                <Route path="/:siteName/:appName/:uniqueId/edit" component={ BookingEditor } />
                <Route path="/:siteName/:appName" component={ CollectionRoutes } />
            </Switch>
        )

    }

}

export default BookingRoutes;