import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textarea: {
        width: "100%",
        overflow: "hidden",
        fontFamily: "inherit",
        fontSize: "inherit",
        lineHeight: "inherit",
    }
}));

const InboxMessageEditor = ({className, value, onChange, ...props}) => {

    const classes = useStyles()

    return (
        <div className={className || classes.root}>
            <TextareaAutosize className={classes.textarea} rowsMin={2} value={value} onChange={(event) => onChange(event.target.value, event)} />
        </div>
    )

}

export default InboxMessageEditor