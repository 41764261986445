import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getApp } from './app';
import qs from 'query-string';

import FinderLayout from "../AppFinder/FinderLayout"
import InboxRoutes from "./InboxRoutes"

const AppInbox = ({app, ...props}) => {

    useEffect(() => {
        props.getApp(props);
    }, [])

    return (
        <FinderLayout {...app}>
            <InboxRoutes {...props} />
            <p>inbox</p>
        </FinderLayout>
    )

}

function mapStateToProps(state) {
	return {
        user: state.user,
        app: state.app
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppInbox);

