import React, { useEffect, useState } from 'react';
import { getDefaultFormState } from "@frontend-components/admin"
import { connect } from 'react-redux';

const CollectionDocumentTypeField = ({collectionId, documentTypes, ...props}) => {
    
    const [schema, setSchema] = useState(props.schema)

    useEffect(() => {
        let enumKeys = [], enumNames = []

        documentTypes && documentTypes.map(documentType => {
            const model = documentType.model;
            enumKeys.push(model.name)
        })

        if (schema.type === "array") {
            setSchema({
                ...props.schema,
                type: "array",
                items: {
                    enum: enumKeys,
//                    enumNames: enumNames
                },
                uniqueItems: true
            })
        } else {
            setSchema({
                ...props.schema,
                type: "string",
                enum: enumKeys,
                enumNames: enumNames
            })
        }
    
    }, [documentTypes])

    const { definitions } = props.registry;


    const handleChange = (formData) => {
        const newFormData = getDefaultFormState(schema, formData, definitions)
        props.onChange(newFormData)
    }

    const { ArrayField, StringField } = props.registry.fields

    const { formData } = props;

    if (schema.type === "array") {

        let newFormData

        if (typeof formData === "string") {
            newFormData = [formData]
        } else {
            newFormData = formData
        }

        return (
            <ArrayField {...props} schema={schema} formData={newFormData} onChange={handleChange} />
        )

    } else {

        return (
            <StringField {...props} schema={schema} onChange={handleChange} />
        )

    }    
    
}

// mapStateToProps

const mapStateToProps = (state) => {
	return {
        collectionId: state.editor.formData.id,
        documentTypes: state.editor.formData.content.documentTypes,
	};
}  

export default connect(
    mapStateToProps,
)(CollectionDocumentTypeField);
