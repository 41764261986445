import React, { Component } from 'react';
import { Button } from "../Button";

class Buttongroup extends Component {
    static defaultProps = {
        buttongroup: false
    }
    
    renderButton = (button, index) => {
        const { buttonType } = this.props;
        const { children } = button;

        if (children) {
            return (
                <Buttongroup {...this.props} key={index} buttongroup={children} />
            )
        }

        return (
            <Button {...button} type={buttonType} key={index} />
        )
    }

    getClassName = () => {
        const { className, type } = this.props;

        if (className) {
            return className
        }

        let classNames = []

        classNames.push('admin-buttongroup');

        if (type) {
            classNames.push('admin-buttongroup--' + type);
        }

        return classNames.join(' ');
    }

    render() {
        const { buttongroup, dataType } = this.props;
        const className = this.getClassName();

        if (buttongroup) {
            return (
                <div className={className} data-type={dataType}>
                    {buttongroup.map(this.renderButton)}
                </div>
            )
        }

        return (
            <div className={className} data-type={dataType}>
                {this.props.children}
            </div>
        )
    }
}

export default Buttongroup;
