import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadModel, editModel, saveModel } from '../redux/functions/model';
import { getSearch } from '../redux/functions/search';

import AppEditor from "../AppEditor"

import SidebarTemplate from "../AppDialog/Sidebar"
import PreviewTemplate from "./CollectionPreview/"

import model from "./schemas/collection"
import registry from "./components/registry"

const CollectionEditor = ({app, modelsById, searchById, models = "collections", collectionType = "exhibition", ...props}) => {
    const { uniqueId } = props.match.params;

    const onLoad = () => {
        if (uniqueId == "<new>") {
            props.editModel({
                status: "new",
                collectionType: collectionType
            });
        } else if (models && uniqueId) {
            props.loadModel(models, uniqueId);
        }
    }

    useEffect(() => {
        onLoad()
    }, [uniqueId])

    const uniqueModel = uniqueId && modelsById && modelsById[uniqueId]
    const collectionId = uniqueModel && uniqueModel.id
    const updatedAt = uniqueModel && uniqueModel.updatedAt

    const onRemoveSchema = ({formData: {uniqueId}}) => {
        props.saveModel("schemas", {
            uniqueId: uniqueId,
            collectionId: null,
        }, false)
    }

    const onAddSchema = ({formData: {uniqueId}}) => {
        props.saveModel("schemas", {
            uniqueId: uniqueId,
            collectionId: collectionId,
        }, false)
    }

    const onEditSchema = ({id, formData}) => {
        const referenceUrl = app.root + "/collection/" + uniqueId + "/schema/" + formData.uniqueId + "/edit"

        props.history.push({
            pathname: referenceUrl,
            search: "?backUrl=" + props.location.pathname + "&backId=" + id
        })

    }

    const onCreateSchema = ({id}) => {
        const referenceUrl = app.root + "/collection/" + uniqueId + "/schema/<new>/edit"

        props.history.push({
            pathname: referenceUrl,
            search: "?backUrl=" + props.location.pathname + "&backId=" + id
        })

    }

    const collectionSchemasQuery = {
        id: "/collection/" + collectionId + "/schemas",
        models: "schemas",
        fl: "id,uniqueId,title,name",
        status: "NOT trash",
        collectionId: collectionId || "*"
    }

    useEffect(() => {
        collectionId && props.getSearch(collectionSchemasQuery)
    }, [collectionId])

    const collectionSchemas = searchById && searchById[collectionSchemasQuery.id] && searchById[collectionSchemasQuery.id].models
    
    const filterDocumentTypes = (formData = []) => {

        if (!collectionId) {
            return formData
        }

        let schemaIds = []
        let schemaModels = []

        // filter schemas for _action

        formData && formData.map(item => {
            const { uniqueId, _action } = item

            if (!uniqueId) {
                return;
            }

            schemaIds.push(uniqueId)

            if (_action === "delete") {
                props.saveModel("schemas", {
                    uniqueId: uniqueId,
                    collectionId: null,
                }, false)

            } else {
                props.saveModel("schemas", {
                    uniqueId: uniqueId,
                    collectionId: collectionId,
                }, false)

                schemaModels.push({
                    ...item,
                    collectionId: collectionId
                })
            }

        })

        // add schemas that have been added elsewhere

        if (collectionSchemas) {
            collectionSchemas.map(model => {
                if (!schemaIds.includes(model.uniqueId)) {
                    schemaModels.push({
                        uniqueId: model.uniqueId,
                        collectionId: collectionId,
                        model: model
                    })
                }
            })
        }

        return schemaModels;
        
    }

    const onSubmit = ({formData, redirect}) => {

        const documentTypes = formData && formData.content && formData.content.documentTypes;

        const newFormData = {
            ...formData,
            content: {
                ...formData.content,
                documentTypes: filterDocumentTypes(documentTypes)
            }
        }

        props.saveModel(models, newFormData, redirect && false)

        if (redirect) {
            props.history.replace(redirect);
        }

    }

    if (!app.uniqueId) {
        return "Loading"
    }

    const formContext = {
        parents: app && app.parents,
        languages: app && app.languages,
        collectionId: collectionId,
        preview: {
            template: PreviewTemplate
        },
        sidebar: {
            template: SidebarTemplate
        },
        onCreateSchema: onCreateSchema,
        onEditSchema: onEditSchema,
        onAddSchema: onAddSchema,
        onRemoveSchema: onRemoveSchema,
    }


    return (
        <AppEditor {...props}
            registry={registry}
            schema={model.schema}
            uiSchema={model.uiSchema}
            formContext={formContext}
            onSubmit={onSubmit}
        />
    )


}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        modelsById: state.modelsById,
        searchById: state.searchById,
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        loadModel, editModel, saveModel,
        getSearch
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CollectionEditor);

