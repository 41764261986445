import React, { Component } from 'react';
import PropTypes from "prop-types";

import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import { FormattedDate } from "react-intl";

class FormatDimension extends Component {

    static contextTypes = { intl: PropTypes.object }

    static propTypes = { 
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    }
    
    renderCompare = () => {
        const { name, value } = this.props;

        moment.locale('nb')
        
        let v1, v2;
        
        const isSameYear = moment(value[0]).isSame(value[1], 'year');
        const isSameMonth = moment(value[0]).isSame(value[1], 'month');
        const isSameDay = moment(value[0]).isSame(value[1], 'day');
        
        if (name == 'ga:nthYear') {
            v1 = moment(value[0]).format('YYYY')
            v2 = moment(value[1]).format('YYYY')
        }

        if (name == 'ga:nthMonth') {
            v1 = moment(value[0]).format('MMMM YYYY')
            v2 = moment(value[1]).format('MMMM YYYY')
            
            if (isSameYear) {
                v1 = moment(value[0]).format('MMMM')
                v2 = moment(value[1]).format('MMMM')
            }
            
        }

        if (name == 'ga:nthWeek') {
            v1 = moment(value[0]).format('Wo YYYY')
            v2 = moment(value[1]).format('Wo YYYY')
        }

        if (name == 'ga:nthDay') {
            v1 = moment(value[0]).format('ll')
            v2 = moment(value[1]).format('ll')

            if (isSameYear) {
                v1 = v1.replace(moment(value[0]).format('YYYY'), '')
                v2 = v2.replace(moment(value[0]).format('YYYY'), '')
            }

            if (isSameMonth) {
                v1 = v1.replace(moment(value[0]).format('MMM'), '')
            }

        }

        if (name == 'ga:nthHour') {
            v1 = moment(value[0]).format('lll')
            v2 = moment(value[1]).format('lll')

            if (isSameYear) {
                v1 = v1.replace(moment(value[0]).format('YYYY'), '')
                v2 = v2.replace(moment(value[0]).format('YYYY'), '')
            }

            if (isSameDay) {
                v2 = moment(value[1]).format('HH:mm')
            }

        }

        if (name == 'ga:nthMinute') {
            v1 = moment(value[0]).format('HH:mm')
            v2 = moment(value[1]).format('HH:mm')
        }
        
        return v1 + " vs. " + v2;
    
    }

    render() {
        const { name, value } = this.props;
        
        if (Array.isArray(value) && value.length === 2) {
            return this.renderCompare();
        }

        if (name == 'ga:nthYear') {
            return moment(value).format('YYYY')
        }

        if (name == 'ga:nthMonth') {
            return moment(value).format('MMMM')
        }

        if (name == 'ga:nthWeek') {
            
            const w1 = moment(value).startOf('week').format('DD. MMM');
            const w2 = moment(value).endOf("week").format('DD. MMM');

            return w1 + "–" + w2
            
//            return moment(value).format('Wo YYYY')
//            return moment(value).format('Wo YYYY')
        }

        if (name == 'ga:nthDay') {
            return moment(value).format('LL')
        }

        if (name == 'ga:nthHour') {
            return moment(value).format('llll')
        }

        if (name == 'ga:nthMinute') {
            return moment(value).format('HH:mm')
        }
        
        return value;

    }
    
}

export default FormatDimension
