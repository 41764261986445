import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TabsUncontrolled from "./TabsUncontrolled"

class Tabs extends Component {
  
    static defaultProps = {
        className: "admin-tabs",
        forceRenderTabPanel: false,
        defaultIndex: 0,
        defaultFocus: false,
    };
  
    state = {
        index: 0,
        focus: false,
    }
  
    componentDidMount() {
        const { defaultIndex, defaultFocus } = this.props;
        
        this.setState({
            index: defaultIndex,
            focus: defaultFocus
        })
        
    }
    
    componentDidUpdate = (prevProps) => {
        
        if (prevProps.defaultIndex !== this.props.defaultIndex) {
            this.setState({
                index: this.props.defaultIndex                
            })
        }
        
    }
  
    onSelect = (index, prevIndex, event) => {
        let state = {
          focus: event.type === 'keydown',
        };
        
        state.index = index;
        this.setState(state);

        if (this.props.onSelect) {
            this.props.onSelect({
                index: index,
                prevIndex: prevIndex
            }, event)
        } else {
            
        }

    };
  
    render() {
        const { className } = this.props;
        const { focus, index } = this.state;

        return (
            <TabsUncontrolled className={className} selectedIndex={index} focus={focus} onSelect={this.onSelect}>
                { this.props.children }
            </TabsUncontrolled>
        )
    }
  
}

export default Tabs;