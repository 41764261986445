import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2'

import "./FieldCode.scss"

class FieldCode extends Component {
    static defaultProps = {
        type: "text",
        value: "",
        required: false,
        autofocus: false,
        options: {
          theme: "default",
          height: "auto",
          viewPortMargin: "infinity",
          mode: {
            name: "javascript",
            json: true,
            statementIndent: 2,
          },
          lineNumbers: true,
          lineWrapping: true,
          indentWithTabs: false,
          tabSize: 2,
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    }

    onBeforeChange = (editor, data, value) => {
        
        this.setState({
            value: value
        });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    getClassName = () => {
        const { className, type } = this.props;

        let classNames = []
        
        classNames.push('admin-field');
        classNames.push('field--code');
        
        return classNames.join(' ');
    }

    render() {
        const { id, type, options, value } = this.props;
        
        const className = this.getClassName();
        
        return (
            <div className={ className }>

                <CodeMirror
                  value={ value }
                  options={ options }
                  onBeforeChange={ this.onBeforeChange }
                />

            </div>
        )
    }
}

export default FieldCode;