// initialState

let initialState = {
};

// editor state

const modelsById = (state = initialState, action) => {

	switch (action.type) {

        case 'REQUEST_MODEL':
        
            return {
                ...state,
                [action.id]: undefined
            }
      	
        case 'RECEIVE_MODEL':

            const uploadProgress = state[action.id] && state[action.id].uploadProgress

            if (uploadProgress) {
                action.data = {
                    ...action.data,
                    uploadProgress: uploadProgress
                }
            }

            return {
                ...state,
                [action.id]: action.data
            }

        case 'RECEIVE_MODEL_STATUS':

            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    status: action.status
                }
            }

        case 'REQUEST_MODEL_UPLOAD_STATUS':

            return {
                ...state,
                [action.id]: {
                    ...state[action.id]
                }
            }

        case 'RECEIVE_MODEL_UPLOAD_STATUS':

            return {
                ...state,
                [action.id]: action.data
            }

        case 'RECEIVE_MODEL_UPLOAD_PROGRESS':

            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    uploadProgress: action.uploadProgress,
                }
            }
                
        default:
            return state
        }

}

export default modelsById;