import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Slider from '@material-ui/core/Slider';

const SliderField = ({ id, disabled, readonly, label, value, step, min, max, marks, onChange }) => {

    const handleChange = (event, value) => {
        console.log('slide', value)
        onChange && onChange(value, event)
    }

    const getAriaValue = (value) => {
        return value
    }


    return (
        <FormControl component="fieldset" fullWidth={true}>
            <FormLabel component="legend">{label}</FormLabel>
            <Slider
                id={id}
                disabled={disabled}
                defaultValue={value}
                getAriaValueText={getAriaValue}
                aria-labelledby={id}
                valueLabelDisplay="auto"
                step={step}
                marks={marks}
                min={min}
                max={max}
                onChange={handleChange}
            />
        </FormControl>

    )

    

}

export default SliderField;