import React from 'react';
import PropTypes from "prop-types";
import { RouterLink as Link } from '../Router/';
import ButtonIntl from './ButtonIntl';
import ButtonUpload from './ButtonUpload';

import './Button.scss';

class Button extends React.Component {
    static contextTypes = { intl: PropTypes.object }

    renderIcon = () => {
        let { icon } = this.props;

        if (!icon) {
            return false;
        }

        if (icon && icon.imageUrl) {
            return (
                <i className="admin-button__icon  material-icons">
                    <img src={icon.imageUrl} alt="" />
                </i>
            )
        }

        return (
            <i className="admin-button__icon  material-icons">{icon}</i>
        )
    }

    renderLabel = () => {
        const title = this.props.title || this.props.label || false;

        if (!title) {
            return false;
        }

        return (
            <i className="admin-button__label">{title}</i>
        )
    }

    renderToggle = () => {
        const { onToggle } = this.props;

        if (!onToggle) {
            return false
        }

        return (
            <div className="admin-button__toggle  material-icons">arrow_drop_down</div>
        )
    }

    getClassName = () => {
        let { buttonType, type, className } = this.props;
        
        if (!buttonType && type) {
            buttonType = type
        }

        if (className) {
            return className
        }

        let classNames = [];

        classNames.push("admin-button");

        if (buttonType) {
            classNames.push("admin-button--" + buttonType);
        }

        return classNames.join(' ');
    }

    render() {
        const { intlLabel, style, url, value, role, controls, tabindex, selected, expanded, disabled, haspopup, target, onClick, onToggle, onUpload, onMouseDown } = this.props;

        if (this.context.intl && intlLabel) {
            return (
                <ButtonIntl {...this.props} />
            )
        }

        const className = this.getClassName();
        
        if (onUpload) {
            
            return (
                <ButtonUpload {...this.props} />
            )
            
        }

        if ((url || onClick) && onToggle) {
            return (
                <div className={className} style={style}
                    aria-selected={selected}
                    aria-haspopup={haspopup}
                    aria-expanded={expanded}>
                    <Button {...this.props} className={false} children={false} haspopup={false} expanded={false} selected={false} onToggle={undefined} />
                    <div className="admin-button__toggle  material-icons" onClick={onToggle}>arrow_drop_down</div>
                </div>
            )
        }

        if (url) {
            return (
                <Link to={url} className={className} style={style} onClick={onClick} target={target}
                    tabIndex={tabindex}
                    controls={controls}
                    haspopup={haspopup}
                    selected={selected}
                    expanded={expanded}>
                    {this.renderIcon()}
                    {this.renderLabel()}
                    {this.props.children}
                </Link>
            );
        }

        if (onToggle) {
            return (
                <button className={className} style={style} onClick={onToggle}
                    value={value}
                    disabled={disabled}
                    aria-haspopup={haspopup}
                    aria-selected={selected}
                    aria-expanded={expanded}>
                    {this.renderIcon()}
                    {this.renderLabel()}
                    {this.renderToggle()}
                    {this.props.children}
                </button>
            );
        }

        return (
            <button className={className} style={style} onClick={onClick} onMouseDown={onMouseDown} onToggle={onToggle}
                tabIndex={tabindex}
                role={role}
                value={value} 
                disabled={disabled}
                aria-controls={controls}
                aria-haspopup={haspopup}
                aria-selected={selected}
                aria-expanded={expanded}>
                {this.renderIcon()}
                {this.renderLabel()}
                {this.props.children}
            </button>
        );
    }
}

export default Button;