import React, { Component } from 'react';
import Tooltip from "./ChartTooltip"

import { FormatMetric, FormatDimension } from "../"

class TooltipCompare extends Component {
    
    renderSingle = () => {
        const { slice, metric, dimension } = this.props;
        
        const series = slice.points;
        const s1 = series[0].data || undefined;

        const { metricType, x, y, datetime } = s1;
        
        let d;
        
        if (dimension.startsWith('ga:nth')) {
            d = datetime;
        } else {
            d = x;
        }
        
        return (
            <Tooltip>
                <h2 className="admin-tooltip__title"><FormatDimension name={dimension} value={d} /></h2>
                <h3 className="admin-tooltip__value"><FormatMetric type={metricType} value={y} /></h3>
            </Tooltip>
        )
        
    }
    
    render() {
        const { slice, metric, dimension } = this.props;

        const series = slice.points;
        
        let s1 = series[1].data || undefined;
        let s2 = series[0].data;
        
        if (s1 && !s2) {
            return this.renderSingle(s2)
        }
        
        let change, trending;
        
        if (s1.value > s2.value) {
            change = (s1.value - s2.value) / s1.value * 100;
            trending = "up"
        } else if (s2.value > s1.value) {
            change = (s2.value - s1.value) / s2.value * 100;
            trending = "down"
        } else {
            change = 0
            trending = "flat"
        }

        if (dimension.startsWith('ga:nth')) {
            s1.d = s1.datetime;
            s2.d = s2.datetime;
        } else {
            s1.d = s1.x;
            s2.d = s2.x;
        }
        
        return (
            <Tooltip>
                <h2 className="admin-tooltip__title"><FormatDimension name={dimension} value={[s1.d, s2.d]} /></h2>
                <h3 className="admin-tooltip__value"><FormatMetric id={s1.metric} type={s1.metricType} value={s1.y} /></h3>
                <p className="admin-tooltip__change" data-trending={trending}><FormatMetric type="PERCENT" value={change} /></p>
            </Tooltip>
        )
        
    }

}

export default TooltipCompare