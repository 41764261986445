import React from 'react'

import ImagePreview from "./ImagePreview"
import VideoPreview from "./VideoPreview"
import AudioPreview from "./AudioPreview"

const templates = {
    "image": ImagePreview,
    "video": VideoPreview,
    "audio": AudioPreview
}

const MediaPreview = ({mediaType, ...media}) => {

    if (!mediaType) {
        return <p>No mediaType</p>
    }

    const PreviewTemplate = mediaType && templates[mediaType]

    if (!PreviewTemplate) {
        return <p>No template for mediaType:{mediaType}</p>
    }

    return (
        <PreviewTemplate {...media} />
    )
    

}

export default MediaPreview