export default {
    "select": {
        "parentId": "parentId",
        "title" : "title",
        "description" : "description",
        "label": "label"
    },
    prepare({formData}) {
        const content = formData && formData.content;

        const title = content && content.title || "Untitled";
        const parentId = content && content.parentId;

        let metadata = []

        if (parentId) {
            metadata.push('En del av ' + parentId)
        }
 
        return {
            parentId: parentId || null,
            title: title,
            description: metadata.join(' – '),
            label: "Sted"
        }

    }
  
}