export default {
  type: "string",
  enum: [
    "title",
    "status",
    "updatedAt",
    "updatedAt DESC",
    "createdAt",
    "createdAt DESC",
    "deletedAt",
    "deletedAt DESC"
  ],
//  default: ""
}