import React, { Component } from 'react';
import "./FieldCheckbox.scss"

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class FieldCheckbox extends Component {
    static defaultProps = {
        value: "",
        required: false,
        disabled: false,
        readonly: false,
        autofocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    }

    onChange = (event) => {
        
        let value;
        
        if (event.target.checked) {
            value = true || this.props.value;
        } else {
            value = false
        }
        
        this.setState({
            value: value
        });

        if (this.props.onChange) {
            this.props.onChange(value, event);
        }
    }

    render() {
        const { id, name, value, checked, required, disabled, readonly, autofocus } = this.props;

        const label = this.props.title || this.props.label || "bool"

        return (
            <FormControlLabel
                    control={
                        <Checkbox checked={checked} onChange={(event) => this.onChange(event)} value={value} />
                    }
                label={label}
            />            
        )

        /*

        return (
            
            <label className="admin-field  field--checkbox">
            
                <input type="checkbox" id={id} 
                    name={name}
                    value={value}
                    checked={checked}
                    required={required}
                    disabled={disabled || readonly}
                    autoFocus={autofocus}
                    onChange={(event) => this.onChange(event)}
                    onBlur={(event) => this.onBlur(event)}
                    onFocus={(event) => this.onFocus(event)}
                />

                <i className="checkbox__icon"></i>
                <b className="checkbox__label">{ label }</b>

            </label>
        )

        */
    }
}

export default FieldCheckbox;