import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    content: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        "&[aria-expanded=false]": {
            right: "50%"
        }
    }
}));

const FinderContent = ({expanded = true, children}) => {
    const classes = useStyles()

    return (
        <section className={classes.content} aria-expanded={expanded}>
            {children}
        </section>
    )

}

export default FinderContent;