import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsWidget, FormatMetric } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

import { FormattedMessage } from "react-intl";
import messages from "./WidgetSessions.messages";

import moment from "moment"

class WidgetSessions extends Component {
    
    static defaultProps = {
        id: "sessions",
        widgetSize: "1:1",
        metric: "ga:sessions",
        query: {
            countryId: "no",
            offset: 0,
            owner: 1,
            reportType: 0,
            rows: 1000,
            startDate: "14daysAgo,7daysAgo",
            endDate: "8daysAgo,yesterday",
            metrics: "sessions",
            dimensions: "nthDay",
            metrics: "sessions",
            orderBy: "nthDay",
            sort: "ascending",
        },
        scopes: {
            "yesterday": {
                compare: true,
                query: {
                    startDate: "yesterday,2daysAgo",
                    endDate: "yesterday,2daysAgo",
                    dimensions: "nthHour",
                    orderBy: "nthHour"
                }
            },
            "7days": {
                compare: true,
                query: {
                    startDate: "7daysAgo,14daysAgo",
                    endDate: "yesterday,8daysAgo"
                }
            },
            "28days": {
                compare: true,
                query: {
                    startDate: "28daysAgo,56daysAgo",
                    endDate: "yesterday,29daysAgo"
                }
            },
            "90days": {
                compare: true,
                query: {
                    startDate: "90daysAgo,180daysAgo",
                    endDate: "yesterday,91daysAgo"
                }
            },
            "52weeks": {
                compare: false,
                stacked: true,
                query: {
                    startDate: "365daysAgo",
                    endDate: "yesterday",
                    dimensions: "nthWeek",
                    orderBy: "nthWeek"
                }
            },
            "yearToDate": {
                type: "bar",
                stacked: false,
                tooltipLabel: ":year",
                query: {
                    startDate: moment().startOf('year').format("YYYY-MM-DD")+","+moment().subtract(1, "year").startOf('year').format("YYYY-MM-DD"),
                    endDate: "yesterday," + moment().subtract(1, "year").endOf('year').format("YYYY-MM-DD"),
                    dimensions: "nthMonth",
                    orderBy: "nthMonth"
                }
            }
        }
    }
    
    state = {
        values: {}
    }
    
    componentDidMount() {
        this.getQuery()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.scope !== this.props.scope) {
            this.getQuery()
        }

        if (prevProps.data[this.props.id] !== this.props.data[this.props.id]) {
            this.getValues()
        }
        
    }
    
    getValues = () => {
        const { metric, scope, data, id } = this.props;
        
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]

        // get metricName & metricType
        
        if (!report) {
            return false
        }

        const defaultMetric = report.columnHeader.metricHeader.metricHeaderEntries[0];
        const metricName = defaultMetric.name;
        const metricType = defaultMetric.type;
        
        // get totals
        
        const { totals, maximums, minimums } = report.data;
        
        const total = totals[0].values[0]
        const maximum = maximums[0].values[0]
        const minimum = minimums[0].values[0]
        const average = parseFloat(total / report.data.rows.length).toFixed(0);
        
        // get change
        
        let change, percent, trending;
        
        if (totals.length === 2) {

            const s1 = totals[0].values[0]
            const s2 = totals[1].values[0]
            
            if (s1 > s2) {
                change = (s1 - s2);
                percent = change / s1 * 100
                trending = "up"
            } else if (s2 > s1) {
                change = (s2 - s1);
                percent = change / s2 * 100
                trending = "down"
            } else {
                change = 0
                trending = "flat"
            }

        }
        
        // set values
        
        const values = {
            total: <FormatMetric {...defaultMetric} value={total} />,
            average: <FormatMetric {...defaultMetric} value={average} />,
            maximum: <FormatMetric {...defaultMetric} value={maximum} />,
            minumum: <FormatMetric {...defaultMetric} value={minimum} />,
            change: <FormatMetric {...defaultMetric} value={change} />,
            percent: <FormatMetric type="PERCENT" value={percent} />,
        }   
        
        this.setState({
            values: values,
            trending: trending
        })
        
    }
    
    getQuery = () => {
        let { widgetSize, id, metric, scopes, scope, query, theme } = this.props;
        
        let widget = scopes[scope];
        
        if (!widget) {
            return false 
        }
        
        query = {
            id: id,
            ...query,
            ...widget.query,
        }
        
        this.props.getReports(query)
        
        this.setState({
            metric: metric,
            scope: scope
        })
        
    }
    
    render() {
        const { theme, scopes, scope } = this.props;
        const { values, trending } = this.state;

        const { data, id } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        // get messages
        
        let loadingTitle, title, description;
        
        const loadingId = scope
        const titleId = [scope, "title"].join('.')
        const descriptionId = [scope, "description"].join('.')
        const trendingId = [scope, trending].join('.')

        if (messages[loadingId]) {
            loadingTitle = <FormattedMessage {...messages[loadingId]} values={values} />
        }
        
        if (messages[titleId]) {
            title = <FormattedMessage {...messages[titleId]} values={values} />
        }
        
        if (messages[trendingId]) {
            description = <FormattedMessage {...messages[trendingId]} values={values} />
        } else if (messages[descriptionId]) {
            description = <FormattedMessage {...messages[descriptionId]} values={values} />
        }

        
        if (!report) {

            return (
                <AnalyticsWidget {...this.props} {...scopes[scope]} loadingTitle={loadingTitle} />
            )
            
        }

        return (
            <AnalyticsWidget {...this.props} {...scopes[scope]} title={title} description={description} trending={trending} report={report} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetSessions);