import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1)
    },
    group: {
        display: "flex",
        flexDirection: props => { return props.direction },
    }
}));

const OptionsBase = ({className, label, inline = false, helperText, children, ...props}) => {

    if (inline) {
        props.direction = "row"
    } else {
        props.direction = "column"
    }

    const classes = useStyles(props)

    return (
        <FormControl className={className || classes.root} component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <div className={classes.group}>
                {children}
            </div>
            {helperText && <FormHelperText>{helperText}</FormHelperText> }
        </FormControl>
    )

}

export default OptionsBase;