const topicsMenu = {
    type: "documents/tree",
    title: "DigitaltMuseum",
    icon: "home",
    pathname: "",
    query: {
        models: "documents",
        status: "NOT trash",
        documentType: "topic",
        q: "NOT parentId:*"
    }
}

export default [
    topicsMenu,
    {
        role: "group",
        title: "Artikler",
        icon: "view_quilt",
        pathname: "article",
        query: {
            models: "documents",
            documentType: "article",
            status: "NOT trash"
        }
    },
    {
        role: "group",
        type: "media",
        title: "Media",
        children: [
            {
                type: "media/image",
                title: "Images"
            },
            {
                type: "media/video",
                title: "Video"
            },
            {
                type: "media/audio",
                title: "Audio"
            },
            {
                type: "media/misc",
                title: "Misc"
            },
            {
                type: "media/application",
                title: "Apps"
            },
            {
                type: "media/upload",
                title: "Upload"
            }
        ]
    },
    {
        role: "group",
        title: "Innboks",
        icon: "inbox",
        pathname: "inbox"
    },
    {
        title: "Statistikk",
        icon: "bar_chart",
        pathname: "analytics",
        template: "analytics"
    },
    {
        role: "group",
        type: "latest",
        title: "Latest",
        pathname: "latest"
    },
    {
        role: "group",
        type: "trash",
        title: "Trash",
        pathname: "trash"
    }

]