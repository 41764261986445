import React, { Component } from 'react';
import PropTypes from "prop-types"
import CheckboxesField from "./CheckboxesField"

const CheckboxesWidget = ({id, disabled, options, value = [], autofocus, readonly, onChange, ...props}) => {
    const { enumOptions, enumDisabled, inline } = options;
    const label = options.title || props.title || props.label || "bool"

    const checkboxOptions = enumOptions.map((option, index) => {
        const itemId = id + "_" + index;
        const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        const checked = value.indexOf(option.value) !== -1;

        return {
            id: itemId,
            label: option.label,
            value: option.value,
            disabled: disabled || itemDisabled || readonly,
            checked: checked
        }

    });

    return (
        <CheckboxesField id={id} label={label} options={checkboxOptions} value={value} onChange={onChange} />
    )
  
}
  
CheckboxesWidget.defaultProps = {
    autofocus: false,
    options: {
      inline: false,
    },
};
  
CheckboxesWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.array,
        inline: PropTypes.bool,
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
}
  
export default CheckboxesWidget;