import { uiSchemaSection } from "../section";

export default {
    "ui:fieldset": [
        "header",
        "sections",
        "isPartOf"
    ],
    "header": {
        "ui:fieldset": [
            "image",
            "title",
            "leadtext"
        ],
        "ui:title": "Header",
        "ui:description": {
            "select": "title"
        },
        "ui:layout": "section",
        "ui:options" : {
            "collapsible": true,
        },
        "image": {
            "ui:field": "media",
            "ui:format": "2:1",
            "ui:options": {
                "editable": true,
                "hotspot": true
            },
            "ui:buttons": [
                "sidebar", "upload"
            ]
        },
        "ui:summary": {
            "select": "title"
        },
        "title": {
            "ui:title": "Tittel"
        },
        "leadtext": {
            "ui:title": "Ingress",
            "ui:widget": "textarea"
        }
    },
    "sections": {
        "ui:title": "Innhold",
        "ui:description": "[count] seksjoner",
        "ui:options" : {
            "collapsible": true,
        },
        "ui:layout": "section",
        "ui:buttons" : [
            {
                "label" : "Tekst",
                "item" : {
                    "sectionType" : "text"
                }
            },
            {
                "label" : "Media",
                "item" : {
                    "sectionType" : "media"
                }
            },
            {
                "label" : "DM",
                "item" : {
                    "sectionType" : "objects"
                }
            }
        ],
        "items": uiSchemaSection
    },
    "isPartOf": {
        "ui:title": "Er en del av",
        "ui:description": "[count] tema",
        "ui:layout": "section",
        "ui:options" : {
            "collapsible": true,
        },
        "ui:buttons": [
            {
                "type": "sidebar",
                "label": "Finn tema"
            },
            {
                "label": "Legg til"
            }
        ]
    }
}