import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontFamily: "Akkurat, sans-serif !important"
    },
    overrides: {
        MuiInputBase: {
            root: {
                fontFamily: "Akkurat, sans-serif !important",
                lineHeight: "1.25 !important"
            }
        },
        MuiFilledInput: {
            root: {
//                backgroundColor: "rgba(255,255,255,0.9) !important",
                borderTopLeftRadius: "0 !important",
                borderTopRightRadius: "0 !important"
            }
        },
        MuiFormLabel: {
            root: {
//                fontFamily: "Akkurat, sans-serif !important"
            }
        },
        MuiFormHelperText: {
            root: {
//                fontFamily: "Akkurat, sans-serif !important"
            }
        },
    },
});

export default theme