import React, { Component } from 'react';
import Schema from "../Schema/SchemaBase";

import PreviewHeader from "./PreviewHeader"
import PreviewField from "./PreviewField"

import "./SchemaPreview.scss";

const layouts = {
    "document": PreviewField,
    "section": PreviewField,
    "block": PreviewField,
    "fieldset": PreviewField,
    "field": PreviewField,
    "module": PreviewField,
    "media": PreviewField
}

class SchemaPreview extends Component {
  
    static defaultProps = {
        schema: {},
        formData: {},
        formContext: {
            languages: ["en"],
            currentLocale: "en"
        }
    }
  
    render() {
        const { schema, formData, formContext } = this.props;

        const { currentLocale } = formContext;
        
        let fieldType;
        
        if (formData && formData.documentType) {
            fieldType = "document"
        } else if (formData && formData.mediaType) {
            fieldType = "media"
        }
    
        return (
            <div className="admin-preview  admin-preview--schema" data-type={fieldType}>
                <PreviewHeader {...formData} currentLocale={currentLocale} />
                <Schema schema={schema} formData={formData} formContext={formContext} layouts={layouts} />
            </div>
        )

    }

}

export default SchemaPreview;