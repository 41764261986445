import React, { useEffect, useState } from 'react';
import { getUiOptions } from '@frontend-components/admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MediaDialog from "./MediaDialog"
import DocumentsDialog from "./DocumentsDialog"
import SidebarSearch from "./SidebarSearch"

const templates = {
    "media": MediaDialog,
    "documents": DocumentsDialog,
    "search": SidebarSearch
}

const DialogApp = ({app, dialog, ...props}) => {
    const { expanded, button, schema, uiSchema, formData, onChange, onClose } = dialog;

    if (!expanded) {
        return false    
    }

    let itemSchema;

    if (schema && schema.type === "array") {
        itemSchema = schema.items
    } else if (schema) {
        itemSchema = schema
    }

    const uiOptions = uiSchema && getUiOptions(uiSchema)
    const uiSidebar = uiOptions && uiOptions.sidebar

    const getReferenceQuery = (query) => {
        const documentSchema = itemSchema && itemSchema.properties && itemSchema.properties.reference
        const documentType = documentSchema && documentSchema.properties.documentType
    
        query.documentType = "*"
    
        if (documentType && documentType.enum) {
            query.documentType = documentType.enum
        }
    
        if (documentType && documentType.default) {
            query.documentType = documentType.default
        }

        return {
            ...query,
            models: "documents"
        } 
        
    }

    const getMediaQuery = (query) => {
        const mediaSchema = itemSchema && itemSchema.properties && itemSchema.properties.media
        const mediaType = mediaSchema && mediaSchema.properties.mediaType
    
//        query.mediaType = "*"

        if (mediaType && mediaType.enum) {
            query.mediaType = mediaType.enum
        }
    
        if (mediaType && mediaType.default) {
            query.mediaType = mediaType.default
        }

        return {
            ...query,
            models: "media"
        }
        
    }

    const getQuery = () => {

        if (dialog && dialog.query) {
            return {
                ...dialog.query,
                siteId: app.siteId,
                collectionId: app.collectionId
            }
        }

        if (button && button.query) {
            return {
                ...button.query,
                siteId: app.siteId,
                collectionId: app.collectionId
            }
        }

        if (uiSidebar && uiSidebar.query) {
            return {
                ...uiSidebar.query,
                siteId: app.siteId,
                collectionId: app.collectionId
            }
        }

        if (itemSchema.properties && itemSchema.properties.referenceId) {
            return getReferenceQuery({
                siteId: app.siteId,
                collectionId: app.collectionId
            })
        }

        if (itemSchema.properties && itemSchema.properties.mediaId) {
            return getMediaQuery({
                siteId: app.siteId,
                collectionId: app.collectionId
            })
        }

        return {
        }

    }

    const query = getQuery()
    const template = query.models;

    const DialogTemplate = template && templates[template]

    if (!DialogTemplate) {
        return (
            <div>
                Missing template for {JSON.stringify(query)}.
                <button onClick={onClose}>Close</button>
            </div>
        )
    }

    return (
        <DialogTemplate query={query} schema={schema} formData={formData} onChange={onChange} onClose={onClose} /> 
    )

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

const mapStateToProps = (state) => {
	return {
        app: state.app,
        collection: state.collection,
        dialog: state.dialog,
	};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialogApp);