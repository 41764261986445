export default {
    "type": "array",
    "items": {
        "type": "object",
        "properties": {
            "status": {
                "type": "string"
            },
            "role": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "number"
                    },
                    "title": {
                        "type": "string"
                    }
                }
            },
            "site": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "number"
                    },
                    "title": {
                        "type": "string"
                    }
                }
            }
        }
    }
}