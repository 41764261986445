import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ImagePreview } from "@frontend-components/admin"
import { VideoPlayer, AudioPlayer } from "../components"

const templates = {
    "image": ImagePreview,
    "video": VideoPlayer,
    "audio": AudioPlayer
}

const BulkPreview = ({bulkCount, bulkModels = []}) => {

    if (bulkModels.length === 1) {
        const model = bulkModels[0]
        const PreviewTemplate = model.mediaType && templates[model.mediaType]
        
        if (PreviewTemplate) {
            return (
                <PreviewTemplate {...model} />
            )
        } else {
            return (
                <p>{JSON.stringify(model)}</p>
            )
        }

    
    }

    return (
        <p>{bulkCount}</p>
    )

    return (
        <p>{JSON.stringify(bulkModels)}</p>
    )
    
}

const mapStateToProps = (state) => {
	return {
        bulkCount: state.bulk.count,
        bulkList: state.bulk.list,
        bulkModels: state.bulk.models,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkPreview);