import React, { useState, useEffect } from 'react';
import { LayoutFinder } from "@frontend-components/admin"
import Typography from '@material-ui/core/Typography';
import createPalette from '@material-ui/core/styles/createPalette';
import color from "color"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: "white",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        padding: theme.spacing(2,2,2,1)
    },
    color: {
        display: "flex",
        width: "100%",
        color: props => { return props.contrastText }
    },
    colorShade: {
        flexBasis: 0,
        flexGrow: 1,
        padding: theme.spacing(1)
    }
}));

const PreviewColor = ({label, color}) => {

    const { main, light, dark } = color

    const classes = useStyles(color)

    return (
        <section className={classes.palette}>
            <Typography>{label}</Typography>
            <div className={classes.color}>
                <div className={classes.colorShade} style={{backgroundColor: light}}>
                    <Typography>Light</Typography>
                    {light}
                </div>
                <div className={classes.colorShade} style={{backgroundColor: main}}>
                    <Typography>Main</Typography>
                    {main}
                </div>
                <div className={classes.colorShade} style={{backgroundColor: dark}}>
                    <Typography>Dark</Typography>
                    {dark}
                </div>
            </div>
        </section>
    )

}

const PreviewCollectionTheme = ({app}) => {

    const classes = useStyles()

    const theme = app.theme;

    const primary = theme && theme.palette && theme.palette.primary
    const secondary = theme && theme.palette && theme.palette.secondary || primary

    if (!primary.main) {
        return (
            <div>
                no palette
            </div>
        )
    }

    const palette = createPalette({
        primary: primary,
        secondary: secondary
    })
    
    return (
        <LayoutFinder title="Theme">
            <PreviewColor label="Primary" color={palette.primary} />
            <PreviewColor label="Secondary" color={palette.secondary} />
        </LayoutFinder>
    )

}

export default PreviewCollectionTheme