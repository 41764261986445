import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import LoginIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        color: "inherit"
    },
    icon: {
        backgroundColor: "transparent",
        color: "inherit",
//        border: "1px dotted",
        borderColor: "inherit",
        borderRadius: "50%",
        padding: "6px",
        width: "36px",
        height: "36px",
        margin: "-6px",
    }

}));

const ButtonLogin = ({onClick}) => {

    const classes = useStyles()

    return (
        <IconButton className={classes.root} onClick={onClick} >
            <LoginIcon className={classes.icon} />
        </IconButton>
    )

}

export default ButtonLogin;