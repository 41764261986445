import React from 'react';

import LayoutTable from "../components/Layout/LayoutTable"
import FinderModel from "./FinderModel"
import FinderSection from "../components/Finder/FinderSection"

import ResultsLoader from "./ResultsLoader"
import ResultsHeader from "./ResultsHeader"
import ResultsFooter from "./ResultsPages"

const ResultsTable = ({layout = "table", ...props}) => {
    const { models = [] } = props

    return (
        <ResultsLoader {...props}>
            <FinderSection>
                <ResultsHeader {...props} />
                <LayoutTable>
                        {models.map((model, index) => {
                            return (
                                <FinderModel model={model} layout={layout} {...props} key={index} />
                            )
                        })}
                </LayoutTable>
                <ResultsFooter {...props} />
            </FinderSection>
        </ResultsLoader>
    )

}

export default ResultsTable