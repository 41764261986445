import React, { Component } from 'react';
import Buttongroup from "./Buttongroup";

import './ButtongroupAction.scss';

class ButtongroupAction extends Component {
    render() {
        return (
            <Buttongroup {...this.props} className="admin-buttongroup  admin-buttongroup--action" buttonType="action">
                {this.props.children}
            </Buttongroup>
        )
    }
}

export default ButtongroupAction;