import { API } from '../settings';
import { addFavouriteItem, removeFavouriteItem } from '../actions/favourites';

// add + remove

export function addFavourite(id, data) {
  
  return function (dispatch) {
    dispatch(addFavouriteItem(id, data))
  }
  
}

export function removeFavourite(id) {

  return function(dispatch) {
    dispatch(removeFavouriteItem(id))
  }
  
}