import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { WidgetBase, AppsGrid } from "../"

const useStyles = makeStyles(theme => ({
    scrollX: {
        position: "absolute",
//        top: "50%",
//        transform: "translateY(-50%)",
        top: 0,
        right: 0,
        bottom: "auto",
        left: 0,
        overflowX: "scroll",
//        display: "flex",
//        alignItems: "center",
//        justifyContent: "center"
    },
    gridX: {
        display: "flex",
        alignItems: "center",
    },
    scrollY: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll"
    },
    gridY: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
    }
}));

const AppsWidget = ({apps = [], ...props}) => {

    const classes = useStyles()

    const grid = props && props.size.split(':')
    const rows = grid && grid[1]

    let size, orientation

    if (rows < 2) {
        orientation = "x"
        size = "small"
    } else if (rows >= 3) {
        orientation = "y"
    }

    if (orientation === "y") {
        return (
            <WidgetBase {...props}>
                <div className={classes.scrollY}>
                    <AppsGrid size={size} className={classes.gridY} apps={apps} />
                </div>
            </WidgetBase>
        )
    }

    return (
        <WidgetBase {...props}>
            <div className={classes.scrollX}>
                <AppsGrid size={size} className={classes.gridX} apps={apps} />
            </div>
        </WidgetBase>
    )

}

AppsWidget.defaultProps = {
    size: "16:2"
}

export default AppsWidget;