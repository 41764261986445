import uiPreview from "./uiPreview"

import authorizedSites from "./authorizedSites/uiSchema"
import authorizedCollections from "./authorizedCollections/uiSchema"


export default {
    "ui:field": "pageEditor",
    "ui:fieldset": ["sections"],
    "ui:preview": uiPreview,
    "sections": {
        "ui:field": "pageContent",
        "ui:nav": true,
        "ui:fieldset": [
            "user",
            "sites",
            "collections"
        ],
        "user": {
            "ui:layout": "section",
            "ui:icon": "person",
            "ui:title": "Bruker",
            "ui:fieldset": [
                "name",
                "authUsername",
                "isSystemAdmin"
            ],
            "ui:grid": 3,
            "name": {
                "ui:title" : "Navn",
            },
            "authUsername": {
                "ui:title": "E-postadresse",
                "ui:widget": "email"
            },
            "isSystemAdmin": {
                "ui:title": "Systemadministrator",
                "ui:widget": "switch"
            }
        },
        "sites": {
            "ui:layout": "section",
            "ui:icon": "lock",
            "ui:title": "Sites",
            "ui:fieldset": [
                "authorizedSites"
            ],
            "authorizedSites": authorizedSites
        },
        "collections": {
            "ui:layout": "section",
            "ui:icon": "lock",
            "ui:title": "Collections",
            "ui:fieldset": [
                "authorizedCollections"
            ],
            "authorizedCollections": authorizedCollections
        }
    }
}