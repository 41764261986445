import layout from './schemaSectionMediaLayout';
import moduleMedia from '../module/schemaModuleMedia';

export default {
    "type": "object",
    "properties": {
        "sectionType": {
            "enum": ["media"]
        },
        "sectionLayout": layout,
        "media": {
            "type": "array",
            "items": moduleMedia
        }
    }
}