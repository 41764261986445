import sectionType from './sectionType';
import sectionText from './sectionText';
import sectionMedia from './sectionMedia';
import sectionEkultur from './sectionEkultur';
  
export default {
    "type": "object",
    "properties": {
        "sectionType": sectionType,
        "title": {
            "type" : "localizedString"
        },
        "titleHidden": {
            "type" : "boolean"
        }
    },
    "dependencies": {
        "sectionType" : {
            "oneOf": [
                sectionText,
                sectionMedia,
                sectionEkultur
            ]
        }
    }
}