import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUiOptions } from "@frontend-components/admin"
import { getSchemaUpload } from '../../redux/functions/uploadSchema';

const MediaChildrenField = ({editor, ...props}) => {

    const { schema, uiSchema } = props;

    const itemSchema = schema && schema.items;
    const mediaType = itemSchema && itemSchema.properties && itemSchema.properties.media && 
        itemSchema.properties.media.properties && itemSchema.properties.media.properties.mediaType && 
        itemSchema.properties.media.properties.mediaType.default;

    const collectionId = editor && editor.formData && editor.formData.collectionId
    const parentId = editor && editor.formData && editor.formData.id

    const { registry } = props;

    const handleUpload = (props, acceptedFiles = []) => {

        const uploadData = {
            parentId: parentId,
            collectionId: collectionId,
            mediaType: mediaType,
            status: "upload"
        }

        console.log("uploadData", props)

        getSchemaUpload({...props, acceptedFiles, uploadData})

    }

    const newRegistry = {
        ...registry,
        formContext: {
            ...registry.formContext,
            onUpload: handleUpload
        }
    }

    const { ArrayField } = registry.fields

    let accept;

    if (mediaType === "artwork") {
        accept = "image/*" 
    }

    if (mediaType === "audiotrack") {
        accept = "audio/*" 
    }

    if (mediaType === "captions") {
        accept = ".vtt,.srt" 
    }

    const uiButtons = [
        {
            "type": "upload",
            "label": "Upload",
            "accept": accept
        }
    ]

    const newUiSchema = {
        ...uiSchema,
        "ui:buttons": uiButtons
    }
    
    return (
        <ArrayField {...props} registry={newRegistry} uiSchema={newUiSchema} />
    )
    
}

const mapStateToProps = (state) => {
	return {
        editor: state.editor
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        getSchemaUpload,
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaChildrenField);
