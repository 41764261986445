import { getAppData } from '../../redux/functions/app';
import { getMenuMedia, getMenuLatest, getMenuTrash, getMenuEkultur } from '../../redux/functions/menu';
import { getSiteCollection } from '../../redux/functions/appCollection';

import schemas from '../schemas/';

export function getApp(props) {
    const { site, collection } = props;

    const title = "KulturPunkt"
    const name = "kp"
    const root = "/" + site.name + "/" + name

    props = {
        ...props,
        sideId: site && site.id,
        collectionId: collection && collection.id,
        root: root,
        name: name,
        title: title,
    }
    
    const appEdit = getAppAction(props)
    const appMenu = getAppMenu(props)

    const appSearch = {
        "placeholder": "Søk i " + title
    }

    const app = {
        ...props,
        theme: {
            primaryColor: "#00C18A"
        },
        search: appSearch,
        edit: appEdit,
        menu: appMenu,
        menuTree: root,
        schemas: schemas,
        sidebar: {
            expanded: true
        },
        languages: [
            "no", "sv", "en", "de", "es", "fr"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
    }

}


export function getAppAction(app) {
    const { root } = app;

    return [{
        label: "Nytt innhold",
        icon: "",
        menu: [
            {
                role: "group",
                menu: [
                    {
                        label: "Tema",
                        url: root + "/topic/<new>/edit",
                    },
                    {
                        label: "Artikkel",
                        url: root + "/article/<new>/edit",
                    }
                ]
            },
            {
                label: "Last opp",
                icon: "cloud_upload",
                url: root + "/upload",
                action: {
                actionType: "upload"
            }
            }
        ]
    }]
    
}

export function getAppMenu(app) {
    const { root, collectionId } = app;

    const navHome = {
        label: "KulturPunkt",
        icon: "home",
        url: root,
        query: {
            queryType: "tree",
            collectionId: collectionId,
            models: "documents",
            status: "NOT trash",
            documentType: "topic",
            q: "NOT parentId:*"
        }
      }

    let navGroup = {
        label: "Tema",
        icon: "view_quilt",
        url: root + "/topic",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "topic",
            status: "NOT trash"
        }
      }

    const navPlaceTrue = {
        label: "Stedfestet",
        url: root + "/article/place:true",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "article",
            status: "NOT trash",
            q: "location.lat:*,location:lng:*"
        }
    }

    const navPlaceFalse = {
        label: "Ikke stedfestet",
        url: root + "/article/place:false",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "article",
            status: "NOT trash",
            q: "NOT location.lat:*"
        }
    }
    const navArticle = {
        label: "Artikler",
        icon: "view_stream",
        url: root + "/article",
        query: {
            collectionId: collectionId,
            models: "documents",
            documentType: "article",
            status: "NOT trash"
        },
        menu: [
            navPlaceTrue,
            navPlaceFalse
        ]
    }
    

    const navEkultur = getMenuEkultur(app)
    const navMedia = getMenuMedia(app)
    const navLatest = getMenuLatest(app)
    const navTrash = getMenuTrash(app)

    const navTemplates = {
        role: "group",
        menu: [
            navGroup
        ]
    }    

   return [
        navHome,
      navTemplates,
      navArticle,
      navEkultur,
      navMedia,
      navLatest,
      navTrash
    ]

}