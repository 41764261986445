import React from 'react';

class ButtonIcon extends React.Component {

    render() {

        const { icon } = this.props;
        
        if (!icon) {
            return false;
        }
        
        return (
			<i className="admin-button__icon  material-icons">{ icon }</i>
        )

    }

}

export default ButtonIcon;