import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAuthRoles, getAuthUsers, getUserById } from "../redux/functions/auth"
import { getAuthSites, getSiteById } from "../redux/functions/authSites"
import { getAppById } from "../redux/functions/authApps"

import { getUrlFromProps } from '@frontend-components/admin/lib/resources/functions';

import Finder from '../AppFinder';
import { FinderTabs, FinderSection } from '@frontend-components/admin';

import AuthSectionApps from "./AuthSectionApps"
import AuthSectionSites from "./AuthSectionSites"
import AuthSectionUsers from "./AuthSectionUsers"
import AuthSectionUsersAdd from "./AuthSectionUsersAdd"



import AuthModuleUser from "./AuthModuleUser"
import AuthModuleSite from "./AuthModuleSite"
import AuthModuleApp from "./AuthModuleApp"

class AuthSite extends Component {

    static defaultProps = {
        urlPattern: "/admin/auth/site/:siteId"
    }

    componentDidMount() {
        this.props.getAuthRoles()
        this.props.getAuthUsers()
        this.props.getAuthSites()
        this.getAuth()
    }
    
    componentDidUpdate = (prevProps) => {

        if (prevProps.match.params.userId !== this.props.match.params.userId) {
            this.getAuth()
        }

        if (prevProps.match.params.siteId !== this.props.match.params.siteId) {
            this.getAuth()
        }

        if (prevProps.match.params.appId !== this.props.match.params.appId) {
            this.getAuth()
        }

    }
    
    getAuth = () => {
        const { userId, siteId, appId } = this.props.match.params
        
        if (userId) {
            this.props.getUserById(userId)
        }

        if (siteId) {
            this.props.getSiteById(siteId)
        }

        if (appId) {
            this.props.getAppById(appId)
        }
        
    }
    
    getAppUsers = (currentSite, currentApp) => {
        const { authUsers, authUsersById, urlPattern } = currentApp
        
        if (!authUsers) {
            return []
        }

        return authUsers.map(user => {
            return {
                ...user,
//                ...authUsersById[userId],
                urlPattern: urlPattern + "/user/:userId",
                moduleSize: "small" 
            }
        })

        
    }
    
    getUserApps = (currentSite, currentUser) => {
        const { siteId, userId } = this.props.match.params;
        const { authApps } = currentSite;
        
        if (!authApps) {
            return []
        }
        
        const { usersById } = this.props;
        
        currentUser = {
            ...currentUser,
            ...usersById[userId]
        }
        
        const { urlPattern } = currentUser;
        
        const userApps = authApps.map(app => {
            const { collectionId } = app;
            
            const roleId = currentUser.authAppsById && currentUser.authAppsById[collectionId] && currentUser.authAppsById[collectionId].roleId || undefined

            return {
                ...app,
                roleId: roleId,
                userId: userId,
                urlPattern: urlPattern + "/app/:appId",
                moduleSize: "small" 
            }
            
        })
        
        return userApps
        
    }

    getUserCollections = (currentSite, currentUser) => {
        const { siteId, userId } = this.props.match.params;
        const { authCollections } = currentSite;

        if (!authCollections) {
            return []
        }

        const { usersById } = this.props;
        
        currentUser = {
            ...currentUser,
            ...usersById[userId]
        }

        const { urlPattern } = currentUser;
        
        let userCollections = []

        authCollections.map(collection => {
            const { collectionId, collectionType, status } = collection;
            const roleId = currentUser.authAppsById && currentUser.authAppsById[collectionId] && currentUser.authAppsById[collectionId].roleId || undefined

            if (status !== "publish") {
                return
            }

            collection = {
                ...collection,
                siteId: siteId,
                roleId: roleId,
                urlPattern: urlPattern + "/app/:appId",
                moduleSize: "small" 
            }
            
            userCollections.push(collection)
            
        })
        
        return userCollections
        
    }
    
    getSiteApps = (currentSite) => {
        const { authApps, urlPattern } = currentSite;
        
        return authApps.map(app => {
            return {
                ...app,
                urlPattern: urlPattern + "/app/:appId",
                moduleSize: "small" 
            }
        })
        
    }

    getSiteCollections = (currentSite) => {
        const { authCollections, urlPattern } = currentSite;

        return authCollections.map(collection => {
            return {
                ...collection,
                urlPattern: urlPattern + "/app/:collectionId",
                moduleSize: "small" 
            }
        })
        
    }
    
    getSiteUsers = (currentSite) => {
        const { authUsers, urlPattern } = currentSite
        
        if (!authUsers) {
            return []
        }

        return authUsers.map(user => {
            return {
                ...user,
                urlPattern: urlPattern + "/user/:userId",
                moduleSize: "small" 
            }
        })
    
    }
    
    getCurrentSite = () => {
        const { sitesById, urlPattern } = this.props;
        const { siteId } = this.props.match.params;
        
        let currentSite;
        
        if (siteId && sitesById[siteId]) {
            currentSite = { 
                ...sitesById[siteId],
                urlPattern: urlPattern
            }

        }
        
        return currentSite;
    }
    
    getCurrentUser = (currentSite) => {
        const { urlPattern, authUsersById } = currentSite;
        const { userId } = this.props.match.params;
        
        let currentUser;
        
        if (userId && authUsersById[userId]) {

            currentUser = {
                ...authUsersById[userId],
                urlPattern: urlPattern + "/user/:userId"
            }

            
        }
        
        return currentUser;
        
    }
    
    getCurrentApp = (currentSite) => {
        const { authAppsById, urlPattern } = currentSite;
        const { appId } = this.props.match.params;
        const { appsById } = this.props;
        
        const app = appId && authAppsById && authAppsById[appId];
        
        if (!app) {
            return false
        }
        
        return { 
            ...appsById[appId],
            ...app,
            urlPattern: urlPattern + "/app/:appId",
            moduleSize: "small"
        }

        
    }

    render() {
        const currentSite = this.getCurrentSite()
        
        if (!currentSite) {
            return false
        }
        
        const currentApp = this.getCurrentApp(currentSite)
        const currentUser = this.getCurrentUser(currentSite)

        // site + app

        if (currentSite && currentApp) {
            const appUsers = this.getAppUsers(currentSite, currentApp);
            
            return (
                <Finder {...this.props}>
                    <AuthSectionSites sites={[currentSite]} />
                    <AuthSectionApps apps={[currentApp]} title="Current app" />
                    <AuthSectionUsers users={appUsers} title="Authorize users for app" />
                    <AuthSectionUsersAdd {...this.props} users={appUsers} appId={currentApp.appId} />
                </Finder>
            )
            
        }
        
        // site + user
        
        if (currentSite && currentUser) {
            const userApps = this.getUserApps(currentSite, currentUser);
            const userCollections = this.getUserCollections(currentSite, currentUser);

            const { urlPattern } = currentUser;
            const { tabId } = this.props.match.params;
            
            const appTab = {
                id: "user",
                url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern})
            }
    
            const collectionTab = {
                id: "collection",
                url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern + "/collection"})
            }


            return (
                <Finder {...this.props}>
                    <AuthSectionSites sites={[currentSite]} />
                    <AuthSectionUsers users={[currentUser]} title="Authorize apps for user" />
                    <FinderTabs tabId={tabId}>
                        <FinderSection {...appTab} title={userApps.length + " apps"}>
                            <AuthSectionApps apps={userApps} title="" />
                        </FinderSection>
                        <FinderSection {...collectionTab} title={userCollections.length + " collections"}>
                            <AuthSectionApps apps={userCollections} title="" />
                        </FinderSection>
                    </FinderTabs>
                </Finder>
            )

        }
        
        // site

        const siteApps = this.getSiteApps(currentSite)
        const siteCollections = this.getSiteCollections(currentSite)
        const siteUsers = this.getSiteUsers(currentSite)

        // tabs

        const { urlPattern } = currentSite;
        const { tabId } = this.props.match.params;
        
        const siteTab = {
            id: "site",
            url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern})
        }

        const userTab = {
            id: "user",
            url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern + "/user"})
        }

        const collectionTab = {
            id: "collection",
            url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern + "/collection"})
        }

        return (
            <Finder {...this.props}>
                <AuthSectionSites sites={[currentSite]} />
                <FinderTabs tabId={tabId}>
                    <FinderSection {...siteTab} title={siteApps.length + " apps"}>
                        <AuthSectionApps apps={siteApps} title="" />
                    </FinderSection>
                    <FinderSection {...collectionTab} title={siteCollections.length + " collections"}>
                        <AuthSectionApps apps={siteCollections} title="" />
                    </FinderSection>
                    <FinderSection {...userTab} title={siteUsers.length + " users"}>
                        <AuthSectionUsers users={siteUsers} title="" />
                        <AuthSectionUsersAdd {...this.props} skipResults={siteUsers} />
                    </FinderSection>
                </FinderTabs>
            </Finder>
        )

    }

}

function mapStateToProps(state) {
	return {
        parents: state.app.parents,
        sites: state.auth.sites,
        users: state.auth.users,
    	appsById: state.auth.appsById,
    	usersById: state.auth.usersById,
    	sitesById: state.auth.sitesById
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getAuthRoles, getAuthSites, getSiteById, 
      getAppById, 
      getAuthUsers, getUserById
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthSite);
