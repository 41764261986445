import layout from "./documentsLayout"
import query from "./searchQuery"

export default {
    "properties": {
        "type": {
            "enum": ["search"]
        },
        "icon": {
            "type": "string",
        },
        "query": query,
        "layout": layout
    }
}