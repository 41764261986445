import { getAppIcon } from '@frontend-components/admin/es/resources/icons';

const icon = getAppIcon({fill: "blue", text: "Ki", type: "filled"})

export default {
    sortOrder: 1.3,
    appType: "site/product",
    collectionType: "kiosk",
    name: "kiosk",
    icon: icon,
    title: "Kiosk"
};