import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Schema } from "../"

import "./InboxEditor.scss"

import InboxEditorCompose from './InboxEditorCompose';
import InboxEditorParent from './InboxEditorParent';
import InboxEditorArray from './InboxEditorArray';
import InboxEditorChild from './InboxEditorChild';

const layouts = {
    "inbox-compose": InboxEditorCompose,
    "inbox-parent": InboxEditorParent,
    "inbox-array": InboxEditorArray,
    "inbox-child": InboxEditorChild
}

class InboxEditor extends Component {

    static propTypes = {
    }
    
    static defaultProps = {
        status: "",
        user: {},
        formData: {
            messages: []
        },
        schema: {
            type: "object",
            properties: {
                uniqueId: {
                    type: "string",
                    readonly: true
                },
                appName: {
                    type: "string"
                },
                appColor: {
                    type: "string"
                },
                ownerName: {
                    type: "string"
                },
                inboxType: {
                    type: "string",
                    enum: [
                        "message",
                        "comments",
                        "issue"
                    ],
                    default: "message"
                },
                attachments: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            uniqueId: {
                                type: "string"
                            },
                            url: {
                                type: "string"
                            },
                            title: {
                                type: "string"
                            },
                            description: {
                                type: "string"
                            },
                            imageUrl: {
                                type: "string"
                            }
                        }
                    }
                },
                messages: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            status: {
                                type: "string",
                            },
                            parentId: {
                                type: "string",
                                readonly: true
                            },
                            uniqueId: {
                                type: "string",
                                readonly: true
                            },
                            createdAt: {
                                type: "string",
                            },
                            updatedAt: {
                                type: "string",
                            },
                            author: {
                                type: "object",
                                properties: {
                                    name: {
                                        type: "string"
                                    },
                                    email: {
                                        type: "string"
                                    }
                                }
                            },
                            from: {
                                type: "string",
                            },
                            to: {
                                type: "string",
                            },
                            subject: {
                                type: "string"
                            },
                            message: {
                                type: "string"
                            }
                        }
                    }
                }
            }
        },
        uiSchema: {
            "ui:layout": "inbox-parent",
            "ui:fieldset": [
                "messages",
            ],
            "messages": {
                "ui:title": undefined,
                "ui:layout": "inbox-array",
                "ui:options": {
                    "orderable": false
                },
                "ui:buttons": [
                    {
                        "label": "Reply",
                        "item": {
                            "status": "reply"
                        }
                    }
                ],
                "items": {
                    "ui:layout": "inbox-child",
                    "ui:options": {
                        "collapsible": true,
                        "editable": true
                    },
                    "ui:fieldset": [
                        "message",
                        "footer"
                    ],
                    "message": {
                        "ui:widget": "textarea",
                        "ui:label": false
                    },
                    "ui:title": {
                        "select": "message"
                    }
                }
            }
        },
        uiSchemaCompose: {
            "ui:layout": "inbox-parent",
            "uniqueId": {
                "ui:hidden": true
            },
            "messages": {
                "ui:title": undefined,
                "ui:layout": "inbox-array",
                "ui:options": {
                    "orderable": false
                },
                "ui:buttons": [
                    {
                        "label": "Reply",
                        "item": {
                            "status": "reply"
                        }
                    }
                ],
                "items": {
                    "ui:layout": "inbox-compose",
                    "ui:options": {
                        "collapsible": true,
                        "editable": true
                    },
                    "ui:fieldset": [
                        "from",
                        "to",
                        "subject",
                        "message",
                        "footer"
                    ],
                    "message": {
                        "ui:widget": "textarea",
                    },
                    "ui:title": {
                        "select": "message"
                    }
                }
            }
        }
    }
    
    state = {
        preview: {},
        formData: {}
    }
    
    componentDidMount() {
        this.getFormData()
        this.getFormContext()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.formData !== this.props.formData) {
            this.getFormData()
            this.getFormContext()
        }
        
        
    }
    
    getFormData = () => {
        const { user, status, formData } = this.props;
        
        if (status === "new") {
            
            const from = user.name + " <" + user.email + ">";
            
            this.setState({
                formData: {
                    ...formData,
                    messages: [
                        {
                            from: from,
                            author: user
                        }
                    ]
                }
            })
            
            return;
            
        }
        
        this.setState({
            formData: formData
        })
        
    }

    getFormContext = () => {
        const { user, formData } = this.props;
        
        const messages = formData.messages || [];
        
        const author = {
            id: user.id || undefined,
            uniqueId: user.uniqueId || undefined,
            name: user.name || undefined,
            imageUrl: user.imageUrl || undefined
        }
        
        const formContext = {
            author: user,
            messagesCount: messages.length,
            onSelect: this.onSelect,
            onDiscardDraft: this.onDiscardDraft,
            onEditMessage: this.onEditMessage,
            onSaveMessage: this.onSaveMessage,
            onDeleteMessage: this.onDeleteMessage,
            onSaveReply: this.onSaveReply,
            onDiscardReply: this.onDiscardReply
        }
        
        this.setState({
            formContext: formContext
        })
        
    }

    onSelect = (props) => {
        if (this.props.onSelect) {
            this.props.onSelect(props)
        }
    }
    
    onChange = (props) => {
        console.log('InboxEditor:onChange', props)

        const { formData } = props;

        const messages = formData.messages || [];
        
        this.setState({
            formData: formData,
            formContext: {
                ...this.state.formContext,
                messagesCount: messages.length
            }
        })

        if (this.props.onChange) {
            this.props.onChange(this.state)
        }
        
    }
    
    onDiscardDraft = (props) => {
        console.log('InboxEditor:onDiscardDraft', props)
        
        if (this.props.onDiscardDraft) {
            this.props.onDiscardDraft(props)
        }
        
    }

    onEditMessage = (props) => {
        console.log('InboxEditor:onEditMessage', props)

        if (this.props.onEditMessage) {
            this.props.onEditMessage(props)
        }

    }

    onSaveMessage = (props) => {
        console.log('InboxEditor:onSaveMessage', props)
        
        if (this.props.onSaveMessage) {
            this.props.onSaveMessage(props)
        }

    }

    onDeleteMessage = (props) => {
        console.log('InboxEditor:onDeleteMessage', props)

        if (this.props.onDeleteMessage) {
            this.props.onDeleteMessage(props)
        }

    }

    onSaveReply = (props) => {
        console.log('InboxEditor:onSaveReply', props)
        
        if (this.props.onSaveReply) {
            this.props.onSaveReply(props)
        }

    }

    onDiscardReply = (props) => {
        console.log('InboxEditor:onDiscardReply', props)
        
        if (this.props.onDiscardReply) {
            this.props.onDiscardReply(props)
        }

    }
    
    render() {
        const { status, schema, uiSchema, uiSchemaCompose, user } = this.props;
        const { formData, formContext } = this.state;
        
        if (status === "new") {

            return (
                <Schema schema={schema} uiSchema={uiSchemaCompose} formData={formData} formContext={formContext} layouts={layouts} onChange={this.onChange} onSubmit={this.onSubmit} />
            )

        }

        return (
            <Schema schema={schema} uiSchema={uiSchema} formData={formData} formContext={formContext} layouts={layouts} onChange={this.onChange} onSubmit={this.onSubmit} />
        )
        
    }

}

export default InboxEditor;