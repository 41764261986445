import React, { useEffect, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSiteCollectionApp } from '../redux/functions/siteCollectionApp';
import { getApp } from './app';

import KioskRoutes from "./KioskRoutes"
import app from "../redux/apps/appKiosk"

const KioskApp = ({site, collection, ...props}) => {
    const siteId = site && site.id
    const collectionId = collection && collection.id
    const collectionType = collection && collection.collectionType

    useEffect(() => {
        props.getSiteCollectionApp({site, app})
    }, [siteId])

    useEffect(() => {
        collectionType === app.collectionType && props.getApp({site, collection, ...app})
    }, [collectionId, collectionType])

    return (
        <KioskRoutes {...props} />
    )

}

function mapStateToProps(state) {
	return {
    	site: state.site,
        collection: state.collection,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({  
    getSiteCollectionApp, getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KioskApp);

