import Color from 'color';
import { getRandomColor } from "./getRandomColor"

export function getChartColors(props) {
    const { colors, theme, data } = props;
        
    if (colors && colors.length) {
        return colors
    }
    
    if (theme && theme.primaryColor) {
        return getThemeColors(props)
    }
    
    return getRandomColors(props)
    
}

export function getRandomColors(props) {
    const { data } = props;

    const steps = data && data.length || 5;
    
    let colors = [];
    
    for (let i = 0; i < steps; i++) {
        const color = getRandomColor()
        colors.push(color)
    }
    
    return colors
    
}

export function getThemeColors(props) {
    const { theme, data } = props;
        
    let { primaryColor, lightColor, darkColor } = theme;
    
    if (!primaryColor) {
        return undefined
    }
    
    if (!lightColor) {
        lightColor = Color(primaryColor).lighten(0.25)
    }

    if (!darkColor) {
        darkColor = Color(primaryColor).darken(0.25)
    }
    
    const c1 = Color(darkColor)
    const c2 = Color(lightColor)

    const steps = data && data.length || 5;

    let colors = []

    for (let i = 0; i < steps; i++) {
        const mix = i/steps
        
        const color = c1.mix(c2, mix)
        colors.push(color)
    }
    
    return colors
    
}
