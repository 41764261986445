import React from "react";
import PropTypes from "prop-types";
import { rangeSpec } from "../Schema/utils";
import SliderField from "./SliderField"

const RangeWidget = (props) => {
    const { schema, options } = props;

    const label = options.title || props.title || props.label || "slider"

    return (
        <SliderField label={label} type="range" {...props} {...rangeSpec(schema)} />
    )
}

if (process.env.NODE_ENV !== "production") {
  RangeWidget.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
}

export default RangeWidget;
