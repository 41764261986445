import React, { useEffect, useState } from 'react';
import { LayoutDialog, WidgetSearch, getUiOptions } from '@frontend-components/admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SidebarSearch from "./SidebarSearch"

const Sidebar = ({app, dialog, ...props}) => {
    const { expanded, button, schema, uiSchema, onClose } = dialog;

    if (!expanded) {
        return false    
    }

    let itemSchema;

    if (schema && schema.type === "array") {
        itemSchema = schema.items
    } else if (schema) {
        itemSchema = schema
    }

    const uiOptions = uiSchema && getUiOptions(uiSchema)
    const uiSidebar = uiOptions && uiOptions.sidebar

    const handleSearch = (q, reset) => {
        let url = props.location.pathname;

        if (q) {
            url = url + "?q=" + q.replace(' ', '+')
        }

        if (q && !app.search.expanded) {
            props.appExpand('search')
        }
        
        if (props.history) {
            props.history.replace(url)
        }

        if (reset) {
            props.appCollapse('search')
        }

    }
    
    const getReferenceQuery = (query) => {
        const documentSchema = itemSchema && itemSchema.properties && itemSchema.properties.reference
        const documentType = documentSchema && documentSchema.properties.documentType
    
        query.documentType = "*"
    
        if (documentType && documentType.enum) {
            query.documentType = documentType.enum
        }
    
        if (documentType && documentType.default) {
            query.documentType = documentType.default
        }

        return {
            ...query,
            models: "documents"
        } 
        
    }

    const getMediaQuery = (query) => {
        const mediaSchema = itemSchema && itemSchema.properties && itemSchema.properties.media
        const mediaType = mediaSchema && mediaSchema.properties.mediaType
    
        query.mediaType = "*"

        if (mediaType && mediaType.enum) {
            query.mediaType = mediaType.enum
        }
    
        if (mediaType && mediaType.default) {
            query.mediaType = mediaType.default
        }

        return {
            ...query,
            models: "media"
        }
        
    }

    const getSidebar = () => {

        if (button && button.query) {
            return button
        }

        if (uiSidebar && uiSidebar.query) {
            return {
                search: uiSidebar && uiSidebar.search,
                query: {
                    ...uiSidebar.query,
                    siteId: app.siteId,
                    collectionId: app.collectionId
                }
            }
        }

        if (itemSchema.properties && itemSchema.properties.referenceId) {
            return {
                search: {
                    placeholder: "Finn referanse"
                },
                query: getReferenceQuery({
                    siteId: app.siteId,
                    collectionId: app.collectionId
                })
            }
        }

        if (itemSchema.properties && itemSchema.properties.mediaId) {
            return {
                template: "media",
                search: {
                    placeholder: "Finn media"
                },
                query: getMediaQuery({
                    siteId: app.siteId,
                    collectionId: app.collectionId
                }),
                layout: "gallery"
            }
        }

        let query = {} 

        return {
            query: query
        }

    }

    const sidebar = getSidebar()

    return (
        <SidebarSearch {...sidebar} {...props} />
    )

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

const mapStateToProps = (state) => {
	return {
        app: state.app,
        collection: state.collection,
        dialog: state.dialog,
	};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Sidebar);