import uiPreview from "./uiPreview"

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "ui:fieldset": [
        "settings",
        "content"
    ],
    "content": {
        "ui:field": "pageContent",
        "ui:fieldset": [
            "profile",
        ],
        "profile": {
            "ui:layout": "section",
            "ui:icon": "person",
            "ui:title": "Din profil",
            "ui:fieldset": [
                "avatar",
                "details",
            ],
            "ui:grid": true,
            "ui:spacing": 2,
            "avatar": {
                "ui:gridCols": 3,
                "ui:format": "1:1",
                "ui:title" : "Profilbilde",
            },
            "details": {
                "ui:spacing": 2,
                "ui:gridCols": 9,
                "ui:fieldset": [
                    "name",
                    "email",
                    "defaultLocale"
                ],
                "name": {
                    "ui:title" : "Navn",
                },
                "email": {
                    "ui:title": "E-postadresse",
                    "ui:widget": "email"
                },
                "defaultLocale": {
                    "ui:title": "Språk i admin"
                }
            },
        },
        "settings": {
            "ui:layout": "section",
            "ui:icon": "settings",
            "ui:title": "Innstillinger",
            "ui:fieldset": [
                "defaultLocale"
            ],
            "defaultLocale": {
                "ui:title": "Språk i admin"
            }
        }
    }
}