export const getWidgetGrid = (size) => {

    if (!size) {
      return {}
    }

    const sizes = {
        "xs": 2,
        "s": 4,
        "m": 8,
        "l": 12,
        "xl": 16
    }
    
    const grid = size.split(":")
    
    let cols = grid[0]
    let rows = grid[1]
    
    cols = sizes[cols] || parseInt(cols);
    rows = sizes[rows] || parseInt(rows);
    
    return {
        cols: cols,
        rows: rows
    }
    
}

export default getWidgetGrid