import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutButtons from "../Layout/LayoutButtons"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        borderTop: "1px solid",
        borderColor: theme.palette.divider
    }
}));

const ModuleFooter = ({className, buttons = [], buttonGroupProps, buttonProps, children}) => {
    const classes = useStyles();

    if (!buttons.length && !children) {
        return false
    }

    if (children) {
        return (
            <footer className={className || classes.root}>
                { children }
            </footer>
        )
    }

    return (
        <footer className={className || classes.root}>
            { buttons && <LayoutButtons buttons={buttons} buttonGroupProps={buttonGroupProps} buttonProps={buttonProps} /> }
            { children }
        </footer>
    )

}

export default ModuleFooter;