import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { editorChange } from '../../redux/functions/editor';
import { getMediaPreview } from "../../redux/functions/media"
import { ModuleTitle, ModuleLabel, ModuleDescription } from "@frontend-components/admin"
import { UploadDropzone, VideoPreview, AudioPreview, ImagePreview, PreviewIframe } from "../../components"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const PdfPreview = ({className, mediaUrl}) => {
    const embedUrl = mediaUrl + "?mediaType=application/pdf"

    return (
        <div className={className}>
            {embedUrl}
        </div>
    )

}

const SketchfabPreview = ({className, sourceId}) => {
    const embedUrl = "https://sketchfab.com/models/" + sourceId + "/embed"

    return (
        <div className={className}>
            <PreviewIframe url={embedUrl} />
        </div>
    )
}

const YouTubePreview = ({className, sourceId}) => {
    const embedUrl = "https://www.youtube.com/embed/" + sourceId // content.playerUrl 

    return (
        <div className={className}>
            <PreviewIframe url={embedUrl} />
        </div>
    )

}

const VimeoPreview = ({className, sourceId}) => {
    const embedUrl = "https://player.vimeo.com/video/" + sourceId

    return (
        <div className={className}>
            <PreviewIframe url={embedUrl} />
        </div>
    )
}


const templates = {
    "image": ImagePreview,
    "video": VideoPreview,
    "video/vimeo": VimeoPreview,
    "video/youtube": YouTubePreview,
    "audio": AudioPreview,
    "application/pdf": PdfPreview,
    "application/sketchfab": SketchfabPreview
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    media: {
        flexBasis: "50%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        margin: theme.spacing(1)
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    title: {
        fontSize: "24px"
    },
    upload: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(1)
    },
    metadata: {
        width: "100%",

        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",

        "& b": {
            fontWeight: "bold"
        },

        "& > p": {
            padding: theme.spacing(1,0)
        },       

        "& > * + *": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider
        }

    }

}));

const UploadButton = ({formContext, mediaType, ...props}) => {

    const classes = useStyles()

    const accept = mediaType && mediaType + "/*"
    const onUploadReplace = formContext && formContext.onUploadReplace

    return (
        <UploadDropzone multiple={false} accept={accept} onUpload={onUploadReplace}>
            <Button className={classes.upload} variant="outlined">
                Upload { mediaType }
            </Button>
        </UploadDropzone>
    )
    
}

const PreviewMetadata = ({filename, mimeType, dimensions, aspectRatio, duration, size, ...props}) => {
    const classes = useStyles()

    return (
        <div className={classes.content}>
            <Typography className={classes.title}>{filename}</Typography>
            <ModuleLabel>{mimeType}</ModuleLabel>

            <div className={classes.metadata}>
                { mimeType && <p><b>Format:</b> {mimeType}</p>}
                { dimensions && <p><b>Dimensions:</b> {dimensions}</p>}
                { aspectRatio && <p><b>Format:</b> {aspectRatio}</p>}
                { duration && <p><b>Duration:</b> {duration}</p>}
                { size && <p><b>Size:</b> {size}</p>}
            </div>

            {/* <UploadButton {...props} /> */}

        </div>
    )

}

const PreviewMedia = ({schema, formData, formContext, ...props}) => {
    const { t, i18n } = useTranslation();
    const media = getMediaPreview({formData, formContext, t})

    // upload

    const classes = useStyles()

    const PreviewTemplate = media.mediaType && templates[media.mediaType] || media.mimeType && templates[media.mimeType]

    /*

    const handleMetadata = (metadata) => {

        const { videoWidth, videoHeight, duration } = metadata

        const mediaWidth = videoWidth || formData.mediaWidth 
        const mediaHeight = videoHeight || formData.mediaHeight

        props.editorChange({
            ...formData,
            mediaWidth: mediaWidth,
            mediaHeight: mediaHeight,
            content: {
                ...formData.content,
                videoHeight: videoHeight,
                videoWidth: videoWidth,
                duration: duration
            }
        })

        console.log('metadata', metadata)

    }

    */

    if (PreviewTemplate) {

        return (
            <article className={classes.root}>
                <PreviewTemplate {...media} className={classes.media} maxWidth="100%" minHeight={192} />
                <PreviewMetadata {...media} formContext={formContext} />
            </article>
        )
    
    }

    return (
        <article className={classes.root}>
            No preview for {media.mediaType}
        </article>
    )
    
}

const mapStateToProps = (state) => {
	return {
        editor: state.editor,
        schema: state.editor.schema,
        formData: state.editor.formData,
        formContext: state.editor.formContext,
    };
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        editorChange
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PreviewMedia);

