import LayoutModule from "./LayoutModule"

export default {
    "fieldtypes": {
    },
    "fields": {
    },
    "layouts": {
        "module": LayoutModule
    },
    "widgets": {
    }
}