import React from 'react';
import { connect } from 'react-redux';

import DocumentEditor from "../AppDocuments/DocumentEditor"
import registry from "./components/registry"

const BookingEditor = (props) => {
    const { app, editor } = props;

    return (
        <DocumentEditor {...props}
            formContext={{
                preview: undefined,
            }}
            registry={registry} />
    )
 
}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        editor: state.editor
	}
}  

export default connect(
    mapStateToProps,
)(BookingEditor);