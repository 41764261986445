import React, { Component } from 'react';
import { ChartArea } from '../'
import moment from 'moment';

import "./ChartAreaStacked.scss"

class ChartAreaStacked extends Component {
    
    static defaultProps = {
        areaOpacity: 1,
        enableSlices: "x",
        yScale: {
            type: "linear",
            stacked: true
        }
    }

    render() {

        return (
            <ChartArea {...this.props} />
        )
    
    }

}

export default ChartAreaStacked