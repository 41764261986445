import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

export function getLabelFromProps(props) {
    let { label } = props;
    
    if (!label) {
        return undefined;
    }
    
    // get url from urlPattern
    
    const labelParts = label.split(' ');
    
    label = labelParts.map((name) => {
        
        let value = name;
        
        if (name.startsWith(":")) {
            name = name.substr(1);
            value = props[name] || undefined;
        }
        
        return value;
        
    })

    return label.join(" ");

}