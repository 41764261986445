import React, { useState, useEffect } from 'react';
import NavMenu from "./NavMenu"

const NavMenuControlled = ({menu = [], menuByUrl = {}, currentUrl, onSelect, onToggle, ...props}) => {

    const [selectedByUrl, setSelectedByUrl] = useState({})

    const handleSelect = (item, event) => {
        const { url } = item;

        url && !currentUrl && setSelectedByUrl({
            [url]: {
                selected: true
            }
        })

        onSelect && onSelect(item, event)
    }

    const [expandedByUrl, setExpandedByUrl] = useState({})

    const handleToggle = (item, event) => {
        const { url, expanded } = item;

        url && setExpandedByUrl({
            ...expandedByUrl,
            [url]: {
                expanded: !expanded
            }
        })

        onToggle && onToggle(item, event)
    }

    return (
        <NavMenu 
            menu={menu} menuByUrl={menuByUrl}
            currentUrl={currentUrl}
            expandedByUrl={expandedByUrl} onToggle={handleToggle}
            selectedByUrl={selectedByUrl} onSelect={handleSelect} {...props} />
    )

}

export default NavMenuControlled;