import getInboxLabel from "./getInboxLabel"
import getInboxBody from "./getInboxBody"

export const getInboxMessage = ({message, createdAt, updatedAt, ...props}) => {
    const { label, labelColor } = getInboxLabel(props)
    const { text, markdown } = getInboxBody({message})

    const datetime = updatedAt || createdAt;

    return {
        ...props,
        datetime: datetime,
        message: text,
        markdown: markdown,
        label: label,
        labelColor: labelColor
    }    
}

export default getInboxMessage