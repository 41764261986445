import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

import ButtonDraggable from "../NavToolbar/ButtonDraggable"
import NavToolbar from "../NavToolbar/NavToolbar"

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
        "& .MuiInputBase-root": {
            fontFamily: "Akkurat, sans-serif",
            lineHeight: "1.25",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0"
        },
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
    },
    startAdornment: {
    },
    endAdornment: {
    },
    iconPrefix: {
        marginTop: theme.spacing(-1.5),
        marginRight: theme.spacing(.5)
    },
    textPrefix: {
        fontSize: "14px",
        color: theme.palette.text.secondary
    },
    buttonPrefix: {
        marginLeft: theme.spacing(-1.5),
        marginTop: theme.spacing(-1.5),
        marginRight: theme.spacing(-1)
    },
    iconPrefixLabel: {
        marginLeft: theme.spacing(4.5)
    }
}));

const InputBase = ({
    variant = "filled",
    icon = undefined,
    prefix = undefined,
    type = "text",
    id = undefined,
    label = "Label",
    value = undefined,
    placeholder,
    helperText,
    multiline = false,
    language = undefined,
    draggable,
    draggableHandle,
    toolbar,
    startAdornment,
    endAdornment,
    onChange,
    ...props
}) => {

    const classes = useStyles()

    let iconPrefix, textPrefix;

    if (draggable && draggableHandle) {
        iconPrefix = <ButtonDraggable className={classes.buttonPrefix} draggableHandle={draggableHandle} />
    } else if (icon) {
        iconPrefix = <Icon className={classes.iconPrefix}>{icon}</Icon>
    }

    if (prefix) {
        textPrefix = <b className={classes.textPrefix}>{prefix}</b>;
    }

    if (iconPrefix || textPrefix) {
        startAdornment = <InputAdornment position="start">{iconPrefix}{textPrefix}</InputAdornment>
    }

    if (toolbar) {
        endAdornment = <InputAdornment position="end"><NavToolbar toolbar={toolbar} /></InputAdornment>
    }

    const shrinkLabel = () => {
        return placeholder && true || value && true || false
    }

    return (
        <TextField
            className={classes.root}
            variant={variant}
            type={type}
            id={id}
            multiline={multiline}
            label={label}
            placeholder={placeholder}
            value={value}
            helperText={helperText}
            fullWidth={true}
            InputLabelProps={{
                className: iconPrefix && classes.iconPrefixLabel,
                shrink: shrinkLabel
            }}
            InputProps={{
                startAdornment: startAdornment,
                endAdornment: endAdornment
            }}
            inputProps={props}
            onChange={onChange}
        />
    )
    
}

export default InputBase;