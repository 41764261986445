import React, { useState } from 'react';
import DateTimeInputBase from "./DateTimeInputBase"

const DateTimeField = ({id, label, helperText, value, onChange}) => {

    return (
        <DateTimeInputBase id={id} label={label} helperText={helperText} value={value} onChange={onChange} />
   )

}

export default DateTimeField