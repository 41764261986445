let initialState = {
};

const search = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'REQUEST_SEARCH':
            return {
                ...state,
                [action.id]: {
                    query: [action.query]
                }
            }

        case 'RECEIVE_SEARCH':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    ...action.data
                }
            }

        default:
            return state

    }
       
}

export default search;