import React, { Component } from 'react';
import PropTypes from "prop-types";

import { Widget, WidgetHeader, WidgetBody, WidgetFooter, WidgetTitle, WidgetSpinner } from "../"
import { Chart, ChartTooltip, FormatMetric } from "../"

import { getGaDataByMetrics } from "../../resources/functions/"


import Color from 'color';
import _ from "lodash"
import moment from "moment"

import "./AnalyticsReportRegVsPub.scss"

class AnalyticsReportRegVsPub extends Component {

    static propTypes = { 
        title: PropTypes.string,
        listTitle: PropTypes.string,
        loadingTitle: PropTypes.string,
    }
    
    static defaultProps = {
        backgroundColor: "white",
        widgetType: "analytics-reg-vs-pub",
        widgetSize: "1:1",

        loadingTitle: "Primus vs. DigitaltMuseum",
        title: "Primus vs. DigitaltMuseum",
        listTitle: "Object types",
    }
    
    state = {
        style: {},
        colors: [
            "white",
            "rgba(255,255,255,0.25)"
        ],
        data: undefined,
        chartData: [
            {
                "id": "dm:published",
                "label": "Publisert",
                "value": 0
            },
            {
                "id": "dm:unpublished",
                "label": "Ikke publisert",
                "value": 1
            }
        ],
        loading: true
    }

    componentDidMount() {
        this.getReport()
        this.getColors()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.report !== this.props.report) {
            this.getReport()
        }

        if (prevProps.theme !== this.props.theme || prevProps.backgroundColor !== this.props.backgroundColor) {
            this.getColors()
        }
        
        if (prevState.data !== this.state.data) {
            this.getChartData()
            this.getListData()
        }
        
    }

    getColors = () => {
        let { backgroundColor, theme } = this.props;

        backgroundColor = Color(backgroundColor)
        
        let color;
        
        if (theme) {
            backgroundColor = "white"
            color = theme && theme.primaryColor || "black"
        } else if (backgroundColor.isDark()) {
            color = "white"
        } else {
            color = "black"
        }

        const style = {
            backgroundColor: backgroundColor,
            color: color
        }
        
        // colors

        let colors = []
        
        if (color === "white") {
            colors = [
                "white",
                "rgba(255,255,255,.25)",
            ]
        } else {
            colors = [
                color,
                "rgba(0,0,0,.125)"
            ]
        }
        
        this.setState({
            style: style,
            colors: colors
        })
        
        
    }
    
    getReport = () => {
        let { report } = this.props;
        
        // set state
        
        this.setState({
            loading: true,
        })
        
        if (!report) {
            return false
        }
        
        // load data

        const data = getGaDataByMetrics(report)
        
        if (!data) {
            return false
        }
        
        this.setState({
            loading: false,
            data: data
        })
        
    }

    getChartData = () => {
        const { data, colors } = this.state
        
        if (!data) {
            return false
        }
        
        const total = data["primus:total"] && data["primus:total"].total || 0
        const published = data["dm:published"] && data["dm:published"].total || 0
        const unpublished = data["dm:unpublished"] && data["dm:unpublished"].total || 0

        const chartData = [
            {
                "id": "dm:published",
                "label": "Publisert",
                "value": published,
            },
            {
                "id": "dm:unpublished",
                "label": "Ikke publisert",
                "value": unpublished,
            }
        ] 
        
        this.setState({
            summary: {
                total: total,
                published: published,
                unpublished: unpublished   
            },
            chartData: chartData
        })

    }
 
    getListData = () => {
        const { data } = this.state
        
        if (!data) {
            return false
        }
        
        
        const total = data["primus:total"] && data["primus:total"].data || []
        const published = data["dm:published"] && data["dm:published"].data || []
        const unpublished = data["dm:unpublished"] && data["dm:unpublished"].data || []

        let listData = []
        
        total.map((row, index) => {
            const { data, total } = row;
            const label = data[0]["ga:objectType"]
            
            const listItem = {
                "label": label,
                "total": total,
                "published": published[index].total,
                "unpublished": unpublished[index].total,
            }            
            
            if (total) {
                listData.push(listItem)
            }
            
            
        })
        

        this.setState({
            listData: listData
        })
        
    }
    
    renderTooltip = (props) => {
        
        const { data } = props;
        
        return (
            <ChartTooltip>
                <h2 className="admin-tooltip__title">Publisert vs. registrert</h2>
            
                <ul>
                    <li>
                        <div className="admin-tooltip__chip" style={{backgroundColor: "white"}}></div>
                        <b className="admin-tooltip__value">Publisert</b>
                        <i className="admin-tooltip__value"><FormatMetric type="INTEGER" value={1000} /></i>
                    </li>
                    <li>
                        <div className="admin-tooltip__chip" style={{backgroundColor: "rgba(255,255,255,0.25"}}></div>
                        <b className="admin-tooltip__value">Registert</b>
                        <i className="admin-tooltip__value"><FormatMetric type="INTEGER" value={50} /></i>
                    </li>
                </ul>
            </ChartTooltip>
        )
        
    }
   
    renderChart = () => {
        const { chartTitle } = this.props;
        const { chartData, colors } = this.state;
        
        return (
            <Chart type="pie" data={chartData} colors={colors} enableSlicesLabels={false} />
        )
        
    }
    
    renderListItem = (item, index) => {
        const { label, total, unpublished, published } = item;
        
        const value = published;
        const percentage = published / total * 100;
        
        const { style } = this.state;
        
        const pctStyle = {
            backgroundColor: style && style.color || "black",
            width: percentage + "%"
        }
        
        return (
            <li className="list-item" key={index}>
                <b className="list-item__label">{ item['label']}</b>
                <i className="sparkline">
                    <i className="sparkline__bar" style={pctStyle}></i>
                </i>
                <b className="list-item__value"><FormatMetric type="PERCENT" value={percentage} decimals="1" /></b>
            </li>
        )
        
    }
    
    renderDetails = () => {
        const { listTitle } = this.props;
        const { listData } = this.state;
        
        if (!listData) {
            return false
        }
        
        return (
            <section className="details">
                <ul className="list">
                    { listData.map(this.renderListItem) }
                </ul>
            </section>
        )
        
    }
    
    renderLegend = () => {
        const { summary, colors } = this.state;
        
        if (!summary) {
            return false
        }

        const { total, published, unpublished } = summary;
        
        return (
            <section className="admin-legend">
                <ul>
                    <li>
                        <div className="admin-legend__chip" style={{backgroundColor: colors[1]}}></div>
                        <b className="admin-legend__label">Primus</b>
                        <i className="admin-legend__value"><FormatMetric type="INTEGER" value={total} /></i>
                    </li>
                    <li>
                        <div className="admin-legend__chip" style={{backgroundColor: colors[0]}}></div>
                        <b className="admin-legend__label">DigitaltMuseum</b>
                        <i className="admin-legend__value"><FormatMetric type="INTEGER" value={published} /></i>
                    </li>
                </ul>
            </section>
        )
        
    }
    
    renderSummary = () => {
        const { title, loadingTitle } = this.props;
        const { summary, colors, style } = this.state;

        const loading = this.state.loading || this.props.loading;
        
        if (loading || !summary) {

            return (
                <section className="summary">
                    <h1 className="summary__title">{loadingTitle}</h1>
                    <WidgetSpinner style={style} />
                </section>
            )        
            
        }

        const { total, published, unpublished } = summary;

        const percentage = published / total * 100;

        return (
            <section className="summary">
                <h1 className="summary__title">{title}</h1>
                <h2 className="summary__value"><FormatMetric type="PERCENT" value={percentage} decimals="0"/></h2>
            </section>
        )        

    }
    
    render() {
        const { style } = this.state
        
        return (
            <Widget {...this.props} style={style}>
                <WidgetHeader>
                    { this.renderSummary() }
                    { this.renderChart() }
                    { this.renderLegend() }
                </WidgetHeader>
                <WidgetFooter>
                    { this.renderDetails() }
                </WidgetFooter>
            </Widget>
        )

    }

}

export default AnalyticsReportRegVsPub;