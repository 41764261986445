import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "./Preview.scss"

class Preview extends Component {

    static propTypes = {
        expanded: PropTypes.bool
    }

    static defaultProps = {
        expanded: true
    }

    getClassName = () => {

    }

    render() {
        const { expanded } = this.props;
        
        return (
            <div className="admin-preview" aria-expanded={expanded}>
                { this.props.children }
            </div>
        )
    }
}

export default Preview;