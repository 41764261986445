import audioCategory from './audioCategory';

export default {
  "type": "object",
  "properties": {
    "title": {
      "type": "string"
    },
    "headline": {
      "type": "string"
    },
    "description": {
      "type": "string"
    },
    "descriptionWriter": {
      "type": "string"
    },
    "keywords": {
      "type": "string"
    },
    "volume": {
      "type": "integer",
      "minimum": 0,
      "maximum": 100
    },
        
    "category": audioCategory,

    "filename": {
      "type": "string",
      "readonly": true
    },

    "album": {
      "type": "string"
    },
    "artist": {
      "type": "string"
    },
    "composer": {
      "type": "string"
    },
    "copywriter": {
      "type": "string"
    },
    "releaseYear": {
      "type": "string"
    },
    "recordCompany": {
      "type": "string"
    },
    "catalogNumber": {
      "type": "string"
    },

    "creditLine": {
      "type": "string"
    },
    "creator": {
      "type": "string"
    },
    "creatorJobtitle": {
      "type": "string"
    },
    "creatorContactInfo": {
      "type": "string"
    },
    "copyrightNotice": {
      "type": "string"
    },
    "copyrightOwner": {
      "type": "string"
    },
    "license": {
      "type": "string"
    },
    "instructions": {
      "type": "string"
    }

  }
}