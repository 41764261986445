import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Buttongroup,
    Button
} from '../';

import "./AuthOptions.scss"

class AuthOptions extends Component {
    
    static defaultProps = {
        options: undefined,
        value: undefined
    }
    
    onChange = (value) => {
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }
    
    renderOption = (option, index) => {
        const { label, value } = option;
        
        return (
            <Button {...option} type="auth-option" selected={this.props.value === value} onClick={() => this.onChange(value)} />
        )
        
    }
    
    render() {
        const { options } = this.props;
        
        if (!options) {
            return false
        }
        
        return (
            <Buttongroup type="auth-options">{ options.map(this.renderOption) }</Buttongroup>
        )
        
    }

}

export default AuthOptions;
