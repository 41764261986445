import { API } from '../settings';
import { requestReferences, receiveReferences } from '../actions/references';

// GET REFERENCES

export function getReferences(models, uniqueId) {

    const url = API + '/admin/api/references/' + models + '/' + uniqueId;
  
    return function (dispatch) {
        dispatch(requestReferences(uniqueId))
  
        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(receiveReferences(uniqueId, data)),
        )
    }
    
}