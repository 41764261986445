import React from "react";
import PropTypes from "prop-types";

import { asNumber, guessType } from "../Schema/utils";
import AutocompleteBase from "./AutocompleteBase"

const nums = new Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
function processValue(schema, value) {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === "") {
    return undefined;
  } else if (type === "array" && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === "boolean") {
    return value === "true";
  } else if (type === "number") {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every(x => guessType(x) === "number")) {
      return asNumber(value);
    } else if (schema.enum.every(x => guessType(x) === "boolean")) {
      return value === "true";
    }
  }

  return value;
}

function getValue(event, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter(o => o.selected)
      .map(o => o.value);
  } else {
    return event.target.value;
  }
}

function AutocompleteWidget(props) {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
  } = props;

    const { enumOptions, enumDisabled } = options;
    const emptyValue = multiple ? [] : "";

    let selectOptions = []

    enumOptions.map(({ value, label }, i) => {
        const disabled = enumDisabled && enumDisabled.indexOf(value) != -1;
        selectOptions.push({
            label: label,
            value: value,
            disabled: disabled
        })
    })

    const label = options.title || props.title || props.label
    const helperText = options.help || undefined
    const icon = options.icon
    const prefix = options.prefix

    const handleChange = (event, option) => {
        onChange(processValue(schema, option && option.value || ""));
    }

    return (
        <AutocompleteBase
            id={id}
            placeholder={placeholder}
            multiple={multiple}
            options={selectOptions}
            label={label}
            icon={icon}
            prefix={prefix}
            helperText={helperText}
            value={typeof value === "undefined" ? emptyValue : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={handleChange}>
        </AutocompleteBase> 
    )

}

AutocompleteWidget.defaultProps = {
    autofocus: false,
};

if (process.env.NODE_ENV !== "production") {
    AutocompleteWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        options: PropTypes.shape({
            enumOptions: PropTypes.array,
        }).isRequired,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        multiple: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    }
}

export default AutocompleteWidget;
