import React from 'react';

import EditorSection from "./EditorSection"
import EditorContent from "./EditorContent"
import EditorPreview from "./EditorPreview"
import EditorSidebar from "./EditorSidebar"

import PageMenu from "./PageMenu"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        zIndex: 1,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    editor: {
        backgroundColor: "inherit",
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        overflow: "hidden",
    },
    preview: {
        position: "absolute",
        backgroundColor: "#333",
        top: 0,
        right: 0,
        bottom: 0,
        left: "50%",
        overflow: "hidden"
    }
}));

const PageSettings = ({expanded = true, sidebar, menuWidth, menu = undefined, children}) => {
    const classes = useStyles();

    const renderSidebar = () => {
        const SidebarTemplate = sidebar && sidebar.template;

        return (
            <EditorSidebar expanded={sidebar && sidebar.id && true}>
                { SidebarTemplate && <SidebarTemplate {...sidebar} /> }
            </EditorSidebar>
        )
    }

    return (
        <EditorSection elevation={0} className={classes.root} aria-expanded={expanded}>
            { menu && <PageMenu menuWidth={menuWidth} menu={menu} /> }
            <EditorContent expanded={true}>
                {children}
            </EditorContent>
            { sidebar && renderSidebar() }
        </EditorSection>
    )

}

export default PageSettings;