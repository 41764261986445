import React, { Component, useMemo } from 'react';
import Axis from "./Axis"

class Axes extends Component {
    
    renderAxis = (position, index) => {
        const { xScale, yScale, width, height, top, right, bottom, left } = this.props;

        const axis = this.props[position]

        if (!axis) return null

        const isXAxis = position === 'top' || position === 'bottom'
        const ticksPosition = position === 'top' || position === 'left' ? 'before' : 'after'

        return (
            <Axis
                key={position}
                {...axis}
                axis={isXAxis ? 'x' : 'y'}
                position={position}
                x={position === 'right' ? width : 0}
                y={position === 'bottom' ? height : 0}
                scale={isXAxis ? xScale : yScale}
                length={isXAxis ? width : height}
                ticksPosition={ticksPosition}
            />
        )
        
    }
    
    render() {
        const positions = ['top', 'right', 'bottom', 'left']
        
        return (
            <g className="axes">
                { positions.map(this.renderAxis)}
            </g>
        )
        
    
    }

}

export default Axes