import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "& $root": {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
}));

const ActionMenuList = ({className, children}) => {
    const classes = useStyles()

    return (
        <ul className={className || classes.root}>
            {children}
        </ul>
    )

}

export default ActionMenuList