export const REQUEST_USER = 'REQUEST_USER'
export const RECEIVE_USER = 'RECEIVE_USER'

export const RECEIVE_USER_LOCALE = 'RECEIVE_USER_LOCALE'
export const RECEIVE_USER_FAVOURITES = 'RECEIVE_USER_FAVOURITES'

export const REQUEST_USER_ROLES = 'REQUEST_USER_ROLES'
export const RECEIVE_USER_ROLES = 'RECEIVE_USER_ROLES'

export const REQUEST_USER_COLLECTIONS = 'REQUEST_USER_COLLECTIONS'
export const RECEIVE_USER_COLLECTIONS = 'RECEIVE_USER_COLLECTIONS'

export const REQUEST_USER_SITES = 'REQUEST_USER_SITES'
export const RECEIVE_USER_SITES = 'RECEIVE_USER_SITES'

export const REQUEST_USER_APPS = 'REQUEST_USER_APPS'
export const RECEIVE_USER_APPS = 'RECEIVE_USER_APPS'

export const REQUEST_APPS_BY_SITE = 'REQUEST_APPS_BY_SITE'
export const RECEIVE_APPS_BY_SITE = 'RECEIVE_APPS_BY_SITE'


export function requestUser() {

  return {
    type: REQUEST_USER,
  }
  
}

export function receiveUser(data) {

  return {
    type: RECEIVE_USER,
    data: data,
  }
  
}

export function receiveUserLocale(locale) {
    return {
        type: RECEIVE_USER_LOCALE,
        locale: locale
    }
}

export function receiveUserFavourites(favourites) {
    return {
        type: RECEIVE_USER_FAVOURITES,
        favourites: favourites
    }
}
  
export function requestUserRoles() {

  return {
    type: REQUEST_USER_ROLES,
  }
  
}

export function receiveUserRoles(roles) {

  return {
    type: RECEIVE_USER_ROLES,
    roles: roles,
  }
  
}

export function requestUserSites() {

  return {
    type: REQUEST_USER_SITES,
  }
  
}

export function receiveUserSites(sites) {

  return {
    type: RECEIVE_USER_SITES,
    sites: sites,
  }
  
}

export function requestUserCollections() {

  return {
    type: REQUEST_USER_COLLECTIONS,
  }
  
}

export function receiveUserCollections(collections) {

  return {
    type: RECEIVE_USER_COLLECTIONS,
    collections: collections,
  }
  
}

export function requestUserApps() {

  return {
    type: REQUEST_USER_APPS,
  }
  
}

export function receiveUserApps(apps) {

  return {
    type: RECEIVE_USER_APPS,
    apps: apps,
  }
  
}

export function requestAppsBySite() {

  return {
    type: REQUEST_APPS_BY_SITE,
  }
  
}

export function receiveAppsBySite(appsBySite) {

  return {
    type: RECEIVE_APPS_BY_SITE,
    appsBySite: appsBySite,
  }
  
}

