import moment from 'moment';

export function getQueryString(queryString = window.location.search) {

  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
  
}

export function getQueryDate(value) {
  
  let dateFrom, dateTo;
  
  if (value === "today") {
    dateFrom = moment().startOf('day').format('YYYY-MM-DD')
    dateTo = moment(dateFrom).subtract(1, 'days').format('YYYY-MM-DD')
    value = "[" + dateFrom + " TO " + dateTo + "]"
  }

  if (value === "thisWeek") {
    dateFrom = moment().startOf('day').format('YYYY-MM-DD')
    dateTo = moment(dateFrom).subtract(7, 'days').format('YYYY-MM-DD')
    value = "[" + dateFrom + " TO " + dateTo + "]"
  }
  
  return value;
  
}

export function getQueryFilter(key, value) {
  
  let filter;
  
  if (key === "updatedAt") {
//    value = getQueryDate(value);
  }

  filter = key + '=' + value
  
  return filter;
  
}

export function getQueryParams(query) {

  let params = [];

  for (var key in query) {
    if (query.hasOwnProperty(key)) {
      let value = query[key];
      if (value !== "") {
//        let filter = key + '=' + query[key];
        let filter = getQueryFilter(key, value)
        params.push(filter);
      }
    }
  }
  
  return params.join('&');
  
}

export function getQueryFilters(query) {
  let { q } = query;
  
  if (!q) {
    return query;
  }

  let words = q.split(' ');
  
  query.q = '';
  
  q = [];
  
  words.map(word => { 
    
    let w = word.split(':');
    
    let key = w[0];
    let value = w[1];
    
    if (key && value) {
      query[key] = value + '*';
    } else {
      q.push(word)
    }
    
  });
  
  query.q = '*' + q.join(' ') + '*';
  
  return query;  
}