import { API } from '../settings';
import { requestAuthRoles, receiveAuthRoles } from '../actions/auth';
import { requestAuthSites, receiveAuthSites } from '../actions/auth';
import { requestAuthCollections, receiveAuthCollections } from '../actions/auth';
import { receiveCollectionById } from '../actions/collection';
import { receiveSiteById } from '../actions/site';
import { saveModel } from "./model"

export function getAuthRoles() {
    const url = API + '/admin/api/roles?fl=name,title,id,uniqueId';
  
    return function (dispatch) {
        dispatch(requestAuthRoles())

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => 
            dispatch(receiveAuthRoles(data)),
        )

    }

}

export function getAuthSites() {
    const url = API + '/admin/api/sites?fl=name,title,id,uniqueId';
  
    return function (dispatch) {
        dispatch(requestAuthSites())

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveAuthSites(data))
            data.models && data.models.map(model => dispatch(receiveSiteById(model)))
        })

    }

}

export function getAuthCollections() {
    const url = API + '/admin/api/collections?fl=name,title,id,uniqueId';
  
    return function (dispatch) {
        dispatch(requestAuthCollections())

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveAuthCollections(data))
            data.models && data.models.map(model => dispatch(receiveCollectionById(model)))
        })

    }

}

export function saveUserAuth(formData) {
    const { id, isSystemAdmin, authorizedSites, authorizedCollections } = formData;

    const authData = {
        "id": id,
        "isSystemAdmin": isSystemAdmin,
        "authorizedSites": authorizedSites,
        "authorizedCollections": authorizedCollections,
    }

    const payload = JSON.stringify(authData);

    console.log('AUTH DATA', payload)

    const url = API + '/admin/api/authorize/user';

    return function(dispatch) {
        fetch(url, {
            method: "POST",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json; charset=utf-8",
            },
            body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(auth =>
            dispatch(saveModel('users', formData))
        )        
    }
    
}