import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "baseline",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        borderTop: "1px solid",
        borderColor: theme.palette.divider,
        paddingTop: theme.spacing(1)
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        color: theme.palette.text.primary
    }
}));

const ResultsLoader = ({query = {}, models = [], count, rows, children}) => {
    const { t, i18n } = useTranslation('search');

    const classes = useStyles()

    let title;

    if (rows && !models.length) {
        title = t('No hits')
    } else if (!models.length) {
        title = t('Fetching data, please wait') + "..."
    } 

    if (title) {
        return (
            <Typography className={classes.title}>{title}</Typography>
        )
    }

    return children

}

export default ResultsLoader