//let API = "https://rock-hof.dimu.org";
let API = "https://kompass.dimu.org";
let LOCALHOST = false

try {
  const settings = require("../.settings.json");
  if (settings != undefined && settings.API !== undefined) {
    API = settings.API;
  }
} catch (ex) {
    console.log("No .settings.json to find, so using default settings!");
    LOCALHOST = true
}

console.log("Running Admin against API: " + API);
export {
    API,
    LOCALHOST
}