import {
    getMediaField, 
    getImageField,
    getImageCropdataField,
    getImageFocalpointField,
    getImageFiltersField,
    getVideoField,
    getAudioField,
    getAnnotateImageField
} from "./utils"

// fields

import MediaField from "./FieldMedia"

import ImageFiltersField from "./ImageFiltersField"
import ImageFocalpointField from "./ImageFocalpointField"
import ImageCropdataField from "./ImageCropdataField"
import AnnotateImageField from "./AnnotateImageField"
import AnnotateImage from "./AnnotateImage"

// layouts

import LayoutMedia from "./LayoutMedia"

import MediaPreview from "./MediaPreview"
import ImagePreview from "./ImagePreview"
import VideoPreview from "./VideoPreview"
import AudioPreview from "./AudioPreview"

import ImageFilters from "./ImageFilters"
import ImageCropper from "./ImageCropper"
import ImageFocalpoint from "./ImageFocalpoint"
import ImageAnnotations from "./ImageAnnotations"

// subtitles

import SubtitlesModule from "./SubtitlesModule"


export default {
    "fieldtypes": {
        "media": getMediaField,
        "image": getImageField,
        "imageCropdata": getImageCropdataField,
        "imageFocalpoint": getImageFocalpointField,
        "imageFilters": getImageFiltersField,
        "video": getVideoField,
        "audio": getAudioField,
        "annotateImage": getAnnotateImageField
    },
    "fields": {
        "media": MediaField,
        "imageFilters": ImageFiltersField,
        "imageFocalpoint": ImageFocalpointField,
        "imageCropdata": ImageCropdataField,
        "annotateImage": AnnotateImageField
    },
    "layouts": {
        "media": LayoutMedia,
        "mediaPreview": MediaPreview,
 
        "imagePreview": ImagePreview,
        "imageCropper": ImageCropper,
        "imageFocalpoint": ImageFocalpoint,
        "imageFilters": ImageFilters,
        "imageAnnotations": ImageAnnotations,
 
        "videoPreview": VideoPreview,
        "audioPreview": AudioPreview,
 
        "annotateImage": AnnotateImage,
        "subtitlesModule": SubtitlesModule
    }
}