import AltDateWidget from "./AltDateWidget";
import AltDateTimeWidget from "./AltDateTimeWidget";
import BaseInput from "./BaseInput";
import CheckboxWidget from "./CheckboxWidget";
import CheckboxesWidget from "./CheckboxesWidget";
import ColorWidget from "./ColorWidget";
import DateWidget from "./DateWidget";
import DateTimeWidget from "./DateTimeWidget";
import EmailWidget from "./EmailWidget";
import FileWidget from "./FileWidget";
import HiddenWidget from "./HiddenWidget";
import PasswordWidget from "./PasswordWidget";
import RadioWidget from "./RadioWidget";
import RangeWidget from "./RangeWidget";
import SelectWidget from "./SelectWidget";
import TextareaWidget from "./TextareaWidget";
import TextWidget from "./TextWidget";
import URLWidget from "./URLWidget";
import UpDownWidget from "./UpDownWidget";

import registry from "../../registry"

export default {
    BaseInput,
    PasswordWidget,
    RadioWidget,
    UpDownWidget,
    RangeWidget,
    SelectWidget,
    TextWidget,
    DateWidget,
    DateTimeWidget,
    AltDateWidget,
    AltDateTimeWidget,
    EmailWidget,
    URLWidget,
    TextareaWidget,
    HiddenWidget,
    ColorWidget,
    FileWidget,
    CheckboxWidget,
    CheckboxesWidget,
  ...registry.widgets
};
