import React, { Component } from 'react';
import "./WidgetIcon.scss"

class WidgetIcon extends Component {

    render() {
        const { imageUrl, icon } = this.props;
        
        if (icon) {
            return (
                <div className="admin-widget__icon  material-icons">
                    { icon }
                </div>
            )
            
        }
        
        if (imageUrl) {
            return (
                <div className="admin-widget__icon">
                    <img src={ imageUrl } />
                </div>
            )
        }
        
        return false
    }
}

export default WidgetIcon;