export default {
    type: "array",
    items: {
        type: "object",
        properties: {
            identifier: {
                type: "string"
            }
        }
    }
}