import query from "./queryUi";

export default {
    "ui:layout": "module",
    "ui:fieldset": [
        "documentTypes",
        "mediaTypes",
        "objectTypes",
        "query",
        "results",
        "children"
    ],
    "ui:preview": {
        "select": {
            "title": "title"
        },
        prepare({formData}) {
            const { title } = formData;
            return {
                title: title
            }
        }
    },
    "ui:options": {
        "editable": true,
    },
    "ui:nav": true,
    "ui:fieldset": [
        "menu",
        "query",
        "results"
    ],
    "menu": {
        "ui:title": "Seksjon",
        "ui:options": {
            "grid": true,
            "padding": 2,
            "spacing": 2
        },
        "ui:fieldset": [
            "type",
            "title",
            "icon",
            "pathname",
            "children",
        ],
        "type": {
            "ui:title": "Type"
        },
        "title": {
            "ui:title": "Tittel"
        },
        "icon": {
            "ui:title": "Ikon",
            "ui:widget": "icon"
        },
        "pathname": {
            "ui:title": "Pathname",
            "ui:help": "Start med / for full url"
        }
    },
    "results": {
        "ui:title": "Resultater",
        "ui:fieldset": [
            "layout",
        ],
        "ui:options": {
            "grid": true,
            "padding": 2,
            "spacing": 2
        },
        "layout": {
            "ui:title": "Velg layout"
        }
    },
    "documentTypes": {
        "ui:field": "documentType",
        "ui:widget": "checkboxes",
        "ui:title": "Velg dokumenttype",
    },
    "mediaTypes": {
        "ui:widget": "checkboxes",
        "ui:title": "Velg mediatype",
    },
    "objectTypes": {
        "ui:widget": "checkboxes",
        "ui:title": "Velg objecttype",
    },
    "query": query,
}