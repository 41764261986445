import React from 'react';
import { sortableElement } from 'react-sortable-hoc';

import {
    getDefaultFormState,
    retrieveSchema,
    toIdSchema,
} from "../utils";

import ArrayFieldItem from './ArrayFieldItem';

const ArrayFieldSortableItem = sortableElement((props) => {
    
    const { id, idPrefix, formData, schema, uiSchema, errorSchema, registry, itemIndex, value } = props;
    const { definitions } = registry;

    const itemSchema = retrieveSchema(schema.items, definitions, value);
    const itemErrorSchema = errorSchema ? errorSchema[itemIndex] : undefined;

    const itemId = id + "_" + itemIndex;
    const itemIdSchema = toIdSchema(
        itemSchema,
        itemId,
        definitions,
        value,
        idPrefix
    );
    
    const itemData = getDefaultFormState(itemSchema, value, definitions);

    const itemUiSchema = {
        ...uiSchema.items,
        "ui:draggable": true,
        "ui:draggableHandle": true
    }
    
    const canRemove = true;
    const canMoveUp = itemIndex > 0;
    const canMoveDown = itemIndex < formData.length - 1;
    
    return (
        <ArrayFieldItem 
            {...props}

            index={itemIndex} 
            
            itemId={itemId}
            itemIdPrefix={itemId}
            
            canRemove={canRemove}
            canMoveUp={canMoveUp}
            canMoveDown={canMoveDown}

            itemSchema={itemSchema}
            itemUiSchema={itemUiSchema}
            itemIdSchema={itemIdSchema}
            itemErrorSchema={itemErrorSchema}
            itemData={itemData}
            />
    )
    
    
});

export default ArrayFieldSortableItem;