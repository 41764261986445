import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const AuthorizedCollectionsField = ({authCollections, collectionsById, sitesById, ...props}) => {
    const {formData, registry, uiSchema, onChange} = props

    const handleAdd = (collection) => {
        const newFormData = [
            ...formData,
            {collection: collection}
        ]
        onChange && onChange(newFormData)
    }

    const authIds = formData.map(auth => {
        return auth.collection.id
    })

    const title = formData.length + " authorized collections"
    const collections = authCollections.filter(collection => !authIds.includes(collection.id))

    const results = collections.map(item => {

        const collectionId = item.id;
        const collection = collectionsById[collectionId] || item;
    
        const siteId = item && item.siteId;
    
        const site = siteId && sitesById[siteId]
        const siteName = site && site.name
        const siteTitle = site && site.title
        const description = siteTitle || collection && collection.description;

        return {
            ...item,
            siteName: siteName,
            description: description
        }
            
    })

    const search = {
        groupBy: "siteName",
        placeholder: "Filter collections",
    }

    const newUiSchema = {
        ...uiSchema,
        "ui:title": title,
        "ui:search": search,
        "ui:results": results,
        "ui:onAddAuth": handleAdd,
        "ui:layout": "authList",
        "items": {
            "ui:field": "authCollection",
        }
    }

    const { ArrayField } = registry.fields;

    return (
        <ArrayField {...props} uiSchema={newUiSchema} />
    )

}

// mapStateToProps

const mapStateToProps = (state) => {
	return {
        authCollections: state.authCollections.models,
        collectionsById: state.collectionsById,
        sitesById: state.sitesById,
	};
}  

// export default

export default connect(
    mapStateToProps,
)(AuthorizedCollectionsField);