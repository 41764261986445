import React from "react";
import { AnalyticsIcon as icon } from '@frontend-components/admin/es/resources/icons';

export default {
    uniqueId: "user/analytics",
    appType: "user/service",
    name: "analytics",
    icon: icon,
    title: "Statistikk",
    widgets: [
        "site/report"
    ]
};