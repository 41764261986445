import React from "react"
import { makeStyles } from '@material-ui/core/styles';

import UploadDropzone from "./UploadDropzone"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: theme.spacing(14),
        border: "1px solid",
        borderColor: theme.palette.divider
    }
}))

const ListUpload = ({children, onUpload, ...props}) => {

    const classes = useStyles()

    return (
        <UploadDropzone {...props} onUpload={onUpload}>
            <div className={classes.root}>
                {children}
            </div>
        </UploadDropzone>
    )

}

export default ListUpload