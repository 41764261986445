export default [
    {
        type: "documents/tree",
        title: "Museum24",
        icon: "home",
        pathname: "",
        query: {
            models: "documents",
            status: "NOT trash",
            documentType: "topic",
            q: "NOT parentId:*"
        },
    },
    {
        role: "group",
        title: "Artikler",
        icon: "view_quilt",
        pathname: "article",
        query: {
            models: "documents",
            documentType: "article",
            status: "NOT trash"
        }
    },
    {
        role: "group",
        type: "media",
        title: "Media",
        children: [
            {
                type: "media/image",
                title: "Images"
            },
            {
                type: "media/video",
                title: "Video"
            },
            {
                type: "media/audio",
                title: "Audio"
            },
            {
                type: "media/misc",
                title: "Misc"
            },
            {
                type: "media/application",
                title: "Apps"
            },
            {
                type: "media/upload",
                title: "Upload"
            }
        ]
    },
    {
        role: "group",
        hidden: "true",
        children: [
            {
                title: "Åpningstider",
                pathname: "openinghours"
            },
        ]
    },
    {
        role: "group",
        hidden: "true",
        children: [
            {
                title: "Arrangementer",
                pathname: "events"
            },
            {
                title: "Utstillinger",
                pathname: "exhibitions"
            }
        ]
    },
    {
        role: "group",
        type: "latest",
        title: "Latest",
        pathname: "latest"
    },
    {
        role: "group",
        type: "trash",
        title: "Trash",
        pathname: "trash"
    }

]