import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import CalendarTable from "./CalendarTable"
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {

    },
    table: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",

        "& thead": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },

        "& tbody": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },

        "& tr": {
            display: "flex",
            alignItems: "flex-start",
            width: "100%",

            "& > *": {
                width: theme.spacing(4),
            }

        },

    },
    weekday: {
        display: "flex",
        width: theme.spacing(4),
        height: theme.spacing(4),
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Akkurat, sans-serif",
        fontSize: '12px',
        lineHeight: 1,
    },
    date: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        margin: 0,
        fontFamily: "Akkurat, sans-serif",
        fontSize: '12px',
        color: theme.palette.text.disabled,
        lineHeight: 1,
        "&[data-month=true]": {
            color: theme.palette.text.primary
        },
        "&[data-today=true]": {
            border: "1px solid",
            borderColor: theme.palette.primary.main
        },
        "&[data-selected=true]": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
    }
}));


const NavCalendar = ({className, format = "YYYY-MM-DD", today, date, startDate, endDate, onSelect}) => {

    const classes = useStyles()

    const renderWeekday = ({dt}) => {
        const weekday = moment(dt).format('dd').substr(0, 1).toUpperCase()

        return (
            <div className={classes.weekday}>{weekday}</div>
        )
    }

    const renderDate = ({dt, dtMonth}) => {

        const selected = date === dt

        return (
            <IconButton className={classes.date} onClick={(e) => onSelect && onSelect({date:dt}, e)}
                data-selected={selected}
                data-month={dtMonth}>
                {moment(dt).format('D')}
            </IconButton>
        )
    }

    return (
        <nav className={className || classes.root}>
            <CalendarTable dt={date} className={classes.table} renderWeekday={renderWeekday} renderDate={renderDate} />
        </nav>
    )

}

export default NavCalendar;
