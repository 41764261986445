import uiPreview from "./uiPreview"

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "content": {
        "ui:field": "pageContent",
        "ui:fieldset": [
            "person",
            "places"
        ],
        "person": {
            "ui:layout": "section",
            "ui:title": "Person",
            "ui:options": {
                "grid": true,
                "spacing": 2
            },
            "ui:fieldset": [
                "name",
                "email",
                "phone",
                "languages"
            ],
            "name": {
                "ui:title": "Navn"
            },
            "email": {
                "ui:title": "E-post"
            },
            "phone": {
                "ui:title": "Telefon",
                "ui:gridCols": 6
            },
            "languages": {
                "ui:title": "Språk",
                "ui:widget": "checkboxes"
            }
        },
        "places": {
            "ui:layout": "section",
            "ui:title": "Tilknyttede steder",
            "ui:buttons": [
                {
                    "icon": "search",
                    "type": "sidebar",
                    "label": "Finn sted"
                },
                {
                    "label": "Legg til"
                }
            ]
        }
    }
}