export const REQUEST_AUTH_ROLES = 'REQUEST_AUTH_ROLES'
export const RECEIVE_AUTH_ROLES = 'RECEIVE_AUTH_ROLES'

export const REQUEST_AUTH_APPS = 'REQUEST_AUTH_APPS'
export const RECEIVE_AUTH_APPS = 'RECEIVE_AUTH_APPS'
export const RECEIVE_AUTH_APP_BY_NAME = 'RECEIVE_AUTH_APP_BY_NAME'
export const RECEIVE_AUTH_APP_BY_ID = 'RECEIVE_AUTH_APP_BY_ID'

export const REQUEST_AUTH_USERS = 'REQUEST_AUTH_USERS'
export const RECEIVE_AUTH_USERS = 'RECEIVE_AUTH_USERS'
export const RECEIVE_AUTH_USER_BY_ID = 'RECEIVE_AUTH_USER_BY_ID'

export const REQUEST_AUTH_SITES = 'REQUEST_AUTH_SITES'
export const RECEIVE_AUTH_SITES = 'RECEIVE_AUTH_SITES'
export const RECEIVE_AUTH_SITE_BY_ID = 'RECEIVE_AUTH_SITE_BY_ID'
export const RECEIVE_AUTH_SITE_BY_NAME = 'RECEIVE_AUTH_SITE_BY_NAME'

export const REQUEST_AUTH_SITE_APPS = 'REQUEST_AUTH_SITE_APPS'
export const RECEIVE_AUTH_SITE_APPS = 'RECEIVE_AUTH_SITE_APPS'

export const REQUEST_AUTH_SITE_COLLECTIONS = 'REQUEST_AUTH_SITE_COLLECTIONS'
export const RECEIVE_AUTH_SITE_COLLECTIONS = 'RECEIVE_AUTH_SITE_COLLECTIONS'

export const REQUEST_AUTH_COLLECTIONS = 'REQUEST_AUTH_COLLECTIONS'
export const RECEIVE_AUTH_COLLECTIONS = 'RECEIVE_AUTH_COLLECTIONS'

export function requestAuthApps() {

    return {
        type: REQUEST_AUTH_APPS,
    }
  
}

export function receiveAuthApps(data) {

    return {
        type: RECEIVE_AUTH_APPS,
        data: data
    }
  
}

export function receiveAuthAppByName(name, data) {

    return {
        type: RECEIVE_AUTH_APP_BY_NAME,
        name: name,
        data: data
    }
  
}

export function receiveAuthAppById(id, data) {

    return {
        type: RECEIVE_AUTH_APP_BY_ID,
        id: id,
        data: data
    }
  
}

export function requestAuthSites() {

    return {
        type: REQUEST_AUTH_SITES,
    }
  
}

export function receiveAuthSites(data) {

    return {
        type: RECEIVE_AUTH_SITES,
        data: data
    }
  
}

export function receiveAuthSiteById(id, data) {

    return {
        type: RECEIVE_AUTH_SITE_BY_ID,
        id: id,
        data: data
    }
  
}

export function receiveAuthSiteByName(name, data) {

    return {
        type: RECEIVE_AUTH_SITE_BY_NAME,
        name: name,
        data: data
    }
  
}

export function requestAuthSiteApps(id) {

    return {
        type: REQUEST_AUTH_SITE_APPS,
        id: id
    }
  
}

export function receiveAuthSiteApps(id, data) {

    return {
        type: RECEIVE_AUTH_SITE_APPS,
        id: id,
        data: data
    }
  
}

export function requestAuthSiteCollections(id) {

    return {
        type: REQUEST_AUTH_SITE_COLLECTIONS,
        id: id
    }
  
}

export function receiveAuthSiteCollections(id, data) {

    return {
        type: RECEIVE_AUTH_SITE_COLLECTIONS,
        id: id,
        data: data
    }
  
}

export function requestAuthUsers() {

    return {
        type: REQUEST_AUTH_USERS
    }
  
}

export function receiveAuthUsers(data) {

    return {
        type: RECEIVE_AUTH_USERS,
        data: data
    }
  
}

export function receiveAuthUserById(id, data) {

    return {
        type: RECEIVE_AUTH_USER_BY_ID,
        id: id,
        data: data
    }
  
}

export function requestAuthRoles() {

    return {
        type: REQUEST_AUTH_ROLES,
    }
  
}

export function receiveAuthRoles(data) {

    return {
        type: RECEIVE_AUTH_ROLES,
        data: data,
    }
  
}

export function requestAuthCollections() {

    return {
        type: REQUEST_AUTH_COLLECTIONS,
    }
  
}

export function receiveAuthCollections(data) {

    return {
        type: RECEIVE_AUTH_COLLECTIONS,
        data: data
    }
  
}