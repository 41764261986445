import layout from "./documentsLayout"
import documentsQuery from "./documentsQuery"

export default {
    "properties": {
        "type": {
            "enum": ["documents"]
        },
        "icon": {
            "type": "string",
        },
        "query": documentsQuery,
        "layout": layout
    }
}