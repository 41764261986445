import LayoutSection from "./LayoutSection"

export default {
    "fieldtypes": {
    },
    "fields": {
    },
    "layouts": {
        "section": LayoutSection
    },
    "widgets": {
    }
}