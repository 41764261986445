import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsWidget, FormatMetric } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

import { FormattedMessage } from "react-intl";
import messages from "./WidgetSessionsByOwner.messages";

class WidgetUserAnalytics extends Component {
    
    static defaultProps = {
        id: "/sessions/owner",
        loadingTitle: "Besøk etter eier",
        title: "{value} besøk sist uke",
        description: "Alle eiere du har tilgang til",
        tooltipLabel: ":ga:deviceCategory",
        widgetSize: "1:1",
        url: "/dm/analytics",
        scope: "7days",
        type: "line",
        stacked: true,
        query: {
            countryId: "no",
            offset: 0,
            owner: [1,2,3,4,5],
            reportType: 0,
            rows: 1000,
            startDate: "14daysAgo",
            endDate: "yesterday",
            metrics: "sessions",
            dimensions: "nthDay,dimension1",
            metrics: "sessions",
            orderBy: "nthDay",
            sort: "ascending",
            includeReports: "totals"
        }
    }
    
    state = {
        
    }
    
    componentDidMount() {
        this.getQuery()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.scope !== this.props.scope) {
            this.getQuery()
        }

        if (prevProps.data[this.props.id] !== this.props.data[this.props.id]) {
            this.getValues()
        }
        
    }
    
    getQuery = () => {
        const { id, widgetSize, scope, query, theme } = this.props;
        
        this.props.getReports({
            ...query,
            id: id
        })
        
    }

    getValues = () => {
        const { metric, scope, data, id } = this.props;
        
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]

        if (!report) {
            return false
        }

        // get metricName & metricType
        
        const defaultMetric = report.columnHeader.metricHeader.metricHeaderEntries[0];
        const metricName = defaultMetric.name;
        const metricType = defaultMetric.type;
        
        // get totals
        
        const { totals, maximums, minimums } = report.data;
        
        const total = totals[0].values[0]
        const maximum = maximums[0].values[0]
        const minimum = minimums[0].values[0]
        const average = parseFloat(total / report.data.rows.length).toFixed(0);
        
        // get change
        
        let change, percent, trending;
        
        if (totals.length === 2) {

            const s1 = totals[0].values[0]
            const s2 = totals[1].values[0]
            
            if (s1 > s2) {
                change = (s1 - s2);
                percent = change / s1 * 100
                trending = "up"
            } else if (s2 > s1) {
                change = (s2 - s1);
                percent = change / s2 * 100
                trending = "down"
            } else {
                change = 0
                trending = "flat"
            }

        }
        
        // set values
        
        const values = {
            total: <FormatMetric {...defaultMetric} value={total} />,
            average: <FormatMetric {...defaultMetric} value={average} />,
            maximum: <FormatMetric {...defaultMetric} value={maximum} />,
            minumum: <FormatMetric {...defaultMetric} value={minimum} />,
            change: <FormatMetric {...defaultMetric} value={change} />,
            percent: <FormatMetric type="PERCENT" value={percent} />,
        }   
        
        this.setState({
            values: values,
            trending: trending
        })
        
    }
    
   
    render() {
        const { id, data, theme, scope } = this.props;

        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]

        const { values, trending } = this.state;

        // get messages
        
        let loadingTitle, title, description;
        
        const loadingId = scope
        const titleId = [scope, "title"].join('.')
        const descriptionId = [scope, "description"].join('.')
        const trendingId = [scope, trending].join('.')

        if (messages[loadingId]) {
            loadingTitle = <FormattedMessage {...messages[loadingId]} values={values} />
        }
        
        if (messages[titleId]) {
            title = <FormattedMessage {...messages[titleId]} values={values} />
        }
        
        if (messages[descriptionId]) {
            description = <FormattedMessage {...messages[descriptionId]} values={values} />
        }
        
        if (!report) {

            return (
                <AnalyticsWidget {...this.props} loadingTitle={loadingTitle} />
            )
            
        }
        
        const colors = [
            "rgb(64, 53, 118)",
            "rgb(33, 133, 177)",
            "rgb(179, 0, 33)",
            "rgb(216, 172, 38)"
        ]
        
        return (
            <AnalyticsWidget {...this.props} report={report || undefined} dimension="dm:owner" theme={theme} title={title} description={description} colors={colors}/>
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetUserAnalytics);