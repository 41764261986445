export default {
  "select": {
    "parentId" : "parentId",
    "imageUrl" : "imageUrl",
    "title" : "title",
    "description" : "description",
    "metadata" : "metadata",
    "label": "label"
  },
  prepare(selection) {
    let { locale, imageUrl, title, content, description, referenceCount } = selection

    const label = 'kp/topic';
    
    let meta = []

    let parentId
    
    if (content) {
        let { header, metadata, hasArticles } = content;

        let localeId
        
        if (locale) {
            localeId = "locale:" + locale;
        }
        
        if (content.parentId) {
            parentId = parseInt(content.parentId)
        }
        
        if (header) {

            if (header.title && header.title[localeId]) {
                title = header.title[localeId]
            }
            
            if (header.description && header.description[localeId]) {
                description = header.description[localeId]
            }
    
            if (header.image && header.image.media && header.image.media.imageUrl) {
                imageUrl = header.image.media.imageUrl;
            }

        }

        if (hasArticles) {
            meta.push(hasArticles.length + ' artikler')
        }
                
    }
    
    return {
        parentId: parentId || null,
        imageUrl: imageUrl,
        title: title,
        description: description,
        metadata: meta,
        label: label
    }
    
  }
  
}