import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
    root: {
        "&[data-status=trash]": {
            "& figure": {
                opacity: "0.5"
            },
        },
        "&.sortable-helper": {
            boxShadow: theme.shadows[12],
            zIndex: 2000
        }
    }
}));

const ModuleBase = ({
        id, 
        name,
        component = "article",
        className,
        size,
        status, 
        selectable = false,
        selected = false,
        collapsible = false,
        expanded = false, 
        toolbar,
        settings,
        elevation = 0, 
        onClick, 
        children,
        ...props
    }) => {

    const classes = useStyles();

    if (component === "tr") {
        return (
            <tr id={id}
                className={className || classes.root}
                data-grid-cols={props.gridCols}
                data-id={id}
                data-name={name}
                data-size={size}
                data-status={status}
                aria-expanded={expanded}
                aria-selected={selected}
                role={onClick && "button"}
                onClick={onClick}>
                    {children}
            </tr>
        )
    }

    if (!elevation) {
        return (
            <article id={id}
                className={className || classes.root}
                data-grid-cols={props.gridCols}
                data-id={id}
                data-name={name}
                data-size={size}
                data-status={status}
                aria-expanded={expanded}
                aria-selected={selected}
                role={onClick && "button"}
                onClick={onClick}>
                    {children}
            </article>
        )
    }


    return (
        <Paper id={id} 
            component={component}
            className={className || classes.root}
            data-grid-cols={props.gridCols}
            data-id={id}
            data-name={name}
            data-size={size}
            data-status={status}
            aria-expanded={expanded}
            aria-selected={selected}
            data-elevated={true}
            elevation={elevation}
            square={true}
            role={onClick && "button"}
            onClick={onClick}>
                { children }
        </Paper>
    )

}

ModuleBase.propTypes = {
    id: PropTypes.any,
    elevation: PropTypes.number,
    draggable: PropTypes.bool,
    draggableHandle: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    collapsible: PropTypes.bool,
    expanded: PropTypes.bool,
    onToggle: PropTypes.func,
    deletable: PropTypes.bool,
    restorable: PropTypes.bool,
    onDelete: PropTypes.func,
    onRestore: PropTypes.func,
    onErase: PropTypes.func,
    draggable: PropTypes.bool,
    hideable: PropTypes.bool,
    hidden: PropTypes.bool
}

export default ModuleBase;
