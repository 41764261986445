import { API } from '../settings';
import { getQueryParams } from './query';
import { requestSearch, receiveSearch } from '../actions/search';
//import { requestEditorData, receiveEditorData } from '../actions/editor';
//import { getEditor } from './editor';

// PREVIEW

export function getUiPreview(preview, model) {

    return getPreview({ "preview": preview}, model)

}

export function getPreview(schema, model) {

  if (!schema) {
    return model;
  }

  if (!schema.preview) {
    return model;
  }
  
  if (!schema.preview.select) {
    return model;
  }

  if (!schema.preview.prepare) {
    return model;
  }
  
  let { select, prepare } = schema.preview;
  
  let selection;
  
  if (prepare) {
    selection = prepare(model)
  } else {
    selection = model;
  }
  
  if (select) {
    
    for (let key in select) {
        
        let value = null;
      
        if (select.hasOwnProperty(key) && selection && selection[key]) {
            value = selection[key];
        }

        model[key] = value;
      
    }
    
  }

  return model;
  
}

/*


// EDIT

export function editSchema(schema, data) {
  
  if (!data) {

    data = {
      status: 'new',
      content: {}
    }
    
  }

  return function(dispatch) {
    dispatch(getEditor(schema))
    dispatch(receiveEditorData(data))
  }
  
}


// LOAD

export function loadSchema(schema, id) {
  
  if (!id) {
    
    return function (dispatch) {
      dispatch(editSchema(schema))
    }
    
  }
  
  const url = API + '/admin/api/schemas/' + id;
  
  return function (dispatch) {

    dispatch(requestEditorData())

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
        dispatch(editSchema(schema, data)),
    )

  }

}

// SAVE

export function saveSchema(schema, data) {

  const url = API + '/admin/api/schemas';
  const payload = JSON.stringify(data);

  return function (dispatch) {

    fetch(url, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: payload
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(editSchema(schema, data))
    )
  
  }

}


// SEARCH

export function getSchemas(path, query) {
  
  let url = API + '/admin/api/schemas'

  const params = getQueryParams(query);
  
  if (params) {
    url = url + '?' + params;
  }

  return function (dispatch) {

    dispatch(requestSearch(path, query))
  
    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
        dispatch(receiveSearch(path, data))
    )

  }

}

// DELETE, ERASE, RESTORE

export function deleteSchema(id) {

  const url = API + '/admin/api/schemas/' + id;

  return function (dispatch) {
    
    fetch(url, {
      method: "DELETE",
    })
    .then(
      error => console.log('An error occurred.', error)
    )
  
  }

}

export function eraseSchema(id) {

  const url = API + '/admin/api/schemas/' + id + '?erase=true';

  return function (dispatch) {
    
    fetch(url, {
      method: "DELETE",
    })
    .then(
      error => console.log('An error occurred.', error)
    )
  
  }

}

export function restoreSchema(id) {

  const url = API + '/admin/api/schemas/restore/' + id;
  
  return function (dispatch) {
    
    fetch(url, {
      method: "POST",
    })
    .then(
      error => console.log('An error occurred.', error)
    )
  
  }

}

*/
