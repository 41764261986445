import React from 'react'
import { getUiOptions } from "@frontend-components/admin"

const BulkEditorField = (props) => {
    const { schema, uiSchema, formData, formContext, registry } = props;
    const { primaryAction, secondaryAction, preview, untitled, title, languages, currentLocale, onLocale, onClose } = formContext;

    const newUiSchema = {
        ...uiSchema,
        "ui:id": "editor",
        "ui:preview": preview,
        "ui:untitled": untitled,
        "ui:title": title,
        "ui:primaryAction": primaryAction,
        "ui:secondaryAction": secondaryAction,
        "ui:languages": languages,
        "ui:currentLocale": currentLocale,
        "ui:onLocale": onLocale,
        "ui:onClose": onClose,
        "ui:layout": "bulkEditor"
    }

    const { ObjectField } = registry.fields;

    return (
        <ObjectField {...props} uiSchema={newUiSchema} />
    )

}

export default BulkEditorField;