import moment from 'moment'

import { getChartColors } from "./getChartColors"

const metrics = {
    "ga:bounceRate": {
        yFormat: "percent",
    },
    "ga:avgTimeOnPage": {
        yFormat: "time"
    }
}

export function getChart(props) {
    let { type, metric, range, compare, theme, data, colors } = props;
    
    if (!data) {
        data = getRandomChartData(props)
    }
    
    return {
        ...props,
        type: type,
        xFormat: getXFormat(props),
        yFormat: getYFormat(props),
        colors: colors || getChartColors(props),
        data: data
    }

}

function getXFormat(props) {
    const { xFormat, range } = props;
    
    if (xFormat) {
        return xFormat
    }
    
    if (!range) {
        return undefined
    }
    
    if (range === "today" || range === "yesterday") {
        return "hour"
    }
    
    if (range.includes('days')) {
        return "date"
    }
    
    return undefined
    
}

function getYFormat(props) {
    const { yFormat, metric } = props;
    
    if (yFormat) {
        return yFormat
    }
    
    if (metrics[metric] && metrics[metric].yFormat) {
        return metrics[metric].yFormat
    }
    
    return undefined
    
}

function getRandomChartData(props) {
    const { metric, range, compare, series } = props;
    
    let data = [];
    
    if (compare) {
        data[0] = getRandomChartSerie({...props, id: "primary"})
        data[1] = getRandomChartSerie({...props, id: "secondary"})

    } else if (series) {
        for (let i = 0; i <= series; i++) {
            data[i] = getRandomChartSerie({...props, id: i})
        }
    } else {
        data[0] = getRandomChartSerie({...props, id: "primary"})
    }
    
    return data;
    
}

function getRandomChartSerie(props) {
    const { id, range } = props;
    
    if (!range) {
        return {
            id: id,
            error: "No range"
        }
    }
    
    if (range === "today") {
        return getRandomChartTimeRange(props)
    }

    if (range === "yesterday") {
        return getRandomChartTimeRange(props)
    }
    
    if (range.includes('days')) {
        return getRandomChartDateRange(props)
    }
    
    return {
        id: id,
        error: range + " not supported"
    }

}

function getRandomChartDateRange(props) {

    const { id, date, metric, range } = props;
    
    const days = parseInt(range);

    const d1 = moment(date).format('YYYY-MM-DD');
    const d2 = moment(d1).add(days, "days").format('YYYY-MM-DD');
    
    let data = []
    let total = 0;
    
    for (var m = moment(d1); m.isBefore(d2); m.add(1, 'days')) {
        const x = m.format('YYYY-MM-DD');
        const y = getRandomChartValue(props);
        
        total = total + y;
        
        data.push({
            x: x,
            y: y
        })
    }     

    const average = total / data.length;
    
    return {
        id: id,
        metric: metric,
        total: total,
        average: average,
        data: data
    }   


}

function getRandomChartTimeRange(props) {

    const { id, date, metric, range } = props;
    
    const days = parseInt(range);

    const r1 = moment(date).format();
    const r2 = moment(r1).add(24, "hours").format();
    
    let data = []
    let total = 0;
    
    for (var m = moment(r1); m.isBefore(r2); m.add(1, 'hour')) {
        const x = m.format();
        const y = getRandomChartValue(props);

        total = total + y;
        
        data.push({
            x: x,
            y: y
        })
    }     

    const average = total / data.length;
    
    return {
        id: id,
        total: total,
        average: average,
        data: data
    }   


}

function getRandomChartValue(props) {
    const { metric } = props;
    
    const yFormat = getYFormat(props);
    const xFormat = getXFormat(props);
    
    if (yFormat && yFormat === "percent") {
        return getRandomPercent(props)
    }

    if (yFormat && yFormat === "time") {
        return getRandomTime(props)
    }
    
    if (xFormat === "hour") {
        return getRandomByHour(props)
    }
    
    return getRandomByDate(props)
    
}

function getRandomByHour(props) {

    const min = 735 / 24;
    const max = 3200 / 24;

    const rnd = Math.random() * (max - min) + min
    
    return Math.round(rnd)
    
}

function getRandomByDate(props) {

    const min = 735;
    const max = 3200;

    const rnd = Math.random() * (max - min) + min
    
    return Math.round(rnd)
    
}


function getRandomTime(props) {

    const min = 100;
    const max = 180;
    
    return Math.random() * (max - min) + min
    
}

function getRandomPercent(props) {

    const min = 35;
    const max = 65;
    
    return Math.random() * (max - min) + min
    
}