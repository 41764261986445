export default {
    type: "object",
    properties: {
        googleAnalytics: {
            type: "object",
            properties: {
                apiKey: {
                    type: "string"
                }
            }
        },
        googleMaps: {
            type: "object",
            properties: {
                apiKey: {
                    type: "string"
                }
            }
        },
        googleMyBusiness: {
            type: "object",
            properties: {
                apiKey: {
                    type: "string"
                }
            }
        }
    }
}