import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutGrid from "../Layout/LayoutGrid"

const useStyles = makeStyles(theme => ({
    root: {
        padding: props => { return theme.spacing(props.padding) },
    }
}));

const ModuleBody = ({className, format, grid = false, padding = 2, children, ...props}) => {
    const classes = useStyles({padding});

    return (
        <div className={className || classes.root}>
            <LayoutGrid grid={grid} {...props}>
                {children}
            </LayoutGrid>
        </div>
    )

}

export default ModuleBody;