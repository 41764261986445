// initialState

let initialState = {
};

// editor state

const referencesById = (state = initialState, action) => {

	switch (action.type) {

        case 'REQUEST_REFERENCES':
        
            return {
                ...state,
                [action.id]: undefined
            }
      	
        case 'RECEIVE_REFERENCES':

            return {
                ...state,
                [action.id]: action.data
            }

                
        default:
            return state
        }

}

export default referencesById;