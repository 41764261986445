import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearch } from '../redux/functions/search';
import { useTranslation } from 'react-i18next';

import DialogTabs from "./DialogTabs"
import MediaResults from "./MediaResults"

import { FinderSection } from "../components"


import qs from 'query-string';

const MediaQuery = ({schema, formData, onChange, onClose, query, searchById, ...props}) => {
    const { t, i18n } = useTranslation(['dialog']);

    const getSection = (mediaType) => {

        if (mediaType === "youtube" || mediaType == "vimeo" || mediaType === "sketchfab") {

            return {
                title: mediaType,
                layout: "gallery",
                query: {
                    id: query.id + "/" + mediaType + "/" + query.q,
                    models: mediaType,
                    siteId: query.siteId,
                    collectionId: query.collectionId,
                    rows: query.rows,
                    q: query.q
                }
            }
                
        }

        if (mediaType === "*") {

            return {
                title: "media",
                query: {
                    ...query,
                    id: query.id + "/" + mediaType + "/" + query.q,
                    mediaType: mediaType
                }
            }
                
        }

        let layout;

        if (mediaType.startsWith('image') || mediaType.startsWith('video')) {
            layout = "gallery"
        }

        return {
            title: mediaType,
            layout: layout,
            query: {
                ...query,
                id: query.id + "/" + mediaType + "/" + query.q,
                mediaType: mediaType
            }
        }

    }

    let sections = [];

    const { mediaType, sources } = query

    if (mediaType) {

        if (Array.isArray(mediaType)) {
            sections = mediaType.map(type => {
                return getSection(type)
            })
        } else {
            sections = [getSection(query.mediaType)]
        }

    } else {
        sections = [
            getSection('image'),
            getSection('video'),
            getSection('audio'),
        ]
    }

    const [tabIndex, setTabIndex] = useState(0)
    const currentTab = sections[tabIndex]

    const getSearchCount = () => {

        sections.map((section, index) => {
            const { query } = section;

            if (query.models === "media" && tabIndex !== index) {
                props.getSearch({...query, rows: 0});
            }

        })
        
    }

    useEffect(() => {
        getSearchCount()
    }, [query.q])

    const tabs = sections.map((section, index) => {
        const { title, query } = section;

        const currentSearch = searchById && searchById[query.id]
        const resultsLoaded = currentSearch && currentSearch.resultsLoaded
        
        let count;

        if (currentSearch && currentSearch.count) {
            count = currentSearch && currentSearch.count
        } else if (resultsLoaded && resultsLoaded.length && currentSearch.nextToken) {
            count = resultsLoaded && resultsLoaded.length && resultsLoaded.length + "+";
        } else if (resultsLoaded && resultsLoaded.length) {
            count = resultsLoaded && resultsLoaded.length && resultsLoaded.length;
        }

        return {
            title: t(title),
            count: count,
            onClick: () => setTabIndex(index)
        }

    })

    const getSearch = () => {
        currentTab && props.getSearch(currentTab.query);
    }

    useEffect(() => {
        getSearch()
    }, [tabIndex, query.q])

    // renderResults

    return (
        <FinderSection>
            <header>
                <DialogTabs tabs={tabs} />
            </header>
            <MediaResults 
                schema={schema}
                formData={formData}
                onChange={onChange}
                query={currentTab.query}
                layout={currentTab.layout} />
        </FinderSection>
    )

}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getSearch,
    }, 
dispatch);

const mapStateToProps = (state) => {
	return {
        searchById: state.searchById
	};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaQuery);