export default {
    type: "array",
    items: {
        type: "object",
        properties: {
            placeId: {
                type: "string"
            },
            place: {
                type: "object",
                properties: {
                    uniqueId: {
                        type: "string"
                    },
                    title: {
                        type: "string"
                    },
                    address: {
                        type: "string"
                    },
                    countryCode: {
                        type: "string"
                    },
                    lat: {
                        type: "string"
                    },
                    lng: {
                        type: "string"
                    }
                }
            }
        }
    }
}