import ParentIdField from "./Parent/ParentIdField"

export default {
    "fieldtypes": {
    },
    "fields": {
        "parentId": ParentIdField,
    },
    "layouts": {
    },
    "widgets": {
    }
}