import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../redux/functions/user';
import { getQueryString } from "../redux/functions/query";
import { getAppLayout } from '../redux/functions/app';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    button: {

    }

}));

const AppLogin = ({user, ...props}) => {

    const qs = getQueryString()
    const userId = qs && qs.userId;

    const handleLogin = (userId) => {
        props.login(userId)
    }

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Button onClick={() => handleLogin(userId)} color="primary" variant="contained" size="large">Logg inn {userId}</Button>
        </div>
    )

}

// mapStateToProps

function mapStateToProps(state) {
	return {
		"user": state.user
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ getAppLayout, login }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppLogin);

