import { getUiOptions } from "../.."

const defaultProps = {
    defaultFormData: "",
    defaultOptions: {
    },
    defaultSchema: {
        "type": "string",
        "format": "color",
        "enum": ["black","white"],
        "default": "black"
    }
}

const getSchema = (props) => {
    const { schema, defaultSchema } = props;
    
    let format;

    if (schema.type === "textColor") {
        format = "textColor"
    } else {
        format = "color"
    }

    return {
        ...schema,
        "type": "string",
        "format": format,
        "enum": schema.enum || ["black", "white"],
        "default": schema.default || "black"
    }
    
}
    
function getUiSchema(props) {
    const { schema, uiSchema, defaultOptions } = props;
    
    let options = getUiOptions(uiSchema)
    
    return {
        "ui:options": {
            ...defaultOptions,
            ...options
        }
    }

    
}
    
function getFormData(props) {
    let { formData, defaultFormData } = props;

    return formData
    
}

export function getColorField(props) {
    
    props = {
        ...defaultProps,
        ...props,
    }    
    
    const schema = getSchema(props)
    const uiSchema = getUiSchema({...props, schema: schema})
    const formData = getFormData({...props, schema: schema, uiSchema: uiSchema})
    
    return {
        ...props,
        schema: schema,
        uiSchema: uiSchema,
        formData: formData
    }
    
}