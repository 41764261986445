import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: props => { return props.backgroundColor },
        color: "black",
        maxWidth: "320px",
        zIndex: 2
    },
    arrow: {
        position: "absolute",
        zIndex: 1,
        fontSize: "7px",
        width: "3em",
        height: "3em",
        "[x-placement*=top] &": {
            bottom: 0,
            width: 0,
            height: 0, 
            borderLeft: "1em solid transparent",
            borderRight: "1em solid transparent",
            borderTop: "1em solid",
            borderTopColor: props => { return props.arrowColor },
            marginBottom: "-1em",
        },
        "[x-placement*=bottom] &": {
            width: 0, 
            height: 0, 
            borderLeft: "1em solid transparent",
            borderRight: "1em solid transparent",
            borderBottom: "1em solid",
            borderBottomColor: props => { return props.arrowColor },
            marginTop: "-1em",
        },
        "[x-placement*=right] &": {
            left: 0,
            width: 0, 
            height: 0, 
            borderTop: "1em solid transparent",
            borderBottom: "1em solid transparent",
            borderRight: "1em solid",
            borderRightColor: props => { return props.arrowColor },
            marginLeft: "-1em",
        },
        "[x-placement*=left] &": {
            right: 0,
            width: 0, 
            height: 0, 
            borderTop: "1em solid transparent",
            borderBottom: "1em solid transparent",
            borderLeft: "1em solid",
            marginRight: "-0.9em",
        }
    }
}));

const UserDropdown = ({children, expanded, onCollapse, anchorEl, placement = "bottom-end"}) => {

    const classes = useStyles({backgroundColor: "white", arrowColor: "white"})

    return (
        <Popper open={expanded}
            style={{zIndex: 2000}}
            role={undefined} transition anchorEl={anchorEl} placement={placement} modifiers={{arrow: { enabled: true, element: "[data-popper-arrow]" }}}>
            <div className={classes.arrow} data-popper-arrow></div>
            <ClickAwayListener onClickAway={onCollapse}>
                <Paper className={classes.paper} elevation={24} square={true}>
                    {children}
                </Paper>
            </ClickAwayListener>
        </Popper>
    )
}

export default UserDropdown