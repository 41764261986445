import { getAppData } from '../../redux/functions/app';
import { getUserCollection } from '../../redux/functions/appCollection';

import apps from "../../redux/apps";
import schemas from '../schemas/';

export function getApp(props) {
    const { user, site, collection } = props;
    
    const title = "Admin"
    const name = "admin"
    const root = "/admin"
    
    props = {
        ...props,
        uniqueId: "admin",
        title: title,
        name: name,
        root: root,
    }
    
    const primaryAction = getPrimaryAction(props)
    const appMenu = getAppMenu(props)
    
    const app = {
        ...props,
        layout: "finder",
        theme: {
            primaryColor: "#444"
        },
        search: {
            placeholder: "Søk i admin"
        },
        sidebar: {
            expanded: true
        },
        primaryAction: primaryAction,
        menu: appMenu,
        schemas: schemas,
        languages: [
            "no", "sv", "en", "nn"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
//        dispatch(getUserCollection({...props, app: app}))
    }
    
}

export function getPrimaryAction(props) {
    const { root } = props;

    return {
        title: "Create",
        children: [
            {
                role: "group",
                children: [
                    {
                        title: "New owner",
                        url: root + "/site/<new>/edit",
                        icon: "business"
                    },
                    {
                        title: "New user",
                        url: root + "/user/<new>/edit",
                        icon: "person"
                    }
                ]
            },
            {
                role: "group",
                children: [
                    {
                        title: "New collection",
                        url: root + "/collection/<new>/edit",
                        icon: "apps"
                    },
                    {
                        title: "New schema",
                        url: root + "/schema/<new>/edit",
                        icon: "apps"
                    }
                ]
            }
        ]
    }
    
}

export function getSiteMenu(props) {
    const { site } = props;
    
    const root = "/" + site.name + "/admin" 

    return [
        {
            title: site.title || site.name,
            url: root
        },
        {
            title: "Admin",
            url: "/admin"
        }
    ]
    
    
}

export function getAppMenu(props) {
    const { root } = props;
    
    const authAppsMenu = getAuthAppsMenu(props);
    const authSiteMenu = getAuthSiteMenu(props);
    const authUserMenu = getUserAuthMenu(props);
    
    return [
        {
            title: "Admin",
            role: "group",
            url: root
        },
        {
            hidden: true,
            role: "group",
            children: [
                {
                    url: root + "/site",
                    title: "Sites",
                    icon: "business"
                },
                {
                    url: root + "/user",
                    title: "Users",
                    icon: "people"
                },
                {
                    url: root + "/collection",
                    title: "Collections",
                    icon: "apps",
                },
                {
                    url: root + "/schema",
                    title: "Schema",
                    icon: "apps"
                }
            ]
        }
        
    ]
    
}

export function getUserAuthMenu(props) {
    const { root } = props;

    const accessByOwner = getMenuBySite({...props, root: root + "/owner/access"})
    
    return{
        title: "Users",
        icon: "people",
        url: root + "/auth/user",
        children: [
            {
                title: "Apps",
            },
            {
                title: "Owners",
            }
        ]
      }
    
}

export function getAuthSiteMenu(props) {
    const { root } = props;

    const accessByOwner = getMenuBySite({...props, root: root + "/owner/access"})
    
    return{
        title: "Owners",
        icon: "business",
        url: root + "/auth/site",
        children: [
            {
                title: "Apps",
            },
            {
                title: "Users",
            }
        ]
//        children: accessByOwner,
//        count: accessByOwner.length
      }
    
}

export function getAuthAppsMenu(props) {
    const { root } = props;
    
    let children = []
    
    Object.values(apps).map(app => {
        
        if (!app.collectionType || !app.collectionType.startsWith('site')) {
            return
        }
        
        children.push({
            ...app,
            icon: undefined,
            url: root + "/auth/apps/" + app.name
        })
        
    })
    
    return {
        title: "Apps",
        icon: "apps",
        url: root + "/auth/app",
        children: [
            {
                title: "Owners",
            },
            {
                title: "Users",
            }
        ]
    }
    
}


export function getMenuBySite(props) {
    const { user, root } = props;
    const { appsBySite } = user;
    
    let menu = []
    
    if (!appsBySite) {
        return menu
    }

    appsBySite.map((site) => {
        
        let siteMenu = {
            title: site.title,
            url: root + "/" + site.name,
            siteId: site.id
        }
        
        let children = []
        
        site.apps.map((app) => {
            const { name, appType } = app;
            
            if (appType !== "site/product") {
                return
            }
            
            children.push({
                title: app.title,
                url: siteMenu.url + "/" + app.name
            })
            
        })
        
        menu.push({
            ...siteMenu,
            children: children,
            count: children.length
        })
        
    })
    
    return menu;
    
}

export function getMenuByApps(props) {
    const { user, root } = props;
    const { appsBySite } = user;
    
    let menu = []
    
    if (!appsBySite) {
        return menu
    }
    
    let apps = {}

    appsBySite.map((site) => {
        
        site.apps.map((app) => {
            const { name, appType } = app;
            
            if (!appType || !appType.startsWith("site")) {
                return
            }
            
            if (!apps[name]) {
                apps[name] = {
                    title: app.title,
                    url: root + "/" + app.name,
                    count: 0,
                    children: []
                }
            }
            
            const appSiteMenu = {
                title: site.title,
                url: apps[name].url + "/" + site.name
            }
            
            if (!apps[name].children.includes(appSiteMenu)) {
                apps[name].children.push(appSiteMenu)
            }
            
            apps[name].count = apps[name].count + 1;
            
            
        })
        
    })
    
    return Object.values(apps);
    
}