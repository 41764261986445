import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {

        "&[role=group] + *": {
            borderTop: "1px solid",
            borderTopColor: theme.palette.divider,
            marginTop: theme.spacing(1.5),
            paddingTop: theme.spacing(1.5),
        },
        "& + &[role=group]": {
            borderTop: "1px solid",
            borderTopColor: theme.palette.divider,
            marginTop: theme.spacing(1.5),
            paddingTop: theme.spacing(1.5),

            "& + *": {
                borderTop: "1px solid",
                borderTopColor: theme.palette.divider,
                marginTop: theme.spacing(1.5),
                paddingTop: theme.spacing(1.5),
            }

        }

    }
}));

const ActionMenuItem = ({className, role, children}) => {
    const classes = useStyles()

    return (
        <li className={className || classes.root} role={role}>
            {children }
        </li>
    )

}

export default ActionMenuItem