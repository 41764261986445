import React, { Component } from 'react';
import ReactPlayer from 'react-player'

// Class

class PreviewVideo extends Component {
  
  getResource = (resource) => {
    
    const { mimeType, url } = resource
    
    return {
      type: mimeType,
      src: url
    }
    
  }
  
  getUrls = () => {

    const { media } = this.props.formData;
    const { resources } = media;
    
    const urls = []
    
    if (resources['mp3']) {
      urls.push(this.getResource(resources['mp3']))
    }

    if (resources['ogg']) {
      urls.push(this.getResource(resources['ogg']))
    }
    
    return urls;
    
  }
  
  render() {
    
    const urls = this.getUrls();
    
    return (

      <ReactPlayer className='audio' url={ urls } controls={ true } />
      
    )
    
  }
  
}

export default PreviewVideo;