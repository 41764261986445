import React, { Component } from 'react';
import { getUiOptions, getDefaultFormState } from "@frontend-components/admin"

class FieldAnnotateImage extends Component {

    static defaultProps = {
        defaultFormData: {
            "mediaId": undefined,
            "media": {
                "mediaType": undefined
            },
            "imageAnnotations": []
        },
        defaultUiSchema: {
            "ui:layout": "annotateImage",
            "ui:format": "2:1",
            "ui:options": {
                "hideable": true,
                "deletable": true,
            },
            "ui:preview": {
                "select": {
                    "mediaId": "mediaId",
                    "media": "media",
                    "imageAnnotations": "imageAnnotations"
                }
            },
            "ui:buttons": [
                {
                    "type": "upload"
                }
            ],
            "ui:fieldset": [
                "imageAnnotations"
            ],
            "imageAnnotations": {
                "ui:title": "Markører",
                "ui:preview": {
                    "select": {
                        "title": "title",
                    },
                    prepare({formData}) {
                        let title;

                        if (formData && formData.length) {
                            title = formData.length + " markører"
                        } else {
                            title = "Klikk på bilde for å legge til"
                        }

                        return {
                            title: title
                        }

                    }
                },
                "ui:options": {
                    "sortable": true
                },
                "items": {
                    "ui:layout": "module",
                    "ui:preview": {
                        "select": {
                            "title": "title",
                        },
                        prepare({formData}) {
                            const index = formData && formData._index && formData._index+1 || 1
                            const title = formData && formData.title;

                            return {
                                title: index + ". " + title
                            }
                        }                        
                    },
                    "ui:options": {
                        "editable": true,
                    },
                    "top": {
                        "ui:hidden": true,
                    },
                    "left": {
                        "ui:hidden": true 
                    },
                    "x": {
                        "ui:hidden": true 
                    },
                    "y": {
                        "ui:hidden": true 
                    },
                    "radius": {
                        "ui:widget": "range" 
                    },
                    "title": {
                        "ui:title": "Tittel"
                    },
                    "description": {
                        "ui:title": "Beskrivelse",
                        "ui:widget": "textarea"
                    }
                }
            }
        }
    }

    onChange = (formData) => {
        const { schema, defaultFormData } = this.props;

        formData = getDefaultFormState(schema, {
            ...defaultFormData,
            ...formData
        })
        
        const newFormData = {
            ...formData
        }
        
        if (this.props.onChange) {
            this.props.onChange(newFormData);
        }
        
    }

    getUiSchema = () => {
        const { formData, schema, uiSchema, defaultUiSchema } = this.props;

        return {
            ...defaultUiSchema,
            ...uiSchema
        }
        
    }

    getField = () => {
        const uiSchema = this.getUiSchema()

        return {
            ...this.props,
            uiSchema: uiSchema
        }

    }

    render() {
        const { schema, uiSchema, formData, registry } = this.getField();
        const { ObjectField } = registry.fields;

        return (
            <ObjectField {...this.props} schema={schema} uiSchema={uiSchema} formData={formData} onChange={this.onChange} />
        )
    
    }

}

export default FieldAnnotateImage;