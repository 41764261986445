import React, { Component } from 'react';

class Areas extends Component {
    
    renderArea = (area) => {
        const { areaGenerator, areaOpacity, areaBlendMode } = this.props;
        const { id, data, color: areaColor } = area;
        
        const className = "area  area--" + id;
        
        return (
            <path
                key={id}
                d={areaGenerator(data.map(d => d.position))}
                fill={areaColor}
                fillOpacity={areaOpacity}
                strokeWidth={0}
                className={className}
                style={{
                    mixBlendMode: areaBlendMode,
                }}
            />
        )
        
    }

    render() {
        const { series } = this.props;
        
        return (
            <g className="areas">
                { series.slice(0).reverse().map(this.renderArea) }
            </g>
        )
        
    }


}

export default Areas