import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUiOptions } from "@frontend-components/admin"
import { searchModel, getModel, getModelChildren } from '../../redux/functions/model';

class FieldEditorChildren extends Component {

    static defaultProps = {
        model: "documents"
    }

    componentDidMount() {
        this.getChildren()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.parentId !== this.props.parentId) {
            this.getChildren()
        }
    }

    getChildren = () => {
        const { id, uiSchema, model, parentId } = this.props;

        if (!parentId) {
            return false
        }

        const uiOptions = getUiOptions(uiSchema)
        const queryId = parentId + "/" + id

        this.props.searchModel(model, queryId, {
            parentId: parentId,
            documentType: uiOptions && uiOptions.documentType
        });

    }

    getRegistry = () => {
        const { registry, formContext } = this.props;

        return {
            ...registry,
            formContext: {
                ...registry.formContext,
                onEdit: formContext.onEditChild
            }
        }

    }

    getFormData = () => {
        const { id, parentId, data, modelsById } = this.props
        const queryId = parentId + "/" + id

        let models = data && data[queryId] && data[queryId].models || []

        const formData = models && models.map(model => {
            return {
                ...model,
                ...modelsById[model.uniqueId]
            }
        })

        return formData

    }

    getUiSchema = () => {
        const { formContext, uiSchema, parentId } = this.props;
        const uiOptions = getUiOptions(uiSchema)

        const { documentType, buttons } = uiOptions;

        const uiButtons = buttons && buttons.map(button => {
            return {
                ...button,
                onClick: () => formContext.onCreateChild({
                    documentType: documentType,
                    parentId: parentId,
                })
            }
        })

        const uiPreview = {
            "select": {
                "description": "description",
            },
            prepare({formData}) {
                return {
                    description: formData && formData.length
                }
            }
        }
        
        return {
            ...uiSchema,
            "ui:preview": uiPreview,
            "ui:collapsible": true,
            "ui:buttons": uiButtons,
            "ui:parentId": parentId
        }

    }


    render() {
        const registry = this.getRegistry()
        const formData = this.getFormData()
        const uiSchema = this.getUiSchema()

        const { ArrayField } = registry.fields

        return (
            <ArrayField {...this.props} registry={registry} formData={formData} uiSchema={uiSchema} />
        )
             
    }

    

}

// mapStateToProps

const mapStateToProps = (state) => {
	return {
        app: state.app,
        site: state.site,
        collection: state.collection,
        schemas: state.schemas,
        editor: state.editor,
        parentId: state.editor && state.editor.formData && state.editor.formData.id,
        data: state.data,
        modelsById: state.modelsById
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        searchModel,
        getModel, getModelChildren,
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FieldEditorChildren);
