import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from '../redux/functions/model';
import { ListModule, TableModule, GridModule, GalleryModule, getUiPreview } from '../components';
import { useTranslation } from 'react-i18next';

const layouts = {
    "table": TableModule,
    "list": ListModule,
    "grid": GridModule,     
    "gallery": GalleryModule,
}

const ResultsModel = ({app, dialog, query, model, layout = "table", size = "medium", schemasByName, modelsById, modelsBySource, modelsSelected = [], onAdd, onRemove, ...props}) => {
    const { t, i18n } = useTranslation();

    const { uniqueId, source, sourceId, documentType, mediaType } = model;


    useEffect(() => {
        if (modelName && uniqueId && !modelsById[uniqueId]) {
            props.getModel(modelName, uniqueId)
        }
    }, [uniqueId])

    const appDocumentType = documentType && app && app.root + "/" + documentType
    const sourceDocumentType = source && "document/" + source

    let modelName = model.modelName === "doc_schemas" && "schemas" || model.modelName
    let modelSchema;

    if (modelName === "users") {
        modelSchema = schemasByName['user']
    } else if (modelName === "sites") {
        modelSchema = schemasByName['site']
    } else if (modelName === "collections") {
        modelSchema = schemasByName['collection']
    } else if (modelName === "doc_schemas") {
        modelSchema = schemasByName['schema']
    } else if (modelName === "documents" || documentType) {
        if (schemasByName[appDocumentType]) {
            modelSchema = schemasByName[appDocumentType]
        } else if (source && schemasByName[sourceDocumentType]) {
            modelSchema = schemasByName[sourceDocumentType]
        } else if (documentType) {
            modelSchema = schemasByName[documentType]
        }
    } else if (modelName === "media" || mediaType) {
        modelSchema = mediaType && schemasByName["media/"+mediaType]
    }


    const uniqueModel = source && sourceId && modelsBySource && modelsBySource[source + "/" + sourceId] || uniqueId && modelsById && modelsById[uniqueId] || model

    const [preview, setPreview] = useState(model)

    useEffect(() => {
        let uiPreview = {}

        if (modelSchema && uniqueModel) {
            uiPreview = getUiPreview({...modelSchema, formData: uniqueModel, formContext: {}})
        }

        const { createdAt, createdByName, updatedAt, updatedByName, deletedAt, deletedByName } = uniqueModel;

        const author = deletedByName || updatedByName || createdByName || "N/A"
        const datetime = deletedAt || updatedAt || createdAt

        setPreview({
            ...uniqueModel,
            ...model,
            ...uiPreview,
            author: author,
            datetime: datetime
        })

    }, [uniqueModel, modelSchema])

    const { status } = uniqueModel;

    const statusLabel = t("status:"+status || undefined)
    const LayoutTemplate = layout && layouts[layout]

    let selected;

    if (source && sourceId) {
        selected = modelsSelected && modelsSelected.includes(source + "/" + sourceId)
    } else if (uniqueId) {
        selected = modelsSelected && modelsSelected.includes(uniqueId)
    }


    const handleSelect = () => {

        if (selected) {
            onRemove && onRemove(uniqueModel)
        } else {
            onAdd && onAdd(uniqueModel)
        }

    }

    return (
        <LayoutTemplate {...preview} size={size} statusLabel={statusLabel} selectable={true} selected={selected} onClick={handleSelect} />
    )

}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        dialog: state.dialog,
        schemasByName: state.schemasByName,
        modelsById: state.modelsById,
        modelsBySource: state.modelsBySource,
    };
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getModel
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResultsModel);