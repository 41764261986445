import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//import { SchemaBase } from "@frontend-components/admin"
import defaultRegistry from "../components/registry"
import SchemaBase from "../components/Schema/SchemaBase"
import { getSchemaPreview } from "../AppCollection/app/getSchemaPreview"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: theme.spacing(1),
    },
    menu: {
        position: "absolute",
        zIndex: 2000,
        top: "auto",
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1),

        "& *": {
            margin: theme.spacing(.5),
        }

    }
}));

const SchemaStatus = ({schema, uiSchema, uiPreview}) => {

    return (
        <div style={{position: "absolute", zIndex: 2, bottom: 0, left: 0, right: 0}}>
            { schema && <p>Schema: OK</p> }
            { uiSchema && <p>uiSchema: OK</p> }
            { uiPreview && uiPreview.select && <p>uiPreview.select: OK</p> }
            { uiPreview && uiPreview.prepare && <p>uiPreview.prepare: OK</p> }
        </div>
    )

}

const SchemaPreview = ({editor, currentLocale}) => {
    const formContext = editor && editor.formContext

    const schemaPreview = getSchemaPreview({...editor, currentLocale: currentLocale})

    const [schema, setSchema] = useState(schemaPreview.schema || {})
    const [uiSchema, setUiSchema] = useState(schemaPreview.uiSchema || {})
    const [formData, setFormData] = useState(schemaPreview.formData || {})
    const [uiPreview, setUiPreview] = useState(schemaPreview.uiPreview || {})

    const handleChange = ({formData}) => {
        setFormData(formData)
    }

    useEffect(() => {

        if (currentLocale) {
            const previewLocale = getSchemaPreview({...editor, currentLocale: currentLocale})

            setSchema(previewLocale.schema)
            setUiSchema(previewLocale.uiSchema)
    
        }

    }, [currentLocale, editor.formData])

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <SchemaBase {...defaultRegistry}
                idPrefix="root_content_editSchema"
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                formContext={formContext}
                onChange={handleChange}  />
            <SchemaStatus schema={schema} uiSchema={uiSchema} uiPreview={uiPreview} />
        </div>
    )

    
}

function mapStateToProps(state) {
	return {
        editor: state.editor,
        currentLocale: state.editor.formContext.currentLocale
	};
}  

export default connect(
  mapStateToProps,
)(SchemaPreview);
