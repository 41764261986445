import React, { Component } from 'react';

import PrimaryAction from "../Module/ModuleActionPrimary"
import SecondaryAction from "../Module/ModuleActionbar"
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        minHeight: theme.spacing(7),
        userSelect: "none",
        "&[aria-expanded=true]": {
            minHeight: theme.spacing(8)
        },
    },
    primaryAction: {
        display: "flex",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),

        "& + $content": {
            marginLeft: 0
        }

    },
    actionbar: {
        display: "flex",
        marginRight: theme.spacing(2),
        "& * + *": {

        }
    },
    content: {
        display: "flex",
        alignItems: "baseline",
        flexBasis: "100%",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        "&[data-editable=true]:hover": {
            cursor: "pointer",
        },
        "&[data-collapsible=true]:hover": {
            cursor: "pointer"
        }
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "18px",
        fontWeight: "normal",
        letterSpacing: 0,
    },
    description: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        fontWeight: "normal",
        letterSpacing: 0,
        opacity: ".6",
        marginLeft: theme.spacing(1)
    }
}));

const SectionHeader = ({title, untitled = "Untitled", description, editable, collapsible, expanded, onToggle, ...props}) => {
    const classes = useStyles()

    return (
        <header className={classes.root} aria-expanded={expanded}>
            <PrimaryAction {...props} collapsible={collapsible} expanded={expanded} className={classes.primaryAction} onToggle={onToggle} />
            <div className={classes.content} data-editable={editable} data-collapsible={collapsible} onClick={onToggle}>
                <Typography className={classes.title} component="h2" noWrap>{title || untitled}</Typography>
                { description && <Typography className={classes.description} component="h3" noWrap>{description}</Typography> }
            </div>
            <SecondaryAction {...props} className={classes.actionbar} />
        </header>
    )

}

export default SectionHeader;
