import React from "react";

import { getAppIcon } from '@frontend-components/admin/es/resources/icons';

const icon = getAppIcon({fill: "blue", type: "border", text: "Ko"})

export default {
    sortOrder: 20,
    uniqueId: "app/kompass",
    appType: "site/management",
    collectionType: "site/kompass",
    name: "kompass",
    icon: icon,
    title: "Kompass"
};