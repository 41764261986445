import JSONSchema from './JSONSchema';
import uiSchema from './uiSchema';
import preview from './preview';

export default {
  schemaType: 'media',
  name: 'video',
  title: 'Media/Video',

  JSONSchema: JSONSchema,
  uiSchema: uiSchema,
  preview: preview  
}