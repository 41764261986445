import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { FinderSection, LayoutButtons, LayoutGallery } from "../components"
import ResultsModel from "./ResultsModel"
import ResultsLoader from "./ResultsLoader"
import ResultsHeader from "./ResultsHeader"
import ResultsFooter from "./ResultsFooter"

const ResultsGallery = ({layout = "gallery", mediaHeight = 120, mediaLayout = "cover", spacing = 1, padding = 2, ...props}) => {
    const { resultsLoaded = [], resultsByPage = {}, pages, page, onPage } = props

    return (
        <ResultsLoader {...props}>
            <LayoutGallery padding={padding} spacing={spacing}>
                { resultsLoaded.map((model, index) => {

                    let width, height;
                        
                    if (model.mediaWidth && model.mediaHeight) {
                        width = model.mediaWidth
                        height = model.mediaHeight
                    } else if (model.imageWidth && model.imageHeight) {
                        width = model.imageWidth
                        height = model.imageHeight
                    }

                    model = {
                        ...model,
                        width: Math.floor(mediaHeight * (width / height)) || mediaHeight,
                        mediaHeight: mediaHeight,
                        mediaLayout: mediaLayout
                    }

                    return (
                        <ResultsModel {...props} layout="gallery" model={model} width={model.width} key={index} />
                    )
                })}
            </LayoutGallery>
            <ResultsFooter {...props} />
        </ResultsLoader>
    )    
}

ResultsGallery.propTypes = {
    spacing: PropTypes.number,
    padding: PropTypes.number,
    models: PropTypes.array 
}

export default ResultsGallery;