import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AutocompleteBase from "./AutocompleteBase"
import Icon from '@material-ui/core/Icon';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import data from "./IconField.data.json"

const getOptions = () => {
    let options = []

    data.categories.map((category) => {
        category && category.icons && category.icons.map((icon) => {
            options.push({
                group: category.name,
                label: category.name + "/" + icon.name,
                value: icon.ligature
            })
        })
    })

    return options
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        "& * + *": {
            marginLeft: theme.spacing(2)
        }
    },
    icon: {
    },
    label: {
        fontFamily: "Akkurat, sans-serif"
    },
}));

const IconFieldOption = ({ value, label }) => {
    const classes = useStyles()
   
    return (
        <div className={classes.root}>
            <Icon className={classes.icon}>{value}</Icon>
            <Typography className={classes.label}>{label}</Typography>
        </div>
    )
}

const IconField = ({
    id,
    label = "Select icon",
    helperText,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder
}) => {

    const emptyValue = multiple ? [] : "";
    const options = getOptions()

    const handleChange = (event, option) => {
        onChange(option.value, event);
    }

    const renderOption = (option, state) => {
        return (
            <IconFieldOption {...option} />
        )
    }

    return (
        <AutocompleteBase
            id={id}
            placeholder={placeholder}
            multiple={multiple}
            options={options}
            groupBy={(option) => option.group}
            icon={value || "search"}
            label={label}
            helperText={helperText}
            value={typeof value === "undefined" ? emptyValue : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            renderOption={renderOption}
            onChange={handleChange}>
        </AutocompleteBase> 
    )

}

IconField.defaultProps = {
    autofocus: false,
};

IconField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default IconField;
