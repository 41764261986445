export default {
    "type": "string",
    "enum": [
        "sound",
        "music"
    ],
    "enumNames": [
        "Generell",
        "Musikk"
    ],
    "default": "sound"
}