import React, { Component } from 'react';

class PreviewLabel extends React.Component {

  render() {
      
    const { id, type, schema } = this.props;
    
    if (!id) {
        return false
    }
    
    const label = id.replace(/_/gi, ".");
    
    return (

      <h2 className="field__label"><b className="field__id">{ label }</b> <em className="field__type">{ type }</em></h2>             
    
    )
  
  }
  
}

export default PreviewLabel;