import React, { Component } from 'react';
import { FinderHeader, FinderTitle, FinderLabel } from "../";
import "./InboxSection.scss";

class InboxSection extends Component {

    onToggle = () => {
        if (this.props.onToggle) {
            this.props.onToggle()
        }
    }
    
    renderTitle = () => {
        const { title, label } = this.props;
        
        if (title) {
            return (
                <FinderTitle title={title} />
            )
        }
        
        if (label) {
            return (
                <FinderLabel label={label} />
            )
        }
        
        return false

        
    }

    renderHeader = () => {
        const { collapsible, expanded, title, label } = this.props;
        
        if (!title && !label) {
            return false
        }
        
        if (collapsible) {
            return (
                <FinderHeader expanded={expanded} onClick={this.onToggle}>
                    { this.renderTitle() }
                </FinderHeader>
            )
        }
        
        return (
            <FinderHeader expanded={expanded} onClick={this.onToggle}>
                { this.renderTitle() }
            </FinderHeader>
        )
        
    }

    render() {
        const { collapsible, expanded, title } = this.props;

        if (collapsible && !expanded && title) {
            return (
                <section className="admin-inbox__section" aria-expanded={expanded}>
                    { this.renderHeader() }
                </section>
            )
        }

        return (
            <section className="admin-inbox__section" aria-expanded={expanded}>
                { this.renderHeader() }
                {this.props.children}
            </section>
        )

    }
}

export default InboxSection;