export default {
    "type": "object",
    "properties": {
        "parentId": {
            "type": "string"
        },
        "title": {
            "type": "string"
        },
        "parent": {
            "type": "object",
            "properties": {
                "parentId": {
                    "type": "string"
                }
            }
        },
        "header": {
            "type": "object",
            "properties": {
                "image": {
                    "type": "image"
                },
                "title": {
                    "type": "localizedString"
                },
                "leadtext": {
                    "type": "localizedString"
                },
                "bodytext": {
                    "type": "localizedString"
                }
            }
        },
        "hasArticles": {
            "type": "array",
            "items": {
                "type": "reference",
                "documentType": "article",
                "referenceType": "topicHasArticles",
                "_reverseReferenceType": "articleIsPartOf",
                "_reverseReference": "isPartOf[]",
                "imageCropdata": true,
                "imageFocalpoint": true,
                "imageAdjust": true,
            }
        }
    }
}