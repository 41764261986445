import React, { Component } from 'react';

import {
    WidgetBody,
} from "../"

class LayoutWidgetBody extends Component {

    render() {
        return (
            <WidgetBody expanded={true}>
                { this.props.children }
            </WidgetBody>
        )

    }

}

export default LayoutWidgetBody;