import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearch } from '../redux/functions/search';
import { useTranslation } from 'react-i18next';

import DialogTabs from "./DialogTabs"
import DocumentsResults from "./DocumentsResults"

import { FinderSection } from "../components"


import qs from 'query-string';

const DocumentsQuery = ({schema, formData, onChange, onClose, query, searchById, ...props}) => {
    const { t, i18n } = useTranslation(['dialog']);

    const getSection = (documentType) => {

        if (documentType === "ekultur") {

            return {
                title: documentType,
                query: {
                    models: documentType,
                    id: query.id + "/" + documentType + "/" + query.q,
                    siteId: query.siteId,
                    collectionId: query.collectionId,
                    rows: query.rows,
                    q: query.q
                }
            }
                
        }

        if (documentType === "*") {

            return {
                title: "documents",
                query: {
                    ...query,
                    id: query.id + "/" + documentType + "/" + query.q,
                    documentType: documentType
                }
            }
    
        }

        return {
            title: documentType,
            query: {
                ...query,
                id: query.id + "/" + documentType + "/" + query.q,
                documentType: documentType
            }
        }

    }

    let sections = [];

    const {documentType} = query

    if (documentType) {

        if (Array.isArray(documentType)) {
            sections = documentType.map(type => {
                return getSection(type)
            })
        } else {
            sections = [getSection(query.documentType)]
        }

    } else {
        sections = [getSection("*")]
    }

    const [tabIndex, setTabIndex] = useState(0)
    const currentTab = sections[tabIndex]

    const getSearchCount = () => {

        sections.map((section, index) => {
            const { query } = section;

            if (query.models === "documents" && tabIndex !== index) {
                props.getSearch({...query, rows: 0});
            }

        })
        
    }

    useEffect(() => {
        getSearchCount()
    }, [query.q])

    /*

    const getSearch = () => {

        sections.map((section, index) => {
            const { query } = section;
            props.getSearch(query);
        })

    }

    useEffect(() => {
        getSearch()
    }, [query.q])

    */

    const tabs = sections.map((section, index) => {
        const { title, query } = section;

        const count = searchById && searchById[query.id] && searchById[query.id].count;

        return {
            title: t(title),
            count: count,
            onClick: () => setTabIndex(index)
        }

    })

    // search current tab

    const getSearch = () => {
        currentTab && props.getSearch(currentTab.query);
    }

    useEffect(() => {
        getSearch()
    }, [tabIndex, query.q])



//    const [tabIndex, setTabIndex] = useState(0)
//    const currentTab = sections[tabIndex]
    
    // renderResults

    return (
        <FinderSection>
            <header>
                <DialogTabs tabs={tabs} />
            </header>
            <DocumentsResults 
                schema={schema}
                formData={formData}
                onChange={onChange}
                query={currentTab.query}
                layout={currentTab.layout} />
        </FinderSection>
    )

}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getSearch,
    }, 
dispatch);

const mapStateToProps = (state) => {
	return {
        searchById: state.searchById
	};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentsQuery);