import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"
import { getUrlFromProps } from "../../resources/functions/getUrlFromProps"

import ModelsTable from "./ModelsTable"
import ModelsList from "./ModelsList"
import ModelsGrid from "./ModelsGrid"
import ModelsGallery from "./ModelsGallery"

class LayoutModels extends Component {

    static propTypes = {
        models: PropTypes.arrayOf(
            PropTypes.shape({
                documentType: PropTypes.string,
                mediaType: PropTypes.string,
                imageUrl: PropTypes.string,
                title: PropTypes.string,
                description: PropTypes.string,
                label: PropTypes.string,
                metadata: PropTypes.array,
                createdAt: PropTypes.string,
                updatedAt: PropTypes.string
            })
        ).isRequired,
        layout: PropTypes.string,
        padding: PropTypes.number,
        spacing: PropTypes.number,
        groups: PropTypes.object,
        sortBy: PropTypes.string,
        sortOrder: PropTypes.string,
        groupBy: PropTypes.string,
        groupOrder: PropTypes.string,
        moduleFooter: PropTypes.bool,
        moduleAction: PropTypes.array,
        urlPattern: PropTypes.string,
        editUrlPattern: PropTypes.string,
        refsUrlPattern: PropTypes.string
    };

    static defaultProps = {
        models: [],
        groups: undefined,
        sortBy: undefined,
        sortOrder: 'ascending',
        groupBy: undefined,
        groupOrder: undefined
    };

    state = {
        models: [],
        modelsById: {},
        layout: undefined,
        groups: undefined,
        sortBy: undefined,
        sortOrder: undefined
    }

    componentDidMount() {
        this.getModels()
        this.getLayout()
    }

    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.layout !== this.props.layout) {
            this.getLayout()
        }

        if (prevProps.models !== this.props.models) {
            this.getModels()
        }

        if (prevState.sortBy !== this.state.sortBy) {
            this.sortModels()
        }

    }

    getLayout = () => {
        const { layout } = this.props;

        this.setState({
            layout: layout
        })

    }

    getMetadata = (model) => {
        let { metadata, size, mediaWidth, mediaHeight, mediaSize } = model;

        if (metadata) {
            return metadata
        }

        metadata = []

        size = size || mediaWidth && mediaHeight && mediaWidth + "×" + mediaHeight || undefined;

        if (size) {
            metadata.push(size)
        }
        
        return metadata.join(' – ');

    }

    getByline = (model) => {

        const { deletedAt, deletedByName, updatedAt, updatedByName, createdAt, createdByName } = model

        let { author, datetime } = model;

        if (!datetime && deletedAt) {
            datetime = deletedAt;
        } else if (!datetime && updatedAt) {
            datetime = updatedAt;
        } else if (!datetime && createdAt) {
            datetime = createdAt;
        }
        
        if (author) {
            author = author;
        } else if (deletedAt && deletedByName) {
            author = deletedByName;
            datetime = deletedAt;
        } else if (updatedAt && updatedByName) {
            author = updatedByName;
            datetime = updatedAt;
        } else if (createdAt && createdByName) {
            author = createdByName;
            datetime = createdAt;
        }

        return {
            author: author || "N/A",
            datetime: datetime
        }

    }

    getLabel = (model) => {
        let { label, documentType, mediaType, mimeType } = model;
        return label || mediaType || documentType || undefined;
    }

    getSortSize = (model) => {
        let { size, sortSize, mediaWidth, mediaSize } = model;
        return sortSize || mediaSize || mediaWidth && mediaWidth && mediaWidth * mediaWidth || undefined;
    }

    onEdit = (model) => {
        console.log('LayoutModels:onEdit', model)
        if (model.onEdit) {
            model.onEdit(model)
        } else if (this.props.onEdit) {
            this.props.onEdit(model)
        }
    }

    onView = (model) => {
        console.log('LayoutModels:onView', model)
        if (model.onView) {
            model.onView(model)
        } else if (this.props.onView) {
            this.props.onView(model)
        }
    }

    onRefs = (model) => {
        console.log('LayoutModels:onRefs', model)
        if (model.onRefs) {
            model.onRefs(model)
        } else if (this.props.onRefs) {
            this.props.onRefs(model)
        }
    }

    onDelete = (model) => {
        console.log('LayoutModels:onDelete', model)
        if (model.onDelete) {
            model.onDelete(model)
        } else if (this.props.onDelete) {
            this.props.onDelete(model)
        }
    }

    onRestore = (model) => {
        console.log('LayoutModels:onRestore', model)
        if (model.onRestore) {
            model.onRestore(model)
        } else if (this.props.onRestore) {
            this.props.onRestore(model)
        }
    }

    onErase = (model) => {
        console.log('LayoutModels:onErase', model)
        if (model.onErase) {
            model.onErase(model)
        } else if (this.props.onErase) {
            this.props.onErase(model)
        }
    }

    onToggle = (model) => {
        console.log('LayoutModels:onToggle', model)
        if (model.onToggle) {
            model.onToggle(model)
        } else if (this.props.onToggle) {
            this.props.onToggle(model)
        }
    }

    onAdd = (model) => {
        console.log('LayoutModels:onAdd', model)
        if (model.onAdd) {
            model.onAdd(model)
        } else if (this.props.onToggle) {
            this.props.onAdd(model)
        }
    }

    onRemove = (model) => {
        console.log('LayoutModels:onRemove', model)
        if (model.onRemove) {
            model.onRemove(model)
        } else if (this.props.onRemove) {
            this.props.onRemove(model)
        }
    }

    onSelect = (model) => {
        console.log('LayoutModels:onSelect', model)
        if (model.onSelect) {
            model.onSelect(model)
        } else if (this.props.onSelect) {
            this.props.onSelect(model)
        }
    }

    onUnselect = (model) => {
        console.log('LayoutModels:onUnselect', model)
        if (model.onUnselect) {
            model.onUnselect(model)
        } else if (this.props.onUnselect) {
            this.props.onUnselect(model)
        }
    }

    getToolbar = (model) => {
        const { status, selected } = model;
        const moduleAction = model.moduleAction || this.props.moduleAction;

        let toolbar = []

        // edit, view, refs

        if (moduleAction.includes('edit')) {
            toolbar.push({
                name: "edit",
                onClick: () => this.onEdit(model)
            })
        }

        if (moduleAction.includes('view')) {
            toolbar.push({
                name: "view",
                onClick: () => this.onView(model)
            })
        }

        if (moduleAction.includes('refs')) {
            toolbar.push({
                name: "refs",
                onClick: () => this.onRefs(model)
            })
        }

        // delete + restore

        if (moduleAction.includes('delete') && status === "trash" || moduleAction.includes("restore")) {
            toolbar.push({
                name: "restore",
                onClick: () => this.onRestore(model)
            })
        } else if (moduleAction.includes("delete")) {
            toolbar.push({
                name: "delete",
                onClick: () => this.onDelete(model)
            })
        }

        // erase

        if (moduleAction.includes('erase') && status === "trash") {
            toolbar.push({
                name: "erase",
                onClick: () => this.onErase(model)
            })
        }

        // select + unselect

        /*

        if (moduleAction.includes('select') && selected || moduleAction.includes("unselect")) {
            toolbar.push({
                name: "unselect",
                onClick: () => this.onUnselect(model)
            })
        } else if (moduleAction.includes("select")) {
            toolbar.push({
                name: "unselect",
                onClick: () => this.onSelect(model)
            })
        }        

        */

        // add + remove

        if (moduleAction.includes('add') && selected || moduleAction.includes("remove")) {
            toolbar.push({
                name: "remove",
                onClick: () => this.onRemove(model)
            })
        } else if (moduleAction.includes("add")) {
            toolbar.push({
                name: "add",
                onClick: () => this.onAdd(model)
            })
        }

        // toggle

        /*

        if (moduleAction.includes('toggle') && selected) {
            toolbar.push({
                name: "selected",
                onClick: () => this.onToggle(model)
            })
        } else if (moduleAction.includes('toggle')) {
            toolbar.push({
                name: "select",
                onClick: () => this.onToggle(model)
            })
        }

        */

        return toolbar
        
    }

    getModel = (model) => {
        const { urlPattern, editUrlPattern, refsUrlPattern } = this.props;
        const { url, editUrl, refsUrl } = model;
        
        const moduleFooter = model.moduleFooter || this.props.moduleFooter;
        const moduleAction = model.moduleAction || this.props.moduleAction;

        const { author, datetime } = this.getByline(model);

        let selectable, onSelect

        if (moduleAction && moduleAction.includes('select')) {
            onSelect = () => this.onSelect(model)
            selectable = true
        }

        let onClick;

        if (selectable && moduleAction.length === 1) {
            onClick = onSelect
        }
        
        return {
            ...model,
            selectable: selectable,
            onSelect: onSelect,
            label: this.getLabel(model),
            description: this.getMetadata(model),
            author: author,
            datetime: datetime,
            sortSize: this.getSortSize(model),
            toolbar: this.getToolbar(model),
            url: url || getUrlFromProps(model, urlPattern) || undefined,
            editUrl: editUrl || getUrlFromProps(model, editUrlPattern) || undefined,
            refsUrl: refsUrl || getUrlFromProps(model, refsUrlPattern) || undefined,
            moduleFooter: moduleFooter,
            moduleAction: moduleAction,
            onClick: onClick,
            onEdit: () => this.onEdit(model),

            /*
            onEdit: () => this.onEdit(model),
            onDelete: () => this.onDelete(model),
            onRestore: () => this.onRestore(model),
            onToggle: () => this.onToggle(model),
            onSelect: () => this.onSelect(model),
            onUnselect: () => this.onUnselect(model),
            onAdd: () => this.onAdd(model),
            onRemove: () => this.onRemove(model),
            */
        }

    }

    getModels = () => {
        const { models, sortBy, sortOrder, groupBy } = this.props;

        let modelsById = {}

        models.map((model, index) => {
            const { uniqueId, source, sourceId } = model;
            const id = uniqueId || source && sourceId && source + "/" + sourceId || index || undefined
            modelsById[id] = this.getModel(model)
        })

        this.setState({
            modelsById: modelsById,
            models: Object.values(modelsById),
            sortBy: sortBy,
            sortOrder: sortOrder,
            groupBy: groupBy
        })

    }

    sortModels = () => {
        let { models, sortBy, sortOrder, groupBy, groups } = this.state;

        if (sortBy && sortOrder) {
            models = _.orderBy(models, sortBy, sortOrder); // Use Lodash to sort array by 'name'
        }

        if (groupBy) {
            groups = _.groupBy(models, groupBy); // Use Lodash to sort array by 'name'
        }

        this.setState({
            models: models,
            groups: groups
        })

    }

    onSort = (sortBy, sortOrder) => {
        
        if (this.props.onSort) {
            this.props.onSort(sortBy, sortOrder)
        }
        
        let { models } = this.state;
        
        models = _.orderBy(models, sortBy, sortOrder); // Use Lodash to sort array by 'name'
        
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
            models: models
        })
        
    }

    render() {
        const { padding, spacing } = this.props;
        const { layout, models, groups, sortBy, sortOrder } = this.state;

        const layouts = {
            "table": ModelsTable,
            "list": ModelsList,
            "grid": ModelsGrid,
            "gallery": ModelsGallery,
        }
        
        let ModelsTemplate;

        if (layout && layouts[layout]) {
            ModelsTemplate = layouts[layout]
        } else {
            ModelsTemplate = layouts['table']
        }

        return (
            <ModelsTemplate models={models} padding={padding} spacing={spacing} groups={groups} onSort={this.onSort} sortBy={sortBy} sortOrder={sortOrder} />
        )

    }
}

export default LayoutModels;