import mediaLayout from "./mediaLayout"

const query = {
    "type": "object",
    "properties": {
        "mediaType": {
            "type": "array",
            "items": {
                "type": "string",
                "enum": [
                    "image",
                    "video",
                    "audio",
                ],
                "enumNames": [
                    "Bilder",
                    "Video",
                    "Audio",
                ]
            },
            "uniqueItems": true
        }
    }
}

export default {
    "properties": {
        "type": {
            "enum": ["media/upload"]
        },
        "pathname": {
            "type": "string",
        },
        "query": query,
        "layout": {
            "type": "string",
            "default": "uploads",
            "readonly": true
        }    
    }
}