export default {
    type: 'object',
    properties: {
        enabled: {
            type: "boolean"
        },
        variant: {
            "type": "string",
            "enum": ["default","grow"],
        },
        placeholder: {
            type: "string"  
        }
    }
}