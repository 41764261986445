import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SelectIcon from '@material-ui/icons/Check';
import SelectedIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
    }
}));

const NavSelectable = ({selected = false, onClick}) => {
    const classes = useStyles();

    if (selected) {
        return (
            <IconButton className={classes.root} color="inherit" aria-label="select" onClick={onClick}>
                <SelectedIcon />
            </IconButton>
        )
    }

    return (
        <IconButton className={classes.root} color="inherit" aria-label="select" onClick={onClick}>
            <SelectIcon />
        </IconButton>
    )

}

export default NavSelectable;