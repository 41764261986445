import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"
import MenuList from "./ActionMenuList"
import MenuItem from "./ActionMenuItem"
import MenuLink from "./ActionMenuLink"

class NavActionMenu extends Component {

    static propTypes = {
        currentUrl: PropTypes.string,
        menu: PropTypes.array,
        menuByUrl: PropTypes.object,
        onSelect: PropTypes.func
    }

    static defaultProps = {
        selectedUrl: undefined,
        menu: [],
        menuByUrl: {},
        datetime: ""
    }

    state = {
        menuByUrl: {}
    }

    componentDidMount() {
        this.getMenuByUrl()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.menuByUrl !== this.props.menuByUrl) {
            this.getMenuByUrl()
        }
        if (prevProps.currentUrl !== this.props.currentUrl) {
            this.getCurrentUrl()
        }
    }

    getMenuByUrl = () => {
        const { menuByUrl } = this.props;

        this.setState({
            menuByUrl: menuByUrl
        })

    }

    getCurrentUrl = () => {
        const { currentUrl } = this.props;

        this.selectMenuByUrl(currentUrl)

    }

    selectMenuByUrl = (url) => {
        let { menuByUrl } = this.state;

        this.setState({
            currentUrl: url,
            menuByUrl: {
                ...menuByUrl,
                [url]: {
                    ...menuByUrl[url],
                    expanded: true,
                }
            }
        })

    }

    getMenuItem = (item) => {
        const { menuByUrl, currentUrl } = this.state;

        const selected = item.url && currentUrl == item.url || item.selected || false;

        if (item.url && menuByUrl[item.url]) {
            item = {
                ...item,
                ...menuByUrl[item.url],
                selected: selected
            }
        }

        return item;

    }

    onSelect = (item) => {
        const { url } = item;

        url && this.selectMenuByUrl(url);

        if (this.props.onSelect) {
            this.props.onSelect(item)
        }

    }

    onToggle = (item) => {
        const { url, expanded } = item;
        const { menuByUrl } = this.state;

        this.setState({
            menuByUrl: {
                ...menuByUrl,
                [url]: {
                    ...item,
                    ...menuByUrl[url],
                    expanded: !expanded
                }
            }
        })

        if (this.props.onToggle) {
            this.props.onToggle(item)
        }

    }

    renderLink = (item) => {
        const { selected, url, icon, title, label, description, count } = item;

        return (
            <MenuLink selected={selected} url={url} icon={icon} label={label || title} description={description} count={count} onClick={() => this.onSelect(item)} />
        )

    }
    
    renderItem = (item, index) => {
        item = this.getMenuItem(item)

        const { role, children, hidden, selected } = item;

        const expanded = true

        if (hidden) {
            return (
                <MenuItem role={role}>
                    <MenuList role={role}>
                    { children && children.map(this.renderItem)}
                    </MenuList>
                </MenuItem>
            )
        }

        if (children) {
            return (
                <MenuItem role={role || "treeitem"} selected={selected} expanded={expanded} onToggle={() => this.onToggle(item)}>
                    { this.renderLink(item) }
                    { children && expanded && 
                        <MenuList role="tree">
                        { children && children.map(this.renderItem)}
                        </MenuList>
                    }
                </MenuItem>
            )
        }

        return (
            <MenuItem {...item} role={role || "none"} key={index}>
                { this.renderLink(item) }
            </MenuItem>
        )

    }
    
    render() {
        const { className, menu } = this.props;

        if (!menu || !menu.length) {
            return false
        }

        return (
            <nav className={className}>
                <MenuList role="tree">
                    { menu.map(this.renderItem)}
                </MenuList>
            </nav>
        )
    }

}

export default NavActionMenu;
