import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModuleBase from "../Module/ModuleBase"
import ModuleBaseLink from "../Module/ModuleLink"
import ModuleActionPrimary from "../Module/ModuleActionPrimary"
import ModuleActionbar from "../Module/ModuleActionbar"


import ModuleMedia from '../Module/ModuleMedia';
import ModuleTitle from '../Module/ModuleTitle';
import ModuleLabel from '../Module/ModuleLabel';
import ModuleDescription from '../Module/ModuleDescription';
import ModuleStatus from '../Module/ModuleStatus';
import ModuleByline from '../Module/ModuleByline';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        width: "100%",
        minHeight: theme.spacing(7),
        justifyContent: "flex-start",
        alignItems: "center",
        userSelect: "none",

        "&[data-elevated=true]": {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },

        "& + $root": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider
        },

        "&.sortable-helper": {
            borderTopColor: "transparent",
            boxShadow: theme.shadows[12],
            zIndex: 2000,
            "& $trailingIcons": {
                display: "none"
            }
        },

        "&[data-status=trash]": {

            "& figure": {
                opacity: "0.5"
            },

            "& $content": {
                opacity: "0.5"
            }

        },

    },
    trailingIcons: {
        display: "flex",
    },
    link: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        userSelect: "none",
    },
    body: {
    },
    media: {
        display: "flex",
    },
    content: {
        width: "100%",
        maxWidth: "100%",
        flexDirection: "column",
        overflow: "hidden",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        margin: theme.spacing(1)
    },
    header: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        "& * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
    metadata: {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        "& * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
}));

const SubtitlesModule = ({ imageUrl, untitled, title, status, statusLabel, author, datetime, onClick, onEdit, ...props }) => {

    const classes = useStyles()

    return (
        <ModuleBase {...props} className={classes.root} status={status} >
            <ModuleBaseLink className={classes.link} onClick={onClick}>
                <ModuleActionPrimary {...props} />
                <div className={classes.content}>
                    <header className={classes.header}>
                        <ModuleTitle status={status} untitled={untitled} title={title} onClick={!onClick && onEdit} />
                        Subtitles
                    </header>
                </div>
                <ModuleActionbar {...props} className={classes.trailingIcons} />
            </ModuleBaseLink>
        </ModuleBase>
    )    

}

SubtitlesModule.propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    typeLabel: PropTypes.string,
    status: PropTypes.string,
    statusLabel: PropTypes.string,
    author: PropTypes.string,
    datetime: PropTypes.string,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
}

SubtitlesModule.defaultProps = {
    size: "small"
}

export default SubtitlesModule;
