import React, { useState, useEffect } from 'react';
import { CustomPicker } from 'react-color';
import ColorInput from "./ColorInput"
import ColorSwatches from "./ColorSwatches"
import ColorHelper from 'react-color/lib/helpers/color'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    picker: {
        width: theme.spacing(25),
        display: "flex",
        flexDirection: "column",
    },
    input: {
        fontFamily: "Akkurat Mono, monospace",
        backgroundColor: props => { return props.backgroundColor },
        color: props => {  return props.color },
        minHeight: theme.spacing(14),

        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& input": {
            border: "none",
            backgroundColor: "transparent",
            color: "inherit",

            fontFamily: "inherit",
            fontSize: "inherit",
            lineHeight: "1",

            width: "100%",
//            minHeight: theme.spacing(7),
            textAlign: "center"
        }

    }
}));

const Colorpicker = ({variant = "default", hex, rgb, hsl, hsv, backgroundColor, colors = [], onChange}) => {

    const getContrastColor = () => {

        if (!ColorHelper.isValidHex(hex)) {
            return false
        }

        if (hex === 'transparent') {
            return 'rgba(0,0,0,0.4)'
        }

        const yiq = ((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000
        return (yiq >= 128) ? '#000' : '#fff'
            
    }    

    const contrastColor = getContrastColor()
    let color;

    if (variant === "text") {
        backgroundColor = backgroundColor || contrastColor
        color = hex
    } else {
        backgroundColor = hex
        color = contrastColor
    }

    const classes = useStyles({backgroundColor: backgroundColor, color: color})

    return (
        <div className={classes.picker}>
            <ColorInput className={classes.input} variant={variant} color={hex} onChange={onChange} />
            { colors && <ColorSwatches variant={variant} backgroundColor={backgroundColor} color={hex} colors={colors} onChange={onChange} /> }
        </div>
    )


}

export default CustomPicker(Colorpicker);