export default {
    "type": "object",
    "readonly": true,
    "properties": {
        "uniqueId": {
            "type": "string"
        },
        "source": {
            "type": "string"
        },
        "sourceId": {
            "type": "string"
        },
        "documentType": {
            "type": "string"
        },
        "imageUrl": {
            "type": "string"
        },
        "title": {
            "type": "string"
        },
        "content": {
            "type": "object",
            "properties": {
                "owner": {
                    "type": "string"
                },
                "ownerName": {
                    "type": "string"
                },
            }
        }
    }
}