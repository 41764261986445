import React, { useRef, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ImageCropperBase from "./ImageCropperBase"

const useStyles = makeStyles(theme => ({
    root: {
//        backgroundColor: "#333",
//        backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC')",
//        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    cropper: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
//        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(6),

        "& .cropper-container": {
            backgroundImage: "none"
        },
        "& .cropper-modal": {
            backgroundColor: "inherit"
        }

    },
    footer: {
        position: "absolute",
        top: "auto",
        right: 0,
        left: 0,
        bottom: 0,
        paddingTop: theme.spacing(1),
        height: theme.spacing(5),
    },
    description: {
        fontSize: "12px",
        textAlign: "center"
    }
}));


const ImageCropper = ({className, imageUrl, imageCropdata = {}, onChange, ...props}) => {
    const classes = useStyles()

    const applyCrop = (imageCropdata) => {
        onChange && onChange(imageCropdata)
    }

    return (
        <div className={className || classes.root}>
            <ImageCropperBase className={classes.cropper} imageUrl={imageUrl} imageCropdata={imageCropdata} onChange={applyCrop} />
            <footer className={classes.footer}>
                <Typography nowrap className={classes.description}>
                    crop x free crop etc
                </Typography>
            </footer>
        </div>
    )

}

export default ImageCropper;