// SEARCH

export const REQUEST_MODEL = 'REQUEST_MODEL'
export const RECEIVE_MODEL = 'RECEIVE_MODEL'

export const REQUEST_MODEL_REFERENCES = 'REQUEST_MODEL_REFERENCES'
export const RECEIVE_MODEL_REFERENCES = 'RECEIVE_MODEL_REFERENCES'

export const RECEIVE_MODEL_STATUS = 'RECEIVE_MODEL_STATUS'

export function requestModel(id) {

  return {
    type: REQUEST_MODEL,
    id: id,
  }
  
}

export function receiveModel(id, data) {

    const source = data && data.source
    const sourceId = data && data.sourceId
  
    return {
        type: RECEIVE_MODEL,
        id: id,
        source: source,
        sourceId: sourceId,
        data: data,
    }
  
}

export function receiveModelStatus(id, status) {
  
    return {
        type: RECEIVE_MODEL_STATUS,
        id: id,
        status: status,
    }
    
}