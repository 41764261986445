import React, { useState, useEffect } from 'react';

import ResultsGallery from "./ResultsGallery"
import ResultsTable from "./ResultsTable"
import ResultsList from "./ResultsList"
import UploadResults from "./ResultsUploads"

import qs from 'query-string';


const templates = {
    "table": ResultsTable,
    "list": ResultsList,
    "grid": ResultsList,
    "gallery": ResultsGallery,
    "uploads": UploadResults 
}

const FinderResults = ({template, layout = "table", ...props}) => {

    let sq = props.location && props.location.search && qs.parse(props.location.search) || {}

    if (sq.layout) {
        layout = sq.layout
    }

    const ResultsTemplate = template && templates[template] || layout && templates[layout]

    return (
        <div>
            <ResultsTemplate {...props} />
        </div>
    )

}

export default FinderResults