export default {
    "type": "object",
    "properties": {
        "title": {
            "type": "string"
        },
        "description": {
            "type": "string"
        },
        "dtStart": {
            "type": "string",
            "format": "date-time"
        },
        "dtEnd": {
            "type": "string",
            "format": "date-time"
        }
    }
}