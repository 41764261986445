import React, { Component } from 'react';
import "./FieldRadio.scss"

class FieldRadio extends Component {
    static defaultProps = {
        value: "",
        required: false,
        disabled: false,
        readonly: false,
        autofocus: false,
    };

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    }

    onChange = (event) => {

        let value;
        
        if (event.target.checked) {
            value = this.props.value || true;
        } else {
            value = false;
        }

        if (this.props.onChange) {
            this.props.onChange(value, event);
        }
    }

    render() {
        const { id, name, checked, value, required, disabled, readonly, autofocus } = this.props;

        const label = this.props.title || this.props.label || "radio"

        return (
            
            <label className="admin-field  field--radio">
            
                <input type="radio"
                    id={id}
                    name={name}
                    checked={checked}
                    value={value}
                    required={required}
                    disabled={disabled || readonly}
                    autoFocus={autofocus}
                    onChange={(event) => this.onChange(event)}
                    onBlur={(event) => this.onBlur(event)}
                    onFocus={(event) => this.onFocus(event)}
                />

                <i className="radio__icon"></i>
                <b className="radio__label">{ label }</b>

            </label>
        )
    }
}

export default FieldRadio;