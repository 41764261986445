import _ from "lodash"
import { API } from '../settings';
import { 
    requestAuthApps, receiveAuthApps, receiveAuthAppByName, receiveAuthAppById,
    requestAuthSites, receiveAuthSites, receiveAuthSiteByName, receiveAuthSiteById,
    requestAuthSiteApps, receiveAuthSiteApps,
    requestAuthSiteCollections, receiveAuthSiteCollections,
    requestAuthUsers, receiveAuthUsers, receiveAuthUserById, 
    requestAuthRoles, receiveAuthRoles
} from '../actions/auth';

import { siteApps } from "../apps/"
import { getAppById, getAppAuthData } from "./authApps"

// SITES

export function getAuthSites() {
    const url = API + '/admin/api/sites/search';

    return function (dispatch) {
        dispatch(requestAuthSites())

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getAuthSitesData(data)),
        )

    }    
}

export function getAuthSitesData(data) {
    const { models } = data;
    
    let sites = []

    models.map(site => {
        sites.push(getSiteAuthData(site))
    })
    
    return function (dispatch) {
        dispatch(receiveAuthSites(sites))
        sites.map(site => {
            dispatch(getSiteById(site.id))
        })
    }
    
}

export function getSiteById(id) {
    
    const url = API + '/admin/api/sites/' + id;
  
    return function (dispatch) {

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getSiteAuthApps(id, data))
        )

    }

}

export function getSiteAuthApps(id, site) {
    const url = API + "/admin/api/collections/search?siteId=" + id;

    return function (dispatch) {

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getSiteAuthAppsData(id, site, data)),
        )

    }

}

export function getSiteAuthAppsData(id, site, data) {

    const models = data && data.models;
    
    let authApps = [], authAppsByName = {}, authAppsById = {}
    
    // apps

    Object.values(siteApps).map(app => {

        const appType = app.collectionType || undefined        

        models.map(collection => {
            const { collectionType, status } = collection;
            
            if (collectionType === appType) {
                app = {
                    ...collection,
                    ...app,
                }
            }

        })
        
        app = {
            ...app,
            siteId: site.id,
        }

        app = getAppAuthData(app)
        authApps.push(app)
        
        /*
        
        if (app.appId) {
            authAppsById[app.appId] = app
        }

        if (app.name) {
            authAppsByName[app.name] = app
            authApps.push(app)
        }
        */
        
    })
    
    // collections
    
    let authCollections = []
    
    models.map(collection => {

        if (collection.collectionType !== "exhibition") {
            return
        }
        
        collection = getAppAuthData(collection);

        if (collection.id) {
            authAppsById[collection.id] = collection
        }
        
        authCollections.push(collection)

    })
    
    
    site = {
        ...site,
        authApps: authApps,
        authCollections: authCollections,
    }
    
    return function (dispatch) {
        dispatch(getSiteAuthUsers(id, site))
    }
        
}

export function getSiteAuthUsers(id, site) {
    const url = API + '/admin/api/users/search';

    return function (dispatch) {

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getSiteAuthUsersData(id, site, data)),
        )

    }

}

export function getSiteAuthUsersData(id, site, data) {
    const { models } = data;
    
    let authUsers = []
    
    models.map(user => {
        const { authorizedSites } = user
        
        authorizedSites.map(authSite => {
            
            if (authSite.site.id === site.id) {
                authUsers.push({
                    ...user,
                    userId: user.id,
                    siteId: authSite.site.id,
                    roleId: authSite.role.id
                })
            }
            
        })
        
        
    })
    
    site = {
        ...site,
        authUsers: authUsers
    }
    
    site = getSiteAuthData(site)
    
    return function(dispatch) {
        dispatch(receiveAuthSiteById(id, site))
    }
    
}


export function getSiteAuthData(site) {
    const { id, name, authUsers, authApps, authCollections } = site

    let authAppsByName = {}, authAppsById = {}, authUsersById = {}, authCollectionsById = {}
    
    if (authApps && authApps.length) {
        authApps.map(app => {
            authAppsByName[app.appName] = app
            
            if (app.appId) {
                authAppsById[app.appId] = app
            }
        })
    }

    if (authUsers && authUsers.length) {
        authUsers.map(user => {
            authUsersById[user.userId] = user
        })
    }

    if (authCollections && authCollections.length) {
        authCollections.map(collection => {
            authCollectionsById[collection.collectionId] = collection
        })
    }

    
    return {
        ...site,
        authAppsByName: authAppsByName,
        authAppsById: authAppsById,
        authCollectionsById: authCollectionsById,
        authUsersById: authUsersById,
        siteId: id,
        siteName: name
    }
        
}
