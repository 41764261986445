import React from 'react';
import { getUiOptions } from "../utils/"

const SchemaFieldset = ({fieldset, ...props}) => {
    const { id, uiSchema, registry, onPropertyChange, onChange } = props;
    const { SchemaField } = registry.fields

    // name, selected
    const { name, selected } = fieldset

    // part of navigation?

    const uiOptions = getUiOptions(uiSchema)

    if (uiOptions.nav && !selected) {
        return null
    }

    // schema, uiSchema, formData
    const fieldId = fieldset && fieldset.id;
    const fieldSchema = fieldset && fieldset.schema;
    const fieldUiSchema = fieldset && fieldset.uiSchema;
    const fieldUiOptions = fieldset && fieldset.uiOptions;
    const fieldFormData = fieldset && fieldset.formData;

    /*
    */

    if (fieldSchema) {
        return (
            <SchemaField {...props}
                name={name}
                id={fieldId}
                idPrefix={id}
                schema={fieldSchema}
                uiSchema={fieldUiSchema} 
                formData={fieldFormData}
                isArrayItem={false}
                onChange={onPropertyChange(name)} />
        )
    }

    if (fieldUiSchema && fieldUiOptions.fieldset) {

        return (
            <SchemaField {...props}
                name={name}
                id={fieldId}
                idPrefix={id}
                uiSchema={fieldUiSchema}
                isArrayItem={false}
                onChange={onChange} />
        )
    }

    return null

}

export default SchemaFieldset;