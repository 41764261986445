import React, { Component } from 'react';
import ChartTooltip from "./ChartTooltip"

import { FormatMetric } from "../"

class ChartPieTooltip extends Component {

    static defaultProps = {
        data: [],
        colors: []
    }
    
    getColorByIndex = (index) => {
        const { colors } = this.props;
        
        if (!colors[index]) {
            index = index - colors.length
        }
        
        const color = colors && colors[index];
        
        
        return color
        
    }
    
    renderLegend = (data, index) => {
        const { colors } = this.props;
        let { label, value, metricType } = data;
        
        if (metricType) {
           value = <FormatMetric type={metricType} value={value} /> 
        }

        const color = this.getColorByIndex(index);
        
        const style = {
            backgroundColor: color
        }
        
        return (
            <li key={index}>
                <div className="admin-legend__chip" style={style}></div>
                <b className="admin-legend__label">{label}</b>
                <i className="admin-legend__value">{value}</i>
            </li>
        )
        
    }    
    
    render() {
        const { data } = this.props;
         
        return (
            <ul>
                { data.map(this.renderLegend) }
            </ul>
        )
    
    }

}

export default ChartPieTooltip