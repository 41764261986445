import React, { Component } from 'react';
import "./Document.scss";

class Document extends Component {

  render() {
    return (
      <div className="admin-document">
          { this.props.children }
      </div>
    )
  }
}

export default Document;