import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import InboxQuery from "./InboxQuery"
import InboxEditor from "./InboxEditor"

class AppRoutes extends Component {
  
    render() {
    
        return (
      
            <Switch>
                <Route path="/inbox/:inboxApp/:inboxOwner/:uniqueId" component={ InboxEditor } />
                <Route path="/inbox/:inboxApp/:inboxOwner" component={ InboxQuery } />
                <Route path="/inbox/:inboxApp" component={ InboxQuery } />
                <Route path="/inbox" component={ InboxQuery } />
            </Switch>

        )

    }

}

export default AppRoutes