import {defineMessages} from "react-intl";
import {type Messages} from "./MessageDescriptor"

const messages: Messages = {

  // yesterday

  ["yesterday"]: {
    id: "ga.widget.sessions.yesterday",
    defaultMessage: "Sessions yesterday",
  },
  ["yesterday.title"]: {
    id: "ga.widget.sessions.yesterday.title",
    defaultMessage: "{total} visits yesterday",
  },
  ["yesterday.up"]: {
    id: "ga.widget.sessions.yesterday.up",
    defaultMessage: "{change} more than the day before",
  },
  ["yesterday.down"]: {
    id: "ga.widget.sessions.yesterday.down",
    defaultMessage: "{change} less than the day before",
  },
  ["yesterday.flat"]: {
    id: "ga.widget.sessions.yesterday.flat",
    defaultMessage: "{change} less than the day before",
  },

  // 7 day

  ["7days"]: {
    id: "ga.widget.sessions.7days",
    defaultMessage: "7 day sessions",
  },
  ["7days.title"]: {
    id: "ga.widget.sessions.7days.title",
    defaultMessage: "{total} visited last week",
  },
  ["7days.description"]: {
    id: "ga.widget.sessions.7days.title",
    defaultMessage: "{average} per day",
  },
  ["7days.up"]: {
    id: "ga.widget.sessions.7days.up",
    defaultMessage: "{percent} up from last week",
  },
  ["7days.down"]: {
    id: "ga.widget.sessions.7days.down",
    defaultMessage: "{percent} down from last week",
  },
  
  // 28 days

  ["28days"]: {
    id: "ga.widget.sessions.28days",
    defaultMessage: "28 days sessions",
  },
  ["28days.title"]: {
    id: "ga.widget.sessions.28days.title",
    defaultMessage: "{average} sessions per day",
  },
  ["28days.up"]: {
    id: "ga.widget.sessions.28days.up",
    defaultMessage: "{percent} up vs. last period",
  },
  ["28days.down"]: {
    id: "ga.widget.sessions.28days.down",
    defaultMessage: "{percent} down vs. last period",
  },

  // YTD

  ["yearToDate"]: {
    id: "ga.widget.sessions.yearToDate",
    defaultMessage: "Year to date",
  },
  ["yearToDate.title"]: {
    id: "ga.widget.sessions.yearToDate.title",
    defaultMessage: "{total} sessions so far this year",
  },
  ["yearToDate.description"]: {
    id: "ga.widget.sessions.yearToDate.description",
    defaultMessage: "Thats an average of {average} per month",
  }

};

const definedMessages: typeof messages = defineMessages(messages);

export default definedMessages;