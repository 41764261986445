import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100%",
        padding: props => { return theme.spacing(props.padding) },
        display: "flex",

        "& > * + *": {
            flexGrow: 1,
            marginLeft: props => { return theme.spacing(props.spacing) },
        },

        "& > *:first-child": {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: props => { return props.minWidth },
            minHeight: props => { return props.minHeight },
        }

    }
}));

const LayoutThumbnail = ({minWidth = 164, minHeight = 164, padding, spacing = 2, children, ...props}) => {

    const classes = useStyles({minWidth, padding, spacing})

    return (
        <div className={classes.root}>
            {children}
        </div>
    )

}

export default LayoutThumbnail;