import { getAppData } from '../../redux/functions/app';
import { getAppCollection } from '../../redux/functions/appCollection';

//import schemas from '../schemas/';

export function getApp(props) {
    const { user, site, collection } = props;
    
    const appTitle = "Kalender"
    const appName = "calendar"
    const appRoot = "/calendar"
    
    props = {
        ...props,
        appTitle: appTitle,
        appName: appName,
        appRoot: appRoot,
    }
    
    const search = {
        placeholder: "Søk i kalender"
    }

    const primaryAction = {
        label: "Create"
    }

    const theme = {
        palette: {
            primary: {
                main: "#fff"
            },
            secondary: {
                main: "#ff0"
            }
        }
    }
    
    const app = {
        layout: "finder",
        root: appRoot,
        name: appName,
        title: "Kalender",
        theme: theme,
        header: {
            title: appTitle,
            subtitle: user.name || "N/A"
        },
        sidebar: {
            expanded: true,
        },
        search: search,
        primaryAction: primaryAction,
        menu: false,
        calendar: true,
        languages: [
            "no", "sv", "en", "de", "es", "fr"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
    }
    
}

export function getAppSearch(props) {
    const { appTitle } = props;
    
    return {
        "placeholder": "Søk i " + appTitle
    }
    
}

export function getAppMenu(props) {
    const { appRoot, collection } = props;
    
   return [
      {
        title: "Kalender",
        icon: "home",
        url: appRoot,
        calendar: true
      }
    ]

}