import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import CalendarFinder from './CalendarFinder';

class AppRoutes extends Component {
  
    render() {

        return (
            <Switch>
                <Route path="/:appName/:yyyy/:mm/:dd" component={ CalendarFinder } />
                <Route path="/:appName/:yyyy/:mm/" component={ CalendarFinder } />
                <Route path="/:appName/:yyyy" component={ CalendarFinder } />
                <Route path="/:appName" component={ CalendarFinder } />
            </Switch>
        )

    }

}

export default AppRoutes