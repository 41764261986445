import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsReportOwnersVsTotal } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

import { FormattedMessage } from "react-intl";
import messages from "./WidgetSessionsByOwner.messages";

class WidgetOwnersVsTotal extends Component {
    
    static defaultProps = {
        id: "/sessions/ownerVsTotal",
        widgetSize: "1:1",
        url: "/dm/analytics",
        scope: "7days",
        type: "line",
        stacked: true,
        query: {
            countryId: "no",
            offset: 0,
            owner: [6,14,27,32,1,21,31,30,4,5,50,39,16,7],
            reportType: 0,
            rows: 1000,
            startDate: "14daysAgo",
            endDate: "yesterday",
            metrics: "sessions",
            dimensions: "dimension1,nthDay",
            metrics: "sessions",
            orderBy: "nthDay",
            sort: "ascending",
            includeReports: "totals"
        }
    }
    
    state = {
        
    }
    
    componentDidMount() {
        this.getQuery()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.scope !== this.props.scope) {
            this.getQuery()
        }
        
    }
    
    getQuery = () => {
        const { id, widgetSize, scope, query, theme } = this.props;
        
        this.props.getReports({
            ...query,
            id: id
        })
        
    }

    render() {
        const { id, data, theme, scope } = this.props;

        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        const report2 = data[id] && data[id].reports && data[id].reports && data[id].reports[1]

        if (!report) {

            return (
                <AnalyticsReportOwnersVsTotal {...this.props} />
            )
            
        }
        
        const colors = [
            "rgb(64, 53, 118)",
            "rgb(33, 133, 177)",
            "rgb(179, 0, 33)",
            "rgb(216, 172, 38)"
        ]
        
        return (
            <AnalyticsReportOwnersVsTotal {...this.props} report={report || undefined} compareReport={report2 || undefined} dimension="dm:owner" />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetOwnersVsTotal);