import { Children, cloneElement } from 'react';

// Get a universally unique identifier
let count = 0;

export function uuid() {
  return `admin-tabs-${count++}`;
}

export function reset() {
  count = 0;
}

export function isTab(el) {
  
  return el.type && el.type.name === 'TabsTab';
  
//  return el.type && el.type.tabsRole === 'WindowTab';
}

export function isTabPanel(el) {

  return el.type && el.type.name === 'TabsTabpanel';

//  return el.type && el.type.tabsRole === 'TabPanel';
}

export function isTabList(el) {
  return el.type && el.type.tabsRole === 'TabList';
}

export function isTabChild(child) {
  return isTab(child) || isTabList(child) || isTabPanel(child);
}

export function isTabNode(node) {
  return 'getAttribute' in node && node.getAttribute('role') === 'tab';
}

export function isTabDisabled(node) {
  return node.getAttribute('aria-disabled') === 'true';
}

export function getTabsCount(children) {
  let tabCount = 0;
  deepForEach(children, child => {
    if (isTab(child)) tabCount++;
  });

  return tabCount;
}

export function getPanelsCount(children) {
  let panelCount = 0;
  deepForEach(children, child => {
    if (isTabPanel(child)) panelCount++;
  });

  return panelCount;
}

export function deepForEach(children, callback) {
  return Children.forEach(children, child => {
    // null happens when conditionally rendering TabPanel/Tab
    // see https://github.com/reactjs/react-tabs/issues/37
    if (child === null) return;

    if (isTab(child) || isTabPanel(child)) {
      callback(child);
    } else if (
      child.props &&
      child.props.children &&
      typeof child.props.children === 'object'
    ) {
      if (isTabList(child)) callback(child);
      deepForEach(child.props.children, callback);
    }
  });
}

export function deepMap(children, callback) {
  return Children.map(children, child => {
    // null happens when conditionally rendering TabPanel/Tab
    // see https://github.com/reactjs/react-tabs/issues/37
    if (child === null) return null;

    if (isTabChild(child)) {
      return callback(child);
    }

    if (
      child.props &&
      child.props.children &&
      typeof child.props.children === 'object'
    ) {
      // Clone the child that has children and map them too
      return cloneElement(child, {
        ...child.props,
        children: deepMap(child.props.children, callback),
      });
    }

    return child;
  });
}

export function getTablist(node) {
  return getTabsChildren(node, "tab")
}

export function getTabpanels(node) {
  return getTabsChildren(node, "tabpanel")
}

export function getTabsContainer(node) {

  let nodeAncestor = node.parentElement;

  do {

    if (nodeAncestor.getAttribute('role') === "dialog") {
      return nodeAncestor
    }

    nodeAncestor = nodeAncestor.parentElement;

  } while (nodeAncestor);
  
}

export function getTabsChildren(node, role, children = []) {

  let nodeChildren = node.childNodes;

  nodeChildren.forEach(nodeChild => {

    if (nodeChild.getAttribute && nodeChild.getAttribute('role') === role) {
      children.push(nodeChild)
    }
    
    if (nodeChild.childNodes) {
      children = getTabsChildren(nodeChild, role, children)
    }
    
  });
  
  return children;
  
}

export function getTabParent(node) {
  return node.parentNode;
}

export function getTabIndex(node) {

  const parent = node.parentNode.children;
  const children = node.parentNode.children;
  const container = getTabsContainer(node)
  
  const tabs = getTablist(container)
  const tabpanels = getTabpanels(container)
    
  const index = tabs.indexOf(node)

  console.log('node', node);
  console.log('parent', parent);
  console.log('children', children);
  console.log('container', container);
  console.log('tabs', tabs);
  console.log('tabpanels', tabpanels);

  console.log('index', index);
  
}

