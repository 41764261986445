import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchModel } from '../../redux/functions/model';

const SelectPlaceField = ({uniqueId, collection, search, queryId, schema, ...props}) => {

    const query = {
        collectionId: collection && collection.id,
        documentType: "place",
        fl: "id,parentId,uniqueId,title",
        q: "uniqueId:NOT " + uniqueId
    }

    const getPlaces = () => {
        props.searchModel('documents', queryId, query)
    }

    useEffect(() => {
        getPlaces()
    }, [])

    const [enumOptions, setEnumOptions] = useState([])
    const [enumNames, setEnumNames] = useState([])

    const getOptions = () => {
        const models = search && search[queryId] && search[queryId].models || []
        // const options = models.length && getOptionsTree(models) || []

        let optionsEnum = [], optionsNames = []

        models.map(model => {

            optionsEnum.push(model.id)
            optionsNames.push(model.title)

        })

        setEnumOptions(optionsEnum)
        setEnumNames(optionsNames)

    }

    useEffect(() => {
        getOptions()
    }, [search[queryId]])

    const { StringField } = props.registry.fields;

    const newSchema = {
        ...schema,
        enum: enumOptions,
        enumNames: enumNames
    }

    return (
        <StringField {...props} schema={newSchema} />
    )

}

SelectPlaceField.defaultProps = {
    queryId: 'select/parentId',
}

const mapStateToProps = (state) => {
	return {
        collection: state.collection,
        search: state.search,
        uniqueId: state.editor.uniqueId
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        searchModel
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectPlaceField);