// initialState

let initialState = {
};

// nav state

const nav = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_NAV':

      return Object.assign({}, state, {
      })

    case 'RECEIVE_NAV':
        
      return action.nav

    case 'RECEIVE_NAV_ITEM':

      return Object.assign({}, state, {
        [action.id]: action.item
      })

    case 'RECEIVE_NAV_COUNT':
    
      return {
        ...state,
        [action.id]: {
        ...state[action.id],
            count: action.count
        }
      }

    case 'RECEIVE_NAV_MENU':
    
      return {
        ...state,
        [action.id]: {
        ...state[action.id],
            menu: action.menu
        }
      }

      case 'RECEIVE_NAV_CHILDREN':
    
      return {
        ...state,
        [action.id]: {
        ...state[action.id],
            children: action.children
        }
      }

    case 'RECEIVE_FILTER':

      return {
        ...state,
        [action.id]: {
        ...state[action.id],
          query: {
            ...state[action.id].query,
            [action.name]: action.value
          }
        }
      }

    default:

      return state

    }

}

export default nav;