import authorizedSites from "./authorizedSites/schema"
import authorizedCollections from "./authorizedCollections/schema"

export default {
    "type": 'object',
    "properties": {
        "name": {
            "type": "string",
        },
        "title": {
            "type": "string",
        },
        "authUsername": {
            "type": "string",
        },
        "isSystemAdmin": {
            "type": "boolean",
        },
        "authorizedSites": authorizedSites,
        "authorizedCollections": authorizedCollections,
        "content": {
            "type": "object",
            "properties": {
                "avatar": {
                    "type": "image",
                },
                "defaultLocale": {
                    "type": "string",
                    "enum": [
                        "en",
                        "nb",
                        "nn",
                        "sv"
                    ],
                    "enumNames": [
                        "English",
                        "Norsk – Bokmål",
                        "Norsk – Nynorsk",
                        "Svensk"
                    ]
                }
            }        
        }
    }
}