import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./NavPreview.scss"

import { ButtonPreview } from '../';

class NavPreview extends Component {

    static defaultProps = {
        options: [
            {
                value: "p1",
                title: "Preview",
                icon: "code",
            },
            {
                value: "p2",
                title: "Preview 2",
                icon: "code"
            }
        ]
    }

    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired
        }))
    }

    state = {
        selected: this.props.options[0].value
    };

    onSelect = (value) => {
        this.setState({
            selected: value
        })

        if (this.props.onSelect) {
            this.props.onSelect(value)
        }
    }

    renderOption = (option, index) => {
        const { selected } = this.state;
        const { title, icon, value } = option;

        return (
            <ButtonPreview {...option} key={index} label={title} icon={icon} selected={selected === value} onClick={() => this.onSelect(value)} />
        )
    }

    render() {
        const { options } = this.props;

        return (
            <nav className="nav-preview" role="option">
                <div className="admin-buttongroup  admin-buttongroup--preview">
                    {options.map(this.renderOption)}
                </div>
            </nav>
        )
    }
}

export default NavPreview;