import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import AppLoader from "../App/AppLoader"

import MediaEditor from "./MediaEditor"

const MediaRoutes = ({site, collection, app, menuByUrl, ...props}) => {

    if (!site.uniqueId || !collection.uniqueId || !app.uniqueId) {
        return <AppLoader site={site} collection={collection} app={app}></AppLoader>
    }

    const { mediaType, uniqueId, parentId, referenceId } = props.match.params;

    const pathname = props.location.pathname;
    const urlParts = pathname.split('/');
    const urlAction = urlParts[urlParts.length-1]

    if (urlAction === "edit") {
        return (
            <MediaEditor {...props} />
        )
    }

    return (
        <Switch>
            <Route path="/:siteName/:appName/media/:mediaType/:uniqueId/edit" component={ MediaEditor } />
            <Route path="/:siteName/:appName/media/:uniqueId/edit" component={ MediaEditor } />
       </Switch>
    )

}

const mapStateToProps = (state) => {
	return {
    	site: state.site,
        collection: state.collection,
        app: state.app,
        menuByUrl: state.menuByUrl
	};
}  

export default connect(
    mapStateToProps,
)(MediaRoutes);