import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import MediaDialog from "./MediaDialog.example"
import ReferenceDialog from "./ReferenceDialog.example"

const DialogRoutes = (props) => {

    return (
        <Switch>
            <Route path="/:appName/reference/mix" render={ () => <ReferenceDialog query={{documentType: ["article","page","ekultur"]}} /> } />
            <Route path="/:appName/reference/article" render={ () => <ReferenceDialog query={{documentType: "article"}} /> } />
            <Route path="/:appName/reference" render={ () => <ReferenceDialog /> } />
            <Route path="/:appName/image" render={ () => <MediaDialog query={{mediaType: "image"}} /> } />
            <Route path="/:appName/media/video" render={ () => <MediaDialog query={{mediaType: ["video","youtube","vimeo","ekultur"]}} /> } />
            <Route path="/:appName/media/mix" render={ () => <MediaDialog query={{mediaType: ["image","video","audio","vimeo","youtube","sketchfab"]}} /> } />
            <Route path="/:appName/media/sketchfab" render={ () => <MediaDialog query={{mediaType: "sketchfab"}} /> } />
            <Route path="/:appName/media" component={ MediaDialog } />
            <Route path="/:appName" component={ MediaDialog } />
        </Switch>        
    )

}

export default DialogRoutes