import { requestReports, receiveReports } from '../actions/reports';
import { getQueryParams } from './query';

// GET MODEL

/*
?countryId=no&offset=0&owner=1,2,3,4&reportType=0&rows=50&startDate=2019-07-01,2018-07-01&endDate=2019-07-01,2018-07-01&orderBy=nthDay&metrics=uniquePageviews,users,sessions&dimensions=dimension1,dimension2,dimension3,dimension4,nthDay&sort=descending

*/

export function getReports(query) {
    const { id, reportType } = query;

    let url = "https://dm-admin-api.digitaltmuseum.no/v1/statistics/playground/reports/";
    
    if (reportType === "primusVsDm") {
        url = "https://dm-admin-api.digitaltmuseum.no/v1/statistics/primus_vs_dm/"
    }

    if (reportType === "live") {
        url = "https://dm-admin-api.digitaltmuseum.no/v1/statistics/playground/realtime/"
    }
  
    const params = getQueryParams(query);
  
    if (params) {
        url = "https://cors-anywhere.herokuapp.com/" + url + '?' + params;
    }
  
    return function (dispatch) {

        dispatch(requestReports(id))
        
        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(receiveReports(id, data)),
        )
    }
  
}