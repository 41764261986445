import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { FinderSection, LayoutButtons, LayoutGallery } from "../components"
import FinderModel from "./FinderModel"
import ResultsLoader from "./ResultsLoader"
import ResultsHeader from "./ResultsHeader"

import ResultsPagesPrev from "./ResultsPagesPrev"
import ResultsPagesNext from "./ResultsPagesNext"

const ResultsGallery = ({layout = "gallery", mediaHeight = 180, mediaLayout = "cover", spacing = 1, padding = 0, ...props}) => {
    const { resultsLoaded } = props

    return (
        <ResultsLoader {...props}>
            <FinderSection>
                <ResultsHeader {...props} />
                <ResultsPagesPrev {...props} />
                    <LayoutGallery padding={padding} spacing={spacing} debug={false}>
                        { resultsLoaded && resultsLoaded.map((model, index) => {

                            let width, height;
                                
                            if (model.mediaWidth && model.mediaHeight) {
                                width = model.mediaWidth
                                height = model.mediaHeight
                            } else if (model.imageWidth && model.imageHeight) {
                                width = model.imageWidth
                                height = model.imageHeight
                            }

                            model = {
                                ...model,
                                width: Math.floor(mediaHeight * (width / height)) || mediaHeight,
                                mediaHeight: mediaHeight,
                                mediaLayout: mediaLayout
                            }

                            return (
                                <FinderModel {...props} layout="gallery" model={model} width={model.width} key={index} />
                            )
                        })}
                    </LayoutGallery>
                <ResultsPagesNext {...props} />
                </FinderSection>
        </ResultsLoader>
    )    
}

ResultsGallery.propTypes = {
    spacing: PropTypes.number,
    padding: PropTypes.number,
    models: PropTypes.array 
}

export default ResultsGallery;