import React, { Component } from 'react';

import MediaBase from "./MediaBase"
import MediaButtonbar from "./MediaButtonbar"

import ImageAnnotations from "./ImageAnnotationsBase"

const NavSettings = () => (<div></div>)

class LayoutAnnotateImage extends Component {

    static defaultProps = {
        editable: false,
        editing: false,
        mediaId: undefined,
        media: {
            mediaType: undefined
        },
        imageAnnotations: [],
        buttons: []
    }

    onAnnotationsChange = (array) => {
        if (this.props.onChange) {
            this.props.onChange({
                ...this.props.formData,
                imageAnnotations: array
            })
        }
    }

    render() {
        const { media, mediaId, formData, editable, editing, settings, menu, onSave } = this.props;
        const { imageUrl, imageAnnotations } = formData

        if (!imageUrl) {
            
            return (
                <MediaBase {...this.props}>
                    <MediaButtonbar {...this.props} />
                </MediaBase>
            )

        }

        return (
            <React.Fragment>
                <MediaBase {...this.props} expanded={true}>
                    <ImageAnnotations imageUrl={imageUrl} imageAnnotations={imageAnnotations} onChange={this.onAnnotationsChange} />
                    <NavSettings {...this.props} settings={settings} />
                </MediaBase>

                { this.props.children }

            </React.Fragment>
        )

    }

}

export default LayoutAnnotateImage;