import React, { Component } from 'react';
import { ModuleLink, ModuleTitle, ModuleDescription, ModuleMetadata } from '../Module';
import { FormatMetric } from "../"
import { getUrlFromProps } from "../../resources/functions/"

class AnalyticsTableBodyRow extends Component {
    
    static defaultProps = {
        dimensions: [],
        metrics: [],
        urlPattern: undefined,
    }
    
    getClassName = () => {
        return "admin-analytics-table__row"
    }
    
    renderDimensions = () => {
        const { dimensions, thStyle, urlPattern } = this.props;
        
        let metadata = []
        
        if (dimensions.length >= 2) {
            metadata = dimensions.slice(1, dimensions.length)
        }
        
        const url = getUrlFromProps(this.props, urlPattern)
        
        if (url) {
            metadata.push(url)
        }

        const title = dimensions[0]
        const description = metadata.join('–')

        return (
            <th style={thStyle}>
                <ModuleLink url={url}>
                    <ModuleTitle title={dimensions[0]} />
                    <ModuleMetadata metadata={metadata} />
                </ModuleLink>
            </th>
        )

        return (
            <th style={thStyle}>
                {title}
                {description}
            </th>
        )

        
    }
    
    renderMetricValue = (metric, index) => {
        const { metrics, totals, sortBy, tdStyle } = this.props;
        const { name, type } = metric;
        const value = metrics[0].values[index];

        let selected;
        
        if (sortBy === name) {
            selected = true
        }
        
        const pctOfTotal = value / totals[index] * 100; 
        
        return (
            <td style={tdStyle} key={index} aria-selected={selected}>
                <b><FormatMetric {...metric} value={value} /></b>
                <i><FormatMetric type="PERCENT" value={pctOfTotal} /></i>
            </td>
        )
        
        
    }
    
    renderMetrics = () => {
        const { metricHeaders } = this.props;
        
        return metricHeaders.map(this.renderMetricValue)
        
    }
    
    render() {
        const className = this.getClassName();
        
        return (
            
            <tr className={className}>
                { this.renderDimensions() }
                { this.renderMetrics() }
            </tr>

        )
        
    }
    
}

export default AnalyticsTableBodyRow;