// initialState

let initialState = {
    expanded: false,
};

const sidebar = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'SIDEBAR_EXPAND':
    
            return {
                ...state,
                expanded: true
            }    
    
        case 'SIDEBAR_COLLAPSE':
            
            return {
                ...state,
                expanded: false
            }    

        default:
        
            return state

    }

}

export default sidebar;