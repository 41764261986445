import React, { Component } from 'react';

class Points extends Component {
    
    getClassName = (id, serieId) => {
        const { currentPoint, currentSlice } = this.props;

        let classNames = []
        
        const pointClass = "point--" + id.toString().replace('.', '-');
        const serieClass = "serie--" + serieId.toString().replace('.', '-');
        
        
        classNames.push('point')
        classNames.push(pointClass)
        classNames.push(serieClass)
        
        let isCurrent;

        if (currentPoint && currentPoint.id && currentPoint.id === id) {
            isCurrent = true
        }
        
        if (currentSlice && currentSlice.points) {
            
            const inCurrentSlice = currentSlice.points.filter(point => {
                if (point.id === id) {
                     return true
                }
            })
            
            if (inCurrentSlice.length) {
                isCurrent = true
            } 
            
        }
        
        if (isCurrent) {
            classNames.push('point--is-current')
        }
        
        return classNames.join(' ')
        
        
    }
    
    renderPoint = (point, index) => {
        const { id, serieId, serieColor, x, y } = point;
        
        const className = this.getClassName(id, serieId)
        const transform = "translate("+x+", "+y+")";

        return (
            <circle transform={transform} className={className} r="2" fill="transparent" stroke={serieColor} strokeWidth="4"></circle>
        )
        
    }

    render() {
        const { points } = this.props;
        
        return (
            <g className="points">
                { points.map(this.renderPoint) }
            </g>
        )
        
    }


}

export default Points