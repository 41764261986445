import React, { Component } from 'react';
import { ButtonHeader } from '../Button/';

class AdminHeaderAction extends Component {
    
    static defaultProps = {
        expanded: false
    }

    onToggle = (event) => {
    
        if (this.props.onToggle) {
            this.props.onToggle(event)
        }
    
    }

    render() {
        const { title, subtitle, expanded, collapseUrl } = this.props;
  	
      	return (
      		<section className="header__action" aria-expanded={expanded}>
      			<h2 className="header__title">{ title }</h2>
      			<h3 className="header__subtitle">{ subtitle }</h3>
                  <ButtonHeader action="close" icon="close" link={collapseUrl} onClick={ (event) => this.onToggle(event) } />
      		</section>
         )
    }
    
}

export default AdminHeaderAction;