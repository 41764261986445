// initialState

let initialState = {
    count: 0,
    list: [],
    models: [],
    formData: {}
}

const bulk = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_BULK':
        return initialState
      
    case 'RECEIVE_BULK':
        return {
            models: action.models,
            count: action.count
        }

    case 'BULK_ADD':
        return {
            list: [
                ...state.list,
                action.uniqueId
            ],
            models: [
                ...state.models,
                {
                    ...action.model,
                    selected: true
                }
            ],
            count: state.count + 1
        }

    case 'BULK_REMOVE':
        const list = state.list.filter(uniqueId => {
            return action.uniqueId !== uniqueId
        })

        const models = state.models.filter(model => {
            return action.uniqueId !== model.uniqueId
        })

        return {
            list: list,
            models: models,
            count: state.count - 1
        }

    case 'RECEIVE_BULK_EDIT': {
        return {
            ...state,
            formData: action.formData
        }
   }

    default:
        return state
    }

}

export default bulk;