export default {
    "ui:fieldset": [
        "imageAnnotations",
//        "imageCropdata",
//        "imageFocalpoint",
        "imageFilters"
    ],
    "imageAnnotations": {
        "ui:field": "kioskAnnotateImage",
        "ui:title": "Markeringer",
    },
    "ui:minHeight": 512,
    "ui:buttons": [
        {
            "type": "upload",
            "label": "Upload"
        },
        {
            "type": "sidebar",
            "label": "Finn bilde"
        }
    ]
}