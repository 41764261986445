export default {
    "ui:options": {
        "grid": true,
        "spacing": 2
    },
    "ui:fieldset": [
        "title",
        "description",
        "category",
        "alternativeText",
        "headline",
        "caption",
        "byline",
        "keywords"
    ],
    "title": {
        "ui:title": "Tittel",
        "ui:help": "Referansetittel. Brukes primært til gjenfinning."
    },
    "description": {
        "ui:title": "Beskrivelse",
        "ui:widget": "textarea",
        "ui:help": "Referansebeskrivelse. Brukes primært til gjenfinning."
    },
    "headline": {
        "ui:title": "Overskrift",
        "ui:help": "Bildetekst, overskrift."
    },
    "caption": {
        "ui:title": "Bildetekst",
        "ui:help": "Hvem, hva, hvor. Beskrivelse av innholdet.",
        "ui:widget": "textarea"
    },
    "alternativeText": {
        "ui:title": "Alternativ tekst",
        "ui:help": "Beskrivende tekst til bruk når bildet ikke kan vises.",
        "ui:widget": "textarea"
    },
    "category": {
        "ui:title": "Kategori",
        "ui:placeholder": "Velg kategori"
    },
    "byline": {
        "ui:title": "Byline",
        "ui:help": "Opphavsrett, person eller organisasjon"
    },
    "copyright": {
        "ui:title": "Copyright",
        "ui:help": "Beskrivelse av opphavsrett"
    },
    "keywords": {
        "ui:widget": "tags",
        "ui:title": "Nøkkelord",
        "ui:help": "Beskrivende stikkord.",
    },
    "license": {
        "ui:widget": "selectLicense",
        "ui:title": "Lisens",
        "ui:help": "Velg en lisens for dette innholdet.",
    }
}