export default {
    type: "string",
    enum: [
        "*",
        "NOT trash",
        "publish",
        "draft",
        "new",
        "trash",
    ],
//    default: "NOT trash"
}