export default {
    "type": "object",
    "properties": {
        "products": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "number",
                        "enum": [1,2,3,4,5],
                        "enumNames": ["Coca cola","Pepsi","Solo","Julebrus","Urge"]
                    },
                    "count": {
                        "type": "number",
                        "enum": [0,1,2,3,4,5],
                        "default": 1
                    }
                }
            }
        },
        "comments": {
            "type": "string"
        }
    }
}