import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsWidget, AnalyticsChart } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

class ChartSessionsByApp extends Component {
    
    static defaultProps = {
        id: "/sessions/owner",
        loadingTitle: "Sessions by app",
        title: "Sessions by app",
        tooltipLabel: ":ga:deviceCategory",
        widgetSize: "1:1",
        url: "/dm/analytics",
        scope: "7days",
        type: "bar",
        stacked: true,
        query: {
            countryId: "no",
            offset: 0,
            owner: [1,2,3,4,5],
            reportType: 0,
            rows: 1000,
            startDate: "7daysAgo",
            endDate: "yesterday",
            metrics: "sessions",
            dimensions: "nthDay,deviceCategory",
            metrics: "sessions",
            orderBy: "nthDay",
            sort: "ascending",
        }
    }
    
    componentDidMount() {
        this.getQuery()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.scope !== this.props.scope) {
            this.getQuery()
        }
        
    }
    
    getQuery = () => {
        const { id, widgetSize, scope, query, theme } = this.props;
        
        this.props.getReports({
            ...query,
            id: id
        })
        
    }
    
    render() {
        const { id, data, theme } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        if (!report) {

            return (
                <AnalyticsChart {...this.props} />
            )
            
        }
        
        return (
            <AnalyticsChart {...this.props} report={report || undefined} theme={theme} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChartSessionsByApp);