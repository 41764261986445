import React, { Component } from 'react';
import { ButtonHeader, NavSearch } from '../';

class AdminHeaderSearch extends Component {

    static defaultProps = {
        expanded: false
    }
    
    onSearch = (q, event) => {
        if (this.props.onSearch) {
            this.props.onSearch(q, event)
        }
    }

    onToggle = () => {
        if (this.props.onToggle) {
            this.props.onToggle()
        }
    }

    render() {
        const { expanded, placeholder, q, expandUrl, collapseUrl } = this.props;
    
    	return (
    		<section className="header__search" aria-expanded={ expanded }>
                <ButtonHeader className="admin-button  admin-button--header  admin-button--header-search  color-primary-contrast" icon="search" link={expandUrl} onClick={this.onToggle} />
                <NavSearch placeholder={placeholder} value={q} onChange={this.onSearch} />
                <ButtonHeader icon="close" action="close" link={collapseUrl} onClick={this.onToggle} />
    		</section>
    	)
          
    }
    
}

export default AdminHeaderSearch;