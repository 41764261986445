import React, { Component } from 'react';
import ArrayFieldItem from './ArrayFieldItem';

import {
    retrieveSchema,
    toIdSchema,
} from "../utils";

class ArrayFieldFixed extends Component {

    renderItem = (item, index) => {
        
        const { id, idPrefix, formData, schema, uiSchema, errorSchema, registry, items, itemSchemas } = this.props;
        const { definitions } = registry;

        const additional = index >= itemSchemas.length;
        const itemSchema = additional
            ? retrieveSchema(schema.additionalItems, definitions, item)
            : itemSchemas[index];
        //        const itemIdPrefix = idSchema.$id + "_" + index;

        const itemUiSchema = additional
            ? uiSchema.additionalItems || {}
            : Array.isArray(uiSchema.items)
                ? uiSchema.items[index]
                : uiSchema.items || {};


        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;
        //        const itemIdPrefix = idSchema.$id + "_" + index;

        const itemId = this.props.id + "_" + index;
        const itemIdSchema = toIdSchema(
            itemSchema,
            itemId,
            definitions,
            item,
            idPrefix
        );

        const canRemove = additional;
        const canMoveUp = index >= itemSchemas.length + 1;
        const canMoveDown = additional && index < items.length - 1;

        return (
            <ArrayFieldItem 
                {...this.props}

                index={index} 

                itemId={itemId}
                itemIdPrefix={itemId}
                
                canRemove={canRemove}
                canMoveUp={canMoveUp}
                canMoveDown={canMoveDown}

                itemSchema={itemSchema}
                itemUiSchema={itemUiSchema}
                itemIdSchema={itemIdSchema}
                itemErrorSchema={itemErrorSchema}
                itemData={item}

                key={index} />
        )

    }
    
    render() {
        const { registry, items, canAdd, onAddItem, onChange } = this.props;
        
        if (!items.length) {
            return false
        }

        const { SchemaLayout } = registry.fields;

        return (
            <SchemaLayout {...this.props} onAdd={canAdd && onAddItem} onChange={onChange}>
                { items.length && items.map(this.renderItem) }
            </SchemaLayout>
        )

    }

}

export default ArrayFieldFixed;