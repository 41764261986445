export default [
    {
        role: "group",
        title: "Kiosk",
        icon: "view_quilt",
        pathname: "",
        query: {
            models: "documents",
            documentType: "page*",
            status: "NOT trash"
        },
        children: [
            {
                title: "Mosaikk",
                pathname: "page/grid",
                query: {
                    models: "documents",
                    documentType: "page/grid",
                    status: "NOT trash",
                }
            },
            {
                title: "Tidslinje",
                pathname: "page/timeline",
                query: {
                    models: "documents",
                    documentType: "page/timeline",
                    status: "NOT trash",
                }
            },
            {
                title: "Bildekart",
                pathname: "page/annotate",
                query: {
                    models: "documents",
                    documentType: "page/annotate",
                    status: "NOT trash",
                }
            },
            {
                title: "Bilde, video og lyd",
                pathname: "page/media",
                query: {
                    models: "documents",
                    documentType: "page/media",
                    status: "NOT trash",
                }
            },
            {
                title: "Liste",
                pathname: "page/list",
                query: {
                    models: "documents",
                    documentType: "page/list",
                    status: "NOT trash"
                }
            }
        ]
    },
    {
        role: "group",
        title: "Artikler",
        icon: "view_stream",
        pathname: "article",
        query: {
            models: "documents",
            documentType: "article",
            status: "NOT trash"
        }
    },
    {
        role: "group",
        title: "Enheter",
        icon: "bug_report",
        pathname: "device",
        query: {
            models: "documents",
            documentType: "device",
            status: "NOT trash"
        }
    },
    {
        role: "group",
        type: "media",
        title: "Media",
        children: [
            {
                type: "media/image",
                title: "Images"
            },
            {
                type: "media/video",
                title: "Video"
            },
            {
                type: "media/audio",
                title: "Audio"
            },
            {
                type: "media/misc",
                title: "Misc"
            },
            {
                type: "media/application",
                title: "Apps"
            },
            {
                type: "media/upload",
                title: "Upload"
            }
        ]
    },
    {
        role: "group",
        title: "Ekultur",
        icon: "link",
        pathname: "ekultur",
        query: {
            models: "documents",
            documentType: "ekultur*",
            status: "NOT trash"
        }
    },
    {
        role: "group",
        type: "latest",
        title: "Latest",
        pathname: "latest"
    },
    {
        role: "group",
        type: "trash",
        title: "Trash",
        pathname: "trash"
    }

]