import React, { Component, memo } from 'react'
import SlicesItem from './SlicesItem';

class Slices extends Component {
    
    renderSlice = (slice, index) => {

        const { tooltip, debug, axis, height, setCurrent, current } = this.props;
        
        const isCurrent = current && current.id === slice.id;
        
        return (
            <SlicesItem
                key={slice.id}
                slice={slice}
                axis={axis}
                debug={debug}
                height={height}
                tooltip={tooltip}
                setCurrent={setCurrent}
                isCurrent={isCurrent || false}
            />
        )
        
    }
    
    render() {
        const { slices } = this.props;
        
        if (!slices) {
            return false
        }
        
        return (
            <g className="slices">
                { slices.map(this.renderSlice) }
            </g>            
        ) 
               
    }

}

export default memo(Slices)