import React from "react";
import PropTypes from "prop-types";
import AutocompleteBase from "./AutocompleteBase"
import data from "./WidgetMdIcon.data.json"

function MdIconWidget(props) {
    const {
        id,
        options,
        value,
        required,
        disabled,
        readonly,
        multiple,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        placeholder,
    } = props;

    const emptyValue = multiple ? [] : "";

    let selectOptions = []

    data.categories.map((category) => {

        category && category.icons && category.icons.map((icon) => {
            selectOptions.push({
                group: category.name,
                label: category.name + "/" + icon.name,
                value: icon.ligature
            })
        })
    })

    const label = options.title || props.title || props.label
    const helperText = options.help || undefined

    const handleChange = (event, option) => {
        onChange(option.value);
    }

    return (
        <AutocompleteBase
            id={id}
            placeholder={placeholder}
            multiple={multiple}
            options={selectOptions}
            groupBy={(option) => option.group   }
            icon={value}
            label={label}
            helperText={helperText}
            value={typeof value === "undefined" ? emptyValue : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={handleChange}>
        </AutocompleteBase> 
    )

}

MdIconWidget.defaultProps = {
    autofocus: false,
};

MdIconWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.array,
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default MdIconWidget;
