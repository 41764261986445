import React, { Component } from 'react';

class WidgetMedia extends Component {

    render() {
        const { mediaType, backgroundImage, imageUrl } = this.props;
        
        if (backgroundImage) {
            
            return (
                <figure className="admin-widget__media" style={{backgroundImage: "url("+backgroundImage+")"}}>
                </figure>
            )
            
        }
        
        if (imageUrl) {
            
            return (
                <figure className="admin-widget__media">
                    <img src={imageUrl} />
                </figure>
            )
            
        }
        
        return false

    }
}

export default WidgetMedia;