import JSONSchema from "./JSONSchema";
import uiSchema from "./uiSchema";
import preview from "./preview";

export default {
  schemaType: 'admin',
  name: 'site',
  preview: preview,
  JSONSchema: JSONSchema,
  uiSchema: uiSchema
}
    