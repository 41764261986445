import React, { Component } from 'react';

class Lines extends Component {
    
    renderLine = (line) => {
        const { lineGenerator } = this.props;
        const { id, data, color } = line;
        
        const className = "line  line--" + id;
        
        return (
            <path
                key={id}
                d={lineGenerator(
                    data.map(d => ({
                        x: d.position.x,
                        y: d.position.y,
                    }))
                )}
                stroke={color}
                fill="none"
                className={className}
            />
        )
        
    }

    render() {
        const { series } = this.props;
        
        return (
            <g className="lines">
                { series.map(this.renderLine) }
            </g>
        )
        
    }

}

export default Lines