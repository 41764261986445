export default {
    "ui:options": {
        "grid": true,
        "spacing": 2
    },
    "ui:fieldset": [
        "title",
        "description",
        "source",
        "sourceId"
    ],
    "title": {
        "ui:title": "Tittel",
        "ui:help": "Original tittel."
    },
    "description": {
        "ui:title": "Beskrivelse",
        "ui:widget": "textarea",
        "ui:help": "Original beskrivelse."
    },
    "source": {
        "ui:title": "Kilde",
    },
    "sourceId": {
        "ui:title": "Kildens id",
    },

}