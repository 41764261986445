import schemaType from './schemaType';
import preview from "./preview/schema"

export default {
    type: 'object',
    properties: {
        title: {
            type: "string",
        },
        collectionId: {
            type: "number",
        },
        content: {
            type: 'object',
            properties: {
                collectionId: {
                    type: "number",
                },
                name: {
                    type: "string"
                },
                title: {
                    type: "string",
                },
                description: {
                    type: "string",
                },
                schemaType: schemaType,
                schema: {
                    type: "string"
                },
                schemaLocalized: {
                    type: "localizedString"
                },
                uiSchema: {
                    type: "string"
                },
                uiSchemaLocalized: {
                    type: "localizedString"
                },
                formData: {
                    type: "string"
                },
                uiPreview: {
                    type: "object",
                    properties: {
                        "select": {
                            type: "string"
                        },
                        "prepare": { 
                            type: "string"
                        }
                    }
                },
                JSONSchema: {
                    type: "string"
                },
                preview: preview
            }
        }
    } 
}