export default {
  "select": {
    "imageUrl" : "imageUrl",
    "title" : "title",
    "description" : "description",
    "metadata" : "metadata",
    "typeLabel": "typeLabel"
  },
  prepare({formData, formContext}) {
    let { documentType, locale, title, content, description } = formData

    const { defaultLocale } = formContext

    const localeId = "locale:" + defaultLocale;

    let metadata = []

    const headerImage = content && content.headerImage
    const imageUrl = headerImage && headerImage.media && headerImage.media.imageUrl
    
    if (content) {

        title = content.title && content.title[localeId]
        description = content.description && content.description[localeId]

        const { location, sections, isPartOf } = content

        if (location && location.lat && location.lng) {
            
            if (location.address) {
                metadata.push(location.address)
            } else {
                metadata.push("Sted")
            }
            
        }

        if (sections && sections.length) {
            metadata.push(sections.length + ' seksjoner')
        }

        if (isPartOf && isPartOf.length) {
            metadata.push(isPartOf.length + ' tema')
        }
        
                
    }

    return {
        imageUrl: imageUrl,
        title: title,
        description: description,
        metadata: metadata,
        typeLabel: documentType
    }
    
  }
  
}