export default {
    "ui:fieldset": [
//              "headerImage",
        "title",
        "leadtext"
    ],
    "ui:layout": "section",
    "ui:options" : {
        "collapsible": true,
        "expanded": true,
        "spacing": 2
    },
    "ui:title": "Header",
    "ui:preview": {
        "select": {
            "description": "description"
        },
        prepare({formData, formContext}) {
            const localeId = "locale:" + formContext.currentLocale
            const description = formData.title && formData.title[localeId]  
    
            return {
                description: description
            }
    
        }
    },
    "headerImage": {
        "ui:field": "media",
        "ui:format": "2:1",
        "ui:options": {
            "editable": true,
            "hotspot": true
        },
        "ui:buttons": [
            "sidebar", "upload"
        ]
    },
    "title": {
        "ui:title": "Tittel"
    },
    "leadtext": {
        "ui:title": "Ingress",
        "ui:widget": "textarea"
    }
}