import React, { Component } from 'react';

import './AdminContent.scss';

class AdminContent extends Component {
    render() {
        const { expanded } = this.props;

        return (
            <main className="admin__content" aria-expanded={expanded}>
                {this.props.children}
            </main>
        )
    }
}

export default AdminContent;