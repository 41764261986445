import React, { Component } from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Badge from '@material-ui/core/Badge';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1.5),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "grab"
    }
}));

const DraggableHandle = sortableHandle(({badgeContent, icon, className}) => {
    const classes = useStyles();

    return (
        <div className={className || classes.root} color="inherit" aria-label="sort">
            <Badge badgeContent={badgeContent} color="primary">
                { icon && <Icon>{icon}</Icon> || <DragHandleIcon /> }
            </Badge>
        </div>
    )

})


const ButtonDraggable = ({className, sortBadge, draggableHandle, icon}) => {
    const classes = useStyles();

    if (draggableHandle) {
        return <DraggableHandle className={className} icon={icon} badgeContent={sortBadge} />
    }

    return (
        <IconButton className={className || classes.root} color="inherit" aria-label="sort">
            { icon && <Icon>{icon}</Icon> || <DragHandleIcon /> }
        </IconButton>
    )

}

export default ButtonDraggable;