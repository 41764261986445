// initialState

let initialState = [];

// app state

const parents = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_PARENTS':
        return []

    case 'RECEIVE_PARENTS':
        return action.parents

    default:
        return state

    }

}

export default parents;