// initialState

let initialState = {
    drawer: {
        expanded: false
    },
    header: {
        expanded: true
    },
    nav: {
        expanded: true
    },
    search: {
        expanded: false,
    },
    subview: {
       expanded: false
    },
    sidebar: {
        expanded: false,
    },
};

// app state

const appLayout = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'TOGGLE_APP_SECTION':

      return Object.assign({}, state, {
        [action.name]: {
          expanded: !state[action.name].expanded
        }
      })

    case 'EXPAND_APP_SECTION':

      return Object.assign({}, state, {
        [action.name]: {
          expanded: true
        }
      })

    case 'COLLAPSE_APP_SECTION':

      return Object.assign({}, state, {
        [action.name]: {
          expanded: false
        }
      })

    default:

      return state

    }

}

export default appLayout;