import React, { Component } from 'react';
import { Schema, LayoutWidget, LayoutWidgetHeader, LayoutWidgetBody, LayoutWidgetEdit } from "../"

class EditableWidget extends Component {
    
    state = {
        editing: false
    }
    
    static defaultProps = {
        addable: false,
        removable: false,
        expanded: false,
        editable: false,
        formData: {},
        schema: {
            type: "object",
            properties: {
                widgetType: {
                    type: "string"
                },
                widgetSize: {
                    type: "string",
                    pattern: "grid",
                    enum: [
                        "1:1","2:1","3:1","4:1",
                        "1:2","2:2","3:2","4:2",
                        "1:3","2:3","3:3","4:3",
                        "1:4","2:4","3:4","4:4"
                    ]
                },
                title: {
                    type: "string"
                },
                description: {
                    type: "string",
                    format: "email"
                },
                icon: {
                    type: "string"
                },
                image: {
                    type: "image"
                }
            }
        },
        uiSchema: {
            "ui:layout": "widget",
            "ui:title": {
                "select": "title",
            },
            "ui:description": {
                "select": "description",
            },
            "ui:fieldset": [
                "header",
                "body"
            ],
            "header": {
                "ui:layout": "widget-header",
                "ui:fieldset": [
                    "title", "description"
                ],
                "ui:title": {
                    "select": "title"
                }
            },
            "body": {
                "ui:layout": "widget-body",
                "ui:fieldset": [
                    "title", "description"
                ]
            },
            "ui:settings": [
                "widgetSize"
            ]
        }
    }
    
    onAdd = (props) => {
        console.log('editableWidget:add', props)
        
        if (this.props.onAdd) {
            this.props.onAdd(props)
        }
        
    }

    onRemove = (props) => {
        console.log('editableWidget:remove', props)

        if (this.props.onRemove) {
            this.props.onRemove(props)
        }

    }

    onEdit = (props) => {
        console.log('editableWidget:edit', props)
        
        this.setState({
            editing: true
        })
        
    }

    onSave = (props) => {
        console.log('editableWidget:save', props)

        this.setState({
            editing: false
        })
        
        if (this.props.onSave) {
            this.props.onSave(props)
        }

    }
    
    getUiSchema = () => {
        const { uiSchema, expanded, editable, removable, addable, style } = this.props;

        return {
            ...uiSchema,
            "ui:style": style,
            "ui:options": {
                "expanded": expanded,
                "editable": editable,
                "addable": addable,
                "removable": removable
            }
        }
        
    }
    
    getFormData = () => {
        const { formData, widgetType, widgetSize, title, description, icon, imageUrl } = this.props;
        
        let image;
        
        if (imageUrl) {
            
            image = {
                mediaId: true,
                media: {
                    imageUrl: imageUrl
                }
            }
            
        }
        
        return {
            widgetType: widgetType,
            widgetSize: widgetSize,
            title: title,
            description: description,
            icon: icon,
            image: image,
            ...formData
        }
        
    }
    
    getLayouts = () => {
        const { editing } = this.state;
        const { widgetTemplate, widgetEditor } = this.props;
        
        let layouts = {
            "widget": LayoutWidget,
            "widget-header": LayoutWidgetHeader,
            "widget-body": () => { return ( this.props.children ) }
        }
        
        if (editing && widgetEditor) {
            layouts['widget-header'] = () => { return false }
            layouts['widget-body'] = widgetEditor;

        } else if (editing) {
            layouts['widget-body'] = LayoutWidgetEdit;

        } else if (widgetTemplate) {
            layouts['widget-header'] = () => { return false }
            layouts['widget-body'] = widgetTemplate;
        }

        return layouts;
        
    }
    
    render() {
        const formData = this.getFormData();
        const uiSchema = this.getUiSchema()
        const layouts = this.getLayouts()
        
        return (
            <Schema {...this.props} formData={formData} uiSchema={uiSchema} layouts={layouts}
                onAdd={this.onAdd} onRemove={this.onRemove} onEdit={this.onEdit} onSave={this.onSave} />
        )

    }

}

export default EditableWidget;