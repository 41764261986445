export default {
    "type": "array",
    "items": {
        "type": "object",
        "properties": {
            "collectionId": {
                "type": "string",
                "readonly": true,
            },
            "uniqueId": {
                "type": "string",
                "readonly": true,
            },
            "model": {
                "type": "object",
                "readonly": true,
                "properties": {
                    "title": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    }
                }
            },
            "icon": {
                "type": "string"
            },
            "label": {
                "type": "string"
            },
            "role": {
                "type": "string",
                "enum": ["none","group","split"],
                "enumNames": ["Role","Group","Split"],
                "default": "none"
            },
            "hidden": {
                "type": "boolean"
            }
        }
    }

}