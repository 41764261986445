import React from 'react';
import ButtonLogin from './ButtonLogin';

const NavLogin = ({className, onLogin, ...props}) => {

    return (
        <nav className={className}>
            <ButtonLogin {...props} onClick={onLogin} />
        </nav>        
    )

}

export default NavLogin;