import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutGrid from "./LayoutGrid"

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        padding: props => { return theme.spacing(props.padding) },
        "& > * + *": {
            marginTop: props => { return theme.spacing(props.spacing) }, 
        }
    },
}));

const LayoutBody = ({grid = false, padding, spacing, children}) => {
    const classes = useStyles({padding, spacing});

    if (!children) {
        return false
    }

    return (
        <div className={classes.root}>
            <LayoutGrid grid={grid} padding={padding} spacing={spacing}>
                {children}
            </LayoutGrid>
        </div>
    )

}

export default LayoutBody