import fields from "../fields/";
import fieldtypes from "../fieldtypes/";
import widgets from "../widgets/";
import layouts from "../layouts/";

export function getDefaultRegistry() {
    return {
        fields: fields,
        fieldtypes: fieldtypes,
        widgets: widgets,
        layouts: layouts,
        definitions: {},
        formContext: {},
    };
}