import section from "../section/uiSchema";

export default {
    "ui:fieldset": [
        "sections"
    ],
    "sections": {
        "ui:layout": "section",
        "ui:title": "Innhold",
        "ui:preview": {
            "select": {
                "description": "description" 
            },
            prepare({formData = []}) {
    
                let description;
    
                if (formData.length && formData.length === 1) {
                    description = formData.length + " seksjon"
                } else if (formData.length) {
                    description = formData.length + " seksjoner"
                } else {
                    description = "Ingen seksjoner"
                }
    
                return {
                    "description": description
                }
            }
        },
        "ui:options" : {
            "collapsible": true,
            "expanded": true,
            "draggable": true,
            "padding": 2,
        },
        "ui:buttons" : [
            {
                "label" : "Sporvalg",
                "item" : {
                    "sectionType" : "chatLinks"
                }
            },
            {
                "label" : "Tekstsvar",
                "item" : {
                    "sectionType" : "chatText"
                }
            },
            {
                "label" : "Videosvar",
                "item" : {
                    "sectionType" : "chatVideo"
                }
            }
        ],
        "items": section
    }
}