import AuthorizedSitesField from "./AuthorizesSitesField"
import AuthorizedCollectionsField from "./AuthorizedCollectionsField"

import AuthSiteField from "./AuthSiteItemField"
import AuthCollectionField from "./AuthCollectionItemField"

import AuthList from "./AuthList"
import AuthItem from "./AuthItem"

import MenuEditor from "./MenuEditor"
import MenuTreeEditor from "./MenuTreeEditor"

import MenuList from "./MenuList"
import MenuItem from "./MenuItem"

import CollectionSchemasField from "./CollectionSchemasField"
import CollectionDocumentTypeField from "./CollectionDocumentTypeField"
import CollectionSchemaReferenceField from "./CollectionSchemaReferenceField"
import CollectionPaletteField from "./CollectionPaletteField"
import CollectionColorField from "./CollectionColorField"

import SiteIdWidget from "./SiteIdWidget"
import CollectionIdWidget from "./CollectionIdWidget"

export default {
    "fieldtypes": {
    },
    "fields": {
        "collectionSchemas": CollectionSchemasField,
        "collectionDocumentType": CollectionDocumentTypeField,
        "collectionSchemaReference": CollectionSchemaReferenceField,
        "collectionPalette": CollectionPaletteField,
        "collectionColor": CollectionColorField,
        "authorizedSites": AuthorizedSitesField,
        "authSite": AuthSiteField,
        "authorizedCollections": AuthorizedCollectionsField,
        "authCollection": AuthCollectionField,
    },
    "layouts": {
        "authList": AuthList,
        "authItem": AuthItem,

        "menuEditor": MenuEditor,
        "treeEditor": MenuTreeEditor,
        "menuList": MenuList,
        "menuItem": MenuItem,
    },
    "widgets": {
        "siteId": SiteIdWidget,
        "collectionId": CollectionIdWidget,
    }
}