export default {
    "type": "object",
    "properties": {
        "content": {
            "type": "object",
            "properties": {
                "title": {
                    "type": "string"
                },
                "hourlyRate": {
                    "type": "number"
                },
                "description": {
                    "type": "string"
                },
                "places": {
                    "type": "array",
                    "items": {
                        "type": "reference",
                        "referenceType": "place",
                        "documentType": "place",
                        "_reverseReferenceType": "program",
                        "_reverseReference": "program[]"
                    }
                }
            }
        }
    }
}