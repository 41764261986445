import React, { Component } from 'react';
import { ChartLine } from '../'
import moment from 'moment';

import "./ChartArea.scss"

class ChartArea extends Component {
    
    static defaultProps = {
        enableArea: true,
        areaBaselineValue: 0,
        areaOpacity: 0.5,
        areaBlendMode: 'normal'
    }

    render() {

        return (
            <ChartLine {...this.props}  />
        )
    
    }

}

export default ChartArea