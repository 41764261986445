export default {
    "ui:layout": "section",
    "ui:title": "Presentasjon",
    "ui:preview": {
        "select": {
            "description": "description"
        },
        prepare({formData, formContext}) {
            const { title } = formData;

            const localeId = "locale:" + formContext.currentLocale
            const description = title && title[localeId]

            return {
                description: description
            }

        }
    },
    "ui:fieldset": [
        "title",
        "media",
    ],
    "ui:options": {
        "expanded": true,
        "collapsible": true,
        "padding": 2,
        "spacing": 2,
        "grid": true
    },
    "title": {
        "ui:title": "Tittel"
    },
    "media": {
        "ui:options": {
            "grid": true,
            "spacing": 2
        },
        "ui:fieldset": [
            "backgroundImage"
        ],
        "backgroundImage": {
            "ui:layout": "media",
            "ui:buttons": [
                {
                    "type": "upload",
                    "label": "Upload"
                },
                {
                    "type": "sidebar",
                    "label": "Finn bakgrunnsbilde"
                }
            ],
            "ui:fieldset": [
                "media","imageCropdata","imageFilters"
            ],
            "media": {
                "ui:title": "Bakgrunnsbilde"
            }
        },
        "parallaxImage": {
            "ui:layout": "media",
    //        "ui:format": "2:1",
            "ui:buttons": [
                {
                    "type": "upload",
                    "label": "Upload"
                },
                {
                    "type": "sidebar",
                    "label": "Finn parallaxbilde"
                }
            ],
            "media": {
                "ui:title": "Parallaxbilde"
            }
        }        
    }
}