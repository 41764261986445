import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsChart } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

class ReportAcquisition extends Component {

    static defaultProps = {
        loadingTitle: "Hvor kommer brukerne fra?",
        title: "Hvor kommer brukerne fra?",
        description: "",
        type: "bar",
        stacked: true,
        tooltipLabel: ":ga:medium",
        query: {
            countryId: "no",
            offset: 0,
            owner: 1,
            reportType: 0,
            rows: 1000,
            startDate: "7daysAgo",
            endDate: "yesterday",
            dimensions: "nthDay,medium",
            metrics: "users",
            orderBy: "nthDay",
            sort: "ascending",
        },
        scopes: {
            today: {
                startDate: "today",
                endDate: "today",
                dimensions: "nthHour,medium",
                orderBy: "nthHour"
            },
            yesterday: {
                startDate: "yesterday",
                endDate: "yesterday",
                dimensions: "nthHour,medium",
                orderBy: "nthHour"
            },
            ['7days']: {
                startDate: "7daysAgo",
                endDate: "yesterday",
                dimensions: "nthDay,medium",
                orderBy: "nthDay"
            },
            ['28days']: {
                startDate: "28daysAgo",
                endDate: "yesterday",
                dimensions: "nthWeek,medium",
                orderBy: "nthWeek"
            },
            ['quarter']: {
                startDate: "91daysAgo",
                endDate: "yesterday",
                dimensions: "nthMonth,medium",
                orderBy: "nthMonth"
            },
            ['year']: {
                startDate: "364daysAgo",
                endDate: "yesterday",
                dimensions: "nthMonth,medium",
                orderBy: "nthMonth"
            }
        }
    }
    
    componentDidMount() {
        this.getReport()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.scope !== this.props.scope) {
            this.getReport()
        }

    }

    
    getReport = () => {
        let { query, scopes, id, scope } = this.props;

        if (scope && scopes[scope]) {
            query = {
                ...query,
                ...scopes[scope]
            }
        }

        this.props.getReports({
            ...query,
            id: id
        })
        
        
    }
    
    render() {
        const { data, id } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        return (
            <AnalyticsChart {...this.props} report={report || undefined} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	theme: state.app.theme,
    	data: state.reports,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportAcquisition);