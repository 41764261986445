import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModuleBase from "./ModuleBase"
import ModuleBaseLink from "./ModuleLink"
import ModuleActionPrimary from "./ModuleActionPrimary"
import ModuleActionbar from "./ModuleActionbar"

import ModuleMedia from './ModuleMedia';
import ModuleTitle from './ModuleTitle';
import ModuleLabel from './ModuleLabel';
import ModuleDescription from './ModuleDescription';
import ModuleMetadata from './ModuleMetadata';
import ModuleStatus from './ModuleStatus';
import ModuleByline from './ModuleByline';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        userSelect: "none",

        "& > td": {
            minHeight: theme.spacing(8),
        },

        "&[data-status=trash]": {
            "& figure": {
                opacity: "0.5"
            },
            "& $content": {
                opacity: "0.5"
            }
        },

        "& h2": {
            fontSize: "16px",
            lineHeight: "24px",
        }

    },
    headerCell: {
        width: "50%",
        verticalAlign: "middle",
        padding: theme.spacing(1),
    },
    footerCell: {
        width: "50%",
        verticalAlign: "middle",
        padding: theme.spacing(1),
        position: "relative"
    },
    footer: {
        display: "flex",
        width: "100%",
    },
    actionCell: {
        width: theme.spacing(6),
        verticalAlign: "middle",
    },
    mediaCell: {
        width: theme.spacing(7),
        textAlign: "center",
        verticalAlign: "middle"
    },
    media: {
        position: "relative",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    content: {
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    metadata: {
        width: "100%",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "& * + *": {
            marginLeft: theme.spacing(.5)
        },
        "& > *": {
            lineHeight: "24px"
        }
    },
    actionBar: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    }
}));

const TableModule = ({ imageUrl, title, description, metadata, type, typeLabel, status, statusLabel, author, datetime, onClick, onEdit, ...props }) => {

    const classes = useStyles()
    const defaultImageUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==";

    return (
        <ModuleBase {...props} component="tr" className={classes.root} status={status} onClick={onClick}>
            <td className={classes.actionCell}><ModuleActionPrimary {...props} /></td>
            <td className={classes.mediaCell}>
                <ModuleMedia imageUrl={imageUrl || defaultImageUrl} className={classes.media} />
            </td>            
            <td className={classes.headerCell}>
                <header className={classes.content}>
                    <ModuleTitle status={status} title={title} onClick={!onClick && onEdit} />
                    <div className={classes.metadata}>
                        <ModuleLabel label={typeLabel || type} />
                        <ModuleDescription description={description} />
                        <ModuleMetadata metadata={metadata} />
                    </div>
                </header>
            </td>
            <td className={classes.footerCell}>
                <div className={classes.content}>
                    <ModuleStatus component="h2" status={statusLabel || status} />
                    <div className={classes.metadata}>
                        <ModuleByline author={author} datetime={datetime} />
                    </div>
                </div>
                <ModuleActionbar {...props} className={classes.actionBar} />
            </td>
        </ModuleBase>
    )    

}

TableModule.propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    typeLabel: PropTypes.string,
    status: PropTypes.string,
    statusLabel: PropTypes.string,
    author: PropTypes.string,
    datetime: PropTypes.string,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
}

export default TableModule;
