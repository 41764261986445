import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPreview } from '../redux/functions/schema';
import { getQueryString } from "../redux/functions/query";

import { FinderModule } from '@frontend-components/admin';

class TemplateDashboardSectionModule extends Component {
  
  static defaultProps = {
    
  }
  
  state = {
    selected: false
  }
  
  getUrl = () => {
    
    const { query, item } = this.props;
    
    const path = query.path || "/:uniqueId"

    let pathnames = []
    
    if (path) {

      let names = path.split('/');
      
      names.forEach(name => {

        if (name.startsWith(':')) {
          let key = name.replace(':', '');
          name = name.replace(name, item[key]);
        }
        
        pathnames.push(name);
        
      })
      
    }

    const qs = getQueryString();
    
    let url = item.url;
    
    if (!url) {
        url = pathnames.join("/")
    }
    
    if (qs.redirectUrl) {
      url = url + "?redirectUrl=" + qs.redirectUrl;
    } else {
      url = url
    }
    
    
    return url;
    
  }

  getPreview = () => {
    
    const { query, item, schemas } = this.props;
    const { schema } = query;
    
    let preview;

    if (schema && typeof schema === "object") {
      preview = getPreview(schema, item)
    } else if (schema && typeof schema === "string" && schemas[schema]) {
      preview = getPreview(schemas[schema], item)
    }
    
    let moduleAction = "link";
    
    const url = this.getUrl();

    return Object.assign({}, preview, {
      url: url,
      moduleAction: moduleAction,
      moduleLayout: "card",
      moduleSize: "medium",
    })
    
  }
  
  render() {

    const item = this.getPreview()
    
    const { selected } = this.state;
    
    return (
      <FinderModule { ...this.props } { ...item } selected={ selected } />
    )
    
  }

}

function mapStateToProps(state) {
	return {
    schemas: state.schemas
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateDashboardSectionModule);

