import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import InboxMessageHeader from "./InboxMessageHeader"
import InboxMessageEditor from './InboxMessageEditor';
import NavEditbar from "../NavEditbar/NavEditbar"


const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        width: "100%",
        position: "relative",
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    content: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(2),
    },
    body: {
        maxWidth: "100%",
        overflow: "hidden",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    }
}));

const InboxMessageReply = ({author, from, to, message, onChange, buttons, ...props}) => {

    const classes = useStyles()

    return (
        <article className={classes.root}>
            <InboxMessageHeader {...props} from={from} replyTo={to} author={author} />
            <div className={classes.content}>
                <InboxMessageEditor className={classes.body} value={message} onChange={onChange} />
                <NavEditbar buttons={buttons} />
            </div>
        </article>
    )

}

export default InboxMessageReply