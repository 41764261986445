import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    iconButton: {
        color: "inherit",
    },
    icon: {
        fontSize: "18px",
        "& + $label": {
            marginLeft: theme.spacing(.5)
        }
    },
    button: {
        padding: theme.spacing(1),
        margin: theme.spacing(-.5),
        borderRadius: 0,
        minWidth: 0
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: "1",
        textTransform: "uppercase",
    },
}));


const OptionsButton = ({name, icon, value, label, disabled, expanded, controls, onToggle, forwardedRef}) => {

    const classes = useStyles()

    if (icon) {
        return (
            <IconButton className={classes.iconButton} ref={forwardedRef} 
                disabled={disabled}
                aria-controls={expanded ? controls : undefined}
                aria-expanded={expanded ? 'true' : undefined}
                aria-label="options"    
                aria-haspopup="menu"
                onClick={onToggle}>
                <Icon className={classes.icon}>{icon}</Icon>
            </IconButton>
        )
    }

    return (
        <Button className={classes.button} disabled={disabled} ref={forwardedRef} 
            aria-controls={expanded ? controls : undefined}
            aria-expanded={expanded ? 'true' : undefined}
            aria-label="options"    
            aria-haspopup="menu"
            onClick={onToggle}>
                { icon && <Icon className={classes.icon}>{icon}</Icon> }
                <b className={classes.label}>{label || name}</b>
        </Button>
    )


}

export default OptionsButton