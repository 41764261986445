import React, { useState } from 'react';

import { WidgetSearch } from "@frontend-components/admin"

import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"

import { makeStyles } from '@material-ui/core/styles'

import AuthItem from "./AuthItem"


const useStyles = makeStyles(theme => ({
    root: {
        "& $root": {
            marginTop: theme.spacing(1)
        },
    },
    label: {
    },
    list: {
        "& li": {
            minHeight: theme.spacing(5),
            "&.sortable-helper": {
                boxShadow: theme.shadows[12],
                zIndex: 2000
            }
        },
        "& li + li": {
            borderTop: "1px solid",
            borderColor: theme.palette.grey[500],
        }
    }
}));

const AuthSearch = ({search, results = [], onAddAuth}) => {

    const [filteredResults, setFilteredeResults] = useState(results)

    const handleSearch = (q) => {

        const filtered = results.filter((item) => {
            const { title, description } = item;
            
            if (q) {
                q = q.toLowerCase()

                if (title.toLowerCase().includes(q) || description.toLowerCase().includes(q)) {
                    return true
                }

                return false

            }
            
            return true
        })

        setFilteredeResults(filtered)
    
    }

    const classes = useStyles()

    return (
        <List className={classes.list}>
        { search && <WidgetSearch {...search} onChange={handleSearch} /> }
        { filteredResults.map((auth, index) => <AuthItem auth={auth} key={index} onClick={() => onAddAuth(auth)} />) }
        </List>
    )

}

const AuthList = ({title, children, results, ...props}) => {

    const classes = useStyles()
    const header = title && <ListSubheader className={classes.label}>{title}</ListSubheader>

    return (
        <section>
            <List className={classes.list} subheader={header}>
                { children }
            </List>
            { results.length && <AuthSearch results={results} {...props} /> }
        </section>
    )
   
}

export default AuthList