import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnalyticsReportMetrics } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

class ReportAudience extends Component {
    
    static defaultProps = {
        query: {
            countryId: "no",
            offset: 0,
            owner: 1,
            reportType: 0,
            rows: 1000,
            startDate: "7daysAgo,14daysAgo",
            endDate: "yesterday,8daysAgo",
            dimensions: "nthDay",
            metrics: "users,sessions,bounceRate,avgSessionDuration,avgTimeOnPage",
            orderBy: "nthDay",
            sort: "ascending",
        },
        scopes: {
            today: {
                loadingTitle: "Metrics today",
                query: {
                    startDate: "today,yesterday",
                    endDate: "today,yesterday",
                    dimensions: "nthHour",
                    orderBy: "nthHour"
                }
            },
            yesterday: {
                loadingTitle: "Metrics yesterday",
                query: {
                    startDate: "yesterday,2daysAgo",
                    endDate: "yesterday,2daysAgo",
                    dimensions: "nthHour",
                    orderBy: "nthHour"
                }
            },
            ['7days']: {
                loadingTitle: "Metrics last week",
                query: {
                    startDate: "7daysAgo,14daysAgo",
                    endDate: "yesterday,8daysAgo",
                    dimensions: "nthDay",
                    orderBy: "nthDay"
                }
            },
            ['28days']: {
                loadingTitle: "Metrics last 4 weeks",
                query: {
                    startDate: "28daysAgo,56daysAgo",
                    endDate: "yesterday,29daysAgo",
                    dimensions: "nthDay",
                    orderBy: "nthDay"
                }
            },
            ['90days']: {
                loadingTitle: "Metrics last 90 days",
                query: {
                    startDate: "90daysAgo,180daysAgo",
                    endDate: "yesterday,91daysAgo",
                    dimensions: "nthDay",
                    orderBy: "nthDay"
                }
            },
            ['180days']: {
                loadingTitle: "Metrics last 180 days",
                query: {
                    startDate: "180daysAgo,360daysAgo",
                    endDate: "yesterday,181daysAgo",
                    dimensions: "nthDay",
                    orderBy: "nthDay"
                }
            },
            ['year']: {
                loadingTitle: "Metrics last year",
                query: {
                    startDate: "365daysAgo",
                    endDate: "yesterday",
                    dimensions: "nthWeek",
                    orderBy: "nthWeek"
                }
            }
        }

    }
    
    componentDidMount() {
        this.getReport()
    }

    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.scope !== this.props.scope) {
            this.getReport()
        }
        
    }

    getReport = () => {
        let { data, query, scopes, scope, id } = this.props;
        
        if (scope && scopes[scope] && scopes[scope].query) {
            
            query = {
                ...query,
                ...scopes[scope].query
            }
        }
        
        if (!data[id]) {

            this.props.getReports({
                ...query,
                id: id
            })

            
        }
        
        
    }
    
    render() {
        const { theme, data, scope, id, widgetSize } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        return (
            <AnalyticsReportMetrics scope={scope} report={report || undefined} theme={theme} widgetSize={widgetSize} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	theme: state.app.theme,
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportAudience);