import { getColorField } from "./utils"

import ColorSettings from "./ColorSettings"
import TextColorSettings from "./TextColorSettings"
import ColorWidget from "./ColorWidget"

export default {
    "fieldtypes": {
        "color":  getColorField,
        "textColor":  getColorField
    },
    "fields": {
    },
    "layouts": {
    },
    "widgets": {
        "color": ColorWidget,
        "textColor": ColorWidget
    },
    "settings": {
        "color": ColorSettings,
        "textColor": TextColorSettings
    }
}