import React from "react";
import DateTimeField from "./DateTimeField"

const DateTimeWidget = ({options, ...props}) => {
    const label = options.title || props.title || props.label || null
    const helperText = options.help || null

    return (
        <DateTimeField
            {...props}
            label={label}
            helperText={helperText}
        />
    )
}

export default DateTimeWidget;