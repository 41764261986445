import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AuthModule } from '@frontend-components/admin';
import { getPreview } from '../redux/functions/schema';
import { setUserAuth, setAppAuth } from "../redux/functions/auth"

import schema from "./schemas/app/"

class Module extends Component {
    
    static defaultProps = {
        urlPattern: "/admin/auth/app/:appId",
        editUrlPattern: "/admin/auth/app/:uniqueId/edit",
        action: ['edit']
    }
    
    onAuth = (authId) => {
        const { collectionId, collectionType, userId, siteId } = this.props;
        
        if (collectionId && userId) {
            this.props.setUserAuth(userId, {
                collectionId: collectionId,
                roleId: authId
            })
        } else if (collectionType && siteId) {
            this.props.setAppAuth(collectionId, {
                siteId: siteId,
                collectionType: collectionType,
                status: authId
            })
        }
        
    }
    
    getAuth = () => {
        let { rolesOptions, roleId, userId, collectionId, collectionType, siteId, status, action } = this.props;

        const app = getPreview(schema, {
            ...this.props,
            content: undefined
        });
        
        let authOptions, authValue;
        
        if (collectionId && userId && rolesOptions) {
            authOptions = rolesOptions
            authValue = roleId || undefined
            action = ["remove"]

        } else if (siteId && collectionType) {

            if (!status || status !== "publish") {
                authOptions = [
                    {
                        label: "Activate",
                        value: "publish"
                    }
                ]
            } else {
                authOptions = [
                    {
                        label: "Active",
                        value: "publish"
                    },
                    {
                        label: "Deactivate",
                        value: "trash"
                    }
                ]
            }

            authValue = status || undefined
//            action = []
            
        }

        return {
            ...app,
            authType: "app",
            authOptions: authOptions,
            authValue: authValue,
            action: action
        }        
        
    }
    
    getLabel = () => {
        const { userId, appName, siteName } = this.props;
        
        let labels = []

        if (userId) {
            labels.push("User")
        } else {
        }
        
        if (siteName) {
            labels.push(siteName)
        }

        if (appName) {
            labels.push(appName)
        }
        
        return labels.join('/')
        
    }
    
    render() {
        const { app, name, title, content, appType, authSites } = this.props;
        
        /*
        
        const preview = getPreview(schema, {
            name: name,
            title: title,
            content: content,
            authSites: authSites
        });
        
        */

        const site = this.getAuth()
        
        return (
            <AuthModule {...site} onAuth={this.onAuth} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
        rolesOptions: state.auth.rolesOptions
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      setUserAuth, setAppAuth
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Module);