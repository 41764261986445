import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"
import MenuList from "./MenuList"
import MenuItem from "./MenuItem"
import MenuLink from "./MenuLink"

const NavMenu = ({className, menu = [], menuByUrl, expandedByUrl, selectedByUrl, currentUrl, onSelect, onToggle}) => {

    const handleSelect = (item, event) => {
        onSelect && onSelect(item, event)
    }

    const handleToggle = (item, event) => {
        onToggle && onToggle(item, event)
    }

    const renderLink = (item) => {
        const { selected, url, icon, title, label, count } = item;

        return (
            <MenuLink selected={selected} url={url} icon={icon} label={label || title} count={count} onClick={(event) => handleSelect(item, event)} />
        )

    }

    const renderItem = (item, index) => {

        if (item.url && menuByUrl[item.url]) {
            item = {
                ...item,
                ...menuByUrl[item.url],
            }
        }

        if (item.url && expandedByUrl[item.url]) {
            item = {
                ...item,
                ...expandedByUrl[item.url],
            }
        }

        if (item.url && selectedByUrl[item.url]) {
            item = {
                ...item,
                ...selectedByUrl[item.url],
            }
        }

        if (item.url && currentUrl && item.url === currentUrl) {
            item.expanded = true
            item.selected = true
        }

        if (item.url && currentUrl && currentUrl.startsWith(item.url)) {
            item.expanded = true
        }

        const { role, children, hidden, selected, expanded } = item;

        if (hidden) {
            return (
                <MenuItem role={role}>
                    <MenuList role={role}>
                    { children && children.map(renderItem)}
                    </MenuList>
                </MenuItem>
            )
        }

        if (children) {
            return (
                <MenuItem role={role || "treeitem"} selected={selected} expanded={expanded} onToggle={(event) => handleToggle(item, event)}>
                    { renderLink(item) }
                    { children && expanded && 
                        <MenuList role="tree">
                        { children && children.map(renderItem)}
                        </MenuList>
                    }
                </MenuItem>
            )
        }

        return (
            <MenuItem {...item} role={role || "none"} key={index}>
                { renderLink(item) }
            </MenuItem>
        )

    }

    if (!menu || !menu.length) {
        return false
    }

    return (
        <nav className={className}>
            <MenuList role="tree">
                { menu.map(renderItem)}
            </MenuList>
        </nav>
    )

}

NavMenu.propTypes = {
    currentUrl: PropTypes.string,
    menu: PropTypes.array,
    menuByUrl: PropTypes.object,
    onSelect: PropTypes.func,
    onToggle: PropTypes.func
}

export default NavMenu;