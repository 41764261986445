import React, { Component } from 'react';
import PreviewMedia from "./PreviewMedia"

class PreviewHeader extends Component {
    
    renderMedia = () => {
        
        const { mediaType, mimeType, imageWidth, imageHeight,  } = this.props;
        
        if (!mediaType) {
            return false;
        }
        
        let metadata = []

        metadata.push({
            "name": "mediaType",
            "label": "Media"
        })

        metadata.push({
            "name": "mimeType",
            "label": "MIME type"
        })
        
        if (mediaType === "image") {
            
            metadata.push({
                "label": "Dimensions",
                "value": imageWidth + "x" + imageHeight
            })
            
        }
        
        return (
            <div>

                <ul className="field__metadata">
                { metadata.map(this.renderMeta) }
                </ul>
            
                <PreviewMedia formData={{ media: this.props }} />
            </div>
        )
        
    }
    
    renderMeta = (meta, index) => {
        
        const { name, label } = meta;
        
        const value = meta.value || this.props[name];
        
        if (!value) {
            return false
        }
        
        return (
            <li className="field__meta" key={index}><b className="meta__label">{label}</b> <i className="meta__value">{value}</i></li>
        )
        
    }
    
    renderMetadata = () => {

        const { uniqueId, parentId, locale, status, createdAt, updatedAt } = this.props;
        
        const metadata = [
            {
              "name": "uniqueId",
              "label": "uniqueId",
            },
            {
              "name": "parentId",
              "label": "parentId",
            },
            {
                "name": "currentLocale",
                "label": "Locale",
            },
            {
                "name": "status",
                "label": "Status",
            },
            {
                "name": "createdAt",
                "label": "Created at",
            },
            {
                "name": "updatedAt",
                "label": "Updated at",
            }
        ]
        
        return (
            <ul className="field__metadata">
            { metadata.map(this.renderMeta) }
            </ul>
        )
        
        
    }

    render() {
    
        const { documentType, mediaType, imageUrl, name, title, description, language, locale, status, createdAt, updatedAt } = this.props;
        
        let fieldLabel, fieldType;
        
        if (documentType) {
            fieldLabel = documentType
            fieldType = "document"
        }
        
        if (mediaType) {
            fieldLabel = mediaType
            fieldType = "media"
        }
        
        if (!fieldType) {
            fieldLabel = name || "untitled";
            fieldType = "schema"
        }
        
        return (
            <header className="admin-preview__field  field--header">
                <h3 className="field__label">{ fieldLabel } <em className="field__type">{ fieldType }</em></h3>
                <h1 className="field__title">{ title }</h1>
                { this.renderMedia() }
                { this.renderMetadata() }
            </header>
        )    
    }

}

export default PreviewHeader