import React from 'react';
import { Button } from '../Button';

import './ButtonToggle.scss';

class ButtonToggle extends React.Component {
    render() {
        return (
            <Button {...this.props} className="admin-button  admin-button--toggle" icon="more_horiz" label="Toggle" />
        )
    }
}

export default ButtonToggle;