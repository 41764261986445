import { API } from '../settings';
import { receiveMenuItem, receiveMenuItemChildren } from '../actions/nav';
import { getNavItem } from '../functions/nav';
import { getQueryParams } from './query';
import qs from 'query-string';

// GET TREE

export function getNavTree(parent) {
    const { query } = parent;
    const { models } = query;
    
    const fetchUrl = API + '/admin/api/' + models + '/search?' + qs.stringify({...query, fl: "id,title,uniqueId,parentId"});

    return function(dispatch) {
  
        fetch(fetchUrl, {
          method: "GET",
          headers: {
            "Accept": "application/json",
          },
        })
        .then(
          response => response.json(),
          error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(getNavTreeParent(parent, data))
        )

  }

}

export function getNavTreeParent(parent, data) {
    const { models } = data;
    
    let children = []

    return function(dispatch) {

        if (models) {
            models.map((model) => {
                const child = getNavTreeChild(parent, model)
                children.push(child)
            })
        
        }
        
        if (children.length) {
            dispatch(receiveMenuItem({...parent, count: children.length, children: children}))
            
            children.map((child) => {
                dispatch(receiveMenuItem(child))

//                dispatch(getNavItem(child))
            })
            
        }
        
    }
    
}

export function getNavTreeChild(parent, model) {
    const { id, title, uniqueId } = model;
    const { type, query } = parent
    const { collectionId, models } = query;

    let url;

    if (type === "documents/tree") {
        url = parent.url + "/tree/" + id
    } else {
        url = parent.url + "/" + id
    }

    const child = {
        type: "documents/treeitem",
        id: id,
        uniqueId: uniqueId,
        url: url,
        title: title,
        query: {
            collectionId: collectionId,
            models: models,
            parentId: id
        }
    }
    
    return child;
    
}