import React, { Component } from 'react';
import { FinderSection } from '@frontend-components/admin';
import AuthModuleSite from "./AuthModuleSite"

class AuthSectionSites extends Component {
    
    static defaultProps = {
        sites: []
    }
    
    renderSite = (site, index) => {

        return (
            <AuthModuleSite {...site} key={index} />
        )
        
    }

    render() {
        const { title, sites } = this.props;
        
        return (
            <FinderSection title={title}>
                { sites.map(this.renderSite) }
            </FinderSection>
        )
        
    }

}

export default AuthSectionSites