export default {
    "ui:layout": "section",
    "ui:options" : {
        "collapsible": true,
        "sortable": true
    },
    "ui:preview": {
        "select": {
            "title": "title"
        },
        prepare({formData}) {
            const count = formData && formData.length;
            if (count) {
                return {
                    "title": count + " artikler"
                }
            }
            return {
               "title": "Ingen artikler"
            }
        }
    },
    "ui:buttons": [
        {
            "type": "sidebar",
            "label": "Finn artikkel"
        },
        {
            "type": "add",
            "label": "Legg til"
        }
    ]
}