import React from 'react'
import { EditorBase, EditorHeader, EditorBody, EditorFooter } from "@frontend-components/admin"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "inherit",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    body: {
        position: "absolute",
        top: theme.spacing(8),
        right: 0,
        bottom: 0,
        left: 0,
        "& > *:first-child": {
            transition: ".25s ease-out",
        },
        "&[aria-expanded=true]": {
            "& > *:first-child": {
                top: "100%"
            }
        }
    }
}));

const PageEditor = ({currentId, primaryId, secondaryId, onToggle, children, ...props}) => {
    const classes = useStyles()

    const collapsible = primaryId && secondaryId
    const expanded = collapsible && currentId && currentId.startsWith(secondaryId) || false

    return (
        <EditorBase className={classes.root}>
            <EditorHeader {...props} collapsible={collapsible} expanded={expanded} onToggle={onToggle} />
            <EditorBody className={classes.body} square={true} elevation={1} expanded={expanded}>
                {children}
            </EditorBody>
            <EditorFooter {...props} />
        </EditorBase>
    )
}

export default PageEditor;