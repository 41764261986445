export default {
    editor: {
        "Loading": "Läsa",
        "Saving": "Spara",
        "Save": "Spara",
        "Save as draft": "Spara som utkast",
        "Save and publish": "Spara och publicera",
        "Save and exit": "Spare och avsluta",
        "Save as": "Spare som"
    }
}