import { getAppData } from '../../redux/functions/app';
//import { getSiteCollection } from '../../redux/functions/appCollection';
import { getMenuChildren } from '../../AppCollection/app';

import schemas from "../schemas"
import menuChildren from "./menu";


export function getApp(props) {
    const { title, name, site, collection } = props;

    const root = "/" + site.name + "/" + name

    const appProps = {
        uniqueId: collection && collection.id,
        siteId: site && site.id,
        collectionId: collection && collection.id,
        root: root,
        title: title,
        name: name
    }

    const menu = getMenuChildren({children: menuChildren, ...appProps})
    const primaryAction = getAppAction(appProps)

    const app = {
        ...appProps,
        theme: {
            "palette": {
                "primary": {
                    "main": "#3154ea",
                    "light": "#8aa7f7",
                    "dark": "#24439f"
                }
            }
        },
        languages: ["no","en"],
        layout: "finder",
        sidebar: {
            expanded: true,
        },
        header: {
            expanded: true,
            title: "Museum24",
            subtitle: site.title || site.name
        },
        search: {
            "placeholder": "Søk i DigitaltMuseum"
        },
        primaryAction: primaryAction,
        menu: menu,
        schemas: schemas
    }

    return function(dispatch) {
        dispatch(getAppData(app))
    }
    
}

export function getAppSearch(appRoot) {
    
    return {
        "placeholder": "Søk i DigitaltMuseum"
    }
    
}

export function getAppAction({root}) {

    return {
        title: "Nytt innhold",
        icon: "",
        children: [
            {
                title: "Artikkel",
                url: root + "/article/<new>/edit",
            },
            {
                title: "Arrangement",
                url: root + "/event/<new>/edit",
            },
            {
                title: "Innboks",
                url: root + "/inbox/section/<new>",
            },
            {
                title: "Last opp",
                icon: "cloud_upload",
                url: root + "/media/upload",
            }
        ]
    }
    
}

export function getAppMenu(appRoot) {

   return [
      {
        title: "DigitaltMuseum",
        icon: "dashboard",
        url: appRoot
      },
      {
          hidden: "true",
        role: "group",
        children: [
          {
            title: "Artikler",
            icon: "view_stream",
            url: appRoot + "/article",
            query: {
                models: "documents",
                documentType: "article"
            }
          },
          {
            title: "Arrangementer",
            icon: "view_stream",
            url: appRoot + "/event",
            query: {
                models: "documents",
                documentType: "event"
            }
          },
          {
            title: "Samlinger",
            icon: "view_headline",
            url: appRoot + "/object",
            query: {
                models: "documents",
                documentType: "article"
            },
            children: [
                {
                    title: "Fotografier",
                    url: appRoot + "/object/photograph",
                    query: {
                        objectType: "Photograph"
                    }
                },
                {
                    title: "Gjenstander",
                    url: appRoot + "/object/thing",
                    query: {
                        objectType: "Thing"
                    }
                }
            ]
          }
        ]
      },
      {
        title: "Media",
        icon: "image",
        url: appRoot + "/media",
        children: [
          {
            title: "Bilder",
            url: appRoot + "/media/image",
            query: {
                mediaType: "image"
            }
          },
          {
            title: "Video",
            url: appRoot + "/media/video",
            query: {
                mediaType: "video"
            }
          },
          {
            title: "Audio",
            url: appRoot + "/media/audio",
            query: {
                mediaType: "audio"
            }
          }
        ]
      },
      {
        title: "Kalender",
        icon: "event",
        url: appRoot + "/calendar",
      },
      {
        title: "Innboks",
        icon: "inbox",
        url: appRoot + "/inbox",
        query: {
            models: "documents",
            documentType: "inbox"
        },
        children: [
          {
            title: "Kommentarer",
            url: appRoot + "/inbox/comments"
          },
          {
            title: "Tilbakemeldinger",
            url: appRoot + "/inbox/message"
          }
        ]
      },
      {
        title: "Statistikk",
        icon: "insert_chart",
        url: appRoot + "/analytics",
        children: [
          {
            title: "Alt innhold",
            icon: "",
            url: appRoot + "/analytics/all",
            chart: {
                template: "regVsPub",
            },
            query: {
                dimensions: "pageTitle,pagePath"
            }
          },
          {
            title: "Sidetyper",
            icon: "",
            url: appRoot + "/analytics/type",
            chart: {
                template: "objectTypes",
            },
            query: {
                urlPattern: appRoot + "/analytics/:dm:objectType",
                dimensions: "dimension4"
            }
          },
              {
                title: "Fotografier",
                icon: "",
                url: appRoot + "/analytics/photograph",
                chart: {
                    template: "sessions",
                },
                query: {
                    dimensions: "dimension4"
                }
              },
              {
                title: "Gjenstander",
                icon: "",
                url: appRoot + "/analytics/thing",
                chart: {
                    template: "sessions",
                },
                query: {
                    dimensions: "dimension4"
                }
              },
              {
                title: "Bygninger",
                icon: "",
                url: appRoot + "/analytics/building",
                chart: {
                    template: "sessions",
                },
                query: {
                    dimensions: "dimension4"
                }
              },
              {
                title: "Utstillinger",
                icon: "",
                url: appRoot + "/analytics/exhibition",
                 chart: {
                    template: "sessions",
                },
               query: {
                    dimensions: "dimension4"
                }
              },
              {
                title: "Mapper",
                icon: "",
                url: appRoot + "/analytics/folder",
                chart: {
                    template: "sessions",
                },
                query: {
                    dimensions: "dimension4"
                }
              }
        ]
      },
      {
        children: [
          {
            title: "Brukere",
            icon: "people",
            url:  appRoot + "/useradmin"
          },
        ]
      }
    ]

    
}