export default {
    editor: {
        "Loading": "Laster",
        "Saving": "Lagrer",
        "Save": "Lagre",
        "Save as draft": "Lagre som utkast",
        "Save and publish": "Lagre og publiser",
        "Save and exit": "Lagre og avslutt",
        "Save as": "Lagre som",
        "Empty trash": "Tøm papirkurv",
        "Restore": "Gjenopprett",
        "Erase": "Slett for alltid"
    },
    layout: {
        "table": "Tabell",
        "grid": "Grid",
        "list": "Liste",
        "gallery": "Galleri",
    },
    dialog: {
        "Find document": "Finn innhold",
        "Find media": "Finn media",
        "Find image": "Finn bilde",
        "Find video": "Finn video",
        "Find audio": "Finn audio",
        "image": "Bilder",
        "video": "Video",
        "audio": "Audio",
        "youtube": "YouTube",
        "vimeo": "Vimeo",
        "sketchfab": "Sketchfab",
        "ekultur": "DigitaltMuseum",
    },
    upload: {
        "Uploading {{requested}} of {{received}}": "Laster opp {{requested}} av {{received}}",
        "Click or drop files to upload": "Klikk eller slipp filer for å laste opp",
        "Fetching unprocessed uploads": "Finner ubehandlede opplastninger",
        "No unprocessed uploads": "Ingen ubehandlede opplastninger",
        "{{count}} unprocessed uploads": "{{count}} ubehandlede opplastninger",
    },
    search: {
        "Fetching data, please wait": "Henter data, vennligst vent",
        "No hits": "Ingen treff",
        "{{count}} hits": "{{count}} treff",
        "{{page}} of {{pages}} pages": "Side {{page}} av {{pages}}",
        "More results": "Flere resultater",
        "Next page": "Neste side",
        "Previous page": "Forrige side"
    },
    sort: {
        "title": "Alfabetisk",
        "updatedAt DESC": "Sist oppdatert",
        "createdAt DESC": "Opprettet dato",
    },
    status: {
        "error": "Feil",
        "general": "N/A",
        "draft": "Utkast",
        "publish": "Publisert",
        "trash": "Papirkurv",
        "erased": "Slettet",
        "restore": "Gjenopprettet",
        "upload": "Ubehandlet",
        "new": "Ny"
    },
    uploadStatus: {
        "init": "Laster opp",
        "done": "Lastet opp",
        "convert": "Konverterer media",
    },
    bylineTitle: {
        "photographer": "Foto",
        "rightsmanager": "Rettighetsforvalter",
        "director": "Regissør",
        "photograph": "Foto",
        "vector": "Grafikk",
        "illustration": "Illustrasjon",
        "painting": "Maleri",
    },
    license: {
        "copyright": "Opphavsrett",
        "by": "Navngivelse",
        "by-sa": "Navngivelse-DelPåSammeVilkår",
        "by-nd": "Navngivelse-IngenBearbeidelse",
        "by-nc": "Navngivelse-IkkeKommersiell",
        "by-nc-sa": "Navngivelse-IkkeKommersiell-DelPåSammeVilkår",
        "pdm": "Public Domain Mark",
        "pdd": "Public Domain Dedication",
    },
    licenseGroup: {
        "by": "Kan gjenbrukes ved navngivelse",
        "by-nd": "Kan kun gjenbrukes ved tillatelse",
        "by-nc": "Kan ikke gjenbrukes kommersielt",
        "pd": "Åpne lisenser (kan gjenbrukes)"
    }
}