import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Finder from "../AppFinder/"
import { FinderSection } from '@frontend-components/admin';

import WidgetObjectTypes from './WidgetObjectTypes';
import ReportTable from './ReportTable';

import ChartRegVsPub from "./ChartRegVsPub"
import ChartObjectTypes from "./ChartObjectTypes"
import ChartSessionsByOwner from "./ChartSessionsByOwner"
import ChartSessionsByApp from "./ChartSessionsByApp"
import ChartSessions from "./ChartSessions"

const templates = {
    "regVsPub": ChartRegVsPub,
    "objectTypes": ChartObjectTypes,
    "sessionsByOwner": ChartSessionsByOwner,
    "sessionsByApp": ChartSessionsByApp,
    "sessions": ChartSessions
}

// ?countryId=no&offset=0&owner=1,2,3,4&reportType=0&rows=50&startDate=2019-07-01,2018-07-01&endDate=2019-07-01,2018-07-01&orderBy=nthDay&metrics=uniquePageviews,users,sessions&dimensions=dimension1,dimension2,dimension3,dimension4,nthDay&sort=descending

class AnalyticsReport extends Component {

    static defaultProps = {
        defaultFilter: "7days",
        filtersById: {
            yesterday: {
                title: "I går"
            },
            ['7days']: {
                title: "Siste uke"
            },
            ['28days']: {
                title: "Siste 4 uker"
            },
            ['quarter']: {
                title: "Siste kvartal"
            },
            ['year']: {
                title: "Siste år"
            }
        }
    }
    
    state = {
        filters: [],
        scope: "7days"
    }
    
    getFilters = () => {
        const { pathname, hash } = this.props.location;
        const { filtersById, defaultFilter } = this.props;
        
        let selectedId;

        if (hash) {
            selectedId = hash.replace('#', '')
        } else {
            selectedId = defaultFilter;
        }
        
        let scope;

        const filters = Object.keys(filtersById).map(id => {
            
            let selected;
            
            if (id === selectedId) {
                selected = true;
                scope = id
            }
            
            const filter = {
                ...filtersById[id],
                id: id,
                url: pathname + "#" + id,
                selected: selected
            }
            
            return filter
            
        })
        
        console.log('SCOPE', scope)
        
        this.setState({
            filters: filters,
            scope: scope
        })

    }
    
    componentDidMount() {
        this.getFilters()
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.location.hash !== this.props.location.hash) {
            this.getFilters()
        }
        
    }
    
    getProps = () => {
        const { filters, scope } = this.state
        const { pathname } = this.props.location;
        const { nav, theme } = this.props;

        let title, chart, query;

        if (nav[pathname] && nav[pathname].query) {
            title = nav[pathname].title
            chart = nav[pathname].chart || undefined
            query = nav[pathname].query || undefined
        }

        return {
            title: title,
            chart: chart,
            query: query
        }
        
    }
    
    renderTable = () => {
        const { filters, scope } = this.state
        const { pathname } = this.props.location;
        const { nav, theme } = this.props;

        let title, chart, query;

        if (nav[pathname] && nav[pathname].query) {
            title = nav[pathname].title
            chart = nav[pathname].chart
            query = nav[pathname].query
        }
        
        if (!query) {
            return false
        }
        
        const id = pathname + "/table/" + scope;
        
        if (chart) {

            return (
                <FinderSection label={title} action={[{title: "Export", icon: "save_alt"}]}>
                    <ReportTable query={query} scope={scope} id={id} theme={theme}  />
                </FinderSection>
            )
            
        }
        
        return (
            <ReportTable query={query} scope={scope} id={id} theme={theme}  />
        )
        
    }
    
    renderChart = () => {
        const { filters, scope } = this.state
        const { pathname } = this.props.location;
        const { nav, theme } = this.props;

        let chart;
        
        if (nav[pathname] && nav[pathname].chart) {
            chart = nav[pathname].chart
        }
        
        if (!chart) {
            return false
        }
        
        // template
        
        let ChartTemplate;
        
        if (chart.template && templates[chart.template]) {
            ChartTemplate = templates[chart.template]
        }

        const id = pathname + "/chart/" + scope;
        
        if (ChartTemplate) {

            return (
                <FinderSection label={false}>
                    <ChartTemplate id={id} theme={theme} />
                </FinderSection>
            )
            
        }
        
        return (
            <p>{ JSON.stringify(chart) }</p>
        )
        
        
    }
    
    render() {
        const { filters, scope } = this.state
        
        const { chart } = this.getProps()
        
        if (chart) {

            return (
                
                <Finder {...this.props} filters={filters}>
                    { this.renderChart() }
                    { this.renderTable() }
                </Finder>
            
            )
            
        }

        return (
            
            <Finder {...this.props} filters={filters} action={[{title: "Export", icon: "save_alt"}]}>
                { this.renderChart() }
                { this.renderTable() }
            </Finder>
        
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app,
    	theme: state.app.theme,
    	nav: state.nav,
    	site: state.site,
    	collection: state.collection,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsReport);