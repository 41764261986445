import { API } from '../settings';
import { requestSearch, receiveSearch } from '../actions/search';
import { requestModel, receiveModel } from '../actions/model';
import { requestEditorData, reveiceEditorData } from '../actions/editor';
import { getQueryParams } from './query';

// example data

import data from '@frontend-components/admin/es/resources/data/inbox.data';

// SEARCH

export function searchInbox(model, pathname, query) {
  
  let url = API + '/admin/api/' + model + '/search';
  
  const params = getQueryParams(query);
  
  if (params) {
    url = url + '?' + params;
  }

  console.log('Search inbox', query)
  
  return function(dispatch) {
      dispatch(receiveSearch(pathname, data))
  }

}

// GET MODEL

export function getInboxModel(model, uniqueId) {

  const url = API + '/admin/api/' + model + '/' + uniqueId;

  // API
  
  return function (dispatch) {

//    dispatch(requestModel(pathname))
    dispatch(receiveModel(uniqueId, data.models[0]))

  }
  
}
