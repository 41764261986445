export const getLocalizedStringSchema = (props) => {
    const { registry } = props;
    const { languages } = registry.formContext;
    
    let fieldSchema = {
        type: "object",
        properties: {}
    }
    
    languages.map(locale => {
        const localeId = "locale:" + locale
        const title = props.name || props.title;

        fieldSchema.properties[localeId] = {
            title: title + ":" + locale,
            type: "string"
        }
    })
    
    return fieldSchema;
}

export default getLocalizedStringSchema