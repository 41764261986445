import React from "react";
import DateField from "./DateField"

const DateWidget = ({options, ...props}) => {
    const label = options.title || props.title || props.label || null
    const helperText = options.help || null

    return (
        <DateField
            {...props}
            label={label}
            helperText={helperText}
        />
    )
}

export default DateWidget;