import React, { Component } from 'react';

import PreviewObject from "./PreviewObject";
import PreviewArray from "./PreviewArray";

import PreviewString from "./PreviewString";
import PreviewNumber from "./PreviewNumber";
import PreviewBoolean from "./PreviewBoolean";

import PreviewLocalizedString from "./PreviewLocalizedString";

import PreviewReference from "./PreviewReference";
import PreviewReferenceArray from "./PreviewReferenceArray";

import PreviewMediaArray from "./PreviewMediaArray";
import PreviewMedia from "./PreviewMedia/";

import {
    isMediaObject,
    isReferenceObject,
    isLocalizedString
} from "../Schema/utils"

import "./PreviewField.scss";

const templates = {
    "object": PreviewObject,
    "array": PreviewArray,
    "string": PreviewString,
    "number": PreviewNumber,
    "integer": PreviewNumber,
    "boolean": PreviewBoolean,
    "localizedString": PreviewLocalizedString,
    "reference": PreviewReference,
    "referenceArray": PreviewReferenceArray,
    "mediaArray": PreviewMediaArray,
    "media": PreviewMedia,
}


class PreviewField extends Component {
    
    renderChild = (name, index) => {
        
    }
    
    getClassName = () => {
        
        let classNames = []
        
        classNames.push('admin-preview__field');
        
        const template = this.getTemplate();
        
        if (template) {
            classNames.push('field--' + template);
        }
        
        return classNames.join(' ');
        
    }
    
    getTemplate = () => {

        const { schema } = this.props;
        
        let template;
        
        if (isMediaObject(schema)) {
            template = "media";          
        } else if (schema.type === "array" && isMediaObject(schema.items)) {
            template = "mediaArray";          

        } else if (isReferenceObject(schema)) {
            template = "reference";          
        } else if (schema.type === "array" && isReferenceObject(schema.items)) {
            template = "referenceArray";          

        } else if (isLocalizedString(schema)) {
            template = "localizedString";
        } else if (schema && schema.type) {
            template = schema.type;          
        }
        
        return template;
        
    }
    
    render() {
      
        const { id, schema, formData } = this.props;
        
        const className = this.getClassName();
        const template = this.getTemplate();
        
        if (template && templates[template]) {
          const FieldTemplate = templates[template]
        
          return (
                <FieldTemplate {...this.props} className={className}>
                    {this.props.children}
                </FieldTemplate>
          )
        
        }
        
        return this.props.children;
        
    }

}

export default PreviewField;