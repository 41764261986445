import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"

import NavSettings from "../NavSettings/NavSettings"
import NavToolbar from "../NavToolbar/NavToolbar"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    settings: {
        display: "flex",
    },
    toolbar: {
        display: "flex",
    },
}));

const getToolbar = ({editable, onEdit, removable, onRemove, deletable, onDelete, restorable, onRestore}) => {

    let toolbar = []

    if (editable || deletable || removable) {

        if (editable && onEdit) {
            toolbar.push({
                icon: "edit",
                onClick: onEdit
            })
        }

        if (removable && onRemove) {
            toolbar.push({
                icon: "remove_circle",
                onClick: onRemove
            })
        } else if (deletable && onDelete) {
            toolbar.push({
                icon: "delete",
                onClick: onDelete
            })
        } else if (restorable && onRestore) {
            toolbar.push({
                icon: "restore",
                onClick: onRestore
            })
        }

    }

    return toolbar;

}

const ModuleActionbar = ({ className, settings, toolbar, children, ...props }) => {
    const classes = useStyles()

    if (children) {
        return (
            <div className={className || classes.root}>
                {children}
            </div>
        )    
    }

    if (!toolbar) {
        toolbar = getToolbar(props)
    }

    if (settings || toolbar) {
        return (
            <div className={className || classes.root}>
                { settings && <NavSettings className={classes.settings} settings={settings} /> }
                { toolbar && <NavToolbar className={classes.toolbar} toolbar={toolbar} /> }
            </div>
        )    
    }

    return false

}

ModuleActionbar.propTypes = {
    settings: PropTypes.array,
    toolbar: PropTypes.array
}

export default ModuleActionbar;
