import children from './menuChildren';

import group from './group';
import documents from './documents';
import media from './media';
import mediaImage from './mediaImage';
import mediaVideo from './mediaVideo';
import mediaAudio from './mediaAudio';
import mediaChildren from './mediaChildren';
import search from './search';

import ekultur from './ekultur';
import latest from './latest';
import trash from './trash';

const groupWithChildren = {
    "properties": {
        ...group.properties,
        "children": children
    }
}

const documentsWithChildren = {
    "properties": {
        ...documents.properties,
        "children": children
    }
}

const searchWithChildren = {
    "properties": {
        ...search.properties,
        "children": children
    }
}

const mediaWithChildren = {
    "properties": {
        ...media.properties,
        "children": mediaChildren
    }
}

const imageWithChildren = {
    "properties": {
        ...mediaImage.properties,
        "children": children
    }
}

const videoWithChildren = {
    "properties": {
        ...mediaVideo.properties,
        "children": children
    }
}

const audioWithChildren = {
    "properties": {
        ...mediaAudio.properties,
        "children": children
    }
}

const ekulturWithChildren = {
    "properties": {
        ...ekultur.properties,
        "children": children
    }
}

const latestWithChildren = {
    "properties": {
        ...latest.properties,
        "children": children
    }
}

const trashWithChildren = {
    "properties": {
        ...trash.properties,
        "children": children
    }
}


export default {
    "type": "object",
    "properties": {
        "title": {
            "type": "string",
        },
        "pathname": {
            "type": "string",
        },
        "hidden": {
            "type": "boolean"
        },
        "role": {
            "type": "string",
            "enum": ["none","group"],
            "enumNames": ["Role","Group"],
            "default": "none"
        },
        "type": {
            "type": "string",
            "enum": ["home", "group", "documents", "media", "ekultur", "latest", "trash"],
            "default": "documents"
        }
    },
    "dependencies": {
        "type" : {
            "oneOf": [
                groupWithChildren,
                documentsWithChildren,
                mediaWithChildren,
//                imageWithChildren,
//                videoWithChildren,
//                audioWithChildren,
                ekulturWithChildren,
                trashWithChildren,
                latestWithChildren,
                searchWithChildren
            ]
        }
    }
}    