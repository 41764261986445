import topic from "@frontend-components/admin/lib/components/AppKp/schemas/topic"
import article from "@frontend-components/admin/lib/components/AppKp/schemas/article"
import chat from "./chat"

const questionnaire = {
    ...chat
}

const schemas = [
    topic,
    article,
    chat,
    questionnaire
]

export default schemas;