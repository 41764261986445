export const REQUEST_FINDER = 'REQUEST_FINDER'
export const RECEIVE_FINDER = 'RECEIVE_FINDER'

export const REQUEST_FINDER_QUERY = 'REQUEST_FINDER_QUERY'
export const RECEIVE_FINDER_QUERY = 'RECEIVE_FINDER_QUERY'

export const REQUEST_FINDER_RESULTS = 'REQUEST_FINDER_RESULTS'
export const RECEIVE_FINDER_RESULTS = 'RECEIVE_FINDER_RESULTS'

export const RECEIVE_FINDER_VIEW_OPTIONS = 'RECEIVE_FINDER_VIEW_OPTIONS'
export const RECEIVE_FINDER_SORT_OPTIONS = 'RECEIVE_FINDER_SORT_OPTIONS'

export const RECEIVE_FINDER_VIEW = 'RECEIVE_FINDER_VIEW'
export const RECEIVE_FINDER_SORT = 'RECEIVE_FINDER_SORT'

export const RECEIVE_BULK_TOGGLE = 'RECEIVE_BULK_TOGGLE'
export const RECEIVE_BULK_CHANGE = 'RECEIVE_BULK_CHANGE'

export const RECEIVE_MODEL_TOGGLE = 'RECEIVE_MODEL_CHANGE'
export const RECEIVE_MODEL_CHANGE = 'RECEIVE_MODEL_CHANGE'

export function requestFinder() {
    return {
        type: REQUEST_FINDER,
    }
}

 export function receiveFinder(finder) {
    return {
        type: RECEIVE_FINDER,
        finder: finder
    }
}

export function receiveFinderViewOptions(options) {
    return {
        type: RECEIVE_FINDER_VIEW_OPTIONS,
        options: options
    }
}

export function receiveFinderView(view) {
    return {
        type: RECEIVE_FINDER_VIEW,
        view: view
    }
}

export function receiveFinderSortOptions(options) {
    return {
        type: RECEIVE_FINDER_SORT_OPTIONS,
        options: options
    }
}

export function receiveFinderSort(sort) {
    return {
        type: RECEIVE_FINDER_SORT,
        sort: sort
    }
}

export function requestFinderQuery() {
    return {
        type: REQUEST_FINDER_QUERY,
    }
}

export function receiveFinderQuery(query) {
    return {
        type: RECEIVE_FINDER_QUERY,
        query: query
    }
}

export function requestFinderResults() {
    return {
        type: REQUEST_FINDER_RESULTS,
    }
 }

 export function receiveFinderResults(results) {
    return {
        type: RECEIVE_FINDER_RESULTS,
        results: results
    }
}

export function receiveModelToggle(id) {
    return {
        type: RECEIVE_MODEL_TOGGLE,
        id: id,
    }
}

export function receiveModelChange(id, data) {
    return {
        type: RECEIVE_MODEL_CHANGE,
        id: id,
        data: data
    }
}

export function receiveBulkToggle() {
    return {
        type: RECEIVE_BULK_TOGGLE,
    }
}

export function receiveBulkChange(data) {
    return {
        type: RECEIVE_BULK_CHANGE,
        data: data
    }
}