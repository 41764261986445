import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearch } from '../redux/functions/search';

import InboxResults from './InboxResults';
import qs from 'query-string';

const InboxQuery = (props) => {
    let { query, searchById } = props;

    let id = query.id || props.location && props.location.pathname
    let sq = props.location && props.location.search && qs.parse(props.location.search) || {}

    let { page, sort, start, rows, q } = sq

    sort = sort || query.sort || "title ASC"

    if (!start) {
        start = 0
    }

    rows = rows || query.rows || 20

    if (page) {
        start = rows * (page-1)
    }

    if (query.q && q) {
        q = q + " " + q + "*";
    } else if (q) {
        q = q + "*"
    } else if (query.q) {
        q = query.q
    }

    let fl

    if (query.fl) {
        fl = query.fl
    } else {
        fl = "uniqueId,documentType,mediaType,title,description,status,mediaWidth,mediaHeight"
    }

    console.log('query',query)

    query = {
        ...query,
        models: "documents",
        documentType: "message",
        id: id,
        page: page,
        start: start,
        rows: rows,
        sort: sort,
        q: q || "",
        fl: fl
    }

    useEffect(() => {
        props.getSearch(query);
    }, [id, q, start, rows, page, sort])

    const results = searchById && searchById[id] || props.defaultResults;

    // pages
    
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)

    const getPages = () => {
        const resultsPages = Math.ceil(results.count/results.rows)
        const resultsPage = Math.ceil((results.start+results.rows)/results.rows)

        setPages(resultsPages)
        setCurrentPage(resultsPage)
    }

    useEffect(() => {
        results && results.count && getPages()
    }, [results])

    // handlePage

    const handlePage = (page) => {

        const pageQuery = qs.stringify({
            ...sq,   
            page: page
        })

        const url = props.location.pathname + "?" + pageQuery;
        
        props.history.replace(url)

    }

    // results

    return (
        <InboxResults {...props} {...results} query={query} pages={pages} page={currentPage} onPage={handlePage}/>
    )

}

InboxQuery.defaultProps = {
    query: {
    }
}

const mapStateToProps = (state) => {
	return {
        searchById: state.searchById
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getSearch
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InboxQuery);