import React, { Component } from "react";

import * as types from "../utils/types";

import {
    getWidget,
    getFieldSchema,
    getDefaultFormState,
    isMultiSelect,
    isFilesArray,
    isFixedItems,
    allowAdditionalItems,
    optionsList,
    retrieveSchema,
    toIdSchema,
    getDefaultRegistry,
} from "../utils/";

import ArrayFieldNormal from "./ArrayFieldNormal";
import ArrayFieldFixed from "./ArrayFieldFixed";
import ArrayFieldFiles from "./ArrayFieldFiles";
import ArrayFieldSelect from "./ArrayFieldSelect";

class ArrayField extends Component {
    static defaultProps = {
        uiSchema: {},
        formData: [],
        idSchema: {},
        required: false,
        disabled: false,
        readonly: false,
        autofocus: false,
    };
    
    static propTypes = types.fieldProps;

    canAddItem = (formItems) => {
        const { schema, uiOptions } = this.props;
        let { addable } = uiOptions;

        if (addable !== false) {
            // if ui:options.addable was not explicitly set to false, we can add
            // another item if we have not exceeded maxItems yet
            if (schema.maxItems !== undefined) {
                addable = formItems.length < schema.maxItems;
            } else {
                addable = true;
            }
        }

        return addable;
    }

    canRemoveItem = (formItems) => {
        const { schema, uiOptions } = this.props;
        let { removable } = uiOptions;

        if (removable !== false) {
            if (schema.minItems !== undefined) {
                removable = formItems.length > schema.minItems;
            } else {
                removable = true;
            }
        }

        return removable;
    }    

    onAddItem = (item) => {
        console.log('array:onAddItem', item)

        const { schema, formData, formContext, registry } = this.props;
        const { definitions } = registry;

        let itemSchema = schema.items;

        if (isFixedItems(schema) && allowAdditionalItems(schema)) {
            itemSchema = schema.additionalItems;
        }

        const newFormData = [
            ...formData,
            getDefaultFormState(itemSchema, item, definitions),
        ]

        this.onChange(newFormData);

        if (formContext.onAfterAdd) {
            setTimeout(() => {
                formContext.onAfterAdd({
                    ...this.props,
                    formData: newFormData
                })
            })
        }


    }
    
    onChange = (formData) => {
        console.log('array:onChange', formData)
        const { formContext } = this.props;

        if (this.props.onChange) {
            this.props.onChange(formData);
        }

        if (formContext.onAfterChange) {
            setTimeout(() => {
                formContext.onAfterChange({
                    ...this.props,
                    formData: formData
                })
            })
        }

    }
    
    onChangeIndex = (index, value, errorSchema) => {
        const { formData } = this.props;
        
        console.log('onChangeIndex', index);
        console.log('value', value);
        
        const newFormData = formData.map((item, i) => {
            // We need to treat undefined items as nulls to have validation.
            // See https://github.com/tdegrunt/jsonschema/issues/206
            const jsonValue = typeof value === "undefined" ? null : value;
            return index === i ? jsonValue : item;
        });

        this.onChange(
            newFormData,
            errorSchema &&
            this.props.errorSchema && {
                ...this.props.errorSchema,
                [index]: errorSchema,
            }
        );

    }

    onInsertAtIndex = (item, index) => {
        console.log('array:onInsertAtIndex', index)

        const { schema, formData, registry, canAdd } = this.props;
        const { definitions } = registry;

        let itemSchema = schema.items;

        if (isFixedItems(schema) && allowAdditionalItems(schema)) {
            itemSchema = schema.additionalItems;
        }
        

        const beforeIndex = formData.slice(0,index);
        const afterIndex = formData.slice(index);
        
        console.log('beforeIndex', beforeIndex);
        console.log('afterIndex', afterIndex);
        
        const newFormData = [].concat(beforeIndex, getDefaultFormState(itemSchema, item, definitions), afterIndex);
        
        console.log('newFormData', newFormData);

        if (newFormData) {
            this.onChange(newFormData);
        }

        
    }

    onReorderIndex = (index, newIndex) => {
        console.log('array:onReorderIndex', index)

        const { formData } = this.props;

        // errorSchema

        let newErrorSchema;

        if (this.props.errorSchema) {
            newErrorSchema = {};
            const errorSchema = this.props.errorSchema;
            for (let i in errorSchema) {
                if (i == index) {
                    newErrorSchema[newIndex] = errorSchema[index];
                } else if (i == newIndex) {
                    newErrorSchema[index] = errorSchema[newIndex];
                } else {
                    newErrorSchema[i] = errorSchema[i];
                }
            }
        }

        // Copy item
        let newFormData = formData.slice();

        // Moves item from index to newIndex
        newFormData.splice(index, 1);
        newFormData.splice(newIndex, 0, formData[index]);

        // Array on Change
        this.onChange(newFormData, newErrorSchema);

    };

    onRemoveIndex = (index) => {
        console.log('array:onRemoveIndex', index)

        const { formData, formContext } = this.props;
        // refs #195: revalidate to ensure properly reindexing errors
        let newErrorSchema;
        if (this.props.errorSchema) {
            newErrorSchema = {};
            const errorSchema = this.props.errorSchema;
            for (let i in errorSchema) {
                i = parseInt(i);
                if (i < index) {
                    newErrorSchema[i] = errorSchema[i];
                } else if (i > index) {
                    newErrorSchema[i - 1] = errorSchema[i];
                }
            }
        }

        const newFormData = formData.filter((_, i) => i !== index)

        this.onChange(newFormData, newErrorSchema);

        if (formContext.onAfterRemove) {
            setTimeout(() => {
                formContext.onAfterRemove({
                    ...this.props,
                    formData: newFormData
                })
            })
        }

    }

    renderFixedArray = () => {
        const { schema, uiSchema, formData, registry } = this.props;
        const { definitions } = registry;

        // items

        let items = this.props.formData;

        const itemSchemas = schema.items.map((item, index) =>
            retrieveSchema(item, definitions, formData[index])
        );

        const additionalSchema = allowAdditionalItems(schema)
            ? retrieveSchema(schema.additionalItems, definitions, formData)
            : null;

        if (!items || items.length < itemSchemas.length) {
            // to make sure at least all fixed items are generated
            items = items || [];
            items = items.concat(new Array(itemSchemas.length - items.length));
        }

        const canAdd = this.canAddItem(items) && additionalSchema
//        const canRemove = this.canRemoveItem(items) 

        return (
            <ArrayFieldFixed {...this.props} 
                itemSchemas={itemSchemas} 
                items={items} 
                canAdd={canAdd}
//                canRemove={canRemove}
                onChange={this.onChange}
                onChangeIndex={this.onChangeIndex}
                onAddItem={this.onAddItem}
                onReorderIndex={this.onReorderIndex}
                onInsertAtIndex={this.onInsertAtIndex}
                onRemoveIndex={this.onRemoveIndex} />
        )

    }

    renderNormalArray = () => {
        const { formData } = this.props;

        const items = formData;
        const canAdd = this.canAddItem(formData)
        const canRemove = this.canRemoveItem(formData)

        return (
            <ArrayFieldNormal {...this.props} 
                items={items} 
                canAdd={canAdd}
                canRemove={canRemove}
                onChange={this.onChange}
                onChangeIndex={this.onChangeIndex}
                onAddItem={this.onAddItem}
                onReorderIndex={this.onReorderIndex}
                onInsertAtIndex={this.onInsertAtIndex}
                onRemoveIndex={this.onRemoveIndex} />
        )
        
    }
    
    render() {
        const {
            schema,
            uiSchema,
            idSchema,
            registry
        } = this.props;

        const { definitions } = registry;
        const { UnsupportedField } = registry.fields;

        if (!schema.hasOwnProperty("items")) {
            return (
                <UnsupportedField
                    schema={schema}
                    idSchema={idSchema}
                    reason="Missing items definition"
                />
            );
        }

        if (isFilesArray(schema, uiSchema, definitions)) {
            return (
                <ArrayFieldFiles {...this.props} />
            )
        }

        if (isMultiSelect(schema, definitions)) {
            return (
                <ArrayFieldSelect {...this.props} />
            )
        }

        if (isFixedItems(schema)) {
            return this.renderFixedArray()
        }
        
        return this.renderNormalArray()

    }

}

export default ArrayField;
