import { getLocalizedStringField } from "./utils"
import LocalizedStringField from "./LocalizedStringField"

export default {
    "fieldtypes": {
        "localizedString": getLocalizedStringField
    },
    "fields": {
        "localizedString": LocalizedStringField
    },
    "layouts": {
    },
    "widgets": {
    }
}