import React, { Component } from 'react';
import includes from "core-js/library/fn/array/includes";

class ArrayFieldItem extends Component {

    isItemRequired(itemSchema) {
        if (Array.isArray(itemSchema.type)) {
            // While we don't yet support composite/nullable jsonschema types, it's
            // future-proof to check for requirement against these.
            return !includes(itemSchema.type, "null");
        }
        // All non-null array item types are inherently required by design
        return itemSchema.type !== "null";
    }
    
    onChange = (itemData, errorSchema) => {
        const { itemSchema, index } = this.props;

        // set _action prop

        if (itemSchema.type === "object" && itemSchema.properties.hasOwnProperty('_action')) {
            
            if (itemData._action !== "delete" && itemData._action !== "create") {
                itemData._action = "update"
            }
            
        }        

        console.log('arrayItem:onChange', itemData)
        this.props.onChangeIndex(index, itemData, errorSchema)

    }
    
    onMoveUp = () => {
        const { index } = this.props;
        this.props.onReorderIndex(index, index-1)
    }

    onMoveDown = () => {
        const { index } = this.props;
        this.props.onReorderIndex(index, index+1)
    }
    
    onRemoveItem = () => {
        const { index } = this.props;
        this.props.onRemoveIndex(index)
    }
    
    onAddBefore = (item) => {
        const { index } = this.props;
        this.props.onInsertAtIndex(item, index)
    }

    onAddAfter = (item) => {
        const { index } = this.props;
        this.props.onInsertAtIndex(item, index+1)
    }
    
    render() {
    
        const {
            index,
            canRemove = true,
            canMoveUp = true,
            canMoveDown = true,
            itemSchema,
            itemData,
            itemUiSchema,
            
            itemId,
            itemIdSchema,
            itemIdPrefix,
            itemErrorSchema,

            autofocus,
            disabled,
            readonly,
            uiSchema,
            registry,
            onBlur,
            onFocus,
            rawErrors,
        } = this.props;
    
        const { orderable, removable } = {
            orderable: true,
            removable: true,
            ...uiSchema["ui:options"],
        };
        
        const required = this.isItemRequired(itemSchema)
        
        const has = {
            moveUp: orderable && canMoveUp,
            moveDown: orderable && canMoveDown,
            remove: removable && canRemove,
        };
    
        has.toolbar = Object.keys(has).some(key => has[key]);

        const { SchemaField } = registry.fields
        
        return (
    
            <SchemaField
                formData={itemData}
                schema={itemSchema}
                uiSchema={itemUiSchema}
                idSchema={itemIdSchema}
                errorSchema={itemErrorSchema}

                required={required}
                orderable={orderable}
                removable={removable}
    
                onBlur={onBlur}
                onFocus={onFocus}

                registry={{
                    ...registry,
                    formContext: {
                        ...registry.formContext,
                        onRemove: this.onRemoveItem
                    }
                }}

                disabled={this.props.disabled}
                readonly={this.props.readonly}
                autofocus={autofocus}
                rawErrors={rawErrors}
                
                arrayIndex={index}
                isArrayItem={true}

                idPrefix={itemId}
                id={itemId}
                
                index={index}
    
                hasToolbar={has.toolbar}
                hasMoveUp={has.moveUp}
                hasMoveDown={has.moveDown}
                hasRemove={has.remove}
    
                onChange={this.onChange}

                onMoveUp={this.onMoveUp}
                onMoveDown={this.onMoveDown}
    
                onRemoveItem={this.onRemoveItem}

                onAddBefore={this.onAddBefore}
                onAddAfter={this.onAddAfter}
    
    
            />
    
            
        )

    }
    
}

export default ArrayFieldItem;