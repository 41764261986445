import React, { Component } from 'react';

class FieldErrors extends Component {
    renderError = (error, index) => {
        return (
            <li key={index} className="error">{error}</li>
        )
    }

    render() {
        const { errors } = this.props;

        if (!errors) {
            return false;
        }

        return (
            <p>{JSON.stringify(errors)}</p>
        )
    }
}

export default FieldErrors;