// initialState

let initialState = {
};

// app state

const schemas = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'REQUEST_SCHEMAS':

            return initialState

        case 'REQUEST_SCHEMA':

            return {
                ...state,
                [action.pathname]: undefined
            }

        case 'RECEIVE_SCHEMA':

            return {
                ...state,
                [action.pathname]: action.data
            }

        default:

            return state
    }

}

export default schemas;