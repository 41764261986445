import { getAppData } from '../../redux/functions/app';
import { getMenuMedia, getMenuLatest, getMenuTrash, getMenuEkultur } from '../../redux/functions/menu';
import { getMenuChildren } from '../../AppCollection/app';
import schemas from '../schemas/';
import menuChildren from "./menu";

export function getApp(appData) {
    const { site, collection } = appData;

    const title = "Kiosk"
    const name = "kiosk"
    const root = "/" + site.name + "/" + name

    const appProps = {
        uniqueId: collection && collection.id,
        siteId: site && site.id,
        collectionId: collection && collection.id,
        root: root
    }
    
    const primaryAction = getAppAction(appProps)
    const menu = getMenuChildren({children: menuChildren, ...appProps})

    const search = {
        "placeholder": "Søk i " + title
    }
    
    const app = {
        ...appData,
        ...appProps,
        title: title,
        name: name,
        layout: "finder",
        theme: {
            primaryColor: "#00C18A"
        },
        primaryAction: primaryAction,
        search: search,
        menu: menu,
        menuTree: root,
        schemas: schemas,
        languages: [
            "no", "sv", "en", "de", "es", "fr"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
    }

}


export function getAppAction(app) {
    const { root } = app;

    return {
        title: "Nytt innhold",
        icon: "",
        children: [
            /*
            {
                title: "Topic",
                url: root + "/topic/<new>/edit",
            },
            */
            {
                title: "Artikkel",
                url: root + "/article/<new>/edit",
            },
            {
                "role": "group",
                "children": [
                    {
                        title: "Liste",
                        url: root + "/page/list/<new>/edit",
                    },
                    {
                        title: "Mosaikk",
                        url: root + "/page/grid/<new>/edit",
                    },
                    {
                        title: "Tidslinje",
                        url: root + "/page/timeline/<new>/edit",
                    },
                    {
                        title: "Bilde, video og lyd",
                        url: root + "/page/media/<new>/edit",
                    },
                    {
                        title: "Bildekart",
                        url: root + "/page/annotate/<new>/edit",
                    }
                ]
            },
            {
                role: "group",
                title: "Enhet",
                url: root + "/device/<new>/edit",
            },
            {
                role: "group",
                title: "Last opp",
                icon: "cloud_upload",
                url: root + "/media/upload",
                action: {
                    actionType: "upload"
                }
            }
        ]
    }

}