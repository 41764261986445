import React, { Component } from 'react';

class PreviewObject extends Component {
    
  render() {
      
      const { id, schema, formData } = this.props;
      
      return (
          <section className="admin-preview__field  field--object">
            { this.props.children }
          </section>
      )

  }

}

export default PreviewObject;