import SchemaField from "./SchemaField";

import SchemaLayout from "./SchemaLayout";
import SchemaFieldset from "./SchemaFieldset";

import ArrayField from "./ArrayField";
import BooleanField from "./BooleanField";
import DescriptionField from "./DescriptionField";
import MultiSchemaField from "./MultiSchemaField";
import NumberField from "./NumberField";
import ObjectField from "./ObjectField";
import StringField from "./StringField";
import TitleField from "./TitleField";
import UnsupportedField from "./UnsupportedField";


/*
import {
    MediaObject,
    MediaPreviewObject,
    ImageCropdataObject,
    ImageFocalpointObject,
    ImageFiltersObject,
    ImageAdjustObject
} from "../../Media"

import { AnnotateImageObject } from "../../AnnotateImage"
*/

import registry from  "../../registry"

export default {
    AnyOfField: MultiSchemaField,
    ArrayField,
    BooleanField,
    DescriptionField,
    NumberField,
    ObjectField,
    OneOfField: MultiSchemaField,
    SchemaField,
    StringField,
    TitleField,
    UnsupportedField,
    
    SchemaLayout,
    SchemaFieldset,
    
    "object": ObjectField,

    ...registry.fields,
};
