import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InboxModule from "./InboxModule"

class InboxResults extends Component {
    static propTypes = {
        urlPattern: PropTypes.string,
        moduleSize: PropTypes.string,
        root: PropTypes.string,
        results: PropTypes.array,
    }

    static defaultProps = {
        urlPattern: '/inbox/:uniqueId',
        results: []
    }
    
    renderItem = (item, index) => {
        const { urlPattern, moduleSize, onSelect } = this.props;
        
        return (
            <InboxModule {...item} urlPattern={urlPattern} moduleSize={moduleSize} onSelect={onSelect} key={index} />
        )
        
    }
    
    render() {
        const { results } = this.props
        
        return (
            <div className="admin-inbox__results">
                { results.map(this.renderItem) }
            </div>
        )
        
    }

}

export default InboxResults;