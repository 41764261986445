export default {
    type: "array",
    items: {
        type: "object",
        properties: {
            title: {
                type: "string",
                readonly: true
            },
            appType: {
                type: "string",
                readonly: true
            },
            appId: {
                type: "string",
                readonly: true
            },
            accessType: {
                type: "string",
                enum: [
                    "full",
                    "demo"
                ]
            }
        }
    }
}