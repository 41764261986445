import React, { Component } from 'react';

import AppBase from "../App/AppBase" 
import AppHeader from "../App/AppHeader"
import AppNav from "../App/AppNav" 
import AppBody from "../App/AppBody" 
import AppContent from "../App/AppContent" 
import AppSidebar from "../App/AppSidebar" 

const layouts = {
    "default": {
        nav: {
            expanded: true,
        },
        header: {
            expanded: false
        },
        sidebar: {
            expanded: false
        } 
    },
    "dashboard": {
        nav: {
            expanded: true,
        },
        header: {
            expanded: true
        },
        sidebar: {
            expanded: false
        },
        search: {
            disabled: true
        }
    },
    "finder": {
        nav: {
            expanded: true,
        },
        header: {
            expanded: true
        },
        sidebar: {
            expanded: true
        },
        subview: {
            expanded: false
        } 
    },
    "finder/search": {
        nav: {
            expanded: false,
        },
        header: {
            expanded: true
        },
        sidebar: {
            expanded: true
        },
        search: {
            expanded: true
        },
        subview: {
            expanded: false
        } 
    },
    "finder/upload": {
        nav: {
            expanded: true,
        },
        header: {
            expanded: true
        },
        sidebar: {
            expanded: false
        },
        subview: {
            expanded: true
        } 
    },
    "finder/subview": {
        nav: {
            expanded: true,
        },
        header: {
            expanded: true
        },
        sidebar: {
            expanded: false
        },
        subview: {
            expanded: true
        } 
    },
    "editor": {
        nav: {
            expanded: false,
        },
        header: {
            expanded: false
        },
        sidebar: {
            expanded: false
        } 
    }
}

class LayoutAdmin extends Component {
  
    static defaultProps = {
        app: {
            title: "App",
            subtitle: "Owner or user",
            theme: undefined,
            layout: undefined,
            version: 0.1
        },
        theme: undefined,
        layout: undefined,
        user: {
            uniqueId: undefined
        },
        owner: {
            uniqueId: undefined
        }
    }
    
    state = {
        theme: undefined,
        nav: {
            expanded: true
        },
        header: {
            expanded: false
        },
        sidebar: {
            expanded: false
        },
        search: {
            expanded: false
        },
        subview: {
            expanded: false
        }
    }
    
    // update state
    
    componentDidMount() {
        this.getApp()
    }

    componentDidUpdate = (prevProps, prevState) => {
    
        if (prevProps.app !== this.props.app) {
            this.getApp()
        } else {
            if (prevProps.theme !== this.props.theme) {
                this.getTheme()
            }
            
            if (prevProps.layout !== this.props.layout) {
                this.getLayout()
            } 

            if (prevProps.search !== this.props.search) {
                this.getSearch()
            }
            
            if (prevProps.subview !== this.props.subview) {
                this.getSubview()
            }
        }

    }
    
    getApp = () => {
        const theme = this.props.theme || this.props.app.theme || undefined
        const layout = this.props.layout || this.props.app.layout || undefined
        const search = this.props.search || this.props.app.search || undefined;
        const subview = this.props.subview || this.props.app.subview || undefined;
        
        let layoutState;
        
        if (layouts[layout]) {
            layoutState = layouts[layout]
        } else if (typeof layout === 'object' && layout !== null) {
            layoutState = layout
        } else {
            layoutState = layouts['default']
        }

        this.setState({
            ...this.state,
            ...layoutState,
            theme: theme,
            search: search,
            subview: subview
         })

    }
    
    getTheme = () => {
        const theme = this.props.theme || this.props.app.theme || undefined

        this.setState({
            theme: theme
        })
        
    }

    getLayout = () => {
        const layout = this.props.layout || this.props.app.layout || undefined

        let layoutState;
        
        if (layouts[layout]) {
            layoutState = layouts[layout]
        } else if (typeof layout === 'object' && layout !== null) {
            layoutState = layout
        } else {
            layoutState = layouts['default']
        }
        
        this.setState({
            ...this.state,
            ...layoutState
         })
        
    }

    getSearch = () => {
        const { search } = this.props;
        
        if (search) {
           this.setState({
                search: search
            })
        }
        
    }

    getSubview = () => {
        const { subview } = this.props;
        
        if (subview) {
           this.setState({
                subview: subview
            })
        }
        
    }

    // sidebar button + menu

    renderSidebar = () => {
        const { sidebar } = this.state;
        const { onSelect } = this.props;
        const button = this.props.button || this.props.app.button || undefined;
        const edit = this.props.edit || this.props.app.edit || button && [button] || []
        const menu = this.props.menu || this.props.app.menu || undefined;
        const menuByUrl = this.props.menuByUrl || this.props.app.menuByUrl || undefined;
        const currentUrl = this.props.currentUrl || this.props.url || undefined;

        return (
            <AppSidebar expanded={sidebar.expanded || false} onSelect={onSelect} edit={edit} menu={menu} menuByUrl={menuByUrl} onSelect={onSelect} currentUrl={currentUrl} />
        )

    }
    
    render() {
        const { app, user, owner, onSelect, onToggle, onLogin, onLogout } = this.props;
        const { nav, theme, header, search, subview } = this.state;

        const title = app && app.title;
        const subtitle = owner && owner.uniqueId && owner.title || user && user.uniqueId && user.name
   
        return (
            <AppBase theme={theme || undefined}>
                <AppNav expanded={nav && nav.expanded} app={app} user={user} owner={owner} onLogin={onLogin} onLogout={onLogout} onSelect={onSelect} />
                <AppHeader expanded={header && header.expanded} title={title} subtitle={subtitle} subview={subview} search={search || false} onToggle={onToggle}></AppHeader>
                <AppBody expanded={header && !header.expanded}>
                    { this.renderSidebar() }
                    <AppContent>
                        { this.props.children }
                    </AppContent>
                </AppBody>
            </AppBase>
    
        )
    
    }

}

export default LayoutAdmin;

