export default {
  "select": {
    "name" : "name",
    "abbr" : "abbr",
    "title" : "title",
    "description" : "description",
    "metadata" : "metadata",
    "theme": "theme",
    "label": "label"
  },
  prepare({formData}) {
    let { label, name, title, description, content } = formData

    let theme = {}, abbr;
    
    if (content) {
      name = content.name || name;
      title = content.title || "Untitled site";
      description = content.description || "";
      
      abbr = name && name.substr(0,3).toUpperCase()
      
      if (content.colors && content.colors.length) {
          theme = {
              primaryColor: content.colors[0].value
          }
      }
      
    }
    
    let alpha = title || name;
    
    if (alpha) {
        alpha = alpha.substring(0,1);
    }
    
    
    let metadata = []
    
    if (!label) {
        label = "Owner"
    }
    
    return {
      name: name,
      alpha: alpha,
      abbr: abbr,
      title: title,
      description: description,
      metadata: metadata,
      theme: theme,
      label: label
    }
    
  }
  
}