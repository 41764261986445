import React, { Component } from 'react';

class DashboardLabel extends Component {

    render() {
        const { label } = this.props;
        
        if (!label) {
            return false
        }

        return (
            <p className="admin-dashboard__label">{ label }</p>
        )
    }
}

export default DashboardLabel;