import settings from "./settings/uiSchema";
import header from "./header/uiSchema";
import hasArticles from "./hasArticles/uiSchema";
import uiPreview from "./uiPreview";

export default {
    "ui:preview": uiPreview,
    "ui:field": "pageEditor",
    "ui:fieldset": [
        "content",
        "settings"
    ],
    "settings": settings,
    "content": {
        "ui:field": "pageContent",
        "ui:fieldset": [
            "header",
            "hasArticles",
        ],
        "header": header,
        "hasArticles": hasArticles
    }
}