import React, { useState, useEffect } from 'react';

import InboxModel from "./InboxModel"

const InboxResults = ({models = [], ...props}) => {

    return (
        <div>
            { models.map(model => {
                return (
                    <InboxModel {...props} model={model} />
                )
            })}
        </div>
    )

}

export default InboxResults