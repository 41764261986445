import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppFinder from '../AppFinder';

const SchemaFinder = (props) => {

    const query = {
        models: "schemas",
        status: "NOT trash",
        sort: "title",
        fl: "uniqueId,status,title"
    }

    const editUrlPattern = "/admin/schema/:uniqueId/edit"

    return (
        <AppFinder {...props} query={query} editUrlPattern={editUrlPattern} />
    )

}

function mapStateToProps(state) {
	return {
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaFinder);
