import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReports } from '../redux/functions/reports';

import { AnalyticsReportRegVsPub } from '@frontend-components/admin';

import { FormattedMessage } from "react-intl";
import messages from "./WidgetRegVsPub.messages";

class WidgetRegVsPub extends Component {

    static defaultProps = {
        query: {
            reportType: "primusVsDm",
            countryId: "no",
            offset: 0,
            owner: 1,
            rows: 1000,
            startDate: "90daysAgo",
            endDate: "yesterday",
            dimensions: "countryIsoCode,country",
            metrics: "users",
            orderBy: "countryIsoCode",
            sort: "descending",
        }
    }
    
    state = {}
    
    componentDidMount() {
        this.getReport()
    }
    
    getReport = () => {
        const { id, query } = this.props;
        
        this.props.getReports({
            ...query,
            id: id
        })

        this.setState({
            loadingTitle: <FormattedMessage {...messages['loadingTitle']} />,
            title: <FormattedMessage {...messages['title']} />
        })
        
    }
    
    render() {
        const { data, id } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[1]
        
        return (
            <AnalyticsReportRegVsPub {...this.props} {...this.state} report={report || undefined}  />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetRegVsPub);