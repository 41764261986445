import React, { Component } from 'react';

import { Dropdown } from "../Dropdown/";
import { ButtonSetting } from '../Button/';
import ButtongroupOptions from './ButtongroupOptions';

import './ButtongroupSettings.scss';

class ButtongroupSettings extends Component {
    static defaultProps = {
        name: "settings",
        expanded: false
    }

    state = this.props;

    onToggle = () => {
        this.setState((prevState) => ({
            expanded: !prevState.expanded
        }));
    }

    getSelected = () => {
        const { value, options } = this.props;
        let selected = options.find(option => option.value === value);

        if (!selected) {
            selected = options[0];
        }

        return selected;
    }

    render() {
        const { name, expanded } = this.state;
        const selected = this.getSelected();

        return (
            <div className="admin-buttongroup  admin-buttongroup--settings" role="option" aria-selected={selected}>
                <ButtonSetting name={name} label={selected.title} icon={selected.icon} expanded={expanded} onClick={this.onToggle} />
                <Dropdown type="settings" expanded={expanded}>
                    <ButtongroupOptions {...this.props} />
                </Dropdown>
            </div>
        )
    }
}

export default ButtongroupSettings;
