import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

// store
import store from './store';

// i18next
import './i18n';

// App
import App from './App';

// Routes

const Routes = () => (
    <Suspense fallback="loading">
        <Provider store={ store }>
            <BrowserRouter>
                <Route path="/" component={ App } />
            </BrowserRouter>
        </Provider>
    </Suspense>
);

// Render

ReactDOM.render(<Routes />, document.getElementById('root'));