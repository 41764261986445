import pageMedia from './pageMedia.svg';
import pageList from './pageList.svg';
import pageTimeline from './pageTimeline.svg';
import pageMosaic from './pageMosaic.svg';
import pageAnnotate from './pageAnnotate.svg';


export default {
    "page/media": pageMedia,
    "page/list": pageList,
    "page/timeline": pageTimeline,
    "page/mosaic": pageMosaic,
    "page/annotate": pageAnnotate
}