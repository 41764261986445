import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import "./FieldSuggest.scss"

class FieldSuggest extends Component {
    
    static defaultProps = {
        suggestions: [],
        value: "",
        placeholder: "",
    }
    
    state = {
        value: ""
    }

    onChange = (event, { newValue }) => {

        if (this.props.onChange) {
            this.props.onChange(newValue, event)
        }

    }

    onFetchRequested = ({ value }) => {
        
        if (this.props.onSuggest) {
            this.props.onSuggest(value)
        }
        
    }

    onClearRequested = () => {
        
        if (this.props.onSuggest) {
            this.props.onSuggest()
        }
        
    }
    
    onSelect = (item) => {
        
        if (this.props.onSelect) {
            return this.props.onSelect(item)
        }
        
        return item
        
    }
    
    renderSuggestion = (item) => {

        if (this.props.renderItem) {
            return this.props.renderItem(item)
        }
        
        return (
            <React.Fragment>
                { JSON.stringify(item) }
            </React.Fragment>
        )
        
    }
    
    render() {
        const { placeholder, value, suggestions } = this.props;

        const theme = {
            container: 'admin-field--suggest',
            containerOpen: 'admin-field--suggest--open',
            input: 'admin-field__input',
            inputOpen: 'admin-field__input--open',
            inputFocused: 'admin-field__input--focus',
            itemsContainer: 'admin-field__suggest',
            itemsContainerOpen: 'admin-field__suggest--open',
            itemsList: 'admin-field__suggest-list',
            item: 'admin-field__suggest-item',
            itemFirst: 'admin-field__suggest-item--first',
            itemHighlighted: 'admin-field__suggest-item--highlight',
            sectionContainer: 'admin-field__suggest-section',
            sectionContainerFirst: 'admin-field__suggest-section--first',
            sectionTitle: 'admin-field__suggest-section__title'
        };

        const inputProps = {
            placeholder: placeholder,
            value,
            onChange: this.onChange
        }
        
        return (

            <Autosuggest
                theme={theme}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onFetchRequested}
                onSuggestionsClearRequested={this.onClearRequested}
                getSuggestionValue={this.onSelect}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
              />
            
        )

    }
}

export default FieldSuggest;