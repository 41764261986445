import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadModel, editModel, saveModel } from '../redux/functions/model';
import { getSearch } from '../redux/functions/search';

import AppEditor from "../AppEditor"

import SidebarTemplate from "../AppDialog/Sidebar"
import PreviewTemplate from "./CollectionPreview/"

import model from "./schemas/site"
import registry from "./components/registry"

const CollectionEditor = ({app, modelsById, searchById, models = "sites", ...props}) => {
    const { uniqueId } = props.match.params;

    const onLoad = () => {
        if (uniqueId == "<new>") {
            props.editModel({
                status: "new",
//                collectionType: collectionType
            });
        } else if (models && uniqueId) {
            props.loadModel(models, uniqueId);
        }
    }

    useEffect(() => {
        onLoad()
    }, [uniqueId])

    const uniqueModel = uniqueId && modelsById && modelsById[uniqueId]
    const siteId = uniqueModel && uniqueModel.id
    const updatedAt = uniqueModel && uniqueModel.updatedAt

    const onSubmit = ({formData, redirect}) => {

        props.saveModel(models, formData, redirect && false)

        if (redirect) {
            props.history.replace(redirect);
        }

    }

    if (!app.uniqueId) {
        return "Loading"
    }

    const formContext = {
        parents: app && app.parents,
        languages: app && app.languages,
        siteId: siteId,
        preview: {
            template: PreviewTemplate
        },
        sidebar: {
            template: SidebarTemplate
        }
    }

    return (
        <AppEditor {...props}
            registry={registry}
            schema={model.schema}
            uiSchema={model.uiSchema}
            formContext={formContext}
            onSubmit={onSubmit}
        />
    )


}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        modelsById: state.modelsById,
        searchById: state.searchById,
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        loadModel, editModel, saveModel,
        getSearch
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CollectionEditor);

