import React, { Component } from 'react';
import PropTypes from "prop-types";

import { Widget, WidgetHeader, WidgetTitle, WidgetDescription, WidgetBody, WidgetFooter, WidgetLoading } from "../"
import { Chart } from "../"

import "./DataWidget.scss"

class DataWidget extends Component {

    static defaultProps = {
        expanded: true,
        widgetType: "data",
        widgetSize: "1:1",
        chart: {
            type: undefined,
            metric: undefined,
            range: undefined,
            compare: undefined,
            stacked: undefined
        }
    }
    
    getSize = () => {
        const { widgetSize } = this.props;
        
        if (!widgetSize) {
          return {}
        }
        
        const grid = widgetSize.split(":")
        
        return {
            cols: parseInt(grid[0]),
            rows: parseInt(grid[1])
        }
        
    }
    
    getChart = () => {
        const { chart, theme, widgetSize } = this.props;
        const { cols, rows } = this.getSize()
        
        let padding, margin, offset;

        margin = {
            top: 60,
            right: 0,
            bottom: 0,
            left: 0
        }
        
        if (cols && cols >= 2) {
            margin.top = 75
        } else {
            
            
        }

        offset = {
            top: margin.top * -1,
            right: 0,
            bottom: 0,
            left: 0
        }
        
        return {
            ...chart,
            margin: margin,
            offset: offset,
            theme: theme
        }
        
    }
    
    renderChart = () => {
        const chart = this.getChart()
        
        if (chart) {
            return (
                <Chart {...chart} />
            )
        }
        
    }
    
    render() {
        const { loading, chart, title, titleUrl, description } = this.props
        
        if (loading) {

            return (
                <Widget {...this.props}>
                    <WidgetLoading title={title}  description={description} />
                </Widget>
            )
            
        }
        
        return (
            <Widget {...this.props}>
                <WidgetHeader> 
                    <WidgetTitle title={title} url={titleUrl} />
                    <WidgetDescription description={description} />
                </WidgetHeader>
                <WidgetBody>
                    { this.renderChart() }
                </WidgetBody>
            </Widget>
        )

    }

}

export default DataWidget;