import article from "../../Kiosk/schemas/article"
import event from './event/';
import inbox from './inbox/';


const articleWithPreview = {
    ...article,
    preview: {
        template: "iframe",
        url: "//digitaltmuseum.no/021188550507/spanskesyken-pandemien-i-verdenskrigens-fotspor"
    }
}

const schemas = [
    articleWithPreview,
    event,
    inbox
]

export default schemas;