import article from "../../Kiosk/schemas/article"
import event from './event/';
import inbox from './inbox/';


const articleWithPreview = {
    ...article,
    preview: {
        template: "iframe",
        url: "//norskfolkemuseum.no/tider-priser-adkomst"
    }
}

const schemas = [
    articleWithPreview,
    event,
    inbox
]

export default schemas;