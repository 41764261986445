import React, { Component } from 'react';
import { ChartBar } from '../'

class ChartBarGrouped extends Component {
    
    static defaultProps = {
        groupMode: "grouped",
    }

    render() {

        return (
            <ChartBar {...this.props} />
        )
    
    }

}

export default ChartBarGrouped