import React, { useState, useEffect } from 'react';

import { CalendarYear, CalendarMonth, CalendarSchedule } from "../components"

//import ics from "./ical/helligdager"

import qs from 'query-string';


const layouts = {
    "year": CalendarYear,
    "month": CalendarMonth,
    "schedule": CalendarSchedule
}

const FinderResults = ({layout = "table", query, eventsByDate = {}, onSelect, ...props}) => {

    let sq = props.location && props.location.search && qs.parse(props.location.search) || {}

    if (sq.layout) {
        layout = sq.layout
    }

    const ResultsTemplate = layout && layouts[layout] || CalendarMonth


    return (
        <ResultsTemplate {...props} {...query} eventsByDate={eventsByDate} onSelect={onSelect} />
    )

    return (
        <p>{JSON.stringify(eventsByDate)}</p>
    )


}

export default FinderResults