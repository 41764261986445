import React from "react";
import Color from 'color';

export function getOwnerIcon({ fill = "#000", text = "N/A"}) {

    let contrast;

    fill = Color(fill);

    if (fill.isDark()) {
        contrast = 'white';
    } else {
        contrast = 'black';
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <circle cx="32" cy="32" r="27" fill={fill}></circle>
            <text x="32" y="34" fill={contrast} fontFamily="Akkurat, sans-serif" fontSize="18" fontWeight="normal" dominantBaseline="middle" textAnchor="middle">{text}</text>
        </svg>
    )

}

export default getOwnerIcon