import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchModel } from '../redux/functions/model';
import { FinderSection, AuthModule, WidgetSearch } from '@frontend-components/admin';

import AuthModuleSite from './AuthModuleSite';

class AuthAddSite extends Component {
    
    state = {
        q: "",
    }

    static defaultProps = {
        search: {
            models: []
        }
    }
    
    onSearch = (q) => {
        this.setState({
            q: q
        })

        this.props.searchModel('sites', "/auth/sites/add", {name: q + "*"})
    }
    
    renderSite = (site, index) => {
        const { userId } = this.props.match.params;
        const { sites } = this.props;

        let add = true;
        
        sites.filter(skip => {
            if (site.id === skip.siteId) {
                add = false
            }
        })

        site = {
            ...site,
            userId: userId,
            siteId: site.id,
            moduleSize: "small"
        }
        
        if (add) {
            return (
                <AuthModuleSite {...site} key={index} action={["add"]} />
            )
        }

        return (
            <AuthModuleSite {...site} key={index} action={["remove"]}  />
        )
        
    }
    
    renderResults = () => {
        const { models } = this.props.search;
        
        if (models) {
            return (
                <FinderSection title={models.length + " owners found"}>
                    { models.map(this.renderSite) }
                </FinderSection>                
            )
        }
        
    }
    
    render() {
        const { q } = this.state;
        
        return (
            <FinderSection title="Add owner">
                <FinderSection>
                    <WidgetSearch placeholder="Find owner by name or identifier" value={q} onChange={this.onSearch} />
                </FinderSection>
                { this.renderResults() }
            </FinderSection>
        )
        
    }

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      searchModel
  }, 
dispatch);


function mapStateToProps(state) {
	return {
        search: state.data["/auth/sites/add"],
	};
}  

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthAddSite);