import React from "react";

export default (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 14)">
          <polygon fill="#999999" points="23.976 24.047 12.024 24.047 12.023 24.047 .046 30.162 0 36 21.773 36 24.573 36 36 36 36 30.162 23.977 24.047"/>
          <path fill="#BBBBBB" d="M22.5703336,24.0557555 L22.5703336,10.6585523 L13.4297086,10.6585523 L13.4297086,24.0557555 L12.0234586,24.0557555 C12.0234586,27.3565055 14.6992711,30.032318 18.0000211,30.032318 C21.3007711,30.032318 23.9765836,27.3565055 23.9765836,24.0557555 L22.5703336,24.0557555 Z"/>
          <path fill="#DDDDDD" d="M28.1330578,10.1973305 C28.0305422,9.56276016 27.6930422,9.27658828 27.1465734,9.25542422 L26.8703859,9.25542422 C26.1201516,3.99365859 22.4547609,-2.109375e-05 18.0460266,-2.109375e-05 C18.0306281,-2.109375e-05 18.0153703,0.00061171875 17.9999719,0.00075234375 C17.9845734,0.00061171875 17.9692453,-2.109375e-05 17.9538469,-2.109375e-05 C13.5451125,-2.109375e-05 9.87972188,3.99365859 9.12955781,9.25542422 L8.85344063,9.25542422 C8.30690156,9.27658828 7.96940156,9.56276016 7.86688594,10.1973305 C7.614675,11.7584789 8.06868281,13.1385727 9.0619875,14.3370492 C9.30301875,14.6278617 9.46030781,14.9268305 9.57119063,15.2745258 L9.57245625,15.2483695 C10.8574172,19.4874398 14.1249797,22.5000492 17.9538469,22.5000492 C17.9692453,22.5000492 17.9845734,22.4993461 17.9999719,22.4992758 C18.0153703,22.4993461 18.0306281,22.5000492 18.0460266,22.5000492 C21.8748938,22.5000492 25.1425969,19.4874398 26.4274172,15.2483695 L26.4287531,15.2745258 C26.5396359,14.9268305 26.696925,14.6278617 26.9379563,14.3370492 C27.9312609,13.1385727 28.3853391,11.7584789 28.1330578,10.1973305"/>
          <path fill="#CCCCCC" d="M27.0273516,9.30798281 C25.8384375,9.47061562 25.3217109,8.59149844 24.7912031,7.80406875 C24.4958906,7.36567031 24.3189141,6.84029531 24.1304063,6.338475 C23.7426328,5.30642812 23.2093828,4.36888125 22.4541563,3.56092031 C21.9313828,3.00165469 21.337875,2.81757656 20.6219531,3.17905312 C20.3608828,3.31081875 20.0884922,3.42043594 19.8204609,3.53806875 C18.6292266,4.06133437 17.4375703,4.06070156 16.2459844,3.53884219 C15.9349219,3.40264687 15.6213984,3.27151406 15.3135,3.12835781 C14.7110625,2.8481625 14.1946172,2.99954531 13.7397656,3.43906875 C12.9233672,4.22776406 12.3388594,5.17817812 11.9837109,6.2422875 C11.624625,7.31813906 11.0429297,8.2206 10.2310313,8.99677969 C9.86245313,9.34925625 9.41048438,9.28013906 8.97271875,9.30720937 C9.54295313,6.16185 10.8438047,3.43337344 13.4715234,1.47207656 C16.2926016,-0.633571875 20.4878672,-0.5234625 23.3004375,2.081475 C25.3113047,3.94384219 26.4324375,6.27610781 26.96175,8.9276625 C26.9869922,9.05373281 27.005625,9.18113906 27.0273516,9.30798281"/>
        </g>
        <g transform="translate(28 14)">
          <path fill="#666666" d="M9.02257031,20.5815937 C9.02257031,20.5815937 12.0661172,22.5 18.0592031,22.5 C24.0522187,22.5 26.9450156,20.5917891 26.9450156,20.5917891 L26.9774297,10.6823672 L9.02257031,10.6823672"/>
          <polygon fill="#666666" points="23.977 24.047 23.976 24.047 12.023 24.047 12.023 24.047 .046 30.162 0 36 21.773 36 24.573 36 36 36 36 30.162"/>
          <path fill="#BBBBBB" d="M22.5703266,24.0557414 L22.5703266,10.6585383 L13.4297016,10.6585383 L13.4297016,24.0557414 L12.0234516,24.0557414 C12.0234516,27.3564914 14.6992641,30.0323039 18.0000141,30.0323039 C21.3007641,30.0323039 23.9765766,27.3564914 23.9765766,24.0557414 L22.5703266,24.0557414 Z"/>
          <path fill="#D3D4D5" d="M26.9648578,11.2554352 C26.9648578,17.4685992 22.9511391,22.5054352 18.0000141,22.5054352 C13.0488187,22.5054352 9.03517031,17.4685992 9.03517031,11.2554352 C9.03517031,5.04220078 13.0488187,0.00543515625 18.0000141,0.00543515625 C22.9511391,0.00543515625 26.9648578,5.04220078 26.9648578,11.2554352"/>
          <path fill="#777777" d="M18.8948531,1.40625e-05 C19.1886891,0.0666 19.4855484,0.122357812 19.7760094,0.201529687 C24.1429781,1.39185 27.0421031,4.11301406 28.2784078,8.47604531 C28.7319938,10.0767797 28.5384234,11.7313734 28.2298219,13.349475 C27.8793844,15.1865297 27.4720641,17.0128266 27.1182516,18.8493187 C27.0080719,19.4208891 26.9914781,20.0104594 26.9320641,20.5917328 C26.7408141,20.3308734 26.5288219,20.0825297 26.3622516,19.8069047 C25.8824391,19.0130766 25.719525,18.1143422 25.5400875,17.2227797 C25.4377828,15.3679359 25.3547438,13.5117562 25.226775,11.6586703 C25.1350875,10.3307484 24.6390328,9.31417031 23.34915,8.60598281 C20.8484859,7.23299062 19.2059156,5.03607656 18.0462516,2.46580312 C17.9959078,2.36145937 17.9358609,2.26063125 17.8969781,2.15227969 C17.6399156,1.43579531 17.3874234,0.717623437 17.1334547,1.40625e-05 L18.8948531,1.40625e-05 Z"/>
          <path fill="#888888" d="M17.1334336,1.40625e-05 C17.3874023,0.717623437 17.6398945,1.43579531 17.896957,2.15227969 C17.9358398,2.26063125 17.995957,2.36145937 18.0462305,2.46573281 C17.9024414,2.68271719 17.7369258,2.88852187 17.618168,3.11837344 C16.3530352,5.56714687 14.627707,7.58399062 12.1582617,8.89531875 C11.482418,9.25419375 11.0680664,9.81957656 10.9499414,10.5315609 C10.7496211,11.7385453 10.6087852,12.9585375 10.5112617,14.1783891 C10.4304727,15.1902562 10.4481211,16.2099984 10.4221055,17.226225 C10.2698086,17.8247953 10.1579414,18.4374984 9.95382422,19.0179281 C9.75097266,19.5947719 9.51366797,20.1747797 9.00959766,20.5816078 C8.96565234,18.931725 8.57133984,17.3388656 8.22048047,15.7386234 C7.85471484,14.0698969 7.48859766,12.406725 7.45505859,10.6812562 C7.41800391,8.77606875 7.92319922,7.04342812 8.94273047,5.45956875 C10.6110352,2.86806094 12.8906367,1.06946719 15.9049336,0.263545312 C16.287082,0.16138125 16.6767539,0.08713125 17.0629805,1.40625e-05 L17.1334336,1.40625e-05 Z"/>
        </g>
      </g>
    </svg>
)