export default {
  type: "array",
  items: {
    type: "object",
    properties: {
      title: {
        type: "string"
      },
      code: {
        type: "string"
      }
    }
  }
}