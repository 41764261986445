import { getGeopointField } from "./utils"

import GeopointField from './GeopointField';
import GeopointPreview from './GeopointPreview';

import Geocoder from './Geocoder'

export default {
    "fieldtypes": {
        "geopoint": getGeopointField,
    },
    "fields": {
        "geopoint": GeopointField
    },
    "layouts": {
        "geopointPreview": GeopointPreview,
        "geocoder": Geocoder,
    }
}