export default {
  "select": {
    "imageUrl" : "imageUrl",
    "title" : "title",
    "description" : "description",
    "metadata" : "metadata",
    "label": "label"
  },
  prepare(selection) {
    let { locale, imageUrl, title, content, description } = selection

    let label = 'kp/object';
    
    let metadata = []
    
    if (content) {
        const { objectId, objectType } = content;
        
        if (objectType) {
            label = label + "/" + objectType
        }
        
        if (objectId) {
            metadata.push(objectId)
        }
                
    }

    return {
        imageUrl: imageUrl,
        title: title,
        description: description,
        metadata: metadata,
        label: label
    }
    
  }
  
}