export default {
    "type": "object",
    "properties": {
        "palette": {
            "type": "object",
            "properties": {
                "type": {
                    "type": "string",
                    "enum": ["light","dark"],
                    "enumNames": ["Light","Dark"]
                },
                "primary": {
                    "type": "object",
                    "properties": {
                        "main": {
                            "type": "string",
                            "format": "color",
                            "default": "#000"
                        },
                        "light": {
                            "type": "string",
                            "format": "color",
                        },
                        "dark": {
                            "type": "string",
                            "format": "color",
                        }
                    }  
                },
                "secondary": {
                    "type": "object",
                    "properties": {
                        "main": {
                            "type": "string",
                            "format": "color",
                            "default": "#f00"
                        },
                        "light": {
                            "type": "string",
                            "format": "color",
                            "default": "#f00"
                        },
                        "dark": {
                            "type": "string",
                            "format": "color",
                            "default": "#f00"
                        }
                    }  
                }
            }
        }
    }
}