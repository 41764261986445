import React, { Component } from "react";
import * as types from "../utils/types";

import SchemaLayout from "./SchemaLayout";

import {
    getWidget,
    getUiOptions,
    isSelect,
    optionsList,
    getDefaultRegistry,
} from "../utils/";

class StringField extends Component {

    static propTypes = types.fieldProps;

    static defaultProps = {
        uiSchema: {},
        disabled: false,
        readonly: false,
        autofocus: false,
    }
    
    render() {
        const {
            schema,
            name,
            uiSchema,
            idSchema,
            formData,
            required,
            disabled,
            readonly,
            autofocus,
            onChange,
            onBlur,
            onFocus,
            registry = getDefaultRegistry(),
            rawErrors,
        } = this.props;

        const { title, format } = schema;
        const { widgets, formContext } = registry;
        const enumOptions = isSelect(schema) && optionsList(schema);
    
        const defaultWidget = format || (enumOptions ? "select" : "text");
        const { language, widget = defaultWidget, placeholder = "", ...options } = getUiOptions(uiSchema);

        const Widget = getWidget(schema, widget, widgets);

        return (
            <SchemaLayout {...this.props}>
                <Widget
                    options={{ ...options, enumOptions }}
                    language={language}
                    schema={schema}
                    id={idSchema && idSchema.$id}
                    label={title === undefined ? name : title}
                    value={formData}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    required={required}
                    disabled={disabled}
                    readonly={readonly}
                    formContext={formContext}
                    autofocus={autofocus}
                    registry={registry}
                    placeholder={placeholder}
                    rawErrors={rawErrors}
                />
            </SchemaLayout>
        )

    }
    
}

export default StringField;