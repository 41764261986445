import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getUrlFromProps } from "../../resources/functions/getUrlFromProps"

import Module from "../Module/Module"

import "./FinderModule.scss"

class FinderModule extends Component {

    static propTypes = {
        uniqueId: PropTypes.string,
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        label: PropTypes.string,
        metadata: PropTypes.array,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string
    }

    static defaultProps = {
        moduleLayout: "list",
        moduleSize: "auto",
    }

    getMetadata = () => {
        let { metadata, size, mediaWidth, mediaHeight, mediaSize } = this.props;

        if (metadata) {
            return metadata
        }

        metadata = []

        size = size || mediaWidth && mediaHeight && mediaWidth + "×" + mediaHeight || undefined;

        if (size) {
            metadata.push(size)
        }
        
        return metadata;

    }

    getLabel = (model) => {
        let { label, documentType, mediaType, mimeType } = this.props;
        return label || mediaType || documentType || undefined;
    }
    
    formatSize = (bytes, decimals) => {
        if (bytes === 0) return '0 Bytes';

        var k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getModel = () => {
        const { urlPattern, editUrlPattern, refsUrlPattern } = this.props;
        const { url, editUrl, refsUrl } = this.props;
        
        return {
            ...this.props,
            label: this.getLabel(),
            metadata: this.getMetadata(),
            url: url || getUrlFromProps(this.props, urlPattern) || undefined,
            editUrl: editUrl || getUrlFromProps(this.props, editUrlPattern) || undefined,
            refsUrl: refsUrl || getUrlFromProps(this.props, refsUrlPattern) || undefined,
        }

    }

    render() {
        const model = this.getModel()
        const { moduleLayout } = model;
        
        return (
            <Module {...model} />
        )
        
    }

}

export default FinderModule;
