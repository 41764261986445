import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    action: {
        position: "absolute",
        zIndex: 3,
        top: 0,
        right: 0,
        bottom: 0,
        left: "100%",
        width: "50%",
        "&[aria-expanded=true]": {
            left: "50%"
        }
    }
}));

const FinderAction = ({expanded = false, children}) => {
    const classes = useStyles()

    return (
        <Paper className={classes.action} aria-expanded={expanded}>
            {children}
        </Paper>
    )

}

export default FinderAction;