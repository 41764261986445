import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AnalyticsTable } from '@frontend-components/admin';
import { getReports } from '../redux/functions/reports';

// ?countryId=no&offset=0&owner=1,2,3,4&reportType=0&rows=50&startDate=2019-07-01,2018-07-01&endDate=2019-07-01,2018-07-01&orderBy=nthDay&metrics=uniquePageviews,users,sessions&dimensions=dimension1,dimension2,dimension3,dimension4,nthDay&sort=descending

class ReportTable extends Component {
    
    static defaultProps = {
        dimensions: undefined,
        id: "table",
        defaultQuery: {
            countryId: "no",
            offset: 0,
            owner: 1,
            reportType: 0,
            rows: 1000,
            startDate: "7daysAgo",
            endDate: "yesterday",
            dimensions: "city",
            metrics: "sessions,percentNewSessions,newUsers,bounceRate,pageviewsPerSession,avgSessionDuration",
            orderBy: "sessions",
            sort: "descending",
        },
        scopes: {
            today: {
                startDate: "today",
                endDate: "today"
            },
            yesterday: {
                startDate: "yesterday",
                endDate: "yesterday"
            },
            ['7days']: {
                startDate: "7daysAgo",
                endDate: "yesterday"
            },
            ['28days']: {
                startDate: "28daysAgo",
                endDate: "yesterday"
            },
            ['quarter']: {
                startDate: "90daysAgo",
                endDate: "yesterday"
            },
            ['year']: {
                startDate: "365daysAgo",
                endDate: "yesterday"
            }
        }

    }

    componentDidMount() {
        this.getReport()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.id !== this.props.id) {
            this.getReport()
        }

        if (prevProps.scope !== this.props.scope) {
            this.getReport()
        }
        
    }
    
    getReport = () => {
        let { id, defaultQuery, query, scopes, scope } = this.props;
        
        if (scope && scopes[scope]) {
            query = {
                ...defaultQuery,
                ...query,
                ...scopes[scope]
            }
        }
        
        this.props.getReports({
            ...query,
            id: id
        })
        
    }
    
    render() {
        const { urlPattern, theme, data, id, scope } = this.props;

        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        return (
            <AnalyticsTable report={report} scope={scope} theme={theme} urlPattern={urlPattern} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportTable);