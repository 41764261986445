import React from 'react';
import { PreviewIframe } from "@frontend-components/admin"

const IframePreview = ({formData, formContext, ...preview}) => {

    return (
        <PreviewIframe {...preview} postMessage={{formData: formData, formContext: formContext}} />
    )
    
}

export default IframePreview