import React, { useState, useEffect } from 'react';
import { NavSearch, NavTabs } from '../components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    search: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: "auto",
        left: 0,
        display: "flex",
        backgroundColor: "inherit",
        color: "black",
        width: "100%",
        height: theme.spacing(8),
        overflow: "hidden",

        "& + *": {
            marginTop: theme.spacing(8),
        }

    },
}));


const DialogSearch = ({placeholder, q, onChange, onReset}) => {
    const classes = useStyles()

    return (
        <NavSearch placeholder={placeholder} q={q} onChange={onChange} onReset={onReset} className={classes.search} />
    )
}

export default DialogSearch