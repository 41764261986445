export const REQUEST_DIALOG = 'REQUEST_DIALOG'
export const RECEIVE_DIALOG = 'RECEIVE_DIALOG'

export const REQUEST_DIALOG_FORMDATA = 'REQUEST_DIALOG_FORMDATA'
export const RECEIVE_DIALOG_FORMDATA = 'RECEIVE_DIALOG_FORMDATA'

export const REQUEST_DIALOG_QUERY = 'REQUEST_DIALOG_QUERY'
export const RECEIVE_DIALOG_QUERY = 'RECEIVE_DIALOG_QUERY'

export const REQUEST_DIALOG_SELECTED = 'REQUEST_DIALOG_SELECTED'
export const RECEIVE_DIALOG_SELECTED = 'RECEIVE_DIALOG_SELECTED'

export function requestDialog() {

    return {
        type: REQUEST_DIALOG,
    }
  
}

export function receiveDialog(dialog) {

    return {
        type: RECEIVE_DIALOG,
        dialog: dialog
    }
  
}

export function requestDialogFormData() {

    return {
        type: REQUEST_DIALOG_FORMDATA,
    }
  
}

export function receiveDialogFormData(formData) {

    return {
        type: RECEIVE_DIALOG_FORMDATA,
        formData: formData
    }
  
}

export function requestDialogQuery() {

    return {
        type: REQUEST_DIALOG_QUERY,
    }
  
}

export function receiveDialogQuery(query) {

    return {
        type: RECEIVE_DIALOG_QUERY,
        query: query
    }
  
}

export function requestDialogSelected() {

    return {
        type: REQUEST_DIALOG_SELECTED,
    }
  
}

export function receiveDialogSelected(selected) {

    return {
        type: RECEIVE_DIALOG_SELECTED,
        selected: selected,
    }
  
}
