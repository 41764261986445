import { API } from '../settings';
import { requestSearch, receiveSearch } from '../actions/search';
import { getQueryParams } from './query';

import apps from "../apps"

// SEARCH

export function searchApps(model, id, query = {}) {
  
  let models = Object.values(apps);
  
  return function(dispatch) {
      
      if (query.siteId) {
          dispatch(searchAppsBySite(id, query))
      } else if (query.collectionType) {
          dispatch(searchAppsByCollectionType(id, query))
      } else {
          dispatch(filterApps(id, {models:models}, query))
      }
      
  }

}

export function searchAppsByCollectionType(id, query) {
    let url = API + "/admin/api/collections/search";

    const params = getQueryParams(query);

    if (params) {
        url = url + '?' + params;
    }
    
    return function(dispatch) {
        dispatch(requestSearch(id, query))
  
        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
          },
        })
        .then(
          response => response.json(),
          error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(filterApps(id, data, query))
        )

    }
    
}

export function searchAppsBySite(id, query) {
    let url = API + "/admin/api/collections/search";

    const params = getQueryParams(query);

    if (params) {
        url = url + '?' + params;
    }
    
    return function(dispatch) {
        dispatch(requestSearch(id, query))
  
        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
          },
        })
        .then(
          response => response.json(),
          error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(filterApps(id, data, query))
        )

    }
    
}

export function filterApps(id, data, query) {
    let { models } = data;
    
    // map collections to apps
    
    models = models.map(collection => {
        const { collectionType, appType } = collection;
        
        const app = apps[collectionType] || {}
        
        return {
            ...collection,
            ...app,
            appType: collectionType || undefined
        }
    })

    // filter by appType

    if (query.appType) {
        models = models.filter(app => {
            const { appType } = app;
            
            if (!appType) {
                return false
            }
            
            appType.startsWith(query.appType)
        })
    }
    
    return function(dispatch) {

        dispatch(receiveSearch(id, {
            models: models
        }))
        
    }
    
}