import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageBase from "./ImageBase"
import ImageFocalpointReticle from "./ImageFocalpointReticle"
import { getImagePointEvent } from "./utils/getImagePoint"

const useStyles = makeStyles(theme => ({
    root: {
    },
}));

const ImageFocalpoint = ({className, imageUrl, imageCropdata, imageFilters, imageFocalpoint = {x: 0, y: 0, left: 50, top: 50}, onChange }) => {

    const [focalpoint, setFocalpoint] = useState(imageFocalpoint)
 
    const handleClick = (event) => {
        imageFocalpoint = getImagePointEvent(event)
        setFocalpoint(imageFocalpoint)
        onChange && onChange(imageFocalpoint)
    }

    const classes = useStyles()

    if (!imageUrl) {
        return (
            <p>No imageUrl for focalpoint</p>
        )
    }

    return (
        <ImageBase imageUrl={imageUrl} imageCropdata={imageCropdata} imageFilters={imageFilters} onClick={handleClick}>
            <ImageFocalpointReticle {...focalpoint} />
        </ImageBase>
    )

}

export default ImageFocalpoint;