import React, { useRef, useState, useEffect } from 'react'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import VideoControls from "./VideoControls"

const useStyles = makeStyles(theme => ({
    player: {
        backgroundColor: "black",
        color: "white",

        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    video: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "block",
        width: "100%",
        height: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        margin: "auto",
    },
    controls: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: theme.spacing(1),
    }

}));

const VideoPlayer = ({
    className, 
    position = "absolute", 
    minHeight = undefined, 
    maxWidth = undefined, 
    maxHeight = undefined, 
    format = undefined, 
    title, 
    description, 
    imageUrl = undefined, 
    videoUrl = undefined, 
    videoType = undefined, 
    videoSources = [], 
    resources = {},
    ...props}) => {

//    size = size === "large" && 32 || size === "medium" && 24 || size === "small" && 12

    const playerRef = useRef(null)

    const [buffered, setBuffered] = useState(null)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [seeking, setSeeking] = useState(null)
    const [paused, setPaused] = useState(true)
    const [volume, setVolume] = useState(true)
    const [muted, setMuted] = useState(true)

    const [videoWidth, setVideoWidth] = useState(null)
    const [videoHeight, setVideoHeight] = useState(null)
    const [videoRatio, setVideoRatio] = useState(null)


//    const [maxWidth, setMaxWidth] = useState(null)
    const [scale, setScale] = useState(null)
    const [ratio, setRatio] = useState(null)

    const classes = useStyles()


    useEffect(() => {

        if (format && format.includes(":")) {
            const wh = format.split(":")
            setRatio(wh[1]/wh[0])
        } else if (videoWidth && videoHeight) {
            setRatio(videoHeight/videoWidth)
        }
    
    }, [format, videoWidth, videoHeight])

    useEffect(() => {

        if (!playerRef.current) {
            return
        }

        playerRef.current.addEventListener('loadedmetadata', event => {

            const offsetWidth = event.target.offsetWidth;

            const videoWidth = event.target.videoWidth;
            const videoHeight = event.target.videoHeight;
            const scale = offsetWidth / videoWidth

            const duration = event.target.duration;
    
            console.log('event', event)
    
            setVideoWidth(videoWidth)
            setVideoHeight(videoHeight)
            setVideoRatio(videoHeight/videoWidth)

            setBuffered(event.target.buffered)
            setDuration(event.target.duration)
            setCurrentTime(event.target.currentTime)
            setPaused(event.target.paused)
            setMuted(event.target.muted)
            setVolume(event.target.volume)

            props.onMetadata && props.onMetadata({
                videoWidth: videoWidth,
                videoHeight: videoHeight,
                duration: duration
            })
             
        })

        playerRef.current.addEventListener('canplay', event => {
            /*
            setBuffered(event.target.buffered)
            setDuration(event.target.duration)
            setCurrentTime(event.target.currentTime)
            setPaused(event.target.paused)
            setMuted(event.target.muted)
            setVolume(event.target.volume)
            */
        })

        playerRef.current.addEventListener('timeupdate', event => {
            setDuration(event.target.duration)
            setCurrentTime(event.target.currentTime)
        })

        playerRef.current.addEventListener('seeking', event => {
            setCurrentTime(event.target.currentTime)
            setSeeking(true)
        })

        playerRef.current.addEventListener('seeked', event => {
            setCurrentTime(event.target.currentTime)
            setSeeking(false)
        })

        playerRef.current.addEventListener('volumechange', event => {
            setVolume(event.target.volume)
        })

        playerRef.current.addEventListener('play', event => {
            setPaused(event.target.paused)
        })

        playerRef.current.addEventListener('pause', event => {
            setPaused(event.target.paused)
        })

        
    }, [playerRef.current])

    const handlePlayPause = () => {
        const { paused } = playerRef.current

        if (paused) {
            playerRef.current.play()
        } else {
            playerRef.current.pause()
        }

        setPaused(!paused)

    }

    const handleSeek = (value) => {
        playerRef.current.currentTime = value
    }

    const handleVolume = (value) => {
        playerRef.current.volume = value
    }

    const handleMute = () => {
        const { mute } = playerRef.current
        playerRef.current.mute = !mute
        setMuted(!mute)
    }

    if (!videoSources.length && resources) {
        Object.values(resources).map(source => {
            const { mimeType, url } = source

            if (mimeType.startsWith('video') && url) {

                videoSources.push({
                    src: url,
                    type: mimeType
                })

            }

        })
    }

    if (!videoSources.length) {
        return <p>No video sources</p>
    }

    return (
        <div className={classes.player} data-layout="videoPlayer">
            <video ref={playerRef} className={classes.video} poster={imageUrl}>
                { videoSources.map(({src, type}) => <source src={src} type={type} />)}
                Your browser does not support the video tag.
            </video>
            { duration &&
                <VideoControls
                    className={classes.controls}
                    title={title}
                    description={description}
                    paused={paused}
                    onPlayPause={handlePlayPause}
                    duration={duration}
                    currentTime={currentTime}
                    onSeek={handleSeek}
                    />
            }

            {/* 

            <ul style={{position: "absolute", top: 0, right: 0}}>
                <li>videoWidth: {videoWidth}</li>
                <li>videoHeight: {videoHeight}</li>
                <li>videoRatio: {videoRatio}</li>
                <li>maxWidth: {maxWidth}</li>
                <li>format: {format}</li>
                <li>ratio: {ratio}</li>
            </ul>

            */}


        </div>
    )

}

export default VideoPlayer;