import schema from "./schema"
import uiSchema from "./uiSchema"
import formData from "./formData.json"

export default {
    id: 1,
    name: "booking",
    schemaType: "booking",
    schema: schema,
    uiSchema: uiSchema,
    formData: formData
}