import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const AuthCollectionField = ({authRoles, collectionsById, sitesById, ...props}) => {
    const { registry, formData, uiSchema, onChange } = props

    const collectionId = formData && formData.collection && formData.collection.id;
    const collection = collectionsById[collectionId] || formData && formData.collection;

    const siteId = collection && collection.siteId;

    const site = siteId && sitesById[siteId]
    const description = site && site.title || collection && collection.description;

    const auth = {
        ...collection,
        description: description
    }

    const role = formData && formData.role;

    const handleAuthRole = (role) => {
        const newFormData = ({
            collection: {
                id: collectionId
            },
            role: {
                id: role && role.id
            },
        })
        onChange && onChange(newFormData)
    }

    const handleAuthRemove = () => {
        const newFormData = ({
            collection: {
                id: collectionId
            },
            role: {}
        })
        onChange && onChange(newFormData)
    }

    const newUiSchema = {
        ...uiSchema,
        "ui:status": formData && formData.status,
        "ui:auth": auth,
        "ui:onAuthRole": handleAuthRole,
        "ui:onAuthRemove": handleAuthRemove,
        "ui:role": role,
        "ui:roles": authRoles,
        "ui:layout": "authItem",
    }

    const { ObjectField } = registry.fields;

    return (
        <ObjectField {...props} uiSchema={newUiSchema} registry={registry} />
    )


}

// mapStateToProps

const mapStateToProps = (state) => {
	return {
        authRoles: state.authRoles.models,
        collectionsById: state.collectionsById,
        sitesById: state.sitesById,
	};
}  

// export default

export default connect(
    mapStateToProps,
)(AuthCollectionField);