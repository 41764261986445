import React, { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReferences } from '../redux/functions/references';

import FinderApp from "../AppFinder/FinderApp"
import FinderSection from '../AppFinder/FinderSection';
import FinderModel from "../AppFinder/FinderModel"

const DocumentReferences = ({models = "documents", referencesById, ...props}) => {
    const uniqueId = props.match.params.uniqueId

    useEffect(() => {
        props.getReferences(models, uniqueId);
    }, [uniqueId])

    const model = referencesById && referencesById[uniqueId] && referencesById[uniqueId].model
    const references = referencesById && referencesById[uniqueId] && referencesById[uniqueId].references

    const [referencesByType, setReferencesByType] = useState(null)

    useEffect(() => {

        let byType = {}

        references && references.map((ref) => {
            const { referenceType, reference } = ref;
            const { documentType } = reference;
    
            if (!byType[documentType]) {
                byType[documentType] = {
                    "documentType": documentType,
                    "models": []
                }
            }
          
            byType[documentType].models.push(reference);
            
        })

        setReferencesByType(byType)

    }, [references])

    const renderSection = ({documentType, models = []}) => {

        const label = models.length + " " + documentType

        return (
            <FinderSection label={label}>
                { models.map((model, index) => {
                    return (
                        <FinderModel {...props} model={model} />
                    )
                })}
            </FinderSection>
        )

    }

    return (
        <FinderApp {...props}>
            { model && <FinderModel {...props} model={model} /> }
            { referencesByType && Object.values(referencesByType).map(renderSection) }
        </FinderApp>
    )    

}

function mapStateToProps(state) {
	return {
        referencesById: state.referencesById,
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getReferences
    }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentReferences);