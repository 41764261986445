import React, { useRef, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import WidgetBase from "../Widget/WidgetBase"
import { FilterAppsControlled as FilterApps, AppsList, AppsGrid } from "../"
import { makeStyles } from '@material-ui/core/styles';
import OwnerIcon from "./OwnerIcon"

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        position: "relative"
    },
    header: {
        backgroundColor: "#ccc",
        backgroundImage:  props => { return "url(" + props.imageUrl + ")"},
        backgroundSize: "cover",
        width: "100%",
        height: theme.spacing(30),
    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    icon: {
        fontSize: theme.spacing(8),
        margin: theme.spacing(2)
    },
    title: {
        fontSize: "18px",
        lineHeight: "20px",
        fontWeight: "normal",
        color: "white",
    },
    body: {
        position: "absolute",
        top: theme.spacing(30),
        right: 0,
        bottom: 0,
        left: 0
    },
    grid: {
        width: "270px",
        margin: "auto"
    }
}));

const OwnerWidget = ({owner = {}, layout = "list", onSelect, ...props}) => {
    const [overflow, setOverflow] = useState(false)
    const bodyRef = useRef()

    const imageUrl = owner.imageUrl || props.imageUrl
    const abbr = owner.abbr || props.abbr
    const title = owner.title || props.title || "N/A"
    const apps = owner.apps || props.apps
    const theme = owner.theme || props.theme

    const classes = useStyles({imageUrl})

    useEffect(() => {
        const height = bodyRef.current && bodyRef.current.offsetHeight
        const width = bodyRef.current && bodyRef.current.offsetWidth

        const itemsHeight = apps && apps.length * 57;

        if (layout === "grid" && apps.length > 6) {
            setOverflow(true)
        }

        if (layout === "list" && itemsHeight > height) {
            setOverflow(true)
        }

    });

    return (
        <WidgetBase {...props} classes={classes}>
            <header className={classes.header}>
                <div className={classes.content}>
                    <OwnerIcon theme={theme} title={title} abbr={abbr} className={classes.icon} />
                    <Typography className={classes.title}>{title}</Typography>
                </div>
            </header>
            <div className={classes.body} ref={bodyRef}>
                { overflow &&  <FilterApps apps={apps} layout={layout} onSelect={onSelect} /> }
                { !overflow && layout === "grid" && <AppsGrid className={classes.grid} apps={apps} onSelect={onSelect} /> }
                { !overflow && layout === "list" && <AppsList apps={apps} onSelect={onSelect} /> }
            </div>
        </WidgetBase>
    )

}

OwnerWidget.defaultProps = {
    type: "owner",
    size: "4:6",
    layout: "list"
}

export default OwnerWidget;