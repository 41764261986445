import schema from "../page/schema";
import uiSchema from "../page/uiSchema";
import PreviewTemplate from "@frontend-components/admin/lib/components/AppKp/preview/Page"

export default {
    id: 1,
    schemaType: 'kp',
    name: 'page/list',
    schema: schema,
    uiSchema: uiSchema,
    preview: {
        template: PreviewTemplate
    }
}