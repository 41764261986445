import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LayoutFinder } from "@frontend-components/admin"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: "white",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        padding: theme.spacing(2)
    },
}));
const PreviewCollectionSettings = ({app, formData}) => {

    const classes = useStyles()

    const { root, siteId, siteName, name, title, collectionType, defaultLocale } = app;

    return (
        <LayoutFinder title="Settings">
            <ul>
                <li>root: {root}</li>
                <li>title: {title}</li>
                <li>name: {name}</li>
                <li>siteId: {siteId}</li>
                <li>siteName: {siteName}</li>
                <li>collectionType: {collectionType}</li>
                <li>defaultLocale: {defaultLocale}</li>
            </ul>

            {JSON.stringify(app)}

        </LayoutFinder>
    )
}

function mapStateToProps(state) {
	return {
        formData: state.editor.formData,
        formContext: state.editor.formContext,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewCollectionSettings);
