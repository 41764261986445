import { API } from '../settings';
import { requestModel, receiveModel } from '../actions/model';

// document source

export const addDocumentSource = (model, callback) => {
    const { collectionId, sourceId } = model;    

    let url = API + '/admin/api/documents/search?collectionId=' + collectionId + "&sourceId=" +sourceId;

    return function (dispatch) {
        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {

            if (data.models && data.models[0]) {
                dispatch(receiveModel(data.models[0].uniqueId, data.models[0]))
                callback && callback(data.models[0])
            } else {
                dispatch(newDocumentSource(model, callback))
            }

        })

    }

}

export const newDocumentSource = (model, callback = undefined) => {
    const url = API + '/admin/api/documents'

    model = {
        ...model,
        source: model.source.toLowerCase(),
        schemaId: 1
    }

    delete model.uniqueId

    const payload = JSON.stringify(model);

    console.log(payload);

    return function (dispatch) {
        fetch(url, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
            body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveModel(data.uniqueId, data))
            callback && callback(data)
        })
    }

}

// media source

export const addMediaSource = (model, callback) => {
    const { collectionId, sourceId } = model;    

    let url = API + '/admin/api/media/search?collectionId=' + collectionId + "&sourceId=" +sourceId;

    return function (dispatch) {
        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {

            if (data.models && data.models[0]) {
                dispatch(receiveModel(data.models[0].uniqueId, data.models[0]))
                callback && callback(data.models[0])
            } else {
                dispatch(newMediaSource(model, callback))
            }

        })

    }

}

export const newMediaSource = (model, callback = undefined) => {
    const url = API + '/admin/api/media'

    model = {
        ...model,
        source: model.source.toLowerCase(),
        schemaId: 1
    }

    delete model.uniqueId
    
    const payload = JSON.stringify(model);

    console.log(payload);

    return function (dispatch) {
        fetch(url, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
            body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveModel(data.uniqueId, data))
            callback && callback(data)
        })
    }

}