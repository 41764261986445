import React, { Component } from 'react';
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import UserIcon from "../User/UserIcon"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "320px",
        padding: "6px"
    },
    figure: {
        position: "relative",
        display: "block",
        width: "72px",
        height: "72px",
        margin: "6px"
    },
    header: {
        margin: "6px"
    },
    icon: {
        width: "72px",
        height: "72px",
    },
    name: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        fontSize: "16px"
    },
    email: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "normal",
        fontSize: "14px"
    }
}));


const UserInfo = ({imageUrl, name, email, initials}) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <figure className={classes.figure}>
                <UserIcon className={classes.icon} name={name} initials={initials} imageUrl={imageUrl} />
            </figure>
            <header className={classes.header}>
                <Typography className={classes.name} nowrap>{name}</Typography>
                <Typography className={classes.email} nowrap>{email}</Typography>
            </header>
        </div>
    )

}

UserInfo.propTypes = {
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
}

export default UserInfo;
