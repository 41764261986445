import React, { Component } from 'react';

class WidgetBody extends Component {
    
    static defaultProps = {
    }
    
    render() {
        return (
            <div className="admin-widget__body">
                { this.props.children }
            </div>
        )
    }
}

export default WidgetBody;