import React from "react";
import IconField from "./IconField"

const IconWidget = (props) => {
    const {
        id,
        options,
        value,
        required,
        disabled,
        readonly,
        multiple,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        placeholder,
    } = props;

    const emptyValue = multiple ? [] : "";
    const label = options.title || props.title || props.label
    const helperText = options.help || undefined

    const handleChange = (value, event) => {
        onChange(value);
    }

    return (
        <IconField
            id={id}
            placeholder={placeholder}
            multiple={multiple}
            label={label}
            helperText={helperText}
            value={typeof value === "undefined" ? emptyValue : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={handleChange}>
        </IconField> 
    )

}

export default IconWidget;
