import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutButtons from "../Layout/LayoutButtons"

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: props => { return theme.spacing(props.padding) },
        paddingRight: props => { return theme.spacing(props.padding) },
        paddingBottom: props => { return theme.spacing(props.padding) },
    }
}));

const SectionFooter = ({className, padding = 2, buttons = [], buttonGroupProps, buttonProps, children}) => {
    const classes = useStyles({padding});

    if (!buttons.length && !children) {
        return false
    }

    return (
        <footer className={className || classes.root}>
            { buttons && <LayoutButtons buttons={buttons} buttonGroupProps={buttonGroupProps} buttonProps={buttonProps} /> }
            { children }
        </footer>
    )

}

export default SectionFooter;
