import React, { Component } from 'react';
import Button from '../Button/Button';

import './ButtonFilter.scss';

class ButtonFilter extends Component {
    
    static defaultProps = {
        buttonType: "filter"
    }

    getClassName = () => {
        const { buttonType } = this.props;

        let classNames = [];

        classNames.push("admin-button");

        if (buttonType) {
            classNames.push("admin-button--" + buttonType);
        }

        return classNames.join(' ');
    }


    render() {
        const className = this.getClassName()
        
        return (
            <Button {...this.props} className={className} />
        )

    }

}

export default ButtonFilter;
