import React, { Component } from 'react';
import Color from 'color';
import "./Widget.scss"

import WidgetGrid from "./WidgetGrid"

class Widget extends Component {
    
    static defaultProps = {
        id: "widget",
        expanded: false,
        selected: false,
        widgetType: undefined,
        widgetSize: "1:1",
        style: {}
    }
    
    getThemeStyle = () => {
        const { style } = this.props;        
        
        let backgroundColor = style.backgroundColor || "white";

        backgroundColor = Color(backgroundColor)
        
        let themeStyle;
        
        if (backgroundColor.isDark()) {
            themeStyle = "dark"
        } else {
            themeStyle = "light"
        }

        return themeStyle;
        
    }

    getStyle = () => {
        const { style, cols, rows, area } = this.props;
        
        if (!style) {
            return false
        }
        
        return style;
    }
    
    getClassName = () => {
        const { widgetType, widgetSize } = this.props;

        let classNames = []

        classNames.push('admin-widget')

        if (widgetType) {
            classNames.push('admin-widget--' + widgetType)
        }
        
        return classNames.join(' ')
    }
    
    getSize = () => {
        const { widgetSize } = this.props;
        
        if (!widgetSize) {
          return {}
        }

        const sizes = {
            "xs": 2,
            "s": 4,
            "m": 8,
            "l": 12,
            "xl": 16
        }
        
        const grid = widgetSize.split(":")
        
        let cols = grid[0]
        let rows = grid[1]
        
        cols = sizes[cols] || parseInt(cols);
        rows = sizes[rows] || parseInt(rows);
        
        return {
            cols: cols,
            rows: rows
        }
        
    }
    
    render() {
        const { id, expanded, selected, style, widgetSize } = this.props;
        const { cols, rows } = this.getSize();

        const themeStyle = this.getThemeStyle()

        const className = this.getClassName()
        
        return (
            <div className={className} style={style} onClick={this.props.onClick}
                data-theme={themeStyle}
                data-size={widgetSize}
                data-cols={cols}
                data-rows={rows}
                aria-expanded={expanded} 
                aria-selected={selected}>
                    <WidgetGrid>
                        {this.props.children}
                    </WidgetGrid>
            </div>
        )
    }
}

export default Widget;