import React from 'react';
//import _ from 'lodash';

import {
    getDefaultFormState,
    getUiFieldset,
    getUiOptions,
    getUiLayout,
    getUiSettings,
    getUiToolbar,
    getUiButtons,
    getUiPreview,
    getUiMenu,
} from "../utils/"

const SchemaLayout = ({children, ...props}) => {
    const { id, index, schema, uiSchema, formData, onPropertyChange, onChange, registry } = props;
    const { formContext } = registry;

    // uiOptions
    const uiOptions = getUiOptions(uiSchema);

    if (!uiOptions) {
        return children
    }

    // uiLayout
    const uiLayout = getUiLayout(props)

    // settings and toolbar
    const changeProperty = (name, value) => {

        if (!schema.type === "object") {
            return false
        }
    
        onChange({
            ...formData,
            [name]: value
        })

    } 

    // delete + restore

    const handleDelete = () => {
        if (uiLayout.deletable && uiLayout.restorable) {
            changeProperty("_action", "delete")
        } else if (props.hasRemove && props.onRemoveItem) {
            props.onRemoveItem()
        } else {
            props.onChange(getDefaultFormState(schema, undefined))
        }
    }

    const handleRestore = () => {
        if (uiLayout.restorable) {
            changeProperty("_action", "restore")
        }
    }

    // uiActions
    const uiActions = {
        onEdit: () => formContext.onEdit && formContext.onEdit(props),
        onSave: () => formContext.onSave && formContext.onSave(props),
        onToggle: () => formContext.onToggle && formContext.onToggle(props),
        onExpand: () => formContext.onExpand && formContext.onExpand(props),
        onCollapse: () => formContext.onCollapse && formContext.onCollapse(props),
        onAddItem: props.canAdd && props.onAddItem,
        onRemoveItem: props.hasRemove && props.onRemoveItem,
        onDelete: () => formContext.onDelete && formContext.onDelete(props) || uiLayout.deletable && handleDelete(),
        onRestore: () => formContext.onRestore && formContext.onRestore(props) || uiLayout.restorable && handleRestore(),
        onDialog: (event, button) => formContext.onDialog && formContext.onDialog({...props, button: button}, event),
        onUpload: (event) => formContext.onUpload && formContext.onUpload(props, event),
    }

    // uiButtons
    const uiButtons = getUiButtons({
        ...props,
        ...uiActions
    })

    // uiMenu
    const uiMenu = getUiMenu(props)


    const uiSettings = getUiSettings({
        ...props,
        onSettingsChange: changeProperty
    })

    const uiToolbar = getUiToolbar({
        ...props,
        ...uiLayout,
        ...uiActions,
        onSettingsChange: changeProperty
    })

    // preview
    const uiPreview = getUiPreview(props);

    // get layout

    const { layouts } = registry; // || getDefaultRegistry()
    const { layout } = uiLayout;

    let FieldLayout;
    
    if (layout && layouts[layout]) {
        FieldLayout = layouts[layout]
    } else if (layout) {
        return (
            <p>Layout {layout} does not exist.</p>
        )
    } else {
        FieldLayout = layouts['field']
    }
    
    // expandId + expandName
    const { currentId, expandId, expandName, editId, editName, settingsId, settingsName } = formContext;

    // uiFieldset
    const uiFieldset = getUiFieldset(props)

    // render fieldset
    const renderFieldset = (fieldset, index) => {
        const { SchemaField } = registry.fields
    
        // name, selected
        const { name, selected } = fieldset
    
        // part of navigation?
    
        const uiOptions = getUiOptions(uiSchema)
    
        if (uiOptions.nav && !selected) {
            return null
        }
    
        // schema, uiSchema, formData
        const fieldId = fieldset && fieldset.id;
        const fieldSchema = fieldset && fieldset.schema;
        const fieldUiSchema = fieldset && fieldset.uiSchema;
        const fieldUiOptions = fieldset && fieldset.uiOptions;
        const fieldFormData = fieldset && fieldset.formData;
    
        /*
        */
    
        if (fieldSchema) {
            return (
                <SchemaField {...props}
                    key={index}
                    name={name}
                    id={fieldId}
                    idPrefix={id}
                    schema={fieldSchema}
                    uiSchema={fieldUiSchema} 
                    formData={fieldFormData}
                    isArrayItem={false}
                    onChange={onPropertyChange(name)} />
            )
        }
    
        if (fieldUiSchema && fieldUiOptions.fieldset) {
    
            return (
                <SchemaField {...props}
                    key={index}
                    name={name}
                    id={fieldId}
                    idPrefix={id}
                    uiSchema={fieldUiSchema}
                    isArrayItem={false}
                    onChange={onChange} />
            )
        }
    
        return null        

    }

    return (
        <FieldLayout
            {...uiActions}
            {...uiLayout}
            {...uiPreview}
            currentId={currentId}
            menu={uiMenu}
            buttons={uiButtons}
            settings={uiSettings}
            toolbar={uiToolbar}
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            formContext={formContext}>
            { uiFieldset && uiFieldset.map(renderFieldset) || children }
        </FieldLayout>
    )       

}

export default SchemaLayout;