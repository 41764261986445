import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getCollection } from "../../AppCollection/app"

import PreviewCollection from "./PreviewCollection"
import PreviewCollectionMenu from "./PreviewCollectionMenu"
import PreviewCollectionAction from "./PreviewCollectionAction"

import { PreviewJSON } from "@frontend-components/admin"
 
import NavPreview from "./NavPreview"

const PreviewSchema = ({schema}) => {
    return (
        <PreviewJSON data={schema} theme="dark" />
    )
}

const PreviewUiSchema = ({uiSchema}) => {
    return (
        <PreviewJSON data={uiSchema} theme="dark" />
    )
}

const PreviewFormData = ({formData}) => {
    return (
        <PreviewJSON data={formData} />
    )
}

const templates = {
    "preview": PreviewCollection,
    "previewMenu": PreviewCollectionMenu,
    "previewAction": PreviewCollectionAction,
    "schema": PreviewSchema,
    "uiSchema": PreviewUiSchema,
    "formData": PreviewFormData
}

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: theme.spacing(1),
    },
    menu: {
        position: "absolute",
        zIndex: 2,
        top: "auto",
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1),

        "& *": {
            margin: theme.spacing(.5),
        }

    }
}));

const Preview = (props) => {
    const classes = useStyles()

    const app = getCollection({collection: props.formData, site: {id: 1, name: "preview"}})
   
    const defaultMenu = [
        { value: 'preview', label: 'Preview', selected: true },
        { value: 'previewMenu', label: 'Menu' },
        { value: 'previewAction', label: 'Action' },
        { value: 'schema', label: 'schema' },
        { value: 'uiSchema', label: 'uiSchema' },
        { value: 'formData', label: 'formData' }
    ]

    const [preview, setPreview] = React.useState(defaultMenu[0])
    const [menu, setMenu] = React.useState(defaultMenu)

    const handleSelect = (item) => {
        const { value } = item;

        setPreview(item)
 
        const newMenu = menu.map(item => {
            return {
                ...item,
                selected: item.value === value || false
            }
        })

        setMenu(newMenu);

    }

    const PreviewTemplate = templates[preview.value]
    
    return (
        <div className={classes.root}>
            <PreviewTemplate {...props} app={app} />
            <NavPreview className={classes.menu} menu={menu} onSelect={handleSelect} />
        </div>
    )

}

function mapStateToProps(state) {
	return {
        schema: state.editor.schema,
        uiSchema: state.editor.uiSchema,
        formData: state.editor.formData,
        formContext: state.editor.formContext
	};
}  

export default connect(
  mapStateToProps,
)(Preview);
