export default {
  "select": {
    "name" : "name",
    "title" : "title",
    "description" : "description",
    "metadata" : "metadata",
    "theme": "theme",
    "label": "label"
  },
  prepare(selection) {
    let { label, name, title, description, content, appType, authSites } = selection

    let theme = {};
    
    if (content) {
      name = content.name || name;
      title = content.title || "Untitled site";
      description = content.description || "";
      
      if (content.colors && content.colors.length) {
          theme = {
              primaryColor: content.colors[0].value
          }
      }
      
    }
    
    let metadata = []

    const sitesCount = authSites && authSites.length;
    
    if (sitesCount > 1) {
        metadata.push(sitesCount + " sites")
    } else if (sitesCount) {
        metadata.push(sitesCount + " site")
    }
    
    
    if (!label) {
        label = appType || "App"
    }
    
    return {
      name: name,
      title: title,
      description: description,
      metadata: metadata,
      theme: theme,
      label: label
    }
    
  }
  
}