export default {
    "type": "array",
    "items": {
        "type": "string",
        "enum": [
            "image",
            "video",
            "audio",
            "youtube",
            "vimeo",
            "sketchfab"
        ],
        "enumNames": [
            "Bilder",
            "Video",
            "Audio",
            "YouTube",
            "Vimeo",
            "Sketchfab"
        ]
    },
    "uniqueItems": true
}