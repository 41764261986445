import React, { Component, useMemo } from 'react';
import { computeGridLines } from '../lib/compute'

class Grid extends Component {
    
    renderGridX = () => {
        const { width, height, xScale, yScale, xValues, yValues } = this.props;
        
        if (!xScale) {
            return false
        }
        
        const xLines = computeGridLines({
            width,
            height,
            scale: xScale,
            axis: 'x',
            values: xValues,
        })

        return xLines.map(this.renderLineX)
        
    }

    renderGridY = () => {
        const { width, height, xScale, yScale, xValues, yValues } = this.props;

        if (!yScale) {
            return false
        }
        
        const yLines = computeGridLines({
            width,
            height,
            scale: yScale,
            axis: 'y',
            values: yValues,
        })
        
        return yLines.map(this.renderLineY)
        
    }

    renderLineX = (line, index) => {
        
        return (
            <line {...line} key={index} className="gridline  gridline--x" />
        )
        
    }
    
    renderLineY = (line, index) => {
        
        return (
            <line {...line} key={index} className="gridline  gridline--y" />
        )
        
    }
    
    render() {
        
        return (
            <g className="grid">
                { this.renderGridX() }
                { this.renderGridY() }
            </g>
        )
    
    }

}

export default Grid