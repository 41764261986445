// initialState

let initialState = {
};

// app state

const reports = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_REPORTS':

      return Object.assign({}, state, {
        [action.id]: undefined
      })

    case 'RECEIVE_REPORTS':

      return Object.assign({}, state, {
        [action.id]: action.data
      })

    default:

      return state

    }

}

export default reports;