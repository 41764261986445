import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Widget, WidgetHeader, WidgetTitle, WidgetDescription, WidgetBody, WidgetFooter, WidgetLoading } from "../"
import { Chart } from "../"

import { getGaChartByType } from "../../resources/functions/"

import "./AnalyticsChart.scss"

class AnalyticsChart extends Component {

    static propTypes = { 
        loadingTitle: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        tooltipTitle: PropTypes.string,
        tooltipLabel: PropTypes.string,
        scope: PropTypes.string
    }

    static defaultProps = {
        loadingTitle: "Loading chart",
        scope: undefined,
        widgetType: "analytics-chart",
        widgetSize: "1:4",
        report: undefined,
        title: "Chart",
        type: "bar",
        compare: false,
        stacked: false,
        labels: undefined,
        debug: false,
    }
    
    state = {
        id: undefined,
        chart: undefined,
        loading: true,
        values: {}
    }

    componentDidMount() {
        this.getReport()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.report !== this.props.report) {
            this.getReport()
        }
    }

    getReport = () => {
        const { report, type, compare, stacked, tooltipTitle, tooltipLabel } = this.props;

        this.setState({
            chart: undefined,
            loading: true
        })
        
        if (!report) {
            return false
        }
        
        // chart
        
        let chart

        if (report) {
            chart = getGaChartByType(type, report)
        }

        this.setState({
            loading: false,
            chart: {
                ...chart,
                type: type,
                compare: compare,
                stacked: stacked,
                tooltipTitle: tooltipTitle,
                tooltipLabel: tooltipLabel,
            }
        })
        
    }
    
    renderChart = () => {
        const { data, theme, colors } = this.props;
        const { chart } = this.state;
        
        return (
            <Chart {...chart} outerMargin={{top: 20, right: 20, bottom: 20, left: 20}} theme={theme} colors={colors} />
        )

        
    }
    
    render() {
        const { data, theme, scope, title, description, loadingTitle } = this.props;
        const { chart, values } = this.state;

        const loading = this.state.loading || this.props.loading;
        
        if (loading) {
            return (
                <Widget {...this.props}>
                    <WidgetLoading theme={theme} title={loadingTitle} />            
                </Widget>
            )
        }
        
        return (
            <Widget {...this.props}>
                <WidgetHeader>
                    <WidgetTitle title={title} />
                    <WidgetDescription description={description} />
                </WidgetHeader>
                <WidgetBody>
                    { this.renderChart() }
                </WidgetBody>
            </Widget>
        )


    }

}

export default AnalyticsChart;