import React from "react";

const icon = (

<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
  <g fill="none" fillRule="evenodd">
    <path fill="#CCCCCC" fillRule="nonzero" d="M46.7,31.08 C45.34,24.18 39.28,19 32,19 C26.22,19 21.2,22.28 18.7,27.08 C12.68,27.72 8,32.82 8,39 C8,45.62 13.38,51 20,51 L46,51 C51.52,51 56,46.52 56,41 C56,35.72 51.9,31.44 46.7,31.08 Z"/>
    <path fill="#F2F2F2" fillRule="nonzero" d="M46.7,28.08 C45.34,21.18 39.28,16 32,16 C26.22,16 21.2,19.28 18.7,24.08 C12.68,24.72 8,29.82 8,36 C8,42.62 13.38,48 20,48 L46,48 C51.52,48 56,43.52 56,38 C56,32.72 51.9,28.44 46.7,28.08 Z"/>
    <g transform="translate(4 9)">
      <path fill="#0666B0" fillRule="nonzero" d="M30,31.5 L30,22.5 C30,21.675 29.325,21 28.5,21 L19.5,21 C18.675,21 18,21.675 18,22.5 L18,31.5 C18,32.325 18.675,33 19.5,33 L28.5,33 C29.325,33 30,32.325 30,31.5 Z M22,27.8 L23.3533333,29.968 L25.3333333,27 L28,31 L20,31 L22,27.8 Z"/>
      <path fill="#004388" fillRule="nonzero" d="M49.5,9 L40.5,9 C39.675,9 39,9.675 39,10.5 L39,19.5 C39,20.325 39.675,21 40.5,21 L49.5,21 C50.325,21 51,20.325 51,19.5 L51,10.5 C51,9.675 50.325,9 49.5,9 Z M48,12.75 L45.75,12.75 L45.75,16.875 C45.75,17.91 44.91,18.75 43.875,18.75 C42.84,18.75 42,17.91 42,16.875 C42,15.84 42.84,15 43.875,15 C44.3025,15 44.685,15.1425 45,15.3825 L45,11.25 L48,11.25 L48,12.75 Z"/>
      <path fill="#8DA4D5" d="M4,25 L4,17 L9,21 L4,25 Z M10.5,15 L1.5,15 C0.675,15 0,15.675 0,16.5 L0,25.5 C0,26.32575 0.675,27 1.5,27 L10.5,27 C11.325,27 12,26.32575 12,25.5 L12,16.5 C12,15.675 11.325,15 10.5,15 L10.5,15 Z"/>
      <path fill="#999999" d="M20.25,12 L15.75,12 C15.3375,12 15,12.3375 15,12.75 L15,17.25 C15,17.662875 15.3375,18 15.75,18 L20.25,18 C20.6625,18 21,17.662875 21,17.25 L21,12.75 C21,12.3375 20.6625,12 20.25,12 L20.25,12 Z"/>
      <path fill="#CCCCCC" d="M11.25 6L6.75 6C6.3375 6 6 6.3375 6 6.75L6 11.25C6 11.662875 6.3375 12 6.75 12L11.25 12C11.6625 12 12 11.662875 12 11.25L12 6.75C12 6.3375 11.6625 6 11.25 6L11.25 6zM29.25 0L24.75 0C24.3375 0 24 .3375 24 .75L24 5.25C24 5.662875 24.3375 6 24.75 6L29.25 6C29.6625 6 30 5.662875 30 5.25L30 .75C30 .3375 29.6625 0 29.25 0L29.25 0zM41.625 3L39.375 3C39.16875 3 39 3.16875 39 3.375L39 5.625C39 5.8314375 39.16875 6 39.375 6L41.625 6C41.83125 6 42 5.8314375 42 5.625L42 3.375C42 3.16875 41.83125 3 41.625 3L41.625 3zM35.625 18L33.375 18C33.16875 18 33 18.16875 33 18.375L33 20.625C33 20.8314375 33.16875 21 33.375 21L35.625 21C35.83125 21 36 20.8314375 36 20.625L36 18.375C36 18.16875 35.83125 18 35.625 18L35.625 18zM26.625 15L24.375 15C24.16875 15 24 15.16875 24 15.375L24 17.625C24 17.8314375 24.16875 18 24.375 18L26.625 18C26.83125 18 27 17.8314375 27 17.625L27 15.375C27 15.16875 26.83125 15 26.625 15L26.625 15zM14.625 30L12.375 30C12.16875 30 12 30.16875 12 30.375L12 32.625C12 32.8314375 12.16875 33 12.375 33L14.625 33C14.83125 33 15 32.8314375 15 32.625L15 30.375C15 30.16875 14.83125 30 14.625 30L14.625 30z"/>
      <path fill="#999999" d="M35.625,3 L33.375,3 C33.16875,3 33,3.16875 33,3.375 L33,5.625 C33,5.8314375 33.16875,6 33.375,6 L35.625,6 C35.83125,6 36,5.8314375 36,5.625 L36,3.375 C36,3.16875 35.83125,3 35.625,3 L35.625,3 Z"/>
      <path fill="#CCCCCC" d="M17.625 6L15.375 6C15.16875 6 15 6.16875 15 6.375L15 8.625C15 8.8314375 15.16875 9 15.375 9L17.625 9C17.83125 9 18 8.8314375 18 8.625L18 6.375C18 6.16875 17.83125 6 17.625 6L17.625 6zM26.625 9L24.375 9C24.16875 9 24 9.16875 24 9.375L24 11.625C24 11.8314375 24.16875 12 24.375 12L26.625 12C26.83125 12 27 11.8314375 27 11.625L27 9.375C27 9.16875 26.83125 9 26.625 9L26.625 9zM20.625 0L18.375 0C18.16875 0 18 .16875 18 .375L18 2.625C18 2.8314375 18.16875 3 18.375 3L20.625 3C20.83125 3 21 2.8314375 21 2.625L21 .375C21 .16875 20.83125 0 20.625 0L20.625 0z"/>
      <path fill="#999999" d="M35.25 9L30.75 9C30.3375 9 30 9.3375 30 9.75L30 14.25C30 14.662875 30.3375 15 30.75 15L35.25 15C35.6625 15 36 14.662875 36 14.25L36 9.75C36 9.3375 35.6625 9 35.25 9L35.25 9zM38.25 24L33.75 24C33.3375 24 33 24.3375 33 24.75L33 29.25C33 29.662875 33.3375 30 33.75 30L38.25 30C38.6625 30 39 29.662875 39 29.25L39 24.75C39 24.3375 38.6625 24 38.25 24L38.25 24z"/>
    </g>
  </g>
</svg>

        
);

export default {
    uniqueId: "user/media",
    appType: "user/service",
    name: "media",
    icon: icon,
    title: "Media"
};