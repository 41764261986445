import schema from "./schema";
import uiSchema from "./uiSchema";
import preview from "./preview";

export default {
    id: 52,
    schemaType: 'dm',
    name: 'inbox',
    JSONSchema: schema,
    uiSchema: uiSchema,
    preview: preview
}