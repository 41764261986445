import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import AppLoader from "../App/AppLoader"

import { InboxFinder, InboxEditor } from "../AppInbox";

import CalendarQuery from "../AppCalendar/CalendarQuery"
import CollectionSettings from "./CollectionSettings"

import CollectionEditor from "../AppAdmin/CollectionEditor"
import SchemaEditor from "../AppAdmin/SchemaEditor"

import DocumentFinder from "../AppDocuments/DocumentFinder"
import DocumentEditor from "../AppDocuments/DocumentEditor"
import DocumentReferences from "../AppDocuments/DocumentReferences"
import DocumentChildren from "../AppDocuments/DocumentChildren"
import DocumentTree from "../AppDocuments/DocumentTree"

import MediaFinder from "../AppMedia/MediaFinder"
import MediaEditor from "../AppMedia/MediaEditor"
import MediaUpload from "../AppMedia/MediaUpload"

import AnalyticsHome from "../AppAnalytics/AnalyticsHome";
import AnalyticsReport from "../AppAnalytics/AnalyticsReport";


const templates = {
    "documents": DocumentFinder,
    "documents/tree": DocumentTree,
    "calendar": CalendarQuery,
    "analytics": AnalyticsHome,
    "analytics/report": AnalyticsReport,
    "media": MediaFinder,
    "upload": MediaUpload,
    "social": MediaFinder,
    "youtube": MediaFinder,
    "vimeo": MediaFinder,
    "sketchfab": MediaFinder,
    "ekultur": MediaFinder,
}

const CollectionRoutes = ({site, collection, app, menuByUrl, ...props}) => {

    if (!site.uniqueId || !collection.uniqueId || !app.uniqueId) {
        return <AppLoader site={site} collection={collection} app={app}></AppLoader>
    }

    const renderTemplate = () => {
        
        const pathname = props.location.pathname;
        const finder = menuByUrl && menuByUrl[pathname] || {}
    
        const template = finder.template || finder.query && finder.query.models || undefined
        const query = finder.query || {}

        const FinderTemplate = template && templates[template]

        if (FinderTemplate) {
            return (
                <FinderTemplate {...props} query={query} children={undefined} />
            )
        }

        return (
            <p>{template} {pathname}</p>
        )
    
    }

    return (
        <Switch>
            <Route path="/:siteName/:appName/media/:parents*/:uniqueId/edit/:referenceId*" component={ MediaEditor } />
            <Route path="/:siteName/:appName/media/:parents*/upload" component={ MediaUpload } />
 
            <Route path="/:siteName/:appName/:documentType*/<new>/edit" component={ DocumentEditor } />
            <Route path="/:siteName/:appName/:parents*/:uniqueId/edit/:referenceId*" component={ DocumentEditor } />
            <Route path="/:siteName/:appName/:parents*/:uniqueId/children" component={ DocumentChildren } />
            <Route path="/:siteName/:appName/:parents*/:uniqueId/references" component={ DocumentReferences } />
            <Route path="/:siteName/:appName/:parents*/tree/:children*/:uniqueId" component={ DocumentTree } />

            <Route path="/:siteName/:appName" render={ renderTemplate } />
        </Switch>        
    )

    return (
        <Switch>
            <Route path="/:siteName/:appName/settings/collection/:collectionId/schema/:uniqueId/edit" component={ SchemaEditor } />
            <Route path="/:siteName/:appName/settings/collection/:uniqueId/edit" component={ CollectionEditor } />
            <Route path="/:siteName/:appName/settings" component={ CollectionSettings } />

            <Route path="/:siteName/:appName/calendar/:yyyy/:mm/:dd" component={ CalendarQuery } />
            <Route path="/:siteName/:appName/calendar/:yyyy/:mm" component={ CalendarQuery } />
            <Route path="/:siteName/:appName/calendar/:yyyy" component={ CalendarQuery } />
            <Route path="/:siteName/:appName/calendar" component={ CalendarQuery } />

            <Route path="/:siteName/:appName/inbox/:inboxType/:uniqueId" component={ InboxEditor } />
            <Route path="/:siteName/:appName/inbox" component={ InboxFinder } />

            <Route path="/:siteName/:appName/analytics/:reportType/:reportDetails" component={ AnalyticsReport } />
            <Route path="/:siteName/:appName/analytics/:reportType" component={ AnalyticsReport } />
            <Route path="/:siteName/:appName/analytics" component={ AnalyticsHome } />

           </Switch>
    )

}

const mapStateToProps = (state) => {
	return {
    	site: state.site,
        collection: state.collection,
        app: state.app,
        menuByUrl: state.menuByUrl
	};
}  

export default connect(
    mapStateToProps,
)(CollectionRoutes);