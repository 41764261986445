import React from "react";
import moment from 'moment';
import SvgIcon from '@material-ui/core/SvgIcon';

export function getCalendarIcon(d = "31") {
    
    return (
        <SvgIcon width="64" height="64" viewBox="0 0 64 64">
            <path fill="#CCCCCC" d="M46,17 L18,17 C15.78,17 14.02,18.8 14.02,21 L14,49 C14,51.2 15.78,53 18,53 L46,53 C48.2,53 50,51.2 50,49 L50,21 C50,18.8 48.2,17 46,17 Z"/>
            <g transform="translate(14 14)">
              <path fill="#F2F2F2" d="M32,0 L4,0 C1.78,0 0.02,1.8 0.02,4 L0,32 C0,34.2 1.78,36 4,36 L32,36 C34.2,36 36,34.2 36,32 L36,4 C36,1.8 34.2,0 32,0 Z"/>
              <path fill="#0666B0" d="M32,0 L4,0 C1.78,0 0.02,1.8 0.02,4 L0,10 C0.02,10 36,10 36,10 L36,4 C36,1.8 34.2,0 32,0 Z"/>
            </g>
            <rect width="4" height="10" x="20" y="10" fill="#CCCCCC" rx="2"/>
            <rect width="4" height="10" x="40" y="10" fill="#CCCCCC" rx="2"/>
            <text x="32" y="38" fill="#000000" fontFamily="Akkurat Mono, monospace" fontSize="20" dominantBaseline="middle" textAnchor="middle">{d}</text>
        </SvgIcon>
    )
    
}

const CalendarIcon = ({d}) => {
    if (!d) {
        d = moment().format('D');
    }

    return getCalendarIcon(d)
}

export default CalendarIcon