import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MediaPlayPauseButton from "./MediaPlayPauseButton"
import MediaVolumeControls from "./MediaVolumeControls"
import MediaTimeControls from "./MediaTimeControls"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        zindex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "black",
        color: "white"
    },
    header: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    footer: {
        margin: theme.spacing(0, 1)
    },
    playPauseButton: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    info: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(0, 1)
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        color: "white"
    },
    description: {
        fontFamily: "Akkurat, sans-serif",
        color: "rgba(255, 255, 255, 0.7)"
    },
}));

const VideoControls = ({className, title, untitled, description, paused, buffered, duration = 0, currentTime = 0, onPlayPause, onSeek, muted, volume, onMute, onVolume}) => {

    const classes = useStyles()

    return (
        <nav className={className || classes.root}>
            <header className={classes.header}>
                <MediaPlayPauseButton className={classes.playPauseButton} paused={paused} onClick={onPlayPause} />
                <div className={classes.info}>
                    <Typography component="h2" className={classes.title}>{title || untitled}</Typography>
                    { description && <Typography component="h3"className={classes.description}>{description}</Typography> }
                </div>
            </header>
            <footer className={classes.footer}>
                <MediaTimeControls duration={duration} currentTime={currentTime} onChange={onSeek} />
            </footer>
        </nav>

    )

}

export default VideoControls