export default {
    type: 'object',
    properties: {
        enabled: {
            type: "boolean"
        },
        label: {
            type: "string"  
        }
    }
}