import { getApp } from '../../redux/functions/app';

import getCollectionAction from "./getCollectionAction"
import getCollectionSearch from "./getCollectionSearch"
import getCollectionMenu from "./getCollectionMenu"
import getCollectionLanguages from "./getCollectionLanguages"

export function getCollectionApp(props) {

    const app = getCollection(props)

    return function (dispatch) {
        dispatch(getApp(app))
    }

}

export function getCollection({user, site, collection}) {
    const { content } = collection;

    const title = collection.title;
    const name = collection.name;
    const root = "/" + site.name + "/" + name

    const theme = content && content.theme;

    const app = {
        layout: "finder",
        collectionType: collection && collection.collectionType,
        defaultLocale: collection && collection.defaultLocale,
        uniqueId: collection && collection.uniqueId,
        siteId: site && site.id,
        collectionId: collection && collection.id,
        root: root,
        name: name,
        title: title,
        theme: theme
    }

    let languages, search, menu, primaryAction;

    if (content && content.languages) {
        languages = getCollectionLanguages({...app, languages: content.languages})
    }

    if (content && content.search) {
        search = getCollectionSearch({...app, search: content.search})
    }
   
    if (content && content.menu && content.menu.length) {
        menu = getCollectionMenu({app, user, site, collection})
    }

    if (content && content.documentTypes) {
        primaryAction = getCollectionAction({...app, documentTypes: content.documentTypes})
    }

    return {
        ...app,
        languages: languages,
        primaryAction: primaryAction,
        search: search,
        menu: menu
    }
  
}