import React, { Component } from 'react';
import { getUiOptions, getDefaultFormState } from "@frontend-components/admin"

class ImageCropdataField extends Component {

    static defaultProps = {
        defaultOptions: {
            "layout": "imageCropper"
        }
    }

    getField = () => {
        const { uiSchema, defaultOptions } = this.props;

        const uiOptions = getUiOptions(uiSchema)

        return {
            ...this.props,
            uiSchema: {
                ...uiSchema,
                "ui:options": {
                    ...defaultOptions,
                    ...uiOptions
                }
            }
        }

    }

    render() {
        const { registry, uiSchema } = this.getField()
        const { ObjectField } = registry.fields;

        return (
            <ObjectField {...this.props} uiSchema={uiSchema} />
        )
        
    }

}

export default ImageCropdataField;