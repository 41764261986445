import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import AnalyticsHome from "./AnalyticsHome";
import AnalyticsReport from "./AnalyticsReport";
import AnalyticsCharts from "./AnalyticsCharts";

import { CollectionRoutes } from "../AppCollection"

class AnalyticsRoutes extends Component {
  
  render() {
    
    return (
      
        <Switch>
            <Route path="/:appName/debug" component={ AnalyticsCharts } />
            <Route path="/:appName/:reportSection/:reportType" component={ AnalyticsReport } />
            <Route path="/:appName/:reportSection" component={ AnalyticsReport } />
            <Route path="/:appName" component={ AnalyticsHome } />
        </Switch>

    )

  }

}

export default AnalyticsRoutes;