import { requestDialog, receiveDialog } from '../actions/dialog';
import { requestDialogFormData, receiveDialogFormData } from '../actions/dialog';
import { requestDialogQuery, receiveDialogQuery } from '../actions/dialog';
import { requestDialogSelected, receiveDialogSelected } from '../actions/dialog';

export function getDialog(dialog) {

    return function(dispatch) {
        dispatch(requestDialog())
        dispatch(receiveDialog(dialog))
    }

}

export function closeDialog() {

    return function(dispatch) {
        dispatch(requestDialog())
    }

}

export function dialogChange(formData) {

    return function(dispatch) {
        dispatch(receiveDialogFormData(formData))
    }

}

export function dialogQuery(query) {

    return function(dispatch) {
        dispatch(receiveDialogQuery(query))
    }

}

export function getSelected(selected) {

    return function(dispatch) {
        dispatch(requestDialogSelected())
        dispatch(receiveDialogSelected(selected))
    }

}
