import React, { Component } from 'react';
import PreviewLabel from "./PreviewLabel"

class PreviewBoolean extends React.Component {

  render() {
    const { name, title, formData, className } = this.props;

    return (

        <div className={className}>
        
            <PreviewLabel {...this.props} />

          <p className="field__boolean">
            <b className="boolean__label">{ title || name }</b>
            <i className="boolean__value" data-value={ Boolean(formData) }>{ Boolean(formData).toString() }</i>
          </p>
      
      </div>
    
    )
  
  }
  
}

export default PreviewBoolean;