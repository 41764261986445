import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ImportantIcon from "@material-ui/icons/LabelImportant"
import CommentsIcon from "@material-ui/icons/Forum"

import Typography from '@material-ui/core/Typography';
import UserIcon from "../User/UserIcon"
//import InboxIcon from "./InboxIcon"

import getInboxModule from "./utils/getInboxModule"

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        width: "100%",
        position: "relative",
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(2),
        userSelect: "none",
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        position: "absolute",
        top: 0,
        left: 0,
        width: theme.spacing(4.5), 
        height: theme.spacing(4.5),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    icon: {
        fontSize: "18px",
        color: props => { return props.color || "rgba(0,0,0,.25)" }
    },
    authors: {
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px"
    },
    name: {
        "&[data-unread=true]": {
            fontWeight: "bold"
        }
    },
    count: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "12px"
    },

}));

const InboxAuthor = ({unread, shortName, name, size}) => {
    const classes = useStyles()

    if (size === "small") {
        return <b className={classes.name} data-unread={unread}>{shortName}</b>
    }

    return <b className={classes.name} data-unread={unread}>{name}</b>

}

const InboxAuthors = ({authors = []}) => {
    const classes = useStyles()

    if (!authors.length) {
        return false
    }

    if (authors.length > 2) {
        return (
            <Typography noWrap className={classes.authors}>
                <InboxAuthor {...authors[0]} size="small" />
                 ...
                 <InboxAuthor {...authors[authors.length-1]} size="small" />
            </Typography>
        )
    }

    if (authors.length === 2) {
        return (
            <Typography noWrap className={classes.authors}>
                <InboxAuthor {...authors[0]} size="small" />,
                <InboxAuthor {...authors[authors.length-1]} size="small" />
            </Typography>
        )
    }

    return (
        <Typography noWrap className={classes.authors}>
            <InboxAuthor {...authors[0]} />
        </Typography>
    )

}

const InboxIcon = ({inboxType, important, appColor}) => {

    let color = "#ccc"

    if (important) {
        color = "orange" || appColor
    }

    const classes = useStyles({color})

    if (inboxType === "comment" || inboxType === "forum") {
        return <CommentsIcon className={classes.icon} />
    }

    return (
        <ImportantIcon className={classes.icon} />
    )
    
}

const InboxModuleHeader = ({className, ...props}) => {
    const { appColor, important, author, authors, messagesCount } = getInboxModule(props)

    const classes = useStyles()

    return (
        <header className={className || classes.root}>
            <UserIcon {...author} className={classes.avatar} />
            <InboxIcon {...props} className={classes.icon} />
            <InboxAuthors authors={authors} />
            <i className={classes.count}>{messagesCount}</i>
        </header>
    )

}

export default InboxModuleHeader;