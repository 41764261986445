const messages = {
    "ga:users": "Brukere",
    "ga:newUsers": "Nye brukere",
    "ga:sessions": "Besøk",
    "ga:percentNewSessions": "Nye besøk",
    "ga:bounces": "Flukt",
    "ga:bounceRate": "Fluktfrekvens",
    "ga:sessionDuration": "Tid per besøk",
    "ga:avgSessionDuration": "Tid/besøk",
    "ga:uniquePageviews": "Unike sidevisninger",
    "ga:pageviews": "Sidevisninger",
    "ga:pageviewsPerSession": "Sider/besøk",
    "ga:timeOnPage": "Tid per side",
    "ga:avgTimeOnPage": "Tid/side",
    "ga:medium:organic": "Organisk",
    "ga:medium:referral": "Henvisning",
    "ga:medium:(none)": "Ukjent"
};

export default messages;