import React, { Component } from 'react';
import { DashboardTitle, DashboardLabel } from "../"

import "./DashboardHeader.scss";

class DashboardHeader extends Component {

    render() {
        const { expanded, title, label } = this.props;

        return (
            <header className="admin-dashboard__header" aria-expanded={expanded}>
                { this.props.children }
            </header>
        )
    }
}

export default DashboardHeader;