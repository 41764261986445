export default {
    "type": "object",
    "properties": {
        "updatedAt": {
            "type": "string",
            "enum": [
                "today",
                "lastWeek",
                "lastMonth",
                "lastYear",
            ]
        }
    }
}