import React, { Component } from 'react';
import "./Dashboard.scss"

class Dashboard extends Component {

    render() {
        const { expanded } = this.props;

        return (
            <div className="admin-dashboard" aria-expanded={expanded}>
                {this.props.children}
            </div>
        )
    }
}

export default Dashboard;