import merge from "deepmerge"

export const getSchemaPreview = ({formData, currentLocale}) => {

    const previewSchema = formData && formData.content && getSchema(formData.content, currentLocale)
    const previewUiSchema = formData && formData.content && getUiSchema(formData.content, currentLocale)
    const previewFormData = formData && formData.content && getFormData(formData.content)
    const uiPreview = formData && formData.content && formData.content.preview && getUiPreview(formData.content.preview)

    return {
        schema: previewSchema,
        uiSchema: previewUiSchema,
        formData: previewFormData,
        uiPreview: uiPreview,
    }

}

export const getUiPreview = ({select, prepare}) => {

    let uiPreview = {}

    if (select) {
        try {
            uiPreview.select = JSON.parse(select);
        } catch(error) {
            uiPreview.select = {}
        }
    }

    if (prepare) {
        try {
            uiPreview.prepare = eval(prepare)
        } catch(error) {
            uiPreview.prepare = undefined
        }
//        uiPreview.prepare = new Function(["props"], prepare);  
    }

    return uiPreview
    
}

export const getFormData = ({formData}) => {

    let formDataPreview = {};

    try {
        formDataPreview = JSON.parse(formData);
    } catch(error) {
        return undefined
    }

    return formDataPreview

}

export const getUiSchema = ({uiSchema, uiSchemaLocalized}, currentLocale) => {

    try {
        uiSchema = JSON.parse(uiSchema);
    } catch(error) {
        return undefined
    }

    const localeId = currentLocale && "locale:" + currentLocale;
    const localizedUiSchema = uiSchemaLocalized && localeId && uiSchemaLocalized[localeId]

    if (localizedUiSchema) {

        try {
            uiSchema = merge(uiSchema, JSON.parse(localizedUiSchema))
        } catch(error) {
            return undefined
        }
    
    }

    return {
        "ui:layout": "pageEditor",
        "fieldset": ["content"],
        "content": {
            "ui:layout": "pageContent",
            ...uiSchema
        }
    }

}

export const getSchema = ({schema, schemaLocalized}, currentLocale) => {

    try {
        schema = JSON.parse(schema);
    } catch(error) {
        return undefined
    }

    const localeId = currentLocale && "locale:" + currentLocale;
    const localizedSchema = schemaLocalized && localeId && schemaLocalized[localeId]

    if (localizedSchema) {

        try {
            schema = merge(schema, JSON.parse(localizedSchema))
        } catch(error) {
            return undefined
        }
    
    }

    return {
        "type": "object",
        "properties": {
            "content": schema
        }
    }
    
}