import { getOwnerIcon } from "../icons/"

export default {
    uniqueId: 1,
    root: "/rock",
    name: "rock",
    abbr: "ROCK",
    title: "Rockheim",
    imageUrl: "https://dms-cf-09.dimu.org/image/032yiVGVkzHu",
    apps: [
        {
            icon: getOwnerIcon({text: "RCK", fill: "rgb(64, 53, 118)"}),
            url: "/rock/",
            title: "Forside"
        },
        {
            icon: "m24",
            layout: "finder",
            ownerId: 1,
            url: "/rock/m24/",
            title: "Museum24"
        },
        {
            icon: "dm",
            layout: "finder",
            ownerId: 1,
            url: "/rock/dm/",
            title: "DigitaltMuseum"
        },
        {
            icon: "kp",
            layout: "finder",
            ownerId: 1,
            url: "/rock/kp/",
            title: "KulturPunkt"
        },
        {
            icon: "pr",
            layout: "finder",
            ownerId: 1,
            url: "/rock/m24/",
            title: "Primus"
        },
        {
            icon: "kn",
            layout: "finder",
            ownerId: 1,
            url: "/rock/m24/",
            title: "KulturNav"
        },
        {
            icon: "mi",
            layout: "finder",
            ownerId: 1,
            url: "/rock/m24/",
            title: "Minne"
        },
        {
            layout: "finder",
            ownerId: 1,
            url: "/rock/hof/",
            title: "Hall of Fame",
            imageUrl: "https://dms-test.dimu.org/image/012uNXPMCuYh?mediaType=image/svg"
        },
        {
            icon: 'settings',
            layout: "finder",
            title: "Innstillinger"
        }
    ]
}