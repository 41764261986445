export default {
    "type": "string",
    "enum": [
        "chat",
        "chatLinks",
        "chatText",
        "chatVideo",
        "chatImage",
    ],
    "enumNames": [
        "Chat",
        "Sporvalg",
        "Spørsmål",
        "Video",
        "Bilde",
    ],
    "default" : "chat"
}