export default {
    "type": "string",
    "enum": [
        "page/list",
        "page/grid",
        "page/timeline",
        "page/annotate",
        "page/media"
    ],
    "enumNames": [
        "Liste",
        "Mosaikk",
        "Tidslinje",
        "Bildekart",
        "Media"
    ]
}