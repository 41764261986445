import React from 'react';
import { AppsGrid } from '../';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const ButtonBack = ({className, onClick}) => {
    return (
        <ButtonBase className={className} onClick={onClick}>
            Endre
        </ButtonBase>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        position: "relative",
    },
    header: {
        display: "flex",
        borderTop: "1px solid",
        borderColor: "#ccc",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "18px",
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        lineHeight: "24px",
    },
    toggleButton: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        fontWeight: "normal",
        margin: "0 6px",
    },
    section: {
        display: "block",
        width: "100%",
        padding: "18px 25px",
    }
}));

const AppsByUser = ({title, onCollapse, apps, onSelect}) => {

    const classes = useStyles()

    return (
        <section className={classes.root}>
            { title &&
                <header className={classes.header}>
                    <Typography className={classes.title}>{title}</Typography>
                    <ButtonBack className={classes.toggleButton} onClick={onCollapse} />
                </header>
            }
            <div className={classes.section}>
                <AppsGrid apps={apps} onSelect={onSelect} />
            </div>
        </section>
    )

}

export default AppsByUser;
