// initialState

let initialState = {
};

// nav state

const menuByUrl = (state = initialState, action) => {

	switch (action.type) {

        case 'REQUEST_MENU_BY_URL':
            return initialState

        case 'RECEIVE_MENU_BY_URL':
            return action.data

        case 'RECEIVE_MENU_ITEM':
            return {
                ...state,
                [action.url]: action.item
            }
    
        case 'RECEIVE_MENU_ITEM_COUNT':
            return {
                ...state,
                [action.url]: {
                    ...state[action.url],
                    count: action.count
                }
            }
    
        case 'RECEIVE_MENU_ITEM_CHILDREN':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    children: action.children
                }
            }

        default:
            return state
        }

}

export default menuByUrl;