import React, { Component } from 'react';
import Button from './Button';

import './ButtonPrimary.scss';

class ButtonPrimary extends Component {
    render() {
        return (
            <Button {...this.props} className="admin-button  admin-button--primary  bg-primary" />
        );
    }
}

export default ButtonPrimary;
