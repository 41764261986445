import { getAppData } from '../../redux/functions/app';
import { getUserCollection } from '../../redux/functions/appCollection';

//import schemas from '../schemas/';

export function getApp(props) {
    const { user, site, collection } = props;
    
    const appTitle = "Innboks"
    const appName = "inbox"
    const appRoot = "/inbox"
    
    props = {
        ...props,
        appTitle: appTitle,
        appName: appName,
        appRoot: appRoot,
    }
    
    const appButton = getAppButton(props)
    const appMenu = getAppMenu(props)
    const appSearch = getAppSearch(props)

    const primaryAction = {
        label: "Create"
    }
    
    const app = {
        layout: "finder",
        title: "Innboks",
        root: appRoot,
        name: appName,
        theme: {
            primaryColor: "#fff"
        },
        header: {
            title: appTitle,
            subtitle: user.name || "N/A"
        },
        sidebar: {
            expanded: true,
        },
        search: appSearch,
        primaryAction: primaryAction,
//        schemas: schemas,
        languages: [
            "no", "sv", "en", "de", "es", "fr"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
        dispatch(getUserCollection({...props, app: app}))
    }
    
}

export function getAppSearch(props) {
    const { appTitle } = props;
    
    return {
        "placeholder": "Søk i " + appTitle
    }
    
}

export function getAppButton(props) {
    const { appRoot } = props;

    return {
      title: "Ny melding",
      url: appRoot + "/section/category/<new>",
      icon: ""
    }
    
}

export function getAppMenu(props) {
    const { appRoot, collection } = props;
    
   return [
      {
        title: "Innboks",
        icon: "inbox",
        url: appRoot
      },
      {
          role: "group",
          children: [
              {
                title: "DigitaltMuseum",
                icon: "",
                url: appRoot + "/dm"
              },
              {
                title: "Museum24",
                icon: "",
                url: appRoot + "/m24"
              },
              {
                title: "KulturPunkt",
                icon: "",
                url: appRoot + "/kp"
              }
            ]
        },
        {
            role: "group",
            children: [
              {
                title: "Forum",
                icon: "",
                url: appRoot + "/forum"
              },
              {
                title: "Tilbakemeldinger",
                icon: "",
                url: appRoot + "/feedback"
              }
          ]
      }
    ]

}