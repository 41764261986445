import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AnalyticsReportLive } from '@frontend-components/admin';

import { getReports } from '../redux/functions/reports';

class WidgetLiveReport extends Component {
    
    static defaultProps = {
        query: {
            reportType: "live"
        }
    }

    componentDidMount() {
        this.getReport()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.id !== this.props.id) {
            this.getReport()
        }

    }
    
    getReport = () => {
        let { id, query } = this.props;

        this.props.getReports({
            ...query,
            id: id
        })
        
        
    }
    
    render() {
        const { theme, reports, id } = this.props;
        
        const report = reports[id] || {}
    
        return (
            <AnalyticsReportLive {...this.props} {...report} theme={theme} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	reports: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetLiveReport);