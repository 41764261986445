import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearch } from '../redux/functions/search';
import { FinderResults } from './';
import qs from 'query-string';

const FinderQuery = ({children, ...props}) => {
    let { query, searchById } = props;

    let sq = props.location && props.location.search && qs.parse(props.location.search) || {}

    let { page, sort, start, rows, q } = sq

    sort = sort || query.sort || "title ASC"

    if (!start) {
        start = 0
    }

    rows = rows || query.rows || 20

    if (page) {
        start = rows * (page-1)
    }

    if (query.q && q) {
        q = query.q + " " + q + "*";
    } else if (q) {
        q = q + "*"
    } else if (query.q) {
        q = query.q
    }

    let fl

    if (query.fl) {
        fl = query.fl
    } else {
        fl = "uniqueId,source,sourceId,documentType,mediaType,imageUrl,title,description,status,mediaWidth,mediaHeight"
    }

    // set id

    let id = query.id || props.location && props.location.pathname
    
    if (q) {
        id = id + "?q=" + q
    }

    console.log('query',query)

    query = {
        ...query,
        id: id,
        page: page,
        start: start,
        rows: rows,
        sort: sort,
        q: q || "",
        fl: fl
    }

    const getSearch = () => {

        if (!query) {
            return false
        }

        props.getSearch(query);
    }

    useEffect(() => {
        getSearch()
    }, [id, q, start, rows, page, sort])

    const results = searchById && searchById[id] || props.defaultResults;

    // pages
    
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)

    const getPages = () => {
        const resultsPages = Math.ceil(results.count/results.rows)
        const resultsPage = Math.ceil((results.start+results.rows)/results.rows)

        setPages(resultsPages)
        setCurrentPage(resultsPage)
    }

    useEffect(() => {
        results && results.count && getPages()
    }, [results])

    // handlePage

    const handlePage = (page) => {

        const pageQuery = qs.stringify({
            ...sq,   
            page: page
        })

        const url = props.location.pathname + "?" + pageQuery;
        
        props.history.replace(url)

    }

    // children

    if (children) {
        return React.cloneElement(children, { ...props, ...results, query: query, pages: pages, page:currentPage, onPage:handlePage })
    }


    // results

    return (
        <FinderResults {...props} {...results} query={query} pages={pages} page={currentPage} onPage={handlePage}/>
    )

}

FinderQuery.defaultProps = {
    query: {
    }
}

const mapStateToProps = (state) => {
	return {
        searchById: state.searchById
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getSearch
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinderQuery);