import React, { Component } from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    iconButton: {
        color: "inherit",
    },
    icon: {
        fontSize: "18px",
        "& + $label": {
            marginLeft: theme.spacing(.5)
        }
    },
    button: {
        padding: theme.spacing(1),
        borderRadius: 0,
        minWidth: 0
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: "1",
        textTransform: "uppercase",
    },
}));

const ButtonAction = ({ name, value, icon, label, options, disabled, color, onClick, ...props }) => {
    const classes = useStyles();

    if (icon && label) {
        return (
            <Button className={classes.button} name={name} value={value} disabled={disabled} color={color} onClick={onClick}>
                <Icon className={classes.icon}>{icon}</Icon>
                <b className={classes.label}>{label}</b>
            </Button>
        )
    }

    if (icon) {
        return (
            <IconButton className={classes.iconButton} name={name} value={value} disabled={disabled} color={color} onClick={onClick}>
                <Icon className={classes.icon}>{icon}</Icon>
            </IconButton>
        )
    }

    return (
        <Button className={classes.button} name={name} value={value} disabled={disabled} color={color} onClick={onClick}>
            <b className={classes.label}>{label}</b>
        </Button>
    )
} 

export default ButtonAction