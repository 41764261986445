import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import PreviewLabel from "./PreviewLabel"

// Class

class PreviewString extends React.Component {

  render() {
      
    const { formData, className } = this.props;
    
    return (
        
        <div className={className}>
            <PreviewLabel {...this.props} />
            <ReactMarkdown className="field__string" source={ formData } />
        </div>
    
    )
  
  }
  
}

export default PreviewString;