import { API, LOCALHOST } from '../settings';
import _ from "lodash";
import { 
  requestUser, receiveUser, 
  receiveUserFavourites,
  receiveUserLocale,
  requestUserRoles, receiveUserRoles,
  requestUserSites, receiveUserSites,
  requestUserCollections, receiveUserCollections,
  requestUserApps, receiveUserApps,
  requestAppsBySite, receiveAppsBySite,
} from '../actions/user';

import { getAppData, getAppLayout } from '../functions/app';
import { getSiteData } from '../functions/site';
import qs from 'query-string';

import appList from "../apps/"
import mediaAudio from '../../AppAdmin/schemas/collection/menu/mediaAudio';

export function getApp(props) {
    const { user, schemas, layout } = props;
    
    const app = {
        root: "/",
        name: "user",
        header: {
            title: "Dashboard",
            subtitle: user.name || "N/A"
        },
        theme: {
            primaryColor: "#eee"
        },
        schemas: schemas
    }

    return function (dispatch) {
        dispatch(getAppData(app))
    }
    
}

// LOGOUT

export function logout() {

    const url = API + '/admin/api/logout';

    return function (dispatch) {
        fetch(url, {
            method: "GET",
            headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(logoutUser(data)),
        )

    }

}

export function logoutUser(data) {
    return function (dispatch) {
        dispatch(requestUser())
    }
}


// LOGIN

export function login(uniqueId) {
  
    let url;

    uniqueId = LOCALHOST && uniqueId || LOCALHOST && "c6dcd8c7-f7be-46b1-8f35-14f7c65dc2f1" // Debug, remove
//    uniqueId = LOCALHOST && uniqueId || LOCALHOST && "d405449b-4339-4f8b-84cf-92a4c43e2af0" // Debug, remove

    if (uniqueId) {
        url = API + '/admin/api/users/' + uniqueId;
    } else {
//        url = API + '/admin/api/login';
        url = API + '/admin/api/user';
    }
  
    return function (dispatch) {
        dispatch(requestUser())

        fetch(url, {
            method: "GET",
            headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(loginUser(data)),
        )
    }

}

export function loginUser(data) {
    return function (dispatch) {
        if (data && data.uniqueId) {
            dispatch(loginSuccess(data))
        } else {
            dispatch(loginError(data))
        }
    }
}

export function loginError(data) {
    return function (dispatch) {
        dispatch(requestUser())
    }
}

export function loginSuccess(data) {

    return function (dispatch) {
        dispatch(receiveUser(data))
        dispatch(getUserApps(data))
        dispatch(getUserSites(data))
    }
  
}

/*
export function getUserData(data) {
    const { uniqueId, createdAt, updatedAt, name, authUsername, content } = data;

    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

    let defaultLocale = "en";

    if (content && content.defaultLocale) {
        defaultLocale = content.defaultLocale
    }

    let imageUrl;

    if (content && content.avatar && content.avatar.media && content.avatar.media.imageUrl) {
        imageUrl = content.avatar.media.imageUrl;
    }
  
    const user = {
        ...data,
        uniqueId: uniqueId,
        imageUrl: imageUrl,
        defaultLocale: defaultLocale,
        createdAt: createdAt,
        updatedAt: updatedAt,
        name: name,
        email: authUsername,
        initials: initials
    }

    return function (dispatch) {
        dispatch(getUserRoles(data))
        dispatch(getUserSites(data))
        dispatch(getUserApps(data))
    }

}
*/

export function setUserLanguage(language) {
    return function (dispatch) {
        dispatch(receiveUserLocale(language))
    }
}

export function setUserFavourites(favourites) {
    return function (dispatch) {
        dispatch(receiveUserFavourites(favourites))
    }
}

export function getUserApps(user) {
    const { isSystemAdmin } = user;

    let apps = [
        {
            ...appList['dashboard'],
            url: '/',
        },
    ];
  
    if (isSystemAdmin) {
        apps.push(
            {
                ...appList['user/inbox'],
                url: '/inbox',
            },
            {
                ...appList['user/calendar'],
                url: '/calendar',
            },
            {
                ...appList['user/media'],
                url: '/media',
            },
            {
                ...appList['user/analytics'],
                url: '/analytics',
            }
        )
    }

    apps.push(        {
        ...appList['user/profile'],
        url: '/profile'
    })

    if (isSystemAdmin) {
        apps.push(
            {
                ...appList['admin'],
                url: '/admin'
            }
        )
    }    

    return function (dispatch) {
        dispatch(requestUserApps())
        dispatch(receiveUserApps(apps))
    }
  
}

export function getUserRoles(user) {
  let url = API + '/admin/api/roles';
  
  return function (dispatch) {
    
    dispatch(requestUserRoles());

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
      },
    })
    .then(
      response => response.json(),
      error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(receiveUserRoles(data.models))
    )

  }
  
}

export function getUserSites(user) {
  const { authorizedSites } = user;

  let url = API + '/admin/api/sites/search?status=publish&sort=name';
  
  return function (dispatch) {
    
    dispatch(requestUserSites());
    dispatch(requestAppsBySite());

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
      },
    })
    .then(
      response => response.json(),
      error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(getUserCollections(user, data.models))
    )

  }
  
}

export function getUserCollections(user, sites) {

  let url = API + '/admin/api/collections/search?status=publish&sort=name';
  
  return function (dispatch) {

    dispatch(requestUserCollections())
    dispatch(receiveUserSites(sites))

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
      },
    })
    .then(
      response => response.json(),
      error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(getAppsBySite(user, sites, data.models))
    )

  }

}

function getSiteApp(site, appId = "site/dashboard") {

    let app = {
        ...app,
        ...appList[appId]
    }    

    if (appId === "site/dashboard") {
        app.url = "/" + site.name;
        app.icon = site.icon
        
    } else {
        app.url = "/" + site.name + "/" + app.name;
    }

    return app    
    
}

export function getAppsBySite(user, sites, collections) {

  let appsBySite = {};

  user.authorizedSites.forEach(authSite => {
      
    const { role } = authSite;
    
    const filteredSites = sites.filter(filterSite => {
        return filterSite.uniqueId === authSite.site.uniqueId
    })
    
    const site = getSiteData(filteredSites[0]);
    
    const { id, uniqueId, title, name, content } = site;

    const siteDashboard = getSiteApp(site, "site/dashboard")
    const siteSettings = getSiteApp(site, "site/settings")

    // add site
    
    appsBySite[id] = {
        ...site,
      uniqueId: uniqueId,
      siteId: id,
      siteName: name,
      title: title || name,
      role: role,
      appDashboard: siteDashboard,
      appSettings: siteSettings,
      apps: [],
      collections: []
    }
      
  })
  
  collections.forEach(collection => {
    const { siteId, collectionType, name, content } = collection;
    
    if (appsBySite[siteId]) {

        const { siteName } = appsBySite[siteId];
      
        let imageUrl;

        const media = content && content.icon && content.icon.media && content.icon.media
    
        if (media && media.mimeType === "image/svg+xml") {
            imageUrl = media.mediaUrl + "?mediaType=image/svg";
        } else if (media && media.imageUrl) {
            imageUrl = media.imageUrl;
        }

        let app = {
            ...collection,
            sortOrder: 100
        };

        if (appList[collectionType]) {
            app = {
                ...app,
                ...appList[collectionType],
            }
        }

        app.url = "/" + siteName + "/" + app.name;

        appsBySite[siteId].collections.push({
            ...app,
            imageUrl
        });
      
        }
    
    })
  
  let apps = [];

  Object.keys(appsBySite).map(function(key) {

    let { collections, appDashboard, appSettings } = appsBySite[key]

    if (appDashboard) {
        appsBySite[key].apps.push(appDashboard)
    }
    
    // get apps by type

    if (collections.length) {

        collections = _.orderBy(collections, 'sortOrder', 'asc');

        
        collections.forEach(app => {
            if (app.url) {
                appsBySite[key].apps.push(app)
            }
        });
          
    }

    if (appSettings) {
        appsBySite[key].apps.push(appSettings)
    }

    // push to apps
    
    if (appsBySite[key].apps.length) {
      apps.push(appsBySite[key]);
    }

    
  });
  
  return function (dispatch) {
    dispatch(receiveUserCollections(collections))
    dispatch(receiveAppsBySite(apps))
  }  
  
}