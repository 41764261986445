import React from 'react';
import PropTypes from "prop-types"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

//import Dropzone from 'react-dropzone';
import UploadDropzone from '../Upload/UploadDropzone';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 0,
        margin: props => { return theme.spacing(-1 * props.spacing/2) }
    },
    group: {
        flexDirection: props => { return props.flexDirection },
        alignItems: props => { return props.alignItems }
    },
    button: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "none",
        letterSpacing: 0,
        borderRadius: 1,
        minWidth: theme.spacing(12),
        minHeight: theme.spacing(5),
        margin: props => { return theme.spacing(props.spacing/2) },

        "&[data-size=large]": {
            minHeight: theme.spacing(7),
        },

        "&[data-variant=text]": {
            fontWeight: "normal"
        }

    },
    icon: {
        marginRight: theme.spacing(1),
        "& + $label": {
            marginRight: theme.spacing(1)
        }
    },
    label: {

    }
}));

const UploadButton = ({accept, multiple, onUpload, children}) => {

    return (
        <UploadDropzone
            multiple={multiple}
            accept={accept}
            onUpload={onUpload}>
                {children}
        </UploadDropzone>
    )

}

const DefaultButton = ({icon, label, title, color="primary", spacing, disableElevation=true, variant="outlined", size="large", ...props}) => {
    const classes = useStyles({spacing})
    
    return (
        <Button {...props} type="button" data-size={size} data-variant={variant} disableElevation={disableElevation} color={color} variant={variant} size={size} className={classes.button}>
            { icon && <Icon className={classes.icon}>{icon}</Icon>}
            <b className={classes.label}>{label || title }</b>
        </Button>
    )

}


const LayoutButtons = ({className, buttonGroupProps, buttonTemplate, buttonProps, buttons = [], ...props}) => {

    const spacing = buttonGroupProps.spacing || props.spacing;
    const flexDirection = buttonGroupProps.flexDirection || props.flexDirection;
    const alignItems = buttonGroupProps.alignItems || props.alignItems;

    buttonGroupProps = {
        ...buttonGroupProps,
        spacing: spacing,
        fullWidth: props.fullWidth || buttonGroupProps.fullWidth
    }

    buttonProps = {
        ...buttonProps,
        spacing: spacing,
        variant: props.variant ||  buttonProps.variant || buttonGroupProps.variant,
        size: props.size || buttonProps.size || buttonGroupProps.size,
    }

    const classes = useStyles({spacing, flexDirection, alignItems})

    if (!buttons.length) {
        return null
    }

    let ButtonTemplate;

    if (buttonTemplate) {
        ButtonTemplate = buttonTemplate;
    } else {
        ButtonTemplate = DefaultButton;
    }

    return (
        <div className={className || classes.root}>
            <ButtonGroup {...buttonGroupProps} color="primary" className={classes.group}>
                { buttons && buttons.map(({onClick, onUpload, ...button}, index) => {
                    if (onUpload) {
                        return (
                            <UploadButton {...button} template={buttonTemplate} onUpload={onUpload} key={index}>
                                <ButtonTemplate {...buttonProps} {...button} template={buttonTemplate} key={index} />
                            </UploadButton>
                        )
                    }
                    return (
                        <ButtonTemplate {...buttonProps} {...button} template={buttonTemplate} onClick={onClick} key={index} />
                    )
                })}
            </ButtonGroup>
        </div>
    )
    
}

LayoutButtons.defaultProps = {
    buttonGroupProps: {
        "fullWidth": false,
        "variant": "outlined",
        "size": "large",
        "spacing": 1,
    },
    buttonProps: {
        "variant": "outlined",
        "size": "large",
        "spacing": 1,
    }
}

LayoutButtons.propTypes = {
    buttons: PropTypes.array,
    buttonGroupProps: PropTypes.object,
    buttonProps: PropTypes.object
}

export default LayoutButtons;