import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Typography from "@material-ui/core/Typography";
import data from "./License.data.json"


import LicenseIcon from "./LicenseIcon"

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
        "& .MuiInputBase-root": {
            fontFamily: "Akkurat, sans-serif",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(1),
        },
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
        "& .MuiAutocomplete-inputRoot[class*=MuiFilledInput-root] .MuiAutocomplete-input": {
            padding: theme.spacing(.25, .5)
        },
        "& .MuiAutocomplete-tag": {
            margin: theme.spacing(.25)
        }
    },
    option: {
        display: "flex",
        alignItems: "center",
        "& > * + *": {
            marginLeft: theme.spacing(1)
        }
    },
    icon: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        "& > * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
    text: {
        fontFamily: "Akkurat, sans-serif",
        lineHeight: 1,
        "& > * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
    label: {
        color: theme.palette.text.primary
    },
    value: {
        fontFamily: "Akkurat, sans-serif",
        color: theme.palette.text.secondary
    }
}));

const SelectLicenseFieldOption = ({ value, label, system }) => {
    const classes = useStyles()
   
    return (
        <div className={classes.option}>

            <LicenseIcon className={classes.icon} license={value} />

            <Typography className={classes.text}>
                <b className={classes.label}>{label}</b>
                <i className={classes.value}>({value})</i>
            </Typography>
        </div>
    )
}

const SelectLicense = ({
    id,
    label = "Select icon",
    helperText,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    placeholder
}) => {

    const { t, i18n } = useTranslation();

    const getOptions = () => {
        const options = data.map(option => {
            const { group, value } = option;
    
            return {
                ...option,
                group: group && t("licenseGroup:"+group)
            }
        })

        options.sort((a, b) => (a.groupId > b.groupId) ? 1 : -1)
        

        return options
    }

    const emptyValue = multiple ? [] : "";
    const options = getOptions()

    const handleChange = (event, option) => {
        onChange && onChange(option && option.value || emptyValue, event);
    }

    const renderGroup = (option) => {
        return (
            <p>{JSON.stringify(option.group)}</p>
        )
    }

    const renderOption = (option, state) => {
        return (
            <SelectLicenseFieldOption {...option} />
        )
    }

    let selected = value && options.filter(option => option.value == value)

    if (!multiple && selected.length) {
        selected = selected[0]
    }

    const classes = useStyles()

    return (
        <Autocomplete
            className={classes.root}
            id={id}
            placeholder={placeholder}
            multiple={multiple}
            options={options}
            groupBy={(option) => option.group}
            label={label}
            helperText={helperText}
            value={selected}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            groupBy={(option) => option.group}
//            getOptionSelected={(option, value) => option.value === value}
            getOptionLabel={option => options && option.label || ""}
//            renderGroup={renderGroup}
            renderOption={renderOption}
            renderInput={(params) => (
                <TextField {...params} className={classes.input} variant="filled" label={label} placeholder={placeholder} helperText={helperText} />
            )}
            onChange={handleChange}>
        </Autocomplete> 
    )

}

SelectLicense.defaultProps = {
    autofocus: false,
};

SelectLicense.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default SelectLicense;
