import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// user apps

import AppLogin from "./AppLogin"
import AppLayout from "./AppLayout"
import AppLoader from "./AppLoader"

import Admin from '../AppAdmin/'
import User from "../AppUser/"
import Inbox from "../AppInbox/"
import Calendar from "../AppCalendar/"
import Forum from "../AppForum/"
import Media from '../AppMedia/'
import Analytics from '../AppAnalytics/'

import Dialog from '../AppDialog/'


// site apps

import Site from "../AppSite/"

const AppRoutes = ({user, ...props}) => {
    const { i18n } = useTranslation();

    const defaultLocale = user && user.content && user.content.defaultLocale;

    useEffect(() => {
        i18n.changeLanguage(defaultLocale);
    }, [defaultLocale])

    if (!user.uniqueId) {
        return (
            <AppLogin {...props} />
        )
    }

    return (
        <Switch>
            <Route path="/login" component={ AppLogin } />
            <Route path="/admin" component={ Admin } />
            <Route path="/forum" component={ Forum } />
            <Route path="/calendar" component={ Calendar } />
            <Route path="/inbox" component={ Inbox } />
            <Route path="/analytics" component={ Analytics } />
            <Route path="/media" component={ Media } />
            <Route path="/dialog" component={ Dialog } />
            <Route path="/profile" component={ User } />
            <Route path="/:siteName" component={ Site } />
            <Route path="/" component={ User } />
        </Switch>
    )

}

const mapStateToProps = (state) => {
	return {
		"user": state.user,
        "site": state.site,
        "app": state.app,
        "nav": state.nav
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppRoutes);