import React from 'react'
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavAction from "../NavAction/NavAction"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "inherit",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexDirection: "column"
    },
    header: {
        flexGrow: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        maxWidth: "100%",
        width: "100%",
        minHeight: theme.spacing(8),
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        color: "inherit",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    closeButton: {
        margin: theme.spacing(1)
    },
    body: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: props => { return theme.spacing(props.padding) },
        "* + *": {
            marginTop: props => { return theme.spacing(props.spacing) }
        }
    },
    footer: {
        flexGrow: 0,
        display: "flex",
        margin: theme.spacing(2),

        "& > * + *": {
            marginLeft: theme.spacing(2)
        }

    },
    preview: {
        minHeight: theme.spacing(32),
        position: "relative",
        margin: theme.spacing(1)
    },
    content: {
        flexBasis: 0,
        flexGrow: 1
    }
}));

const CloseButton = ({className, onClick}) => {

    return (
        <IconButton className={className} onClick={onClick}>
            <CloseIcon />
        </IconButton>
    )
}

const BulkEditor = ({preview, title, primaryAction, secondaryAction, padding = 2, spacing = 2, children, onClose, ...props}) => {
    const classes = useStyles({padding, spacing})

    const renderPreview = () => {
        const PreviewTemplate = preview && preview.template;

        if (!PreviewTemplate) {
            return false
        }

        return (
            <div className={classes.preview}>
                { PreviewTemplate && <PreviewTemplate {...preview} /> || JSON.stringify(preview) }
            </div>
        )

    }  

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <Typography className={classes.title} noWrap>{title}</Typography>
                <CloseButton className={classes.closeButton} onClick={onClose} />
            </header>
            <div className={classes.body}>
                { renderPreview() }
                <div className={classes.content}>
                    {children}
                </div>
            </div>
            <footer className={classes.footer}>
                <NavAction primaryAction={primaryAction} />
                { secondaryAction && <NavAction primaryAction={secondaryAction} /> }
            </footer>
        </div>
    )
}

export default BulkEditor;