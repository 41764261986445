import { getAppData } from '../../redux/functions/app';
import { getSiteCollection, getUserCollection } from '../../redux/functions/appCollection';

//import schemas from "../schemas"

export function getApp(props) {
    const { user } = props;
    
    const title = "Media"
    const name = "media"
    const root = "/media"

    props = {
        ...props,
        root: root,
        name: name,
        title: title
    }

    const search = {
        "placeholder": "Søk i media"
    }

    const primaryAction = {
        label: "Upload",
        template: "upload",
        url: root + "/upload"
    }

    const menu = getAppMenu(props)

    const theme = {
        "palette": {
            "type": "dark",
            "primary": {
                "main": "#8aa7f7"
            }
        }
    }
    
    const app = {
        ...props,
        layout: "finder",
        theme: theme,
        header: {
            title: title,
            subtitle: user.name || "N/A"
        },
        search: search,
        primaryAction: primaryAction,
        menu: menu,
    }

    return function(dispatch) {
        dispatch(getAppData(app))
        dispatch(getUserCollection({...props, app: app}))
    }
    
}

export function getAppMenu(props) {
    
    const frontpage = getMenuHome(props)
    const byOwner = getMenuBySite(props)
    const byType = getMenuByType(props)
    const bySocial = getMenuBySocial(props)

    return [
        frontpage,
        byType,
        bySocial,
        byOwner
    ]
}

export function getMenuHome(props) {
    const { root } = props;

    return {
        title: "Mediaserver",
        icon: "storage",
        url: root,
        query: {
            models: "media",
            mediaType: "image",
            status: "NOT trash",
            rows: 5
        },
    }

}

export function getMenuBySocial(props) {
    const { root } = props;

    return {
        title: "API",
        icon: "cloud",
        url: root + "/social",
        query: {
            models: "social",
        },
        children: [
            {
                title: "YouTube",
                url: root + "/social/youtube",
                query: {
                    models: "youtube",
                }
            },
            {
                title: "Vimeo",
                url: root + "/social/vimeo",
                query: {
                    models: "vimeo",
                }
            },
            {
                title: "Sketchfab",
                url: root + "/social/sketchfab",
                query: {
                    models: "vimeo",
                }
            }
        ]
      }

}

export function getMenuByType(props) {
    const { root } = props;

    return {
        title: "Media",
        icon: "cloud",
        url: root + "/dms",
        query: {
            models: "media",
            status: "NOT trash"
        },
        children: [
            {
                title: "Bilder",
                url: root + "/dms/image",
                query: {
                    models: "media",
                    mediaType: "image",
                    status: "NOT trash"
                }
            },
            {
                title: "Video",
                url: root + "/dms/video",
                query: {
                    models: "media",
                    mediaType: "video",
                    status: "NOT trash"
                }
            },
            {
                title: "Audio",
                url: root + "/dms/audio",
                query: {
                    models: "media",
                    mediaType: "audio",
                    status: "NOT trash"
                }
            }
        ]
      }

}

export function getMenuBySite(props) {
    const { user, root } = props;
    const { appsBySite } = user;
    
    let menu = []
    
    if (!appsBySite) {
        return menu
    }

    appsBySite.map((site) => {
        
        let siteMenu = {
            title: site.title,
            url: root + "/site/" + site.id,
            query: {
                models: "media",
                siteId: site.id,
                status: "NOT trash"
            }
        }
        
        let children = [
            {
                title: "Bilder",
                url: root + "/site/image",
                query: {
                    models: "media",
                    siteId: site.id,
                    mediaType: "image",
                    status: "NOT trash"
                }
            },
            {
                title: "Video",
                url: root + "/site/video",
                query: {
                    models: "media",
                    siteId: site.id,
                    mediaType: "video",
                    status: "NOT trash"
                }
            },
            {
                title: "Audio",
                url: root + "/site/audio",
                query: {
                    models: "media",
                    siteId: site.id,
                    mediaType: "audio",
                    status: "NOT trash"
                }
            }            
        ]

        children = []
        
        site.apps.map((app) => {
            const { name, appType } = app;
            
            if (!app.id) {
                return
            }
            
            children.push({
                title: app.title,
                url: siteMenu.url + "/app/" + app.id,
                query: {
                    models: "media",
                    siteId: site.id,
                    collectionId: app.id,
                    status: "NOT trash"
                }
            })
            
        })
        
        menu.push({
            ...siteMenu,
            children: children,
            count: children.length
        })
        
    })

    return {
        children: menu
    }
    
}