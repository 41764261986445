import React from 'react';
import NavAction from "../NavAction/NavAction"
import NavMenu from "../NavMenu/NavMenu"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: "#eee",
        zIndex: 1,
        top: 0,
        right: "auto",
        left: 0,
        bottom: 0,
        width: props => { return props.width },
        marginLeft: props => { return "-" + props.width },
        transition: ".125s ease-out",
        "&[aria-expanded=true]":  {
            marginLeft: 0,
        },
        "&[aria-expanded=true] + *":  {
            marginLeft: props => { return props.width },
        }
    },
    action: {
        position: "relative",
        zIndex: 2,
        margin: "24px"
    },
    menu: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        paddingTop: "84px",
        marginRight: "16px",
        marginLeft: "0",
        overflowY: "scroll"	
    }
}));

const AppSidebar = ({expanded = false, width = "224px", action, menu, menuByUrl, onSelect, children}) => {

    const classes = useStyles({width})

    return (
        <aside className={classes.root} aria-expanded={expanded}>
            <NavAction className={classes.action} action={action} onSelect={onSelect} />
            <NavMenu className={classes.menu} menu={menu} menuByUrl={menuByUrl} onSelect={onSelect} />
        </aside>
    )

}

export default AppSidebar;