import React, { Component } from 'react';
import { WidgetTitle, WidgetDescription, WidgetSpinner } from '../';

import "./WidgetLoading.scss"

class WidgetLoading extends Component {
    
    static defaultProps = {
        title: undefined,
        description: undefined
    }
    
    render() {
        
        const { title, description } = this.props;
        
        if (title && description) {

            return (
                <div className="admin-widget__loading">
                    <WidgetSpinner {...this.props} />
                    <p>{title}</p>
                </div>
            )
            
        } else {

            return (
                <div className="admin-widget__loading">
                    <WidgetSpinner {...this.props} />
                    <p>{title}</p>
                </div>
            )
            
        }
        
    }
}

export default WidgetLoading;