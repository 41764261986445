import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionButton, ActionMenu } from "@frontend-components/admin"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: "white",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        padding: theme.spacing(2)
    },
}));

const PreviewCollectionEdit = ({app}) => {

    const classes = useStyles()

    const primaryAction = app.primaryAction
    const menu = primaryAction && primaryAction.children

    return (
        <div className={classes.root}>
            <ActionButton {...primaryAction} />
            <ActionMenu menu={menu} />
        </div>
    )
}

function mapStateToProps(state) {
	return {
        formData: state.editor.formData,
        formContext: state.editor.formContext,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewCollectionEdit);
