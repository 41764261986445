export default {
    "type": "string",
    "enum": [
        "text",
        "media",
        "ekultur"
    ],
    "enumNames": [
        "Tekst",
        "Media",
        "Ekultur"
    ],
    "default" : "content"
}