export default {
    "type": "array",
    "items": [
        {
            "type": "object",
            "properties": {
                "dtStart": {
                    "type": "string",
                    "format": "datetime"
                },
                "dtEnd": {
                    "type": "string",
                    "format": "datetime"
                },
                "placeId": {
                    "type": "string",
                },
                "programId": {
                    "type": "string"
                },
                "personId": {
                    "type": "string"
                },
                "comments": {
                    "type": "string",
                }
            }
        }
    ]
}