import media from './media';
import mediaImage from './mediaImage';
import mediaVideo from './mediaVideo';
import mediaAudio from './mediaAudio';
import mediaUpload from './mediaUpload';

export default {
    "type": "array",
    "items": {
        "type": "object",
        "properties": {
            "title": {
                "type": "string",
            },
            "pathname": {
                "type": "string",
            },
            "type": {
                "type": "string",
                "enum": ["media","media/image","media/video","media/audio","media/upload"],
                "default": "media"
            }
        },
        "dependencies": {
            "type" : {
                "oneOf": [
                    media,
                    mediaImage,
                    mediaVideo,
                    mediaAudio,
                    mediaUpload
                ]
            }
        }
    }
}    