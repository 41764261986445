import sectionText from './schemaSectionText';
import sectionMedia from './schemaSectionMedia';
import sectionObjects from './schemaSectionObjects';

import sectionContent from './schemaSectionContent';
import sectionReferences from './schemaSectionReferences';
  
export default {
    "type": "object",
    "properties": {
        "sectionType" : {
            "type": "string",
            "enum": [
                "text",
                "media",
                "objects"
            ],
            "enumNames": [
                "Tekst",
                "Media",
                "Objekter"
            ],
            "default" : "content"
        },
        "title": {
            "type" : "localizedString"
        },
        "titleHidden": {
            "type" : "boolean"
        }
    },
    "dependencies": {
        "sectionType" : {
            "oneOf": [
                sectionText,
                sectionMedia,
                sectionObjects
            ]
        }
    }
}