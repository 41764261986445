import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        "&[data-loading=true]": {
            pointerEvents: "none",
        }
     },
    loader: {
        position: "fixed",
        zIndex: 2000,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "white",
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0,
        transition: ".5s ease-out",
        pointerEvents: "none",
        "[data-loading=true] &": {
            opacity: 1
        }
    },
    header: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& * + *": {
            margin: theme.spacing(1)
        },
        fontFamily: "Akkurat, sans-serif",
        fontSize: "12px",
        color: theme.palette.text.secondary
    },
    siteTitle: {
    },
    appTitle: {
    }

}));

const AppLoader = ({loading = false, app, site, user, children, ...props}) => {
    const classes = useStyles()

    return (
        <div data-loading={loading}>
            {children}
            <div className={classes.loader}>
                <p>{app.icon}</p>
                <header className={classes.header}>
                    <h2 className={classes.appTitle}>{app && app.title}</h2>
                </header>
            </div>
        </div>
    )

}

export default AppLoader

