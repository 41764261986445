export default {
  "type": "string",
  "enum": [
    "movie",
    "tv",
    "music"
  ],
  "enumNames": [
    "Film",
    "TV-program",
    "Musikkvideo"
  ],
  "default": "movie"
}