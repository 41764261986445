import React, { Component } from 'react';

import "./FieldDescription.scss";

class FieldDescription extends Component {
    render() {
        const { description } = this.props;

        if (!description) {
            return false;
        }

        return (
            <p className="field__description">{description}</p>
        )
    }
}

export default FieldDescription;