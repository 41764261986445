import React, { Component } from 'react';
import "./FieldSwitch.scss"

class FieldSwitch extends Component {
    static defaultProps = {
        value: "",
        required: false,
        disabled: false,
        readonly: false,
        autofocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    }

    onChange = (event) => {
        
        let value;
        
        if (event.target.checked) {
            value = this.props.value || true;
        } else {
            value = false
        }
        
        this.setState({
            value: value
        });

        if (this.props.onChange) {
            this.props.onChange(value, event);
        }
    }

    render() {
        const { id, name, value, checked, required, disabled, readonly, autofocus } = this.props;

        const label = this.props.title || this.props.label || "bool"

        return (
            
            <label className="admin-field  field--switch">
            
                <input type="checkbox" id={id} 
                    name={name}
                    value={value}
                    checked={checked}
                    required={required}
                    disabled={disabled || readonly}
                    autoFocus={autofocus}
                    onChange={(event) => this.onChange(event)}
                    onBlur={(event) => this.onBlur(event)}
                    onFocus={(event) => this.onFocus(event)}
                />
                
                <i className="switch__slider"></i>
                <b className="switch__label">{ label }</b>

            </label>
        )
    }
}

export default FieldSwitch;