import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { CalendarWidget } from '@frontend-components/admin';

class WidgetUserCalendar extends Component {
    
    getEvents = () => {
        
        return [
            {
                color: "green",
                title: "Fullføre alt"
            },
            {
                color: "green",
                title: "Ekultur møte"
            },
            {
                color: "green",
                title: "Snart ferie"
            },
            {
                color: "blue",
                title: "Endelig ferie"
            },
            {
                color: "red",
                title: "Nå er det vel ferie?"
            }
        ]
        
    }

    render() {
        
        const events = this.getEvents()
    
        return (
            <p>Kalendeer</p>
        )
    
    }

}

function mapStateToProps(state) {
	return {
      	user: state.user
	};
}  

export default connect(
  mapStateToProps,
)(WidgetUserCalendar);