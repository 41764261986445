import { getAppData } from '../../redux/functions/app';
    
export function getApp(props) {
    const { schemas } = props;
    
    const app = {
        name: "user",
        title: "Dashboard",
        layout: "dashboard",
        theme: {
//            primaryColor: "#fff"
        },
        search: false,
        schemas: schemas,
    }

    return function (dispatch) {
        dispatch(getAppData(app))
    }
    
}