export default {
    "ui:layout": "section",
    "ui:title": "Servering",
    "ui:options": {
        "collapsible": true,
        "grid": true,
        "spacing": 2
    },
    "products": {
        "ui:spacing": 2,
        "ui:preview": {
            "select": {
                "title": "title"
            },
            prepare({formData}) {
                const count = formData.length;

                if (count > 1) {
                    return count + " produkter"
                } else if (count) {
                    return "1 produkt"
                }
                
                return "Ingen produkter"
            }
        },
        "ui:buttons": [
            {
                "icon": "add",
                "label": "Legg til produkt"
            },
            {
                "icon": "add",
                "label": "Coca",
                "item": {
                    "id": 1
                }
            },
            {
                "icon": "add",
                "label": "Pepsi",
                "item": {
                    "id": 2
                }
            }
        ],
        "items": {
            "ui:layout": "fieldset",
            "ui:grid": true,
            "ui:spacing": 2,
            "id": {
                "ui:title": "Produkt",
                "ui:gridCols": 4
            },
            "count": {
                "ui:title": "Antall",
                "ui:gridCols": 2
            }
        }
    },
    "comments": {
        "ui:title": "Kommentarer",
        "ui:widget": "textarea"
    }

}