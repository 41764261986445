import React, { Component } from 'react';
import "./WidgetSpinner.scss"

class WidgetSpinner extends Component {
    
    getStyle = () => {
        let { theme, style } = this.props;
        
        if (style && style.color) {
            return {
                backgroundColor: style.color
            }
        }
        
        if (theme && theme.primaryColor) {
            return {
                backgroundColor: theme.primaryColor
            }
        }
        
        return {}
        
    }
    
    render() {
        const style = this.getStyle()
        
        return (
            <div className="admin-widget__spinner">
              <div className="anim-1" style={style}></div>
              <div className="anim-2" style={style}></div>
              <div className="anim-3" style={style}></div>
            </div>            
        )
        
    }
}

export default WidgetSpinner;