import React from 'react';
import Button from './Button';

import './ButtonEdit.scss';

class ButtonView extends React.Component {
    render() {
        const { label, icon, type, link, onClick } = this.props;

        let classNames = "admin-button  admin-button--edit";

        if (type) {
            classNames = classNames + "  admin-button--edit-" + type;
        }

        return (
            <Button classNames={classNames} link={link} onClick={onClick} icon={icon} label={label} />
        );
    }
}

export default ButtonView;
