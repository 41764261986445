import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MediaFormat from "./MediaFormat"
import AudioControls from "./AudioControls"

const useStyles = makeStyles(theme => ({

    player: {
        backgroundColor: "white",

        display: "flex",
        width: "100%",
        margin: "auto",

        maxWidth: props => { return props.maxWidth || "100%" },
        maxHeight: props => { return props.maxHeight || "100%" },

        "[data-position=absolute] &": {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        }

    },
    controls: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: props => { return props.maxHeight || props.minHeight },
        margin: theme.spacing(1),

        "[data-position=absolute] &": {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        }

    },
    artwork: {
        width: props => { return props.artworkSize },
        position: "relative",
        margin: theme.spacing(1),

        "[data-position=absolute] &": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 0,
            bottom: "auto",
            left: 0,
            maxWidth: theme.spacing(32),
            width: "50%",
            margin: "auto",
        }
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "block",
        width: "100%",
        height: "100%"
    },
    audio: {
        display: "none"
    }
}));

const AudioPlayer = ({className, 
    position = "relative", size = undefined, format = undefined, minHeight = undefined, maxWidth = undefined, maxHeight = undefined, 
    title, untitled = "Untitled", description, imageUrl = undefined, audioUrl = undefined, audioType = undefined, audioSources = [], resources = {}, ...props}) => {

    if (format || maxHeight) {
        position = "absolute"
    }

    if (size === "large") {
        minHeight = 32 * 8
    } else if (size === "medium") {
        minHeight = 24 * 8
    } else if (size === "small") {
        minHeight = 12 * 8
    }

    let artworkSize

    if (maxHeight) {
        artworkSize = maxHeight - 96;
    } else if (minHeight) {
        artworkSize = minHeight;
    }


    const classes = useStyles({position, format, maxWidth, maxHeight, minHeight, artworkSize})
    const playerRef = useRef(null)
    const imageRef = useRef(null)

    const [imageWidth, setImageWidth] = useState(null)
    const [imageHeight, setImageHeight] = useState(null)

    useEffect(() => {

        if (!imageRef.current) {
            return
        }

        imageRef.current.addEventListener('load', event => {
            console.log('imageEvent', event)

            const imageWidth = event.target.naturalWidth;
            const imageHeight = event.target.naturalHeight;

            setImageWidth(imageWidth)
            setImageHeight(imageHeight)


        })

    }, [imageRef.current])

    const [buffered, setBuffered] = useState(null)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [seeking, setSeeking] = useState(null)
    const [paused, setPaused] = useState(true)
    const [volume, setVolume] = useState(true)
    const [muted, setMuted] = useState(true)

    useEffect(() => {

        if (!playerRef.current) {
            return
        }

        playerRef.current.addEventListener('loadedmetadata', event => {

            const duration = event.target.duration;
    
            console.log('event', event)
    
            setBuffered(event.target.buffered)
            setDuration(event.target.duration)
            setCurrentTime(event.target.currentTime)
            setPaused(event.target.paused)
            setMuted(event.target.muted)
            setVolume(event.target.volume)

            props.onMetadata && props.onMetadata({
                duration: duration
            })
            
             
        })
        
        /*
        playerRef.current.addEventListener('canplay', event => {
            setBuffered(event.target.buffered)
            setDuration(event.target.duration)
            setCurrentTime(event.target.currentTime)
            setPaused(event.target.paused)
            setMuted(event.target.muted)
            setVolume(event.target.volume)
        })
        */

        playerRef.current.addEventListener('timeupdate', event => {
            setDuration(event.target.duration)
            setCurrentTime(event.target.currentTime)
        })

        playerRef.current.addEventListener('seeking', event => {
            setCurrentTime(event.target.currentTime)
            setSeeking(true)
        })

        playerRef.current.addEventListener('seeked', event => {
            setCurrentTime(event.target.currentTime)
            setSeeking(false)
        })

        playerRef.current.addEventListener('volumechange', event => {
            setVolume(event.target.volume)
        })

        playerRef.current.addEventListener('play', event => {
            setPaused(event.target.paused)
        })

        playerRef.current.addEventListener('pause', event => {
            setPaused(event.target.paused)
        })

        
    }, [playerRef.current])


    const handlePlayPause = () => {
        const { paused } = playerRef.current

        if (paused) {
            playerRef.current.play()
        } else {
            playerRef.current.pause()
        }

        setPaused(!paused)

    }

    const handleSeek = (value) => {
        playerRef.current.currentTime = value
    }

    const handleVolume = (value) => {
        playerRef.current.volume = value
    }

    const handleMute = () => {
        const { mute } = playerRef.current
        playerRef.current.mute = !mute
        setMuted(!mute)
    }

    if (!audioSources.length && resources) {
        Object.values(resources).map(source => {
            const { mimeType, url } = source

            if (mimeType.startsWith('audio') && url) {
                audioSources.push({
                    src: url,
                    type: mimeType
                })
            }

        })
    }

    if (!audioSources.length) {
        return <p>No audio sources</p>
    }

    return (
        <div className={classes.player} data-layout="audioPlayer">
            <AudioControls
                className={classes.controls}
                title={title}
                description={description}
                paused={paused}
                onPlayPause={handlePlayPause}
                duration={duration}
                currentTime={currentTime}
                onSeek={handleSeek}
            />
            { imageUrl &&
                <Paper component="figure" square={true} elevation={2} className={classes.artwork}>
                    <MediaFormat format="1:1">
                        <img src={imageUrl} ref={imageRef} className={classes.image} />
                    </MediaFormat>
                </Paper>
            }
            <audio ref={playerRef} className={classes.audio}>
            { audioSources.map(({src, type}) => <source src={src} type={type} />)}
            Your browser does not support the audio tag.
            </audio>
        </div>
    )

}

export default AudioPlayer