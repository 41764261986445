import links from "../../page/links/uiSchema"

export default {
    ...links,
    "items": {
        ...links.items,
        "ui:settings": [
            "grid",
            "placement",
            "backgroundColor",
            "color"
        ],
        "link": {
            ...links.items.link,
            "linkContent": {
            ...links.items.link.linkContent,
                "ui:settings": [
                    "titleSize",
                    "titleColor",
                ]
            }
        }
    }
}