import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAuthRoles, getAuthUsers } from "../redux/functions/auth"

import { getAuthSites } from "../redux/functions/authSites"
import { getAppByName, getAppById } from "../redux/functions/authApps"

import Finder from '../AppFinder';

import { FinderTabs, FinderSection } from '@frontend-components/admin';
import { getUrlFromProps } from '@frontend-components/admin/lib/resources/functions';

import AuthSectionApps from "./AuthSectionApps"
import AuthSectionSites from "./AuthSectionSites"
import AuthSectionUsers from "./AuthSectionUsers"

class AuthApp extends Component {
    
    static defaultProps = {
        urlPattern: "/admin/auth/app/:appId"
    }
    
    componentDidMount() {
        this.props.getAuthSites()
        this.props.getAuthRoles()
        this.props.getAuthUsers()
        this.getAuth()
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.match.params.appId !== this.props.match.params.appId) {
            this.getAuth()
        }

        if (prevProps.match.params.siteId !== this.props.match.params.siteId) {
            this.getAuth()
        }

    }
    
    getAuth = () => {
        const { appsById, sitesById } = this.props;
        const { appId, siteId } = this.props.match.params
        
        if (appId) {
            this.props.getAppByName(appId)
        }
        
        if (siteId) {
            this.props.getAuthUsers()
        }
        
    }
    
    getCurrentSite = (currentApp) => {
        const { sitesById, urlPattern, appsById } = this.props;
        const { siteId } = this.props.match.params;
        
        if (!siteId) {
            return false
        }

        const site = siteId && sitesById[siteId];
        const collection = siteId && currentApp && currentApp.authSitesById && currentApp.authSitesById[siteId]

        return {
            ...site,
            ...collection,
            moduleSize: "small",
            urlPattern: urlPattern
        }
        
    }
    
    getCurrentApp = () => {
        const { appsByName, urlPattern } = this.props;
        const { appId, siteId } = this.props.match.params;
        
        const app = appId && appsByName[appId];
        
        return {
            ...app,
            urlPattern: urlPattern
        }
        
    }
    
    getAppSiteUsers = (currentApp, currentSite) => {
        const { collectionId, siteId, urlPattern } = currentSite;
        const { users, usersById } = this.props;
        
        let appUsers = []
        
        Object.values(usersById).map(user => {
            const { authAppsById } = user;

            let roleId;
            
            if (authAppsById[collectionId]) {
                roleId = authAppsById[collectionId].roleId
            }
            
            user = {
                ...user,
                siteId: siteId,
                collectionId: collectionId,
                roleId: roleId,
                urlPattern: "/admin/auth/user/:userId/site/:siteId",
                moduleSize: "small"
            }
            
            if (roleId) {
                appUsers.push(user)
            }

            
        })
        
        return appUsers;
    }
    
    getAppSites = (currentApp) => {
        const { sitesById, appsById, urlPattern } = this.props;
        const { authSites } = currentApp;
        
        if (!authSites) {
            return []
        }
        
        return authSites.map(site => {
            const { siteId, collectionId } = site;

            const status = appsById[collectionId] && appsById[collectionId].status;
            
            return {
//                ...appsById[collectionId],
                ...site,
                ...sitesById[siteId],
                status: status,
                urlPattern: urlPattern + "/site/:siteId",
                moduleSize: "small"
            }
            
        })
        
    }
    
    render() {
        const currentApp = this.getCurrentApp()
        
        if (!currentApp) {
            return false
        }

        const currentSite = this.getCurrentSite(currentApp)
        
        // app + site
                
        if (currentApp && currentSite) {
            const siteUsers = this.getAppSiteUsers(currentApp, currentSite)
        
            return (
                <Finder {...this.props}>
                    <AuthSectionApps apps={[currentApp]} />
                    <AuthSectionSites sites={[currentSite]} title="Current site" />
                    <AuthSectionUsers users={siteUsers} title="Authorize users for site" />
                </Finder>
            )
            
        }
        
        // app
        
        const appSites = this.getAppSites(currentApp)

        const { urlPattern } = currentApp;
        const { tabId } = this.props.match.params;
        
        const appTab = {
            id: "app",
            url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern})
        }

        const userTab = {
            id: "user",
            url: getUrlFromProps({...this.props.match.params, urlPattern: urlPattern + "/user"})
        }

        return (
            <Finder {...this.props}>
                <AuthSectionApps apps={[currentApp]} />
                <FinderTabs tabId={tabId}>
                    <FinderSection {...appTab} title={appSites.length + " sites"}>
                        <AuthSectionSites sites={appSites} title="" />
                    </FinderSection>
                    <FinderSection {...userTab} title="0 users">
                        todo
                    </FinderSection>
                </FinderTabs>
            </Finder>
        )
        
    }

}

function mapStateToProps(state) {
	return {
        parents: state.app.parents,
    	users: state.auth.users,
    	usersById: state.auth.usersById,
    	appsByName: state.auth.appsByName,
    	appsById: state.auth.appsById,
    	sitesById: state.auth.sitesById,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getAuthRoles, getAuthUsers, getAuthSites, getAppByName, getAppById
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthApp);
