import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAppLayout } from '../redux/functions/app';
import { getAppCollection } from '../redux/functions/appCollection';
import { getApp } from './app';

import PrimusRoutes from "./PrimusRoutes"

class PrimusApp extends Component {
    
    static defaultProps = {
        appType: "primus"
    }
    
    componentDidMount() {
        this.getAppCollection();
        this.getCollectionApp();
    }
    
    componentDidUpdate = (prevProps) => {

        if (!prevProps.site.id && this.props.site.id || prevProps.site.id !== this.props.site.id) {
            this.getAppCollection();
        }

        if (prevProps.collection.id !== this.props.collection.id) {
            this.getCollectionApp();
        }

    }

    getAppCollection = () => {
        const { appType, site } = this.props;
        
        if (appType && site.id) {
            this.props.getAppCollection(this.props);
        }
        
    }

    getCollectionApp = () => {
        const { appType, site, collection } = this.props;
        
        if (collection.collectionType === appType && collection.siteId === site.id) {
            this.props.getApp(this.props);
        }
        
    }
    
    render() {
        const { app } = this.props;
        
        if (!app.uniqueId) {

            return (
                <p>Loading Primus</p>
            )

        }
    
        return (
            <PrimusRoutes {...this.props} />
        )

    }

}

function mapStateToProps(state) {
	return {
        app: state.app,
    	site: state.site,
    	collection: state.collection
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getAppCollection, getApp, getAppLayout
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrimusApp);

