import React, { Component } from 'react';
import AppsListItem from "./AppsListItem";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
    }
}));

const AppsList = ({className, apps = undefined, size, children, onSelect}) => {
    const classes = useStyles()

    return (
        <section className={className || classes.root}>
            {apps && apps.length && apps.map(app => {
                return (
                    <AppsListItem {...app} size={size} onClick={() => onSelect(app)} />
                )
            })}
            {children}
        </section>
    )

}

AppsList.defaultProps = {
    size: "small"
}

export default AppsList;