import React, { Component } from 'react';
import { ChartLine } from '../'
import moment from 'moment';

import "./ChartLineCompare.scss"

class ChartLineStacked extends Component {
    
    static defaultProps = {
        enableSlices: "x",
        yScale: {
            type: "linear",
            stacked: true
        }
    }

    render() {

        return (
            <ChartLine {...this.props} />
        )
    
    }

}

export default ChartLineStacked