export default {
    "ui:title": "Søk",
    "ui:options": {
        "grid": true,
        "padding": 2,
        "spacing": 2
    },
    "ui:fieldset": [
        "models",
        "documentType",
        "mediaType",
        "objectType",
        "createdAt",
        "updatedAt",
        "deletedAt",
        "q",
        "status",
        "sort",
        "rows"
    ],
    "documentType": {
        "ui:field": "collectionDocumentType",
        "ui:widget": "checkboxes",
        "ui:title": "Velg dokumenttype",
    },
    "mediaType": {
        "ui:widget": "checkboxes",
        "ui:title": "Velg mediatype",
    },
    "objectType": {
        "ui:widget": "checkboxes",
        "ui:title": "Velg objecttype",
    },
    "q": {

    },
    "createdAt": {
        "ui:widget": "autocomplete",
        "ui:gridCols": 4,
    },
    "updatedAt": {
        "ui:widget": "autocomplete",
        "ui:gridCols": 4,
    },
    "deletedAt": {
        "ui:widget": "autocomplete",
        "ui:gridCols": 4,
    },
    "status": {
        "ui:gridCols": 4,
    },
    "sort": {
        "ui:gridCols": 4,
    },
    "rows": {
        "ui:gridCols": 4,
    },
}