import React from "react";

export default (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path d="M46,17 L18,17 C15.78,17 14.02,18.8 14.02,21 L14,49 C14,51.2 15.78,53 18,53 L46,53 C48.2,53 50,51.2 50,49 L50,21 C50,18.8 48.2,17 46,17 Z" id="Path" fill="#CCCCCC"></path>
        <path d="M46,14 L18,14 C15.78,14 14.02,15.8 14.02,18 L14,46 C14,48.2 15.78,50 18,50 L46,50 C48.2,50 50,48.2 50,46 L50,18 C50,15.8 48.2,14 46,14 Z" id="Path" fill="#F2F2F2"></path>
        <rect id="Rectangle-15" fill="#0666B0" x="39" y="25" width="4" height="20"></rect>
        <rect id="Rectangle-15" fill="#8DA4D5" x="33" y="33" width="4" height="12"></rect>
        <rect id="Rectangle-15" fill="#8DA4D5" x="21" y="33" width="4" height="12"></rect>
        <rect id="Rectangle-15" fill="#0666B0" x="27" y="37" width="4" height="8"></rect>
        <g transform="translate(9.000000, 9.000000)">
            <path d="M7.5e-05,9 C7.5e-05,13.97025 4.029825,18 9.000075,18 C13.970325,18 18.000075,13.97025 18.000075,9 C18.000075,4.02975 13.970325,0 9.000075,0 C4.029825,0 7.5e-05,4.02975 7.5e-05,9" id="Fill-1" fill="#0666B0"></path>
            <path d="M9.000075,9 L9.000075,0 C10.242825,0 11.426325,0.252 12.503325,0.70725 C13.579575,1.1625 14.549325,1.82175 15.363825,2.63625 C16.178325,3.45075 16.837575,4.4205 17.292825,5.49675 C17.748075,6.57375 18.000075,7.75725 18.000075,9 C18.000075,10.24275 17.748075,11.42625 17.292825,12.50325 C16.837575,13.5795 16.178325,14.54925 15.363825,15.36375 L9.000075,9 Z" id="Fill-4" fill="#CCCCCC"></path>
        </g>
    </svg>
)