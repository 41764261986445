import React from 'react';
import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        color: props => { return props.color || theme.palette.text.secondary }
    }
}));

const ModuleDate = ({className, color, datetime, locale = "nb", format = "relative"}) => {
    const classes = useStyles({color})

    if (!datetime) {
        return false
    }

    // format value

    moment.locale(locale)

    let date;
    
    if (format === "relative") {
        date = moment(datetime).fromNow()
    } else if (format) {
        date = moment(datetime).format(format)
    }

    return (
        <time dateTime={datetime} className={className || classes.root}>{date}</time>
    )
}

ModuleDate.propTypes = {
    datetime: PropTypes.string,
    locale: PropTypes.string,
    format: PropTypes.string,
};

export default ModuleDate;