import PlaceChildrenField from "./PlaceChildrenField"
import AutocompletePlace from "./AutocompletePlaceWidget"
import SelectPlaceField from "./SelectPlaceField"
import SelectParentField from "./SelectParentField"

export default {
    "fieldtypes": {
    },
    "fields": {
        "placeChildren": PlaceChildrenField,
        "selectParent": SelectParentField,
    },
    "layouts": {
    },
    "widgets": {
        "autocompletePlace": AutocompletePlace
    }
}