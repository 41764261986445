import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearch } from '../redux/functions/search';
import { useTranslation } from 'react-i18next';

import DialogBase from "./DialogBase"
import DialogSearch from "./DialogSearch"
import DialogSection from "./DialogSection"

import ReferenceQuery from "./DocumentsQuery"

import qs from 'query-string';

const DocumentsDialog = ({schema, formData, onChange, onClose, query, searchById}) => {
    const { t, i18n } = useTranslation(['dialog']);
    const [q, setQ] = useState("")

    const handleReset = () => {
        if (q) {
            setQ("")
        } else if (onClose) {
            onClose()
        }
    }

    const search = {
        placeholder: t("Find document"),
        q: q,
        onReset: () => handleReset(),
        onChange: (q) => setQ(q)
    }

    // query

    query = {
        ...query,
        id: "/dialog/reference",
        models: "documents",
        start: 0,
        rows: 20,
        sort: "title ASC",
        status:  "NOT trash",
        fl: "uniqueId,source,sourceId,modelName,documentType,title,description,status,mediaWidth,mediaHeight",
        q: q && q + "*" || "",
    }

    return (
        <DialogBase>
            <DialogSearch {...search} />
            <DialogSection>
                <ReferenceQuery
                    schema={schema}
                    formData={formData}
                    onChange={onChange}
                    query={query} />
            </DialogSection>
        </DialogBase>
    )

}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getSearch,
    }, 
dispatch);

const mapStateToProps = (state) => {
	return {
        searchById: state.searchById
	};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentsDialog);