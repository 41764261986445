import React from 'react';

class AdminHeaderSection extends React.Component {
    render() {
        const { title, subtitle } = this.props;
    
        return (
            <section className="header__section  bg-primary  color-primary-contrast">
                <h2 className="header__title">{title}</h2>
                <h3 className="header__subtitle">{subtitle}</h3>
            </section>
        )
    }
}

export default AdminHeaderSection;