import { isSelect, optionsList, getUiOptions } from "./"

const getSchemaSettingsField = ({itemSchema, itemUiSchema, name, value, props}) => {
    const { id, onSettingsChange } = props;
    const fieldId = id + '_settings_' + name;

    const enumOptions = isSelect(itemSchema) && optionsList(itemSchema);
    const uiOptions = itemUiSchema && getUiOptions(itemUiSchema) || {};
    
    let options;
    
    if (enumOptions) {
        options = enumOptions;
    } else {
        options = []
    }
    
    if (options && !value) {
        value = options[0].value
    }

    const current = options.find(option => {
        return option.value === value
    })

    const label = current && current.label || value


    return {
        ...uiOptions,
        id: fieldId,
        type: itemSchema.format || name,
        name: name,
        label: label,
        value: value,
        options: options,
        onChange: (name, value) => onSettingsChange(name, value)
    }
}

const getSchemaSettings = (props) => {
    const { schema, uiSchema, formData, uiSettings } = props;

    if (!uiSettings) {
        return []
    }
    
    let settings = [];

    uiSettings.map(name => {

        // settings is a string, transform it to settings object

        if (typeof name === "string") {

            const value = formData[name] || undefined;
        
            if (schema.properties[name]) {
                const field = getSchemaSettingsField({itemSchema: schema.properties[name], itemUiSchema: uiSchema[name], name: name, value: value, props: props})
                settings.push(field)
            }

        // settings is a string, transform it to settings object

        } else {
            settings.push(name)
        }
        
    })
    
    return settings;
    
}

export const getUiSettings = (props) => {
    const { schema, uiSchema } = props;

    const uiOptions = getUiOptions(uiSchema)
    const uiSettings = uiOptions && uiOptions.settings

    if (!uiSettings) {
        return []
    }

    let settings = []

    // schema settings, ui:settings=true, and we have a settings object

    if (uiSettings && schema.type === "object") {
        settings = getSchemaSettings({schema, uiSettings, ...props})
    }

    return settings;    
  
}