// initialState

let initialState = {
};

// editor state

const usersById = (state = initialState, action) => {

	switch (action.type) {

        case 'REQUEST_USER_BY_ID':
        
            return {
                ...state,
                [action.id]: undefined
            }
      	
        case 'RECEIVE_USER_BY_ID':

            return {
                ...state,
                [action.id]: action.data
            }

        default:
            return state
        }

}

export default usersById;