import React, { Component } from 'react';
import { getUiOptions, getUiSettings, getDefaultFormState } from "@frontend-components/admin"

class FieldMedia extends Component {

    static defaultProps = {
        defaultFormData: {
            "mediaId": undefined,
            "media": {
                "mediaType": undefined
            }
        },
        defaultOptions: {
            "layout": "media",
            "minHeight": 256,
            "editable": true,
            "deletable": true,
            "upload": true,
        }
    }

    onChange = (formData) => {
        const { schema, defaultFormData } = this.props;

        formData = getDefaultFormState(schema, {
            ...defaultFormData,
            ...formData
        })
        
        const newFormData = {
            ...formData
        }

        console.log('FieldMedia:onChange', newFormData)
        
        if (this.props.onChange) {
            this.props.onChange(newFormData);
        }
        
    }

    onEditMedia = () => {
        const { id, schema, uiSchema, formData, formContext, onChange } = this.props;

        if (formContext.onEditMedia) {
            formContext.onEditMedia({
                id: id,
                type: "media",
                schema: schema,
                uiSchema: uiSchema,
                formData: formData,
                onChange: onChange
            })
        }

    }

    /*
    onSettingsChange = (name, value) => {
        const { formData, onChange } = this.props;

        onChange({
            ...formData,
            content: {
                ...formData.content,
                [name]: value
            }
        })

    }
    */

    onCropdata = (imageCropdata) => {
        const { formData } = this.props;

        this.props.onChange({
            ...formData,
            imageCropdata: imageCropdata
        })

    }

    onFocalpoint = (imageFocalpoint) => {
        const { formData } = this.props;

        this.props.onChange({
            ...formData,
            imageFocalpoint: imageFocalpoint
        })

    }

    onFilters = (imageFilters) => {
        const { formData } = this.props;

        this.props.onChange({
            ...formData,
            imageFilters: imageFilters
        })

    }

    onAnnotations = (imageAnnotations) => {
        const { formData } = this.props;

        this.props.onChange({
            ...formData,
            imageAnnotations: imageAnnotations
        })

    }

    getUiPreview = () => {

        return {
            "select": {
                "type": "type",
                /*
                "media": "media",
                "mediaId": "mediaId",
                "uploadProgress": "uploadProgress",
                "mediaId": "mediaId",
                "media": "media",
                "imageCropdata": "imageCropdata",
                "imageFocalpoint": "imageFocalpoint",
                "imageFilters": "imageFilters",
                "imageAnnotations": "imageAnnotations",
                "imageUrl": "imageUrl",
                */
                "title": "title",
                "status": "status",
            },
            prepare({formData}) {
                const { title, uniqueId, imageUrl, status, mediaType, media, mediaId, imageCropdata, imageFocalpoint, imageFilters, imageAnnotations } = formData;

                return {
                    type: media && media.mediaType || mediaType,
                    title: media && media.title || title,
                    status: media && media.status || status,
                }

                return {
                    type: media && media.mediaType || mediaType,
                    status: media && media.status || status,
                    media: media,
                    mediaId: mediaId || uniqueId,
                    uploadProgress: media && media.uploadProgress,
                    title: media && media.title || title,
                    imageUrl: media && media.imageUrl || imageUrl,
                    imageCropdata: imageCropdata,
                    imageFocalpoint: imageFocalpoint,
                    imageFilters: imageFilters,
                    imageAnnotations: imageAnnotations,
                }
            }                
        }

    }

    getUiSchema = () => {
        const { formData, schema, uiSchema, defaultOptions } = this.props;
    
        let uiOptions = getUiOptions(uiSchema)
        let fieldset = uiOptions.fieldset || []

        if (!uiOptions.fieldset && schema.properties) {
            
            Object.keys(schema.properties).map(name => {
                
                const type = schema.properties[name] && schema.properties[name].type || undefined;
                
                if (type === "object") {
                    fieldset.push(name)
                }

                if (type === "array") {
                    fieldset.push(name)
                }
                
            })
            
        }

        const mediaId = formData && formData.mediaId;
        const media = formData && formData.media;

        const uiMediaProps = {
            "ui:mediaId": mediaId,
            "ui:media": media,
            "ui:uploadProgress": media && media.uploadProgress,
            "ui:mediaType": media && media.mediaType,
            "ui:resources": media && media.resources,
            "ui:imageUrl": media && media.imageUrl,
            "ui:imageCropdata": formData && formData.imageCropdata,
            "ui:imageFocalpoint": formData && formData.imageFocalpoint,
            "ui:imageFilters": formData && formData.imageFilters,
            "ui:imageAnnotations": formData && formData.imageAnnotations,
        }
    
        let uiMedia, uiCropdata, uiFocalpoint, uiFilters, uiAnnotations

        if (fieldset && fieldset.includes('media')) {
            const uiSchemaMedia = uiSchema && uiSchema.media;
            uiMedia = {
                "ui:title": "Preview",
                "ui:layout": "mediaPreview",
                ...uiMediaProps,
                ...uiSchemaMedia
            }
        }
    
        if (fieldset && fieldset.includes('imageCropdata')) {
            const uiSchemaCropdata = uiSchema && uiSchema.imageCropdata;
            uiCropdata = {
                "ui:title": "Crop",
                "ui:layout": "imageCropper",
                "ui:onChange": (cropdata) => this.onCropdata(cropdata),
                ...uiMediaProps,
                ...uiSchemaCropdata
            }
        }
    
        if (fieldset && fieldset.includes('imageFocalpoint')) {
            const uiSchemaFocalpoint = uiSchema && uiSchema.imageFocalpoint;
            uiFocalpoint = {
                "ui:title": "Focalpoint",
                "ui:layout": "imageFocalpoint",
                "ui:onChange": (focalpoint) => this.onFocalpoint(focalpoint),
                ...uiMediaProps,
                ...uiSchemaFocalpoint
            }
        }

        if (fieldset && fieldset.includes('imageFilters')) {
            const uiSchemaFilters = uiSchema && uiSchema.imageFilters;
            uiFilters = {
                "ui:title": "Filters",
                "ui:layout": "imageFilters",
                "ui:onChange": (filters) => this.onFilters(filters),
                ...uiMediaProps,
                ...uiSchemaFilters
            }
        }

        if (fieldset && fieldset.includes('imageAnnotations')) {
            const uiSchemaAnnotations = uiSchema && uiSchema.imageAnnotations;
            uiAnnotations = {
                "ui:title": "Annotations",
                "ui:layout": "imageAnnotations",
                "ui:onChange": (annotations) => this.onAnnotations(annotations),
                ...uiMediaProps,
                ...uiSchemaAnnotations
             }
        }

        if (fieldset.length) {
            uiOptions.nav = fieldset.length > 1 && true
            uiOptions.fieldset = fieldset
        }
 
        // buttons

        let uiButtons = uiOptions.buttons || ["save"] || []

        if (formData && formData.mediaId) {

            uiButtons = [
                ...uiButtons,
                {
                    label: "Edit media",
                    onClick: this.onEditMedia
                }
            ]
        } else {

            /*

            uiButtons.push({
                label: "Edit media",
                disabled: "true"
            })

            */

        }

        // getUiSettings

        /*

        let uiSettings;

        if (schema.properties.content && uiOptions.settings) {

            uiSettings = getUiSettings(({
                formData: formData && formData.content || {},
                schema: schema.properties.content,
                uiSchema: {
                    ...uiOptions.content,
                    "ui:settings": uiOptions.settings
                },
                onSettingsChange: this.onSettingsChange
            }))

        }

        */
        
        return {
            ...uiSchema,
            "ui:options": {
                ...defaultOptions,
                ...uiOptions
            },
            ...uiMediaProps,
            "media": uiMedia,
            "imageCropdata": uiCropdata,
            "imageFocalpoint": uiFocalpoint,
            "imageFilters": uiFilters,
            "imageAnnotations": uiAnnotations,
            "ui:preview": this.getUiPreview(),
            "ui:buttons": uiButtons,
//            "ui:settings": uiSettings
        }
        
    }

    getField = () => {
        const uiSchema = this.getUiSchema()

        let { defaultFormData, formData, schema } = this.props;

        formData = getDefaultFormState(schema, {
            ...defaultFormData,
            ...formData
        })

        return {
            ...this.props,
            formData: formData,
            uiSchema: uiSchema
        }

    }

    render() {
        const { schema, uiSchema, formData, registry } = this.getField();
        const { ObjectField } = registry.fields;

        return (
            <ObjectField {...this.props} schema={schema} uiSchema={uiSchema} formData={formData} onChange={this.onChange} />
        )
    
    }

}

export default FieldMedia;