import React, { Component } from "react";
import * as types from "../utils/types";

import {
    orderProperties,
    retrieveSchema,
    getDefaultRegistry,
    getUiOptions,
    ADDITIONAL_PROPERTY_FLAG,
} from "../utils";

/*

function DefaultObjectFieldTemplate(props) {

    const canExpand = function canExpand() {
        const { formData, schema, uiSchema } = props;
        if (!schema.additionalProperties) {
            return false;
        }
        const { expandable } = getUiOptions(uiSchema);
        if (expandable === false) {
            return expandable;
        }
        // if ui:options.expandable was not explicitly set to false, we can add
        // another property if we have not exceeded maxProperties yet
        if (schema.maxProperties !== undefined) {
            return Object.keys(formData).length < schema.maxProperties;
        }
        return true;
    };

    return props.properties.map(prop => prop.content);

}

*/

class ObjectField extends Component {

    static propTypes = types.fieldProps

    static defaultProps = {
        uiSchema: {},
        formData: {},
        errorSchema: {},
        idSchema: {},
        required: false,
        disabled: false,
        readonly: false,
    };

    state = {
        additionalProperties: {},
    };

    isRequired = (name) => {
        const schema = this.props.schema;
        return (
            Array.isArray(schema.required) && schema.required.indexOf(name) !== -1
        );
    }

    onChange = (formData, errorSchema) => {
        console.log('object:onChange', formData)
        const { formContext } = this.props;

        if (this.props.onChange) {
            this.props.onChange(formData, errorSchema)
        }

        if (formContext.onAfterChange) {
            setTimeout(() => {
                formContext.onAfterChange({
                    ...this.props,
                    formData: formData
                })
            })
        }

    }
    
    onPropertyChange = (name, addedByAdditionalProperties = false) => {
        const { schema } = this.props;        
        
        return (formData, errorSchema) => {

            if (!formData && addedByAdditionalProperties) {
                // Don't set value = undefined for fields added by
                // additionalProperties. Doing so removes them from the
                // formData, which causes them to completely disappear
                // (including the input field for the property name). Unlike
                // fields which are "mandated" by the schema, these fields can
                // be set to undefined by clicking a "delete field" button, so
                // set empty values to the empty string.
                formData = "";
            }
            const newFormData = { ...this.props.formData, [name]: formData };

            // set _action prop

            /*

            if (schema.properties.hasOwnProperty('_action')) {
                
                if (newFormData._action !== "delete" && newFormData._action !== "create") {
                    newFormData._action = "update"
                }
                
            }        

            */

            this.onChange(
                newFormData,
                errorSchema &&
                this.props.errorSchema && {
                    ...this.props.errorSchema,
                    [name]: errorSchema,
                }
            )
        }

    }

    onDropPropertyClick = key => {
        return event => {
            event.preventDefault();
            const { onChange, formData } = this.props;
            const copiedFormData = { ...formData };
            delete copiedFormData[key];
            this.onChange(copiedFormData);
        };
    };

    getAvailableKey = (preferredKey, formData) => {
        var index = 0;
        var newKey = preferredKey;
        while (formData.hasOwnProperty(newKey)) {
            newKey = `${preferredKey}-${++index}`;
        }
        return newKey;
    };

    onKeyChange = oldValue => {
        return (value, errorSchema) => {
            if (oldValue === value) {
                return;
            }
            value = this.getAvailableKey(value, this.props.formData);
            const newFormData = { ...this.props.formData };
            const newKeys = { [oldValue]: value };
            const keyValues = Object.keys(newFormData).map(key => {
                const newKey = newKeys[key] || key;
                return { [newKey]: newFormData[key] };
            });
            const renamedObj = Object.assign({}, ...keyValues);
            this.onChange(
                renamedObj,
                errorSchema &&
                this.props.errorSchema && {
                    ...this.props.errorSchema,
                    [value]: errorSchema,
                }
            );
        };
    };

    getDefaultValue(type) {
        switch (type) {
            case "string":
                return "New Value";
            case "array":
                return [];
            case "boolean":
                return false;
            case "null":
                return null;
            case "number":
                return 0;
            case "object":
                return {};
            default:
                // We don't have a datatype for some reason (perhaps additionalProperties was true)
                return "New Value";
        }
    }

    handleAddClick = schema => () => {
        const type = schema.additionalProperties.type;
        const newFormData = { ...this.props.formData };
        newFormData[
            this.getAvailableKey("newKey", newFormData)
        ] = this.getDefaultValue(type);
        this.onChange(newFormData);
    };
    
    render() {
        const {
            uiSchema,
            formData,
            errorSchema,
            idSchema,
            name,
            required,
            disabled,
            readonly,
            idPrefix,
            onBlur,
            onFocus,
            registry,
        } = this.props;

        const { definitions, fields, formContext } = registry;
        const { SchemaField, SchemaLayout } = fields;

        const schema = retrieveSchema(this.props.schema, definitions, formData);

//        const title = schema.title === undefined ? name : schema.title;
//        const description = uiSchema["ui:description"] || schema.description;

        let orderedProperties;
        try {
            const properties = Object.keys(schema.properties || {});
            orderedProperties = orderProperties(properties, uiSchema["ui:order"]);
        } catch (err) {
            return (
                <div>
                    <p className="config-error" style={{ color: "red" }}>
                        Invalid {name || "root"} object field configuration:
                        <em>{err.message}</em>.
                    </p>
                    <pre>{JSON.stringify(schema)}</pre>
                </div>
            );
        }
        
        return (
            <SchemaLayout {...this.props} 
                schema={schema} 
                onAddClick={this.handleAddClick}
                onPropertyChange={this.onPropertyChange}
                onChange={this.onChange}>
                { orderedProperties.map(name => {

                    const addedByAdditionalProperties = schema.properties[name].hasOwnProperty(ADDITIONAL_PROPERTY_FLAG);
                    
                    return (
                        <SchemaField
                            key={name}
                            name={name}
                            required={this.isRequired(name)}
                            schema={schema.properties[name]}
                            uiSchema={
                                addedByAdditionalProperties
                                    ? uiSchema.additionalProperties
                                    : uiSchema[name]
                            }
                            errorSchema={errorSchema[name]}
                            idSchema={idSchema[name]}
                            idPrefix={idPrefix}
                            formData={(formData || {})[name]}
                            onKeyChange={this.onKeyChange(name)}
                            onChange={this.onPropertyChange(
                                name,
                                addedByAdditionalProperties
                            )}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            registry={registry || getDefaultRegistry()}
                            disabled={disabled}
                            readonly={readonly}
                            onDropPropertyClick={this.onDropPropertyClick}
                        />
                    )
                    
                })}
            </SchemaLayout>
        )
        
    }

}

export default ObjectField;
