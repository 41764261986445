import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1)
    }
}));

const ButtonClose = ({className, onClick}) => {
   const classes = useStyles();
  
    return (
        <IconButton edge="end" className={className || classes.root} color="inherit" aria-label="close" onClick={onClick}>
            <CloseIcon />
        </IconButton>
    );

}

export default ButtonClose;