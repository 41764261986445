import React, { useState } from 'react';
import PropTypes from "prop-types"
import AppBar from '@material-ui/core/AppBar';
import AppSearch from "./AppSearch"
import AppIdentifier from "./AppIdentifier"
import AppSubview from "./AppSubview"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: theme.spacing(8),
        overflow: "hidden",
    },
    identifer: {
        position: "relative",
        width: "100%",
        height: theme.spacing(8),
        marginTop: theme.spacing(-8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]": {
            marginTop: 0
        }
    },
    subview: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        position: "relative",
        width: "100%",
        height: theme.spacing(8),
        marginTop: theme.spacing(-8),
        transition: ".125s ease-out",
        "&[aria-expanded=true]": {
            marginTop: 0
        }
    },
    growingSearch: {
        position: "absolute",
        right: 0,
        height: theme.spacing(5),
        margin: theme.spacing(1.5),
        borderRadius: theme.spacing(2.5),
        backgroundColor: "white",
        color: theme.palette.text.primary,
        display: "flex",
        justifyContent: "flex-start",

        width: theme.spacing(18),

        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(5),
        },

        overflow: "hidden",
        transition: ".125s ease-out",

        "& input": {
            fontSize: "16px",
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(3),
            overflow: "hidden",
            textOverflow: "ellipsis",

            [theme.breakpoints.down('xs')]: {
                display: "none",
            },

            "& + button": {
                display: "none"
            }

        },

        "& button": {
            width: "100%",
            height: theme.spacing(6),
            borderRadius: theme.spacing(3),
            marginTop: theme.spacing(-.5),
            marginRight: theme.spacing(.5),
            marginBottom: theme.spacing(-.5),
            marginLeft: theme.spacing(.5),

            [theme.breakpoints.down('xs')]: {
                margin: theme.spacing(-.5),
            },
    
            display: "flex",
            justifyContent: "flex-start"

    
        },

        "&[aria-expanded=true]": {
            width: "100%",

            [theme.breakpoints.down('xs')]: {
                width: "100%",
            },
    
            height: theme.spacing(8),
            margin: 0,
            borderRadius: 0,

            "& button": {
                width: theme.spacing(6),
                height: theme.spacing(6),
                margin: theme.spacing(1),
            },
    
            "& input": {
                fontSize: "18px",
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),

                [theme.breakpoints.down('xs')]: {
                    display: "block"
                },
    
                "& + button": {
                    display: "block"
                }
    
            }

        }

    },
    search: {
        position: "relative",
        width: "100%",
        height: theme.spacing(8),
        marginTop: 0,
        transition: ".125s ease-out",

        display: "flex",
        position: "relative",
        backgroundColor: "white",
        color: theme.palette.text.primary,
        width: "100%",
        height: theme.spacing(8),
        overflow: "hidden",
        fontFamily: "Akkurat, sans-serif",

        "&[aria-expanded=true]": {
            marginTop: theme.spacing(-8),
        }
    }
}));


const AppHeader = ({className, expanded = true, elevation = 2, color = "primary", title, subtitle, menu, search, subview, onToggle, children }) => {

    const classes = useStyles({color})
    const classesSearch = search && search.variant === "grow" && classes.growingSearch || classes.search
    const justifyContent = search && search.variant === "grow" && "flex-start" || "center"
    
    return (
        <AppBar position="absolute" className={className || classes.root} component="header" aria-expanded={expanded} elevation={expanded && elevation}>
            <AppSubview {...subview} className={classes.subview} expanded={subview && subview.expanded} />
            <AppIdentifier className={classes.identifer} justifyContent={justifyContent} menu={menu} search={search} title={title} subtitle={subtitle} expanded={search && search.expanded && false || true} onToggle={onToggle} />
            { search && !search.disabled && <AppSearch {...search} className={classesSearch} expanded={search && search.expanded} /> }
            {children}
        </AppBar>
    )

}

AppHeader.propTypes = {
    expanded: PropTypes.bool,
    elevation: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    search: PropTypes.shape({
        expanded: PropTypes.bool,
        placeholder: PropTypes.string
    }),
    subview: PropTypes.shape({
        expanded: PropTypes.bool,
        title: PropTypes.string,
        subtitle: PropTypes.string
    })
}

export default AppHeader;