import React, { Component } from 'react';
import Color from 'color';

class AdminTheme extends Component {
    static defaultProps = {
        theme: {
            primaryColor: '#666'
        }
    }

    state = {
        theme: undefined
    }

    componentDidMount() {
        this.getTheme()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.theme !== this.props.theme) {
            this.getTheme()
        }
    }

    getContrast = (color) => {
        let contrast;

        color = Color(color);

        if (color.isDark()) {
            contrast = 'white';
        } else {
            contrast = 'black';
        }

        return contrast;
    }

    getTheme = () => {
        let { theme } = this.props;

        if (!(theme && theme.primaryColor)) {
            this.setState({
                theme: false
            });
        }

        let { primaryColor, secondaryColor } = theme;

        if (!secondaryColor) {
            secondaryColor = primaryColor;
        }

        primaryColor = Color(primaryColor);
        secondaryColor = Color(secondaryColor);

        const primaryContrast = this.getContrast(primaryColor)
        const secondaryContrast = this.getContrast(secondaryColor)

        let primaryDark = "#666";
        let primaryLight = "#999";

        if (primaryColor.isDark()) {
            primaryDark = primaryColor;
        } else {
            primaryLight = primaryColor;
        }

        this.setState({
            theme: {
                primaryColor: primaryColor,
                primaryContrast: primaryContrast,
                secondaryColor: secondaryColor,
                secondaryContrast: secondaryContrast,
                primaryDark: primaryDark,
                primaryLight: primaryLight
            }
        })
    }

    renderStyle = () => {
        const { theme } = this.state;

        if (!theme) {
            return false;
        }

        const { primaryColor, primaryContrast, secondaryColor, secondaryContrast, primaryDark, primaryLight } = theme;

        return (
            <style dangerouslySetInnerHTML={{
                __html: `          

        .bg-primary {
          background-color: ${primaryColor};
          color: ${primaryContrast};
        }

        .bg-secondary {
          background-color: ${secondaryColor};
          color: ${secondaryContrast};
        }

        .bg-dark {
          background-color: ${primaryDark};
          color: white;
        }

        .bg-light {
          background-color: ${primaryLight};
          color: black;
        }
        
        .color-primary {
          color: ${primaryColor};
        }

        .color-primary-contrast {
          color: ${primaryContrast};
        }

        .color-secondary {
          color: ${secondaryColor};
        }

        .color-secondary-contrast {
          color: ${secondaryContrast};
        }

        .color-dark {
          color: ${primaryDark};
        }

        .color-light {
          color: ${primaryLight};
        }
        
        .nav-action .admin-button--primary {
          background-color: ${primaryDark};
          color: white;
        }

        .nav-path .admin-button[aria-selected="true"] {
          color: ${primaryDark};
        }
        
        a {
          color: ${primaryDark};
        }
        
      `}} />
        )
    }

    render() {
        const { theme } = this.state;

        if (!(theme && theme.primaryColor)) {
            return this.props.children
        }

        return (
            <div className="admin__theme">
                {this.renderStyle()}
                {this.props.children}
            </div>
        )
    }
}

export default AdminTheme