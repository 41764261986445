import uiPreview from "./uiPreview"

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "content": {
        "ui:field": "pageContent",
        "ui:nav": true,
        "ui:fieldset": [
            "place",
            "program",
            "products",
            "resources",
            "people",
        ],
        "place": {
            "ui:layout": "section",
            "ui:title": "Sted",
            "ui:fieldset": [
                "title",
                "description",
                "parentId"
            ],
            "ui:options": {
                "grid": "true",
                "padding": 2,
                "spacing": 2,
            },
            "title": {
                "ui:title": "Tittel"
            },
            "parentId": {
                "ui:title": "En del av",
                "ui:field": "selectParent",
                "ui:widget": "autocomplete"
            },
        },
        "program": {
            "ui:layout": "section",
            "ui:title": "Program",
            "ui:buttons": [
                {
                    "label": "Legg til",
                }
            ],
            "items": {
                "ui:layout": "module",
                "ui:editable": true
            }
        },
        "products": {
            "ui:layout": "section",
            "ui:title": "Servering",
            "ui:buttons": [
                {
                    "label": "Legg til",
                }
            ],
            "items": {
                "ui:layout": "module",
                "ui:editable": true,
                "ui:preview": {
                    "select": {
                        "description": "description"
                    },
                    prepare({formData}) {

                        const price = formData && formData.price;

                        let metadata = []

                        if (price) {
                            metadata.push(price + " NOK")
                        }

                        return {
                            description: metadata.join(' – ')
                        }
                    }
                }
            }
        },
        "people": {
            "ui:layout": "section",
            "ui:title": "Verter",
            "ui:buttons": [
                {
                    "icon": "search",
                    "type": "sidebar",
                    "label": "Finn person",
                },
                {
                    "label": "Legg til",
                }
            ],
        },
        "resources": {
            "ui:layout": "section",
            "ui:title": "Bygninger/rom",
            "ui:buttons": [
                {
                    "label": "Legg til",
                }
            ],
            "items": {
                "ui:layout": "module",
                "ui:editable": true,
                "ui:preview": {
                    "select": {
                        "description": "description"
                    },
                    prepare({formData}) {

                        const capacity = formData && formData.capacity;

                        let metadata = []

                        if (capacity) {
                            metadata.push(capacity + " personer")
                        }

                        return {
                            description: metadata.join(' – ')
                        }
                    }
                }
            }
        },
    }
}