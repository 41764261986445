import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"

import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"

import ButtonDraggable from "../NavToolbar/ButtonDraggable"
import ButtonCollapsible from "../NavToolbar/ButtonCollapsible"
import ButtonSelectable from "../NavToolbar/ButtonSelectable"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: {
        margin: theme.spacing(1.5)
    }
}));

const ModuleIconButton = ({ className, icon, onClick, collapsible, expanded, onToggle, selectable, selected, onSelect, sortBadge, draggable, draggableHandle }) => {

    const classes = useStyles()

    if (selectable) {
        return (
            <div className={className || classes.root}>
                { selectable && <ButtonSelectable selected={selected} onClick={onSelect} /> }
            </div>
        )    
    }

    if (draggable && draggableHandle) {
        return (
            <div className={className || classes.root}>
                { draggable && <ButtonDraggable icon={icon} sortBadge={sortBadge} draggableHandle={draggableHandle} /> }
            </div>
        )    
    }    

    if (collapsible) {
        return (
            <div className={className || classes.root}>
                { collapsible && <ButtonCollapsible expanded={expanded} onClick={onToggle} /> }
            </div>
        )    
    }

    if (icon && onClick) {
        return (
            <IconButton className={className || classes.root} onClick={onClick}>
                <Icon>{icon}</Icon>
            </IconButton>
        )    
    }

    if (icon) {
        return (
            <div className={className || classes.root}  >
                <Icon className={classes.icon}>{icon}</Icon>
            </div>
        )    
    }

    return false

}

ModuleIconButton.propTypes = {
    draggable: PropTypes.bool,
    draggableHandle: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    collapsible: PropTypes.bool,
    expanded: PropTypes.bool,
    onToggle: PropTypes.func,
}

export default ModuleIconButton;
