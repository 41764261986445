import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxBase = ({label, value, checked, onChange}) => {

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} color="primary" onChange={onChange} value={value} />}
            label={label}
        />
    )

}

export default CheckboxBase