// SEARCH

export const REQUEST_SEARCH = 'REQUEST_SEARCH'
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH'
export const RECEIVE_SEARCH_PAGE = 'RECEIVE_SEARCH_PAGE'

export function requestSearch(id, query) {

    return {
        type: REQUEST_SEARCH,
        id: id,
        query: query
    }
  
}

export function receiveSearch(id, data) {

    return {
        type: RECEIVE_SEARCH,
        id: id,
        data: data,
    }
  
}

export function receiveSearchPage(id, {page = 1, models = []}) {

    return {
        type: RECEIVE_SEARCH_PAGE,
        id: id,
        page: page,
        models: models
    }
  
}