import React, { forwardRef, useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types"
import SettingsButton from "../NavSettings/SettingsButton"
import SettingsDropdown from "../NavSettings/SettingsDropdown"

import Color from 'color';
import ColorPicker from "./Colorpicker"

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon: {
        position: "absolute",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        fontSize: 'inherit',
        width: '1.15em',
        height: '1.15em',
        borderRadius: '1em',
        backgroundColor: props => { return props.backgroundColor },
        color: props => {  return props.color },
        margin: "-0.075em"
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: '0.75em',
        fontWeight: "bold",
        lineHeight: '1',
        textAlign: "center",
    }
}));

const ColorIcon = ({variant, backgroundColor, color, contrastColor}) => {

    if (variant === "text") {
        backgroundColor = backgroundColor || contrastColor
    } else {
        backgroundColor = color
        color = contrastColor
    }

    const classes = useStyles({backgroundColor, color})

    const label = variant === "text" && "T" || ""

    return (
        <Paper className={classes.icon} elevation={1}>
            <b className={classes.label}>{label}</b>
        </Paper>
    )
}

const getOptions = ({options = []}) => {
    let _colors = [], _options = [], _byValue = {};

    options.length && options.map((item, index) => {

        if (typeof item === "string") {
            item = { label: item, value: item }
        }

        _colors.push(item.value)
        _options.push(item)

    })    

    return {
        colors: _colors,
        options: _options,
    }

}

const SettingsButtonRef = forwardRef((props, ref) => {
    const icon = ColorIcon(props)
    return <SettingsButton {...props} icon={icon} forwardedRef={ref} />;
});

const ColorSettings = ({type = "color", variant = "default", backgroundColor, buttonLabel, name, label, value, onChange, ...props}) => {
    const [expanded, setExpanded] = useState(false);
    const anchorRef = useRef(null);

    const { colors } = getOptions(props)

    if (!value && colors.length) {
        value = colors[0]
    }

    const primaryColor = Color(value)
    const color = primaryColor.hex()
    const contrastColor = primaryColor.isLight() && "black" || "white"

    if (variant === "text") {
        backgroundColor = backgroundColor || contrastColor
    } else {
        backgroundColor = color
    }

    const arrowColor = backgroundColor;

    const handleToggle = (event) => {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const handleCollapse = () => {
        setExpanded(false);
    };

    const handleChange = ({hex}) => {
        console.log("COLOR CHANDED", color)
        onChange && onChange(name, hex)
    }

    const handleSelect = (color) => {
        onChange && onChange(name, color)
        setExpanded(false);
    }

    return (
        <React.Fragment>
            <SettingsButtonRef type={type} value={value} variant={variant} backgroundColor={backgroundColor} color={color} contrastColor={contrastColor} onToggle={handleToggle} expanded={expanded} ref={anchorRef} />
            <SettingsDropdown expanded={expanded} anchorEl={anchorRef.current} arrowColor={arrowColor} onCollapse={handleCollapse}>
                <ColorPicker variant={variant} backgroundColor={backgroundColor} color={color} colors={colors} onChange={handleChange} />
            </SettingsDropdown>
        </React.Fragment>            
    )

}

ColorSettings.propTypes = {
    value: PropTypes.string,
    options: PropTypes.array
}

export default ColorSettings;