import React, { Component } from 'react';

import './Dropdown.scss';

class Dropdown extends Component {
    getClassName = () => {
        const { type, align, valign } = this.props;
        let classNames = []

        classNames.push('admin-dropdown');

        if (align) {
            classNames.push('admin-dropdown--' + align);
        }

        if (valign) {
            classNames.push('admin-dropdown--' + valign);
        }

        if (type) {
            classNames.push('admin-dropdown--' + type);
        }

        return classNames.join(' ')
    }

    render() {
        const { expanded, hidden, onClick } = this.props;
        const className = this.getClassName();

        return (
            <div className={className} aria-expanded={expanded} aria-hidden={hidden} onClick={onClick}>
                {this.props.children}
            </div>
        )
    }
}

export default Dropdown;