import { API } from '../settings';
//import { requestMenu, receiveMenuItem } from '../actions/menu';

export function getMenu(app, schema, menu) {

  const { title, root } = app;

  if (menu) {
    
    menu = getMenuChildren(root, menu);
    return menu;
    
  }
  
}

export function getMenuChildren(root, menu) {
  
  let children = []

  menu.forEach((child) => {
    const item = getMenuItem(root, child);
    children.push(item);
  });
  
  return children
  
}

export function getMenuItem(root, schema) {

  if (!schema) {
    return false;
  }

  if (schema.content) {
    schema = schema.content;
  }

  let { name, title, icon, url, path, children, query } = schema;
  
  if (!title) {
    title = name;
  }

  if (!url && path) {
    url = path.replace('/:root', root);
  }
  
  if (!url && name) {
    url = root + '/' + name;
  }
  
  if (!url) {
    url = root;
  }
  
  let item = schema;
  
  item.title = title;
  item.icon = icon;
  item.url = url;
  
  /*
  
  const item = {
    title: title,
    icon: icon,
    url: url
  }
  
  */

  if (children) {
    item.children = getMenuChildren(root, children)
  }
    
    /*
  
  if (query) {
    item.children = getMenuQuery(root, schema)
  } else if (children) {
    item.children = getMenuChildren(root, children)
  }
  */
  
  return item;
  
}

export function getMenuQueryData(data) {
  
  console.log('MODELS', data.models);

  return [
      {
        title: "Artister",
        icon: "",
        path: "artist"
      },
      {
        title: "Utgivelser",
        icon: "",
        path: "release"
      },
      {
        title: "Spor",
        icon: "",
        path: "track"
      }
    ]
  
  
  return data.models;
  
}

export function getMenuQuery(root, schema) {
  
  const { query } = schema;

  let url = API + '/admin/api/search'
  url = url + '?q=' + query;
  
  let children = [];

  fetch(url, {
    method: "GET",
    headers: {
      "Accept": "application/json",
    },
  })
  .then(
      response => response.json(),
      error => console.log('An error occurred.', error)
  )
  .then((data) => {
    return getMenuQueryData(data);
  })
  
}

export function getMediaMenu(root, title = "Media", icon = "image") {

  const menu = {
    title: title,
    icon: icon,
    url: root + '/media',
    children: [
      {
        title: 'Bilder',
        url: root + '/media/image#grid',
      },
      {
        title: 'Video',
        url: root + '/media/video',
      },
      {
        title: 'Audio',
        url: root + '/media/audio',
      },
      {
        title: 'Dokumenter',
        url: root + '/media/misc',
      }
    ]
  }
  
  return menu;
  
}

export function getSettingsMenu(root) {

  const menu = {
    children: [
      {
        title: 'Innstillinger',
        icon: 'settings',
        url: root + '/settings',
        children: [
          {
            title: 'Schema',
            url: root + '/settings/schema',
          }
        ]
      }
    ]
  }
  
  return menu;
  
}




export function getSchemaMenu(root, schemas) {

  let children = [];
  
  schemas.forEach((schema) => {
    const item = getMenuItem(root, schema);
    children.push(item);
  });
  
  const menu = {
    children: children
  }

  return menu;  
  
}

export function getAction(app, schema) {

  const { title, root } = app;

  const actionSchema = getSchemaAction(root, schema);
  const actionUpload = getUploadAction(root);
  
  const action = {
    title: 'Nytt innhold',
    children: [
      actionSchema,
      actionUpload
    ]
  }
  
  return action;
  
}

export function getSchemaActionItem(root, schema) {

  let { name, title, finderSettings } = schema;
  
  if (!title) {
    title = name;
  }

  let icon;
  
  if (finderSettings && finderSettings.actionLabel) {
    title = finderSettings.actionLabel;
  }

  if (finderSettings && finderSettings.actionIcon) {
    icon = finderSettings.actionIcon;
  }
  
  let url = root + '/' + name + '/<new>/edit';
  
  const item = {
    title: title,
    icon: icon,
    url: url
  }
  
  return item;
  
}

export function getSchemaAction(root, schemas) {

  let children = [];
  
  schemas.forEach((schema) => {
    const item = getSchemaActionItem(root, schema);
    children.push(item);
  });

  const action = {
    children: children
  }

  return action;  
  
}

export function getViewsMenu(root) {

  const menu = {
    children: [
      {
        title: 'Siste',
        icon: 'access_time',
        url: root + '/latest',
      },
      {
        title: 'Favoritter',
        icon: 'star',
        url: root + '/starred',
      },
      {
        title: 'Slettet',
        icon: 'delete',
        url: root + '/trash',
      }
    ]
  }
  
  return menu;
  
}

export function getUploadAction(root) {

  const action = {
    title: 'Last opp media',
    icon: 'cloud_upload',
    url: root + '#upload'
  }
  
  return action;
  
}

export function getMenuLatest({root, collectionId, role = "group", title = "Siste", icon = "access_time"}) {

    return {
        role: role,
        title: title,
        icon: icon,
        url:  root + "/latest",
        query: {
            collectionId: collectionId,
            status: "NOT trash",
            models: "documents",
            sort: "updatedAt DESC",
            group: "updatedAt"
        },
        children: [
            {
              title: "I dag",
              url: root + "/latest/today",
              query: {
                    collectionId: collectionId,
                    updatedAt: "today",
                    status: "NOT trash",
                    models: "documents",
                    sort: "updatedAt DESC",
                    group: "updatedAt"
                }
            },
            {
                title: "Denne uken",
                url: root + "/latest/lastWeek",
                query: {
                    collectionId: collectionId,
                    status: "NOT trash",
                    models: "documents",
                    updatedAt: "lastWeek",
                    sort: "updatedAt DESC",
                    group: "updatedAt"
                }
              },
              {
                title: "Denne måneden",
                url: root + "/latest/lastMonth",
                query: {
                    collectionId: collectionId,
                    status: "NOT trash",
                    models: "documents",
                    updatedAt: "lastMonth",
                    sort: "updatedAt DESC",
                    group: "updatedAt"
                }
              }
            ]
      }    
    
}

export function getMenuTrash({ root, collectionId, role = "group", title = "Slettet", icon = "delete" }) {
    
    return {
        role: role,
        title: title,
        icon: icon,
        url:  root + "/trash",
        view: "list",
        query: {
            collectionId: collectionId,
            status: "trash",
            models: "documents",
            sort: "deletedAt DESC",
            group: "deletedAt"
        },
        children: [
            {
                title: "Dokumenter",
                url: root + "/trash/documents",
                query: {
                    collectionId: collectionId,
                    status: "trash",
                    models: "documents",
                    sort: "deletedAt DESC",
                    group: "deletedAt"
                }
            },
            {
                title: "Media",
                url: root + "/trash/media",
                query: {
                    collectionId: collectionId,
                    status: "trash",
                    models: "media",
                    sort: "deletedAt DESC",
                    group: "deletedAt"
                }
            }
        ]
    }    
}

export function getMenuMedia({ root, collectionId, mediaTypes = ["image","video","audio"], role = "group", title = "Media", icon = "image"}) {

    let children = []

    if (mediaTypes.includes("image")) {
        children.push({
            title: "Bilder",
            url: root + "/media/image",
            query: {
                collectionId: collectionId,
                status: "NOT trash",
                models: "media",
                mediaType: "image"
            }
        })
    }

    if (mediaTypes.includes("video")) {
        children.push({
            title: "Video",
            url: root + "/media/video",
            query: {
                collectionId: collectionId,
                status: "NOT trash",
                models: "media",
                mediaType: "video"
            }
        })
    }

    if (mediaTypes.includes("audio")) {
        children.push({
            title: "Audio",
            url: root + "/media/audio",
            query: {
                collectionId: collectionId,
                status: "NOT trash",
                models: "media",
                mediaType: "audio"
            }
        })
    }

    if (mediaTypes.includes("youtube")) {
        children.push({
            title: "YouTube",
            url: root + "/media/youtube",
            query: {
                models: "youtube",
                mediaType: "video"
           }
        })
    }

    if (mediaTypes.includes("vimeo")) {
        children.push({
            title: "Vimeo",
            url: root + "/media/vimeo",
            query: {
                models: "vimeo",
                mediaType: "video"
           }
        })
    }

    if (mediaTypes.includes("sketchfab")) {
        children.push({
            title: "Sketchfab",
            url: root + "/media/sketchfab",
            query: {
                models: "sketchfab",
                mediaType: "video"
           }
        })
    }

    // upload

    children.push({
        "url": root + "/media/upload",
        "title": "Upload",
        "query": {
            "collectionId": collectionId,
            "models": "media",
            "uploadStatus": "NOT done"
        },
        "template": "upload",
    })

    // q

    let include = mediaTypes && mediaTypes.map(mediaType => {
        return "mediaType:"+mediaType
    })

    return {
        role: role,
        title: title,
        icon: icon,
        url: root + "/media",
        query: {
            collectionId: collectionId,
            status: "NOT trash",
            models: "media",
            q: include && include.join(" OR ")
        },
        children: children
    }

}

export function getMenuEkultur({ root, collectionId, role = "group", title = "Samlinger", icon = "view_headline"}) {

    return {
        role: role,
        title: title,
        icon: icon,
        url: root + "/ekultur",
        query: {
            models: "ekultur"
        },
        children: [
            {
                title: "Fotografier",
                url: root + "/ekultur/photograph",
                query: {
                    models: "ekultur",
                    objectType: "Photograph"
                }
            },
            {
                title: "Gjenstander",
                url: root + "/ekultur/thing",
                query: {
                    models: "ekultur",
                    objectType: "Thing"
                }
            }
        ]
      }

}