import React, { Component } from 'react';
import Button from './Button';

import './ButtonHeader.scss';

class ButtonHeader extends Component {
    getClassName = () => {
        const { className, action } = this.props;

        if (className) {
            return className;
        }

        let classNames = []

        classNames.push('admin-button');
        classNames.push('admin-button--header');

        if (action) {
            classNames.push('admin-button--header-' + action);
        }

        return classNames.join(' ');
    }

    render() {
        const className = this.getClassName();

        return (
            <Button {...this.props} className={className} />
        );
    }
}

export default ButtonHeader;
