import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LayoutFinder, NavPath } from "@frontend-components/admin"
import FinderQuery from "../../AppFinder/FinderQuery"
import FinderTemplate from "../../AppFinder/FinderTemplate"

const defaultResults = {
    models: [
        {
            imageUrl: "https://www.spaniaidag.no/wp-content/uploads/2019/09/David-de-Gea-960x480.jpeg",
            mediaWidth: 960,
            mediaHeight: 940,
            title: "David De Gea"
        },
        {
            imageUrl: "https://assets.manutd.com/AssetPicker/images/0/0/10/126/687732/Legends-Profile_Gary-Neville1523521397690.jpg",
            mediaWidth: 1130,
            mediaHeight: 1130,
            title: "Gary Neville"
        },
        {
            imageUrl: "https://www.united.no/wp-content/uploads/2013/05/js-268x350.jpg",
            mediaWidth: 268,
            mediaHeight: 350,
            title: "Jaap Stam"
        }
     ]
}

const PreviewCollectionQuery = ({title, url, query, template, layout, ...props}) => {

    query = {
        ...query,
        id: url
    }

    return (
        <LayoutFinder title={title}>
            { JSON.stringify(query, '', 2) }
            <FinderQuery query={query} template={template} layout={layout} />
        </LayoutFinder>
    )

}

function mapStateToProps(state) {
	return {
        editor: state.editor,
        formData: state.editor.formData,
        formContext: state.editor.formContext,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewCollectionQuery);
