import React from 'react';

import EditorSection from "./EditorSection"
import EditorContent from "./EditorContent"
import EditorPreview from "./EditorPreview"
import EditorSidebar from "./EditorSidebar"

import PageMenu from "./PageMenu"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        zIndex: 2,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    editor: {
        backgroundColor: "inherit",
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        overflow: "hidden",
    },
    preview: {
        position: "absolute",
        backgroundColor: "#333",
        top: 0,
        right: 0,
        bottom: 0,
        left: "50%",
        overflow: "hidden"
    }
}));

const PageContent = ({expanded = true, sidebar, preview, menuWidth, menu = undefined, children}) => {
    const classes = useStyles();

    const renderSidebar = () => {
        const SidebarTemplate = sidebar && sidebar.template;

        return (
            <EditorSidebar expanded={sidebar && sidebar.id && true}>
                { SidebarTemplate && <SidebarTemplate {...sidebar} /> }
            </EditorSidebar>
        )
    }

    const renderPreview = () => {
        const PreviewTemplate = preview && preview.template;

        return (
            <EditorPreview className={classes.preview} expanded={true}>
                { PreviewTemplate && <PreviewTemplate {...preview} /> || JSON.stringify(preview) }
            </EditorPreview>
        )

    }  

    return (
        <EditorSection elevation={0} className={classes.root} aria-expanded={expanded}>
            { sidebar && renderSidebar() }
            <EditorSection elevation={2} className={classes.editor}>
                { menu && <PageMenu menuWidth={menuWidth} menu={menu} /> }
                <EditorContent expanded={!preview && true || false}>
                    {children}
                </EditorContent>
                { preview && renderPreview() }
            </EditorSection>
        </EditorSection>
    )

}

export default PageContent;