import React, { useState, useEffect } from 'react';
import FinderSection from "../components/Finder/FinderSection"
import FinderModel from "./FinderModel"
import ResultsLoader from "./ResultsLoader"
import ResultsPagesPrev from "./ResultsPagesPrev"
import ResultsPagesNext from "./ResultsPagesNext"
import { useTranslation } from 'react-i18next';

const ResultsUploads = ({layout = "uploads", ...props}) => {
    const { t, i18n } = useTranslation('upload');

    const { resultsLoaded = [], count } = props

    const title = t('{{count}} unprocessed uploads', {count});
    const loadingtTitle = t('Fetching unprocessed uploads') + "...";
    const emptyTitle = t('No unprocessed uploads');

    return (
        <ResultsLoader {...props} emptyTitle={emptyTitle} loadingtTitle={loadingtTitle}>
            <FinderSection title={title}>
                <ResultsPagesPrev {...props} />
                { resultsLoaded.map((model, index) => {
                    return (
                        <FinderModel {...props} model={model} layout="list" key={index} />
                    )
                })}
                <ResultsPagesNext {...props} />
            </FinderSection>
        </ResultsLoader>
    )

}

export default ResultsUploads