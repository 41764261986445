import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Color from 'color';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
//        minHeight: theme.spacing(7),
        padding: theme.spacing(.5),

        "& > *": {
        }
    },
    swatch: {
        boxShadow: theme.shadows[1],
        minHeight: theme.spacing(3),
        minWidth: theme.spacing(3),
        margin: theme.spacing(.5),
        backgroundColor: props => { return props.backgroundColor },
        color: props => {  return props.color },
        borderRadius: "100%",
        display: "flex",
        flexAlign: "center",
        justifyContent: "center"
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: '1em',
        fontWeight: "bold",
        lineHeight: '1',
    }
}));

const ColorSwatches = ({variant, backgroundColor, colors = [], color, onChange}) => {

    const classes = useStyles()
    
    const handleChange = (hex, event) => {
        onChange && onChange({hex: hex}, event)
    }
    
    if (!colors.length) {
        return false
    }

    const SwatchButton = ({color, contrastColor, onClick}) => {

        if (variant === "text") {
            backgroundColor = backgroundColor || contrastColor
        } else {
            backgroundColor = color
            color = contrastColor
        }

        const classes = useStyles({backgroundColor: backgroundColor, color: color})

        return (
            <ButtonBase className={classes.swatch} onClick={onClick}>
                <i className={classes.label}>A</i>
            </ButtonBase>
        )

    }

    return (
        
        <div className={classes.root} data-size={colors.length}>
            { colors.map((color, index) => {

                const primaryColor = Color(color)
                const contrastColor = primaryColor.isLight() && "black" || "white"

                return (
                    <SwatchButton color={color} contrastColor={contrastColor} key={index} onClick={(event) => handleChange(color, event)} />
                )
            })}
        </div>

    )
    
}

export default ColorSwatches;