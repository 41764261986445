import React from 'react';
import { FilterOwnersControlled as FilterOwners, OwnersList } from "../Owners/"


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderTop: "1px solid",
        borderColor: "#ccc",
        position: "relative"
    },
    list: {
        height: theme.spacing(7 * 8)
    }
}));

const AppsOwnersList = ({owners = [], onSelect }) => {

    const classes = useStyles()

    if (owners.length > 7) {
        return (
            <section className={classes.root}>
                <FilterOwners className={classes.list} owners={owners} onSelect={onSelect} />
            </section>
        )
    } 

    return (
        <section className={classes.root}>
            <OwnersList owners={owners} onSelect={onSelect} />
        </section>
    )

}

export default AppsOwnersList;
