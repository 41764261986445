import schema from "./schema";
import uiSchema from "./uiSchema";
import preview from "./preview";

export default {
    id: 58,
    schemaType: 'kp',
    name: 'article',
    JSONSchema: schema,
    uiSchema: uiSchema,
    preview: preview
}