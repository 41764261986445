import React from "react";
import { PrimusIcon as icon } from '@frontend-components/admin/es/resources/icons';

export default {
    sortOrder: 10.1,
    uniqueId: "app/primus",
    appType: "site/management",
    collectionType: "site/primus",
    name: "primus",
    icon: icon,
    title: "Primus"
};