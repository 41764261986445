import layout from "./Layout/registry"
import document from "./Document/registry"
import section from "./Section/registry"
import module from "./Module/registry"
import editor from "./Editor/registry"
import location from "./Location/registry"
import localizedString from "./LocalizedString/registry"
import media from  "./Media/registry"
import reference from  "./Reference/registry"
import field from "./Field/registry"
import color from "./Color/registry"
import settings from "./NavSettings/registry"
import license from "./License/registry"

export default {
    "fieldtypes": {
        ...field.fieldtypes,
        ...color.fieldtypes,
        ...layout.fieldtypes,
        ...section.fieldtypes,
        ...module.fieldtypes,
        ...document.fieldtypes,
        ...editor.fieldtypes,
        ...localizedString.fieldtypes,
        ...media.fieldtypes,
        ...location.fieldtypes,
        ...reference.fieldtypes,
        ...license.fieldtypes,
    },
    "fields": {
        ...field.fields,
        ...color.fields,
        ...layout.fields,
        ...section.fields,
        ...module.fields,
        ...document.fields,
        ...editor.fields,
        ...localizedString.fields,
        ...media.fields,
        ...location.fields,
        ...reference.fields,
        ...license.fields
    },
    "layouts": {
        ...field.layouts,
        ...color.layouts,
        ...layout.layouts,
        ...section.layouts,
        ...module.layouts,
        ...document.layouts,
        ...editor.layouts,
        ...localizedString.layouts,
        ...media.layouts,
        ...location.layouts,
        ...reference.layouts,
        ...license.layouts
    },
    "widgets": {
        ...field.widgets,
        ...color.widgets,
        ...layout.widgets,
        ...section.widgets,
        ...module.widgets,
        ...document.widgets,
        ...editor.widgets,
        ...localizedString.widgets,
        ...media.widgets,
        ...location.widgets,
        ...reference.widgets,
        ...settings.widgets,
        ...license.widgets
    },
    "settings": {
        ...field.settings,
        ...color.settings,
        ...layout.settings,
        ...section.settings,
        ...module.settings,
        ...document.settings,
        ...editor.settings,
        ...localizedString.settings,
        ...media.settings,
        ...location.settings,
        ...reference.settings,
        ...settings.settings,
        ...license.settings
    }
}