import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { withResizeDetector } from 'react-resize-detector';

import { getImageCropdataStyles } from "./utils/getImageCropdataStyles"
import { getImageFiltersStyle } from "./utils/getImageFiltersStyle"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: "hidden"
    },
    cropbox: {
        position: "absolute",
        overflow: props => { return props.overflow },
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: "auto"
    },
    image: {
        position: "absolute",
        opacity: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "block",
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        margin: "auto",
    }
}));

const ImageBase = ({
    className = undefined,
    overflow = "hidden", 
    imageUrl = undefined, 
    imageCropdata = undefined, 
    imageFilters = undefined,
    onLoad,
    onClick,
    children,
    width,
    height}) => {

    const parentRef = useRef(null)
    const cropboxRef = useRef(null)
    const imageRef = useRef(null)

    const [imageLoaded, setImageLoaded] = useState(false)
    const [imageWidth, setImageWidth] = useState(null)
    const [imageHeight, setImageHeight] = useState(null)

    const [cropboxStyle, setCropboxStyle] = useState(null)

    const [imageCropStyle, setImageCropStyle] = useState(null)
    const [imageFiltersStyle, setImageFiltersStyle] = useState(null)

    const classes = useStyles({overflow})

    const getImage = () => {

        if (!imageRef.current) {
            return
        }

        imageRef.current.addEventListener('load', event => {
            const imageWidth = event.target.naturalWidth;
            const imageHeight = event.target.naturalHeight;

            setImageLoaded(true)
            setImageWidth(imageWidth)
            setImageHeight(imageHeight)

            onLoad && onLoad({
                parentRef,
                cropboxRef,
                imageRef
            })


        })

    }

    const getImageFilters = () => {

        if (!imageFilters) {
            return
        }

        const filters = getImageFiltersStyle({imageFilters})
        setImageFiltersStyle(filters)

    }

    const getImageCrop = () => {

        if (!imageRef.current || !parentRef.current) {
            return
        }

        if (!imageCropdata) {
            return
        }

        const cropStyles = getImageCropdataStyles({imageCropdata, parent: parentRef.current, image: imageRef.current})

        setCropboxStyle(cropStyles.cropbox)
        setImageCropStyle(cropStyles.image)

    }

    useEffect(() => {
        getImage()
    }, [imageRef.current])

    useEffect(() => {
        getImageFilters()
    }, [imageFilters])

    useEffect(() => {
        getImageCrop()
    }, [imageCropdata, imageLoaded, width, height])

    const imageStyle = {
        ...imageFiltersStyle,
        ...imageCropStyle
    }
    
    return (
        <div className={className || classes.root} ref={parentRef} data-loaded={imageLoaded}>
            <div className={classes.cropbox} ref={cropboxRef} style={cropboxStyle}>
                <img className={classes.image} src={imageUrl} ref={imageRef} style={imageStyle} onClick={onClick} />
                { children }
            </div>
        </div>
    )

}

export default withResizeDetector(ImageBase);