export default {
    "ui:layout": "section",
    "ui:icon": "settings",
    "ui:title": "Innstillinger",
    "ui:options": {
        "grid": true,
        "spacing": 2
    },
    "ui:fieldset": [
        "title",
        "description",
        "schemaType",
        "name",
        "defaultLocale",
        "collectionId"
    ],
    "title": {
        "ui:title" : "Tittel",
    },
    "description": {
        "ui:title" : "Beskrivelse",
        "ui:help" : "Brukes kun internt i applikasjonen for gjenfinning.",
        "ui:widget": "textarea"
    },
    "schemaType": {
        "ui:title" : "Schema type",
        "ui:placeholder": "Velg type"
    },
    "name": {
        "ui:title" : "Navn",
        "ui:description" : "NB: Kort navn, uten mellomrom.",
        "ui:help" : "Brukes for å lage brukervennlige url-er i admin.",
    },
    "collectionId": {
        "ui:title" : "CollectionId",
        "ui:help" : "Knytt schema til collection.",
        "ui:widget": "collectionId"
    },
}