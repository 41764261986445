import React from "react";
import { DigitaltMuseumIcon as icon } from '@frontend-components/admin/es/resources/icons';

export default {
    sortOrder: 1.1,
    uniqueId: "app/dm",
    appType: "site/product",
    collectionType: "dm",
    name: "dm",
    icon: icon,
    title: "DigitaltMuseum"
};