export default {
    type: "object",
    properties: {
        template: {
            type: "string",
            enum: ["iframe"],
            default: "iframe"
        },
        url: {
            type: "string"
        },
        format: {
            type: "string",
            enum: [undefined, "16:9","4:3"],
            default: undefined
        },
        targetOrigin: {
            type: "string",
            default: "*"
        },
        "select": {
            type: "string"
        },
        "prepare": { 
            type: "string"
        },
    }
}