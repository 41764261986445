import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabsTabpanel extends Component {
  
    static defaultProps = {
        className: "admin-tabs__tabpanel",
        forceRender: false,
    };

    static propTypes = {
        children: PropTypes.node,
        forceRender: PropTypes.bool,
        id: PropTypes.string, // private
        selected: PropTypes.bool, // private
        selectedClassName: PropTypes.string,
        tabId: PropTypes.string, // private
    };
  
    render() {

    const {
        className,
        children,
        forceRender,
        id,
        selected,
        selectedClassName,
        tabId,
        ...attributes
    } = this.props;

    return (
        <div 
        {...attributes}
        className={ className } role="tabpanel"
        id={id}
        aria-labelledby={ tabId }
        aria-selected={selected ? 'true' : 'false'}
        key={this.props.index}
        >
        { this.props.children }
        </div>
    )
  
  }
  
}

export default TabsTabpanel;