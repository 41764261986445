export default {
    "select": {
        "name": "name",
        "siteId": "siteId",
        "title": "title",
        "description": "description",
        "imageUrl": "imageUrl"
    },
    prepare({formData}) {
        const content = formData && formData.content;

        let name, title, description, siteId, collectionType;

        if (content) {
            name = content && content.name
            title = content && content.title
            description = content && content.description
            siteId = content && content.siteId
            collectionType = content && content.collectionType
        }

        let media, imageUrl

        if (content && content.icon) {
            media = content && content.icon && content.icon && content.icon.media

            if (media && media.mimeType && media.mimeType.startsWith('image/svg')) {
                imageUrl = media && media.mediaUrl + "?mediaType=image/svg"
            } else {
                imageUrl = media && media.imageUrl
            }

            
        }
        
        return {
            name: name,
            siteId: siteId,
            title: title,
            description: description,
            imageUrl: imageUrl
        }

    }
}