import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel, deleteModel, eraseModel, restoreModel } from '../redux/functions/model';
import { bulkAdd, bulkRemove } from '../redux/functions/bulk';
import { addFavourite, removeFavourite } from '../redux/functions/favourites';
import { ListModule, TableModule, GridModule, getUiPreview } from '@frontend-components/admin';
import { useTranslation } from 'react-i18next';

const layouts = {
    "table": TableModule,
    "list": ListModule,
    "grid": GridModule,
}

const getUrlFromProps = (props, urlPattern) => {

    if (!urlPattern) {
        return undefined;
    }
    
    const urlParts = urlPattern.split('/');

    const url = urlParts.map((name) => {
        
        let value = name;
        
        if (name.startsWith(":")) {
            name = name.substr(1);
            value = props[name] || undefined;
        }
        
        return value;
        
    })

    return url.join("/");

}

const FinderModel = ({model, layout="table", size="medium", schemasByName, modelsById, bulk, favourites, query, ...props}) => {
    const { t, i18n } = useTranslation();

    const { modelName, documentType, mediaType, uniqueId } = model;

    useEffect(() => {
        if (models && !modelsById[uniqueId]) {
            props.getModel(models, uniqueId)
        }
    }, [uniqueId])

    let models, modelSchema;

    if (modelName === "users") {
        models = "users"
        modelSchema = schemasByName['user']
    } else if (modelName === "sites") {
        models = "sites"
        modelSchema = schemasByName['site']
    } else if (modelName === "collections") {
        models = "collections"
        modelSchema = schemasByName['collection']
    } else if (modelName === "doc_schemas") {
        models = "schemas"
        modelSchema = schemasByName['schema']
    } else if (modelName === "documents") {
        models = "documents"
        modelSchema = documentType && schemasByName[documentType]
    } else if (modelName === "media") {
        models = "media"
        modelSchema = mediaType && schemasByName[mediaType]
    } else if (query && query.models) {
        models = query.models
    }

    const uniqueModel = modelsById && modelsById[uniqueId] || model

    const [preview, setPreview] = useState(model)

    useEffect(() => {
        let uiPreview = {}

        if (modelSchema && uniqueModel) {
            uiPreview = getUiPreview({...modelSchema, formData: uniqueModel, formContext: {}})
        }

        const { createdAt, createdByName, updatedAt, updatedByName, deletedAt, deletedByName } = uniqueModel;

        const author = deletedByName || updatedByName || createdByName || "N/A"
        const datetime = deletedAt || updatedAt || createdAt

        setPreview({
            ...model,
            ...uniqueModel,
            ...uiPreview,
            author: author,
            datetime: datetime
        })

    }, [uniqueModel, modelSchema])

   
    const onEdit = () => {
        const editUrl = props.editUrl || getUrlFromProps(model, props.editUrlPattern || props.location.pathname + "/:uniqueId/edit")
        editUrl && props.history.push(editUrl)
    }

    const onDelete = () => {
        props.deleteModel(models, uniqueId);
    }

    const onRestore = () => {
        props.restoreModel(models, uniqueId);
    }

    const onErase = () => {
        props.eraseModel(models, uniqueId);
    }

    const selected = bulk.includes(uniqueId)

    const onSelect = () => {
        if (selected) {
            props.bulkRemove(uniqueId)
        } else {
            props.bulkAdd(uniqueId)
        }
    }

    const favourite = favourites.includes(uniqueId)

    const onFavourite = () => {
        if (favourite) {
            props.removeFavourite(uniqueId)
        } else {
            props.addFavourite(uniqueId)
        }
    }
   
    const { status } = uniqueModel;

    let action, toolbar;

    if (status === "erased") {

        action = {
            editable: false,
            selectable: false
        }

    } else if (bulk.length) {

        action = {
            selectable: true,
            onClick: onSelect
        }

    } else if (models && status === "trash") {

        action = {
            selectable: true,
            onSelect: onSelect
        }

        toolbar = [
            {
                "icon": "restore",
                "onClick": onRestore
            },
            {
                "label": "Erase",
                "onClick": onErase
            }
        ]

    } else if (models) {

        action = {
            editable: true,
            onEdit: onEdit,
            selectable: true,
            onSelect: onSelect
        }
    
        toolbar = [
            {
                "icon": favourite && "favorite" || "favorite_border",
                "onClick": onFavourite
            },
            {
                "name": "edit",
                "icon": "edit",
                "onClick": onEdit
            },
            {
                "icon": "delete",
                "onClick": onDelete
            }
        ]
    }

    const statusLabel = t("status:"+status || undefined)

    const LayoutTemplate = layout && layouts[layout]
    
    return (
        <LayoutTemplate {...preview} size={size} selected={selected} statusLabel={statusLabel} toolbar={toolbar} {...action} />
    )

}

const mapStateToProps = (state) => {
	return {
        schemasByName: state.schemasByName,
        modelsById: state.modelsById,
        bulk: state.finderBulk,
//        selectedById: state.bulk.selectedById,
        favourites: state.userFavourites,
    };
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getModel, deleteModel, eraseModel, restoreModel,
    bulkAdd, bulkRemove,
    addFavourite, removeFavourite
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinderModel);