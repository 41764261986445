import React, { useState, useEffect } from 'react';
import Nestable from 'react-nestable';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
    }
}));

let id = 0

const getItems = ({children = []}) => {

    return children.map((item, index) => {
        id = id + 1;

        if (item.children) {
            return {
                ...item,
                id: id,
                children: getItems({children: item.children})
            }
        }
        return {
            ...item,
            id: id
        }
    })

}

const MenuEditorItem = ({handler, item}) => {
 
    if (item.expanded) {
        return (
            <div>
                {item.title}
            </div>
        )
    }

    return (
        <div>
            {handler}
            {item.title}
        </div>
    )

}

const MenuEditorList = ({schema, formData, onChange, ...props}) => {
    const [items, setItems] = useState([])

    useEffect(() => {
        setItems(getItems({children: formData}))
    }, [formData]);

    
    const handleChange = (items, item) => {
//        onChange(items)
    }


    return (
        <Nestable
            items={items}
            renderItem={MenuEditorItem}
            handler={<IconButton color="inherit" aria-label="sort"><DragHandleIcon /></IconButton>}
            onChange={handleChange}
        />
    )
    
}

const MenuEditor = ({formData, buttons, onChange, ...props}) => {

    return (
        <div>
            <MenuEditorList formData={formData} onChange={onChange} />
            <footer>
                { buttons && buttons.map((button, index) => {
                    return (
                        <Button onClick={button.onClick}>{button.label}</Button>
                    )
                })}
            </footer>
        </div>
    )
    
}

export default MenuEditor;
