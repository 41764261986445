import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { bulkReset, bulkEdit, bulkEraseAll } from '../redux/functions/bulk';
import { saveModel, deleteModel, eraseModel, restoreModel } from '../redux/functions/model';

import { SchemaBase, getUiPreview } from '@frontend-components/admin';
import registry from "./components/registry"
import schemasByName from "./schemas"

import BulkPreview from "./BulkPreview"

const BulkEditor = ({app, bulkCount, bulkList = [], bulkModels = [], modelsById, ...props}) => {
    const { t, i18n } = useTranslation('editor');

    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const [bulkData, setBulkData] = useState(undefined)
    const [bulkType, setBulkType] = useState(undefined)
    const [bulkSchema, setBulkSchema] = useState(null)

    const [bulkStatus, setBulkStatus] = useState(null)

    const getBulkData = ({bulkData = {}, formData = {}, schema = {}}) => {

        if (!schema) {
            return bulkData
        }

        schema && schema.properties && Object.keys(schema.properties).map(name => {

            const schemaProps = schema && schema.properties && schema.properties[name]

            if (schemaProps && schemaProps.type === "object") {
                bulkData[name] = getBulkData({bulkData: bulkData[name], formData: formData[name], schema: schemaProps})
            } else if (bulkData[name] && bulkData[name] !== formData[name]) {
                bulkData[name] = "**multiple**"
            } else if (formData[name]) {
                bulkData[name] = formData[name]
            }
    
        })
        
        return bulkData
    
    }

    const getBulkEditor = () => {
        let newBulkData
        let newBulkType
        let newBulkSchema

        bulkModels.map((model, index) => {

            const uniqueModel = modelsById[uniqueId] || model || {}

            const { uniqueId, modelName, mediaType, documentType } = uniqueModel
    
            let modelType;
    
            if (modelName === "media" || mediaType) {
                modelType = "media"
            } else if (modelName === "documents" || documentType) {
                modelType = "documents"
            }
    
            if (newBulkType && modelType && newBulkType !== modelType) {
                newBulkType = "multiple"
            } else if (modelType) {
                newBulkType = modelType
            } else {
                newBulkType = "multiple"
            }

            newBulkSchema = newBulkType && schemasByName[newBulkType]
            newBulkData = getBulkData({bulkData: newBulkData, formData: uniqueModel, ...newBulkSchema})


            
        })    

        setBulkData(newBulkData)
        setBulkType(newBulkType)
        setBulkSchema(newBulkSchema)

        setBulkStatus(newBulkData.status)

    }

    useEffect(() => {
        getBulkEditor()
    }, [bulkCount])
    
    if (!bulkSchema || !bulkData) {

        return (
            <p>Missing bulkSchema for {bulkType}</p>
        )
    
    }

    const languages = app.languages || ["en"]
    const defaultLocale = languages[0]

    let title;

    if (bulkCount > 1) {
        title = bulkCount + " models"
    } else {
        title = bulkModels[0] && bulkModels[0].title
    }

    const handleChange = ({formData}) => {
        setIsLoading(true)
        setBulkData(formData)
        setIsLoading(false)
    }

    const handleEraseAll = () => {

        const query = {
            siteId: app && app.siteId,
            collectionId: app && app.collectionId,
        }

        if (bulkType === "documents") {
            props.bulkEraseAll({...query, models: "documents"})
        }

    }

    const handleErase = () => {
        bulkType && bulkList.map(uniqueId => {
            props.eraseModel(bulkType, uniqueId)
        })
    }

    const handleDelete = () => {
        bulkType && bulkList.map(uniqueId => {
            props.deleteModel(bulkType, uniqueId)
        })
    }

    const handleRestore = () => {
        bulkType && bulkList.map(uniqueId => {
            props.restoreModel(bulkType, uniqueId)
        })
    }

    const handleSubmit = (status) => {
        console.log('save:bulkData', bulkData)

        if (status) {
            setBulkStatus(status)
            setBulkData({
                ...bulkData,
                status: status
            })
        }

        const newModels = bulkType && bulkList.map(uniqueId => {

            const uniqueModel = modelsById && modelsById[uniqueId]
            const newStatus = status || bulkData.status || uniqueModel.status

            const newContent = {
                ...uniqueModel.content,
                ...bulkData.content
            }

            return {
                uniqueId: uniqueId,
                status: newStatus,
                content: newContent
            }

        })

        bulkType && newModels.map(model => {
            props.saveModel(bulkType, model, false)
        })
    }

    const handleReset = () => {
        props.bulkReset()
    }

    const getEditAction = () => {

        let deleteAction

        if (bulkStatus === "trash") {
            deleteAction = {
                role: "group",
                children: [
                    {
                        label: t("Restore"),
                        onClick: () => handleRestore(),
                    },
                    {
                        label: t("Erase"),
                        onClick: () => handleErase(),
                    }
                ]
            }
        } else {
            deleteAction = {
                role: "group",
                label: t("Move to trash"),
                onClick: () => handleDelete(),
            }
        }

        return {
            type: "save",
            label: t("Save"),
            onClick: () => handleSubmit(),
            children: [
                {
                    role: "group",
                    children: [
                        {
                            label: t("Save as draft"),
                            onClick: () => handleSubmit('draft'),
                        },
                        {
                            label: t("Save and publish"),
                            onClick: () => handleSubmit('publish'),
                        }
                    ]
                },
                deleteAction
            ]
        }

    }    

    const getTrashAction = () => {

        return {
            type: "save",
            label: t("Empty trash"),
            onClick: () => handleEraseAll()
        }

    }

    let primaryAction, secondaryAction

    if (isLoading) {
        primaryAction = {
            label: t("Loading") + " ...",
            disabled: true
        }
    } else if (isSaving) {
        primaryAction = {
            label: t("Saving") + " ...",
            disabled: true
        }
    } else if (bulkStatus === "trash") {
        primaryAction = getEditAction()
        secondaryAction = getTrashAction()
    } else {
        primaryAction = getEditAction()
    }

    const formContext = {
        languages: languages,
        defaultLocale: defaultLocale,
        title: title,
        preview: {
            template: BulkPreview
        },
        primaryAction: primaryAction,
        secondaryAction: secondaryAction,
        onClose: handleReset
    }

    const { schema, uiSchema } = bulkSchema

    const renderDebug = () => {

        return (
            <div style={{position: "absolute", bottom: "100px", left: 0, right: 0}}>
                <hr />
                {JSON.stringify(schema)}
                <hr />
                {JSON.stringify(bulkData)}
            </div>
        )

    }

    return (
        <div>
            <SchemaBase {...registry} 
                schema={schema}
                uiSchema={uiSchema}
                formData={bulkData}
                formContext={{
                    ...formContext,
                    primaryAction: primaryAction
                }}
                onChange={handleChange} />
        </div>
    )
    
}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        bulk: state.bulk,
        bulkCount: state.bulk.count,
        bulkList: state.bulk.list,
        bulkModels: state.bulk.models,
        modelsById: state.modelsById
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      bulkReset, bulkEdit, bulkEraseAll,
      saveModel,
      eraseModel, deleteModel, restoreModel
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkEditor);