import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Settings from "./Settings"
import SizeSettings from "./SizeSettings"
import GridSettings from "./GridSettings"
import SkinSettings from "./SkinSettings"
import AlignSettings from "./AlignSettings"
import ValignSettings from "./ValignSettings"
import PlacementSettings from "./PlacementSettings"

import ColorSettings from "../Color/ColorSettings"
import TextColorSettings from "../Color/TextColorSettings"


const templates = {
    "list": Settings,
    "grid": GridSettings,
    "size": SizeSettings,
    "skin": SkinSettings,
    "align": AlignSettings,
    "valign": ValignSettings,
    "placement": PlacementSettings,
    "color": ColorSettings,
    "textColor": TextColorSettings
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
}));

const SettingsItem = ({name, type, ...props}) => {

    let SettingsTemplate;

    if (type && templates[type]) {
        SettingsTemplate = templates[type]
    } else if (name && templates[name]) {
        SettingsTemplate = templates[name]
    } else {
        SettingsTemplate = templates["list"]
    }

    return (
        <SettingsTemplate {...props} name={name} type={type} />
    )
        
}

const NavSettings = ({className, settings = [], onSettings}) => {

    const classes = useStyles()

    if (!settings.length) {
        return false
    }

    return (
        <nav className={className || classes.root}>
            { settings && settings.map((button, index) => (
                <SettingsItem {...button} onSettings={onSettings} key={index} />
            ))}
        </nav>
    )

}

export default NavSettings