import program from "./program/schema"
import group from "./group/schema"
import order from "./order/schema"
import payment from "./payment/schema"

export default {
    "type": "object",
    "properties": {
        "content": {
            "type": "object",
            "properties": {
                "title": {
                    "type": "string"
                },
                "adults": {
                    "type": "number"
                },
                "children": {
                    "type": "number"
                },
                "free": {
                    "type": "number"
                },
                "language": {
                    "type": "string",
                    "enum": ["no","en"],
                    "enumNames": ["Norsk","English"]
                },
                "options": {
                    "type": "array",
                    "items": {
                        "type": "string",
                        "enum": [
                            "multilingual",
                            "disability",
                            "disclosure"
                        ],
                        "enumNames": [
                            "Flere språk",
                            "Funksjonsnedsettelse",
                            "Tilleggsopplysning"
                        ],
                    },
                    "uniqueItems": true
                },
                "program": program,
                "order": order,
                "payment": payment
            }
        }
    }
}