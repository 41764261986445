import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1)
    },
    group: {
        display: "flex",
        flexDirection: props => { return props.direction },
    }
}));

const CheckboxesField = ({id, disabled, label, helperText, options = [], value = [], autofocus, readonly, onChange, ...props}) => {

    const classes = useStyles()

    const handleUnselect = (value, selected = []) => {
        return selected.filter(v => v !== value);
    }

    const handleSelect = (value, selected = [], all) => {
        const at = all.indexOf(value)
        const updated = selected.slice(0, at).concat(value, selected.slice(at));
        return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
    }

    const handleChange = (event, option) => {

        let newValue = []

        const all = options.map(({value}) => value)

        if (event.target.checked) {
            newValue = handleSelect(option.value, value, all);
        } else {
            newValue = handleUnselect(option.value, value);
        }

        onChange(newValue)

    }

    return (
        <FormControl className={classes.root} component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup>
                {options.map((option, index) => {
                    return (
                        <FormControlLabel
                        control={<Checkbox checked={value.includes(option.value)} color="primary" onChange={(event) => handleChange(event, option)} value={option.value} />}
                        label={option.label || option.value}
                        />
                    )
                })}
            </FormGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText> }
        </FormControl>
    )
  
}
  
CheckboxesField.defaultProps = {
    autofocus: false,
    options: {
      inline: false,
    },
};
  
CheckboxesField.propTypes = {

}
  
export default CheckboxesField;