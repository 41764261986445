import collectionType from './collectionType';
import documentTypes from './documentTypes/schema';
import languages from "./languages/schema"
import theme from "./theme/schema"
import menu from "./menu/schema"
import edit from "./edit/schema"
import sidebar from "./sidebar/schema"
import search from "./search/schema"
import upload from "./upload/schema"

export default {
    type: 'object',
    properties: {
        title: {
            type: "string",
        },
        name: {
            type: "string",
        },
        siteId: {
            type: "number",
        },
        siteName: {
            type: "string",
        },
        collectionType: {
            type: "string",
        },
        defaultLocale: {
            type: "string",
            default: "en"
        },
        content: {
            type: "object",
            properties: {
                icon: {
                    type: "image",
                },
                name: {
                    type: "string",
                },
                title: {
                    type: "string",
                },
                description: {
                    type: "string",
                },
                collectionType: collectionType,
                documentTypes: documentTypes,
                siteId: {
                    type: "number",
                    enum: [0]
                },
                image: {
                    type: "image"  
                },
                theme: theme,
                sidebar: sidebar,
                search: search,
                upload: upload,
                languages: languages,
                edit: edit,
                menu: menu
            }
        }
    }        
}