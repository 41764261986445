import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getUrlFromProps } from "../../resources/functions/"

import {
    isSelect,
    optionsList,
} from "../Schema/utils/"

import UserIcon from "../User/UserIcon"
import OwnerIcon from "../Owner/OwnerIcon"

import {
    ModuleBase,
    ModuleLink,
    ModuleMedia,
    ModuleHeader,
    ModuleFooter,
    ModuleTitle,
    ModuleDescription,
    ModuleLabel,
    ModuleMetadata,
    ModuleStatus,
    ModuleByline,
    ModuleActionbar,
//    NavSettings
} from '../Module/';

import AuthOptions from "./AuthOptions"

import "./AuthModule.scss"

class AuthModule extends Component {
    static propTypes = {
        expanded: PropTypes.string,
        editable: PropTypes.string,
        editing: PropTypes.string,
        removable: PropTypes.string,
        authType: PropTypes.string,
        authOptions: PropTypes.array,
        authName: PropTypes.string,
        authValue: PropTypes.string,
        url: PropTypes.string,
        urlPattern: PropTypes.string,
        editUrl: PropTypes.string,
        editUrlPattern: PropTypes.string,
    }

    static defaultProps = {
        url: undefined,
        urlPattern: "/auth/:uniqueId",
        editUrl: undefined,
        editUrlPattern: undefined,
        moduleType: "auth",
        moduleLayout: "table",
        moduleSize: "auto",
        label: undefined,
        labelColor: undefined,
        action: ["edit","remove","select"],

        authorize: false,

        authName: "authId",
        authValue: undefined,
        authOptions: undefined,
        authStatus: undefined,

        formData: {
        },
        schema: {
        }
    }
    
    onAuthChange = (value) => {

        if (this.props.onAuth) {
            this.props.onAuth(value)
        }
        
    } 

    getProps = () => {
        let { metadata, name, title, url, urlPattern, editUrl, editUrlPattern } = this.props;
        
        url = url || getUrlFromProps(this.props, urlPattern)
        editUrl = editUrl || getUrlFromProps(this.props, editUrlPattern)
        
        return {
            ...this.props,
            url: url,
            editUrl: editUrl,
            title: title || name,
            metadata: metadata
        }
        
    }
    
    getAuthSchemaOptions = (schema) => {
        const options = isSelect(schema) && optionsList(schema);
    
        if (!options) {
            return false
        }        

        return options
    }
    
    getAuthOptions = () => {
        let { schema, formData, authName, authValue, authOptions } = this.props;
        
        if (schema && schema.properties && schema.properties[authName]) {
            authOptions = this.getAuthSchemaOptions(schema.properties[authName])
        }

        authValue = formData[authName] || this.props[authName] || authValue || undefined
        
        return {
            options: authOptions,
            value: authValue
        }
        
    }
    
    renderFooter = () => {
        const { authorize, authStatus, status } = this.props;
        
        const authOptions = this.getAuthOptions()
        
        if (authOptions) {
            return (
                <ModuleFooter>
                    <AuthOptions {...authOptions} onChange={this.onAuthChange} /> {' '}
                    <ModuleStatus status={authStatus} />
                </ModuleFooter>
            )
        }
        
        return (
            <ModuleFooter>
                <ModuleStatus status={status} /> {' '}
                <ModuleByline {...this.props} name={false} />
            </ModuleFooter>
        )
        
    }
    
    renderHeader = () => {
        const { authType, theme, abbr, title, name, email, metadata, icon } = this.props;
        
        if (authType === "owner" || authType === "site") {
            return (
                <ModuleHeader>
                    <OwnerIcon {...this.props} abbr={abbr || name} title={name} theme={theme} />
                    <div>
                        <ModuleTitle title={title || name} /> {' '}
                        <ModuleLabel {...this.props} /> {' '}
                        <ModuleMetadata metadata={metadata} />
                    </div>
                </ModuleHeader>
            )
        }

        if (authType === "app") {
            return (
                <ModuleHeader>
                    <ModuleMedia {...this.props} svg={icon} mediaType="svg"/>
                    <div>
                        <ModuleTitle title={title || name} /> {' '}
                        <ModuleLabel {...this.props} /> {' '}
                        <ModuleMetadata metadata={metadata} />
                    </div>
                </ModuleHeader>
            )
        }

        if (authType === "user") {
            return (
                <ModuleHeader>
                    <OwnerIcon {...this.props} />
                    <div>
                        <ModuleTitle title={title || name} /> {' '}
                        <ModuleLabel {...this.props} /> {' '}
                        <ModuleDescription description={email} />
                        <ModuleMetadata metadata={metadata} />
                    </div>
                </ModuleHeader>
            )
        }
        
        return (
            <ModuleHeader>
                <ModuleMedia {...this.props} />
                <div>
                    <ModuleTitle title={title} /> {' '}
                </div>
            </ModuleHeader>
        )
        
    }
    
    render() {
        const { url, editUrl, onClick } = this.getProps();

        return (
            <ModuleBase { ...this.props }>
                    <ModuleLink url={url} onClick={onClick}>
                        { this.renderHeader() }
                    </ModuleLink>
                    { this.renderFooter() }
                <ModuleActionbar {...this.props} editUrl={editUrl} />
            </ModuleBase>
        )
    }
}

export default AuthModule;
