import React, { Component } from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { makeStyles } from '@material-ui/core/styles';

import {
    LayoutGrid,
    NavToolbar,
} from "@frontend-components/admin"

import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "white",
        "&[aria-expanded=true]": {
            padding: theme.spacing(2)
        }
    },
    footer: {
        marginTop: theme.spacing(2)
    }
}));

const DraggableHandle = sortableHandle(() => {
    return (
        <DragHandleIcon />
    )
})

const EditListItem = ({ expanded, formData, title, description, status, onClick, onEdit, onSave, children, ...props }) => {
    const classes = useStyles()

    if (expanded) {
        return (
            <li className={classes.root} aria-expanded={true}>
                <LayoutGrid spacing={1}>
                    {children}
                </LayoutGrid>
                <footer className={classes.footer}>
                    <Button color="primary" size="large" variant="outlined" onClick={onSave}>Lagre og lukk</Button>
                </footer>
            </li>
        )
    }

    return (
        <MenuItem className={classes.root} onClick={onEdit}>
            <ListItemIcon>
                <DraggableHandle {...props} />
            </ListItemIcon>
            <ListItemText primary={title} secondary={description || formData.type} />
            <ListItemSecondaryAction>
                <NavToolbar {...props} />
            </ListItemSecondaryAction>
        </MenuItem>
    )    

}

export default EditListItem;
