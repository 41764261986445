// SEARCH

export const REQUEST_REFERENCES = 'REQUEST_REFERENCES'
export const RECEIVE_REFERENCES = 'RECEIVE_REFERENCES'

export function requestReferences(id) {

  return {
    type: REQUEST_REFERENCES,
    id: id,
  }
  
}

export function receiveReferences(id, data) {
  
  return {
    type: RECEIVE_REFERENCES,
    id: id,
    data: data,
  }
  
}

