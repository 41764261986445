import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VolumeOffIcon from '@material-ui/icons/VolumeMute';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
}));

const MediaButtonPlayPause = ({className, muted, onClick}) => {
    const classes = useStyles()

    if (muted) {
        return (
            <IconButton className={className || classes.root} onClick={onClick}>
                <VolumeOffIcon />
            </IconButton>
        )
    }

    return (
        <IconButton className={className || classes.root} onClick={onClick}>
            <VolumeUpIcon />
        </IconButton>
    )

}

export default MediaButtonPlayPause