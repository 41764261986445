import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReports } from '../redux/functions/reports';

import { AnalyticsReportCountries } from '@frontend-components/admin';

import { FormattedMessage } from "react-intl";
import messages from "./ReportSessionsByCountry.messages";

class ReportSessionsByCountry extends Component {

    static defaultProps = {
        loadingTitle: "Besøk etter land",
        title: "Besøk etter land",
        query: {
            countryId: "no",
            offset: 0,
            owner: 1,
            reportType: 0,
            rows: 1000,
            startDate: "90daysAgo",
            endDate: "yesterday",
            dimensions: "countryIsoCode,country",
            metrics: "users",
            orderBy: "countryIsoCode",
            sort: "descending",
        }
    }
    
    state = {
    }
    
    componentDidMount() {
        this.getReport()
    }
    
    getReport = () => {
        const { id, query } = this.props;
        
        this.props.getReports({
            ...query,
            id: id
        })

        this.setState({
            loadingTitle: <FormattedMessage {...messages['loadingTitle']} />,
            title: <FormattedMessage {...messages['title']} />
        })
        
    }
    
    render() {
        const { theme, data, id, widgetSize } = this.props;
        const report = data[id] && data[id].reports && data[id].reports && data[id].reports[0]
        
        return (
            <AnalyticsReportCountries {...this.props} {...this.state} report={report || undefined} theme={theme} />
        )
        
    }

}

function mapStateToProps(state) {
	return {
    	theme: state.app.theme,
    	data: state.reports
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getReports
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportSessionsByCountry);