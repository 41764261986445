import React, { Component } from 'react';

import "./FieldHelp.scss";

class FieldHelp extends Component {
    render() {
        const { help } = this.props;

        if (!help) {
            return false;
        }

        return (
            <p className="admin-field__help">{help}</p>
        )
    }
}

export default FieldHelp;