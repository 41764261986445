import editGroup from "./editGroup"

export default {
    "type": "object",
    "properties": {
        "title": {
            "type": "string",
        },
        "type": {
            "type": "string",
            "enum": ["parent", "create", "upload"],
            "default": "parent"
        }
    },
    "dependencies": {
        "type" : {
            "oneOf": [
                {
                    "properties": {
                        "type": {
                            "enum": ["parent"]
                        },
                        "children": {
                            "type": "array",
                            "items": editGroup
                        }
                    }
                },
                {
                    "properties": {
                        "type": {
                            "enum": ["create"]
                        },
                        "icon": {
                            "type": "string",
                        },
                        "documentType": {
                            "type": "string"
                        }
                    }
                },
                {
                    "properties": {
                        "type": {
                            "enum": ["upload"]
                        },
                        "icon": {
                            "type": "string",
                        },
                        "mediaType": {
                            "type": "string"
                        }
                    }
                }
            ]
        }
    }
}    