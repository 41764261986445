import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WidgetScroller from "../Widget/WidgetScroller"

// import "./FinderBody.scss"

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: 1,
        width: "100%",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        padding: props => { return theme.spacing(props.padding) }
    },
    scroller: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    body: {
        display: "block",
        width: "100%",
        paddingTop: theme.spacing(8),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    }
}));

const FinderBody = ({padding = 2, children}) => {
    const classes = useStyles({padding})

    return (
        <div className={classes.root} >
            {children}
        </div>
    )

    return (
        <div className={classes.root}>
            <WidgetScroller className={classes.scroller}>
                <div className={classes.body}>
                    {children}
                </div>
            </WidgetScroller>
        </div>
    )

}

export default FinderBody;