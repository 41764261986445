import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabsTab extends Component {

    static defaultProps = {
        className: "admin-tabs__tab",
        focus: false,
        id: null,
        panelId: null,
        selected: false,
    };
  
    render() {
        const {
            children,
            className,
            disabled,
            disabledClassName,
            focus, // unused
            id,
            panelId,
            selected,
            selectedClassName,
            tabIndex,
            tabRef,
            name,
            value,
            ...attributes
        } = this.props;
        
        return (
            <button
                {...attributes}
                className={className} role="tab"
                ref={node => {
                this.node = node;
                if (tabRef) tabRef(node);
                }}
                role="tab"
                name={name}
                id={id}
                value={value}
                aria-selected={ selected ? 'true' : 'false' }
                aria-disabled={ disabled ? 'true' : 'false' }
                aria-controls={ panelId }
                tabIndex={ tabIndex || (selected ? '0' : null) }
                onClick={ this.onSelect }>
                { this.props.children }
            </button>
        )
    
    }
  
}

export default TabsTab;