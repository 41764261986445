export function isLocalizedString(schema) {
    if (schema.type === "object" && schema.properties) {
        const keys = Object.keys(schema.properties);

        if (keys[0]) {
            const test = keys[0].split(':');

            if (test[0] === "locale") {
                return true;
            }
        }
    }

    return false;
}

export function getLocalizedString(schema, props) {

    const { languages } = props.formContext;

    let properties = {}
    
    if (languages.length) {

        languages.map((language, index) => {
            
            const name = "locale:"+language;
            
            properties[name] = {
                "type": "string"
            }
                    
        })
        
    } else {
        
        properties = {
            "locale:en": {
                "type": "string"
            }
        }
        
    }

    schema = {
        "type": "object",
        "properties": properties
    }

    return schema;
}

export function getLocalizedStringUi(props) {
    
    const { uiOptions, formContext } = props;

    let language;
    
    if (formContext.language) {
        language = formContext.language
    }

    let { layout, field } = uiOptions;

    if (!layout) {
        layout = "field";
    }

    if (!field) {
        field = "localizedString";
    }
    
    return {
        ...uiOptions,
        language: language,
        layout: layout,
        field: field
    }

}

