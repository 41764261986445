import React, { Component } from 'react';

import {
    getWidget,
    getUiOptions,
    getDefaultFormState,
    optionsList,
    retrieveSchema,
} from "../utils";

const ArrayFieldSelect = (props) => {
    const {
      schema,
      idSchema,
      uiSchema,
      formData,
      disabled,
      readonly,
      required,
      label,
      placeholder,
      autofocus,
      onBlur,
      onFocus,
      registry,
      rawErrors,
      onChange
    } = props;

    const items = getDefaultFormState(schema, props.formData);
    const { widgets, definitions, formContext } = registry;
    const itemsSchema = retrieveSchema(schema.items, definitions, formData);
    const enumOptions = optionsList(itemsSchema);
    const { widget = "select", ...options } = {
      ...getUiOptions(uiSchema),
      enumOptions,
    };

    const Widget = getWidget(schema, widget, widgets);
    const { SchemaLayout } = registry.fields
    
    return (
        <SchemaLayout {...props} uiSchema={{...uiSchema, "ui:layout": "field"}}>
            <Widget
                id={idSchema && idSchema.$id}
                multiple
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                options={options}
                schema={schema}
                registry={registry}
                value={items}
                disabled={disabled}
                readonly={readonly}
                required={required}
                label={label}
                placeholder={placeholder}
                formContext={formContext}
                autofocus={autofocus}
                rawErrors={rawErrors}
        />
      </SchemaLayout>
    );
  }

/*

class ArrayFieldSelect extends Component {

    onSelectChange = (value) => {
        this.props.onChange(value);
    }

    render() {

        const {
            schema,
            idSchema,
            uiSchema,
            formData,
            disabled,
            readonly,
            autofocus,
            onBlur,
            onFocus,
            registry,
            rawErrors,
        } = this.props;

        const items = this.props.formData;
        const { widgets, definitions, formContext } = registry;
        const itemsSchema = retrieveSchema(schema.items, definitions, formData);
        const enumOptions = optionsList(itemsSchema);
        const { widget = "select", ...options } = {
            ...getUiOptions(uiSchema),
            enumOptions,
        };
        const Widget = getWidget(schema, widget, widgets);
        
        const uiOptions = {
            ...this.props.uiOptions,
            addable: false
        }

        const { SchemaLayout } = registry.fields

        return (
            <SchemaLayout {...this.props} uiSchema={{...uiSchema, "ui:layout": "field"}} uiOptions={uiOptions}>
                <Widget
                    id={idSchema && idSchema.$id}
                    multiple={true}
                    onChange={this.onSelectChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    options={{ ...options, enumOptions }}
                    schema={schema}
                    value={items || []}
                    disabled={disabled}
                    readonly={readonly}
                    formContext={formContext}
                    autofocus={autofocus}
                    rawErrors={rawErrors}
                />
            </SchemaLayout>
        );


    }
}

*/

export default ArrayFieldSelect;