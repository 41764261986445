export default {
    "select": {
        "title" : "title",
        "description" : "description",
        "label": "label"
    },
    prepare({formData}) {
        let { title, content, description } = formData

        title = content && content.name || title;

        const languages = content && content.languages;

        let metadata = []

        if (languages && languages.length) {
            metadata.push(languages.length + " språk")
        }

        return {
            title: title,
            description: metadata.join(' – '),
            label: "Person"
        }

    }
  
}