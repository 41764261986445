import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AutocompleteBase from "./AutocompleteBase"
import Icon from '@material-ui/core/Icon';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import ISO6391 from 'iso-639-1';

const getOptions = () => {
    let options = []

    const codes = ISO6391.getAllCodes()

    codes.map((code) => {
        options.push({
            label: ISO6391.getName(code),
            nativeLabel: ISO6391.getNativeName(code),
            value: code
        })
    })

    return options
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        "& * + *": {
            marginLeft: theme.spacing(2)
        }
    },
    icon: {
    },
    label: {
        fontFamily: "Akkurat, sans-serif"
    },
}));

const IsoLanguageField = ({
    id,
    label = "Select language",
    helperText,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder
}) => {

    const emptyValue = multiple ? [] : "";
    const options = getOptions()

    const handleChange = (event, option) => {
        onChange(option.value, event);
    }

    const renderOption = (option, state) => {
        const { value, label, nativeLabel } = option
        return (
            <div>
                <p>{ label } ({value})</p>
                <p>{ nativeLabel }</p>
            </div>
        )
    }

    return (
        <AutocompleteBase
            id={id}
            placeholder={placeholder}
            multiple={multiple}
            options={options}
            label={label}
            helperText={helperText}
            value={typeof value === "undefined" ? emptyValue : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            renderOption={renderOption}
            onChange={handleChange}>
        </AutocompleteBase> 
    )

}

IsoLanguageField.defaultProps = {
    autofocus: false,
};

IsoLanguageField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default IsoLanguageField;
