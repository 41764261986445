export default {
    "type": "object",
    "properties": {
        "parentId": {
            "type": "number",
        },
        "content": {
            "type": "object",
            "properties": {
                "parentId": {
                    "type": "number",
                    "default": undefined
                },
                "title": {
                    "type": "string"
                },
                "description": {
                    "type": "string"
                },
                "resources": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            },
                            "capacity": {
                                "type": "number",
                            }
                        }
                    }
                },
                "program": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "title": {
                                "type": "string"
                            }
                        }
                    }
                },
                "products": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "title": {
                                "type": "string"
                            },
                            "price": {
                                "type": "number"
                            }
                        }
                    }
                },
                "people": {
                    "type": "array",
                    "items": {
                        "type": "reference",
                        "referenceType": "people",
                        "documentType": "person",
                        "_reverseReferenceType": "place",
                        "_reverseReference": "places[]"
                    }
                },
                "children": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "uniqueId": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        }
    }
}