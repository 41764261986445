import React, { Component } from 'react';
import ReactPlayer from 'react-player'

// Class

class PreviewVideo extends Component {
  
  getResource = (resource) => {
    
    const { mimeType, url } = resource
    
    return {
      type: mimeType,
      src: url
    }
    
  }
  
  getUrls = () => {

    const { media } = this.props.formData;
    const { resources } = media;
    
    const urls = []
    
    if (resources['mp4']) {
      urls.push(this.getResource(resources['mp4']))
    }

    if (resources['webm']) {
      urls.push(this.getResource(resources['webm']))
    }
    
    return urls;
    
  }
  
  render() {
    
    const urls = this.getUrls();
    
    return (
      
      <ReactPlayer className='video' url={ urls } controls={ true } />
      
    )
    
  }
  
}

export default PreviewVideo;