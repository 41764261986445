import React from 'react';
import PropTypes from "prop-types"
import TagsField from "./TagsField"

const TagsWidget = ({id, disabled, readonly, options, value = [], autofocus, onChange, ...props}) => {

    const { enumOptions, enumDisabled } = options;
    const label = options.title || props.title || props.label || "bool"
    const placeholder = options.placeholder || null
    const helperText = options.help || null

    const autocompleteOptions = enumOptions.map((option, index) => {
        const selected = value.indexOf(option.value) !== -1;
        const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;

        return {
            label: option.label,
            value: option.value,
            disabled: disabled || itemDisabled || readonly,
        }

    });


    return (
        <TagsField {...props} disabled={disabled || readonly} label={label} placeholder={placeholder} helperText={helperText} value={value} options={autocompleteOptions} onChange={onChange} />
    )

}

TagsWidget.defaultProps = {
    autofocus: false,
    options: {
      inline: false,
    },
};
  
TagsWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.array,
        inline: PropTypes.bool,
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
}
  
export default TagsWidget
