import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FinderBase, FinderHeader, FinderTitle, FinderBody, NavPath } from "../"
import { InboxResults, InboxEditor } from "../"

class LayoutInbox extends Component {
    static propTypes = {
        /** Title */
        title: PropTypes.string,
        /** Parents */
        parents: PropTypes.array,
        /** Section */
        section: PropTypes.string,
        /** Category */
        category: PropTypes.string,
        /** Results */
        results: [],
        /** FormData */
        item: {}
    }

    static defaultProps = {
    }
    
    renderHeader = () => {
        const { title, parents } = this.props;
        
        if (parents) {
            
            return (
                <FinderHeader>
                    <NavPath parents={parents} />
                </FinderHeader>
            )
            
        }
        
        return (
            <FinderHeader>
                <FinderTitle title={title} />
            </FinderHeader>
        )
        
    }
    
    renderBody = () => {
        const { results, item } = this.props;
        
        const style = {
            paddingLeft: 0,
            paddingRight: 0
        }
        
        if (this.props.children) {

            return (
                <FinderBody style={style}>
                    { this.props.children }
                </FinderBody>
            )
            
        }
        
        if (item && item.uniqueId) {
            
            return (
                <FinderBody style={style}>
                    <InboxEditor {...this.props} formData={item} />
                </FinderBody>
            )

        }

        if (results) {
            return (
                <FinderBody style={style}>
                    <InboxResults {...this.props} results={results} />
                </FinderBody>
            )
        }

        return (
            <FinderBody style={style}>
                { this.props.children }
            </FinderBody>
        )
        
    }
    
    render() {

        return (
            <FinderBase>
                { this.renderHeader() }
                { this.renderBody() }
            </FinderBase>
        )
    }

}

export default LayoutInbox;