import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
    return (
        <SvgIcon>
            <path d="M9 12C9 13.6569 10.3431 15 12 15V15C13.6569 15 15 13.6569 15 12V12C15 10.3431 13.6569 9 12 9V9C10.3431 9 9 10.3431 9 12V12Z" fill="black"/>
            <path d="M20 21L20 19L4 19L4 21L20 21Z" fill="black"/>
            <rect x="4" y="3" width="16" height="2" fill="black"/>
        </SvgIcon>
    );
}