import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadModel, editModel, saveModel } from '../redux/functions/model';

import AppEditor from "../AppEditor"
import profile from "./schemas/profile"

import { useTranslation } from 'react-i18next';

const EditProfile = ({app, user, model = "users", formData, ...props}) => {
    const { t, i18n } = useTranslation(['editor']);

    useEffect(() => {
        props.loadModel(model, user.uniqueId)
    }, [])

    const defaultLocale = formData && formData.content && formData.content.defaultLocale;

    useEffect(() => {
        i18n.changeLanguage(defaultLocale);
    }, [defaultLocale])

    const uniqueId = formData && formData.uniqueId;

    const onSubmit = () => {
        console.log(formData)

        props.saveModel(model, {
            uniqueId: uniqueId,
            ...formData,
        })

        props.history.push('/')
    }


    const schema = profile.schema
    const uiSchema = profile.uiSchema

    if (!schema || !uiSchema || !uniqueId) {
        return <p>Loading schema</p>
    }

    const formContext = {
        uniqueId: uniqueId,
        onBack: () => props.history.push('/'),
        primaryAction: {
            label: t("Save"),
            onClick: onSubmit,
        }
    }

    return (
        <AppEditor {...props}
            uniqueId={uniqueId}
            schemaId="user"
            schema={schema}
            uiSchema={uiSchema}
            formContext={formContext}
        />
    )

}

// mapStateToProps

function mapStateToProps(state) {
	return {
        app: state.app,
        user: state.user,
        formData: state.editor.formData
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        loadModel, editModel, saveModel
    }, 
dispatch);

// export default

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditProfile);

