import { getUiOptions } from './';

export const getUploadButton = ({schema, button, onUpload}) => {
    let itemSchema, multiple

    if (schema.type === "array") {
        multiple = true
        itemSchema = schema.items
    } else {
        multiple = false
        itemSchema = schema
    }

    if (!itemSchema || !itemSchema.properties) {
            
        return {
            label: "failed"
        }
        
    }
    
    let mediaType;
    
    if (itemSchema.properties.media 
        && itemSchema.properties.media.properties 
        && itemSchema.properties.media.properties.mediaType 
        && itemSchema.properties.media.properties.mediaType.default) {
            mediaType = itemSchema.properties.media.properties.mediaType.default
    }

    let accept = button.accept || undefined

    if (!accept && mediaType) {
        
        if (mediaType === "image") {
            accept = "image/*"
        }

        if (mediaType === "video") {
            accept = "video/*"
        }

        if (mediaType === "audio") {
            accept = "audio/*"
        }
        
    }

    if (!onUpload) {
        return ({
            ...button,
            disabled: true
        })
    }

    return ({
        ...button,
        type: "upload",
        accept: accept,
        multiple: multiple,
        onUpload: onUpload
    })

}

export const getDialogButton = ({schema, button, onDialog, type, registry, ...props}) => {
    let itemSchema;

    if (schema.type === "array") {
        itemSchema = schema.items
    } else {
        itemSchema = schema
    }

    if (!onDialog) {
        return ({
            ...button,
            disabled: true
        })
    }

    return {
        ...button,
        onClick: (event) => onDialog(event, button)
    }

}

export const getButton = ({schema, button, onAdd, onUpload, onDialog, onSave, onRemove, onDelete, onRestore, ...props}) => {

    if (typeof button === "string") {
        button = {
            type: button,
            label: button.charAt(0).toUpperCase() + button.slice(1)
        }
    }

    const { type, item, onClick } = button;

    if (onClick) {
        return button
    }

    if (type === "upload") {
        button = getUploadButton({schema, button, onUpload, ...props})
   
    } else if (type === "save") {
        button = {
            ...button,
            onClick: () => onSave()
        }

    /*

    } else if (type === "remove") {
        button = {
            ...button,
            onClick: () => onRemove()
        }

    */

    } else if (type === "delete") {
        button = {
            ...button,
            onClick: () => onDelete()
        }

    } else if (type === "restore") {
        button = {
            ...button,
            onClick: () => onRestore()
        }

    } else if (type && type.startsWith("dialog") || type && type.startsWith("sidebar")) {
        button = getDialogButton({schema, button, onDialog, type, ...props})

    } else if (onAdd && item) {
        button = {
            ...button,
            onClick: () => onAdd(item)
        }
    } else if (onAdd) {
        button = {
            ...button,
            onClick: () => onAdd()
        }
    } 

    return button

}

export const getArrayButtons = ({buttons = [], canAdd, onAddItem, ...props}) => {

    if (!canAdd) {
        return false
    }

    let uiButtons = []

    const onAdd = canAdd && onAddItem

    if (buttons && buttons.length) {

        uiButtons = buttons.map((button) => {
            return getButton({button, onAdd, ...props})
        })

    } else if (onAdd) {

        buttons = ["add"]

        uiButtons = buttons.map((button) => {
            return getButton({button, onAdd, ...props})
        })

    }

    return uiButtons

}

export const getObjectButtons = ({buttons = [], onChange, ...props}) => {

    let uiButtons = []

    const onAdd = onChange

    if (buttons && buttons.length) {

        uiButtons = buttons.map((button) => {
            return getButton({button, onAdd, ...props})
        })

    } else if (props.editable) {

        buttons = ["save"]

        uiButtons = buttons.map((button) => {
            return getButton({button, onAdd, ...props})
        })

    }

    return uiButtons    
    
}

export const getUiButtons = ({schema = {}, ...props}) => {

    const { id, uiSchema, formContext } = props;
    const currentId = formContext && formContext.currentId;

    const { buttons, editable, nav } = getUiOptions(uiSchema);

    // dont show button if we're editing a child, unless there is navigation involved

    if (!nav && currentId && id && currentId !== id && currentId.includes(id)) {
        return false
    }

    if (schema.type === "array") {
        return getArrayButtons({schema, buttons, ...props})
    }

    if (schema.type === "object") {
        return getObjectButtons({schema, buttons, editable, ...props})
    }
  
}