import { getAppData } from '../../redux/functions/app';
import { getMenuChildren } from '../../AppCollection/app';
import schemas from "../schemas/"
import menuChildren from "./menu";
import { getPrimaryAction } from "./getPrimaryAction"

export function getApp(appData) {
    const { site, collection } = appData;

    const title = "Minner"
    const name = "minner"
    const root = "/" + site.name + "/" + name

    const appProps = {
        uniqueId: collection && collection.id,
        siteId: site && site.id,
        collectionId: collection && collection.id,
        root: root
    }
    
    const primaryAction = getPrimaryAction(appProps)
    const menu = getMenuChildren({children: menuChildren, ...appProps})

    const search = {
        "placeholder": "Søk i " + title
    }
    
    const app = {
        ...appData,
        ...appProps,
        title: title,
        name: name,
        layout: "finder",
        theme: {
            primaryColor: "#86a7bf"
        },
        primaryAction: primaryAction,
        search: search,
        menu: menu,
        menuTree: root,
        schemas: schemas,
        languages: [
            "no", "sv", "en"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
    }

}


