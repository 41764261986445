import uiPreview from "./uiPreview"
import settings from "./settings/uiSchema"
import documentTypes from './documentTypes/uiSchema';
import languages from "./languages/uiSchema";
import features from "./features/uiSchema";
import menu from "./menu/uiSchema";
import search from "./search/uiSchema";
import theme from "./theme/uiSchema";

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "ui:fieldset": [
        "content",
    ],
    "content": {
        "ui:field": "pageContent",
        "ui:nav": true,
        "ui:fieldset": [
            "settings",
            "documentTypes",
            "languages",
            "menu",
            "theme",
            "features",
        ],
        "settings": settings,
        "documentTypes": documentTypes,
        "languages": languages,
        "theme": theme,
        "search": search,
        "menu": menu,
        "features": features,
    }


}