import itemsUi from "./itemsUi"

export default {
    "ui:field": "collectionSchemas",
    "ui:layout": "section",
    "ui:icon": "code",
    "ui:title": "Dokumenttyper",
    "ui:options": {
        "collapsible": true,
    },
    "ui:sidebar": {
        "query": {
            "id": "sidebar/schemas",
            "models": "schemas",
        }
    },
    "ui:buttons": [
        {
            "label": "Finn schema",
            "type": "sidebar",
        },
        {
            "label": "Legg til",
            "type": "add"
        }        
    ],
    "items": itemsUi
}
