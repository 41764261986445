import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import VideoPlayer from "./VideoPlayer"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
//        backgroundColor: "#333",
//        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    }
}));

const VideoPreview = ({className, ...props}) => {

    const classes = useStyles()

    return (
        <figure className={className || classes.root}>
            <VideoPlayer {...props} />
        </figure>
    )

}

export default VideoPreview