import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PlayIconFilled from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/Pause';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const MediaButtonPlayPause = ({className, filled = true, color = "primary", paused, onClick}) => {
    const classes = useStyles({filled})


    if (paused) {
        return (
            <IconButton color={color} className={className || classes.root} onClick={onClick}>
                <PlayIcon />
            </IconButton>
        )
    }

    return (
        <IconButton color={color} className={className || classes.root} onClick={onClick}>
            <PauseIcon />
        </IconButton>
    )

}

export default MediaButtonPlayPause