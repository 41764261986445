import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutGrid from "./LayoutGrid"

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100%",
        padding: props => { return theme.spacing(props.padding) },
    }
}));

const LayoutObject = ({grid, children, ...props}) => {

    if (!grid) {
        return children
    }

    return (
        <LayoutGrid {...props}>
            {children}
        </LayoutGrid>
    )

}

LayoutObject.defaultProps = {
    header: true,
    footer: true,
    grid: undefined,
    padding: 0,
    spacing: 0
}

export default LayoutObject;