import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class FinderInbox extends Component {
    
    static defaultProps = {
        results: []
    }
    
    getFilters = (filters) => {
        
        let f = [];
        
        Object.keys(filters).map((key) => {
            if (filters[key]) {
                f.push(key + ":" + filters[key])
            }
        })
        
        return f.join('+')
        
    }
    
    getQuery = () => {
        const { app, user, match } = this.props;
        const { pathname } = this.props.location;
        
        const { siteName, appName, inboxApp, inboxOwner, inboxType } = match.params;
        
        let urlPattern, filters = {};
        
        // site + app
        
        if (siteName && appName) {
            urlPattern = app.root + "/inbox/:inboxType/:uniqueId"
            filters = {
                ownerName: siteName,
                appName: appName,
                inboxType: inboxType
            }

        // site

        } else if (siteName) {
            urlPattern = app.root + "/inbox/:ownerName/:uniqueId"
            filters = {
                ownerName: siteName,
            }

        // user inbox

        } else {
            urlPattern = app.root + "/:appName/:ownerName/:uniqueId"

            filters = {
                appName: inboxApp,
            }

        }
        
        const f = this.getFilters(filters);
        
        let query = {
            id: pathname,
            urlPattern: urlPattern,
            models: "documents",
            documentType: "message",
            sort: "updatedAt desc",
            q: f
//            q: q
//            schema: "/document",
//            userId: 41 || user.id,
        };
        
        return query
        
    }

    render() {
        const { app, match } = this.props;
        
        const query = this.getQuery()

        return (
            <div>{JSON.stringify(query)}</div>
        )
      
    }

}

function mapStateToProps(state) {
	return {
    	app: state.app,
    	user: state.user,
    	owner: state.owner,
    	nav: state.nav,
    	site: state.site,
    	collection: state.collection,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinderInbox);

