import React, { Component } from 'react';

class AdminFinder extends Component {
    componentDidMount() {
        require('./AdminFinder.scss');
    }

    render() {
        const { expanded } = this.props;

        return (
            <section className="admin__finder" aria-expanded={expanded}>
                {this.props.children}
            </section>
        )
    }
}

export default AdminFinder;