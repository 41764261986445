import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment';

import Grid from "./layers/Grid"
import Axes from "./layers/Axes"
import Areas from "./layers/Areas"
import Lines from "./layers/Lines"
import Points from "./layers/Points"
import Slices from "./layers/Slices"
import Mesh from "./layers/Mesh"
import Crosshair from "./layers/Crosshair"

import LineTooltip from "./ChartLineTooltip"

import { formatNumber, formatDate, formatHour, formatPercent, formatDuration } from "../../resources/functions"

import "./ChartLine.scss"

class ChartLine extends Component {

    static defaultProps = {
        chartType: "line",
        data: [],
        theme: {},
        margin: {
            top: 0, 
            right: 60, 
            bottom: 60, 
            left: 0
        },
        offset: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },

        xFormat: "date",
        yFormat: "integer",
        
        xScale: {
            type: "point"
        },
        yScale: {
            type: "linear",
            stacked: false,
            min: 0, 
            max: 'auto'
        },

//        curve: "monotoneX",
        curve: "linear",

        enableGridX: false,
        enableGridY: true,
        
        gridYValues: 5,

        enableArea: false,
        enablePoints: true,
        enableSlices: "x",

        useMesh: true,

        legends: [],
        animate: false
    }
    
    state = {
        currentPoint: undefined,
        currentSlice: undefined
    }
    
    setCurrentSlice = (slice, event) => {
        this.setState({
            currentSlice: slice
        })
    }

    setCurrentPoint = (point, event) => {
        this.setState({
            currentPoint: point
        })
    }
    
    getClassName = () => {
        const { chartType } = this.props;
        
        let classNames = [];
        
        classNames.push("admin-chart")
        
        if (chartType) {
            classNames.push("admin-chart--" + chartType)
        }
        
        return classNames.join(' ')
        
    }

    renderTooltip = ({slice, point}) => {
        const props = this.getProps()
        
        if (this.props.renderTooltip) {
            return this.props.renderTooltip({slice, point});
        }
        
        return (
            <LineTooltip {...props} slice={slice} point={point} />
        )
        
    }    

    
    renderGrid = (props) => {
        return (
            <Grid {...props} />
        )
    }

    renderAreas = (props) => {
        
        if (!this.props.enableArea) {
            return false
        }
        
        return (
            <Areas {...props} />
        )
    }
    
    renderLines = (props) => {
        return (
            <Lines {...props} />
        )
    }

    renderPoints = (props) => {

        if (!this.props.enablePoints) {
            return false
        }

        return (
            <Points {...props} {...this.state} />
        )
    }
    
    renderCrosshair = (props) => {
        const { crosshairType, enableSlices, innerWidth, innerHeight, offset } = props;
        const { currentPoint, currentSlice } = this.state;
        
        if (currentPoint) {

            return (
                <Crosshair
                    key="crosshair"
                    offset={offset}
                    width={innerWidth}
                    height={innerHeight}
                    x={currentPoint.x}
                    y={currentPoint.y}
                    type={crosshairType}
                />
            )
            
        }
        
        if (enableSlices && currentSlice) {
            
            return (
                <Crosshair
                    key="crosshair"
                    offset={offset}
                    width={innerWidth}
                    height={innerHeight}
                    x={currentSlice.x}
                    y={currentSlice.y}
                    type={enableSlices}
                />
            )

        }
        
    }

    renderSlices = (props) => {
        const { slices, enableSlices, debugSlices, innerHeight } = props;

        if (!enableSlices) {
            return false
        }

        const { currentSlice } = this.state;
        
        return (
            <Slices
                key="slices"
                slices={slices}
                axis={enableSlices}
                debug={debugSlices}
                height={innerHeight}
                current={currentSlice}
                setCurrent={this.setCurrentSlice}
                tooltip={this.renderTooltip}
            />
        )
        
    }
    

    renderMesh = (props) => {

        if (!this.props.useMesh || this.props.enableSlices) {
            return false
        }
        
        const { debugMesh, points, innerWidth, innerHeight, margin } = props;
        const { currentPoint } = this.state;
        
        return (
            <Mesh 
                key="mesh"
                points={points}
                width={innerWidth}
                height={innerHeight}
                margin={margin}
                debug={debugMesh}
                current={currentPoint}
                setCurrent={this.setCurrentPoint}
                tooltip={this.renderTooltip}
            />
        )
        
    }
    
    renderAxes = (props) => {
        const { innerWidth, innerHeight, xScale, yScale, axisTop, axisRight, axisBottom, axisLeft } = props;
        
        return (
            <Axes
                key="axes"
                width={innerWidth}
                height={innerHeight}
                xScale={xScale}
                yScale={yScale}
                top={axisTop}
                right={axisRight}
                bottom={axisBottom}
                left={axisLeft}
            />
        )
        
    }
    
    renderGrid = (props) => {
        const { innerWidth, innerHeight, enableGridX, enableGridY, xScale, yScale, gridXValues, gridYValues } = props;
        
        return (
            <Grid
                key="grid"
                width={innerWidth}
                height={innerHeight}
                xScale={enableGridX ? xScale : null}
                yScale={enableGridY ? yScale : null}
                xValues={gridXValues}
                yValues={gridYValues}
            />
        )
        
    }
    
    getLayers = () => {
        return [
//            'grid',
            'markers',
//            'axes',
            this.renderGrid,
            this.renderAxes,
//            'areas',
//            'crosshair',
//            this.renderGrid,
            this.renderAreas,
            this.renderLines,
            this.renderSlices,
            this.renderMesh,
            this.renderCrosshair,
            this.renderPoints,
//            'points',
//            'slices',
//            'mesh',
            'legends'
        ]
        
    }
    
    getXScale = () => {
        const { xFormat, xScale } = this.props;

        return xScale
        
    }
    
    getYScale = () => {
        let { yFormat, yScale, yScaleMin, yScaleMax } = this.props;
        
        yScale = {
            ...yScale,
            min: yScaleMin || "0",
            max: yScaleMax || "auto"
        }

        return yScale
        
    }
    
    formatX = (value) => {
        const { xFormat, dimension, date, days } = this.props;
        
        return value
        
        if (xFormat === "STRING" && dimension === "ga:nthDay" && date) {
            value = moment(date).add(value, "days").format()
        }

        if (xFormat === "STRING" && dimension === "ga:nthHour" && date) {
            value = moment(date).add(value, "hours").format()
        }

        return value
        
    }

    formatY = (value) => {
        const { yFormat } = this.props;

        return value;
    }

    getAxisTop = () => {
        return null;
    }

    getAxisRight = () => {
        const { axisRight, margin, yFormat } = this.props;
        
        let tickSize;
        
        return {
            format: yFormat,
            tickSize: margin.right || 0,
            tickValues: 5,
            ...axisRight,
//            tickSize: 0, 
//            tickPadding: 6, 
//            tickRotation: 0
        }
        
    }

    getAxisBottom = () => {
        const { axisBottom, xTickValues, xFormat, dimension, startDate, endDate } = this.props;

        return {
            dimension: dimension,
            startDate: startDate,
            endDate: endDate,
            format: xFormat,
            tickSize: 5,
            tickValues: xTickValues || undefined,
            ...axisBottom,
//            tickValues: 5,
//            tickSize: 0, 
//            tickPadding: 6, 
//            tickRotation: 0
        }
        
    }

    getAxisLeft = () => {
        return null;
    }
    
    getMargin = () => {
        const { outerMargin, margin } = this.props;
        
        if (outerMargin && margin) {
            
            return {
                top: outerMargin.top + margin.top,
                right: outerMargin.right + margin.right,
                bottom: outerMargin.bottom + margin.bottom,
                left: outerMargin.left + margin.left
            }    
            
        }
        
        return margin
        
    }

    
    getProps = () => {
//        const pointProps = this.getPoint();
        
        return {
            ...this.props,
//            ...pointProps,

            margin: this.getMargin(),
            layers: this.getLayers(),

            xScale: this.getXScale(),
            xFormat: this.formatX,
            
            yScale: this.getYScale(),
            yFormat: this.formatY(),

            axisTop: this.getAxisTop(),
            axisRight: this.getAxisRight(),
            axisBottom: this.getAxisBottom(),
            axisLeft: this.getAxisLeft(),
        }
        
    }
    
    render() {
        const props = this.getProps()

        return (
            <ResponsiveLine {...props} />
        )
    
    }

}

export default ChartLine