import React, { Component } from 'react';
import Color from 'color';

import ChartLine from './ChartLine';
import ChartLineCompare from './ChartLineCompare';
import ChartLineStacked from './ChartLineStacked';

import ChartArea from './ChartArea';
import ChartAreaStacked from './ChartAreaStacked';

import ChartBar from './ChartBar';
import ChartBarCompare from './ChartBarCompare';
import ChartBarGrouped from './ChartBarGrouped';
import ChartBarStacked from './ChartBarStacked';

import ChartPie from "./ChartPie"

import ChartChoropleth from "./ChartChoropleth"
import ChartSwarmPlot from "./ChartSwarmPlot"

import { getChart, getChartColors } from "../../resources/functions/"

import "./Chart.scss"
import "./Chart--byWeek.scss"
import "./Chart--byDate.scss"
import "./Chart--byHour.scss"

const templates = {
    "line": ChartLine,
    'line-compare': ChartLineCompare,
    "line-stacked": ChartLineStacked,
    "area": ChartArea,
    "area-stacked": ChartAreaStacked,
    "bar": ChartBar,
    "bar-compare": ChartBarCompare,
    "bar-grouped": ChartBarGrouped,
    "bar-stacked": ChartBarStacked,
    "pie": ChartPie,
    "choropleth": ChartChoropleth,
    "swarm-plot": ChartSwarmPlot
}


class Chart extends Component {

    static defaultProps = {
        theme: {
            primaryColor: "#3154ea",
            lightColor: "#8aa7f7",
            darkColor: "#24439f",
            neutralColor: "#f2f2f2",
        },
        type: "line",
        compare: false,
        stacked: false,
        metric: undefined,
        data: undefined,
        days: undefined,
        tooltipTitle: undefined,
        tooltipLabel: undefined
    }
    
    getProps = () => {
        let { type, data, keys, compare, stacked } = this.props;
        
        if (type === "line" && compare && data && data.length !== 2) {
            compare = false
        }

        if (type === "bar" && compare && keys && keys.length !== 2) {
            compare = false
        }

        if (type === "pie") {
            compare = false
            stacked = false
        }

        if (stacked && data && data.length < 2) {
            stacked = false
        }
        
        return {
            ...this.props,
            compare: compare,
            stacked: stacked
        }


    }
    
    /*
    
    getChartColors = () => {
        const { colors, theme } = this.props;
        
        if (colors) {
            return colors
        }
        
        if (!theme) {
            return undefined
        }
        
        let { primaryColor, lightColor, darkColor } = theme;
        
        if (!primaryColor) {
            return undefined
        }
        
        if (!lightColor) {
            lightColor = Color(primaryColor).lighten(0.25)
        }
    
        if (!darkColor) {
            darkColor = Color(primaryColor).darken(0.25)
        }
        
        const c1 = Color(darkColor)
        const c2 = Color(lightColor)
    
        let steps = 5;
        let themeColors = []
    
        for (let i = 0; i < steps; i++) {
            const mix = i/steps
            
            const color = c1.mix(c2, mix)
            themeColors.push(color)
        }
        
        return themeColors
        
    }
    
    */
    
    getChart = () => {
        const props = this.getProps()
        let { type, data, colors, theme } = props;
        
        if (!data) {
            return getChart(props)
        }
        
        colors = getChartColors(props)
        
        return {
            ...props,
            colors: colors
        }
        
    }

    getClassName = () => {
        const { type, data, compare, stacked, dimension, days } = this.getProps();
        
        let classNames = [];
        
        classNames.push("admin-chart")
        
        if (type) {
            classNames.push("admin-chart--" + type)
        }

        if (type && compare) {
            classNames.push("admin-chart--" + type + "-compare")
        }

        if (type && stacked) {
            classNames.push("admin-chart--" + type + "-stacked")
        }
        
        // dimension, days, weeks

        if (dimension && dimension.startsWith('ga:nth')) {
            classNames.push("admin-chart--" + dimension.replace('ga:', ''))
        }
        
        if (days) {
            classNames.push("admin-chart--" + days + "-days")
        }
        
        if (days && days > 7) {
            const weeks = Math.floor(days/7);
            classNames.push("admin-chart--" + weeks + "-weeks")
            
        }
        
        return classNames.join(' ')
        
    }
    
    getTemplate = () => {
        const { type, data, compare, stacked } = this.getProps();
        
        let template;
        
        if (type && compare) {
            template = type + "-compare"
        } else if (type && stacked) {
            template = type + "-stacked"
        } else {
            template = type
        }
        
        return template
        
    }
    
    render() {
        const className = this.getClassName()
        const template = this.getTemplate()
        
        const { error } = this.props;
        
        if (error) {
            return (
                <div className={className}>
                    <p className="admin-chart__error">{error}</p>
                </div>
            )
        }

        const chart = this.getChart()
        
        if (templates[template] && chart && chart.data) {
            const ChartTemplate = templates[template];
            
            return (
                <div className={className}>
                    <ChartTemplate {...chart} />
                </div>
            )        

        }
        
        
    }

}

export default Chart;