import React, { Component } from "react";
import InputWidget from "./InputWidget"

const TextareaWidget = ({...props}) =>  {

    return (
        <InputWidget {...props} multiline={true} rowsMin={2} />
    )
    
}

export default TextareaWidget;