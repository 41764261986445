import React from 'react';
import NavUser from "../NavUser/NavUser"
import NavApps from "../NavApps/NavApps"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 2000,
        display: "flex",
        marginTop: theme.spacing(-8),
        "&[aria-expanded=true]": {
            marginTop: 0
        },
        height: theme.spacing(8),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    button: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));

const AppNav = ({className, expanded = true, app, user, owner, onLogin, onLogout, onSelect}) => {
    const classes = useStyles()

    return (
        <section className={className || classes.root} aria-expanded={expanded}>
            { user && user.uniqueId && <NavApps className={classes.button} user={user} owners={user && user.owners} owner={owner} onSelect={onSelect} /> }
            <NavUser className={classes.button} app={app} user={user} onLogin={onLogin} onLogout={onLogout} />
        </section>
    )

}

export default AppNav;