// SEARCH

export const REQUEST_SITE = 'REQUEST_SITE'
export const RECEIVE_SITE = 'RECEIVE_SITE'

export const REQUEST_SITE_BY_ID = 'REQUEST_SITE_BY_ID'
export const RECEIVE_SITE_BY_ID = 'RECEIVE_SITE_BY_ID'

export function requestSite() {
    return {
        type: REQUEST_SITE,
    }
}

export function receiveSite(data) {
  
    if (!data) {
        return {
            type: RECEIVE_SITE,
            data: {},
        }
    }
  
    return {
        type: RECEIVE_SITE,
        id: data && data.id,
        data: data,
    }
  
}

export function requestSiteById() {
    return {
        type: REQUEST_SITE_BY_ID,
    }
}

export function receiveSiteById(data) {
  
    return {
        type: RECEIVE_SITE_BY_ID,
        id: data && data.id,
        data: data,
    }
  
}

