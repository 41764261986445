import InboxIcon from "../icons/InboxIcon"

export default {
    icon: InboxIcon,
    version: "1.5",
    createdAt: "2015-12-21",
    updatedAt: "2017-12-21",
    appId: "inbox",
    layout: "finder",
    theme: {
        primaryColor: "#fff"
    },
    root: "/inbox",
    title: "Innboks",
    search: {
        placeholder: "Søk i innboks"
    },
    button: {
        title: "Create",
        url: "/inbox/new",
        action: "editor",
        children: [
            {
                title: "Upload",
                url: "/inbox/upload",
                action: "upload"
            }
        ]
    },
    menu: [
        {
            "title": "Innboks",
            "path": ""
        },
        {
            "title": "Apps",
            "path": "/app"
        },
        {
            "title": "Eiere",
            "path": "/owner"
        }
    ]
}