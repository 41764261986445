import React, { Component } from 'react'
import PropTypes from 'prop-types'

import LayoutList from "../Layout/LayoutList"
import ListModule from "../Module/ListModule"

class ModelsList extends Component {

    static defaultProps = {
        models: [],
        moduleFooter: false
    };

    renderItem = (item, index) => {
        const { moduleFooter } = this.props;

        return (
            <ListModule {...item} moduleFooter={moduleFooter} key={index} />
        )
        
    }

    render() {
        const { padding, spacing, models, groups } = this.props;

        return (
            <LayoutList padding={padding} spacing={spacing}>
                { models.map(this.renderItem )}
            </LayoutList>
        )
    }
}

export default ModelsList;