import {defineMessages} from "react-intl";
import {type Messages} from "./MessageDescriptor"

const messages: Messages = {

  ["loadingTitle"]: {
    id: "ga.report.regVsPub",
    defaultMessage: "Primus vs. DigitaltMuseum",
  },
  ["title"]: {
    id: "ga.report.regVsPub.title",
    defaultMessage: "Publiserte vs. registrerte objekter",
  }

};

const definedMessages: typeof messages = defineMessages(messages);

export default definedMessages;