export default {
    "ui:layout": "section",
    "ui:title": "Betalingsinformasjon",
    "ui:options": {
        "collapsible": true,
        "grid": true,
        "spacing": 2
    },
    "clientId": {
        "ui:gridCols": 6,
        "ui:title": "Kunde",
        "ui:placeholder": "Finn kunde",
        "ui:widget": "autocomplete",
    },
    "guideFee": {
        "ui:title": "Omvisningsgebyr",
        "ui:widget": "switch"
    },
    "payment": {
        "ui:title": "Betalingsmåte",
    }
}