import React, { Component } from 'react';
import { ButtonSecondary } from "../Button/"
import { ButtongroupInline } from "../Buttongroup/"

class FieldFooter extends Component {
    
    renderButton = (button, index) => {
        
        return (
            <ButtonSecondary {...button} key={index} />
        )
        
    }

    render() {
        const { buttons } = this.props;

        if (!buttons) {
            return false;
        }

        return (
            <footer className="admin-field__footer">
                <ButtongroupInline>
                { buttons.map(this.renderButton) }
                </ButtongroupInline>
            </footer>
        )
    }
}

export default FieldFooter;
