import authorize from "./authorize"

import locations from "./locations";
import openinghours from "./openinghours";

import siteIdentifiers from "./siteIdentifiers";

import siteCredentials from "./siteCredentials";

import siteLogos from "./siteLogos";

import colors from "./colors/schema";
import images from "./images/schema";


export default {
    type: 'object',
    properties: {
        defaultLocale: {
            type: "string",
            default: "no"
        },
        content: {
            type: 'object',
            properties: {
                logo: {
                    type: "image",
                },
                title: {
                    type: "string",
                },
                description: {
                    type: "string",
                },
                name: {
                    type: "string",
                },
                abbr: {
                    type: "string",
                },
                domain: {
                    type: "string",
                },
                identifiers: siteIdentifiers,
                locations: locations,
                openinghours: openinghours,
                credentials: siteCredentials,
                logos: siteLogos,
                images: images,
                colors: colors,
                authorize: authorize
            }      
        }
    }
}