import layout from "./mediaLayout"
import query from "./ekulturQuery"

export default {
    "properties": {
        "type": {
            "enum": ["ekultur"]
        },
        "query": query,
        "layout": layout
    }
}