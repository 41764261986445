import _ from "lodash"

export const getReferenceSchema = (props) => {
    const { schema } = props;
    const { documentType, referenceType, _reverseReferenceType, _reverseReference } = schema;
        
    // default schema
    
    let defaultSchema = {
        "type": "object",
        "properties": {
            "referenceId": {
                "type": "string",
                "readonly": true
            },
            "referenceType": {
                "type": "string",
                "default": undefined,
                "readonly": true
            },
            "reference": {
                "type": "object",
                "properties": {
                    "uniqueId": {
                        "type": "string",
                        "default": undefined,
                        "readonly": true
                    },
                    "documentType": {
                        "type": "string",
                        "default": undefined,
                        "readonly": true
                    },
                    "title": {
                        "type": "string",
                        "default": "Untitled",
                    },
                    "_action": {
                        "type": "string",
                        "readonly": true,
                        "default": "update"
                    }
                }
            },
            "_action": {
                "type": "string",
                "readonly": true,
                "default": "update"
            }
        }
    }

    // referenceType
    
    if (referenceType) {

        defaultSchema = {
            ...defaultSchema,
            properties: {
                ...defaultSchema.properties,
                referenceType: {
                    ...defaultSchema.properties.referenceType,
                    default: referenceType
                }
            }
        }

    }

    // reference

    if (documentType) {

        let defaultType, defaultEnum;

        if (Array.isArray(documentType)) {
            defaultType = documentType[0]
            defaultEnum = documentType
        } else {
            defaultType = documentType
        }

        defaultSchema = {
            ...defaultSchema,
            properties: {
                ...defaultSchema.properties,
                reference: {
                    ...defaultSchema.properties.reference,
                    properties: {
                        ...defaultSchema.properties.reference.properties,
                        documentType: {
                            ...defaultSchema.properties.reference.properties.documentType,
                            default: defaultType,
                            enum: defaultEnum
                        },
                        title: {
                            ...defaultSchema.properties.reference.properties.title,
                            default: "Untitled " + defaultType,
                        }
                    }
                }
            }
        }
    }
    
    // _reverseReferenceType

    if (_reverseReferenceType) {
        defaultSchema = {
            ...defaultSchema,
            properties: {
                ...defaultSchema.properties,
                _reverseReferenceType: {
                    "type": "string",
                    "default": _reverseReferenceType,
                    "readonly": true
                }
            }
        }
    }

    // _reverseReference

    if (_reverseReference) {
        defaultSchema = {
            ...defaultSchema,
            properties: {
                ...defaultSchema.properties,
                _reverseReference: {
                    "type": "string",
                    "default": _reverseReference,
                    "readonly": true
                }
            }
        }
    }

    // properties

    if (schema.properties) {
        defaultSchema = {
            ...defaultSchema,
            properties: {
                ...defaultSchema.properties,
                ...schema.properties,
            }
        }
    }

    return defaultSchema
        
}
    
export const getReferenceField = (props) => {
    const schema = getReferenceSchema(props)
    
    return {
        ...props,
        schema: schema,
        uiSchema: {
            ...props.uiSchema,
            "ui:field": "reference"
        },
    }
    
}