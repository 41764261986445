import React, { useEffect, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAppCollection } from '../redux/functions/appCollection';
import { getSiteApp } from '../redux/functions/siteApp';
import { getApp } from './app';

import AppLoader from "../App/AppLoader"
import AppRoutes from "./KpRoutes"
import appData from "../redux/apps/appKp"

const KpApp = ({site, collection, app,...props}) => {

    const siteId = site && site.id
    const collectionId = collection && collection.id
    const collectionType = collection && collection.type


    useEffect(() => {
        props.getSiteApp({site, appData})
    }, [siteId])

    useEffect(() => {
        props.getApp({site, collection, ...appData})
    }, [collectionId, collectionType])

    let isLoading = false

    if (!site.uniqueId || !collection.uniqueId || !app.uniqueId) {
        isLoading = true
    }

    return (
        <AppLoader app={app} site={site} isLoading={isLoading}>
            <AppRoutes {...props} />
        </AppLoader>
    )

}

function mapStateToProps(state) {
	return {
    	site: state.site,
        collection: state.collection,
        app: state.app
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({  
      getSiteApp, getApp
//      getAppCollection, getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KpApp);

