import { API } from '../settings';
import { requestCollection, receiveCollection } from '../actions/collection';

export function getAppCollection(props) {
    const { appType, site } = props;
    
    let url = API + '/admin/api/collections/search?collectionType=' + appType + "&siteId=" + site.id;

    return function (dispatch) {
        dispatch(requestCollection());

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(
          data => dispatch(getAppCollectionData(data, props))
        )

    }
    
}

export function getAppCollectionData(data, props) {
    const { models } = data;

    let collection;
    
    if (models && models[0] && models[0].uniqueId) {
        collection = models[0]
    }
    
    return function (dispatch) {
        if (collection) {
            dispatch(receiveCollection(collection))
        } else {
            dispatch(createAppCollection(props))
        }
    }
    
}

export function createAppCollection(props) {
    const { appType, site } = props;

    const appName = appType + ":" + site.name;
    
    let data = {
      siteId: site.id,
      imageUrl: "",
      collectionType: appType,
      defaultLocale: "en",
      name: appName,
      title: appName,
      description: "",
      content: {
          collectionType: appType,
          siteId: site.id,
          title: appName,
          name: appName
      }
    }

      const url = API + '/admin/api/collections';
      const payload = JSON.stringify(data);
    
      return function (dispatch) {
    
        fetch(url, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
          body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(receiveCollection(data))
        )
      
      }
    
}

// OLD STØFF

export function getUserCollection(props) {

      return function (dispatch) {
    
        dispatch(getSiteCollection({
            ...props,
            site: {
                id: 2,
                name: undefined
            }
        }))

    }
    
}


export function getSiteCollection(props) {
    const { site, app } = props;
    
    let url = API + '/admin/api/collections/search?collectionType=site/' + app.name + "&siteId=" + site.id;

    return function (dispatch) {
        dispatch(requestCollection());

        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(
          data => dispatch(getSiteCollectionData(data, props))
        )

    }
    
}

export function getSiteCollectionData(data, props) {

    const { models } = data;

    let collection;
    
    if (models && models[0] && models[0].uniqueId) {
        collection = models[0]
    } else {
        return newSiteCollection(props)
    }
    
    return function (dispatch) {
        dispatch(receiveCollection(collection))
    }
    
}

export function newSiteCollection(props) {
    const { site, app } = props;

    const collectionType = "site/" + app.name;
    const collectionName = collectionType + ":" + site.name;
    
    let data = {
      siteId: site.id,
      imageUrl: "",
      collectionType: collectionType,
      defaultLocale: "en",
      name: collectionName,
      title: collectionName,
      description: "",
      content: {
          collectionType: collectionType,
          siteId: site.id,
          title: collectionName,
          name: collectionName
      }
    }

      const url = API + '/admin/api/collections';
      const payload = JSON.stringify(data);
    
      return function (dispatch) {
    
        fetch(url, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
          body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data =>
          dispatch(receiveCollection(data))
        )
      
      }
    
}