export default {
    "select": {
        "imageUrl" : "imageUrl",
        "title" : "title",
        "metadata" : "metadata",
        "typeLabel": "typeLabel"
    },
    prepare({formData, formContext}) {
        let { documentType, imageUrl, title, sourceId, content } = formData
  
        let metadata = []

        content && content.owner && metadata.push(content.owner)
        sourceId && metadata.push('#'+sourceId)

        return {
            imageUrl: imageUrl,
            title: title,
            metadata: metadata,
            typeLabel: documentType
        }
      
    }
    
}