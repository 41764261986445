import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchModel } from '../redux/functions/model';

import SectionResults from "./TemplateDashboardSectionResults";

class TemplateDashboardSection extends Component {

  static defaultProps = {
    query: {},
    search: {}
  }

  componentDidMount() {
    this.onSearch();
  }

  componentDidUpdate = (prevProps) => {

    if (prevProps.query !== this.props.query ||
      prevProps.search.q !== this.props.search.q) {
        this.onSearch();
    }
      
  }

  onSearch = () => {
    const { id, models } = this.props.query;

    this.setState({
      results: []
    })
    
    const query = this.getQuery();
    
    this.props.searchModel(models, id, query);
  }
  
  getQuery = () => {
    
    let { query, search } = this.props;
    
    if (search.q) {
      query.q = search.q
    } else {
      query.q = ""
    }
    
    return query;
    
  }

  getResults = () => {
    
    const { data, query } = this.props;
    const { id } = query;
    
    let results = []

    if (data[id] && data[id].models) {
      results = data[id].models;
    }

    return {
      results: results
    }
    
  }

  render() {
    
    const { results } = this.getResults();
    
    return (
      
      <SectionResults { ...this.props } results={ results } />

    )

  }

}

function mapStateToProps(state) {
	return {
  	search: state.app.search,
    data: state.data,
 	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ searchModel }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateDashboardSection);

