export const REQUEST_SCHEMAS = 'REQUEST_SCHEMAS'

export const REQUEST_SCHEMA = 'REQUEST_SCHEMA'
export const RECEIVE_SCHEMA = 'RECEIVE_SCHEMA'

export function requestSchemas() {

  return {
    type: REQUEST_SCHEMAS,
  }
  
}

export function requestSchema(schema) {
    const { pathname, name } = schema; 

    return {
        type: REQUEST_SCHEMA,
        name: name,
        pathname: pathname
    }
  
}


export function receiveSchema(schema) {
    const { id, uniqueId, pathname, name } = schema; 

    return {
        type: RECEIVE_SCHEMA,
        id: id,
        uniqueId: uniqueId,
        pathname: pathname,
        name: name,
        data: schema,
    }
  
}

