import React from 'react';
import Button from './Button';

import {
    injectIntl,
    FormattedMessage,
    defineMessages
} from 'react-intl';

const messages = defineMessages({
  login: {
    id: 'button.action.login',
    defaultMessage: 'Log in',
  },
  logout: {
    id: 'button.action.logout',
    defaultMessage: 'Log out',
  },
  change: {
    id: 'button.action.change',
    defaultMessage: 'Change',
  },
  finder: {
    id: 'button.action.finder',
    defaultMessage: 'Finder',
  },
  upload: {
    id: 'button.action.upload',
    defaultMessage: 'Upload',
  },
  save: {
    id: 'button.action.save',
    defaultMessage: 'Save',
  },
  saving: {
    id: 'button.action.saving',
    defaultMessage: 'Saving ...',
  },
  saveAs: {
    id: 'button.action.saveAs',
    defaultMessage: 'Save as ...',
  },
  saveAsDraft: {
    id: 'button.action.saveAsDraft',
    defaultMessage: 'Save as draft',
  },
  saveAndPublish: {
    id: 'button.action.saveAndPublish',
    defaultMessage: 'Save and publish',
  },
  edit: {
    id: 'button.action.edit',
    defaultMessage: 'Edit',
  },
  view: {
    id: 'button.action.view',
    defaultMessage: 'View',
  },
  refs: {
    id: 'button.action.refs',
    defaultMessage: 'Links',
  },
  delete: {
    id: 'button.action.delete',
    defaultMessage: 'Delete',
  },
  restore: {
    id: 'button.action.restore',
    defaultMessage: 'Restore',
  },
  erase: {
    id: 'button.action.erase',
    defaultMessage: 'Erase',
  },
  star: {
    id: 'button.action.star',
    defaultMessage: 'Star',
  },
  unstar: {
    id: 'button.action.unstar',
    defaultMessage: 'Starred',
  },
  add: {
    id: 'button.action.add',
    defaultMessage: 'Add',
  },
  remove: { 
    id: 'button.action.remove',
    defaultMessage: 'Remove',
  },
  select: {
    id: 'button.action.select',
    defaultMessage: 'Select',
  },
  unselect: {
    id: 'button.action.unselect',
    defaultMessage: 'Unselect',
  },
});

class ButtonIntl extends React.Component {

  render() {
    
    const { intlLabel } = this.props;

    let label = intlLabel;
    
    if (messages[intlLabel]) {
      label = this.props.intl.formatMessage(messages[intlLabel]);
    }

    return (
      <Button { ...this.props } label={ label } title={ false } intlLabel={ false }  />
    );

  }

}

export default injectIntl(ButtonIntl);