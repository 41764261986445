export default {
    "ui:layout": "section",
    "ui:icon": "language",
    "ui:title": "Språk",
    "ui:summary" : {
    "single" : "1 språk",
    "multiple" : "[count] språk",
    },

  "ui:buttons": {
    "sidebar": {
      "label": "Finn språk",
      "sidebar": "language"
    },
    "add": {
      "label": "Legg til"
    }
  },
  
  "items": {
    "ui:layout": "module",
    "ui:editable": true,
  }
}