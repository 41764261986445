import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import OwnerIcon from "../Owner/OwnerIcon"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        listStyle: "none",
        padding: 0,
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        padding: theme.spacing(1.25),
    },
    icon: {
        position: "relative",
        width: "36px",
        height: "36px",
    },
    label: {
        fontSize: "14px",
        fontWeight: "bold",
        margin: theme.spacing(1)
    }

}));

const OwnersListItem = ({title, onClick, ...props}) => {
    const classes = useStyles()

    return (
        <ButtonBase className={classes.root} onClick={onClick}>
            <OwnerIcon className={classes.icon}{...props} title={title} />
            <Typography className={classes.label} noWrap>{title}</Typography>
        </ButtonBase>
    )

}

export default OwnersListItem;