import React from 'react';

import MediaBase from "./MediaBase"
import MediaToolbar from "./MediaToolbar"
import MediaButtonbar from "./MediaButtonbar"
import MediaPreview from "./MediaPreview"
import MediaUpload from "./MediaUpload"

import NavSettings from "../NavSettings/NavSettings"
import NavToolbar from "../NavToolbar/NavToolbar"
import NavTabs from "../NavTabs/NavTabs"

import ButtonSave from "../NavToolbar/ButtonSave"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
    },
    header: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: "auto",
        left: 0,
        display: "flex",
        zIndex: 2,
        alignItems: "center",
        justifyContent: "flex-end",
        height: theme.spacing(7),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: "inherit",

        "& + *": {
            zIndex: 1,
            marginTop: theme.spacing(7)
        },

        "& + [data-layout=imagePreview]": {
            zIndex: 1,
            marginTop: 0
        }

    },
    navTabs: {
        flexGrow: 1,
        marginLeft: theme.spacing(1),
        color: "inherit"
    },
    navSettings: {
        display: "flex",
        margin: theme.spacing(1),
    },
    navToolbar: {
        display: "flex",
        color: "inherit"
    },
}));

const MediaHeader = ({menu, toolbar, settings}) => {

    const classes = useStyles()

    toolbar = toolbar.map(item => {
        if (item.type === "edit") {
            return false
        }

        return item

    })

    return (
        <header className={classes.header}>
            { menu && <NavTabs className={classes.navTabs} tabs={menu} />}
            { settings && <NavSettings className={classes.navSettings} settings={settings} /> }
            { toolbar && <NavToolbar className={classes.navToolbar} toolbar={toolbar} /> }
        </header>
    )
    
}

const MediaBody = ({children}) => {

    const classes = useStyles()

    return (
        <div className={classes.body}>

        </div>
    )


}

const LayoutMedia = ({backgroundColor, color, upload, uploadProgress, mediaId, mediaType, media, imageUrl, menu, buttons = [], children, ...props}) => {

    if (mediaType === "image") {
        backgroundColor = "#333"
        color = "white"
    }

    if (mediaType === "video") {
        backgroundColor = "black"
        color = "white"
    }

    if (mediaType === "audio") {
        backgroundColor = "white"
    }

    const baseProps = {
        ...props,
        backgroundColor: backgroundColor,
        color: color
    }

    if (uploadProgress && uploadProgress < 100) {
        return (
            <MediaBase {...baseProps}>
                <MediaHeader {...props} />
                <MediaUpload {...props} mediaType={mediaType} uploadProgress={uploadProgress} />
            </MediaBase>
        )
    }

    if (!mediaId && upload || !mediaId && buttons) {
        return (
            <MediaBase {...baseProps}>
                <MediaUpload {...props} mediaType={mediaType} buttons={buttons} />
            </MediaBase>
        )
    }

    /*

    if (!buttons && upload) {
        buttons = getUploadButtons()
    }

    if (buttons && !mediaId) {
        return (
            <MediaBase {...props}>
                <MediaButtonbar buttons={buttons} />
            </MediaBase>
        )
    }

    */

    if (menu) {
        return (
            <MediaBase {...baseProps}>
                <MediaHeader {...props} menu={menu} />
                { children }
            </MediaBase>
        )
    }

    return (
        <MediaBase {...baseProps}>
            <MediaHeader {...props} />
            <MediaPreview {...media} />
        </MediaBase>
    )    

}

export default LayoutMedia;