import React from "react";
import PropTypes from "prop-types";
import OptionsBase from "./OptionsBase"
import RadioBase from "./RadioBase"

const RadioWidget = ({
    id,
    value,
    options,
    required,
    disabled,
    readonly,
    autofocus,
    onBlur,
    onFocus,
    onChange,
    ...props
  }) => {

    // Generating a unique field name to identify this set of radio buttons
    const name = Math.random().toString();

    const { enumOptions, enumDisabled, inline } = options;
    // checked={checked} has been moved above name={name}, As mentioned in #349;
    // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.

    const handleChange = (event) => {
        onChange && onChange(event.target.value)
    }

    const label = options.title || props.title || props.label || null

    const radioOptions = enumOptions.map((option, index) => {
        const checked = value && value.toString() && option.value.toString() === value.toString() || false;
        const itemId = id + "_" + index;
        const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;

        return {
            id: itemId,
            name: name,
            disabled: disabled || itemDisabled || readonly,
            label: option.label,
            value: option.value,
            checked: checked,
            onChange: handleChange
        }

    });

    return (
        <OptionsBase label={label} {...options}>
            {radioOptions.map((option, index) => {
            return (<RadioBase {...option} key={index} />)
            })}
        </OptionsBase>
    )

}

RadioWidget.defaultProps = {
  autofocus: false,
};

    RadioWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.array,
        inline: PropTypes.bool,
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    };

export default RadioWidget;