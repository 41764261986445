import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    getDefaultFormState,
    retrieveSchema,
    shouldRender,
    toIdSchema,
    setState,
    getDefaultRegistry,
    deepEquals
} from "./utils";

import validateFormData, { toErrorList } from "./utils/validate";

class SchemaBase extends Component {
    static propTypes = {
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object,
        formData: PropTypes.any,
        widgets: PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        ),
        fields: PropTypes.objectOf(PropTypes.func),
        layouts: PropTypes.objectOf(PropTypes.func),
        ArrayFieldTemplate: PropTypes.func,
        ObjectFieldTemplate: PropTypes.func,
        FieldTemplate: PropTypes.func,
        ErrorList: PropTypes.func,
        onChange: PropTypes.func,
        onError: PropTypes.func,
        showErrorList: PropTypes.bool,
        onSubmit: PropTypes.func,
        id: PropTypes.string,
        className: PropTypes.string,
        name: PropTypes.string,
        method: PropTypes.string,
        target: PropTypes.string,
        action: PropTypes.string,
        autocomplete: PropTypes.string,
        enctype: PropTypes.string,
        acceptcharset: PropTypes.string,
        noValidate: PropTypes.bool,
        noHtml5Validate: PropTypes.bool,
        liveValidate: PropTypes.bool,
        validate: PropTypes.func,
        transformErrors: PropTypes.func,
        safeRenderCompletion: PropTypes.bool,
        formContext: PropTypes.object,
        additionalMetaSchemas: PropTypes.arrayOf(PropTypes.object),
    }

    static defaultProps = {
        className: "schema",
        schema: {},
        uiSchema: {},
        formData: {},
        formContext: {
            language: "en",
            expandId: undefined,
            settings: false
        }
    }

    constructor(props) {
        super(props);
        this.state = this.getStateFromProps(props);
        if (
            this.props.onChange &&
            !deepEquals(this.state.formData, this.props.formData)
        ) {
            this.props.onChange(this.state);
        }
        this.formElement = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextState = this.getStateFromProps(nextProps);
        if (
            !deepEquals(nextState.formData, nextProps.formData) &&
            !deepEquals(nextState.formData, this.state.formData) &&
            this.props.onChange
        ) {
            this.props.onChange(nextState);
        }
        this.setState(nextState);
    }


    getStateFromProps(props) {
        const state = this.state || {};

        let schema = "schema" in props ? props.schema : this.props.schema;

        const uiSchema = "uiSchema" in props ? props.uiSchema : this.props.uiSchema;
        const edit = typeof props.formData !== "undefined";
        const liveValidate = props.liveValidate || this.props.liveValidate;
        const mustValidate = edit && !props.noValidate && liveValidate;
        const { definitions } = schema;
        const formData = getDefaultFormState(schema, props.formData, definitions);
        const retrievedSchema = retrieveSchema(schema, definitions, formData);
        const additionalMetaSchemas = props.additionalMetaSchemas;
        const { errors, errorSchema } = mustValidate
            ? this.validate(formData, schema, additionalMetaSchemas)
            : {
                errors: state.errors || [],
                errorSchema: state.errorSchema || {},
            };
        const idSchema = toIdSchema(
            retrievedSchema,
            uiSchema["ui:rootFieldId"],
            definitions,
            formData,
            props.idPrefix
        );
        return {
            schema,
            uiSchema,
            idSchema,
            formData,
            edit,
            errors,
            errorSchema,
            additionalMetaSchemas,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return shouldRender(this, nextProps, nextState);
    }

    validate(
        formData,
        schema = this.props.schema,
        additionalMetaSchemas = this.props.additionalMetaSchemas
    ) {
        const { validate, transformErrors } = this.props;
        const { definitions } = this.getRegistry();
        const resolvedSchema = retrieveSchema(schema, definitions, formData);
        return validateFormData(
            formData,
            resolvedSchema,
            validate,
            transformErrors,
            additionalMetaSchemas
        );
    }

    onChange = (formData, newErrorSchema) => {
        console.log('schemaForm:onChange')
        const mustValidate = !this.props.noValidate && this.props.liveValidate;
        let state = { formData };
        if (mustValidate) {
            console.log('must validate')
            const { errors, errorSchema } = this.validate(formData);
            state = { ...state, errors, errorSchema };
        } else if (!this.props.noValidate && newErrorSchema) {
            state = {
                ...state,
                errorSchema: newErrorSchema,
                errors: toErrorList(newErrorSchema),
            };
        }
        setState(this, state, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state);
            }
        });
    };

    onBlur = (...args) => {
        if (this.props.onBlur) {
            this.props.onBlur(...args);
        }
    };

    onFocus = (...args) => {
        if (this.props.onFocus) {
            this.props.onFocus(...args);
        }
    };
    
    /*

    onSubmitX = event => {
        event.preventDefault();
        event.persist();

        if (!this.props.noValidate) {
            const { errors, errorSchema } = this.validate(this.state.formData);
            if (Object.keys(errors).length > 0) {
                setState(this, { errors, errorSchema }, () => {
                    if (this.props.onError) {
                        this.props.onError(errors);
                    } else {
                        console.error("Form validation failed", errors);
                    }
                });
                return;
            }
        }

        this.setState({ errors: [], errorSchema: {} }, () => {
            if (this.props.onSubmit) {
                this.props.onSubmit({ ...this.state, status: "submitted" }, event);
            }
        });
    };
    
    */

    onSubmit = (event) => {
        console.log('schemaForm:onSubmit')

//        event.preventDefault();
//        event.persist();

        const { errors, errorSchema } = this.validate(this.state.formData);

        this.setState({ errors: [], errorSchema: {} }, () => {
            if (this.props.onSubmit) {
                this.props.onSubmit({ ...this.state, errors: errors, errorSchema: errorSchema, status: "submitted" });
            }
        });
    };
    
    getRegistry = () => {
        // For BC, accept passed SchemaField and TitleField props and pass them to
        // the "fields" registry one.
        const { fields, fieldtypes, widgets, layouts } = getDefaultRegistry();
        
        return {
            fields: { ...fields, ...this.props.fields },
            fieldtypes: { ...fieldtypes, ...this.props.fieldtypes },
            widgets: { ...widgets, ...this.props.widgets },
            layouts: { ...layouts, ...this.props.layouts },
            ArrayFieldTemplate: this.props.ArrayFieldTemplate,
            ObjectFieldTemplate: this.props.ObjectFieldTemplate,
            FieldTemplate: this.props.FieldTemplate,
            definitions: this.props.schema.definitions || {},
            formContext: this.props.formContext || {},
        };
    }

    submit = () => {
        if (this.formElement) {
            this.formElement.dispatchEvent(new Event("submit", { cancelable: true }));
        }
    }

    render() {
        const { idPrefix, safeRenderCompletion, disabled } = this.props;
        const { schema, uiSchema, formData, errorSchema, idSchema } = this.state;

        const registry = this.getRegistry();

        const { SchemaField } = registry.fields;
        
        return (
            <SchemaField
                schema={schema}
                uiSchema={uiSchema}
                errorSchema={errorSchema}
                idSchema={idSchema}
                idPrefix={idPrefix}
                formData={formData}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                registry={registry}
                safeRenderCompletion={safeRenderCompletion}
                disabled={disabled}
            />
        )
    }
}

export default SchemaBase;