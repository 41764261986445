import React, { useRef, useState, useEffect } from 'react';
import { withResizeDetector } from 'react-resize-detector';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import ArrowIcon from '@material-ui/icons/ArrowDownward'

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    body: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
    },
    footer: {
        position: "absolute",
        zIndex: 5,
        top: "auto",
        right: 0,
        bottom: 0,
        left: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        overflow: "hidden",
    },
    button: {
        zIndex: 5,
        transition: ".125s ease-out",
        transform: props => { return props.scrolled && "translateY(200%)"},
        margin: theme.spacing(1.5),
        backgroundColor: "white",
        boxShadow: theme.shadows[6],

        "&:hover": {
            backgroundColor: "white",
            boxShadow: theme.shadows[1],
        }

    }
}));

const ScrollButton = ({scrollTop, minScroll, onClick}) => {

    let scrolled = false;

    if (scrollTop > minScroll) {
        scrolled = true
    }

    const classes = useStyles({scrolled})

    return (
        <footer className={classes.footer}>
            <IconButton elevation={2} className={classes.button} onClick={onClick}><ArrowIcon /></IconButton>
        </footer>
    )

}

const WidgetScroller = ({className, style = {}, height, minScroll = 100, children}) => {
    const [overflow, setOverflow] = useState(false)
    const [scrollTop, setScrollTop] = useState(0)
    const parentRef = useRef()
    const bottomRef = useRef()

    const scrollTo = () => {
        const bottom = bottomRef.current;
        bottom && bottom.scrollIntoView({ behavior: 'smooth', block: 'center' });

        /*
        const scrollBottom = parentRef.current && parentRef.current.children[0].offsetHeight
        parentRef.current && parentRef.current.scrollTo({
            top: scrollBottom,
            behavior: 'smooth',
        })
        */
    }

    const handleScroll = (e) => {
        setScrollTop(parentRef.current && parentRef.current.scrollTop)
    }

    useEffect(() => {
        const ph = parentRef.current && parentRef.current.offsetHeight
        const ch = parentRef.current && parentRef.current.children[0].offsetHeight

        parentRef.current.addEventListener("scroll", handleScroll);

        if (ph < ch) {
            setOverflow(true)
        }

        return () => {
            parentRef.current.removeEventListener("scroll", handleScroll);
        };

    }, [children, height]);

    const classes = useStyles()

    return (
        <section className={className || classes.root} style={style}>
            <div className={classes.body} ref={parentRef}>
                { children }
                <div ref={bottomRef}></div>
            </div>
            { overflow && <ScrollButton minScroll={minScroll} scrollTop={scrollTop} onClick={scrollTo} /> }
        </section>
    )

}

export default withResizeDetector(WidgetScroller)