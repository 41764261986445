import React, { useState, useRef, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';

import ActionButton from "./ActionButton"
import ActionDropdown from "./ActionDropdown"
import ActionMenu from "./ActionMenu"

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "14px",
//        margin: theme.spacing(1),
    }
}));

const NavAction = ({className, variant="contained", color="primary", placement = "bottom-start", primaryAction, menu = [], onSelect}) => {
    const [expanded, setExpanded] = useState(false);
    const anchorRef = useRef(null);
    const classes = useStyles()
   
    const handleToggle = (event) => {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const handleSelect = ({onClick, ...item}) => {
        onClick && onClick() || onSelect && onSelect(item)
        setExpanded(false);
    }

    if (!primaryAction && menu.length) {
        primaryAction = menu[0]
    }

    if (primaryAction && primaryAction.children) {
        const menu = primaryAction.children
        const role = primaryAction.role

        if (role !== "button") {
            return (
                <nav className={className || classes.root}>
                    <ActionButton {...primaryAction} variant={primaryAction.variant || variant} color={color} expanded={expanded} forwardedRef={anchorRef} onToggle={handleToggle} />
                    <Popper open={expanded} style={{zIndex: 2000}}
                        role={undefined} transition anchorEl={anchorRef.current} placement={placement}>
                        <ClickAwayListener onClickAway={handleToggle}>
                            <ActionDropdown>
                                <ActionMenu menu={menu} onSelect={handleSelect} />
                            </ActionDropdown>
                        </ClickAwayListener>
                    </Popper>
                </nav>
            )
        }

        return (
            <nav className={className || classes.root}>
                <ActionButton {...primaryAction} variant={primaryAction.variant || variant} color={color} expanded={expanded} forwardedRef={anchorRef} onClick={() => handleSelect(primaryAction)} onToggle={handleToggle} />
                <Popper open={expanded} style={{zIndex: 2000}}
                    role={undefined} transition anchorEl={anchorRef.current} placement={placement}>
                    <ClickAwayListener onClickAway={handleToggle}>
                        <ActionDropdown>
                            <ActionMenu menu={menu} onSelect={handleSelect} />
                        </ActionDropdown>
                    </ClickAwayListener>
                </Popper>
            </nav>
        )
        
    }

    if (primaryAction) {
        return (
            <nav className={className || classes.root}>
                <ActionButton {...primaryAction} variant={primaryAction.variant || variant} color={color} onClick={() => handleSelect(primaryAction)} />
            </nav>
        )
    }

    return null

}

export default NavAction;