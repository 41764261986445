import media from './media/';
import topic from './topic/';
import article from './article/';
import object from './object/';

const schemas = [
    ...media,
    topic,
    article,
    object
]

export default schemas;