import { receiveMenuItem } from '../actions/nav';
import { requestParents, receiveParents } from '../actions/nav';

import { getNavCount } from "./navCount"
import { getNavTree } from "./navTree"
import { getNavByDocumentType } from "./navDocumentType"

export {
    getNavCount,
    getNavTree
}

export function getNavParents(menuByUrl, pathname) {
    const parents = getParents(menuByUrl, pathname);

    return function(dispatch) {
        dispatch(requestParents());
        dispatch(receiveParents(parents));
    }

}


export function getParents(nav, pathname) {

    let parents = [];
  
    if (!pathname) {
        return parents;
    }
  
    const pathnames = pathname.split('/');
  
    let url = '';
  
    pathnames.forEach((path) => {

        if (path) {
            url = url + '/' + path;
        } else {
            url = '/';
        }
    
        if (nav && nav[url] && nav[url].label || nav[url] && nav[url].title) {
            parents.push(nav[url]);
        }
        
        if (url == '/') {
            url = '';
        }

    });

    return parents;
  
}

export const getMenuByUrl = (children = []) => {

    children.map((child, index) => {
        if (child.url) {
            getMenuItem(child)
        }
    })
    
}

export const getMenuItem = ({url, ...item}) => {



}

export function getNavByPath(menu, urls = {}, level = 0) {
  
  menu.forEach((item) => {
      
    item.level = level      
    
    if (item.url) {
      urls[item.url] = item;
    }
    
    if (item.children) {
      urls = getNavByPath(item.children, urls, level++);
    }
    
  });
  
  return urls;
  
}

export function getNavItems(menu) {

  const nav = getNavByPath(menu);

  return function(dispatch) {
    
    for (var url in nav) {
      
      if (nav.hasOwnProperty(url)) {
        dispatch(getNavItem(nav[url]))
      }
      
    }

  }
  
}

export function getNav(menu) {
  
  return function(dispatch) {
    dispatch(getNavItems(menu))
  } 
  
}

export function getNavItem(parent) {
  
  return function(dispatch) {
    dispatch(receiveMenuItem(parent))
    
    if (parent.query) {
        dispatch(getNavQuery(parent))
    }

  } 
  
}

export function getNavQuery(parent) {
    const { query, type } = parent;

    return function(dispatch) {
        if (type === "documents/tree") {
            dispatch(getNavTree(parent))
        } else if (type === "documents/treeitem") {
            dispatch(getNavTree(parent))
        } else if (type === "documents/type") {
            dispatch(getNavByDocumentType(parent))
        } else if (query.models && query.collectionId) {
            dispatch(getNavCount(parent))
        }
    }
    
}