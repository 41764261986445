export default {
    "ui:layout": "section",
    "ui:icon": "menu",
    "ui:title": "Preview",
    "ui:options": {
        "grid": true,
        "spacing": 1
    },
    "ui:fieldset": [
        "template",
        "format",
        "url",
        "targetOrigin"
    ],

}