import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AutocompleteBase } from "@frontend-components/admin"
import { searchModel, getModel, getModelChildren } from '../../redux/functions/model';

const AutocompletePlaceWidget = ({collection, search, queryId, ...props}) => {

    const query = {
        collectionId: collection && collection.id,
        documentType: "place",
        fl: "id,parentId,uniqueId,title"
    }

    const getPlaces = () => {
        props.searchModel('documents', queryId, query)
    }

    useEffect(() => {
        getPlaces()
    }, [])

    const [options, setOptions] = useState([])

    const getOptions = () => {
        const models = search && search[queryId] && search[queryId].models

        setOptions(models)

    }

    useEffect(() => {
        getOptions()
    }, [search[queryId]])

    return (
        <p>{JSON.stringify(options)}</p>
    )

}

AutocompletePlaceWidget.defaultProps = {
    queryId: 'autocomplete/place',
    query: {
        documentType: "place"
    }
}

const mapStateToProps = (state) => {
	return {
        collection: state.collection,
        search: state.search
	};
}  

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ 
        searchModel,
        getModel, getModelChildren,
    }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AutocompletePlaceWidget);