import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// Site

import SiteDashboard from "./SiteDashboard"

// Products

import Booking from '../Booking/';
import Minner from '../Minner/';

import Museum24 from '../Museum24/';
import DigitaltMuseum from '../DigitaltMuseum/';
import KulturPunkt from '../KulturPunkt/';
import Kiosk from '../Kiosk/';
import PrimusFDV from '../PrimusFDV/';

// Services

import Media from '../AppMedia/';
import AppCollection from '../AppCollection/';

class SiteRoutes extends Component {
  
    render() {

        return (
        
            <Switch>
                <Route path="/:siteName/media" component={ Media } />
                <Route path="/:siteName/booking" component={ Booking } />
                <Route path="/:siteName/dm" component={ DigitaltMuseum } />
                <Route path="/:siteName/m24" component={ Museum24 } />
                <Route path="/:siteName/kp" component={ KulturPunkt } />
                <Route path="/:siteName/kiosk" component={ Kiosk } />
                <Route path="/:siteName/minner" component={ Minner } />
                <Route path="/:siteName/primus" component={ PrimusFDV } />
                <Route path="/:siteName/:appName" component={ AppCollection } />
                <Route path="/:siteName" component={ SiteDashboard } />
            </Switch>
        
        )

    }

}

export default SiteRoutes
