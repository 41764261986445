import { getAppData } from '../../redux/functions/app';
import { getMenuMedia, getMenuLatest, getMenuTrash, getMenuEkultur } from '../../redux/functions/menu';
import { getSiteCollection } from '../../redux/functions/appCollection';

import schemas from "../schemas"

export function getApp(props) {
    const { site, collection } = props;

    const title = "Booking"
    const name = "booking"
    const root = "/" + site.name + "/" + name

    props = {
        ...props,
        uniqueId: collection && collection.id,
        siteId: site && site.id,
        collectionId: collection && collection.id,
        root: root,
    }
    
    const appEdit = getAppAction(props)
    const appMenu = getAppMenu(props)

    const appSearch = {
        "placeholder": "Søk i " + title
    }
    
    const app = {
        ...props,
        name: name,
        title: title,
        theme: {
            primaryColor: "#FF6161",
            primaryContrast: "white",
        },
        edit: appEdit,
        menu: appMenu,
        search: appSearch,
        menuTree: root,
        schemas: schemas,
        layout: "finder",
        calendar: true,
        drawer: {
            menu: true
        },
        sidebar: {
            expanded: true,
            calendar: true,
            menu: false
        },
        languages: [
            "no", "sv", "en"
        ]
    }

    return function(dispatch) {
        dispatch(getAppData(app))
    }
    
}

export function getAppAction(app) {
    const { root } = app

    return [
        {
            title: "Nytt innhold",
            icon: "",
            children: [
                {
                    title: "Booking",
                    url: root + "/booking/<new>/edit",
                },
                {
                    title: "Sted",
                    url: root + "/place/<new>/edit",
                },
                {
                    title: "Vert",
                    url: root + "/person/<new>/edit",
                },
                {
                    title: "Program",
                    url: root + "/program/<new>/edit",
                },
                {
                    title: "Produkt",
                    url: root + "/product/<new>/edit",
                }
            ]
        }
    ]

    
}

export function getAppMenu(app) {
    const { root, collectionId } = app;

    const navHome = {
        title: "Museer og bygninger",
        icon: "dashboard",
        url: root,
    }

    const navContent = {
        role: "group",
        hidden: true,
        children: [
            {
                title: "Bookinger",
                icon: "view_stream",
                url: root + "/booking",
                query: {
                    collectionId: collectionId,
                    models: "documents",
                    status: "NOT trash",
                    documentType: "booking"
                }
            },
            {
                role: "group",
                title: "Steder",
                icon: "room",
                url: root + "/place",
                query: {
                    collectionId: collectionId,
                    models: "documents",
                    status: "NOT trash",
                    documentType: "place"
                }
            },
            {
                role: "group",
                title: "Verter",
                icon: "person",
                url: root + "/person",
                query: {
                    collectionId: collectionId,
                    models: "documents",
                    status: "NOT trash",
                    documentType: "person"
                }
            },
            {
                title: "Kunder",
                icon: "view_stream",
                url: root + "/client",
                query: {
                    collectionId: collectionId,
                    models: "documents",
                    status: "NOT trash",
                    documentType: "client"
                }
            }
        ]
    }

   return [
       navHome,
       navContent,
   ]
    
}