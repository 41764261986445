import { API } from '../settings';
import { requestModel, receiveModel } from '../actions/model';

/*

export const addMediaSource = (model, callback) => {
    const { collectionId, sourceId } = model;    

    let url = API + '/admin/api/media/search?collectionId=' + collectionId + "&sourceId=" +sourceId;

    return function (dispatch) {
        fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {

            if (data.models && data.models[0]) {
                dispatch(receiveModel(data.models[0].uniqueId, data.models[0]))
                callback && callback(data.models[0])
            } else {
                dispatch(saveMedia(model, callback))
            }

        })

    }

}

*/

export const saveMedia = (model, callback = undefined) => {
    const url = API + '/admin/api/media'
    const payload = JSON.stringify(model);

    console.log(payload);

    return function (dispatch) {
        fetch(url, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
            body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveModel(data.uniqueId, data))
            callback && callback(data)
        })
    }

}

export const formatMediaSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

var gcd = function(a, b) {
//    if (b < 0.0000001) return a;                // Since there is a limited precision we need to limit the value.
    if (b < 0.1) return a;                // Since there is a limited precision we need to limit the value.
    return gcd(b, Math.floor(a % b));           // Discard any fractions due to limitations in precision.
}

export const formatMediaRatio = (fraction, precision = 100) => {

    fraction = Math.floor(fraction*precision)/precision
    
    var len = fraction.toString().length - 2;
    
    var denominator = Math.pow(10, len);
    var numerator = fraction * denominator;
    
    var divisor = gcd(numerator, denominator);    // Should be 5

    numerator /= divisor;                         // Should be 687
    denominator /= divisor;                       // Should be 2000

    return Math.floor(denominator) + ':' + Math.floor(numerator);
//    return Math.floor(numerator) + ':' + Math.floor(denominator);

};

export const formatMediaTime = (timeInSeconds) => {

    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

//    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);

    if (hours) {
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
    }

    return minutes + ':' + pad(seconds, 2);
}

export const getMediaPreview = ({formData, formContext, t}) => {

    const defaultLocale = formContext && formContext.defaultLocale
    const currentLocale = formContext && formContext.currentLocale || defaultLocale
    const localeId = "locale:" + currentLocale

    // content

    const content = formData && formData.content;
    const headline = content && content.headline && content.headline[localeId] || undefined
    const caption = content && content.caption && content.caption[localeId] || undefined

    // byline

    const category = content && content.category
//    const byline = content && content.byline
//    const bylineTitle = t("bylineTitle:"+category) || content.bylineTitle

    // credits

    const credits = content && content.credits || []

    let creditLine = credits && credits.map(({name, type}) => {

        if (!type || type === "undefined") {
            return name
        }

        const bylineTitle = t("bylineTitle:"+type) || type

        return bylineTitle + ": " + name

    })

    creditLine = creditLine.join(', ')

    // license + copyright

    const license = content && content.license
    const licenseLabel = t("license:" + license) || license 

    const copyright = content && content.copyright
    const copyrightYear = content && content.copyrightYear

    // metadata

    const { mediaType, mediaWidth, mediaHeight, mediaSize, mimeType, resources } = formData

    let dimensions, aspectRatio;

    if (mediaWidth && mediaHeight) {
        aspectRatio = formatMediaRatio(mediaHeight/mediaWidth)
        dimensions = mediaWidth + "×" + mediaHeight
    }

    const filename = content.filename || undefined
    const size = mediaSize && formatMediaSize(mediaSize) || undefined
    const duration = content.duration && formatMediaTime(content.duration) || undefined

    // audio

    let sourcesByType = {}

    if (mediaType === "audio" && resources) {
        Object.values(resources).map(({mimeType, url}) => {
            if (mimeType.startsWith('audio')) {
                sourcesByType[mimeType] = url
            }
        })
    }

    // video

    if (mediaType === "video" && resources) {
        Object.values(resources).map(({mimeType, url}) => {
            if (mimeType.startsWith('video')) {
                sourcesByType[mimeType] = url
            }
        })
    }
    
    return {
        ...formData,
        sourcesByType: sourcesByType,
        category: category,
        headline: headline,
        caption: caption,
        credits: credits,
        creditLine: creditLine,
 //       byline: byline,
 //       bylineTitle: bylineTitle,
        license: license,
        licenseLabel: licenseLabel,
        copyright: copyright,
        copyrightYear: copyrightYear,
        filename: filename,
        size: size,
        dimensions: dimensions,
        aspectRatio: aspectRatio,
        duration: duration
    }

}