import React from "react"
import schema from "./schema";
import uiSchema from "./uiSchema";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    preview: {
        margin: theme.spacing(1),
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
//        pointerEvents: "none"
    },
    iframe: {
        backgroundColor: "white",
    },
}));

const PreviewTemplate = ({formData}) => {
    const sourceUrl =  "//digitaltmuseum.no/" + formData.sourceId

    const classes = useStyles()

    return (
        <div className={classes.preview}>
            <iframe className={classes.iframe} width="100%" height="100%" src={sourceUrl} />
        </div>
    )
    return (
        <p>{sourceUrl}</p>
    )

    
    return (
        <p>x</p>
    )

}



export default {
    id: 1,
    schemaType: 'document',
    name: 'ekultur',
    schema: schema,
    uiSchema: uiSchema,
    preview: {
        template: PreviewTemplate
    }
}