export function getUrlFromProps(props, urlPattern) {

    if (!urlPattern) {
        return undefined;
    }
    
    // get url from urlPattern
    
    const urlParts = urlPattern.split('/');
    
    const url = urlParts.map((name) => {
        
        let value = name;
        
        if (name.startsWith(":")) {
            name = name.substr(1);
            value = props[name] || undefined;
        }
        
        return value;
        
    })

    return url.join("/");

}