import { API } from '../../redux/settings';
import { requestSchema, receiveSchema } from '../../redux/actions/schema';

export function getCollectionSchema({siteName, collectionName, name, uniqueId}) {
    const url = API + '/admin/api/schemas/' + uniqueId;

    const pathname = "/" + siteName + "/" + collectionName + "/" + name;

    return function (dispatch) {
        dispatch(requestSchema({pathname: pathname}));

        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                },
            })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(
            data => dispatch(getCollectionSchemaData({...data, pathname: pathname}))
        )
    }
}

export function getCollectionSchemaData(formData) {
    const schema = formData && formData.content && getSchema(formData.content)
    const uiSchema = formData && formData.content && getUiSchema(formData.content)
    const uiPreview = formData && formData.content && formData.content.uiPreview && getUiPreview(formData.content.uiPreview)
    const preview = formData && formData.content && getEditorPreview(formData.content);

    return function (dispatch) {
        dispatch(receiveSchema({
            ...formData,
            schema: schema,
            uiSchema: uiSchema,
            uiSchema: {
                ...uiSchema,
                "ui:preview": uiPreview
            },
            preview: preview
        }));
    }

  
}

export const getEditorPreview = ({preview}) => {

    return {
        template: preview && preview.template,
        format: preview && preview.format,
        url: preview && preview.url,
        targetOrigin: preview && preview.targetOrigin
    }

}

export const getUiPreview = ({select, prepare}) => {

    let uiPreview = {}

    if (select) {
        try {
            uiPreview.select = JSON.parse(select);
        } catch(error) {
            uiPreview.select = {}
        }
    }

    if (prepare) {
        uiPreview.prepare = new Function(["props"], prepare);  
    }

    return uiPreview
    
}

export const getUiSchema = ({uiSchema}) => {

    let contentUiSchema = {};

    try {
        contentUiSchema = JSON.parse(uiSchema);
    } catch(error) {
        return false
    }

    return {
        "ui:field": "pageEditor",
        "ui:fieldset": [
            "content",
        ],
        "content": {
            "ui:field": "pageContent",
            ...contentUiSchema
        }
    }

}

export const getSchema = ({schema}) => {

    let contentSchema = {};

    try {
        contentSchema = JSON.parse(schema);
    } catch(error) {
        return false
    }

    return {
        type: 'object',
        properties: {
            title: {
                type: "string",
                default: "Untitled"
            },
            content: contentSchema
        }
    }
    
}