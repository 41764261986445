import React, { Component } from 'react';
import { ChartLine } from '../'

import TooltipCompare from "./ChartLineCompareTooltip"

import "./ChartLineCompare.scss"

class ChartLineCompare extends Component {

    static defaultProps = {
        enableSlices: "x",
        useMesh: false
    }

    getColors = () => {
        const { theme } = this.props;
        
        return [
            theme.primaryColor || "black",
            "#999"
        ]
        
    }
    
    renderTooltip = ({slice}) => {
        
        return (
            <TooltipCompare {...this.props} slice={slice} />
        )
        
    }
    
    getData = () => {
        const { data } = this.props;
        
        if (data && data.data) {
            
            const reverse = data.data.reverse()
            
            return {
                ...data,
                data: reverse
            }
        }
        
        return data
        
        
    }

    render() {
        const colors = this.getColors()
        const data = this.getData();
        
        return (
            <ChartLine {...this.props} data={data} colors={colors} renderTooltip={this.renderTooltip} />
        )
    
    }

}

export default ChartLineCompare