import { getReferenceField } from "./utils"
import FieldReference from "./FieldReference"

export default {
    "fieldtypes": {
        "reference": getReferenceField
    },
    "fields": {
        "reference": FieldReference,
    },
    "layouts": {
    },
    "widgets": {
    }
}