export default {
    "ui:title": "[count] fargeprofiler",
    "ui:help": "Legg inn fargeprofiler for å gjøre dem tilgjengelig i alle applikasjoner.",
    "items": {
        "ui:layout": "module",
        "ui:title": {
            "select": "name"
        },
        "ui:color": {
            "select": "value"
        },
        "ui:description": {
            "select": "value"
        },
        "ui:options": {
            "collapsible": true,
            "editable": true
        },
        "value": {
            "ui:widget": "colorpicker"
        }
    }
}