import React, { useRef, useEffect, useState } from 'react';
import _ from "lodash"
import { makeStyles } from '@material-ui/core/styles';

import ImagePreview from "./ImagePreview"
import FiltersOptions from "./ImageFiltersOptions"

const useStyles = makeStyles(theme => ({
    root: {
//        backgroundColor: "#333",
//        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    preview: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
//        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(14)
    },
    footer: {
        position: "absolute",
        top: "auto",
        right: 0,
        left: 0,
        bottom: 0,
        paddingTop: theme.spacing(2),
        height: theme.spacing(14),
    }
}));


const EditImageFilters = ({className, imageUrl, imageCropdata = {}, imageFilters = {}, style, menu = [], onChange, ...props}) => {
    const classes = useStyles()

    const handleChange = (imageFilters) => {
        onChange && onChange(imageFilters)
    }

    return (
        <div className={className || classes.root}>
            <div className={classes.preview}>
                <ImagePreview imageCropdata={imageCropdata} imageFilters={imageFilters} imageUrl={imageUrl} />
            </div>
            <footer className={classes.footer}>
                <FiltersOptions imageUrl={imageUrl} imageFilters={imageFilters} onChange={handleChange} />
            </footer>
        </div>
    )

}

export default EditImageFilters;