import React from "react";
import Color from 'color';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const getInitialsFromName = ({name}) => {

    let parts = name.split(' ')
    let initials = []

    parts.map(part => {
        initials.push(part.substr(0, 1).toUpperCase())
    })

    return initials.join('')

}

const UserIcon = ({className, defaultColor = "#000", color, label, imageUrl, name, initials}) => {
    let fill = color || defaultColor
    let text = label || initials || name && getInitialsFromName({name})
    
    fill = Color(fill);

    let contrast

    if (fill.isDark()) {
        contrast = 'white';
    } else {
        contrast = 'black';
    }

    if (imageUrl) {
        return (
            <SvgIcon className={className} title={name}>
                <defs>
                    <clipPath id="circle">
                        <circle cx="12" cy="12" r="12"></circle>
                    </clipPath>
                </defs>
                <image href={imageUrl} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" clipPath="url(#circle)" />
                <text x="12" y="12.5" fill={contrast} fontFamily="Akkurat, sans-serif" fontSize="9" fontWeight="normal" dominantBaseline="middle" textAnchor="middle">{text}</text>
            </SvgIcon>
        )

    }

    return (
        <SvgIcon className={className} title={name}>
            <image href={imageUrl} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMinYMin slice"/>
            <circle cx="12" cy="12" r="12" fill={fill}></circle>
            <text x="12" y="12.5" fill={contrast} fontFamily="Akkurat, sans-serif" fontSize="9" fontWeight="normal" dominantBaseline="middle" textAnchor="middle">{text}</text>
        </SvgIcon>
    )

}

export default UserIcon