const initialState = {
    widgets: [
        "user/apps",
        "user/profile",
        "user/inbox",
        "user/calendar",
    ],
    "user/apps": {
        id: "user/apps",
        expanded: true,
        widgetSize: "4:1"
    },
    "user/profile": {
        id: "user/profile",
        expanded: true,
        widgetSize: "1:1"
    },
    "user/inbox": {
        id: "user/inbox",
        expanded: true,
        widgetSize: "3:1"
    },
    "user/calendar": {
        id: "user/calendar",
        expanded: false,
        widgetSize: "1:1"
    }
}

// app state

const user = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'REQUEST_DASHBOARD':
            return initialState
            
        case 'ADD_WIDGET':
            return {
                ...state,
                widgets: [ ...state.widgets, action.id],
                [action.id]: {
                    ...action.data,
                    addable: false
                }
            }

        case 'REMOVE_WIDGET':
            const prunedIds = state.widgets.filter(item => {
                return item !== action.id // return all the items not matching the action.id
            })
            
            delete state[action.id] // delete the hash associated with the action.id
            
            return {
                ...state,
                widgets: prunedIds
            }

    
        case 'TOGGLE_WIDGET':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    expanded: !state[action.id].expanded
                }
            }

        case 'EDIT_WIDGET':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    [action.name]: action.value
                }
            }

        default:
            return state

    }

}

export default user;