export const REQUEST_UPLOAD = 'REQUEST_UPLOAD'
export const RECEIVE_UPLOAD = 'RECEIVE_UPLOAD'

export const REQUEST_UPLOAD_MODEL = 'REQUEST_UPLOAD_MODEL'
export const RECEIVE_UPLOAD_MODEL = 'RECEIVE_UPLOAD_MODEL'

export const REQUEST_MODEL_UPLOAD_STATUS = 'REQUEST_MODEL_UPLOAD_STATUS'
export const RECEIVE_MODEL_UPLOAD_STATUS = 'RECEIVE_MODEL_UPLOAD_STATUS'
export const RECEIVE_MODEL_UPLOAD_PROGRESS = 'RECEIVE_MODEL_UPLOAD_PROGRESS'

export const REQUEST_UPLOAD_ARTWORK = 'REQUEST_UPLOAD_ARTWORK'
export const RECEIVE_UPLOAD_ARTWORK = 'RECEIVE_UPLOAD_ARTWORK'

export function requestUpload(id) {

    return {
        type: REQUEST_UPLOAD,
        id: id,
    }
  
}

export function receiveUpload(id, data) {

    return {
        type: RECEIVE_UPLOAD,
        id: id,
        data: data,
    }
  
}

export function requestUploadModel(id, data) {

    return {
        type: REQUEST_UPLOAD_MODEL,
        id: id,
        data: data,
    }
  
}

export function receiveUploadModel(id, data) {

    return {
        type: RECEIVE_UPLOAD_MODEL,
        id: id,
        data: data,
    }

}

export function requestUploadStatus(id, data) {

    return {
        type: REQUEST_MODEL_UPLOAD_STATUS,
        id: id,
        data: data
    }
  
}
export function receiveUploadStatus(id, data) {

    return {
        type: RECEIVE_MODEL_UPLOAD_STATUS,
        id: id,
        data: data
    }
  
}

export function receiveUploadProgress(id, uploadProgress) {

    return {
        type: RECEIVE_MODEL_UPLOAD_PROGRESS,
        id: id,
        uploadProgress: uploadProgress
    }
  
}

export function requestUploadArtwork(id) {

    return {
        type: REQUEST_UPLOAD_ARTWORK,
        id: id
    }
  
}
export function receiveUploadArtwork(id, artwork = []) {

    return {
        type: RECEIVE_UPLOAD_ARTWORK,
        id: id,
        artwork: artwork
    }
  
}