import React, { Component } from "react";
import SchemaLayout from "./SchemaLayout";

import * as types from "../utils/types";

import {
    getWidget,
    getUiOptions,
    optionsList,
    getDefaultRegistry,
} from "../utils/";

class BooleanField extends Component {

    static propTypes = types.fieldProps
    
    static defaultProps = {
        uiSchema: {},
        disabled: false,
        readonly: false,
        autofocus: false,
    }
    
    onChange = (value) => {
        
        if (value === "false") {
            value = false;            
        }
        
        value = Boolean(value)

        this.props.onChange(value)
    }
    
    render() {

        const {
            schema,
            name,
            uiSchema,
            uiOptions,
            idSchema,
            formData,
            registry = getDefaultRegistry(),
            required,
            disabled,
            readonly,
            autofocus,
            onChange,
            onFocus,
            onBlur,
            rawErrors,
        } = this.props;

        const { title } = uiOptions || schema;
        const { widgets, formContext } = registry;
        const { widget = "checkbox", ...options } = getUiOptions(uiSchema);
        const Widget = getWidget(schema, widget, widgets);
    
        let enumOptions;
    
        if (Array.isArray(schema.oneOf)) {
            enumOptions = optionsList({
                oneOf: schema.oneOf.map(option => ({
                    ...option,
                    title: option.title || (option.const === true ? "yes" : "no"),
                })),
            });
        } else {
            enumOptions = optionsList({
                enum: schema.enum || [true, false],
                enumNames:
                    schema.enumNames ||
                    (schema.enum && schema.enum[0] === false
                        ? ["no", "yes"]
                        : ["yes", "no"]),
            });
        }
    
        return (
            <SchemaLayout {...this.props}>
                <Widget
                    options={{ ...options, enumOptions }}
                    schema={schema}
                    id={idSchema && idSchema.$id}
                    onChange={this.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    label={title === undefined ? name : title}
                    value={formData}
                    required={required}
                    disabled={disabled}
                    readonly={readonly}
                    registry={registry}
                    formContext={formContext}
                    autofocus={autofocus}
                    rawErrors={rawErrors}
                />
            </SchemaLayout>
        )
        
    }
    
}

export default BooleanField;
