import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';

import NavToolbar from "../NavToolbar/NavToolbar"
import NavEditbar from "../NavEditbar/NavEditbar"

import ModuleLabel from "../Module/ModuleLabel"

import InboxMessageHeader from "./InboxMessageHeader"
import InboxMessageEditor from "./InboxMessageEditor"
import InboxMessageReply from "./InboxMessageReply"

import getInboxMessage from "./utils/getInboxMessage"

const useStyles = makeStyles(theme => ({
    root: {
        display: "block",
        width: "100%",
        position: "relative",
        paddingTop: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
        "& + $root": {
            borderTop: "1px solid",
            borderColor: "#f2f2f2",
        },

        "&[data-status=unread]": {
            "& $name": {
                fontWeight: "bold"
            }
        }

    },
    content: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(1.5),
    },
    toolbar: {
        position: "absolute",
        top: 0,
        right: 0,
        margin: theme.spacing(1)
    },
    body: {
        maxWidth: "100%",
        overflow: "hidden",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),

        "& > p + p": {
            marginTop: theme.spacing(2),
        }

    },
    preview: {
        display: "flex",
        flexWrap: "nowrap",
        maxWidth: "100%",
        "& > * + *": {
            marginLeft: theme.spacing(.5),
        }
    },
    excerpt: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        opacity: "0.75",
    }

}));

const InboxMessage = ({editing, replying, expanded, reply, onToggle, toolbar = [], buttons = [], onChangeMessage, onChangeReply, ...props}) => {
    const classes = useStyles()
    const { label, labelColor, status, author, message, markdown } = getInboxMessage(props)

    if (editing) {
        return (
            <article className={classes.root}>
                <InboxMessageHeader {...props} status={status} expanded={expanded} author={author} onClick={onToggle} />
                <div className={classes.content}>
                    <InboxMessageEditor className={classes.body} value={message} onChange={onChangeMessage} />
                    <NavEditbar buttons={buttons} />
                </div>
            </article>
        )
        
    }

    if (replying) {
        return (
            <article className={classes.root}>
                <InboxMessageHeader {...props} status={status} expanded={expanded} author={author} onClick={onToggle} />
                <div className={classes.content}>
                    <ReactMarkdown className={classes.body} source={markdown} />
                </div>
                <InboxMessageReply {...reply} buttons={buttons} onChange={onChangeReply} />
            </article>
        )
    }

    if (expanded) {
        return (
            <article className={classes.root}>
                <InboxMessageHeader {...props} status={status} expanded={expanded} author={author} onClick={onToggle} />
                <div className={classes.content}>
                    <ReactMarkdown className={classes.body} source={markdown} />
                    <NavEditbar buttons={buttons} />
                </div>
                <NavToolbar className={classes.toolbar} toolbar={toolbar} />
            </article>
        )
    }

    return (
        <article className={classes.root} aria-expanded={false} onClick={onToggle}>
            <InboxMessageHeader {...props} status={status} expanded={expanded} author={author} />
            <div className={classes.content}>
                <Typography noWrap className={classes.preview}>
                    <ModuleLabel label={label} labelColor={labelColor} />
                    <span className={classes.excerpt}>{message}</span>
                </Typography>
            </div>
        </article>
    )        

}

export default InboxMessage