import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Widget, WidgetHeader, WidgetBody, WidgetFooter, WidgetTitle, WidgetLoading, FormatMetric } from "../"
import { Chart } from "../"

import _ from "lodash"

import { getGaChartByType } from "../../resources/functions/"

import "./AnalyticsReportCountries.scss"

class AnalyticsReportCountries extends Component {

    static propTypes = { 
        title: PropTypes.string,
        loadingTitle: PropTypes.string,
        loadingDescription: PropTypes.string,
        scope: PropTypes.string
    }
    
    static defaultProps = {
        reportType: "countries",
        widgetType: "analytics-countries",
        widgetSize: "1:4",
        report: undefined,
        title: "Besøk etter land",
        loadingTitle: "Besøk etter land"
    }

    state = {
        loading: true,
        chart: undefined,
        values: {}
    }

    componentDidMount() {
        this.getChart()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.report !== this.props.report) {
            this.getChart()
        }
    }
    
    getChart = () => {
        const { report } = this.props;
        
        if (!report) {
            return false
        }
        
        const chart = getGaChartByType("choropleth", report)
        
        chart.data = _.orderBy(chart.data, 'value', 'desc'); // Use Lodash to sort array by 'value'
        
        this.setState({
            chart: chart,
            loading: false
        })
        
    }
    
    renderChart = () => {
        const { theme } = this.props;
        const { chart } = this.state;

        return (
            <Chart {...chart} theme={theme} type="choropleth" projectionScale={50} />
        )
        
    }
    
    renderItem = (item, index) => {
        const { theme } = this.props;
        const { total } = this.state.chart;
        
        let { name, value, id } = item;
        
        const percentage = value / total * 100;

        const barStyle = {
            backgroundColor: theme.primaryColor || 'black',
            width: percentage + "%"
        }
        
        if (!name) {
            name = "N/A"
        }
        
        return (
            <li key={index} className="list-item">
                <b className="list-item__label">{name}</b>
                <i className="sparkline">
                    <i className="sparkline__bar" style={barStyle} data-value={value}></i>
                </i>
                <b className="list-item__value"><FormatMetric type="PERCENT" value={percentage} decimals="1" /></b>
            </li>
        )
        
    }
    
    renderList = () => {
        const { data } = this.state.chart;
        
        const top5 = data.slice(0, 5);
        
        return (
            <ul className="list">
                { top5.map(this.renderItem) }
            </ul>
        )
        
    }
    
    render() {
        const { theme, reportType, scope, title, loadingTitle, loadingDescription } = this.props;
        const { chart } = this.state;

        const loading = this.state.loading || this.props.loading;

        if (loading) {
            return (
                <Widget {...this.props}>
                <WidgetLoading theme={theme} title={loadingTitle} description={loadingDescription} />            
                </Widget>
            )
        }

        return (
            <Widget {...this.props}>
                <WidgetHeader>
                    <WidgetTitle title={title} />
                </WidgetHeader>
                { this.renderChart() }
                <WidgetBody>
                    { this.renderList() }
                </WidgetBody>
            </Widget>
        )


    }

}

export default AnalyticsReportCountries;