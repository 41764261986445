import React, { Component } from 'react';

/**
 * Wrapper component for action area.
 */

class AdminAction extends Component {
    componentDidMount() {
        require('./AdminAction.scss');
    }

    render() {
        const { expanded } = this.props;

        return (
            <section className="admin__action" aria-expanded={expanded}>
                {this.props.children}
            </section>
        )
    }
}

export default AdminAction;