import React, { Component } from 'react';
import { ResponsiveChoropleth, ResponsiveGeoMap } from '@nivo/geo'
import countries from "./geo/countries.json";

import "./ChartLine.scss"

class ChartChoropleth extends Component {

    static defaultProps = {
        data: [],

        features: countries.features,
        colors: undefined,

        projectionScale: 100,

        projectionTranslation: [ 
            0.5, 0.5
        ],

        projectionRotation: [
            0, 0, 0
        ],

        enableGraticule: false,
        graticuleLineColor: "#dddddd",
        
        label: "properties.name",
        
        unknownColor: "#eeeeee",
        
        borderWidth: 0.25,
        borderColor: "#ffffff",

        domain: [0, 1000000],
        margin: {
            top: 0, 
            right: 0, 
            bottom: 0, 
            left: 0
        }

    }
    
    getDomain = () => {
        let { domain, minimum, maximum } = this.props;
        
        if (minimum && maximum) {
            domain = [0, maximum]
        }
        
        return domain;
        
    }
    
    getProps = () => {
        
        return {
            ...this.props,
            domain: this.getDomain()
        }
        
    }
    
    render() {
        const props = this.getProps()
        
        return (
            <ResponsiveChoropleth {...props} />
        )
    
    }

}

export default ChartChoropleth