const initialState = {
    uniqueId: undefined,
}

// app state

const user = (state = initialState, action) => {

	switch (action.type) {
  	
    case 'REQUEST_USER':
        return initialState

    case 'RECEIVE_USER':
        return action.data

    /*

    case 'RECEIVE_USER_LOCALE':
        return {
            ...state,
            defaultLocale: action.locale
        }

    case 'REQUEST_USER_ROLES':
    
  		return Object.assign({}, state, {
    		roles: []
  		})

    case 'RECEIVE_USER_ROLES':
    
  		return Object.assign({}, state, {
    		roles: action.roles
  		})

    case 'REQUEST_USER_COLLECTIONS':
    
  		return Object.assign({}, state, {
    		collections: []
  		})

    case 'RECEIVE_USER_COLLECTIONS':
    
  		return Object.assign({}, state, {
    		collections: action.collections
  		})

    case 'REQUEST_USER_SITES':
    
  		return Object.assign({}, state, {
    		sites: []
  		})

    case 'RECEIVE_USER_SITES':
    
  		return Object.assign({}, state, {
    		sites: action.sites
  		})

    case 'REQUEST_USER_APPS':
    
  		return Object.assign({}, state, {
    		apps: []
  		})

    case 'RECEIVE_USER_APPS':
    
  		return Object.assign({}, state, {
    		apps: action.apps
  		})

    case 'REQUEST_APPS_BY_SITE':
    
  		return Object.assign({}, state, {
    		owners: []
  		})

    case 'RECEIVE_APPS_BY_SITE':
    
  		return Object.assign({}, state, {
    		owners: action.appsBySite
          })
          
    */

    case 'LOGIN':
    
      return action.data

    case 'LOGOUT':
    
      return initialState

    default:

      return state

    }

}

export default user;