const initialState = {
    uniqueId: undefined,
    theme: {
        primaryColor: undefined
    }
}

// app state

const site = (state = initialState, action) => {

	switch (action.type) {

    case 'REQUEST_SITE':

  		return Object.assign({}, initialState, {
  		})

    case 'RECEIVE_SITE':
    
      return action.data
  	
    default:

      return state

    }

}

export default site;