export default {
    "type": "string",
    "format": "size",
    "enum": [
      "small",
      "medium",
      "large",
      "xl"
    ],
    "enumNames": [
      "Small",
      "Medium",
      "Large",
      "XL"
    ],
    "default" : "small"
}