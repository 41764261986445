import React, { Component } from 'react';
import { Button } from '../Button/';
import './ButtonPreview.scss'

class ButtonPreview extends Component {

    static defaultProps = {
        label: "Preview",
        icon: "code"
    };

    render() {
        return (
            <Button {...this.props} className="admin-button  admin-button--preview" />
        )
    }
}

export default ButtonPreview;