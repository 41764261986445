import moment from 'moment';
import countries from "i18n-iso-countries";

import { getGaDefaultDimension, getGaDefaultMetric, getGaDateRanges, getDimensionNames, getMetricNames, getMetricTypeByName } from "./gaV4"

countries.registerLocale(require("i18n-iso-countries/langs/nb.json"));
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

export function getGaChoroplethByCountry(report = undefined, metric = undefined, dimension = undefined) {

    if (!report) {
        return {
            error: "no report"
        }
    }

    // get defaults

    if (!dimension) {
        dimension = getGaDefaultDimension(report);
    }

    if (!metric) {
        metric = getGaDefaultMetric(report);
    }
    
    // get dateRanges

    const dateRanges = getGaDateRanges(report)

    if (!dateRanges) {
        return {
            error: "No dateRanges"
        }
    }

    // get unique series by dateRange and dimensions

    const { rows, totals, maximums, minimums } = report.data;
    
    let series = {}

    const dimensionNames = getDimensionNames(report)
    const metricNames = getMetricNames(report)
    const metricType = getMetricTypeByName(report, metric)
    
    let minimum = undefined, maximum = undefined;
    
    dateRanges.map((range, index) => {
        
        rows.map((row, index) => {
            
            let serie = {}
            let serieId = [];
            
            serieId.push(range.startDate);
            serieId.push(range.endDate);
            
            // get dimensions + x
            
            row.dimensions.map((dValue, dIndex) => {
                const dName = dimensionNames[dIndex];
                
                serieId.push(dValue)
                
                if (dName === "ga:countryIsoCode") {
                    serie.id = countries.alpha2ToAlpha3(dValue) || dValue                    
                    serie.name = countries.getName(dValue, "nb")                    
                } else if (dName === dimension) {
                    serie.id = dValue
                }

//                serie[dName] = dValue
                
            })
            
            const { values } = row.metrics[range.id];
            
            values.map((mValue, mIndex) => {
                const mName = metricNames[mIndex];
                
                if (mName === metric) {
                    serie.value = parseFloat(mValue)
                }

            })

            // minimum
            
            if (serie.value && !minimum) {
                minimum = serie.value 
            }

            if (serie.value < minimum) {
                minimum = serie.value
            }
            
            // maximum

            if (serie.value && !maximum) {
                maximum = serie.value 
            }

            if (serie.value > maximum) {
                maximum = serie.value
            }

            // set id            
            
            let id = serieId.join('-')
            
            // push serie to series
            
            if (!series[id]) {
                series[id] = serie
            }
            
            
        })
        
    })
    
    return {
        ...dateRanges[0],
        xFormat: "STRING",
        yFormat: metricType,
        metric: metric,
        dimension: dimension,
        data: Object.values(series),
        minimum: minimums[0].values[0],
        maximum: maximums[0].values[0],
        total: totals[0].values[0]
    }
    
}