import React from 'react';
import { CustomPicker } from 'react-color';
import { EditableInput } from 'react-color/lib/components/common';
import TextField from '@material-ui/core/TextField';

import color from 'react-color/lib/helpers/color'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: "Akkurat Mono, monospace",
        backgroundColor: props => { return props.backgroundColor },
        color: props => { return props.color },

        "& input": {
            border: "none",
            backgroundColor: "transparent",
            color: "inherit",

            fontFamily: "inherit",
            fontSize: "inherit",
            lineHeight: "1",

            width: "100%",
            minHeight: theme.spacing(7),
            textAlign: "center"
        }
    },
}));

const ColorInput = ({className, hex, rgb, hsl, hsv, onChange}) => {

    const handleChange = ({hex}, e) => {

        if (!color.isValidHex(hex)) {
            return false
        }

        onChange && onChange({hex: hex})
    }

    const getContrastColor = () => {

        if (!color.isValidHex(hex)) {
            return false
        }

        if (hex === 'transparent') {
            return 'rgba(0,0,0,0.4)'
        }
    
        const yiq = ((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000
        return (yiq >= 128) ? '#000' : '#fff'
        
    }    

    const classes = useStyles({backgroundColor: hex, color: getContrastColor(hex)})

    return (
        <div className={className || classes.root}>
            <EditableInput hideLabel={true} label="hex" placeholder="HEX" value={hex} onChange={handleChange} />
        </div>
    )

}

export default CustomPicker(ColorInput);
