import { API } from '../../redux/settings';
import { requestCollection, receiveCollection } from '../../redux/actions/collection';
import { getCollectionSchema } from "./getCollectionSchema"
//import { getApp } from '../../redux/functions/app';

export function getCollectionByName(name) {
    let url = API + '/admin/api/collections/search?name=' + encodeURIComponent(name);

    return function(dispatch) {
        dispatch(requestCollection());

        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(
            data => {
                dispatch(receiveCollection(data.models[0]))
                dispatch(getCollectionSchemas(data.models[0]))
            }
        )
    }
  
}

export function getCollectionSchemas(collection) {
    const siteName = collection && collection.siteName;
    const collectionId = collection && collection.id;
    const collectionName = collection && collection.name;

    let url = API + '/admin/api/schemas/search?collectionId=' + collectionId

    return function(dispatch) {

        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(
            data => {

                data.models && data.models.map(schema => {
                    dispatch(getCollectionSchema({...schema, siteName: siteName, collectionName: collectionName}))
                })

            }
        )
    }    

}
