const initialState = []

const appsBySite = (state = initialState, action) => {

    switch (action.type) {
    	
        case 'REQUEST_APPS_BY_SITE':
            return []
        
        case 'RECEIVE_APPS_BY_SITE':
            return action.appsBySite
        
        default:
            return state
    
    }

}

export default appsBySite;