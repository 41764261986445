import React, { Component } from 'react';
import Button from './Button';

import "./ButtonSetting.scss";

class ButtonSetting extends Component {
  
  getClassName = () => {

    const { name } = this.props;
    
    let classNames = []
    
    classNames.push('admin-button');
    classNames.push('admin-button--setting');
    
    if (name) {
      classNames.push('admin-button--setting-' + name.replace('_', ''))
    }

    return classNames.join(' ');    
    
  }

  render() {
    
    const className = this.getClassName()

    return (
      <Button { ...this.props } className={ className } />
    );

  }

}

// export default

export default ButtonSetting;
