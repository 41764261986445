// initialState

let initialState = {
    isLoading: true,
    isSaving: false,
    redirect: undefined,
    id: undefined,
    parentId: undefined,
    uniqueId: undefined,
    errorCode: "",
    error: {
    },
    schemaId: undefined,
    schema: undefined,
    uiSchema: undefined,
    formData: {},
    formContext: {
        currentId: undefined,
        currentLocale: undefined,
        languages: ['en'],
        preview: {}
    },
};

// editor state

const editor = (state = initialState, action) => {

	switch (action.type) {
  	
        case 'REQUEST_EDITOR':
    
            return initialState

        case 'RECEIVE_EDITOR':
            
            return {
                ...state,
                ...action.editor
            }    

        // redirect

        case 'REQUEST_EDITOR_REDIRECT':
        
            return {
                ...state,
                redirect: undefined
            }
            
        case 'RECEIVE_EDITOR_REDIRECT':
        
            return {
                ...state,
                redirect: action.redirect
            }
        
        // schema

        case 'REQUEST_EDITOR_SCHEMA':
        
            return {
                ...state,
                schema: {}
            }
            
        case 'RECEIVE_EDITOR_SCHEMA':
        
            return {
                ...state,
                schema: action.schema
            }

        // uiSchema

        case 'REQUEST_EDITOR_UISCHEMA':
        
            return {
                ...state,
                uiSchema: {}
            }

        case 'RECEIVE_EDITOR_UISCHEMA':
        
            return {
                ...state,
                uiSchema: action.uiSchema
            }

        // formContext

        case 'REQUEST_EDITOR_FORMCONTEXT':
        
            return {
                ...state,
                formContext: {}
            }

        case 'RECEIVE_EDITOR_FORMCONTEXT':

            return {
                ...state,
                formContext: action.formContext
            }

        // formData

        case 'REQUEST_EDITOR_FORMDATA':
        
            return {
                ...state,
                formData: {}
            }

        case 'RECEIVE_EDITOR_FORMDATA':

            return {
                ...state,
                formData: action.formData
            }

        // load, save, edit

        case 'REQUEST_EDITOR_LOAD':
        
            return {
                ...initialState,
                isLoading: true,
                uniqueId: undefined,
                formData: {}
            }

        case 'REQUEST_EDITOR_SAVE':
    
            return {
                ...state,
                isLoading: false,
                isSaving: true,
            }

        case 'REQUEST_EDITOR_EDIT':

            return {
                ...initialState,
                isLoading: false,
                uniqueId: null,
                formData: action.formData
            }
    
        case 'RECEIVE_EDITOR_EDIT':

            return {
                ...state,
                isLoading: false,
                isSaving: false,
                uniqueId: action.uniqueId,
                formData: action.formData
            }
    
        // change

        case 'RECEIVE_EDITOR_CHANGE':

            return {
                ...state,
                formData: action.formData,
            }


        // currentId

        case 'RECEIVE_EDITOR_CURRENT_ID':
        
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    currentId: action.id
                }
            }

        // parents

        case 'RECEIVE_EDITOR_PARENTS':
        
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    parents: action.parents
                }
            }

        // action 

        case 'RECEIVE_EDITOR_ACTION':
        
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    primaryAction: action.primaryAction
                }
            }

        // languages, locale

        case 'RECEIVE_EDITOR_LANGUAGES':
        
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    languages: action.languages
                }
            }

        case 'RECEIVE_EDITOR_LOCALE':
        
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    currentLocale: action.locale
                }
            }

        // formContext/dialog

        case 'REQUEST_EDITOR_DIALOG':
        
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    dialog: undefined
                }
            }

        case 'RECEIVE_EDITOR_DIALOG':
        
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    dialog: action.dialog
                }
            }

        case 'RECEIVE_EDITOR_DIALOG_FORMDATA':
    
            return {
                ...state,
                formContext: {
                    ...state.formContext,
                    dialog: {
                        ...state.formContext.dialog,
                        formData: action.formData
                    }
                }
            }
    
        default:
        
            return state

    }

}

export default editor;