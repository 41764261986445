import uiPreview from "./uiPreview"

import main from "./main/uiSchema"
import preview from "./preview/uiSchema"

export default {
    "ui:field": "pageEditor",
    "ui:preview": uiPreview,
    "ui:fieldset": [
        "content",
    ],
    "content": {
        "ui:field": "pageContent",
        "ui:nav": true,
        "ui:fieldset": [
            "main",
            "editSchema",
            "editUiSchema",
            "editLocale",
            "editFormData",
            "uiPreview",
            "preview",
            "deprecated"
        ],
        "main": main,
        "deprecated": {
            "ui:layout": "section",
            "ui:title": "DEPRECATED",
            "ui:icon": "",

            "ui:fieldset": [
                "JSONSchema",
                "preview"
            ],

            "JSONSchema": {
                "ui:title": "JSONSchema",
                "ui:widget": "textarea",
                "ui:help": "DEPREACTED, use schema"
            },

            "preview": {

                "ui:fieldset": [
                    "select",
                    "prepare"
                ],
    
                "select": {
                    "ui:widget": "textarea",
                    "ui:title": "Select",
                    "ui:help": "DEPREACTED, use uiPreview.select"
                },
                "prepare": {
                    "ui:widget": "textarea",
                    "ui:title": "Prepare(selection)",
                    "ui:help": "DEPREACTED, use uiPreview.prepare."
                }
            }
    
        },
        "editSchema": {
            "ui:layout": "section",
            "ui:icon": "menu",
            "ui:title": "Schema",
            "ui:fieldset": [
                "schema"
            ],
            "schema": {
                "ui:widget": "textarea",
            }
        },
        "editUiSchema": {
            "ui:layout": "section",
            "ui:icon": "menu",
            "ui:title": "uiSchema",
            "ui:fieldset": [
                "uiSchema"
            ],
            "uiSchema": {
                "ui:widget": "textarea",
            }
        },
        "editLocale": {
            "ui:layout": "section",
            "ui:icon": "menu",
            "ui:title": "Localize",
            "ui:fieldset": [
                "schemaLocalized",
                "uiSchemaLocalized",
            ],
            "schemaLocalized": {
                "ui:widget": "textarea",
            },
            "uiSchemaLocalized": {
                "ui:widget": "textarea",
            }
        },
        "editFormData": {
            "ui:layout": "section",
            "ui:icon": "menu",
            "ui:title": "formData",
            "ui:fieldset": [
                "formData"
            ],
            "formData": {
                "ui:widget": "textarea",
                "ui:help": "Paste some formData to test schema"
            }
        },
        "uiPreview": {
            "ui:layout": "section",
            "ui:icon": "menu",
            "ui:title": "uiPreview",
            "ui:options": {
                "grid": true,
                "spacing": 1
            },
            "select": {
                "ui:widget": "textarea",
                "ui:title": "Select",
                "ui:help": "A JSON object representing what will be used in previews."
            },
            "prepare": {
                "ui:widget": "textarea",
                "ui:title": "Prepare(selection)",
                "ui:help": "Return an object with properties to select for the preview."
            }

        },

        "preview": preview
    }
}