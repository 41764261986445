import React from 'react';
import { connect } from 'react-redux';

import DocumentEditor from "../AppDocuments/DocumentEditor"
import defaultRegistry from "./components/registry"

import PrimusWidget from "@frontend-components/admin/lib/components/AppPrimusFDV/components/PrimusPreviewWidget"

const registry = {
    "fieldtypes": {
        ...defaultRegistry.fieldtypes
    },
    "fields": {
        ...defaultRegistry.fields
    },
    "layouts": {
        ...defaultRegistry.layouts,
    },
    "widgets": {
        ...defaultRegistry.widgets,
        "date": PrimusWidget,
        "autocomplete": PrimusWidget,
        "radio": PrimusWidget,
        "text": PrimusWidget,
        "textarea": PrimusWidget,
        "select": PrimusWidget,
        "number": PrimusWidget,
        "primusPriority": PrimusWidget,
    }
}

const PrimusPreview = (props) => {
    const { app, editor } = props;

    const onEditChild = ({formData: { documentType, uniqueId}}) => {
        const childUrl = app.root + "/" + editor.formData.uniqueId + "/children/" + documentType + "/" + uniqueId + "/edit"
        props.history.push(childUrl);
    }

    const onCreateChild = ({documentType}) => {
        const childUrl = app.root + "/" + editor.formData.uniqueId + "/children/" + documentType + "/<new>/edit"
        props.history.push(childUrl);
    }

    const onEdit = () => {
        const editUrl = app.root + "/" + editor.formData.uniqueId + "/edit"
        props.history.push(editUrl);
    }

    return (
        <DocumentEditor {...props}
            formContext={{
                preview: undefined,
                onEditChild: onEditChild,
                onCreateChild: onCreateChild,
                primaryAction: {
                    type: "edit",
                    icon: "edit",
                    label: "Edit",
                    onClick: onEdit
                }
            }}
            registry={registry} />
    )
 
}

const mapStateToProps = (state) => {
	return {
        app: state.app,
        editor: state.editor
	}
}  

export default connect(
    mapStateToProps,
)(PrimusPreview);