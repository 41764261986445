export default {
    "select": {
        "name" : "name",
        "schemaType" : "schemaType",
        "description" : "description",
        "collectionId": "collectionId",
        "title" : "title",
        "metadata" : "metadata",
        "label": "label"
    },
    prepare({formData}) {
        let { content, collectionId } = formData

        let name = "";
        let schemaType = "";
        let description = "";

        let title = "";
        let label;
        let metadata = []

        if (content && content.name) {
            name = content.name
        }

        if (content && content.schemaType) {
            schemaType = content.schemaType
        }

        if (content && content.description) {
            description = content.description
        }

        if (content && content.collectionId) {
            collectionId = content.collectionId
        }

        if (content && content.title) {
            title = content.title
        }

        if (schemaType && name) {
            label = schemaType + "/" + name;
        }
        
        return {
            name: name,
            collectionId: collectionId,
            schemaType: schemaType,
            title: title || "Untitled schema",
            description: description,
            label: label,
            metadata: metadata
        }
    
    }
  
}