import React, { Component } from 'react';
import arrayMove from 'array-move';
import ArrayFieldSortableList from "./ArrayFieldSortableList"

class ArrayFieldSortable extends Component {
    
    state = {
        items: []
    }
    
    componentDidMount() {
        this.getItems()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.formData !== this.props.formData) {
            this.getItems()
        }
    }

    getItems = () => {
        const { formData } = this.props;
        this.setState({
            items: formData
        })
    }
    
    onSortStart = ({node, index, collection, isKeySorting}, event) => {
        
    }
    
    onSortEnd = ({oldIndex, newIndex}) => {
        const { items } = this.state;
        const formData = arrayMove(items, oldIndex, newIndex);
        this.props.onChange(formData)
    };
    
    render() {
        const { items } = this.state;

        return (
            <ArrayFieldSortableList {...this.props} 
                arrayProps={this.props}
                items={items} 
                onSortStart={this.onSortStart}
                onSortEnd={this.onSortEnd} 
                helperClass="sortable-helper"
                hideSortableGhost={true}
                useDragHandle />
        )

    }
}

export default ArrayFieldSortable;