import site from './site/';
import user from './user/';
import collection from './collection/';
import schema from './schema/';

const schemas = [
    site,
    user,
    collection,
    schema
]

export default schemas;