// initialState

let initialState = {
    viewOptions: undefined,
    view: undefined,
    sortOptions: undefined,
    sortOptionsById: {},
    sort: undefined,
    query: {},
    resultsById: {},
    results: [],
    selected: []
};

// finder state

const finder = (state = initialState, action) => {

	switch (action.type) {

        case 'REQUEST_FINDER': 
            return {
                ...initialState,
            }

        case 'RECEIVE_FINDER': 
            return {
                ...initialState,
                ...action.finder
            }

        case 'RECEIVE_FINDER_VIEW_OPTIONS': 
            return {
                ...state,
                viewOptions: action.options
            }

        case 'RECEIVE_FINDER_VIEW': 

            return {
                ...state,
                viewOptions: state.viewOptions.map(option => {
                    return {
                        ...option,
                        selected: option.value === action.view
                    }
                }),
                view: action.view
            }

        case 'RECEIVE_FINDER_SORT_OPTIONS': 

            return {
                ...state,
                sortOptions: action.options
            }

        case 'RECEIVE_FINDER_SORT': 

            return {
                ...state,
                sort: action.sort,
                sortOptions: state.sortOptions.map(option => {
                    return {
                        ...option,
                        selected: option.value === action.sort
                    }
                }),
                query: {
                    ...state.query,
                    sort: action.sort
                }
            }

        case 'REQUEST_FINDER_QUERY': 
            return {
                ...state,
                query: {}
            }

        case 'RECEIVE_FINDER_QUERY': 
            return {
                ...state,
                query: action.query
            }

        case 'REQUEST_FINDER_RESULTS': 
            return {
                ...state,
                results: [],
                resultsById: {},
                selected: []
            }

        case 'RECEIVE_FINDER_RESULTS':

            let resultsById = []
            let resultsSelected = []
            
            action.results.map((model, index) => {
                const { uniqueId, selected } = model;
                resultsById[uniqueId] = model;

                if (selected) {
                    resultsSelected.push(model)
                }

            })

            return {
                ...state,
                resultsById: {
                    ...state.resultsById,
                    ...resultsById
                },
                results: [
                    ...state.results,
                    ...action.results,
                ],
                selected: [
                    ...state.selected,
                    ...resultsSelected
                ]
            }
    
        case 'RECEIVE_FINDER_RESULTS_BY_ID':
            
            return {
                ...state,
                resultsById: action.resultsById,
            }

        case 'RECEIVE_BULK_CHANGE':

            let bulkById = []

            state.results.map((model, index) => {
                const { uniqueId } = model;
                bulkById[uniqueId] = {
                    ...model,
                    ...action.data
                }

            })

            let bulkResults = Object.values(bulkById)
            let bulkSelected = bulkResults.filter(model => model.selected)

            return {
                ...state,
                resultsById: bulkById,
                results: bulkResults,
                selected: bulkSelected,
            }

        case 'RECEIVE_MODEL_TOGGLE': 

            let toggleResultsById = {
                ...state.resultsById,
                [action.id]: {
                    ...state.resultsById[action.id],
                    selected: !state.resultsById[action.id].selected,
                }
            }

            let toggleResults = Object.values(toggleResultsById)
            let toggleSelected = toggleResults.filter(model => model.selected)

            return {
                ...state,
                resultsById: toggleResultsById,
                results: toggleResults,
                selected: toggleSelected
            }

        case 'RECEIVE_MODEL_CHANGE': 

            let updateResultsById = {
                ...state.resultsById,
                [action.id]: {
                    ...state.resultsById[action.id],
                    ...action.data
                }
            }

            let updateResults = Object.values(updateResultsById)
            let updateSelected = updateResults.filter(model => model.selected)

            return {
                ...state,
                resultsById: updateResultsById,
                results: updateResults,
                selected: updateSelected
            }

        default:
            return state
    }

}

export default finder;