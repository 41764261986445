export default {
  "select": {
    "imageUrl": "imageUrl",
    "title" : "title",
    "authUsername": "authUsername",
    "description" : "description",
    "metadata" : "metadata",
    "label": "label"
  },
  prepare({formData}) {
    let { name, content, authUsername, authorizedSites } = formData

    let label = "User";
    let metadata = [];
    
    let title = name;
    let description = "";
    
    if (content && content.name) {
      name = content.name;
      title = content.name;
    }

    if (content && content.email) {
      authUsername = content.email;
    }

    let imageUrl;
    
    if (content && content.avatar && content.avatar.media && content.avatar.media.imageUrl) {
      imageUrl = content.avatar.media.imageUrl;
    }

    if (authUsername) {
      metadata.push(authUsername)
    }

    if (authorizedSites && authorizedSites.length == 1) {
      metadata.push("1 site")
    } else if (authorizedSites && authorizedSites.length) {
      metadata.push(authorizedSites.length + " sites")
    }
      
    return {
      imageUrl: imageUrl,
      name: name,
      authUsername: authUsername,
      title: title,
      description: description,
      metadata: metadata,
      label: label
    }
    
  }
  
}