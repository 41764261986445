import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    }
}));

const MediaVolumeSlider = ({className, min = 0, max = 1, value = 0, onChange}) => {

    const classes = useStyles()

    const handleChange = (event, value) => {
        onChange && onChange(value) 
    }

    return (
        <Slider className={className || classes.volume} step={.1} marks={true} min={min} max={max} value={value} onChange={handleChange} />
    )

}

export default MediaVolumeSlider