import React, { useState, useEffect } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
    }
}));


const MenuEditorItem = ({handler, item}) => {
 
    if (item.expanded) {
        return (
            <div>
                {item.title}
            </div>
        )
    }

    return (
        <div>
            {handler}
            <div>
                {item.title}
            </div>
        </div>
    )

}

const MenuEditorTree = ({formData, onChange, ...props}) => {
    const [treeData, setTreeData] = useState([])

    useEffect(() => {
        setTreeData(formData)
    }, [formData]);

    const handleChange = (treeData) => {
        setTreeData(treeData)
    }

    return (
        <div style={{ height: 400 }}>
            <SortableTree
                treeData={treeData}
                onChange={handleChange}
            />
        </div>
    )
    
}

const MenuEditor = ({formData, buttons, onChange, ...props}) => {

    return (
        <div>
            <MenuEditorTree formData={formData} onChange={onChange} />
            <footer>
                { buttons && buttons.map((button, index) => {
                    return (
                        <Button onClick={button.onClick}>{button.label}</Button>
                    )
                })}
            </footer>
        </div>
    )
    
}

export default MenuEditor;
