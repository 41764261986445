import React, { Component } from 'react';
import PreviewLabel from "./PreviewLabel"
import PreviewMedia from './PreviewMedia/'

class PreviewMediaArray extends Component {
    
    renderItem = (item, index) => {
        
        const id = this.props.id + "_" + index;
        const schema = this.props.schema.items || {};
        
        return (
            <PreviewMedia id={id} formData={item} schema={schema} key={index} />
        )
        
    }
    
    render() {
      
      const { id, schema, formData } = this.props;

      if (formData && formData.length) {
        return (
          <section className="admin-preview__field  field--array">
          <PreviewLabel {...this.props} />
            { formData.map(this.renderItem) }
          </section>  
        )
      }
      
      return false

  }

}

export default PreviewMediaArray;