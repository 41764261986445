import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getApp } from './app/';

import AppRoutes from "./AnalyticsRoutes"

class Analytics extends Component {

    componentDidMount() {
        this.getApp();
    }
    
    componentDidUpdate = (prevProps) => {

        if (prevProps.user !== this.props.user ||
        prevProps.site !== this.props.site) {
            this.getApp();
        }

    }

    getApp = () => {
        this.props.getApp(this.props);
    }
    
    render() {
    
        return (
            <AppRoutes {...this.props} />
        )

    }

}

function mapStateToProps(state) {
	return {
    	app: state.app,
    	user: state.user,
    	site: state.site,
    	collection: state.collection
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
      getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Analytics);