export default {
  
  "ui:layout": "document",
  "ui:nav": "sidebar",

  "ui:fieldset": [
    "image",
    "metadata",
    "rights"
  ],
  
  "image": {
    "ui:layout": "section",
    "ui:icon": "image",
    "ui:title": "Bilde",
  
    "ui:fieldset": [
      "title",
      "headline",
      "description",
      "creditLine",
      "license",
      "category",
      "keywords"
    ],

    "title": {
      "ui:title": "Tittel",
      "ui:help": "This field can include a shorthand reference for the image or “photograph” - primarily for identification. The title of an image should be a short, human-readable name - text and/or numeric reference - and may take several forms."
    },
    "headline": {
      "ui:title": "Overskrift",
      "ui:help": "A headline is a brief, publishable synopsis or summary of the contents of the photograph. Like a news story's lead, the Headline should grab attention and summarize the image's content"
     },
     "description": {
      "ui:title": "Beskrivelse",
       "ui:help": "The Description field, often referred to as “Caption,” should report the who, what and why of what the photograph depicts.",
       "ui:widget": "textarea"
     },
     "descriptionWriter": {
      "ui:title": "Beskrivelse, forfatter",
       "ui:collapsible": true,
       "ui:help": "Enter the name of the person writing, editing or correcting the description of the photograph in this field."       
     },

    "creditLine": {
      "ui:title": "Kreditering",
      "ui:help": "The Credit Line is a free-text field that specifies how to acknowledge the supplying person(s) and/or organization(s) when publishing the image."
    },

    "category": {
      "ui:title": "Kategori",
      "ui:placeholder": "Ikke angitt"
    },

    "license": {
        "ui:widget": "license"
    },

    "keywords": {
      "ui:title": "Stikkord",
      "ui:help": "Enter keyword terms or phrases to describe the subject of content in the photograph.",
      "ui:widget": "textarea"
    }
  },
  "metadata": {
    "ui:layout": "section",
    "ui:icon": "tag",
    "ui:title": "Metadata",
    "ui:fieldset": [
      "category",
      "filename"
    ],
  
    "category": {
      "ui:title": "Kategori",
      "ui:placeholder": "Velg kategori"
    },

    "filename": {
      "ui:title": "Filnavn"
    }

  },
  "rights": {
    "ui:layout": "section",
    "ui:icon": "copyright",
    "ui:title": "Rettigheter",
  
    "ui:fieldset": [
      "creator",
      "creatorJobtitle",
      "creatorContactInfo",
      "copyrightNotice",
      "copyrightOwner"
    ],
  
    "creator": {
      "ui:title": "Navn på fotograf, illustratør, produsent",
      "ui:help": "This field should contain your name or the name of the person who created the photograph. If it is not appropriate to add the name of the photographer (for example, the photographer's identity needs to be protected) use a company or organization name. Once saved, this field should not be changed by anyone."
    },
    "creatorJobtitle": {
      "ui:title": "Jobbtittel",
      "ui:help": "This field should list the job title of the photographer. Examples might include: Staff Photographer, Freelance Photographer or Independent Commercial Photographer. Since this is a qualifier for the Creator field, you must also complete the Creator field."
    },
    "creatorContactInfo": {
      "ui:title": "Kontaktinfo",
      "ui:help": "This field should list the job title of the photographer. Examples might include: Staff Photographer, Freelance Photographer or Independent Commercial Photographer. Since this is a qualifier for the Creator field, you must also complete the Creator field."
    },
    "copyrightNotice": {
      "ui:title": "Opphavsrett",
      "ui:help": "The Copyright Notice should include any legal language required to claim intellectual property."
    },
    "copyrightOwner": {
      "ui:title": "Opphavsrett, navn på eier",
      "ui:help": "This field can be used to indicate the owner or owners of the copyright in the licensed image by name and identifier. This serves to identify the rights holder/s for the image."
    }
  }
  

}