import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
//        marginBottom: props => { return theme.spacing(props.spacing) }
    }
}));

const Field = ({
        id, name, language, layout, type, hidden,  
        draggable, draggableHandle, toolbar,
        children, ...props
    }) => {

    if (hidden) {
        return children
    }
    
    // clone widget

    let widget

    if (draggable || toolbar && toolbar.length) {
        widget = React.cloneElement(children, {
            draggable: draggable,
            draggableHandle: draggableHandle,
            toolbar: toolbar
        })
    }

    return (
        <div data-layout="field" data-name={name}>
            { widget || children }
        </div>
    )

}

export default Field