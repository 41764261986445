import React, { Component } from "react";
import PropTypes from "prop-types";
import FieldSelect from "../Field/FieldSelect"
import { asNumber, guessType } from "../Schema/utils";

class FieldSelectWidget extends Component {

    static defaultProps = {
        autofocus: false,
    };

    static propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        options: PropTypes.shape({
            enumOptions: PropTypes.array,
        }).isRequired,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        multiple: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };

    /**
     * This is a silly limitation in the DOM where option change event values are
     * always retrieved as strings.
     */
    
    processValue = (value) => {
        const { schema } = this.props;

        const nums = new Set(["number", "integer"]);

        // "enum" is a reserved word, so only "type" and "items" can be destructured
        const { type, items } = schema;

        if (value === "") {
            return undefined;
        } else if (type === "array" && items && nums.has(items.type)) {
            return value.map(asNumber);
        } else if (type === "boolean") {
            return value === "true";
        } else if (type === "number") {
            return asNumber(value);
        }
    
        // If type is undefined, but an enum is present, try and infer the type from
        // the enum values
        if (schema.enum) {
            if (schema.enum.every(x => guessType(x) === "number")) {
                return asNumber(value);
            } else if (schema.enum.every(x => guessType(x) === "boolean")) {
                return value === "true";
            }
        }
    
        return value;
        
    }

    getValue = (event) => {
    
        const { multiple } = this.props;
        
        if (multiple) {
            return [].slice.call(event.target.options).filter(function (o) {
                return o.selected;
            }).map(function (o) {
                return o.value;
            });
        } else {
            return event.target.value;
        }
    
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(this.props.id, this.processValue(this.getValue(event)))
        }
    }

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.id, this.processValue(this.getValue(event)))
        }
    }
    
    onChange = (value, event) => {
        this.props.onChange(this.processValue(this.getValue(event)))
    }
    
    getOptions = () => {
        const { schema, multiple, placeholder } = this.props;
        const { enumOptions, enumDisabled } = this.props.options;
        
        // select options
    
        let options = enumOptions.map(({ value, label }, i) => {
            const disabled = enumDisabled && enumDisabled.indexOf(value) !== -1;
            
            return {
                label: label,
                value: value,
                disabled: disabled,
            }
    
        })
        
        // add placeholder

        if (!multiple && schema.default === undefined) {
            
            options.unshift({
                value: "",
                label: placeholder
            })
            
        }
        
        return options;
        
    }
    
    render() {
    
        const {
            id,
            value,
            required,
            disabled,
            readonly,
            multiple,
            autofocus,
            options,
            label
        } = this.props;

        const emptyValue = multiple ? [] : "";

        const selectOptions = this.getOptions()
        
        
        return (
            
            <FieldSelect
                id={id}
                label={options.title || label}
                multiple={multiple}
                value={typeof value === "undefined" ? emptyValue : value}
                required={required}
                disabled={disabled || readonly}
                autoFocus={autofocus}
                options={selectOptions}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
            />
            
        )

    }

}

export default FieldSelectWidget;
