import { getDefaultFormState } from "../../Schema/utils"

export const getLocalizedStringFormData = (props) => {
    const { schema, formData, registry } = props;
    const { languages, currentLocale } = registry.formContext;

    let newFormData = {}

    if (typeof formData === "string") {
        let defaultValue = formData

        languages.map(locale => {
            const localeId = "locale:" + locale
            const value = currentLocale === locale && defaultValue
            newFormData[localeId] = value || ""
        })
    
    } else {

        languages.map(locale => {
            const localeId = "locale:" + locale
            const value = formData && formData[localeId]
            newFormData[localeId] = value || ""
        })


    }

    return getDefaultFormState(schema, {
        ...newFormData
    })
    
}

export default getLocalizedStringFormData