export default {
    "ui:field": "authorizedSites",
    "items": {
        "ui:layout": "module",
        "ui:options": {
            "editable": true
        },
        "ui:preview": {
            "select": {
                "title": "title",
                "label": "label",
                "description": "description"
            },
            prepare({formData}) {
                const { site, role } = formData
    
                const title = site && site.title;
                const label = role && role.name;
                const description = role && role.name;
    
                return {
                    title: title,
                    label: label,
                    description: description
                }
            }
        }  
    }
}