import InputWidget from "./InputWidget"

import ColorWidget from "./ColorWidget"

import DateTimeWidget from "./DateTimeWidget"
import DateWidget from "./DateWidget"
import TimeWidget from "./TimeWidget"

import TextWidget from "./TextWidget"
import TextareaWidget from "./TextareaWidget"
import TexteditorWidget from "./TexteditorWidget"


import PasswordWidget from "./PasswordWidget"
import EmailWidget from "./EmailWidget"
import UrlWidget from "./WidgetUrl"

import CodeWidget from "./CodeWidget"

import SelectWidget from "./SelectWidget"
import CheckboxWidget from "./CheckboxWidget"
import CheckboxesWidget from "./CheckboxesWidget"

import SwitchWidget from "./SwitchWidget"

import UpDownWidget from "./WidgetUpDown"

import SliderWidget from "./SliderWidget"
import RadiogroupWidget from "./RadiogroupWidget"

import AutocompleteWidget from "./AutocompleteWidget"

import MdIconWidget from "./WidgetMdIcon"
import IconWidget from "./IconWidget"

import TagsWidget from "./TagsWidget"
import IsoLanguageWidget from "./IsoLanguageWidget"

export default {
    "fieldtypes": {
    },
    "fields": {
    },
    "layouts": {
    },
    "widgets": {
        "text": TextWidget,
        "textarea": TextareaWidget,
        "texteditor": TexteditorWidget,
        "editor": TexteditorWidget,

        "number": InputWidget,
        "password": PasswordWidget,

        "uri": UrlWidget,
        "data-url": UrlWidget,

        "hostname": InputWidget,

        "ipv4": InputWidget,
        "ipv6": InputWidget,

        "email": EmailWidget,
        "color": ColorWidget,
        "range": SliderWidget,

        "updown": UpDownWidget,

        "select": SelectWidget,
        "autocomplete": AutocompleteWidget,

        "radio": RadiogroupWidget,

        "code": CodeWidget,

        "datetime": DateTimeWidget,
        "date": DateWidget,
        "time": TimeWidget,

        "date-time": DateTimeWidget,
        "alt-date": DateWidget,
        "alt-datetime": TimeWidget,

        "checkbox": CheckboxWidget,
        "checkboxes": CheckboxesWidget,

        "switch": SwitchWidget,

        "size": SelectWidget,
        "grid": SelectWidget,

        "mdIcon": MdIconWidget,
        "icon": IconWidget,

        "tags": TagsWidget,
        "isoLanguage": IsoLanguageWidget

    }
}