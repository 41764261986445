import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import CalendarTable from "./CalendarTable"
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll"
    },
    month: {
    },
    date: {

        "& + $date": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider
        }

    },
    dateButton: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        margin: 0,
        fontFamily: "Akkurat, sans-serif",
        fontSize: '12px',
        color: theme.palette.text.disabled,
        lineHeight: 1,
        "&[data-month=true]": {
            color: theme.palette.text.primary
        },
        "&[data-today=true]": {
            border: "1px solid",
            borderColor: theme.palette.primary.main
        },
        "&[data-selected=true]": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
    }
}));

const EventsList = ({events}) => {

    if (!events) {
        return false
    }

    return (
        <ul>
            { events.map(event => {
                return (
                    <li>{event.title}</li>
                )
            })}
        </ul>
    )

}

const DateButton = ({selected, dt, dtMonth, onClick}) => {

    const classes = useStyles()

    return (
        <IconButton className={classes.dateButton} onClick={onClick}
            data-selected={selected}
            data-month={dtMonth}>
            {moment(dt).format('D')}
        </IconButton>
    )

}

const ScheduleDate = ({date, events = []}) => {

    const classes = useStyles()

    return (
        <div className={classes.date}>
            {date}
            <EventsList events={events} />
        </div>
    )

}

const CalendarSchedule = ({eventsByDate, ...props}) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            { Object.keys(eventsByDate).map(date => {

                const events = eventsByDate[date]

                return (
                    <ScheduleDate date={date} events={events} />
                )
            })}
        </div>
    )

}

export default CalendarSchedule;
