import mediaLayout from "./mediaLayout"
import mediaQuery from "./mediaQuery"

export default {
    "properties": {
        "type": {
            "enum": ["media/image"]
        },
        "pathname": {
            "type": "string",
        },
        "query": mediaQuery,
        "layout": mediaLayout
    }
}