export default {
    "type": "object",
    "properties": {
        "objectId": {
            "type": "string"
        },
        "objectType": {
            "type": "string"
        },
        "imageUrl": {
            "type": "string"
        },
        "title": {
            "type": "string"
        },
        "description": {
            "type": "string"
        }
    }
}