import React from 'react';
import Color from 'color';
import PropTypes from "prop-types"
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "inline-block",
        fontSize: "12px",
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "inherit",
        fontWeight: "bold",
        padding: ".125em .75em",
        border: "1px solid",
        backgroundColor: props => { return props.backgroundColor || "transparent" },
        borderColor: props => { return props.borderColor || theme.palette.divider },
        color: props => { return props.color || theme.palette.text.secondary },
        borderRadius: "1.25em",
//        textTransform: "capitalize",
    }
}));

const getStyles = ({color, backgroundColor, textColor, borderColor}) => {

    if (!color && backgroundColor) {
        color = backgroundColor
    }

    if (!backgroundColor) {
        return {}
    }

    color = Color(color);

    if (color.isDark()) {
        backgroundColor = color.hex();
        textColor = textColor || 'white';
        borderColor = color.hex();
    } else {
        backgroundColor = color.hex();
        textColor = textColor || 'black';
        borderColor = borderColor || 'rgba(0,0,0,.25)'
    }

    return {
        backgroundColor: backgroundColor,
        color: textColor,
        borderColor: borderColor
    }

}

const ModuleLabel = ({className, component = "h4", label, color, backgroundColor, textColor, borderColor, children}) => {

    const styles = getStyles({color, backgroundColor, textColor, borderColor})
    const classes = useStyles(styles)

    if (!label && children) {
        label = children
    }

    if (!label) {
        return false
    }

    return (
        <span className={className || classes.root}>
           <Typography className={classes.label} noWrap={true} component={component}>{label}</Typography>
        </span>
    )
}

ModuleLabel.propTypes = {
    label: PropTypes.string,
};

ModuleLabel.defaultProps = {
    label: undefined
};

export default ModuleLabel;