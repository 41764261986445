import React, { Component, useMemo } from 'react';
import { computeCartesianTicks } from '../lib/compute'
import moment from 'moment';

class Axis extends Component {
    
    static defaultProps = {
        format: "integer"
    }
    
    renderLabel = (value, format) => {

        if (format === "MONTH") {
            const month = moment(value).format('MMMM');
            
            let classNames = []
            
            classNames.push('tick__label');
            
            const className = classNames.join(' ');
            
            return (
                <g className={className}>
                    <text className="tick__month">
                        { month }
                    </text>
                </g>
            )
        
        
        }
        
        if (format === "WEEK") {
            
            const w1 = moment(value).format();
            const w2 = moment(value).endOf("week").format();

            let classNames = []
            
            classNames.push('tick__label');
            
            const date1 = moment(w1).format('DD.MM')
            const date2 = moment(w2).format('DD.MM')
            
            const w = moment(w1).isoWeek()
            
            const className = classNames.join(' ');
            
            return (
                <g className={className}>
                    <text className="tick__start">
                        { date1 }–
                    </text>
                    <text className="tick__end">
                        { date2 }
                    </text>
                </g>
            )
        
        
        }

        if (format === "DATE") {

            const year = moment(value).format('Y');
            const month = moment(value).format('MMM');
            const day = moment(value).format('DD');
            
            let classNames = []
            
            classNames.push('tick__label');
            
            const className = classNames.join(' ');
            
            return (
                <g className={className}>
                    <text className="tick__day">
                        { day }
                    </text>
                    <text className="tick__month">
                        { month }
                    </text>
                </g>
            )
        
        
        }
        
        if (format === "HOUR") {
            const hour = moment(value).format('HH:mm');

            return (
                <text className="tick__label">
                    { hour }
                </text>
            )
            
        }

        if (format === "MINUTE") {
            const hour = moment(value).format('HH:mm');

            return (
                <text className="tick__label">
                    { hour }
                </text>
            )
            
        }
        
        if (format === "PERCENT") {
            value = parseFloat(value);
            value = value.toFixed(0) + "%"
            
            return (
                <text className="tick__label">
                    { value }
                </text>
            )
            
        }

        if (format === "TIME") {
            
            const time = moment().startOf('day').add(value, "seconds").format('H:m:ss');

            const split = time.split(":")
        
            const hours = split[0];
            const minutes = split[1];
            const seconds = split[2];
            
            if (hours >= 1) {
                value = hours + 'h ' + minutes +'m ' + seconds + 's'
            } else {
                value = minutes + 'm ' + seconds + 's'
            }
            
            return (
                <text className="tick__label">
                    { value }
                </text>
            )
            
        }
        
        if (format === "INTEGER") {

            value = new Intl.NumberFormat('de-DE').format(value)
    
            return (
                <text className="tick__label">
                    { value }
                </text>
            )
            
        }
        
        return (
            <text className="tick__label">
                { value }
            </text>
        )
        
    }
    
    renderTick = (props, index) => {
        let { axis, position, scale, format, dimension, startDate, endDate } = this.props;
        let { x, y, lineX, lineY, textX, textY, textAnchor, textBaseline, rotate, value } = props;

        if (format === "STRING" && dimension === "ga:nthMonth" && startDate) {
            format = "MONTH"
            value = moment(startDate).startOf('month').add(value, "months")
        }
        
        if (format === "STRING" && dimension === "ga:nthWeek" && startDate) {
            format = "WEEK"
            value = moment(startDate).startOf('week').add(value, "weeks")
        }
        
        if (format === "STRING" && dimension === "ga:nthDay" && startDate) {
            format = "DATE"
            value = moment(startDate).add(value, "days")
        }

        if (format === "STRING" && dimension === "ga:nthHour" && startDate) {
            format = "HOUR"
            value = moment(startDate).add(value, "hours")
        }

        if (format === "STRING" && dimension === "ga:nthMinute" && startDate) {
            format = "MINUTE"
            value = moment(startDate).add(value, "minutes")
        }
        
        const transform = "translate("+x+","+y+")"
        
        const classNames = []
        
        classNames.push('tick');
        classNames.push('tick--' + axis);
        classNames.push('tick--' + position);
        classNames.push('tick--' + format.toLowerCase());
        
        if (dimension && dimension.includes("ga:")) {
            classNames.push('tick--' + dimension.replace("ga:", ""));
        }
        
        if (format === "DATE") {
            classNames.push('tick--month-' + moment(value).format('M'));
            classNames.push('tick--day-' + moment(value).format('D'));
        }

        const className = classNames.join(' ');
        
        return (

            <g transform={transform} className={className}>
                <line className="tick__line" x1={0} x2={lineX} y1={0} y2={lineY}/>
                { this.renderLabel(value, format) }
            </g>            

        )
        
    }
    
    render() {
        const { axis, position, x, y, scale, ticksPosition, tickValues, tickSize, tickPadding, tickRotation } = this.props;
        
        const transform = "translate("+x+","+y+")"
        const className = "axis  axis--" + axis + " axis--" + position;

        const { ticks, textAlign, textBaseline } = computeCartesianTicks({
            axis,
            scale,
            ticksPosition,
            tickValues,
            tickSize,
            tickPadding,
            tickRotation,
        })
        
        return (
            <g transform={transform} className={className}>
                {ticks.map((tick, index) =>
                    this.renderTick({
                        rotate: tickRotation,
                        textBaseline,
                        textAnchor: textAlign,
                        ...tick
                    }, index)
                )}
            </g>
            
        )        

        return (

            <g transform={transform} className={className}>
                { ticks.map(this.renderTick) }
            </g>

            
        )
        
    }

}

export default Axis