import React from "react";
import { MinneIcon as icon } from '@frontend-components/admin/es/resources/icons';

export default {
    sortOrder: 10.2,
    appType: "site/product",
    collectionType: "booking",
    name: "booking",
    icon: icon,
    title: "Booking"
};