import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LayoutApp, AppBase, AppHeader, AppBody, AppSidebar, AppContent } from "@frontend-components/admin"
import { getCollection } from "../../AppCollection/app"

import AppLayout from "../../App/AppLayout"

import PreviewCollectionSettings from "./PreviewCollectionSettings"
import PreviewCollectionTheme from "./PreviewCollectionTheme"
import PreviewCollectionAction from "./PreviewCollectionAction"
import PreviewCollectionMenu from "./PreviewCollectionMenu"
import PreviewCollectionSearch from "./PreviewCollectionSearch"
import PreviewCollectionQuery from "./PreviewCollectionQuery"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: theme.spacing(1)
    },
    main: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "scroll",
        margin: theme.spacing(2)
    }
}));




const PreviewSection = ({app, currentSection, currentParent, currentChild, ...props}) => {

    if (currentSection && currentSection === "documentTypes") {
        return (
            <PreviewCollectionAction app={app} />
        )
    }

    if (currentSection && currentSection === "search") {
        return (
            <PreviewCollectionSearch app={app} />
        )
    }

    if (currentSection && currentSection === "theme") {
        return (
            <PreviewCollectionTheme app={app} />
        )
    }

    if (currentSection && currentSection === "menu" ) {

        if (currentChild) {
            return (
                <PreviewCollectionQuery {...currentChild} app={app} />
            )
        }

        if (currentParent) {
            return (
                <PreviewCollectionQuery {...currentParent} app={app} />
            )
        }

        return (
            <PreviewCollectionMenu app={app}  />
        )

    }

    return (
        <PreviewCollectionSettings {...props} app={app} />
    )
    
}


const CollectionPreview = ({user, ...props}) => {
    const { formData, formContext } = props;

    const site = {
        id: formData && formData.siteId,
        name: formData && formData.siteName
    }


    const app = getCollection({collection: formData, site: site})

    const currentId = formContext && formContext.currentId;
    const currentIds = currentId && currentId.split('_');
    const currentSection = currentIds && currentIds[2]

    let header = {
        expanded: true
    }

    let search = app && app.search

    const [searchExpanded, setSearchExpanded] = useState(false)

    const toggleSearch = () => {

    }

    search = {
        ...search,
        expanded: searchExpanded,
        onToggle: () => toggleSearch()
    }
    
    if (currentSection && currentSection === "search") {
        search = {
            ...search,
            expanded: true
        }
    }

    let sidebar = {
        expanded: true
    }

    if (currentSection && currentSection === "menu" ) {

        sidebar = {
            expanded: true
        }

    }

    const menu = app && app.menu
    const primaryAction = app && app.primaryAction

    const parentIndex = currentIds && currentIds[3]
    const childIndex = currentIds && currentIds[6]

    const currentParent = parentIndex && menu && menu[parentIndex]
    const currentChild = currentParent && currentParent.children && currentParent.children[childIndex]


    return (
        <AppLayout 
            app={app}
            user={user}
            menu={menu}
            primaryAction={primaryAction}
            header={header}
            search={search}
            sidebar={sidebar}>
                <PreviewSection app={app}
                    currentSection={currentSection}
                    currentParent={currentParent}
                    currentChild={currentChild} {...props} />
        </AppLayout>
    )
    
}

function mapStateToProps(state) {
	return {
        user: state.user,
        editor: state.editor,
        formData: state.editor.formData,
        formContext: state.editor.formContext,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
  }, 
dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionPreview);
