import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif"
        },
        "& .MuiInputBase-root": {
            fontFamily: "Akkurat, sans-serif",
            lineHeight: "1.25",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0"
        },
        "& .MuiFormHelperText-root": {
            fontFamily: "Akkurat, sans-serif"
        },
    },
    startAdornment: {
        marginTop: theme.spacing(-1.5),
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1)
    },
    textPrefix: {
        fontSize: "14px",
        color: theme.palette.text.secondary
    },
    iconPrefix: {
    },
    iconPrefixLabel: {
        marginLeft: theme.spacing(4.5)
    },
}));

const AutocompleteBase = ({variant="filled", id, icon, prefix, label, helperText, placeholder, value, options, multiple, groupBy, onChange, ...props}) => {
    const classes = useStyles()

    let selected = value && options.filter(option => option.value == value)

    if (!multiple && selected.length) {
        selected = selected[0]
    }

    let iconPrefix, textPrefix;

    if (icon) {
        iconPrefix = <Icon className={classes.iconPrefix}>{icon}</Icon>
    }

    if (prefix) {
        textPrefix = <b className={classes.textPrefix}>{prefix}</b>;
    }

    let startAdornment

    if (iconPrefix || textPrefix) {
        startAdornment = <div className={classes.startAdornment} position="start">{iconPrefix}{textPrefix}</div>
    }

    return (
        <Autocomplete
            {...props}
            id={id}
            options={options}
            groupBy={groupBy}
            value={selected}
            getOptionSelected={(option, value) => option.value === value}
            getOptionLabel={option => options && option.label || ""}
            onChange={onChange}
            renderInput={params => (
                <TextField
                    {...params}
                    className={classes.root}
                    helperText={helperText}
                    label={label}
                    variant={variant}
                    placeholder={placeholder}
                    fullWidth
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        className: iconPrefix && classes.iconPrefixLabel
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: startAdornment,
                    }}
                />
            )}
        />
    );  
}

export default AutocompleteBase