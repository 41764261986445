import React, { useEffect, useState } from 'react';
import { getUiOptions } from "@frontend-components/admin"

const CollectionSchemasField = (props) => {
    const { uiSchema, formContext } = props
    const { onCreateSchema } = formContext
    
    const uiOptions = getUiOptions(uiSchema)

    const { buttons } = uiOptions;

    const uiButtons = buttons && buttons.map(button => {

        if (button.type === "add" || !button.type) {
            return {
                ...button,
                onClick: () => onCreateSchema(props)
            }
        }

        return button

    })

    const newUiSchema = {
        ...uiSchema,
        "ui:buttons": uiButtons
    }
    

    const { ArrayField } = props.registry.fields

    return (
        <ArrayField {...props} uiSchema={newUiSchema} />
    )
    
}

export default CollectionSchemasField