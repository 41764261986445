import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutButtons from "./LayoutButtons"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
    }
}));

const LayoutFooter = ({className, padding = 1, buttons = [], buttonGroupProps, buttonProps}) => {
    const classes = useStyles({padding});

    if (!buttons.length) {
        return false
    }

    return (
        <footer className={className || classes.root}>
            { buttons && <LayoutButtons buttons={buttons} buttonGroupProps={buttonGroupProps} buttonProps={buttonProps} /> }
        </footer>
    )

}

export default LayoutFooter;