import { API } from '../settings';
import { requestSearch, receiveSearch } from '../actions/search';
import { requestModel, receiveModel, receiveModelStatus } from '../actions/model';
import { requestReferences, receiveReferences } from '../actions/references';

import { requestEditorLoad, receiveEditorLoad } from '../actions/editor';
import { requestEditorEdit, receiveEditorEdit } from '../actions/editor';
import { requestEditorSave, receiveEditorSave } from '../actions/editor';
import { getQueryParams } from './query';

// dummy data

//import { searchYoutube } from "./youtube"
//import { searchVimeo } from "./vimeo"
//import { searchSketchfab } from "./sketchfab"

import { searchApps } from './apps';
import { searchInbox, getInboxModel, loadInboxModel } from './inbox';

// SEARCH

export function searchModel(model, pathname, query) {
    
  let url = API + '/admin/api/' + model + '/search';
  
  let params = getQueryParams(query);

  if (params) {
    url = url + '?' + params;
  }

  /* DUMMY */
  
  if (model === "apps") {
    return searchApps(model, pathname, query);
  }

  if (model === "inbox") {
    return searchInbox(model, pathname, query);
  }
  
  // API SEARCH

  return function(dispatch) {

    dispatch(requestSearch(pathname, query))
  
    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
      },
    })
    .then(
      response => response.json(),
      error => console.log('An error occurred.', error)
    )
    .then(data => {
        dispatch(receiveSearch(pathname, data))
    })

  }

}

// GET MODEL

export function getModel(model, uniqueId) {

  const url = API + '/admin/api/' + model + '/' + uniqueId;

  /* DUMMY */
  
  if (model === "inbox") {
    return getInboxModel(model, uniqueId);
  }

  // API
  
  return function (dispatch) {

//    dispatch(requestModel(pathname))

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(receiveModel(uniqueId, data)),
    )

  }
  
}

// GET CHILDREN

export function getModelChildren(model, id, parentId) {

  let url = API + '/admin/api/' + model + '/search?parentId=' + parentId;

  return function (dispatch) {

    dispatch(requestSearch(id))

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(receiveSearch(id, data)),
    )

  }
  
}

// GET REFERENCES

export function getModelReferences(models, uniqueId) {

  const url = API + '/admin/api/references/' + models + '/' + uniqueId;

  return function (dispatch) {

    dispatch(requestReferences(uniqueId))

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
    )
    .then(data =>
      dispatch(receiveReferences(uniqueId, data)),
    )

  }
  
}

// EDIT

export function editModel(formData = { uniqueId: "<new>", documentType: "undefined", status: "new", content: {} }) {

    if (formData && typeof formData === "STRING") {
        formData = JSON.parse(formData)
        formData.content = JSON.parse(formData.content)
    }

    if (formData.content && typeof formData.content === "STRING") {
        formData.content = JSON.parse(formData.content)
    }

    return function(dispatch) {
        dispatch(receiveEditorEdit(formData))
    }
  
}

// LOAD

export function loadModel(model, uniqueId) {

    if (!uniqueId) {
        return function (dispatch) {
            dispatch(editModel())
        }
    }
  
    const url = API + '/admin/api/' + model + '/' + uniqueId;
  
    return function (dispatch) {
        dispatch(requestEditorLoad())
        dispatch(requestModel(uniqueId))

        fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
            dispatch(receiveModel(uniqueId, data))
            dispatch(editModel(data))
        })
    }

}

// SAVE

export function saveModel(model, data, edit = true) {
    const url = API + '/admin/api/' + model;
    const payload = JSON.stringify(data);
  
    console.log(payload);

    return function (dispatch) {
        edit && dispatch(requestEditorSave())

        fetch(url, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
            },
            body: payload
        })
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then(data => {
                dispatch(receiveModel(data.uniqueId, data))
                edit && dispatch(editModel(data))
            }
        )
    }

}

// DELETE, ERASE, RESTORE

export function deleteModel(models, uniqueId) {

  const url = API + '/admin/api/' + models + '/' + uniqueId;

  return function (dispatch) {
    
    fetch(url, {
      method: "DELETE",
    })
    .then(
      error => console.log('An error occurred.', error)
    )
    .then(data =>
        dispatch(getModel(models, uniqueId))
    )

  }

}

export function eraseModel(models, uniqueId) {
    const url = API + '/admin/api/' + models + '/' + uniqueId + '?erase=true';
    return function (dispatch) {
        fetch(url, {
            method: "DELETE",
        })
        .then(
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(receiveModelStatus(uniqueId, "erased"))
        )
    }
}

export function restoreModel(models, uniqueId) {
    const url = API + '/admin/api/' + models + '/restore/' + uniqueId;
    return function (dispatch) {
        fetch(url, {
            method: "POST",
        })
        .then(
            error => console.log('An error occurred.', error)
        )
        .then(data =>
            dispatch(getModel(models, uniqueId))
        )
    }
}