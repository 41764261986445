import React, { Component } from 'react';
import ButtonAction from './ButtonAction';
import ButtonOption from './ButtonOption';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    }
}));

const NavToolbar = ({className, toolbar = [], onEdit, onSave, onDelete, onRestore, onErase, onToggle}) => {

    const classes = useStyles()

    if (!toolbar.length) {
        return false
    }

    return (
        <nav className={className || classes.root}>
            { toolbar && toolbar.map((button, index) => {
                if (button.options) {
                    return (
                        <ButtonOption {...button} key={index} />
                    )
                }

                return (
                    <ButtonAction {...button} key={index} />
                )
            })}
        </nav>
    )

}

export default NavToolbar