import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        backgroundColor: props => { return props.backgroundColor },
        color: props => { return props.color },

        /*
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        */

        "&[data-position=absolute]": {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },

        "& + *": {
            marginLeft: props => { if (props.align === "left") return props.minWidth }
        },

        minWidth: props => { return props.minWidth },
        minHeight: props => { return props.minHeight },
        maxWidth: props => { return props.maxWidth || "100%" },
        maxHeight: props => { return props.maxHeight },
        margin: "auto",
    },
    ratio: {
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: props => { return props.ratio && props.ratio * 100 + "%" },
        margin: "auto"
    }
}));

const MediaRatio = ({ratio = undefined, children}) => {
   const classes = useStyles({ratio})

    if (ratio) {
        return (
            <div className={classes.ratio}>
                { children }
            </div>
        )
    }

    return children

}

const MediaBase = ({
    name = undefined,
    backgroundColor = undefined,
    color = undefined,
    position = "relative", 
    minWidth = undefined, 
    minHeight = undefined, 
    maxWidth = undefined, 
    maxHeight = undefined, 
    format = undefined, 
    children }) => {

        let ratio;

    if (format && format.includes(':')) {
        const size = format.split(":");
        const w = size[0];
        const h = size[1];
        ratio = h/w;

        minHeight = undefined;
        maxHeight = undefined;

    }
    
    const classes = useStyles({backgroundColor, color, position, minWidth, minHeight, maxWidth, maxHeight, ratio})

    return (
        <div className={classes.root}
            data-layout="mediaBase"
            data-name={name}
            data-format={format}
            data-min-width={minWidth}
            data-max-width={maxWidth}
            data-min-height={minHeight}
            data-max-height={maxHeight}
            data-position={position}>
                <MediaRatio ratio={ratio}>
                    { children }
                </MediaRatio>
        </div>
    )

}

export default MediaBase;