export default {
    "select": {
        "title" : "title",
        "description" : "description",
    },
    prepare({formData}) {
        const content = formData && formData.content;

        const title = content && content.title || "Untitled";

        let metadata = []

        const adults = content && content.adults;
        const children = content && content.children;
        const free = content && content.free;

        

        if (adults) {
            metadata.push(adults + ' voksne')
        }

        if (children) {
            metadata.push(children + ' barn')
        }

        if (free) {
            metadata.push(free + ' gratis')
        }
        
        return {
            title: title,
            description: metadata.join(' – '),
            label: "Booking"
        }

    }
  
}