import React, { Component } from 'react'
import "./Overlay.scss"

class Overlay extends Component {

    render() {
        const { expanded, style } = this.props;

        return (
            <aside className="admin-overlay" aria-expanded={expanded} style={style}>
                { this.props.children }
            </aside>
        )
    }
}

export default Overlay;