import React, { Component } from 'react';
import Buttongroup from "../Buttongroup/Buttongroup";

import "./ButtongroupFilters.scss";

class ButtongroupFilters extends Component {

    getClassName = () => {
        let classNames = []

        classNames.push('admin-buttongroup');
        classNames.push('admin-buttongroup--filters');

        return classNames.join(' ')
    }

    render() {
        const className = this.getClassName();

        return (
            <Buttongroup {...this.props} className={className} role="group">
                {this.props.children}
            </Buttongroup>
        )
    }
}

export default ButtongroupFilters;
