export default {
    "type": "array",
    "items": {
        "type": "string",
        "enum": [
            "Owner",
            "Name",
            "Exhibition",
            "Photograph",
            "Thing",
            "Artdesign",
            "Fineart",
            "Architecture",
            "Building",
            "Story",
            "Article",
            "Folder"
        ],
        "enumNames": [
            "Owner",
            "Name",
            "Exhibition",
            "Photograph",
            "Thing",
            "Artdesign",
            "Fineart",
            "Architecture",
            "Building",
            "Story",
            "Article",
            "Folder"
        ]
    },
    "uniqueItems": true
}