import React from 'react';
import LayoutList from "./LayoutList"
import LayoutGrid from "./LayoutGrid"
import LayoutButtons from "./LayoutButtons"

const LayoutArray = ({children, grid, gridItem, ...props}) => {

    if (grid && gridItem) {
        return (
            <LayoutGrid grid={grid} gridItem={gridItem} {...props}>
                {children}
                <LayoutButtons {...props} buttonGroupProps={{fullWidth: true}}/>
            </LayoutGrid>
        )
    }

    return (
        <LayoutList {...props}>
            {children}
        </LayoutList>
    )

}

LayoutArray.defaultProps = {
    padding: 0,
    spacing: 0
}

export default LayoutArray;