import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppsWidget } from '@frontend-components/admin';

class WidgetUserApps extends Component {
    
    static defaultProps = {
        editable: false,
        title: "Applikasjoner",
        icon: "apps",
    }

    render() {
        const { user } = this.props;
        const { apps } = user;
    
        return (
            <AppsWidget {...this.props} apps={apps} />
        )
    
    }

}

function mapStateToProps(state) {
	return {
      	user: state.user
	};
}  

export default connect(
  mapStateToProps,
)(WidgetUserApps);