import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getApp } from './app';
import schemas from './schemas';

import { UserDashboard, EditProfile } from './';

class User extends Component {

    componentDidMount() {
        this.onLoad()
    }
  
    onLoad = () => {
        this.props.getApp({...this.props, schemas: schemas});
    }
    
    render() {
        const { user } = this.props
    
        if (!user.uniqueId) {
            
            return (
                <p>Loading user</p>
            )
            
        }
    
        return (
        
            <Switch>
                <Route path="/profile" component={ EditProfile } />
                <Route path="/" component={ UserDashboard } />
            </Switch>
        
        )
    
    }

}

function mapStateToProps(state) {
	return {
        user: state.user,
	};
}  

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
      getApp
  }, 
dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(User);

